import React from 'react';

import { LinkExternal } from '@styled-icons/boxicons-regular/LinkExternal';
import { Microchip } from '@styled-icons/boxicons-regular/Microchip';

import Box from '@northflank/components/Box';
import Icon from '@northflank/components/Icon';
import Text from '@northflank/components/Text';

const DisplayGPU = ({ gpu }) => {
  const { name, displayName, manufacturer } = gpu;

  return (
    <Box
      p={3}
      mb={2}
      _css={{ breakInside: 'avoid-column' }}
      variant="hover"
      borderRadius={2}
    >
      <a href={`/cloud/gpus/${name}`}>
        <Box variant="flex">
          <Icon
            Content={Microchip}
            Size={12}
            color="grey3"
            alignSelf="flex-start"
            pt={3}
            mr={3}
          />
          <Box>
            <Text color="grey1">
              {manufacturer} {name}
            </Text>
            {name !== displayName && (
              <Text fontSize={0} color="grey2">
                {displayName}
              </Text>
            )}
          </Box>
          <Icon
            Content={LinkExternal}
            Size={10}
            color="grey3"
            alignSelf="flex-start"
            pt={3}
            ml={3}
          />
        </Box>
      </a>
    </Box>
  );
};
const DisplayGpus = ({ gpus }) => {
  return (
    <Box
      bg="grey10"
      border="1px solid"
      borderColor="grey5"
      borderRadius={3}
      p={4}
      py={6}
      pl={8}
    >
      <Box _css={{ columnCount: [1, 2, 3, 4] }}>
        {gpus.map((g) => (
          <DisplayGPU key={g.name} gpu={g} />
        ))}
      </Box>
    </Box>
  );
};

export default DisplayGpus;
