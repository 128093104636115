import React from 'react';

import Legal from '@northflank/components/marketing/Legal';
import SEO from '@northflank/components/marketing/SEO';

import privacy from './content.md';

const TermsOfService = () => (
  <>
    <SEO title="Privacy Policy" />
    <Legal
      title="Privacy Policy"
      description={`Northflank's Privacy Policy`}
      html={privacy.html}
    />
  </>
);

export default TermsOfService;
