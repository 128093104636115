import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import '@northflank/components/marketing/util/line-numbers';

import { Facebook } from '@styled-icons/boxicons-logos/Facebook';
import { Linkedin } from '@styled-icons/boxicons-logos/Linkedin';
import { File } from '@styled-icons/boxicons-regular/File';
import css from '@styled-system/css';
import styled from 'styled-components';

import Box from '@northflank/components/Box';
import Grid from '@northflank/components/Grid';
import Heading from '@northflank/components/Heading';
import Image from '@northflank/components/Image';
import MarkdownBody from '@northflank/components/marketing/MarkdownBody';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';
import Tooltip from '@northflank/components/Tooltip';
import X from '@northflank/images/icons/X';

import NewsletterPopup from '../../components/NewsletterPopup';
import PreHeading from '../../components/PreHeading';
import Tags from '../../components/Tags';
import formatDate from '../../utils/formatDate';
import useSyntaxHighlighting from '../../utils/useSyntaxHighlighting';
import { ArticleImage, BackLink, ShareLink } from '../changelog/post';
import { StickyContentsNav } from '../guides/post';
import { BlogCard, ResourcesNav } from './index';

export const AuthorAvatar = styled(Image)(({ size = '50px' }) =>
  css({
    minHeight: size,
    minWidth: size,
    maxHeight: size,
    maxWidth: size,
    borderRadius: '50%',
    boxShadow: 'dropDown',
  })
);

export const ExtraAuthors = styled(Box)(() =>
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bg: 'grey6',
    color: 'grey1',
    minHeight: '50px',
    minWidth: '50px',
    maxHeight: '50px',
    maxWidth: '50px',
    borderRadius: '50%',
    boxShadow: 'dropDown',
    fontSize: 3,
    lineHeight: 1,
  })
);

export const StyledLink = styled.a(() =>
  css({
    color: 'primary',
    textDecoration: 'none',
  })
);

const BlogPost = ({
  post,
  renderedPost,
  isOldHtmlPost,
  newerPosts = [],
  related = [],
}) => {
  const { pathname } = useLocation();

  useSyntaxHighlighting([post.attributes.slug]);

  const shareText = encodeURIComponent(
    `Northflank blog — ${post.attributes.title}`
  );

  return (
    <>
      <SEO
        title={`${post.attributes.title} | Blog`}
        description={post.attributes.excerpt}
        image={post.attributes.header?.data?.attributes.url}
        linkTags={post.attributes.html_link_tags}
      />
      <NewsletterPopup />
      <Box variant="bounding">
        <Box maxWidth="750px" mx="auto">
          <ResourcesNav />
          <Link to="/blog">
            <BackLink>&larr; Back to Blog</BackLink>
          </Link>
          <Box mb={10}>
            <ArticleImage
              src={post.attributes.header?.data?.attributes.url}
              alt={`Header image for blog post: ${post.attributes.title}`}
              width={['100%', '100%', '100%', '125%']}
              ml={[0, 0, 0, 'calc(((125% - 100%) / 2) * -1)']}
              mb={4}
            />
          </Box>
          <Box
            variant="flex"
            alignItems={[
              post.attributes.writers.data.length > 1 ? 'flex-start' : 'center',
              post.attributes.writers.data.length > 1 ? 'flex-start' : 'center',
              'center',
              'center',
            ]}
            flexDirection={[
              post.attributes.writers.data.length > 1 ? 'column' : 'row',
              post.attributes.writers.data.length > 1 ? 'column' : 'row',
              'row',
              'row',
            ]}
            mb={8}
          >
            <Box
              variant="flex"
              height="50px"
              mr={6}
              mb={post.attributes.writers.data.length > 1 ? [6, 6, 0, 0] : 0}
            >
              {post.attributes.writers.data.slice(0, 3).map((author, i) => (
                <Tooltip
                  key={author.id}
                  position="bottom"
                  distance={5}
                  ml={i > 0 && '-20px'}
                  html={
                    <Box>
                      <Text>{author.attributes.name}</Text>
                      {author.attributes.twitter && (
                        <StyledLink
                          href={`https://x.com/${author.attributes.twitter.replace('@', '')}`}
                          target="_blank"
                        >
                          {author.attributes.twitter}
                        </StyledLink>
                      )}
                    </Box>
                  }
                  interactive
                  inline
                >
                  <AuthorAvatar
                    src={
                      author.attributes.picture?.data?.attributes.url ||
                      '/images/headshot/default.png'
                    }
                  />
                </Tooltip>
              ))}
              {post.attributes.writers.data.length > 3 && (
                <ExtraAuthors ml="-20px">
                  +{post.attributes.writers.data.length - 3}
                </ExtraAuthors>
              )}
            </Box>
            <Box>
              {!!post.attributes.writers?.data.length && (
                <Text>
                  By{' '}
                  {post.attributes.writers.data.map((author, i) => (
                    <React.Fragment key={author.attributes.slug}>
                      <Link to={`/author/${author.attributes.slug}`}>
                        <Text
                          as="span"
                          color="grey1"
                          css={{ '&:hover': { textDecoration: 'underline' } }}
                        >
                          {author.attributes.name}
                        </Text>
                      </Link>
                      {i < post.attributes.writers.data.length - 1 && ', '}
                    </React.Fragment>
                  ))}
                </Text>
              )}
              <Text color="grey3">
                Published {formatDate(post.attributes.publication_date)}
              </Text>
            </Box>
          </Box>
          <Heading as="h1" color="grey1" fontSize={[5, 5, '30px']} mb={8}>
            {post.attributes.title}
          </Heading>
          {!!post.attributes.tags?.data.length && (
            <Box variant="flex" mt={8}>
              <Tags tags={post.attributes.tags.data} context="blog" />
            </Box>
          )}
          <Box pt={12} mt={12} borderTop="1px solid" borderColor="grey6" position="relative">
            <MarkdownBody
              dangerouslySetInnerHTML={{
                __html: !isOldHtmlPost ? renderedPost : post.attributes.content,
              }}
            />
            {post.attributes.show_contents && (
              <StickyContentsNav
                title="In this post"
                content={post.attributes.content}
              />
            )}
          </Box>
          <Box pt={12} mt={12} borderTop="1px solid" borderColor="grey6">
            <Box>
              <PreHeading mb={6}>
                Share this article with your network
              </PreHeading>
            </Box>
            <ShareLink
              href={`https://x.com/intent/tweet?text=${shareText}&url=https://northflank.com${pathname}`}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Share to X"
            >
              <X size={24} />
            </ShareLink>
            <ShareLink
              href={`https://www.facebook.com/sharer/sharer.php?t=${shareText}&u=https://northflank.com${pathname}`}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Share to Facebook"
            >
              <Facebook size={32} />
            </ShareLink>
            <ShareLink
              href={`https://www.linkedin.com/shareArticle?mini=true&text=${shareText}&url=https://northflank.com${pathname}`}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Share to LinkedIn"
            >
              <Linkedin size={32} />
            </ShareLink>
          </Box>
          {!!related?.length && (
            <Box pt={12} mt={12} borderTop="1px solid" borderColor="grey6">
              <PreHeading mb={8}>Related posts</PreHeading>
              <Grid
                gridTemplateColumns={['1fr', '1fr', '1fr 1fr']}
                gridGap={12}
              >
                {related.map((post) => (
                  <BlogCard
                    key={post.id}
                    image={post.attributes.header?.data?.attributes.url}
                    authors={post.attributes.writers?.data}
                    published={post.attributes.publication_date}
                    title={post.attributes.title}
                    excerpt={post.attributes.excerpt}
                    tags={post.attributes.tags?.data}
                    link={`/blog/${post.attributes.slug}`}
                  />
                ))}
              </Grid>
            </Box>
          )}
          {!!newerPosts.length > 0 && (
            <Box pt={12} mt={12} borderTop="1px solid" borderColor="grey6">
              <PreHeading mb={6}>Also from the blog</PreHeading>
              <ul style={{ listStyle: 'none' }}>
                {newerPosts?.map((newPost) => (
                  <li key={newPost.id}>
                    <Box display="flex" alignItems="center" mb={2}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color="docs.textLight"
                        width="18px"
                        height="18px"
                        mr={6}
                      >
                        <File size={18} />
                      </Box>
                      <Link
                        color="primary"
                        fontSize="16px"
                        to={`/blog/${newPost.attributes.slug}`}
                        css={{ textDecoration: 'none' }}
                      >
                        {newPost.attributes.title}
                      </Link>
                    </Box>
                  </li>
                ))}
              </ul>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default BlogPost;
