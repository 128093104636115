import React, { useEffect, useState } from 'react';

import { ChevronDown } from '@styled-icons/boxicons-regular/ChevronDown';
import { ChevronUp } from '@styled-icons/boxicons-regular/ChevronUp';
import css from '@styled-system/css';
import styled from 'styled-components';
import { layout } from 'styled-system';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Checkbox from '@northflank/components/Checkbox';
import FieldLabel from '@northflank/components/FieldLabel';
import Grid from '@northflank/components/Grid';
import Icon from '@northflank/components/Icon';
import Input from '@northflank/components/Input';
import Text from '@northflank/components/Text';
import Tooltip from '@northflank/components/Tooltip';
import useOnClickOutside from '@northflank/utilities/hooks/useOnClickOutside';
import withComponentId from '@northflank/utilities/withComponentId';

const DropdownButton = styled.button(layout, ({ theme, expanded, error }) =>
  css({
    appearance: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    bg: 'grey9',
    color: 'grey1',
    px: 7,
    py: 6,
    border: '1px solid',
    borderColor: error ? 'danger' : expanded ? 'primary' : 'grey5',
    borderRadius: 1,
    fontFamily: 'body',
    fontSize: 1,
    cursor: 'pointer',
    minWidth: '165px',
    minHeight: '36px',
    '&[disabled]': {
      bg: 'grey9',
      borderColor: 'grey8',
      cursor: 'not-allowed',
      color: 'grey3',
      '-webkit-text-fill-color': theme.colors.grey3,
      opacity: 0.75,
      '&:hover': {
        borderColor: 'grey8',
      },
    },
  })
);

const WrapTooltip = ({ title, children }) =>
  title ? <Tooltip title={title}>{children}</Tooltip> : children;

const Dropdown = ({
  options,
  selected,
  handleSelect,
  allowCustom,
  postfix,
  transform,
  gridTemplateColumns,
  setExpanded,
  max,
  minValidValue,
  minDisabledTooltip,
  maxValidValue,
  maxDisabledTooltip,
  otherOptions,
  dontCloseOnSelect,
  hideCustomValue = true,
  multi,
  zIndex = 5,
  dropdownAlignEnd,
}) => {
  const [useCustom, setUseCustom] = useState(
    allowCustom && !options.includes(selected)
  );
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setHasMounted(true);
    }, 100);
  }, []);

  const dropdown = useOnClickOutside(() => setExpanded(false));

  return (
    <Box
      ref={hasMounted ? dropdown : undefined}
      position="absolute"
      top="100%"
      left={dropdownAlignEnd ? undefined : 0}
      right={dropdownAlignEnd ? 0 : undefined}
      display="inline-block"
      width="auto"
      bg="grey10"
      border="1px solid"
      borderColor="grey6"
      borderRadius={1}
      boxShadow="dropDown"
      zIndex={zIndex}
      p={7}
      mt={6}
    >
      {otherOptions}
      <FieldLabel mb={6}>Options</FieldLabel>
      <Grid gridTemplateColumns={gridTemplateColumns} gridGap={3}>
        {options.map((opt) => {
          const displayVal = transform ? transform(opt) : opt;
          return (
            <WrapTooltip
              key={opt}
              title={
                (opt < minValidValue ? minDisabledTooltip : null) ||
                (opt > maxValidValue ? maxDisabledTooltip : null)
              }
            >
              <Button
                type="button"
                onClick={() => {
                  setUseCustom(false);
                  handleSelect(opt);
                  if (!dontCloseOnSelect && !multi) setExpanded(false);
                }}
                disabled={opt < minValidValue || opt > maxValidValue}
                borderColor={
                  (
                    multi
                      ? Array.from(selected).includes(opt)
                      : opt === selected
                  )
                    ? 'primary'
                    : 'grey5'
                }
                borderRadius={4}
                width="100%"
                py={6}
                px={6}
              >
                {postfix ? (
                  <Box variant="flex" alignItems="baseline">
                    {displayVal}
                    <Text as="span" fontSize={0} ml={3}>
                      {postfix}
                    </Text>
                  </Box>
                ) : (
                  displayVal
                )}
              </Button>
            </WrapTooltip>
          );
        })}
      </Grid>
      {(!hideCustomValue || allowCustom) && (
        <>
          <Box mt={6}>
            <WrapTooltip
              title={
                !allowCustom &&
                'This is not currently available for your account'
              }
            >
              <Checkbox
                label="Use a custom value"
                inputProps={{
                  checked: useCustom,
                  onChange: () => setUseCustom((v) => !v),
                }}
                disabled={!allowCustom}
                mt={6}
              />
            </WrapTooltip>
          </Box>
          {useCustom && allowCustom && (
            <Box mt={7}>
              <Input
                type="number"
                label={`Custom value (max. ${max})`}
                value={selected}
                onChange={(e) => handleSelect(parseInt(e.target.value))}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

const getDisplayValue = (selected, transform, multi) => {
  if (multi) {
    const selectedValues = Array.from(selected);
    if (!selectedValues.length) return '—';
    return selectedValues
      .map((val) => (typeof transform === 'function' ? transform(val) : val))
      .join(',');
  }
  if (!isNaN(selected))
    return typeof transform === 'function' ? transform(selected) : selected;
  return null;
};

const NumberSelector = ({
  uid,
  label,
  options,
  selected,
  handleSelect,
  min,
  max,
  minValidValue,
  minDisabledTooltip,
  maxValidValue,
  maxDisabledTooltip,
  allowCustom,
  error,
  postfix,
  dropdownPostfix,
  transform,
  gridTemplateColumns = 'repeat(6, 32px)',
  otherOptions,
  dontCloseOnSelect,
  disabled,
  buttonProps,
  hideCustomValue,
  multi,
  initialValue,
  dropdownZIndex,
  dropdownAlignEnd,
  ...rest
}) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (!multi && parseInt(selected) > max) handleSelect(max);
    if (!multi && parseInt(selected) < min) handleSelect(min);
  }, [selected]);

  return (
    <Box position="relative" {...rest}>
      <DropdownButton
        id={uid && `button-${uid}`}
        type="button"
        onClick={() => setExpanded((e) => !e)}
        expanded={expanded}
        disabled={disabled}
        error={!!error}
        {...buttonProps}
      >
        <Box variant="flex" alignItems="center" mr={6}>
          <Text as="span" color="grey3" mr={6}>
            {label}
          </Text>
          {getDisplayValue(selected, transform, multi)}
          {(dropdownPostfix || postfix) && (
            <Text as="span" fontSize={0} ml={3}>
              {dropdownPostfix || postfix}
            </Text>
          )}
        </Box>
        <Icon Content={expanded ? ChevronUp : ChevronDown} Size={16} />
      </DropdownButton>
      {expanded && (
        <Dropdown
          options={options}
          selected={selected}
          handleSelect={handleSelect}
          allowCustom={allowCustom}
          postfix={postfix}
          transform={transform}
          gridTemplateColumns={gridTemplateColumns}
          setExpanded={setExpanded}
          max={max}
          minValidValue={minValidValue}
          minDisabledTooltip={minDisabledTooltip}
          maxValidValue={maxValidValue}
          maxDisabledTooltip={maxDisabledTooltip}
          otherOptions={otherOptions}
          dontCloseOnSelect={dontCloseOnSelect}
          hideCustomValue={hideCustomValue}
          multi={multi}
          zIndex={dropdownZIndex}
          dropdownAlignEnd={dropdownAlignEnd}
        />
      )}
      {error && (
        <Text fontSize={0} color="danger" mt={5}>
          {error}
        </Text>
      )}
    </Box>
  );
};

export default withComponentId(NumberSelector);
