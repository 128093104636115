import React, { useContext } from 'react';

import { AddToQueue } from '@styled-icons/boxicons-regular/AddToQueue';
import { Brush } from '@styled-icons/boxicons-regular/Brush';
import { Cloud } from '@styled-icons/boxicons-regular/Cloud';
import { CodeAlt } from '@styled-icons/boxicons-regular/CodeAlt';
import { Cog } from '@styled-icons/boxicons-regular/Cog';
import { Customize } from '@styled-icons/boxicons-regular/Customize';
import { HardHat } from '@styled-icons/boxicons-regular/HardHat';
import { History } from '@styled-icons/boxicons-regular/History';
import { LockAlt } from '@styled-icons/boxicons-regular/LockAlt';
import { PackageIcon } from '@styled-icons/boxicons-regular/PackageIcon';
import { Rocket } from '@styled-icons/boxicons-regular/Rocket';
import { Trip } from '@styled-icons/boxicons-regular/Trip';
import { UserCircle } from '@styled-icons/boxicons-regular/UserCircle';
import { Wrench } from '@styled-icons/boxicons-regular/Wrench';
import { Dashboard } from '@styled-icons/boxicons-solid/Dashboard';
import { ThemeContext } from 'styled-components';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Grid from '@northflank/components/Grid';
import Heading from '@northflank/components/Heading';
import Icon from '@northflank/components/Icon';
import PrimaryFeatureBlock from '@northflank/components/marketing/PrimaryFeatureBlock';
import SEO from '@northflank/components/marketing/SEO';
import { TestimonialHeader } from '@northflank/components/marketing/Testimonial';
import Text from '@northflank/components/Text';

import DemoVideo from '../../components/DemoVideo';
import DevExpSection from '../../components/DevExpSection';
import FancyQuote from '../../components/FancyQuote';
import GetStartedCta from '../../components/GetStartedCta';
import HeroHeading from '../../components/HeroHeading';
import LargeCarousel from '../../components/LargeCarousel';
import PreHeading from '../../components/PreHeading';
import StatsSection from '../../components/StatsSection';
import texture from '../../components/Texture';
import topog from '../../components/Topog';
import UseCasesFooterLinks from '../../components/UseCasesFooterLinks';
import KubernetesDiagram from '../../svg/KubernetesDiagram.svg';

const AppPlatformForKubernetes = () => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <SEO
        title="App platform for Kubernetes — Use cases"
        description="Our developer platform comes with batteries included. We make Kubernetes easy, so you can focus on shipping software."
      />

      <Box
        background={`radial-gradient(60% 100% at 70% 50%, rgba(0, 147, 255, 0.1) 0%, #151C28 100%),
        radial-gradient(circle at 70% 50%, rgba(0, 0, 0, 0) 0%, #151C28 100%),
         ${topog}`}
        borderBottom="1px solid"
        borderColor="grey5"
        mt="-80px"
        pt="80px"
      >
        <Box variant="bounding">
          <Grid
            gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
            alignItems="center"
            gridGap={12}
          >
            <Box>
              <HeroHeading
                as="h1"
                fontSize={[6, 6, 7]}
                color="grey1"
                mb={8}
                style={{ letterSpacing: 'normal', lineHeight: 1.2 }}
              >
                App platform for Kubernetes: Make K8s work for your devs
              </HeroHeading>
              <Text
                fontSize={[5, 5, 6]}
                color="grey2"
                mb={[12, 12, 13]}
                css={{ lineHeight: 1.2 }}
              >
                Our developer platform comes with batteries included. We make
                Kubernetes easy, so you can focus on shipping software.
              </Text>
              <Text fontSize={4} color="grey2" mb={[12, 12, 13]}>
                Developer experience matters. With Northflank, get the power of
                Kubernetes without the complexity. Build, deploy, and scale
                applications effortlessly—no Kubernetes expertise required.
              </Text>
              <Grid
                gridTemplateColumns={['1fr', '1fr', 'repeat(2, max-content)']}
                gridGap={6}
                mb={6}
              >
                <a href="https://app.northflank.com/signup">
                  <Button
                    variant={['primary', 'large', 'noWrap']}
                    width={['100%', '100%', 'auto']}
                  >
                    <Icon Content={Cloud} Size={16} mr={4} />
                    Deploy apps now
                  </Button>
                </a>
                <DemoVideo buttonProps={{ width: ['100%', '100%', 'auto'] }} />
              </Grid>
              <Text color="grey3">
                Prefer a live walkthrough?{' '}
                <a
                  href="https://cal.com/team/northflank/northflank-demo?duration=30"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Schedule a demo &rarr;
                </a>
              </Text>
            </Box>
            <Box width="100%">
              <KubernetesDiagram />
            </Box>
          </Grid>
        </Box>
      </Box>

      <Box overflow="hidden">
        <Box variant="bounding">
          <Box position={['static', 'static', 'relative']} mb={[13, 13, 14]}>
            <Box
              position={['relative', 'relative', 'absolute']}
              top={0}
              left={[0, 0, '50%']}
              width="100%"
              height="100%"
              zIndex={-1}
              overflow="hidden"
              _css={{ aspectRatio: ['16 / 12', '16 / 12', 'unset'] }}
            >
              <Box
                position="absolute"
                top={0}
                bottom={0}
                left={0}
                right={0}
                background={[
                  `linear-gradient(to bottom, transparent 0%, transparent 60%, ${theme.colors.background} 90%), linear-gradient(to right, transparent 0%, transparent 30%, ${theme.colors.background} 100%)`,
                  `linear-gradient(to bottom, transparent 0%, transparent 60%, ${theme.colors.background} 90%), linear-gradient(to right, transparent 0%, transparent 30%, ${theme.colors.background} 100%)`,
                  `linear-gradient(to bottom, transparent 0%, transparent 60%, ${theme.colors.background} 95%), linear-gradient(to right, transparent 0%, transparent 30%, ${theme.colors.background} 50%)`,
                ]}
                backgroundSize="cover"
                zIndex={-1}
              />
              <Box
                as="img"
                src="/images/solutions/use-cases/cluster.png"
                position="absolute"
                top="10px"
                left={['10px', '10px', '60px']}
                width={['auto', 'auto', '1400px']}
                height={['1000px', '1000px', 'auto']}
                border="1px solid"
                borderColor="grey5"
                borderRadius={3}
                boxShadow="dropDown"
                zIndex={-2}
              />
            </Box>
            <Box width={[1, 1, 1 / 2]}>
              <PreHeading mb={6}>Why Developers Love Northflank</PreHeading>
              <Heading fontSize={[5, 5, '32px']} color="grey1" mb={8}>
                A complete app platform on Kubernetes
              </Heading>
              <Text fontSize="18px" color="grey2" mb={[12, 12, 13]}>
                Get the simplicity of a PaaS with the power and flexibility of
                Kubernetes. Northflank unifies your development workflow,
                letting you move fast on any cloud without the usual headaches.
              </Text>
              <PrimaryFeatureBlock
                subFeatures={[
                  {
                    Icon: Wrench,
                    heading: 'Build vs buy',
                    text: 'Be productive immediately. Northflank abstracts the cloud with higher-level primitives, helping you sidestep the risks of DIY. Get a head start with ready-to-go solutions for CI/CD, deployments, observability, and more.',
                  },
                  {
                    Icon: Cloud,
                    heading: 'Move fast on any cloud',
                    text: 'Deploy a container platform in your cloud account in 30 minutes or less. Northflank seamlessly integrates with EKS, GKE, AKS, and most flavors of Kubernetes.',
                  },
                  {
                    Icon: CodeAlt,
                    heading: 'Language agnostic',
                    text: 'Build and deploy anything with a Dockerfile or Buildpack. No matter your language or framework, just add a Dockerfile and deploy as a Northflank service.',
                  },
                  {
                    Icon: UserCircle,
                    heading: 'Exceptional developer experience',
                    text: 'Give your teams the best developer experience. Northflank eliminates the learning curve of Kubernetes and YAML manifests, so you can focus on coding.',
                  },
                ]}
                subFeaturesGrid="1fr"
                subFeaturesGap={[12, 12, 13]}
                subFeaturesAlign="stretch"
              />
            </Box>
          </Box>

          <FancyQuote
            body={
              <>
                Northflank is way easier than gluing a bunch of tools together
                to spin up apps and databases. It’s the ideal platform to deploy
                containers in our cloud account, avoiding the brain damage of
                big cloud and Kubernetes. It’s more powerful and flexible than
                traditional PaaS – all within our VPC.{' '}
                <Text as="span" color="success" fontWeight={500}>
                  Northflank has become a go-to way to deploy workloads at
                  Sentry
                </Text>
                .
              </>
            }
            attribution={
              <TestimonialHeader
                name="David Cramer"
                position="Co-Founder and CPO @ Sentry"
                avatar="/images/landing/quotes/david-c.jpeg"
                linkedin="https://www.linkedin.com/in/dmcramer/"
                mb={0}
              />
            }
          />
        </Box>
      </Box>

      <Box background="linear-gradient(to bottom, #141c28 0%, #192338 30%, #192338 70%, #141c28 100%)">
        <Box variant="bounding" py={[12, 12, '120px !important']}>
          <Box width={[1, 1, 1 / 2]} mb={[12, 12, 13]}>
            <PreHeading mb={6}>Streamline Your Deployment Pipeline</PreHeading>
            <Heading fontSize={[5, 5, '32px']} color="grey1" mb={8}>
              From Git to production in no time
            </Heading>
            <Text fontSize="18px" color="grey2">
              You don’t have to be a Kubernetes expert to use Kubernetes like
              one. Northflank automates your deployment pipeline, so you can
              focus on building great software.
            </Text>
          </Box>
          <PrimaryFeatureBlock
            subFeatures={[
              {
                Icon: Trip,
                heading: 'Build golden paths',
                text: 'Pipelines and automatic preview environments are your golden path to production. Codify your workflows and graduation policies, storing them in Northflank or Git. Automatically collect logs and metrics on every workload.',
              },
              {
                Icon: Rocket,
                heading: 'Fast builds for faster feedback',
                text: 'Snappy container builds, preview environments and release workflows speed up feedback cycles for stakeholders, QA, and code reviewers.',
              },
              {
                Icon: Dashboard,
                heading: 'Environment dashboard',
                text: 'Monitor the state of all your resources with pipeline overviews, health status, and alerts. Segment your resources into projects or development, staging, and production environments.',
              },
              {
                Icon: Brush,
                heading: 'Clean up dangling resources automatically',
                text: (
                  <Text color="grey2" fontSize={3}>
                    Easily spin up and down clusters with auto-scaling and
                    templates. Automatically provision{' '}
                    <a
                      target="_blank"
                      href="/use-cases/preview-environments-backend-for-kubernetes"
                    >
                      preview environments
                    </a>{' '}
                    on commit. Avoid dangling resources with fine-grained usage
                    and billing data and easily configurable retention periods
                    for resources and backups.
                  </Text>
                ),
              },
            ]}
            subFeaturesGrid={['1fr', '1fr', 'repeat(2, 1fr)']}
            subFeaturesGap={[12, 12, 13]}
            subFeaturesAlign="stretch"
          />
        </Box>
      </Box>

      <Box variant="bounding">
        <Box width={[1, 1, 1 / 2]} mb={[12, 12, 13]}>
          <PreHeading mb={6}>Security and Reliability at Core</PreHeading>
          <Heading fontSize={[5, 5, '32px']} color="grey1" mb={8}>
            Guard rails & security
          </Heading>
          <Text fontSize="18px" color="grey2">
            Focus on innovation while we handle the heavy lifting of security
            and reliability.
          </Text>
        </Box>
        <PrimaryFeatureBlock
          subFeatures={[
            {
              Icon: History,
              heading: 'Easy rollbacks',
              text: 'Push to production with confidence, knowing you can roll back any release in a matter of seconds.',
            },
            {
              Icon: LockAlt,
              heading: 'Secure secrets management',
              text: 'Securely store your secrets outside of GitOps using Northflank’s secure engine to inject variables and arguments at runtime.',
            },
            {
              Icon: PackageIcon,
              heading: 'Beyond provisioning',
              text: 'Traditional IaC stops at provisioning and ignores your apps. Northflank handles infrastructure and extends codification to pipelines and apps. Manage everything from one flexible interface.',
            },
            {
              Icon: Cog,
              heading: 'Robust foundations',
              text: 'As an engineering team, you need the best foundation for building and deploying apps on Kubernetes. Northflank provides that foundation with all the controls needed for complex applications.',
            },
          ]}
          subFeaturesGrid={['1fr', '1fr', 'repeat(2, 1fr)']}
          subFeaturesGap={[12, 12, 13]}
          subFeaturesAlign="stretch"
        />
      </Box>

      <Box background="linear-gradient(to bottom, #141c28 0%, #192338 30%, #192338 70%, #141c28 100%)">
        <Box variant="bounding" py={[12, 12, '120px !important']}>
          <DevExpSection />
        </Box>
      </Box>

      <Box variant="bounding">
        <StatsSection />
      </Box>

      <Box
        background={`linear-gradient(to bottom, #141c28 0%, transparent 30%, #192338 70%, #141c28 100%), ${texture}, linear-gradient(to bottom, #141c28 0%, #192338 30%, #192338 70%, #141c28 100%)`}
      >
        <Box variant="bounding" py={[12, 12, '120px !important']}>
          <Grid
            gridTemplateColumns={['1fr', '1fr', '1fr 1.2fr']}
            gridGap={[12, 12, 13]}
            mb={[12, 12, 13]}
          >
            <Box>
              <PreHeading mb={6}>What’s Holding You Back?</PreHeading>
              <Heading fontSize={[5, 5, '32px']} color="grey1" mb={8}>
                Challenges of building an app platform on Kubernetes
              </Heading>
              <Text fontSize="18px" color="grey2">
                Building your own platform can be a daunting task. From the
                complexities of Kubernetes to the pitfalls of DIY solutions,
                many obstacles can impede your progress.
              </Text>
            </Box>
            <Box as="img" src="/images/landing/k8s.png" width="100%" />
          </Grid>
          <Box mb={[12, 12, 13]}>
            <PrimaryFeatureBlock
              subFeatures={[
                {
                  Icon: AddToQueue,
                  heading: 'Limitations of traditional PaaS',
                  text: 'Many PaaS solutions are great for getting started but lack scalability. As you grow, you need more control and flexibility without sacrificing the developer experience.',
                },
                {
                  Icon: CodeAlt,
                  heading: 'Any language, any framework',
                  text: 'Your platform is only as powerful as the workloads it supports. Northflank is truly a polyglot, supporting all languages and frameworks. Not just Next.js. Not just MySQL. Not just AWS.',
                },
                {
                  Icon: HardHat,
                  heading: 'Avoid the DIY trap',
                  text: 'Building an Internal Developer Platform from scratch can take years. You need to work at a higher level to deliver value quickly and avoid getting stuck in the complexities of infrastructure.',
                },
                {
                  Icon: Customize,
                  heading: 'Your platform is a product',
                  text: `When you build a platform, you're delivering a product to developers. Balancing abstraction with control is a major design challenge. Additionally, DIY platforms come with other difficulties: ensuring great developer experience (DX), customer success, developer adoption, multi-tenancy, security, and more.`,
                },
              ]}
              subFeaturesGrid={['1fr', '1fr', 'repeat(2, 1fr)']}
              subFeaturesGap={[12, 12, 13]}
              subFeaturesAlign="stretch"
            />
          </Box>
          <FancyQuote
            body={
              <>
                Northflank is the first batteries-included developer platform
                that doesn’t suffer from the invisible ceilings that hover over
                its competitors.{' '}
                <Text as="span" color="success" fontWeight={500}>
                  We could have built all of Slack with Northflank
                </Text>{' '}
                – and we would have, had it been available.
              </>
            }
            attribution={
              <TestimonialHeader
                name="Keith Adams"
                position="Formerly Chief Architect Slack, Facebook, VMware, GP @ Pebblebed"
                avatar="/images/landing/quotes/keith.jpeg"
                linkedin="https://linkedin.com/in/keith-adams-pb/"
                mb={0}
              />
            }
          />
        </Box>
      </Box>

      <Box py={[12, 12, '96px']}>
        <LargeCarousel
          images={[
            '/images/solutions/use-cases/backupschedule.png',
            '/images/solutions/use-cases/cluster.png',
            '/images/solutions/use-cases/clusterobserve.png',
            '/images/solutions/use-cases/dashboard.png',
            '/images/solutions/use-cases/jobruns.png',
            '/images/solutions/use-cases/logs.png',
            '/images/solutions/use-cases/metrics.png',
            '/images/solutions/use-cases/preview.png',
            '/images/solutions/use-cases/previewduration.png',
            '/images/solutions/use-cases/releaseflow.png',
          ]}
        />
      </Box>

      <Box variant="bounding">
        <UseCasesFooterLinks mb={[12, 12, 13]} />
        <GetStartedCta />
      </Box>
    </>
  );
};

export default AppPlatformForKubernetes;
