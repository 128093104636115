import moment from 'moment';

export const instanceRunning = (instance) => {
  return !(
    instance?.status === 'TASK_FINISHED' ||
    instance?.status === 'TASK_FAILED' ||
    instance?.status === 'TASK_KILLED'
  );
};

export const getPodStartTime = (instance) => instance.createdAt;
export const getPodEndTime = (instance) =>
  instanceRunning(instance) ? moment().utc().unix() : instance.updatedAt;
