import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import '@northflank/components/marketing/util/line-numbers';

import { Facebook } from '@styled-icons/boxicons-logos/Facebook';
import { Linkedin } from '@styled-icons/boxicons-logos/Linkedin';
import { File } from '@styled-icons/boxicons-regular/File';
import css from '@styled-system/css';
import styled from 'styled-components';

import Box from '@northflank/components/Box';
import Heading from '@northflank/components/Heading';
import MarkdownBody from '@northflank/components/marketing/MarkdownBody';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';
import X from '@northflank/images/icons/X';

import PreHeading from '../../components/PreHeading';
import formatDate from '../../utils/formatDate';
import useSyntaxHighlighting from '../../utils/useSyntaxHighlighting';
import { ResourcesNav } from '../blog';

export const BackLink = styled.span(() =>
  css({
    fontSize: 3,
    mb: 10,
    color: 'grey3',
    display: 'inline-block',
    textDecoration: 'none',
  })
);

export const ArticleImage = styled(Box)(({ src, width = '100%' }) =>
  css({
    width,
    borderRadius: 2,
    boxShadow: 'dropDown',
    backgroundImage: `url(${src})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: 0,
    paddingBottom: Array.isArray(width)
      ? width.map((w) => `calc((9 / 16) * ${w})`)
      : `calc((9 / 16) * ${width})`,
  })
);

export const ShareLink = styled.a(() =>
  css({
    color: 'grey2',
    textDecoration: 'none',
    mr: 6,
    '&:hover': {
      color: 'grey0',
    },
  })
);

const ChangelogPost = ({ post, renderedPost, isOldHtmlPost, newerPosts }) => {
  const { pathname } = useLocation();

  useSyntaxHighlighting([post.attributes.slug]);

  const shareText = encodeURIComponent(
    `Northflank changelog — ${post.attributes.title}`
  );

  return (
    <>
      <SEO
        title={`${post.attributes.title} — Changelog`}
        description={post.attributes.excerpt}
        image={post.attributes.header?.data?.attributes.url}
      />
      <Box variant="bounding">
        <Box maxWidth="750px" mx="auto">
          <ResourcesNav />
          <Link to="/changelog">
            <BackLink>&larr; Back to Changelog</BackLink>
          </Link>
          <Box mb={10}>
            <ArticleImage
              src={post.attributes.header?.data?.attributes.url}
              alt={`Header image for changelog: ${post.attributes.title}`}
              width={['100%', '100%', '100%', '125%']}
              ml={[0, 0, 0, 'calc(((125% - 100%) / 2) * -1)']}
            />
          </Box>
          <Text color="grey3" fontSize={3} mb={6}>
            {formatDate(post.attributes.publication_date)}
          </Text>
          <Heading as="h1" color="grey1" fontSize={[5, 5, '30px']} mb={12}>
            {post.attributes.title}
          </Heading>

          <MarkdownBody
            dangerouslySetInnerHTML={{
              __html: !isOldHtmlPost ? renderedPost : post.attributes.content,
            }}
          />
          <Box pt={12} mt={12} borderTop="1px solid" borderColor="grey6">
            <Box>
              <PreHeading mb={6}>
                Share this article with your network
              </PreHeading>
            </Box>
            <ShareLink
              href={`https://x.com/intent/tweet?text=${shareText}&url=https://northflank.com${pathname}`}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Share to X"
            >
              <X size={24} />
            </ShareLink>
            <ShareLink
              href={`https://www.facebook.com/sharer/sharer.php?t=${shareText}&u=https://northflank.com${pathname}`}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Share to Facebook"
            >
              <Facebook size={32} />
            </ShareLink>
            <ShareLink
              href={`https://www.linkedin.com/shareArticle?mini=true&text=${shareText}&url=https://northflank.com${pathname}`}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Share to LinkedIn"
            >
              <Linkedin size={32} />
            </ShareLink>
          </Box>
          {!!newerPosts.length > 0 && (
            <Box pt={12} mt={12} borderTop="1px solid" borderColor="grey6">
              <PreHeading mb={6}>Next product developments</PreHeading>
              <ul style={{ listStyle: 'none' }}>
                {newerPosts?.map((newPost) => (
                  <li key={newPost.id}>
                    <Box display="flex" alignItems="center" mb={2}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color="docs.textLight"
                        width="18px"
                        height="18px"
                        mr={6}
                      >
                        <File size={18} />
                      </Box>
                      <Link
                        color="primary"
                        fontSize="16px"
                        to={`/changelog/${newPost.attributes.slug}`}
                        css={{ textDecoration: 'none' }}
                      >
                        {newPost.attributes.title}
                      </Link>
                    </Box>
                  </li>
                ))}
              </ul>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ChangelogPost;
