import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const defaultDescription =
  'Northflank is the comprehensive developer platform designed for scale. Seamlessly deploy and release any project on our cloud or in yours.';

const SEO = ({ title, description, image, noTitleTemplate, linkTags }) => {
  const { pathname } = useLocation();
  return (
    <Helmet prioritizeSeoTags>
      <title>
        {title
          ? noTitleTemplate
            ? title
            : `${title} — Northflank`
          : 'Northflank — Deploy any project in seconds, in our cloud or yours.'}
      </title>
      <meta
        property="og:title"
        content={
          title
            ? noTitleTemplate
              ? title
              : `${title} — Northflank`
            : 'Northflank — Deploy any project in seconds, in our cloud or yours.'
        }
      />
      <meta name="description" content={description || defaultDescription} />
      <meta
        property="og:description"
        content={description || defaultDescription}
      />
      <meta
        property="og:site_name"
        content="Northflank — Deploy any project in seconds, in our cloud or yours."
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`https://northflank.com${pathname}`} />
      <meta
        property="og:image"
        content={
          image || 'http://assets.northflank.com/marketing/image/meta-1200.png'
        }
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:image"
        content={
          image || 'http://assets.northflank.com/marketing/image/meta-1200.png'
        }
      />
      <meta name="twitter:site" content="@northflank" />
      <meta name="twitter:creator" content="@northflank" />
      {Array.isArray(linkTags) && linkTags?.length && (
        <>
          {linkTags.map((attributes, i) =>
            typeof attributes === 'object' ? (
              <link key={`link-tag-${i}`} {...attributes} />
            ) : null
          )}
        </>
      )}
      {(!Array.isArray(linkTags) ||
        !linkTags?.some((link) => link.rel === 'canonical')) && (
        <link rel="canonical" href={`https://northflank.com${pathname}`} />
      )}
    </Helmet>
  );
};

export default SEO;
