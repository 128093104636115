export enum DBProjectMigrationTrackerSteps {
  CHECK_LB_CONFLICTS = 'checkLbConflicts',
  SET_MIGRATING = 'setMigrating',
  ITEMS_RETRIEVED = 'itemsRetrieved',
  ITEMS_PREPARED = 'itemsPrepared',
  SET_BILLING = 'setBilling',
  UPDATED_CLUSTER_ID = 'updatedClusterId',

  SET_CUSTOM_DNS_CONFIG = 'setCustomDnsConfig',
  ITEMS_MIGRATED = 'itemsMigrated',
  LB_PORTS_MIGRATED = 'lbPortsMigrated',
  SET_CREATED = 'setCreated',
  NAMESPACE_DELETED = 'namespaceDeleted',
}

export enum DBJobTrackerSteps {
  PAUSED = 'paused',
  RESUMED = 'resumed',
}

export enum DBAddonTrackerSteps {
  MIGRATABLE = 'migratable',
  IGNORED = 'ignored',
  PAUSED = 'paused',
  PREPARE_SNAPSHOT_CRDS = 'prepare-snapshot_crds',
  VOLUMES = 'volumes',
  BACKUP_MIGRATION = 'backup_migration',
  DNS = 'dns',
  REDIRECT_CREATION = 'redirect_creation',
  RESUMED = 'resumed',
  BACKUP_INITIATED = 'backup_initiated',

  BACKUP_COMPLETED = 'backup_completed',

  VOLUME_BILLING_ANNOTATIONS = 'modified_volume_billing_annotation',
  VOLUME_BILLING_ENTRIES = 'modified_volume_billing_entries',

  SNAPSHOTS_DELETED = 'snapshots_deleted',
}

export enum DBVolumeDataTrackerSteps {
  MIGRATABLE = 'migratable',
  CONSUMER_POD_STARTED = 'consumer_started',
  BACKUP_INITIATED = 'backup_initiated',
  BACKUP_COMPLETED = 'backup_completed',
  TARGET_BACKUP_AVAILABLE = 'target_backup_available',
  TARGET_RESOURCES_CREATED = 'target_resources_created',
  RESTORE_INITIATED = 'restore_initiated',
  RESTORE_COMPLETED = 'restore_completed',
  CONSUMER_POD_CLEANED_UP = 'consumer_pod_cleaned_up',
}

export enum DBSnapshotDataTrackerSteps {
  SNAPSHOTS_RESTORED = 'snapshotsRestored',
  DATA_MIGRATED = 'dataMigrated',
  SNAPSHOTS_TAKEN = 'snapshotsTaken',
  DISKS_DELETED = 'disksDeleted',
}

export enum DBVolumeTrackerSteps {
  MOVED = 'moved',
  VOLUME_BILLING_ANNOTATIONS = 'modified_volume_billing_annotation',
  VOLUME_BILLING_ENTRIES = 'modified_volume_billing_entries',
}

export enum DBServiceTrackerSteps {
  STATUS_CLUSTER_ID = 'statusClusterId',
  PAUSED = 'paused',
  VOLUMES = 'volumes',
  DNS = 'dns',
  REDIRECT_CREATION = 'redirect_creation',
  RESUMED = 'resumed',
}
