import React, { useContext, useEffect, useState } from 'react';

import { ThemeContext } from 'styled-components';

import Box from '@northflank/components/Box';
import Marquee from '@northflank/components/marketing/Marquee';

export const logos = [
  { key: 'sentry', link: 'https://sentry.io', alternate: true },
  { key: 'writer', link: 'https://writer.com', alternate: true },
  { key: 'northfield', link: 'https://www.northfieldit.com', alternate: true },
  { key: 'timesplus', link: 'https://www.mytimesplus.co.uk', alternate: true },
  { key: 'quiet', link: 'https://quiet.com', alternate: true },
  { key: 'clock', link: 'https://clock.co.uk' },
  {
    key: 'chaidiscovery',
    link: 'https://www.chaidiscovery.com',
    alternate: true,
  },
  { key: 'dylibso', link: 'https://dylibso.com', alternate: true },
  { key: 'acquire', link: 'https://acquiredigital.com', alternate: true },
  { key: 'kuroro', link: 'https://kuroro.com', alternate: true },
  { key: 'eatwith', link: 'https://www.eatwith.com' },
  { key: 'belive', link: 'https://be.live', alternate: true },
  { key: 'halalbooking', link: 'https://halalbooking.com', alternate: true },
  { key: 'trustflight', link: 'https://www.trustflight.com', alternate: true },
  { key: 'weights', link: 'https://weights.gg' },

  // { key: 'cartographer', link: 'https://cartographer.io', alternate: true },
  // { key: 'bbfc', link: 'https://bbfc.co.uk', alternate: true },
  // { key: 'leicestertigers', link: 'https://www.leicestertigers.com', alternate: true },
  // { key: 'lynqai', link: 'https://lynq.ai' },
  // { key: 'plauti', link: 'https://www.plauti.com' },
  // { key: 'blynksolve', link: 'https://www.blynksolve.com' },
  // { key: 'teaminternet', link: 'https://teaminternet.com', alternate: true },
  // { key: 'krea', link: 'https://krea.ai', alternate: true },
  // { key: 'gparency', link: 'https://gparency.com' },
  // { key: 'salina', link: 'https://www.salina.ai' },
  // { key: 'cedana', link: 'https://www.cedana.ai', alternate: true },
  // { key: 'catalog', link: 'https://catalog.works', alternate: true },
];

export const sortKeyAlpha = (a, b) => {
  if (a.key > b.key) return 1;
  if (a.key < b.key) return -1;
  return 0;
};

const getRandomAlternatingLogos = () => {
  const alt = logos.filter((l) => l.alternate);
  const notAlt = logos.filter((l) => !l.alternate);

  const final = [];
  let lastAlt = true;

  while (final.length < logos.length) {
    const pickFrom = lastAlt ? notAlt : alt;
    const index = Math.floor(Math.random() * pickFrom.length);
    const [el] = pickFrom.splice(index, 1);
    if (el) final.push(el);
    lastAlt = !lastAlt;
  }

  return final;
};

const LogoCarousel = () => {
  const theme = useContext(ThemeContext);

  const [orderedLogos, setOrderedLogos] = useState([]);

  useEffect(() => {
    setOrderedLogos(getRandomAlternatingLogos());
  }, []);

  return (
    <Box
      bg="background"
      border="1px solid"
      borderColor="grey6"
      borderRadius={3}
      position="relative"
      minHeight="110px"
      py={19}
      _css={{
        '&::before, &::after': {
          content: `""`,
          position: 'absolute',
          top: 0,
          display: 'block',
          height: '100%',
          width: '17.5%',
          bg: 'red',
          borderRadius: 3,
          zIndex: 1,
          pointerEvents: 'none',
        },
        '&::before': {
          left: 0,
          background: `linear-gradient(to right, ${theme.colors.background} 0%, transparent 100%)`,
        },
        '&::after': {
          right: 0,
          background: `linear-gradient(to left, ${theme.colors.background} 0%, transparent 100%)`,
        },
      }}
    >
      <Marquee gap="60px" time={`${Object.keys(logos).length * 3}s`}>
        {orderedLogos.map(({ key, link }) => (
          <Box
            key={key}
            as="a"
            href={link}
            target="_blank"
            display="flex"
            _css={{
              img: { transition: '200ms' },
              '&:hover': {
                img: {
                  filter: 'brightness(1.5)',
                },
              },
            }}
          >
            <Box
              as="img"
              src={`/images/landing/logos/${key}.svg`}
              alt={`${key} logo`}
              maxWidth={['120px', '120px', '140px']}
              maxHeight="70px"
            />
          </Box>
        ))}
      </Marquee>
    </Box>
  );
};

export default LogoCarousel;
