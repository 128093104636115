import React, { useMemo } from 'react';

import get from 'lodash/get';

import Box from '@northflank/components/Box';
import Text from '@northflank/components/Text';
import { capitalize } from '@northflank/constants-base/functions';

import OciNote from '../OciNote';
import CloudProviderTableNode from '../TableComponents/CloudProviderTableNode';
import CloudProviderTableType from '../TableComponents/CloudProviderTableType';

const getTableColumns = (providerId) => {
  const cols = [];

  cols.push({
    Header: 'Node type',
    accessor: 'id',
    Cell: ({ value, row: { original } }) => (
      <CloudProviderTableNode
        providerId={providerId}
        value={value}
        original={original}
      />
    ),
    gridWidth: '3fr',
  });

  if (providerId !== 'civo' && providerId !== 'northflank') {
    cols.push({
      Header: 'Family',
      accessor: 'family',
      gridWidth: '1.5fr',
    });
  }

  if (providerId === 'oci') {
    cols.push(
      {
        Header: 'vCPU',
        accessor: 'resources.vcpu',
        Cell: ({ value, row: { original } }) => {
          if (original?.oci?.isFlex) {
            return `${original.resources.vcpuMin % 1 === 0 ? original.resources.vcpuMin : original.resources.vcpuMin.toFixed(2)} - ${original.resources.vcpuMax % 1 === 0 ? original.resources.vcpuMax : original.resources.vcpuMax.toFixed(2)} vCPU`;
          }
          return `${value % 1 === 0 ? value : value.toFixed(2)} vCPU`;
        },
        gridWidth: '1fr',
      },
      {
        Header: 'Memory',
        accessor: 'resources.memory',
        Cell: ({ value, row: { original } }) => {
          if (original?.oci?.isFlex) {
            return `${original.resources.memoryMin % 1 === 0 ? original.resources.memoryMin : original.resources.memoryMin.toFixed(1)} - ${original.resources.memoryMax % 1 === 0 ? original.resources.memoryMax : original.resources.memoryMax.toFixed(1)} GB`;
          }
          return `${value % 1 === 0 ? value : value.toFixed(1)} GB`;
        },
        gridWidth: '1fr',
      }
    );
  } else {
    cols.push(
      {
        Header: 'vCPU',
        accessor: 'resources.vcpu',
        Cell: ({ value }) =>
          `${value % 1 === 0 ? value : value.toFixed(2)} vCPU`,
        gridWidth: '1fr',
      },
      {
        Header: 'Memory',
        accessor: 'resources.memory',
        Cell: ({ value }) => `${value % 1 === 0 ? value : value.toFixed(1)} GB`,
        gridWidth: '1fr',
      }
    );
  }

  if (
    providerId !== 'gcp' &&
    providerId !== 'northflank' &&
    providerId !== 'oci'
  ) {
    cols.push({
      Header: 'Disk',
      accessor: 'resources.disk',
      Cell: ({ value }) => {
        if (value?.size) {
          return `${parseFloat(value.size.toPrecision(1))} GB${value.disk ? ` ${value.disk}` : ''}`;
        }
        return <Text color="grey3">—</Text>;
      },
      gridWidth: '1fr',
    });
  }

  if (providerId !== 'civo' && providerId !== 'northflank') {
    cols.push(
      {
        Header: 'Processor',
        accessor: 'processorFamily',
        Cell: ({ value }) =>
          value ? `${value === 'amd' ? 'AMD' : capitalize(value)}` : '',
        gridWidth: '1fr',
      },
      {
        Header: 'Type',
        accessor: 'workloadType',
        Cell: ({ value, row: { original } }) => (
          <CloudProviderTableType value={value} original={original} />
        ),
        gridWidth: '2.5fr',
      }
    );
  }

  return cols;
};

export const TableRow = ({ item, columns }) => {
  return (
    <Box
      as="li"
      display="grid"
      gridTemplateColumns={columns.map((col) => col.gridWidth).join(' ')}
      gridGap={6}
      alignItems="center"
      borderBottom="1px solid"
      borderBottomColor="grey6"
      px={8}
      py={7}
    >
      {columns.map((col) => {
        const value = get(item, col.accessor);
        if (col.Cell)
          return (
            <div key={col.accessor}>
              <col.Cell value={value} row={{ original: item }} />
            </div>
          );
        return <div key={col.accessor}>{value}</div>;
      })}
    </Box>
  );
};

const CloudProviderNodeTable = ({ providerId, nodeTypes, ...rest }) => {
  const columns = useMemo(() => {
    return getTableColumns(providerId);
  }, [providerId, nodeTypes]);

  return (
    <Box {...rest}>
      {providerId === 'oci' ? <OciNote /> : null}
      <Box
        bg="grey9"
        display="grid"
        gridTemplateColumns={columns.map((col) => col.gridWidth).join(' ')}
        gridGap={6}
        border="1px solid"
        borderColor="grey6"
        borderRadius={1}
        px={8}
        py={7}
      >
        {columns.map((col) => (
          <Text
            key={col.accessor}
            fontFamily="monospace"
            fontSize={1}
            color="grey3"
          >
            {col.Header}
          </Text>
        ))}
      </Box>
      <Box as="ul" _css={{ listStyle: 'none' }}>
        {nodeTypes.map((node) => (
          <TableRow key={node.id} item={node} columns={columns} />
        ))}
      </Box>
    </Box>
  );
};

export default CloudProviderNodeTable;
