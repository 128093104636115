import React, { useEffect, useRef, useState } from 'react';

import { flip, offset, shift, useFloating } from '@floating-ui/react-dom';
import css from '@styled-system/css';
import styled, { css as sCss, keyframes } from 'styled-components';

import Box from '@northflank/components/Box';

const animateMs = 150;

const framesIn = keyframes`
  from {
    transform: translateY(8px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
`;

const framesOut = keyframes`
  from {
    transform: translateY(0px);
    opacity: 1;
  }
  to {
    transform: translateY(8px);
    opacity: 0;
  }
`;

const StyledTooltip = styled.div(
  ({ whiteSpace = 'normal !important', maxWidth = '350px' }) =>
    css({
      bg: '#fff',
      color: '#57637a',
      borderRadius: 2,
      fontSize: 2,
      fontFamily: 'body',
      fontWeight: 400,
      lineHeight: 1.4,
      boxShadow: 'dropDown',
      zIndex: 9999,
      maxWidth,
      textAlign: 'center',
      px: 7,
      py: 4,
      overflow: 'visible !important',
      whiteSpace,
    }),
  ({ animate }) => sCss`
    animation: ${animateMs}ms linear forwards ${animate === 'in' ? framesIn : framesOut};
  `
);

const Tooltip = ({
  children,
  html,
  title,
  position = 'top',
  open,
  disabled,
  tooltipProps,
  ...rest
}) => {
  const [animate, setAnimate] = useState('in');
  const [show, setShow] = useState(false);

  const leaveDelayRef = useRef();

  useEffect(() => {
    if (typeof open === 'boolean') {
      if (open) animateIn();
      else animateOut();
    }
  }, [open]);

  const { x, y, reference, floating, strategy } = useFloating({
    placement: position,
    strategy: 'fixed',
    middleware: [offset(8), flip(), shift({ padding: 8 })],
  });

  const animateIn = () => {
    setAnimate('in');
    setShow(true);
  };

  const animateOut = () => {
    setAnimate('out');
    setTimeout(() => {
      setShow(false);
    }, animateMs);
  };

  const hasContent =
    (html !== undefined && html !== null && html !== false && html !== '') ||
    (title !== undefined && title !== null && title !== false && title !== '');

  const onPointerEnter = () => {
    if (typeof open !== 'boolean' && hasContent && !disabled) {
      if (leaveDelayRef.current) {
        clearTimeout(leaveDelayRef.current);
        leaveDelayRef.current = undefined;
      }
      animateIn();
    }
  };

  const onPointerLeave = () => {
    if (typeof open !== 'boolean') {
      leaveDelayRef.current = setTimeout(() => {
        animateOut();
      }, animateMs);
    }
  };

  return (
    <>
      <Box
        ref={reference}
        onPointerEnter={onPointerEnter}
        onPointerLeave={onPointerLeave}
        {...rest}
      >
        {children}
      </Box>
      {show && (
        <StyledTooltip
          ref={floating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
          }}
          animate={animate}
          onPointerEnter={onPointerEnter}
          onPointerLeave={onPointerLeave}
          {...tooltipProps}
        >
          {html ?? title}
        </StyledTooltip>
      )}
    </>
  );
};

export default Tooltip;
