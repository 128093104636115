import React from 'react';

import { ChevronDown } from '@styled-icons/boxicons-regular/ChevronDown';
import { ChevronUp } from '@styled-icons/boxicons-regular/ChevronUp';
import { LinkExternal } from '@styled-icons/boxicons-regular/LinkExternal';

import Box from '@northflank/components/Box';
import IconContainer from '@northflank/components/Icon';
import Text from '@northflank/components/Text';

const CloudProviderHeader = ({ option, show, setShow }) => (
  <Box
    variant="flex"
    alignItems="flex-start"
    justifyContent="space-between"
    mb={!setShow || show ? 8 : 0}
  >
    <a href={option.link}>
      <Box variant="flex" alignItems="center">
        <IconContainer
          Content={option.icon}
          Size={20}
          color="url(#gradient_svg__blueSvgGradient)"
          mr={6}
        />
        <Text fontSize={4} fontWeight={500} color="grey1">
          {option.label}
        </Text>
        <IconContainer Content={LinkExternal} Size={12} color="grey3" ml={6} />
      </Box>
    </a>
    {typeof setShow === 'function' ? (
      <Box
        css={{ cursor: 'pointer' }}
        p={6}
        onClick={() => setShow(!show)}
        mr={8}
      >
        <IconContainer Content={show ? ChevronUp : ChevronDown} Size={18} />
      </Box>
    ) : null}
  </Box>
);

export default CloudProviderHeader;
