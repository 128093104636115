import React from 'react';

import { LinkExternal } from '@styled-icons/boxicons-regular/LinkExternal';

import Box from '@northflank/components/Box';
import Icon from '@northflank/components/Icon';
import Text from '@northflank/components/Text';
import Tooltip from '@northflank/components/Tooltip';
import { capitalize } from '@northflank/constants-base/functions';

import { cloudProviderOptions } from '../../utility/cloudProviderOptions';

const CloudProviderTableProvider = ({ value }) => {
  const option = cloudProviderOptions.find((o) => o.value === value);
  return (
    <Box
      as="a"
      href={option.link}
      color="grey1"
      _css={{ '&:hover': { color: 'primary' } }}
    >
      <Box variant="flex" alignItems="center">
        <Tooltip title={option.label}>
          <Icon
            Content={option.icon}
            Size={14}
            color="url(#gradient_svg__blueSvgGradient)"
            mr={4}
          />
        </Tooltip>
        <Text>
          {option.value.length > 3
            ? capitalize(option.value)
            : option.value.toUpperCase()}
        </Text>
        <Icon Content={LinkExternal} Size={12} color="grey3" ml={3} />
      </Box>
    </Box>
  );
};

export default CloudProviderTableProvider;
