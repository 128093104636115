import React from 'react';
import { Link } from 'react-router-dom';

import { TrafficBarrier } from '@styled-icons/boxicons-solid/TrafficBarrier';

import Box from '@northflank/components/Box';
import Heading from '@northflank/components/Heading';
import Icon from '@northflank/components/Icon';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';

const NotFound = () => (
  <>
    <SEO title="Not found" />
    <Box variant="bounding">
      <Box variant="flex" alignItems="center" mb={10}>
        <Icon Content={TrafficBarrier} Size={60} color="warning" mr={8} />
        <Heading color="grey1" fontSize={[6, 6, 7, 7]} lineHeight={1}>
          Error 404
        </Heading>
      </Box>
      <Text fontSize={4} width={[1, 1, 1 / 2]} mediaQuery>
        Sorry, it looks like that page or resource doesn’t exist. Why don’t you{' '}
        <Link to="/">head back home</Link>?
      </Text>
    </Box>
  </>
);

export default NotFound;
