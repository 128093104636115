import React, { useEffect, useState } from 'react';

import Box from '@northflank/components/Box';
import Grid from '@northflank/components/Grid';
import FeatureTabs from '@northflank/components/marketing/FeatureTabs';
import Text from '@northflank/components/Text';

import DisplayProviderRegions from '../DisplayProviderRegions';
import { cloudProviderOptions } from '../utility/cloudProviderOptions';
import { regionNames } from '../utility/regionNames';

const BYOCRegions = ({ regions, providers, ...rest }) => {
  const [selectedRegion, setSelectedRegion] = useState('All');
  const [displayedRegions, setDisplayedRegions] = useState(regions);
  const providersList = providers.map((o) => o.id);

  const cloudProviders = cloudProviderOptions.filter((p) =>
    providersList.includes(p.value)
  );

  useEffect(() => {
    let regionsToDisplay = {};
    if (regions !== undefined) {
      cloudProviders.forEach((option) => {
        const key = option.value;
        if (typeof regions[key] !== 'undefined') {
          regionsToDisplay = { ...regionsToDisplay, [key]: [] };
          regions[key].map(
            (r) =>
              (selectedRegion === r.regionName || selectedRegion === 'All') &&
              regionsToDisplay[key].push(r)
          );
        }
      });
    }
    setDisplayedRegions(regionsToDisplay);
  }, [selectedRegion]);

  return (
    <Box {...rest}>
      <Text fontSize={4} fontWeight={500} mb={8}>
        Supported Cloud Regions
      </Text>
      <FeatureTabs
        tabs={regionNames}
        activeTab={selectedRegion}
        setActiveTab={(t) => {
          setSelectedRegion(t);
        }}
        mx="auto"
        mb={10}
        flexWrap="wrap"
        justifyContent="center"
        borderRadius={3}
        bg="grey10"
        border="1px solid"
        borderColor="grey5"
        width="100%"
      />
      <Grid gridTemplateColumns="1fr" gridGap={10}>
        {cloudProviders.map((option) => (
          <DisplayProviderRegions
            key={option.id}
            option={option}
            displayedRegions={displayedRegions}
            collapsible
          />
        ))}
      </Grid>
    </Box>
  );
};

export default BYOCRegions;
