const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const getOrdinal = (dateInt) => {
  if (dateInt >= 11 && dateInt <= 20) return 'th';

  const s = dateInt.toString();
  const lastDigit = Number(s[s.length - 1]);

  switch (lastDigit) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export default function formatDate(dateStr) {
  const date = new Date(dateStr);

  const d = date.getDate();
  const m = date.getMonth();
  const y = date.getFullYear();

  return `${d}${getOrdinal(d)} ${months[m]} ${y}`;
}
