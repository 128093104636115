import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { RightArrowAlt } from '@styled-icons/boxicons-regular/RightArrowAlt';

import Box from '@northflank/components/Box';
import Grid from '@northflank/components/Grid';
import Icon from '@northflank/components/Icon';
import { solutionPages } from '@northflank/components/marketing/FeatureNav';
import Text from '@northflank/components/Text';

const UseCasesFooterLinks = (props) => {
  const { pathname } = useLocation();

  return (
    <Box {...props}>
      <Text fontSize={4} color="grey2" mb={9}>
        Want to learn more about other use cases? Choose from our highlights:
      </Text>
      <Grid
        gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)', 'repeat(4, 1fr)']}
        gridGap={7}
      >
        {solutionPages[0].items.map((item) =>
          pathname !== item.path ? (
            <Link key={item.path} to={item.path}>
              <Box
                variant="flex"
                alignItems="center"
                justifyContent="space-between"
                bg="grey10"
                border="1px solid"
                borderColor="grey5"
                borderRadius={1}
                p={8}
                _css={{
                  transition: '200ms',
                  svg: { transition: '200ms' },
                  '&:hover': {
                    bg: 'grey8',
                    svg: {
                      transform: 'translateX(2px)',
                    },
                  },
                }}
              >
                <Text color="grey1" fontSize={3}>
                  {item.label}
                </Text>
                <Icon Content={RightArrowAlt} Size={24} color="grey3" />
              </Box>
            </Link>
          ) : (
            <Box
              key={item.path}
              variant="flex"
              alignItems="center"
              justifyContent="space-between"
              bg="grey6"
              border="1px solid"
              borderColor="primary"
              borderRadius={1}
              fontWeight={600}
              p={8}
            >
              <Text color="grey1" fontSize={3}>
                {item.label}
              </Text>
            </Box>
          )
        )}
      </Grid>
    </Box>
  );
};

export default UseCasesFooterLinks;
