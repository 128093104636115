// Based on frequency analysis data from https://gist.github.com/lydell/c439049abac2c9226e53 which is released under public domain
import isInteger from 'lodash/isInteger';
import { customAlphabet } from 'nanoid';
import { DBTeam } from '@northflank/schema-types';
import { defaultProjectUidLength } from '../data';

const alphabet = '2456789bcdfghjklmnpqrstvwxyz';
const alphabetNoNumber = 'bcdfghjklmnpqrstvwxyz';

const forbiddenDigraphs = [
  'th',
  'nd',
  'st',
  '5t',
  'nt',
  'ng',
  'ch',
  'll',
  'ns',
  'pr',
  'ct',
  'tr',
  'ly',
  'nc',
  'ss',
  '55',
  'rs',
  'r5',
  'wh',
  'rt',
  'ts',
  'r5',
  'sh',
  '5h',
  'pl',
  'ld',
  'ry',
  'ck',
  'gy',
];

const invalidCode = (code) => forbiddenDigraphs.some((di) => code.includes(di));

export const getProjectUidLength = (entity: DBTeam) => {
  if (
    entity?.overrides?.projectUidLength &&
    isInteger(entity.overrides.projectUidLength) &&
    entity.overrides.projectUidLength > 0
  ) {
    return entity.overrides.projectUidLength;
  }
  return defaultProjectUidLength;
};

export const generateDNSCode = (size) => {
  const nanoid = customAlphabet(alphabet, size);
  let code = nanoid();
  while (invalidCode(code)) {
    code = nanoid();
  }
  return code;
};

export const getClusterDNSCode = (length) => {
  if (length <= 1) throw new Error('getClusterDNSCode length must be at least 2');
  const prefix = customAlphabet(alphabetNoNumber, 1);
  const nanoid = customAlphabet(alphabet, length - 1);

  const getCode = () => `${prefix()}${nanoid()}`;

  let code = getCode();
  while (invalidCode(code)) {
    code = getCode();
  }

  return code;
};
