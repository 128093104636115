import React from 'react';

import { Chip } from '@styled-icons/boxicons-regular/Chip';
import { CloudDownload } from '@styled-icons/boxicons-regular/CloudDownload';
import { Cog } from '@styled-icons/boxicons-regular/Cog';
import { Crosshair } from '@styled-icons/boxicons-regular/Crosshair';
import { Detail } from '@styled-icons/boxicons-regular/Detail';
import { GitCommit } from '@styled-icons/boxicons-regular/GitCommit';
import { GitPullRequest } from '@styled-icons/boxicons-regular/GitPullRequest';
import { ListCheck } from '@styled-icons/boxicons-regular/ListCheck';
import { PackageIcon } from '@styled-icons/boxicons-regular/PackageIcon';
import { Radar } from '@styled-icons/boxicons-regular/Radar';
import { Reset } from '@styled-icons/boxicons-regular/Reset';
import { Rocket } from '@styled-icons/boxicons-regular/Rocket';
import { Save } from '@styled-icons/boxicons-regular/Save';
import { SelectMultiple } from '@styled-icons/boxicons-regular/SelectMultiple';
import { Server } from '@styled-icons/boxicons-regular/Server';
import { Slider } from '@styled-icons/boxicons-regular/Slider';
import { Wrench } from '@styled-icons/boxicons-regular/Wrench';
import { Docker } from '@styled-icons/fa-brands/Docker';
import { Kubernetes } from '@styled-icons/simple-icons/Kubernetes';

import Box from '@northflank/components/Box';
import Heading from '@northflank/components/Heading';
import FeatureNav from '@northflank/components/marketing/FeatureNav';
import FeatureWithIcon from '@northflank/components/marketing/FeatureWithIcon';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';

import {
  FeatureGroup,
  FeaturesWrapper,
} from '../../components/FeaturePageWrapper';
import PreHeading from '../../components/PreHeading';

const Build = () => {
  return (
    <>
      <SEO
        title="Build — Features"
        description="Northflank enables continuous integration of all your code with Dockerfiles and Buildpacks, from all your favourite version control platforms."
      />
      <FeatureNav />
      <FeaturesWrapper>
        <Box variant="bounding" pb="70px!important">
          <PreHeading mb={6}>Feature breakdown</PreHeading>
          <Heading as="h1" color="grey1" fontSize={[6, 6, 7, 7]} mb={8}>
            Build on Northflank
          </Heading>
          <Text color="grey3" fontSize={4} width={[1, 1, 1 / 2]} mediaQuery>
            Northflank enables continuous integration of all your code, from all
            your favourite version control platforms.
          </Text>
        </Box>
        <FeatureGroup
          heading="Implement Continuous Integration"
          wideImage="/images/feature/details/build/build-config.png"
          wideImageAlt="Screenshot of Northflank advanced build configuration UI"
          items={
            <>
              <FeatureWithIcon
                Icon={GitPullRequest}
                iconGradient
                heading="Native VCS Compatibility"
                text="Integrate with GitHub, GitLab, or Bitbucket, and Northflank will immediately sync with all of your repositories, branches, and pull or merge requests."
              />
              <FeatureWithIcon
                Icon={Server}
                iconGradient
                heading="Self-Hosted and Enterprise VCS"
                text="Seamlessly integrate Northflank with self-hosted GitLab or Enterprise GitHub for advanced use cases."
              />
              <FeatureWithIcon
                Icon={GitCommit}
                iconGradient
                heading="Build on Commit"
                text="Simply push to your repository to trigger a build. Build status is immediately viewable via your VCS provider. Pause continuous integration at any time."
              />
              <FeatureWithIcon
                Icon={ListCheck}
                iconGradient
                heading="Build Rules"
                text="Configure rules to determine which branches and pull requests get built based on branch name, commit message, or other user specified file changes."
              />
              <FeatureWithIcon
                Icon={Slider}
                iconGradient
                heading="Build Arguments & Files"
                text="Use build arguments to make environment variables and secret files available at build time. All variables are stored and injected securely."
              />
              <FeatureWithIcon
                Icon={Detail}
                iconGradient
                heading="Build History"
                text="View old builds from any branch or PR. Redeploy historical builds or initiate a new build of an old commit with the click of a button."
              />
            </>
          }
        />
        <FeatureGroup
          heading="Customise Your Build Process"
          wideImage="/images/feature/details/build/build-rules.png"
          wideImageAlt="Screenshot of Northflank build rule configuration"
          items={
            <>
              <FeatureWithIcon
                Icon={Docker}
                iconGradient
                heading="Build with Dockerfile or Buildpack"
                text="Simply add a Dockerfile or Buildpack, and Northflank will build and host your images. Custom or Heroku buildpacks can also be used via Git or the Heroku Buildpack repository."
              />
              <FeatureWithIcon
                Icon={PackageIcon}
                iconGradient
                heading="Monorepo Support"
                text="You can contain all of your services in the same place with multiple Dockerfiles and build contexts in a single repository."
              />
              <FeatureWithIcon
                Icon={Crosshair}
                iconGradient
                heading="Multistage Builds"
                text="Build specific Dockerfile stages for faster and smaller builds. Skip stages that are unnecessary and leverage external base images from private registries."
              />
              <FeatureWithIcon
                Icon={SelectMultiple}
                iconGradient
                heading="Multistep Builds"
                text="Build multiple images in parallel or sequential order across one or multiple repositories. Integrate with pipelines for preview, staging, and production builds."
              />
              <FeatureWithIcon
                Icon={CloudDownload}
                iconGradient
                heading="Pull Builds from Northflank Registry"
                text="Pull images locally to debug workloads, deploy externally, or use as base images in pipelines."
              />
              <FeatureWithIcon
                Icon={Cog}
                iconGradient
                heading="Build Overrides"
                text="Trigger one-off builds with custom build arguments outside of your normal CI flow."
              />
            </>
          }
        />
        <FeatureGroup
          heading="Optimise Your Builds"
          wideImage="/images/feature/details/build/logs.png"
          wideImageAlt="Screenshot of Northflank service logs view"
          items={
            <>
              <FeatureWithIcon
                Icon={Wrench}
                iconGradient
                heading="Build Engines"
                text="Northflank supports both Buildkit and Kaniko so you can always achieve the optimal build output based on your project’s requirements."
              />
              <FeatureWithIcon
                Icon={Save}
                iconGradient
                heading="Caching"
                text="Increase your build speed with remote layer caching to make incremental commits faster to deploy."
              />
              <FeatureWithIcon
                Icon={Reset}
                iconGradient
                heading="Image and Build Reuse"
                text="Save time by not creating duplicate images. Northflank separates the build and release process so you can deploy multiple services from a single build."
              />
              <FeatureWithIcon
                Icon={Chip}
                iconGradient
                heading="Resource Plans"
                text="Select from plans with different CPU and memory levels based on the requirements of your builds."
              />
              <FeatureWithIcon
                Icon={Radar}
                iconGradient
                heading="Build Observability"
                text="Observe builds as they run with real-time log tailing and performance metrics. Receive alerts when builds succeed or fail via Slack, webhook, and more."
              />
              <FeatureWithIcon
                Icon={Rocket}
                iconGradient
                heading="Rapid Compute"
                text="Highly performant build infrastructure scaled and operated by Northflank with finely tuned single thread and disk performance."
              />
              <FeatureWithIcon
                Icon={Kubernetes}
                iconGradient
                heading="Build Clusters"
                text="Designate specialised build clusters with Bring Your Own Cloud. Optimised settings ensure optimal performance and resource utilisation over non-specialised clusters."
              />
            </>
          }
        />
      </FeaturesWrapper>
    </>
  );
};

export default Build;
