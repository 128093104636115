import React, { useContext, useEffect, useRef, useState } from 'react';

import Monaco from '@monaco-editor/react';
import { EditAlt } from '@styled-icons/boxicons-regular/EditAlt';
import { Transfer } from '@styled-icons/boxicons-regular/Transfer';
import { X } from '@styled-icons/boxicons-regular/X';
import css from '@styled-system/css';
import styled, { ThemeContext } from 'styled-components';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import { getMonacoTheme } from '@northflank/components/CodeEditor/nfMonacoTheme';
import Grid from '@northflank/components/Grid';
import Heading from '@northflank/components/Heading';
import InfoBox from '@northflank/components/InfoBox';
import Input from '@northflank/components/Input';
import SEO from '@northflank/components/marketing/SEO';
import Select from '@northflank/components/Select';
import Text from '@northflank/components/Text';

import { EditPreviewContext } from '../App';

class MdxErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.error(error);
    return { hasError: true };
  }

  componentDidUpdate(prev) {
    if (prev.children !== this.props.children) {
      this.setState({ hasError: false });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box variant="bounding">
          <Heading color="danger" mb={10}>
            Could not compile MDX. Check your syntax!
          </Heading>
          <Text>See console for full error info.</Text>
        </Box>
      );
    }
    return this.props.children;
  }
}

const OpenEditButton = styled(Button)(({ open, editorWidth }) =>
  css({
    borderRadius: '30px 0 0 30px',
    px: 4,
    position: 'fixed',
    right: open ? `${editorWidth}%` : 0,
    top: '50%',
    transform: 'translateY(-50%)',
    boxShadow: 'dropDown',
  })
);

const DragResizeButton = styled(Button)(({ editorWidth }) =>
  css({
    borderRadius: '4px 0 0 4px',
    borderRight: 0,
    bg: 'grey11',
    px: 0,
    height: '100px',
    position: 'fixed',
    right: `${editorWidth}%`,
    top: '90%',
    transform: 'translateY(-50%)',
    boxShadow: 'dropDown',
    cursor: 'grab',
  })
);

const visibilityOptions = [
  { label: 'Draft Salvo', value: 'draft_salvo' },
  { label: 'Published', value: 'published' },
];

const CmsPage = ({ page, renderedPage, canPreview }) => {
  const [previewContent, setPreviewContent] = useState(page.attributes.content);
  const [renderedPreviewContent, setRenderedPreviewContent] =
    useState(renderedPage);
  const [editorWidth, setEditorWidth] = useState(30);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [previewError, setPreviewError] = useState(null);

  const { showEditPreview, setShowEditPreview } =
    useContext(EditPreviewContext);
  const { colors } = useContext(ThemeContext);

  const isDraggingResize = useRef();

  useEffect(() => {
    const onMouseUp = () => {
      if (isDraggingResize.current) isDraggingResize.current = false;
    };
    const onMouseMove = (e) => {
      if (isDraggingResize.current) {
        const perc = (e.pageX / window.innerWidth) * 100;
        setEditorWidth(100 - perc - 2);
      }
    };
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('mousemove', onMouseMove);
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  useEffect(() => {
    setSuccess(null);
    setError(null);
  }, [showEditPreview]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    try {
      const res = await fetch('/api/strapi-update-page', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: page.id,
          data: {
            ...page.attributes,
            title: form.get('title'),
            description: form.get('description'),
            publication_date: form.get('publication_date'),
            visibility: form.get('visibility'),
            content: previewContent,
          },
        }),
      });
      if (res.ok) {
        setSuccess('Updated successfully');
        setError(null);
        setTimeout(() => setSuccess(null), 3000);
      } else {
        setSuccess(null);
        setError(`${res.status}: ${res.statusText}`);
      }
    } catch (e) {
      setSuccess(null);
      setError(e.message);
    }
  };

  const renderPreviewContent = async (content) => {
    try {
      const res = await fetch('/api/mdx-to-html', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ content }),
      });
      if (res.ok) {
        setRenderedPreviewContent(await res.text());
        setPreviewError(null);
      } else {
        setPreviewError(await res.json());
      }
    } catch (e) {
      setPreviewError(e);
    }
  };

  return (
    <>
      <SEO
        title={page.attributes.title}
        description={page.attributes.description}
      />
      {previewError && (
        <Box p={12}>
          <InfoBox type="danger" title={previewError.message}>
            <pre>{JSON.stringify(previewError, null, 2)}</pre>
          </InfoBox>
        </Box>
      )}
      <MdxErrorBoundary>
        <div
          id="mdx-root"
          dangerouslySetInnerHTML={{
            __html: canPreview ? renderedPreviewContent : renderedPage,
          }}
        />
      </MdxErrorBoundary>
      {canPreview && (
        <OpenEditButton
          variant={showEditPreview ? 'danger' : 'primary'}
          open={showEditPreview}
          onClick={() => setShowEditPreview((s) => !s)}
          editorWidth={editorWidth}
        >
          {showEditPreview ? <X size={30} /> : <EditAlt size={30} />}
        </OpenEditButton>
      )}
      {showEditPreview && (
        <>
          <Box
            as="form"
            onSubmit={handleSubmit}
            display="flex"
            flexDirection="column"
            width={`${editorWidth}%`}
            height="100vh"
            position="fixed"
            top={0}
            bottom={0}
            right={0}
            bg="grey6"
            borderLeft="1px solid"
            borderColor="grey5"
            boxShadow="-4px 0 24px 0 rgba(0, 0, 0, 0.24)"
            zIndex={9999}
            p={8}
          >
            <Grid gridTemplateColumns="1fr 1fr" gridGap={6} mb={7}>
              <Input
                name="title"
                label="Page title"
                defaultValue={page.attributes.title}
                required
              />
              <Input
                name="description"
                label="Page description"
                defaultValue={page.attributes.description}
                placeholder="Leave blank for default"
              />
            </Grid>
            <Grid gridTemplateColumns="1fr 1fr" gridGap={6} mb={7}>
              <Input
                type="datetime-local"
                name="publication_date"
                label="Published (strapi time)"
                defaultValue={page.attributes.publication_date.split('.')[0]}
                required
              />
              <Select
                name="visibility"
                label="Visibility"
                options={visibilityOptions}
                defaultValue={visibilityOptions.find(
                  (opt) => opt.value === page.attributes.visibility
                )}
                required
              />
            </Grid>
            <Box
              border="1px solid"
              borderColor="grey5"
              borderRadius={1}
              flexGrow={1}
              mb={7}
            >
              <Monaco
                value={previewContent}
                onChange={(val) => {
                  setPreviewContent(val);
                  renderPreviewContent(val);
                }}
                options={{ minimap: { enabled: false } }}
                beforeMount={(monaco) => {
                  monaco.editor.defineTheme(
                    'nfMonacoTheme',
                    getMonacoTheme(colors)
                  );
                  // monaco.languages.registerCompletionItemProvider('html', {
                  //   triggerCharacters: ['<'],
                  //   provideCompletionItems: function (model, position) {
                  //     const textUntilPosition = model.getValueInRange({
                  //       startLineNumber: position.lineNumber,
                  //       startColumn: 1,
                  //       endLineNumber: position.lineNumber,
                  //       endColumn: position.column,
                  //     });
                  //     const match = textUntilPosition.match(/<.*/);
                  //     if (!match) {
                  //       return { suggestions: [] };
                  //     }
                  //     const word = model.getWordUntilPosition(position);
                  //     const range = {
                  //       startLineNumber: position.lineNumber,
                  //       endLineNumber: position.lineNumber,
                  //       startColumn: word.startColumn,
                  //       endColumn: word.endColumn,
                  //     };
                  //     return {
                  //       suggestions: Object.keys(components).map((key) => ({
                  //         label: key,
                  //         kind: monaco.languages.CompletionItemKind.Property,
                  //         insertText: key,
                  //         range,
                  //       })),
                  //     };
                  //   },
                  // });
                }}
                language="html"
                theme="nfMonacoTheme"
              />
            </Box>
            <Box variant="flex" alignItems="center" justifyContent="flex-end">
              {error && (
                <Text color="danger" mr={7}>
                  {error}
                </Text>
              )}
              {success && (
                <Text color="success" mr={7}>
                  {success}
                </Text>
              )}
              <Button
                type="button"
                onClick={() => setShowEditPreview(false)}
                mr={6}
              >
                Close
              </Button>
              <Button type="submit" variant="primary">
                Save
              </Button>
            </Box>
          </Box>
          <DragResizeButton
            editorWidth={editorWidth}
            onMouseDown={() => {
              isDraggingResize.current = true;
            }}
          >
            <Transfer size={20} />
          </DragResizeButton>
        </>
      )}
    </>
  );
};

export default CmsPage;
