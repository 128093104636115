import isEqual from 'lodash/isEqual';
import { V1TemplateNodeBaseSchema } from '@northflank/schema-types';

export const simplifyTemplateSpec = (node: V1TemplateNodeBaseSchema, initial = true) => {
  // Handle workflows recursively.
  if (node.kind === 'Workflow') {
    // Simplify workflow steps recursively.
    const simplifiedSteps = node.spec.steps
      // Simplify each step.
      .map((s) => simplifyTemplateSpec(s, false))
      // Clean up workflows that deleted themselves.
      .filter((s) => typeof s !== 'undefined')
      // Merge together workflows of the same type as this will not change the order they are processed.
      .reduce((a, s) => {
        // Do nothing to non-workflows.
        if (s.kind !== 'Workflow') {
          return [...a, s];
        }

        // Merge workflows if they are of the same type as this does not affect the order they are run.
        // Also ignore workflows if the contexts do not match
        return s.spec.type === node.spec.type &&
          isEqual(s.spec.context || {}, node.spec.context || {})
          ? [...a, ...s.spec.steps]
          : [...a, s];
      }, []);

    // Destroy current workflow if it's not the initial and contains no steps.
    if (!initial && simplifiedSteps.length === 0) {
      return undefined;
    }

    // Pass step up if it's the only step in the workflow.
    if (simplifiedSteps.length === 1 && !Object.keys(node.spec.context || {}).length) {
      // TODO: Pretty sure simplifyTemplateSpec is not needed here - it's already simplified in the map.
      return simplifyTemplateSpec(simplifiedSteps[0], false);
    }

    // Otherwise leave node as is, but filter out destroyed workflows.
    return {
      ...node,
      spec: {
        ...node.spec,
        steps: simplifiedSteps,
      },
    };
  }

  // Do nothing to non-workflows.
  return node;
};
