export const subdomainPathModeOptions = [
  {
    value: 'exact',
    label: 'Exact',
    description: 'Request path must match this path exactly',
  },
  {
    value: 'prefix',
    label: 'Prefix',
    description: 'Request path must begin with this path',
  },
  {
    value: 'regex',
    label: 'RegEx',
    description: 'Request path must satisfy this regular expression',
  },
];

// Note: Cam tried specific status codes on the cluster default in mesh options and that didn't work anyway. So just having 5xx and 4xx is probably fine
// https://www.envoyproxy.io/docs/envoy/latest/configuration/http/http_filters/router_filter#x-envoy-retry-on
export const xEnvoyRetryOnKeys = [
  '5xx',
  'gateway-error',
  'reset',
  'connect-failure',
  'envoy-ratelimited',
  // retriable-4xx only covers 409 atm
  'retriable-4xx',
  'refused-stream',
  'retriable-status-codes',
  'retriable-headers',
];

// https://www.envoyproxy.io/docs/envoy/latest/configuration/http/http_filters/router_filter#x-envoy-retry-on
export const xEnvoyRetryGrpcOn = [
  'cancelled',
  'deadline-exceeded',
  'internal',
  'resource-exhausted',
  'unavailable',
];
