import React from 'react';

import { Linkedin } from '@styled-icons/boxicons-logos/Linkedin';

import Box from '@northflank/components/Box';
import Icon from '@northflank/components/Icon';
import Text from '@northflank/components/Text';

export const TestimonialHeader = ({
  name,
  position,
  avatar,
  linkedin,
  ...rest
}) => (
  <Box display="flex" alignItems="flex-start" mb={8} {...rest}>
    {avatar ? (
      <Box
        as="img"
        src={avatar}
        alt={`${name} profile image`}
        width="48px"
        height="48px"
        boxShadow="dropDown"
        borderRadius={5}
        mr={7}
      />
    ) : (
      <Box bg="grey6" width="48px" height="48px" borderRadius={5} mr={7} />
    )}
    <Box>
      <Box variant="flex" alignItems="center">
        <Text fontSize={4}>{name}</Text>
        {linkedin && (
          <Box
            as="a"
            href={linkedin}
            target="_blank"
            ml={5}
            _css={{
              svg: {
                transition: '100ms',
                '&:hover': {
                  fill: 'grey2',
                },
              },
            }}
            aria-label={`Visit the LinkedIn profile for ${name}`}
          >
            <Icon Content={Linkedin} Size={16} color="grey3" />
          </Box>
        )}
      </Box>
      <Text fontSize={3} color="grey3">
        {position}
      </Text>
    </Box>
  </Box>
);

const Testimonial = ({ name = 'John Johnson', position, quote, avatar }) => {
  return (
    <Box
      bg="grey9"
      border="1px solid"
      borderColor="grey5"
      borderRadius={2}
      boxShadow="dropDown"
      p={8}
    >
      <TestimonialHeader name={name} position={position} avatar={avatar} />
      <Text fontSize={3} color="grey2">
        {quote}
      </Text>
    </Box>
  );
};

export default Testimonial;
