import { useEffect, useState } from 'react';

const getRelativeTopHeight = (parent, child) => {
  const parentBB = parent.getBoundingClientRect();
  const childBB = child.getBoundingClientRect();
  const relativeTop = childBB.top - parentBB.top;
  return parentBB.height - relativeTop;
};

const useAvailableHeight = (ref, parent, dependencies = []) => {
  const [height, setHeight] = useState();

  useEffect(() => {
    setHeight(() => {
      if (!ref.current) return undefined;
      const parentByClassName = ref.current.closest(
        '.__useAvailableHeight_parent'
      );
      return parent
        ? getRelativeTopHeight(parent, ref.current)
        : parentByClassName
          ? getRelativeTopHeight(parentByClassName, ref.current)
          : window.innerHeight - ref.current.getBoundingClientRect().top;
    });
  }, [ref, ...dependencies]);

  return height;
};

export default useAvailableHeight;
