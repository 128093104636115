import React, { useState } from 'react';

import { Chip } from '@styled-icons/boxicons-regular/Chip';
import { Cloud } from '@styled-icons/boxicons-regular/Cloud';
import { ListCheck } from '@styled-icons/boxicons-regular/ListCheck';
import { UserCircle } from '@styled-icons/boxicons-regular/UserCircle';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import { FormikInput } from '@northflank/components/FormikInput';
import FormikSelect from '@northflank/components/FormikSelect';
import Grid from '@northflank/components/Grid';
import Heading from '@northflank/components/Heading';
import Icon from '@northflank/components/Icon';
import FeatureWithIcon from '@northflank/components/marketing/FeatureWithIcon';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';

import CloudProviderCard from '../components/CloudProviders/CloudProviderCard';
import DisplayGpus from '../components/CloudProviders/DisplayGpus';
import { cloudProviderOptions } from '../components/CloudProviders/utility/cloudProviderOptions';
import GetStartedCta from '../components/GetStartedCta';
import PreHeading from '../components/PreHeading';
import topog from '../components/Topog';

const providerOptions = [
  {
    label: 'Northflank Cloud',
    value: 'northflank',
  },
  {
    label: 'Amazon Web Services',
    value: 'aws',
  },
  {
    label: 'Azure',
    value: 'azure',
  },
  {
    label: 'Civo',
    value: 'civo',
  },
  {
    label: 'Google Cloud Platform',
    value: 'gcp',
  },
  {
    label: 'Bare-metal / On-prem',
    value: 'baremetal',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

const useCaseOptions = [
  {
    label: 'AI training',
    value: 'ai-training',
  },
  {
    label: 'AI inference',
    value: 'ai-inference',
  },
  {
    label: 'Machine learning',
    value: 'machine-learning',
  },
  {
    label: 'Data science',
    value: 'data-science',
  },
  {
    label: 'Parallel / HPC',
    value: 'hpc',
  },
  {
    label: '3D / graphics',
    value: '3d-graphics',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

const GpuForm = ({ buttons, setSuccess, setError }) => (
  <Formik
    initialValues={{ email: '', provider: [] }}
    validationSchema={yup.object({
      email: yup.string().email().required('Please enter your email address'),
      provider: yup.array().of(
        yup
          .string()
          .oneOf(providerOptions.map((o) => o.value))
          .required('Please select a provider')
      ),
      usecase: yup.array().of(
        yup
          .string()
          .oneOf(useCaseOptions.map((o) => o.value))
          .required('Please select a provider')
      ),
    })}
    onSubmit={async (values, { resetForm }) => {
      const res = await fetch('/api/cio-form-submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          formId: 'gpu',
          data: {
            email: values.email,
            form_gpu_interest_provider: values.provider.join(','),
            form_gpu_interest_usecase: values.usecase.join(','),
          },
        }),
      });

      setSuccess(false);
      setError(undefined);

      if (res.ok) {
        setError(undefined);
        setSuccess(true);
        resetForm();
      } else {
        setSuccess(false);
        setError(`Something went wrong: ${await res.text()}`, 'error');
      }
    }}
  >
    <Box
      as={Form}
      height="100%"
      variant="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box>
        <FormikInput
          type="email"
          name="email"
          label="Email address"
          placeholder="name@example.com"
          formikRequired
          mb={8}
        />
        <FormikSelect
          name="provider"
          label="Desired cloud provider"
          options={providerOptions}
          isMulti
          simpleValue
          formikRequired
          mb={8}
        />
        <FormikSelect
          name="usecase"
          label="Desired use case"
          options={useCaseOptions}
          isMulti
          simpleValue
          formikRequired
          mb={8}
        />
      </Box>
      <Box>{buttons}</Box>
    </Box>
  </Formik>
);

const Page = ({ gpus = [], gpuProviders = [] }) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();

  return (
    <>
      <SEO
        title="GPU Workloads on Northflank"
        description="Provision compute nodes with the latest GPU models across cloud providers. Northflank does all of the heaving lifting, meaning that your AI, machine learning, and HPC workloads can be up and running with minimal effort."
      />

      <Box
        background={`radial-gradient(35% 60% at 45% 50%, rgba(0, 147, 255, 0.1) 0%, #151C28 100%),
        radial-gradient(circle at 35% 20%, rgba(0, 0, 0, 0) 0%, #151C28 100%),
         ${topog}`}
        mt="-80px"
        pt="80px"
      >
        <Box variant="bounding">
          <Box maxWidth={['100%', '100%', '50%']}>
            <PreHeading mb={6}>Now available</PreHeading>
            <Heading as="h1" fontSize={[6, 6, 7, 7]} color="grey1" mb={8}>
              GPU Workloads on Northflank
            </Heading>
            <Text color="grey2" fontSize={4}>
              Northflank now supports GPU workloads! Provision compute nodes
              with the latest GPU models across cloud providers. Northflank does
              all of the heaving lifting, meaning that your AI, machine
              learning, and HPC workloads can be up and running with minimal
              effort.
            </Text>
          </Box>
        </Box>

        <Box variant="boundingWide" pt="0 !important">
          <Grid
            gridTemplateColumns={['1fr', '1fr', '1fr', '3fr 1fr']}
            gridGap={10}
            alignItems="stretch"
          >
            <Box
              display="flex"
              bg="grey11"
              border="1px solid"
              borderColor="grey5"
              borderRadius={3}
              position="relative"
            >
              <Box
                display="flex"
                width="100%"
                borderRadius={4}
                boxShadow="dropDown"
              >
                <Box
                  as="img"
                  src="https://assets.northflank.com/app_northflank_com_t_northflank_bloomberg_cloud_clusters_aws_bloomberg_nodepools_eb65e6cd54.png"
                  width="100%"
                  borderRadius={3}
                />
              </Box>
            </Box>

            <Box
              gridRow={[1, 1, 1, 'unset']}
              variant="flex"
              flexDirection="column"
              justifyContent="space-between"
              bg="grey11"
              border="1px solid"
              borderColor="grey5"
              borderRadius={3}
              boxShadow="dropDown"
              p={10}
            >
              <Box variant="flex" alignItems="center" mb={8}>
                <Icon
                  Content={UserCircle}
                  Size={24}
                  color="url(#gradient_svg__blueSvgGradient)"
                  mr={6}
                />
                <Text fontSize={5} fontWeight={600}>
                  Request access
                </Text>
              </Box>
              <Text fontSize={3} mb={12}>
                We’re onboarding users to GPU workloads on Northflank in batches
                to ensure a quality developer experience and to iterate on
                customer feedback promptly. Enter your details below to register
                your interest and we’ll be in touch shortly.
              </Text>
              <GpuForm
                setSuccess={setSuccess}
                setError={setError}
                buttons={
                  <Box mt={10}>
                    {success && (
                      <Text fontSize={1} color="success" mb={8}>
                        Thanks for registering your interest!
                      </Text>
                    )}
                    {error && (
                      <Text fontSize={1} color="dangerLight2" mb={8}>
                        {error}
                      </Text>
                    )}
                    <Box
                      mr="auto"
                      width="100%"
                      position="relative"
                      zIndex={0}
                      _css={{
                        '&::before': {
                          content: `""`,
                          position: 'absolute',
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          bg: 'primary',
                          borderRadius: 1,
                          filter: 'blur(12px)',
                          opacity: 0.5,
                          zIndex: -1,
                        },
                      }}
                      mb={7}
                    >
                      <Button
                        type="submit"
                        variant={['primary', 'large']}
                        width="100%"
                      >
                        Request access
                      </Button>
                    </Box>
                    <Button
                      type="button"
                      to="https://cal.com/team/northflank/northflank-gpus"
                      target="_blank"
                      externalLink
                      variant="large"
                      width="100%"
                      linkStyle={{ display: 'block' }}
                    >
                      Request a demo
                    </Button>
                  </Box>
                }
              />
            </Box>
          </Grid>
        </Box>

        <Box variant="bounding" pt="0 !important">
          <Grid
            gridTemplateColumns={['1fr', '1fr', 'repeat(3, 1fr)']}
            gridGap={12}
            alignItems="start"
          >
            <FeatureWithIcon
              Icon={Cloud}
              iconGradient
              heading="Multi-cloud"
              text="Northflank supports GPU workloads on AWS, Azure, Civo, and GCP. Wherever you prefer to deploy, get started in just a couple of clicks – Northflank abstracts away any awkward set-up and configuration."
            />
            <FeatureWithIcon
              Icon={Chip}
              iconGradient
              heading="Time slicing & MIG"
              text="Securely run multiple independent workloads on each provisioned GPU with time slicing and NVIDIA Multi-Instance GPU (MIG). Save cost on over-provisioning hardware when you don’t need to be."
            />
            <FeatureWithIcon
              Icon={ListCheck}
              iconGradient
              heading="Wide range of GPUs"
              text="Select from a broad range of GPU models such as NVIDIA H100, A100, T4, and many more to optimise for your specific use case and budget requirements."
            />
          </Grid>
        </Box>
      </Box>
      {gpus.length ? (
        <>
          <Box
            variant="bounding"
            py={[
              '64px!important',
              '64px!important',
              '64px!important',
              '96px!important',
            ]}
            borderTop="1px solid"
            borderColor="grey6"
          >
            <PreHeading mb={6}>Run workloads where you need them</PreHeading>
            <Heading as="h1" fontSize={[5, 5, 6, 6]} color="grey1" mb={8}>
              Your Preferred Cloud Providers
            </Heading>
            <Text
              color="grey2"
              fontSize={4}
              width={[1, 1, 1 / 2]}
              mediaQuery
              mb={13}
            >
              Deploy your GPU workloads to instances on your preferred cloud
              providers while leveraging the power of the Northflank platform.
            </Text>
            {gpuProviders.length ? (
              <Grid
                gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
                alignItems="stretch"
                gridGap={9}
              >
                {gpuProviders.map((provider) => (
                  <CloudProviderCard
                    key={provider}
                    provider={cloudProviderOptions.find(
                      (o) => o.value === provider
                    )}
                  />
                ))}
              </Grid>
            ) : null}
          </Box>
          <Box
            variant="bounding"
            py={[
              '64px!important',
              '64px!important',
              '64px!important',
              '96px!important',
            ]}
            borderTop="1px solid"
            borderColor="grey6"
          >
            <PreHeading mb={6}>
              Availability of GPU models across regions and clouds
            </PreHeading>
            <Heading as="h1" fontSize={[5, 5, 6, 6]} color="grey1" mb={8}>
              Supported GPUs
            </Heading>
            <Text
              color="grey2"
              fontSize={4}
              width={[1, 1, 1 / 2]}
              mediaQuery
              mb={13}
            >
              Deploy onto instances with the following GPUs.
            </Text>
            <DisplayGpus gpus={gpus} />
          </Box>
        </>
      ) : null}
      <Box variant="bounding" borderTop="1px solid" borderColor="grey6">
        <GetStartedCta fontSize="26px" />
      </Box>
    </>
  );
};

export default Page;
