import React from 'react';

import { LinkExternal } from '@styled-icons/boxicons-regular/LinkExternal';
import { Microchip } from '@styled-icons/boxicons-regular/Microchip';

import Box from '@northflank/components/Box';
import Icon from '@northflank/components/Icon';
import Text from '@northflank/components/Text';
import { capitalize } from '@northflank/constants-base/functions';

const CloudProviderTableType = ({ value, original }) => {
  if (value) {
    if (original.resources.gpuInfo?.gpus.length) {
      const gpu = original.resources.gpuInfo.gpus[0];
      return (
        <Box
          as="a"
          href={`/cloud/gpus/${gpu.name}`}
          color="grey1"
          _css={{ '&:hover': { color: 'primary' } }}
        >
          <Box variant="flex" alignItems="center">
            <Icon Content={Microchip} Size={14} color="grey3" mr={3} />
            <Text>
              {capitalize(value.replace('-', ' ')).replace('Hpc', 'HPC')}
            </Text>
            <Icon Content={LinkExternal} Size={12} color="grey3" ml={3} />
          </Box>
        </Box>
      );
    }
    return (
      <Text>{capitalize(value.replace('-', ' ')).replace('Hpc', 'HPC')}</Text>
    );
  }
  return '';
};

export default CloudProviderTableType;
