import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { LinkedinSquare } from '@styled-icons/boxicons-logos/LinkedinSquare';
import { Envelope } from '@styled-icons/boxicons-regular/Envelope';
import css from '@styled-system/css';
import { cloudProviderOptions } from 'marketing/src/client/components/CloudProviders/utility/cloudProviderOptions';
import styled from 'styled-components';

import Box from '@northflank/components/Box';
import Grid from '@northflank/components/Grid';
import Icon from '@northflank/components/Icon';
import { HiddenText } from '@northflank/components/marketing/Nav';
import Text from '@northflank/components/Text';
import X from '@northflank/images/icons/X';

import NFLogoType from '../../../images/svg/FooterLogo.svg';
import { pages as featurePages, solutionPages } from '../FeatureNav';

const FooterLogo = styled(NFLogoType)`
  height: 26px;
`;

const ListHeading = styled(Text)(() =>
  css({
    color: 'grey3',
    fontWeight: 'bold',
    mb: 8,
  })
);

const List = styled.ul`
  list-style: none;
`;

const StyledLink = styled.span(({ indented }) =>
  css({
    color: 'grey2',
    mb: 6,
    display: 'inline-block',
    pl: indented ? 7 : 0,
    borderLeft: indented ? '1px solid' : 'none',
    borderLeftColor: 'grey5',
    '&:hover': {
      color: 'grey0',
    },
  })
);

const getStatusColour = (status) => {
  switch (status) {
    case 'UP':
      return 'success';
    case 'HASISSUES':
    case 'UNDERMAINTENANCE':
      return 'orange';
    default:
      return 'grey5';
  }
};

const getStatusMessage = (status) => {
  switch (status) {
    case 'UP':
      return 'All systems operational';
    case 'HASISSUES':
      return 'Experiencing issues';
    case 'UNDERMAINTENANCE':
      return 'Ongoing maintenance';
    default:
      return 'Unknown';
  }
};

const Footer = () => {
  const [status, setStatus] = useState();

  useEffect(() => {
    (async () => {
      const res = await fetch('https://status.northflank.com/summary.json');
      if (res.ok) {
        const json = await res.json();
        setStatus(json);
      } else {
        setStatus(undefined);
      }
    })();
  }, []);

  const flattenedFeaturePages = featurePages.reduce((acc, cur) => {
    acc = acc.concat(cur.items);
    return acc;
  }, []);

  return (
    <Box as="footer" bg="grey9">
      <Box maxWidth="max" mx="auto" px={[10, 10, 12]} py={[12, 12, 14]}>
        <Grid gridTemplateColumns={['1fr', '1fr', '1fr 2fr']} gridGap={12}>
          <Box
            variant="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="space-between"
            height="100%"
          >
            <Link to="/">
              <FooterLogo />
              <HiddenText>Northflank</HiddenText>
            </Link>
            <Box mt={8}>
              {status && (
                <Box
                  as="a"
                  href="https://status.northflank.com"
                  target="_blank"
                  display="inline-flex"
                  alignItems="center"
                  bg="grey10"
                  border="1px solid"
                  borderColor="grey6"
                  borderRadius="30px"
                  px={9}
                  py={5}
                  _css={{
                    '&:hover': {
                      borderColor: 'grey5',
                    },
                  }}
                >
                  <Box
                    bg={getStatusColour(status.page.status)}
                    width="12px"
                    height="12px"
                    borderRadius={5}
                    position="relative"
                    mr={7}
                    _css={{
                      '&::before': {
                        content: '""',
                        display: 'block',
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        bg: getStatusColour(status.page.status),
                        borderRadius: 5,
                        filter: 'blur(14px)',
                      },
                    }}
                  />
                  <Text color="grey2">
                    {getStatusMessage(status.page.status)}
                  </Text>
                </Box>
              )}
              <Text color="grey3" mt={8}>
                © {new Date().getFullYear()} Northflank Ltd. All rights
                reserved.
              </Text>
            </Box>
          </Box>
          <Grid
            gridTemplateColumns={[
              '1fr',
              '1fr',
              'repeat(2, 1fr)',
              'repeat(4, 1fr)',
            ]}
            gridGap={12}
            alignItems="start"
          >
            <Box>
              <ListHeading>Features</ListHeading>
              <List>
                {flattenedFeaturePages.map((page) => (
                  <li key={page.path}>
                    <Link to={page.path}>
                      <StyledLink>{page.label}</StyledLink>
                    </Link>
                  </li>
                ))}
              </List>
            </Box>
            <Box>
              <ListHeading>Product</ListHeading>
              <List>
                <li>
                  <Link to="/pricing">
                    <StyledLink>Pricing</StyledLink>
                  </Link>
                </li>
                <li>
                  <Link to="/pricing#calculator">
                    <StyledLink indented>Calculator</StyledLink>
                  </Link>
                </li>
                <li>
                  <a
                    href="https://cal.com/team/northflank/northflank-demo?duration=30"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <StyledLink>Schedule a demo</StyledLink>
                  </a>
                </li>
                <li>
                  <a href="https://app.northflank.com/login">
                    <StyledLink>Log in</StyledLink>
                  </a>
                </li>
                <li>
                  <a href="https://app.northflank.com/signup">
                    <StyledLink>Sign up</StyledLink>
                  </a>
                </li>
              </List>
              <ListHeading mt={10}>Use cases</ListHeading>
              <List>
                {solutionPages[0].items.map((page) => (
                  <li key={page.path}>
                    <Link to={page.path}>
                      <StyledLink>{page.label}</StyledLink>
                    </Link>
                  </li>
                ))}
              </List>
            </Box>
            <Box>
              <ListHeading>Resources</ListHeading>
              <List>
                <li>
                  <Link to="/changelog">
                    <StyledLink>Changelog</StyledLink>
                  </Link>
                </li>
                <li>
                  <Link to="/blog">
                    <StyledLink>Blog</StyledLink>
                  </Link>
                </li>
                <li>
                  <Link to="/guides">
                    <StyledLink>Guides & tutorials</StyledLink>
                  </Link>
                </li>
                <li>
                  <Link to="/stacks">
                    <StyledLink>Stack templates</StyledLink>
                  </Link>
                </li>
                <li>
                  <StyledLink
                    as="a"
                    href="https://status.northflank.com"
                    target="_blank"
                  >
                    Status
                  </StyledLink>
                </li>
                <li>
                  <StyledLink
                    as="a"
                    href="https://northflank.com/docs/v1/application/overview"
                    target="_blank"
                  >
                    Documentation
                  </StyledLink>
                </li>
                <li>
                  <StyledLink
                    as="a"
                    href="https://northflank.com/docs/v1/api/introduction"
                    target="_blank"
                  >
                    API reference
                  </StyledLink>
                </li>
                <li>
                  <StyledLink as="a" href="/cloud">
                    Cloud providers
                  </StyledLink>
                </li>
              </List>
              <ListHeading mt={10}>Cloud Providers</ListHeading>
              <List>
                {cloudProviderOptions.map((page) =>
                  page?.footerText ? (
                    <li key={page.link}>
                      <Link to={page.link}>
                        <StyledLink>{page.footerText}</StyledLink>
                      </Link>
                    </li>
                  ) : null
                )}
              </List>
            </Box>
            <Box>
              <ListHeading>Company</ListHeading>
              <List>
                <li>
                  <Link to="/about">
                    <StyledLink>About Northflank</StyledLink>
                  </Link>
                </li>
                <li>
                  <Link to="/careers">
                    <StyledLink>Careers</StyledLink>
                  </Link>
                </li>
                <li>
                  <Link to="/legal/terms">
                    <StyledLink>Terms of Service</StyledLink>
                  </Link>
                </li>
                <li>
                  <Link to="/legal/privacy">
                    <StyledLink>Privacy Policy</StyledLink>
                  </Link>
                </li>
                <li>
                  <Link to="/contact">
                    <StyledLink>Contact us</StyledLink>
                  </Link>
                </li>
              </List>
              <ListHeading mt={10}>Registered office</ListHeading>
              <Text color="grey2" lineHeight={1.6} mb={10}>
                Company 11918540
                <br />
                20-22 Wenlock Road,
                <br />
                London, England, N1 7GU
              </Text>
              <ListHeading>Get in touch</ListHeading>
              <List>
                <li>
                  <StyledLink as="a" href="mailto:contact@northflank.com">
                    <Box variant="flex" alignItems="center">
                      <Icon Content={Envelope} Size={18} mr={6} />
                      <Text lineHeight={1}>contact@northflank.com</Text>
                    </Box>
                  </StyledLink>
                </li>
                <li>
                  <StyledLink
                    as="a"
                    href="https://www.linkedin.com/company/northflank"
                    target="_blank"
                  >
                    <Box variant="flex" alignItems="center">
                      <Icon Content={LinkedinSquare} Size={18} mr={6} />
                      <Text lineHeight={1}>Northflank</Text>
                    </Box>
                  </StyledLink>
                </li>
                <li>
                  <StyledLink
                    as="a"
                    href="https://x.com/northflank"
                    target="_blank"
                  >
                    <Box variant="flex" alignItems="center">
                      <Icon Content={X} Size={16} mr={6} />
                      <Text lineHeight={1}>@northflank</Text>
                    </Box>
                  </StyledLink>
                </li>
              </List>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Footer;
