import moment, { Moment } from 'moment';
import { getPodEndTime, getPodStartTime } from '../instances';

export const resolutionMappings = (rangeMinutes, rangeStart, rangeEnd) => {
  const range = rangeMinutes === 'custom' ? rangeEnd.diff(rangeStart, 'minutes') : rangeMinutes;

  if (range <= 60) {
    return 15;
  }
  if (range <= 3 * 60 /* 3 hrs */) {
    return 30;
  }
  if (range <= 6 * 60 /* 6 hours */) {
    return 1 * 60; // 1 mins
  }
  if (range <= 24 * 60 /* 24 hours */) {
    return 5 * 60; // 5 mins
  }
  if (range <= 48 * 60 /* 48 hours */) {
    return 15 * 60; // 15 mins
  }
  return 30 * 60; // 1 hr
};

// Align the time to the query resolution boundary
// this is to try and ensure that data points match
export const alignToResolution = (time, queryResolution) => {
  const remainder = time % queryResolution;
  if (remainder === 0) {
    return time;
  }
  if (remainder < queryResolution / 2) {
    return time - remainder;
  }
  return time + (queryResolution - remainder);
};

export const verifyQueryArgs = ({
  pod,
  fromTimestamp: _fromTimestamp,
  endTimestamp: _endTimestamp,
  executorId,
}) => {
  let fromTimestamp =
    typeof _fromTimestamp === 'number' ? _fromTimestamp : moment(_fromTimestamp).unix();
  let endTimestamp =
    typeof _endTimestamp === 'number' ? _endTimestamp : moment(_endTimestamp).unix();

  if (executorId !== 'all') {
    const minimumTs = getPodStartTime(pod);
    const maximumTs = getPodEndTime(pod);

    // Not sure if we need to do alignToResolution
    fromTimestamp = Math.max(minimumTs, fromTimestamp);
    endTimestamp = Math.min(maximumTs, endTimestamp);
  } else if (moment(fromTimestamp).diff(endTimestamp, 'days') > 30) {
    fromTimestamp = endTimestamp.add(-30, 'days');
  }

  const queryResolution = resolutionMappings(
    'custom',
    moment.unix(fromTimestamp),
    moment.unix(endTimestamp)
  );

  return {
    fromTimestamp: alignToResolution(fromTimestamp, queryResolution),
    endTimestamp: alignToResolution(endTimestamp, queryResolution),
    queryResolution,
  };
};
