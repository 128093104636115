import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { RightArrowAlt } from '@styled-icons/boxicons-regular/RightArrowAlt';
import { X } from '@styled-icons/boxicons-regular/X';
import Cookies from 'js-cookie';
import styled, { keyframes, ThemeContext } from 'styled-components';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Icon from '@northflank/components/Icon';
import Text from '@northflank/components/Text';

const expand = keyframes`
  from {
    height: 0px;
  }
  to {
    height: 45px;
  }
`;

const ExpandingBox = styled(Box)`
  height: 0px;
  overflow: hidden;
  animation: ${expand} 200ms forwards;
  animation-delay: 200ms;
  padding-right: 38px;
`;

const Announcement = ({
  announcementId,
  text,
  href,
  setAnnouncement,
  ...rest
}) => {
  const theme = useContext(ThemeContext);
  return (
    <Box borderBottom="1px solid" borderBottomColor="grey5" position="relative">
      <ExpandingBox pl={[7, 7, '38px']}>
        <Link to={href}>
          <Box
            zIndex={100}
            _css={{
              '.arrow': {
                transition: '100ms',
              },
              '&:hover': {
                '.arrow': {
                  ml: 8,
                },
              },
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="background"
              minHeight="45px"
              maxHeight="45px"
              py={7}
              {...rest}
            >
              <Box
                background={`linear-gradient(135deg, ${theme.colors.primary} -10%, ${theme.colors.success} 80%)`}
                borderRadius="30px"
                px={6}
                py={2}
                mr={7}
              >
                <Text
                  fontFamily="monospace"
                  fontSize={0}
                  fontWeight="bold"
                  lineHeight={1}
                  color="grey11"
                >
                  NEW
                </Text>
              </Box>
              <Text color="grey1" lineHeight={1}>
                {text}
              </Text>
              <Box className="arrow" ml={6}>
                <Icon Content={RightArrowAlt} Size={20} color="grey3" />
              </Box>
            </Box>
          </Box>
        </Link>
        <Box
          position="absolute"
          top="50%"
          right={[6, 6, 7]}
          _css={{ transform: 'translateY(-50%)' }}
        >
          <Button
            variant={['icon', 'noBorder', 'noBackground']}
            onClick={() => {
              setAnnouncement(undefined);
              Cookies.set('announcement-hidden-id', announcementId, {
                expires: 7,
              });
            }}
            aria-label="Dismiss notification"
          >
            <X size={16} />
          </Button>
        </Box>
      </ExpandingBox>
    </Box>
  );
};

export default Announcement;
