import React, { useContext } from 'react';

import { Anchor } from '@styled-icons/boxicons-regular/Anchor';
import { Cloud } from '@styled-icons/boxicons-regular/Cloud';
import { Coin } from '@styled-icons/boxicons-regular/Coin';
import { Customize } from '@styled-icons/boxicons-regular/Customize';
import { Desktop } from '@styled-icons/boxicons-regular/Desktop';
import { Devices } from '@styled-icons/boxicons-regular/Devices';
import { File } from '@styled-icons/boxicons-regular/File';
import { Ghost } from '@styled-icons/boxicons-regular/Ghost';
import { Group } from '@styled-icons/boxicons-regular/Group';
import { Layer } from '@styled-icons/boxicons-regular/Layer';
import { LineChartDown } from '@styled-icons/boxicons-regular/LineChartDown';
import { Map } from '@styled-icons/boxicons-regular/Map';
import { Shield } from '@styled-icons/boxicons-regular/Shield';
import { Transfer } from '@styled-icons/boxicons-regular/Transfer';
import { World } from '@styled-icons/boxicons-regular/World';
import { Wrench } from '@styled-icons/boxicons-regular/Wrench';
import { ThemeContext } from 'styled-components';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Grid from '@northflank/components/Grid';
import Heading from '@northflank/components/Heading';
import Icon from '@northflank/components/Icon';
import PrimaryFeatureBlock from '@northflank/components/marketing/PrimaryFeatureBlock';
import SEO from '@northflank/components/marketing/SEO';
import { TestimonialHeader } from '@northflank/components/marketing/Testimonial';
import Text from '@northflank/components/Text';

import DemoVideo from '../../components/DemoVideo';
import DevExpSection from '../../components/DevExpSection';
import FancyQuote from '../../components/FancyQuote';
import GetStartedCta from '../../components/GetStartedCta';
import HeroHeading from '../../components/HeroHeading';
import LargeCarousel from '../../components/LargeCarousel';
import PreHeading from '../../components/PreHeading';
import StatsSection from '../../components/StatsSection';
import texture from '../../components/Texture';
import topog from '../../components/Topog';
import UseCasesFooterLinks from '../../components/UseCasesFooterLinks';
import BYOCDiagram from '../../svg/BYOCDiagram.svg';

const BringYourOwnCloud = () => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <SEO
        title="Bring your own cloud — Use cases"
        description="Deploy workloads on your own infrastructure—AWS, GCP, Azure, on-premises, or bare-metal—with Northflank’s BYOC."
      />

      <Box
        background={`radial-gradient(60% 100% at 70% 50%, rgba(0, 147, 255, 0.1) 0%, #151C28 100%),
        radial-gradient(circle at 70% 50%, rgba(0, 0, 0, 0) 0%, #151C28 100%),
         ${topog}`}
        borderBottom="1px solid"
        borderColor="grey5"
        mt="-80px"
        pt="80px"
      >
        <Box variant="bounding">
          <Grid
            gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
            alignItems="center"
            gridGap={12}
          >
            <Box>
              <HeroHeading
                as="h1"
                fontSize={[6, 6, 7]}
                color="grey1"
                mb={8}
                style={{ letterSpacing: 'normal', lineHeight: 1.2 }}
              >
                Bring your own cloud: your cloud, your rules
              </HeroHeading>
              <Text
                fontSize={[5, 5, 6]}
                color="grey2"
                mb={[12, 12, 13]}
                css={{ lineHeight: 1.2 }}
              >
                Deploy workloads on your own infrastructure—AWS, GCP, Azure,
                on-premises, or bare-metal—with Northflank’s BYOC.
              </Text>
              <Text fontSize={4} color="grey2" mb={[12, 12, 13]}>
                Northflank provides a consistent platform to deploy workloads
                across any cloud. Abstract away complexity while preserving
                control and flexibility. We’ll manage the cluster lifecycle, and
                even import existing clusters using Northflank’s Bring Your Own
                Kubernetes (BYOK).
              </Text>
              <Grid
                gridTemplateColumns={['1fr', '1fr', 'repeat(2, max-content)']}
                gridGap={6}
                mb={6}
              >
                <a href="https://app.northflank.com/signup">
                  <Button
                    variant={['primary', 'large', 'noWrap']}
                    width={['100%', '100%', 'auto']}
                  >
                    <Icon Content={Cloud} Size={16} mr={4} />
                    Deploy in your cloud now
                  </Button>
                </a>
                <DemoVideo buttonProps={{ width: ['100%', '100%', 'auto'] }} />
              </Grid>
              <Text color="grey3">
                Prefer a live walkthrough?{' '}
                <a
                  href="https://cal.com/team/northflank/northflank-demo?duration=30"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Schedule a demo &rarr;
                </a>
              </Text>
            </Box>
            <Box width="100%">
              <BYOCDiagram />
            </Box>
          </Grid>
        </Box>
      </Box>

      <Box variant="bounding">
        <Grid
          gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
          alignItems="center"
          gridGap={14}
          mb={[13, 13, 14]}
        >
          <Box>
            <Box mb={[12, 12, 13]}>
              <PreHeading mb={6}>
                Seamless Integration with Your Infrastructure
              </PreHeading>
              <Heading fontSize={[5, 5, '32px']} mb={6}>
                Hybrid cloud made easy
              </Heading>
              <Text fontSize="18px" color="grey2">
                Automate workload deployment and operations post-commit.
                Northflank abstracts the infrastructure, pipelines, and tedious
                workflows so you can focus on shipping software—not managing
                clusters.
              </Text>
            </Box>
            <PrimaryFeatureBlock
              subFeatures={[
                {
                  Icon: Devices,
                  heading: 'Consistent experience',
                  text: `Enjoy the same developer experience on every cloud. Northflank’s web UI, CLI, and API work seamlessly across all environments, bringing features like deployment pipelines, stateful workloads, and ephemeral preview environments to every cluster.`,
                },
                {
                  Icon: Transfer,
                  heading: 'True portability',
                  text: 'Northflank builds on standards like Kubernetes APIs and Dockerfiles, allowing you to move workloads effortlessly. Switch between EKS, GKE, AKS, or any Kubernetes cluster—or even use multiple clusters across providers simultaneously.',
                },
                {
                  Icon: Desktop,
                  heading: 'Modern cloud interface',
                  text: 'Say goodbye to clunky cloud consoles. Northflank unifies disparate cloud services into a single, intuitive interface, simplifying management and improving productivity.',
                },
                {
                  Icon: Shield,
                  heading: 'Enhanced fail over',
                  text: 'Improve resilience with cross-cloud fail over capabilities. Protect against regional outages by shifting workloads to another cloud with minimal changes.',
                },
              ]}
              subFeaturesGrid="1fr"
              subFeaturesGap={[12, 12, 13]}
              subFeaturesAlign="stretch"
            />
          </Box>
          <Box height="700px" overflow="hidden" position="relative" p={8}>
            <Box
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
              background={`linear-gradient(to right, transparent 0%, transparent 70%, ${theme.colors.background} 95%)`}
              backgroundSize="cover"
              zIndex={-1}
            />
            <Box
              as="img"
              src="/images/solutions/use-cases/cluster.png"
              position="relative"
              height="100%"
              border="1px solid"
              borderColor="grey5"
              borderRadius={3}
              boxShadow="dropDown"
              zIndex={-2}
            />
          </Box>
        </Grid>
        <Grid
          gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
          alignItems="center"
          gridGap={14}
          mb={[13, 13, 14]}
        >
          <Box height="700px" overflow="hidden" position="relative" p={8}>
            <Box
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
              background={`linear-gradient(to left, transparent 0%, transparent 70%, ${theme.colors.background} 95%)`}
              backgroundSize="cover"
              zIndex={-1}
            />
            <Box
              as="img"
              src="/images/solutions/use-cases/clusterobserve-right.png"
              position="absolute"
              right={0}
              height="684px"
              border="1px solid"
              borderColor="grey5"
              borderRadius={3}
              boxShadow="dropDown"
              zIndex={-2}
            />
          </Box>
          <Box>
            <Box mb={[12, 12, 13]}>
              <PreHeading mb={6}>Stay Secure and Compliant</PreHeading>
              <Heading fontSize={[5, 5, '32px']} mb={8}>
                Security & control
              </Heading>
              <Text fontSize="18px" color="grey2">
                Northflank ensures your data and workloads remain secure within
                your own cloud environment. Meet compliance standards and
                maintain control over your infrastructure without sacrificing
                flexibility.
              </Text>
            </Box>
            <PrimaryFeatureBlock
              subFeatures={[
                {
                  Icon: Map,
                  heading: 'Data residency',
                  text: 'Keep your runtime environment and data within your own cloud boundary. Reshore all of your workloads into your VPC including databases, OSS, microservices and backgrounds jobs. Allowing you to maintain control over sensitive data and resources.',
                },
                {
                  Icon: World,
                  heading: 'Data sovereignty',
                  text: 'Manage your regional infrastructure to comply with data sovereignty laws. Organize data within legislative borders to keep customer information safe and secure.',
                },
                {
                  Icon: Group,
                  heading: 'Built-in multi-tenancy',
                  text: 'Unlock multi-tenancy with sandboxed runtimes, secure network policies, service mesh with mTLS, namespace isolation, secret injection, disaster recovery, and more—all out of the box.',
                },
              ]}
              subFeaturesGrid="1fr"
              subFeaturesGap={[12, 12, 13]}
              subFeaturesAlign="stretch"
            />
          </Box>
        </Grid>
        <Grid
          gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
          alignItems="center"
          gridGap={14}
          mb={[13, 13, 14]}
        >
          <Box>
            <Box mb={[12, 12, 13]}>
              <PreHeading mb={6}>
                Reduce Expenses, Increase Efficiency
              </PreHeading>
              <Heading fontSize={[5, 5, '32px']} mb={8}>
                Optimise costs
              </Heading>
              <Text fontSize="18px" color="grey2">
                Northflank helps you minimize costs by optimizing resource usage
                and leveraging existing investments. Streamline infrastructure
                management and focus on delivering business value without
                worrying about overspending.
              </Text>
            </Box>
            <PrimaryFeatureBlock
              subFeatures={[
                {
                  Icon: LineChartDown,
                  heading: 'Efficient resource usage',
                  text: 'Minimize infrastructure sprawl and right-size your cloud resources. Pay only for what you need, optimizing costs without sacrificing performance.',
                },
                {
                  Icon: Wrench,
                  heading: 'Simplified management',
                  text: 'Streamline infrastructure management. Allow your engineers to spend less time writing YAML and more time delivering business value.',
                },
                {
                  Icon: Coin,
                  heading: 'Leverage existing investments',
                  text: 'Utilize existing cloud credits, commitments, and discounts. Maximize the value of your current cloud agreements and reduce overhead.',
                },
              ]}
              subFeaturesGrid="1fr"
              subFeaturesGap={[12, 12, 13]}
              subFeaturesAlign="stretch"
            />
          </Box>
          <Box height="700px" overflow="hidden" position="relative" p={8}>
            <Box
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
              background={`linear-gradient(to right, transparent 0%, transparent 70%, ${theme.colors.background} 95%)`}
              backgroundSize="cover"
              zIndex={-1}
            />
            <Box
              as="img"
              src="/images/solutions/use-cases/metrics.png"
              position="relative"
              height="100%"
              border="1px solid"
              borderColor="grey5"
              borderRadius={3}
              boxShadow="dropDown"
              zIndex={-2}
            />
          </Box>
        </Grid>
        <FancyQuote
          body={
            <>
              Northflank is way easier than gluing a bunch of tools together to
              spin up apps and databases. It’s the ideal platform to deploy
              containers in our cloud account, avoiding the brain damage of big
              cloud and Kubernetes. It’s more powerful and flexible than
              traditional PaaS – all within our VPC.{' '}
              <Text as="span" color="success" fontWeight={500}>
                Northflank has become a go-to way to deploy workloads at Sentry
              </Text>
              .
            </>
          }
          attribution={
            <TestimonialHeader
              name="David Cramer"
              position="Co-Founder and CPO @ Sentry"
              avatar="/images/landing/quotes/david-c.jpeg"
              linkedin="https://www.linkedin.com/in/dmcramer/"
              mb={0}
            />
          }
        />
      </Box>

      <Box background="linear-gradient(to bottom, #141c28 0%, #192338 30%, #192338 70%, #141c28 100%)">
        <Box variant="bounding" py={[12, 12, '120px !important']}>
          <DevExpSection />
        </Box>
      </Box>

      <Box variant="bounding">
        <StatsSection />
      </Box>

      <Box
        background={`linear-gradient(to bottom, #141c28 0%, transparent 30%, #192338 70%, #141c28 100%), ${texture}, linear-gradient(to bottom, #141c28 0%, #192338 30%, #192338 70%, #141c28 100%)`}
      >
        <Box variant="bounding" py={[12, 12, '120px !important']}>
          <Grid
            gridTemplateColumns={['1fr', '1fr', '1fr 1.2fr']}
            gridGap={[12, 12, 13]}
            mb={[12, 12, 13]}
          >
            <Box>
              <PreHeading mb={6}>Conquering DIY Deployment Hurdles</PreHeading>
              <Heading fontSize={[5, 5, '32px']} color="grey1" mb={8}>
                Challenges with container deployment on your cloud?
              </Heading>
              <Text fontSize="18px" color="grey2">
                Deploying containers on your own cloud infrastructure can be
                daunting. Managing clusters, configuring networks, ensuring
                security, and handling scaling can consume valuable time and
                resources. Northflank BYOC removes these obstacles, allowing you
                to focus on what matters most—shipping great software.
              </Text>
            </Box>
            <Box as="img" src="/images/landing/k8s.png" width="100%" />
          </Grid>
          <Box mb={[12, 12, 13]}>
            <PrimaryFeatureBlock
              subFeatures={[
                {
                  Icon: Anchor,
                  heading: 'Feeling stuck with traditional PaaS?',
                  text: 'Traditional PaaS solutions can be limiting as your applications grow in complexity. They often lack the flexibility and control needed to handle advanced workloads, compliance requirements, or integration with existing infrastructure. Northflank frees you from these constraints, providing a scalable platform that grows with you.',
                },
                {
                  Icon: Ghost,
                  heading: 'Daunted by deploying containers on your cloud?',
                  text: 'Deploying containers on your own cloud infrastructure can be overwhelming. Managing Kubernetes clusters, configuring networking, ensuring security, and handling scaling requires significant expertise and resources. Northflank simplifies this process, handling the heavy lifting so you can focus on building great software.',
                },
                {
                  Icon: Customize,
                  heading: 'Your platform is a product',
                  text: `When you build a platform, you're delivering a product to developers. Balancing abstraction with control is a major design challenge. Additionally, DIY platforms come with other difficulties: ensuring great developer experience (DX), customer success, developer adoption, multi-tenancy, security, and more.`,
                },
                {
                  Icon: Layer,
                  heading: 'Unified abstraction for all workloads',
                  text: 'Achieve true portability with a standard interface for abstracting infrastructure. Run your apps and services the same way, regardless of the underlying cloud or environment.',
                },
                {
                  Icon: File,
                  heading: 'Regulatory compliance is challenging',
                  text: 'As a platform provider, your customers need you to meet the regulations that govern their business. HIPAA, SOC 2, and ISO 27001 are common. GDPR and CCPA may require you to move to new geographic areas. Northflank provides the flexibility to move to new clouds and regions.',
                },
              ]}
              subFeaturesGrid={['1fr', '1fr', 'repeat(2, 1fr)']}
              subFeaturesGap={[12, 12, 13]}
              subFeaturesAlign="stretch"
            />
          </Box>
          <FancyQuote
            body={
              <>
                Northflank is the first batteries-included developer platform
                that doesn’t suffer from the invisible ceilings that hover over
                its competitors.{' '}
                <Text as="span" color="success" fontWeight={500}>
                  We could have built all of Slack with Northflank
                </Text>{' '}
                – and we would have, had it been available.
              </>
            }
            attribution={
              <TestimonialHeader
                name="Keith Adams"
                position="Formerly Chief Architect Slack, Facebook, VMware, GP @ Pebblebed"
                avatar="/images/landing/quotes/keith.jpeg"
                linkedin="https://linkedin.com/in/keith-adams-pb/"
                mb={0}
              />
            }
          />
        </Box>
      </Box>

      <Box py={[12, 12, '96px']}>
        <LargeCarousel
          images={[
            '/images/solutions/use-cases/backupschedule.png',
            '/images/solutions/use-cases/cluster.png',
            '/images/solutions/use-cases/clusterobserve.png',
            '/images/solutions/use-cases/dashboard.png',
            '/images/solutions/use-cases/jobruns.png',
            '/images/solutions/use-cases/logs.png',
            '/images/solutions/use-cases/metrics.png',
            '/images/solutions/use-cases/preview.png',
            '/images/solutions/use-cases/previewduration.png',
            '/images/solutions/use-cases/releaseflow.png',
          ]}
        />
      </Box>

      <Box variant="bounding">
        <UseCasesFooterLinks mb={[12, 12, 13]} />
        <GetStartedCta />
      </Box>
    </>
  );
};

export default BringYourOwnCloud;
