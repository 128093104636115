import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { CodeAlt } from '@styled-icons/boxicons-regular/CodeAlt';
import { Map } from '@styled-icons/boxicons-regular/Map';
import { UserCircle } from '@styled-icons/boxicons-regular/UserCircle';
import styled, { ThemeContext } from 'styled-components';

import Box from '@northflank/components/Box';
import Grid from '@northflank/components/Grid';
import Heading from '@northflank/components/Heading';
import Image from '@northflank/components/Image';
import PrimaryFeatureBlock from '@northflank/components/marketing/PrimaryFeatureBlock';
import SEO from '@northflank/components/marketing/SEO';
import { TestimonialHeader } from '@northflank/components/marketing/Testimonial';
import Text from '@northflank/components/Text';

import FancyQuote from '../components/FancyQuote';
import HeroHeading from '../components/HeroHeading';
import PreHeading from '../components/PreHeading';
import topog from '../components/Topog';
import CH from '../svg/flag/ch.svg';
import CO from '../svg/flag/co.svg';
import CZ from '../svg/flag/cz.svg';
import DE from '../svg/flag/de.svg';
import ES from '../svg/flag/es.svg';
import GB from '../svg/flag/gb.svg';
import LV from '../svg/flag/lv.svg';
import US from '../svg/flag/us.svg';

const Will = '/images/headshot/Will.jpg';
const Fred = '/images/headshot/Fred.png';
const Chase = '/images/headshot/Chase.jpg';
const Anon = '/images/headshot/Anon.png';

const Vertex = '/images/investors/vertex.svg';
const Bain = '/images/investors/bain.svg';
const Uncorrelated = '/images/investors/uncorrelated.svg';
const Pebblebed = '/images/investors/pebblebed.svg';
const Expa = '/images/investors/expa.svg';
const Kindred = '/images/investors/kindred-ventures.png';
const Stride = '/images/investors/stride-white.png';
const Tapestry = '/images/investors/tapestry.svg';
const TheFamily = '/images/investors/thefamily.png';
const Mongodb = '/images/investors/mongodb.svg';
const AlexisLequoc = '/images/investors/alexis.png';
const LaurentPerrin = '/images/investors/laurentperrin.png';
const SteveManuel = '/images/landing/quotes/steve.jpeg';
const ZachHolman = '/images/investors/zachholman.png';
const IanLivingstone = '/images/investors/ianlivingstone.jpeg';
const DavidCramer = '/images/investors/davidcramer.jpeg';
const KrisBeevers = '/images/investors/krisbeevers.jpeg';
const ScottJohnston = '/images/investors/scottjohnston.jpeg';
const OskariSaarenmaa = '/images/investors/oskarisaarenmaa.jpeg';
const JeffSettle = '/images/investors/jeffsettle.jpeg';
const JerryTing = '/images/investors/jerryting.jpeg';

const flags = {
  gb: <GB />,
  de: <DE />,
  ch: <CH />,
  cz: <CZ />,
  lv: <LV />,
  co: <CO />,
  es: <ES />,
  us: <US />,
};

const FlagWrapper = styled(Box)`
  svg {
    height: 100%;
  }
`;

const Flag = ({ code }) => (
  <FlagWrapper height="24px" width="24px" borderRadius={5} boxShadow="dropDown">
    {flags[code]}
  </FlagWrapper>
);

const Member = ({ image, name, position, country }) => {
  const theme = useContext(ThemeContext);
  return (
    <Box
      background={`radial-gradient(91% 198% at -3.5% -14%, ${theme.colors.grey9} 0%, ${theme.colors.grey11} 100%)`}
      border="1px solid"
      borderColor="grey5"
      borderRadius={3}
      boxShadow="dropDown"
      p={9}
    >
      <Box
        variant="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        mb={7}
      >
        <Image
          src={image}
          width="90px"
          borderRadius={5}
          boxShadow="dropDown"
          alt={`${name} headshot`}
        />
        {country && <Flag code={country} />}
      </Box>
      <Box variant="flex" alignItems="center" mb={3}>
        <Text color="grey1" fontWeight={600} fontSize={4}>
          {name}
        </Text>
      </Box>
      <Text
        fontWeight={600}
        fontSize={1}
        textTransform="uppercase"
        color="grey3"
      >
        {position}
      </Text>
    </Box>
  );
};

const JoinUs = ({ image, text, link }) => {
  const theme = useContext(ThemeContext);
  return (
    <Box
      background={`radial-gradient(91% 198% at -3.5% -14%, ${theme.colors.grey9} 0%, ${theme.colors.grey11} 100%)`}
      border="1px dashed"
      borderColor="primary"
      borderRadius={3}
      boxShadow="dropDown"
      p={9}
    >
      <Image src={image} width="80px" mb={4} alt="anonymous headshot" />
      <Text fontSize={3} color="grey3">
        {text} <Link to={link}>open positions</Link>.
      </Text>
    </Box>
  );
};

const MainInvestor = ({ image, headshot, name, position, link, ...rest }) => {
  return (
    <Box width="245px" height="72px" {...rest}>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <Box variant="flex" flexDirection="column" alignItems="center">
          <Image
            src={image}
            height="72px"
            width="auto"
            maxWidth="220px"
            alt={position}
            css={{
              objectFit: 'contain',
              filter: 'grayscale(1) brightness(0.8)',
              transition: '200ms',
              '&:hover': {
                filter: 'grayscale(0) brightness(1.2)',
                transform: 'scale(1.025)',
              },
            }}
          />
        </Box>
      </a>
    </Box>
  );
};

const Investor = ({ image, name, position, ...rest }) => (
  <Box variant="flex" alignItems="center" flex={0} {...rest}>
    {!!image && (
      <Box
        minWidth="72px"
        minHeight="72px"
        maxWidth="72px"
        maxHeight="72px"
        mr={8}
      >
        {typeof image === 'object' ? (
          <Box variant="flex">
            {image.map((img, i) => (
              <Image
                key={i}
                src={img}
                borderRadius="50%"
                boxShadow="dropDown"
                width="72px"
                height="72px"
                ml={i > 0 && '-30px'}
              />
            ))}
          </Box>
        ) : (
          <Image
            src={image}
            borderRadius="50%"
            boxShadow="dropDown"
            width="72px"
            height="72px"
            alt={`${name} headshot`}
          />
        )}
      </Box>
    )}
    <Box>
      <Text color="grey1" fontWeight={600} fontSize={3}>
        {typeof name === 'object' ? name.join(', ') : name}
      </Text>
      {position && (
        <Text
          color="grey3"
          fontWeight={600}
          fontSize={1}
          textTransform="uppercase"
          mt={2}
        >
          {position}
        </Text>
      )}
    </Box>
  </Box>
);

const MasonryImage = ({ src, alt, height }) => (
  <Box
    as="img"
    src={src}
    alt={alt}
    display="block"
    width={['100%', '100%', '100%', 'auto']}
    height={['auto', 'auto', 'auto', height]}
    border="1px solid"
    borderColor="grey5"
    borderRadius={3}
    boxShadow="dropDown"
  />
);

const About = () => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <SEO
        title="About"
        description="Northflank is a developer tools and infrastructure company, building a powerful unified platform that redefines PaaS, CI/CD, and real-time observability — making DevOps accessible to all."
      />
      <Box
        background={`radial-gradient(60% 100% at 70% 50%, rgba(0, 147, 255, 0.1) 0%, #151C28 100%),
        radial-gradient(circle at 70% 50%, rgba(0, 0, 0, 0) 0%, #151C28 100%),
         ${topog}`}
        borderBottom="1px solid"
        borderColor="grey5"
        mt="-80px"
        pt="80px"
      >
        <Box variant="bounding">
          <Box width={[1, 1, 2 / 3]}>
            <HeroHeading
              as="h1"
              fontSize={[6, 6, 7]}
              color="grey1"
              mb={12}
              style={{ letterSpacing: 'normal', lineHeight: 1.2 }}
            >
              Empowering developers to ship workloads and not infrastructure
            </HeroHeading>
            <Text fontSize={4} color="grey2">
              At Northflank, our mission is to empower developers to focus on
              creating applications by removing infrastructure complexity. By
              making workloads—not infrastructure—the priority, we help
              organizations accelerate delivery and write more of the code their
              customers pay for.
            </Text>
          </Box>
        </Box>
      </Box>

      <Box overflow="hidden">
        <Box variant="bounding">
          <Box position={['static', 'static', 'relative']}>
            <Box
              position={['relative', 'relative', 'absolute']}
              top={0}
              left={[0, 0, '50%']}
              width="100%"
              height="100%"
              zIndex={-1}
              overflow="hidden"
              _css={{ aspectRatio: ['16 / 12', '16 / 12', 'unset'] }}
            >
              <Box
                position="absolute"
                top={0}
                bottom={0}
                left={0}
                right={0}
                background={[
                  `linear-gradient(to bottom, transparent 0%, transparent 60%, ${theme.colors.background} 90%), linear-gradient(to right, transparent 0%, transparent 30%, ${theme.colors.background} 100%)`,
                  `linear-gradient(to bottom, transparent 0%, transparent 60%, ${theme.colors.background} 90%), linear-gradient(to right, transparent 0%, transparent 30%, ${theme.colors.background} 100%)`,
                  `linear-gradient(to bottom, transparent 0%, transparent 60%, ${theme.colors.background} 95%), linear-gradient(to right, transparent 0%, transparent 30%, ${theme.colors.background} 50%)`,
                ]}
                backgroundSize="cover"
                zIndex={-1}
              />
              <Box
                as="img"
                src="/images/solutions/use-cases/dashboard.png"
                position="absolute"
                top="10px"
                left={['10px', '10px', '60px']}
                width={['auto', 'auto', '1400px']}
                height={['1000px', '1000px', 'auto']}
                border="1px solid"
                borderColor="grey5"
                borderRadius={3}
                boxShadow="dropDown"
                zIndex={-2}
              />
            </Box>
            <Box width={[1, 1, 1 / 2]}>
              <PreHeading mb={6}>What we stand for</PreHeading>
              <Heading fontSize={[5, 5, '32px']} color="grey1" mb={8}>
                Our principles
              </Heading>
              <Text fontSize="18px" color="grey2" mb={[12, 12, 13]}>
                We are determined to simplify application deployment in the era
                of cloud native. Whether it’s on AWS, Azure, Google, or
                bare-metal, developers should be able to build and deploy their
                applications in a consistent, reliable, and cost-effective
                manner.
              </Text>
              <PrimaryFeatureBlock
                subFeatures={[
                  {
                    Icon: Map,
                    heading: 'Your workloads where you need them',
                    text: 'Developers should have the flexibility to deploy their workloads wherever they want without worrying about lock-in. Yes, portability matters.',
                  },
                  {
                    Icon: UserCircle,
                    heading: 'Developer experience matters',
                    text: 'You shouldn’t have to be an infrastructure expert to operate line one. Developer experience not only matters for software engineers, but also the ops teams who work alongside them. Infrastructure should be self-service and intuitive while preserving flexibility and control.',
                  },
                  {
                    Icon: CodeAlt,
                    heading: 'Workloads over infrastructure',
                    text: 'Let’s shift the focus back to delivering valuable workloads instead of wrestling with complex infrastructure. Your infrastructure should work for you, not the other way around.',
                  },
                ]}
                subFeaturesGrid="1fr"
                subFeaturesGap={[12, 12, 13]}
                subFeaturesAlign="stretch"
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box background="linear-gradient(to bottom, #141c28 0%, #192338 30%, #192338 70%, #141c28 100%)">
        <Box variant="bounding" py={[12, 12, '120px !important']}>
          <Heading
            fontSize={[5, 5, 6]}
            color="grey1"
            textAlign="center"
            mb={[12, 12, 13]}
          >
            Leadership
          </Heading>
          <Grid
            gridTemplateColumns={[
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(2, 1fr)',
              'repeat(4, 1fr)',
            ]}
            gridGap={9}
            alignItems="stretch"
          >
            <Member
              image={Will}
              name="Will Stewart"
              position="Co-founder & CEO"
              country="gb"
            />
            <Member
              image={Fred}
              name="Frederik Brix"
              position="Co-founder & CTO"
              country="de"
            />
            <Member
              image={Chase}
              name="Chase Roberts"
              position="COO"
              country="us"
            />
            <JoinUs
              image={Anon}
              text="Interested in joining Northflank? Check out our"
              link="/careers"
            />
          </Grid>
        </Box>
      </Box>

      <Box variant="bounding">
        <Box width={[1, 1, 1 / 2]} mx="auto" textAlign="center" mb={13}>
          <Heading fontSize={[5, 5, 6]} color="grey1" mb={6}>
            Our Investors
          </Heading>
          <Text fontSize={4} color="grey3">
            We’re a venture-backed company with support from a brilliant team of
            investors.
          </Text>
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          gridGap={[12, 12, 14]}
          mb={14}
        >
          <MainInvestor
            image={Bain}
            name="Bain Capital"
            link="https://www.baincapital.com"
          />

          <MainInvestor
            image={Vertex}
            name="Vertex Ventures"
            link="https://vvus.com"
          />
          <MainInvestor
            image={Kindred}
            name="Kindred Ventures"
            link="https://kindredventures.com"
          />
          <MainInvestor
            image={Pebblebed}
            name="Pebblebed"
            link="https://pebblebed.com"
          />
          <MainInvestor
            image={Tapestry}
            name="Tapestry"
            link="https://tapestry.vc"
          />
          <MainInvestor
            image={Uncorrelated}
            name="Uncorrelated"
            link="https://uncorrelated.com"
          />
          <MainInvestor
            image={Mongodb}
            name="MongoDB"
            link="https://mongodb.com"
          />
          <MainInvestor image={Expa} name="Expa" link="https://expa.com" />
          <MainInvestor image={Stride} name="Stride" link="https://stride.vc" />
          <MainInvestor
            image={TheFamily}
            name="The Family"
            link="https://thefamily.co"
          />
        </Box>
        <Grid
          gridTemplateColumns={[
            'repeat(1, 1fr)',
            'repeat(1, 1fr)',
            'repeat(2, 1fr)',
            'repeat(4, 1fr)',
          ]}
          gridGap={[12, 12, 13]}
        >
          <Investor
            image={AlexisLequoc}
            name="Alexis Le-Quoc"
            position="Co-founder & CTO @ Datadog"
          />
          <Investor
            image={DavidCramer}
            name="David Cramer"
            position="Co-founder & CPO @ Sentry"
          />
          <Investor
            image={IanLivingstone}
            name="Ian Livingstone"
            position="Snyk"
          />
          <Investor
            image={JeffSettle}
            name="Jeff Settle"
            position="Enterprise lead @ Databricks"
          />
          <Investor
            image={JerryTing}
            name="Jerry Ting"
            position="Founder & VP @ Evisort"
          />
          <Investor
            image={KrisBeevers}
            name="Kris Beevers"
            position="Co-founder @ NetBox Labs, NS1"
          />
          <Investor
            image={LaurentPerrin}
            name="Laurent Perrin"
            position="Co-founder & CTO @ Front"
          />
          <Investor
            image={OskariSaarenmaa}
            name="Oskari Saarenmaa"
            position="Co-founder & CEO @ Aiven"
          />
          <Investor
            image={ScottJohnston}
            name="Scott Johnston"
            position="CEO @ Docker"
          />
          <Investor
            image={SteveManuel}
            name="Steve Manuel"
            position="Co-founder/CEO @ dylibso"
          />
          <Investor
            image={ZachHolman}
            name="Zach Holman"
            position="Co-founder @ WorkOn, GitHub"
          />
          <Box
            bg="grey9"
            width="256px"
            height="72px"
            display="flex"
            alignItems="center"
            border="1px solid"
            borderColor="grey5"
            borderRadius={2}
            boxShadow="dropDown"
            p={6}
          >
            <Image src={Anon} width="50px" alt="anonymous headshot" mr={7} />
            <Text fontSize={3} color="grey2">
              And plenty of other brilliant angels
            </Text>
          </Box>
        </Grid>
      </Box>

      <Box background="linear-gradient(to bottom, #141c28 0%, #192338 30%, #192338 70%, #141c28 100%)">
        <Box variant="bounding" py={[12, 12, '120px !important']}>
          <FancyQuote
            body={
              <>
                Northflank is the first batteries-included developer platform
                that doesn’t suffer from the invisible ceilings that hover over
                its competitors.{' '}
                <Text as="span" color="success" fontWeight={500}>
                  We could have built all of Slack with Northflank
                </Text>{' '}
                – and we would have, had it been available.
              </>
            }
            attribution={
              <TestimonialHeader
                name="Keith Adams"
                position="Formerly Chief Architect Slack, Facebook, VMware, GP @ Pebblebed"
                avatar="/images/landing/quotes/keith.jpeg"
                linkedin="https://linkedin.com/in/keith-adams-pb/"
                mb={0}
              />
            }
          />
        </Box>
      </Box>

      <Box variant="bounding">
        <Grid
          gridAutoFlow={['row', 'row', 'row', 'column']}
          alignItems="stretch"
          gridGap={8}
          mb={8}
        >
          <MasonryImage
            src="/images/about/1.jpg"
            alt="Fred, Chase, Will"
            height="560px"
          />
          <MasonryImage
            src="/images/about/2.png"
            alt="Will, Fred, Lavrenti"
            height="560px"
          />
        </Grid>
        <Grid
          gridAutoFlow={['row', 'row', 'row', 'column']}
          alignItems="stretch"
          gridGap={8}
        >
          <MasonryImage
            src="/images/about/team.jpg"
            alt="Team"
            height="440px"
          />
          <MasonryImage src="/images/about/4.jpg" alt="Will" height="440px" />
        </Grid>
      </Box>
    </>
  );
};

export default About;
