import React, { useContext } from 'react';

import { Cloud } from '@styled-icons/boxicons-regular/Cloud';
import { CodeAlt } from '@styled-icons/boxicons-regular/CodeAlt';
import { Cog } from '@styled-icons/boxicons-regular/Cog';
import { Data } from '@styled-icons/boxicons-regular/Data';
import { Desktop } from '@styled-icons/boxicons-regular/Desktop';
import { Devices } from '@styled-icons/boxicons-regular/Devices';
import { Directions } from '@styled-icons/boxicons-regular/Directions';
import { Extension } from '@styled-icons/boxicons-regular/Extension';
import { GitCommit } from '@styled-icons/boxicons-regular/GitCommit';
import { Key } from '@styled-icons/boxicons-regular/Key';
import { LayerPlus } from '@styled-icons/boxicons-regular/LayerPlus';
import { Pointer } from '@styled-icons/boxicons-regular/Pointer';
import { Poll } from '@styled-icons/boxicons-regular/Poll';
import { Search } from '@styled-icons/boxicons-regular/Search';
import { Terminal } from '@styled-icons/boxicons-regular/Terminal';
import { Dashboard } from '@styled-icons/boxicons-solid/Dashboard';
import { ThemeContext } from 'styled-components';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Grid from '@northflank/components/Grid';
import Heading from '@northflank/components/Heading';
import Icon from '@northflank/components/Icon';
import PrimaryFeatureBlock from '@northflank/components/marketing/PrimaryFeatureBlock';
import SEO from '@northflank/components/marketing/SEO';
import { TestimonialHeader } from '@northflank/components/marketing/Testimonial';
import Text from '@northflank/components/Text';

import DemoVideo from '../../components/DemoVideo';
import DevExpSection from '../../components/DevExpSection';
import FancyQuote from '../../components/FancyQuote';
import GetStartedCta from '../../components/GetStartedCta';
import HeroHeading from '../../components/HeroHeading';
import LargeCarousel from '../../components/LargeCarousel';
import PreHeading from '../../components/PreHeading';
import StatsSection from '../../components/StatsSection';
import texture from '../../components/Texture';
import topog from '../../components/Topog';
import UseCasesFooterLinks from '../../components/UseCasesFooterLinks';
import DevStepsDiagram from '../../svg/DevStepsDiagram.svg';

const SelfServicePage = () => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <SEO
        title="Self-service developer experience — Use cases"
        description="Treat self-service and DX as first-class concerns. All you need is a dockerfile or Buildpack to get started."
      />

      <Box
        background={`radial-gradient(60% 100% at 70% 50%, rgba(0, 147, 255, 0.1) 0%, #151C28 100%),
        radial-gradient(circle at 70% 50%, rgba(0, 0, 0, 0) 0%, #151C28 100%),
         ${topog}`}
        borderBottom="1px solid"
        borderColor="grey5"
        mt="-80px"
        pt="80px"
      >
        <Box variant="bounding">
          <Grid
            gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
            alignItems="center"
            gridGap={12}
          >
            <Box>
              <HeroHeading
                as="h1"
                fontSize={[6, 6, 7]}
                color="grey1"
                mb={8}
                style={{ letterSpacing: 'normal', lineHeight: 1.2 }}
              >
                Self-service with exceptional DX
              </HeroHeading>
              <Text
                fontSize={[5, 5, 6]}
                color="grey2"
                mb={[12, 12, 13]}
                css={{ lineHeight: 1.2 }}
              >
                Treat self-service and developer experience (DX) as first-class
                concerns. All you need is a dockerfile or Buildpack to get
                started.
              </Text>
              <Text fontSize={4} color="grey2" mb={[12, 12, 13]}>
                Get metrics, logs, and other observability features
                out-of-the-box. Set up your favourite database or hook up a CDN
                in just a few clicks. Northflank’s managed addons and
                integrations make it all possible.
              </Text>
              <Grid
                gridTemplateColumns={['1fr', '1fr', 'repeat(2, max-content)']}
                gridGap={6}
                mb={6}
              >
                <a href="https://app.northflank.com/signup">
                  <Button
                    variant={['primary', 'large', 'noWrap']}
                    width={['100%', '100%', 'auto']}
                  >
                    <Icon Content={Cloud} Size={16} mr={4} />
                    Try Northflank Now
                  </Button>
                </a>
                <DemoVideo buttonProps={{ width: ['100%', '100%', 'auto'] }} />
              </Grid>
              <Text color="grey3">
                Prefer a live walkthrough?{' '}
                <a
                  href="https://cal.com/team/northflank/northflank-demo?duration=30"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Schedule a demo &rarr;
                </a>
              </Text>
            </Box>
            <Box width="100%">
              <DevStepsDiagram />
            </Box>
          </Grid>
        </Box>
      </Box>

      <Box variant="bounding">
        <Grid
          gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
          alignItems="center"
          gridGap={14}
          mb={[13, 13, 14]}
        >
          <Box>
            <Box mb={[12, 12, 13]}>
              <PreHeading mb={6}>
                Streamline Your Deployment Pipeline
              </PreHeading>
              <Heading fontSize={[5, 5, '32px']} mb={6}>
                From Git to production in no time
              </Heading>
              <Text fontSize="18px" color="grey2">
                You don’t have to be a Kubernetes expert to use Kubernetes like
                one. Northflank organises and reduces complexity for container
                infra. For example, you can automatically spin up preview
                environments on every commit or only when a keyword is in the
                commit message.
              </Text>
            </Box>
            <PrimaryFeatureBlock
              subFeatures={[
                {
                  Icon: GitCommit,
                  heading: 'Build on every commit',
                  text: 'Once you push your code, our platform handles everything—from Docker builds to deployments, observability, auto-scaling, and disaster recovery.',
                },
                {
                  Icon: LayerPlus,
                  heading: 'Instant full-stack previews',
                  text: 'Automatically create full-stack preview environments—including databases, microservices, and jobs—for every feature branch. Triggered via Git PRs, these ephemeral environments let you test and iterate faster.',
                },
                {
                  Icon: Terminal,
                  heading: 'Command line debugging',
                  text: 'Interact with resources from your terminal. Tail logs, proxy services, execute into containers, and more.',
                },
                {
                  Icon: Devices,
                  heading: 'Consistent environments',
                  text: 'Prevent the “it works on my machine” problem. Ensure everyone is testing in the same environment that mirrors production, reducing bugs and integration issues.',
                },
              ]}
              subFeaturesGrid="1fr"
              subFeaturesGap={[12, 12, 13]}
              subFeaturesAlign="stretch"
            />
          </Box>
          <Box height="700px" overflow="hidden" position="relative" p={8}>
            <Box
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
              background={`linear-gradient(to right, transparent 0%, transparent 70%, ${theme.colors.background} 95%)`}
              backgroundSize="cover"
              zIndex={-1}
            />
            <Box
              as="img"
              src="/images/solutions/use-cases/jobruns.png"
              position="relative"
              height="100%"
              border="1px solid"
              borderColor="grey5"
              borderRadius={3}
              boxShadow="dropDown"
              zIndex={-2}
            />
          </Box>
        </Grid>

        <Grid
          gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
          alignItems="center"
          gridGap={14}
          mb={[13, 13, 14]}
        >
          <Box height="700px" overflow="hidden" position="relative" p={8}>
            <Box
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
              background={`linear-gradient(to left, transparent 0%, transparent 70%, ${theme.colors.background} 95%)`}
              backgroundSize="cover"
              zIndex={-1}
            />
            <Box
              as="img"
              src="/images/solutions/use-cases/dashboard.png"
              position="absolute"
              right={0}
              height="684px"
              border="1px solid"
              borderColor="grey5"
              borderRadius={3}
              boxShadow="dropDown"
              zIndex={-2}
            />
          </Box>
          <Box>
            <Box mb={[12, 12, 13]}>
              <PreHeading mb={6}>
                The best possible developer experience
              </PreHeading>
              <Heading fontSize={[5, 5, '32px']} mb={6}>
                Work in the UX that works for you
              </Heading>
              <Text fontSize="18px" color="grey2">
                Work in whatever way you want. Use Northflank’s thoughtfully
                designed web UI, API or the streamlined CLI. Deploy to a fully
                featured, well-organised, and expertly designed platform. Join
                10,000+ developers already deploying with Northflank.
              </Text>
            </Box>
            <PrimaryFeatureBlock
              subFeatures={[
                {
                  Icon: Desktop,
                  heading: 'Modern cloud interface',
                  text: 'Say goodbye to clunky cloud consoles. Northflank unifies disparate cloud services into a single, intuitive interface, simplifying management and improving productivity.',
                },
                {
                  Icon: Dashboard,
                  heading: 'One consistent experience',
                  text: 'Forget outdated cloud consoles and enjoy the same developer experience on every cloud.  Your deployment pipelines, stateful workloads, and ephemeral preview environments are portable between cloud providers.',
                },
                {
                  Icon: CodeAlt,
                  heading: 'Any language, any framework',
                  text: 'Your platform is only as powerful as the workloads it supports. Northflank is truly polyglot, supporting all languages and frameworks. Not just Next.js. Not just MySQL. Not just AWS.',
                },
                {
                  Icon: Pointer,
                  heading: 'Exceptional DX',
                  text: 'Define your applications as reusable templates, and Northflank will manage the infrastructure. Details like the port number are inferred from your dockerfile. Manage and change workload settings through Northflank’s web UI or streamlined CLI. Skip the YAML hell that accompanies most container platforms.',
                },
              ]}
              subFeaturesGrid="1fr"
              subFeaturesGap={[12, 12, 13]}
              subFeaturesAlign="stretch"
            />
          </Box>
        </Grid>
        <Grid
          gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
          alignItems="center"
          gridGap={14}
          mb={[13, 13, 14]}
        >
          <Box>
            <Box mb={[12, 12, 13]}>
              <PreHeading mb={6}>A complete platform experience</PreHeading>
              <Heading fontSize={[5, 5, '32px']} mb={6}>
                DevOps like a pro
              </Heading>
              <Text fontSize="18px" color="grey2">
                Northflank provides out-of-the-box support for logging, metrics,
                CDN integrations, container registry integrations, domain names,
                and certificate management. Service meshes are pre-installed,
                beautified and programmatically managed.
              </Text>
            </Box>
            <PrimaryFeatureBlock
              subFeatures={[
                {
                  Icon: Poll,
                  heading: 'Logs and metrics at the workload level',
                  text: 'Northflank offers built in logs and metrics at the workload level. Debug your workloads without calling the DevOps or SRE team. Monitor the state of all your resources with pipeline overviews, health status, and alerts.',
                },
                {
                  Icon: Cog,
                  heading: 'Enhance workloads with advanced features',
                  text: 'Easily configure service mesh, automated backups, and auto-scaling. Want to deploy on spot instances? Do it in a few clicks on Northflank. Start simple, and reach for deeper customisation options when you need them.',
                },
                {
                  Icon: Key,
                  heading: 'Secure by default env vars',
                  text: 'Easily inject environment variables and arguments at runtime without exposing them in your codebase. Set up your secrets in a few minutes, and trust they’re securely encrypted.',
                },
                {
                  Icon: Extension,
                  heading: 'Plug in your favourite solutions',
                  text: 'Northflank is a pick-and-mix platform. Already have a build tool? Great, we’ll support that. And, a favourite observability provider? Log sinks and native integrations are available for the top tools. Like using GitHub Actions for CI? We support that, too.',
                },
              ]}
              subFeaturesGrid="1fr"
              subFeaturesGap={[12, 12, 13]}
              subFeaturesAlign="stretch"
            />
          </Box>
          <Box height="700px" overflow="hidden" position="relative" p={8}>
            <Box
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
              background={`linear-gradient(to right, transparent 0%, transparent 70%, ${theme.colors.background} 95%)`}
              backgroundSize="cover"
              zIndex={-1}
            />
            <Box
              as="img"
              src="/images/solutions/use-cases/metrics.png"
              position="relative"
              height="100%"
              border="1px solid"
              borderColor="grey5"
              borderRadius={3}
              boxShadow="dropDown"
              zIndex={-2}
            />
          </Box>
        </Grid>

        <FancyQuote
          body={
            <>
              Northflank is way easier than gluing a bunch of tools together to
              spin up apps and databases. It’s the ideal platform to deploy
              containers in our cloud account, avoiding the brain damage of big
              cloud and Kubernetes. It’s more powerful and flexible than
              traditional PaaS – all within our VPC.{' '}
              <Text as="span" color="success" fontWeight={500}>
                Northflank has become a go-to way to deploy workloads at Sentry
              </Text>
              .
            </>
          }
          attribution={
            <TestimonialHeader
              name="David Cramer"
              position="Co-Founder and CPO @ Sentry"
              avatar="/images/landing/quotes/david-c.jpeg"
              linkedin="https://www.linkedin.com/in/dmcramer/"
              mb={0}
            />
          }
        />
      </Box>

      <Box background="linear-gradient(to bottom, #141c28 0%, #192338 30%, #192338 70%, #141c28 100%)">
        <Box variant="bounding" py={[12, 12, '120px !important']}>
          <DevExpSection />
        </Box>
      </Box>

      <Box variant="bounding">
        <StatsSection />
      </Box>

      <Box
        background={`linear-gradient(to bottom, #141c28 0%, transparent 30%, #192338 70%, #141c28 100%), ${texture}, linear-gradient(to bottom, #141c28 0%, #192338 30%, #192338 70%, #141c28 100%)`}
      >
        <Box variant="bounding" py={[12, 12, '120px !important']}>
          <Grid
            gridTemplateColumns={['1fr', '1fr', '1fr 1.2fr']}
            gridGap={[12, 12, 13]}
            mb={[12, 12, 13]}
          >
            <Box>
              <PreHeading mb={6}>
                Overcoming Common Development Challenges
              </PreHeading>
              <Heading fontSize={[5, 5, '32px']} color="grey1" mb={8}>
                Why Preview Environments Matter
              </Heading>
              <Text fontSize="18px" color="grey2">
                Building realistic preview environments is essential for modern
                development, yet it presents challenges that can slow down your
                workflow. Northflank addresses these issues head-on.
              </Text>
            </Box>
            <Box as="img" src="/images/landing/k8s.png" width="100%" />
          </Grid>
          <Box mb={[12, 12, 13]}>
            <PrimaryFeatureBlock
              subFeatures={[
                {
                  Icon: Directions,
                  heading: 'Complexity of Full-Stack Previews',
                  text: 'Setting up previews that include databases, microservices, and jobs is complex. Northflank simplifies this with automated, reproducible environments.',
                },
                {
                  Icon: Search,
                  heading: 'Seamless Integration from Git to Production',
                  text: 'Define every step post-commit with ease. Northflank integrates with your CI/CD pipelines, ensuring smooth transitions from development to production.',
                },
                {
                  Icon: Data,
                  heading: 'Efficient Scaling',
                  text: 'Manage your ephemeral environments effectively. Northflank provides tools to scale up when needed and scale down to save resources, all automatically.',
                },
              ]}
              subFeaturesGrid={['1fr', '1fr', 'repeat(2, 1fr)']}
              subFeaturesGap={[12, 12, 13]}
              subFeaturesAlign="stretch"
            />
          </Box>
          <FancyQuote
            body={
              <>
                Northflank is the first batteries-included developer platform
                that doesn’t suffer from the invisible ceilings that hover over
                its competitors.{' '}
                <Text as="span" color="success" fontWeight={500}>
                  We could have built all of Slack with Northflank
                </Text>{' '}
                – and we would have, had it been available.
              </>
            }
            attribution={
              <TestimonialHeader
                name="Keith Adams"
                position="Formerly Chief Architect Slack, Facebook, VMware, GP @ Pebblebed"
                avatar="/images/landing/quotes/keith.jpeg"
                linkedin="https://linkedin.com/in/keith-adams-pb/"
                mb={0}
              />
            }
          />
        </Box>
      </Box>

      <Box py={[12, 12, '96px']}>
        <LargeCarousel
          images={[
            '/images/solutions/use-cases/backupschedule.png',
            '/images/solutions/use-cases/cluster.png',
            '/images/solutions/use-cases/clusterobserve.png',
            '/images/solutions/use-cases/dashboard.png',
            '/images/solutions/use-cases/jobruns.png',
            '/images/solutions/use-cases/logs.png',
            '/images/solutions/use-cases/metrics.png',
            '/images/solutions/use-cases/preview.png',
            '/images/solutions/use-cases/previewduration.png',
            '/images/solutions/use-cases/releaseflow.png',
          ]}
        />
      </Box>

      <Box variant="bounding">
        <UseCasesFooterLinks mb={[12, 12, 13]} />
        <GetStartedCta />
      </Box>
    </>
  );
};

export default SelfServicePage;
