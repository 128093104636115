export enum ByocRequestModifierProfiles {
  HIGH_DENSITY = 'high-density',
  BALANCED = 'balanced',
  LOW_DENSITY = 'low-density',
}

export const predefinedByocRequestModifierProfiles = [
  {
    id: ByocRequestModifierProfiles.HIGH_DENSITY,
    name: 'High Density',
    description:
      'Optimises for pod density on the cluster by reducing the requests for resources. This can be problematic if workloads require most of the memory and CPU they have access to.',

    definition: {
      services: {
        cpu: 0.2,
        memory: 0.6,
      },
      jobs: {
        cpu: 0.2,
        memory: 0.6,
      },
      builds: {
        cpu: 0.2,
        memory: 0.2,
      },
      addons: {
        cpu: 0.2,
        memory: 0.6,
      },
    },
  },
  {
    id: ByocRequestModifierProfiles.BALANCED,
    name: 'Balanced',
    description:
      'Low CPU requests, but higher memory requests optimise for pod density while decreasing the probability of OOM due to workload memory requirements.',
    definition: {
      services: {
        cpu: 0.5,
        memory: 0.8,
      },
      jobs: {
        cpu: 0.5,
        memory: 0.8,
      },
      builds: {
        cpu: 0.2,
        memory: 0.5,
      },
      addons: {
        cpu: 0.5,
        memory: 0.8,
      },
    },
    default: true,
  },
  {
    id: ByocRequestModifierProfiles.LOW_DENSITY,
    name: 'Low Density',
    description:
      'Optimises for stability and resource availability by matching requests and limits for memory, while allowing for a small CPU over commit.',

    definition: {
      services: {
        cpu: 0.8,
        memory: 1,
      },
      jobs: {
        cpu: 0.8,
        memory: 1,
      },
      builds: {
        cpu: 0.5,
        memory: 1,
      },
      addons: {
        cpu: 0.8,
        memory: 1,
      },
    },
  },
];

export const defaultByocRequestModifierProfile = ByocRequestModifierProfiles.BALANCED;

export const defaultByocRequestModifierProfileOption = predefinedByocRequestModifierProfiles.find(
  (o) => o.id === defaultByocRequestModifierProfile
);

if (!defaultByocRequestModifierProfileOption) {
  throw new Error(
    `defaultByocRequestModifierProfileOption not found for default profile ${defaultByocRequestModifierProfile}`
  );
}

export const requestModifierValues = {
  services: {
    cpu: {
      min: 0.2,
      max: 1,
      default: defaultByocRequestModifierProfileOption.definition.services.cpu,
    },
    memory: {
      min: 0.4,
      max: 1,
      default: defaultByocRequestModifierProfileOption.definition.services.memory,
    },
  },
  jobs: {
    cpu: {
      min: 0.2,
      max: 1,
      default: defaultByocRequestModifierProfileOption.definition.jobs.cpu,
    },
    memory: {
      min: 0.4,
      max: 1,
      default: defaultByocRequestModifierProfileOption.definition.jobs.memory,
    },
  },
  builds: {
    cpu: {
      min: 0.1,
      max: 1,
      default: defaultByocRequestModifierProfileOption.definition.builds.cpu,
    },
    memory: {
      min: 0.1,
      max: 1,
      default: defaultByocRequestModifierProfileOption.definition.builds.memory,
    },
  },
  addons: {
    danger: true,
    cpu: {
      min: 0.2,
      max: 1,
      default: defaultByocRequestModifierProfileOption.definition.addons.cpu,
    },
    memory: {
      min: 0.4,
      max: 1,
      default: defaultByocRequestModifierProfileOption.definition.addons.memory,
    },
  },
};
