import { getResourcesFromPlan } from '@northflank/constants-base';

export * from './byoc';
export * from './resource-cost-calculation';

export const cpuOnDemandMultiplier = 2.5;
export const ramOnDemandMultiplier = 2.5;

export const useModifiedBuildJobPricing = true;
export const buildJobMultiplier = 2;

export const hoursPerMonth = 30 * 24;
export const avgSecondsPerMonth = hoursPerMonth * 3600;

// Currently based on preemptible pricing
export const baseResourcePrices = [
  {
    version: 'v1',
    cpu: 12.0, // per month & CPU unit
    memory: 4.0, // per month & GB
    storage: {
      ssd: 0.3,
      hdd: 0.15,
    }, // per month & GB
  },
  {
    version: 'v2',
    cpu: 12.0, // per month & CPU unit
    memory: 6.0, // per month & GB
    storage: {
      ssd: 0.3,
      hdd: 0.15,
    }, // per month & GB
    default: true,
  },
];

export const calculateCPURate = (plan, onDemand, northflankResourcesPrices, hourly) => {
  const planResources = getResourcesFromPlan(plan);

  let rate = planResources.cpu * northflankResourcesPrices.cpu;

  if (hourly) rate /= hoursPerMonth;

  if (onDemand) return rate * cpuOnDemandMultiplier;
  return rate;
};

export const calculateRAMRate = (plan, onDemand, northflankResourcesPrices, hourly) => {
  const planResources = getResourcesFromPlan(plan);
  let rate = planResources.memory * (northflankResourcesPrices.memory / 1024);

  if (hourly) rate /= hoursPerMonth;

  if (onDemand) return rate * ramOnDemandMultiplier;
  return rate;
};

const convertHourlyTo1Min = (hourlyPrice) => {
  const price = hourlyPrice / 60;
  if (useModifiedBuildJobPricing) return price * buildJobMultiplier;
  return price;
};

const convertHourlyTo10kMin = (hourlyPrice) => {
  const price = (hourlyPrice / 60) * 1000;
  if (useModifiedBuildJobPricing) return price * buildJobMultiplier;
  return price;
};

export const calculate1MinPlanPrice = (plan, onDemand = false, northflankResourcesPrices) =>
  convertHourlyTo1Min(
    calculateCPURate(plan, onDemand, northflankResourcesPrices, true) +
      calculateRAMRate(plan, onDemand, northflankResourcesPrices, true)
  );

export const calculate1kMinPlanPrice = (plan, onDemand = false, northflankResourcesPrices) =>
  convertHourlyTo10kMin(
    calculateCPURate(plan, onDemand, northflankResourcesPrices, true) +
      calculateRAMRate(plan, onDemand, northflankResourcesPrices, true)
  );

export const calculateHourlyPlanPrice = (plan, onDemand = false, northflankResourcesPrices) => {
  return (
    calculateCPURate(plan, onDemand, northflankResourcesPrices, true) +
    calculateRAMRate(plan, onDemand, northflankResourcesPrices, true)
  );
};

export const calculateMonthlyPlanPrice = (plan, onDemand = false, northflankResourcesPrices) => {
  return (
    calculateCPURate(plan, onDemand, northflankResourcesPrices, false) +
    calculateRAMRate(plan, onDemand, northflankResourcesPrices, false)
  );
};

export const calculateHourlyStoragePrice = (
  storage,
  storageClassName,
  northflankResourcesPrices
) => {
  return ((northflankResourcesPrices.storage[storageClassName] / 1024) * storage) / hoursPerMonth;
};

export const calculateMonthlyStoragePrice = (
  storage,
  storageClassName,
  northflankResourcesPrices
) => {
  return (northflankResourcesPrices.storage[storageClassName] / 1024) * storage;
};

export const calculateHourlyServicePrice = (
  plan,
  instances,
  onDemand,
  northflankResourcesPrices
) => {
  return calculateHourlyPlanPrice(plan, onDemand, northflankResourcesPrices) * instances;
};

export const calculateMonthlyServicePrice = (
  plan,
  instances,
  onDemand,
  northflankResourcesPrices
) => {
  return calculateMonthlyPlanPrice(plan, onDemand, northflankResourcesPrices) * instances;
};

export const calculateMonthlyAddonPrice = (
  cpuResource,
  ramResource,
  storage,
  storageClassName,
  replicas,
  onDemand = false,
  northflankResourcesPrices
) => {
  const storageRate =
    calculateMonthlyStoragePrice(storage, storageClassName, northflankResourcesPrices) * replicas;
  const rate =
    calculateMonthlyPlanPrice({ cpuResource, ramResource }, onDemand, northflankResourcesPrices) *
    replicas;
  return rate + storageRate;
};

export const calculateExamplePrice = (price, number, duration) =>
  price * ((number * duration) / 1000);
