const blockedPrefixes = [
  'k8s.io',
  'kubernetes.io',
  'northflank.com',
  'istio.io',
  'helm.sh',
  'linkerd.io',
  'prometheus.io',
  'checksum',
];

const blockedAnnotationKeys = [];

const blockedLabelKeys = [
  'app',
  'pod-template-hash',
  'controller-revision-hash',
  // addons
  'application',
  'patroni-role',
  'team',
  'status',
];

const nfLabelPrefix = 'nf';

export const validateCustomAnnotations = (annotations) => {
  const validationResults = Object.entries(annotations || {}).map(([key, value]) => {
    if ((typeof value !== 'object' && typeof value !== 'string') || value === null) {
      return { success: false, message: `Annotation value must be a string, array or object.` };
    }

    const prefix = blockedPrefixes.find((p) => new RegExp(p).test(key));
    if (prefix) {
      return { success: false, message: `Annotation prefix containing ${prefix} is not allowed.` };
    }

    const blockedKey = blockedAnnotationKeys.find((k) => key.startsWith(k));
    if (blockedKey) {
      return { success: false, message: `Annotation key ${blockedKey} is not allowed.` };
    }

    return { success: true };
  });

  const error = validationResults.find((r) => !r.success);

  return error || { success: true };
};

export const validateCustomLabels = (labels) => {
  const validationResults = Object.entries(labels || {}).map(([key, value]) => {
    if (typeof value !== 'string') {
      return { success: false, message: `Label value must be a string.` };
    }

    if (key.startsWith(nfLabelPrefix)) {
      return { success: false, message: `Label may not start with ${nfLabelPrefix}.` };
    }

    const prefix = blockedPrefixes.find((p) => new RegExp(p).test(key));
    if (prefix) {
      return { success: false, message: `Label prefix containing ${prefix} is not allowed.` };
    }

    const blockedKey = blockedLabelKeys.find((k) => key.startsWith(k));
    if (blockedKey) {
      return { success: false, message: `Label key ${blockedKey} is not allowed.` };
    }

    return { success: true };
  });

  const error = validationResults.find((r) => !r.success);

  return error || { success: true };
};
