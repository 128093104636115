import Prism from 'prismjs';

import { useEffect } from 'react';

const highlight = () => {
  Prism.highlightAll(false, () => {
    const snippets = document.querySelectorAll(`pre[class*='language-']`);
    snippets.forEach((el) => {
      el.classList.add('line-numbers');
    });
  });
};

const useSyntaxHighlighting = (deps = [], delay = 500) => {
  useEffect(() => {
    setTimeout(highlight, delay);
  }, deps);
};

export default useSyntaxHighlighting;
