import React from 'react';

import Legal from '@northflank/components/marketing/Legal';
import SEO from '@northflank/components/marketing/SEO';

import terms from './content.md';

const Index = () => (
  <>
    <SEO title="Terms of Service" />
    <Legal
      title="Terms of Service"
      description={`Northflank's Terms of Service`}
      html={terms.html}
    />
  </>
);

export default Index;
