const dark = {
  name: 'dark',
  shadowIntensity: 0.24,
  colors: {
    backgroundGradient: 'linear-gradient(#192338 0%, #0e131f 100%)',
    background: '#151C28',
    primary: '#0093FF',
    primaryLight: '#33A9FF',
    success: '#2DD881',
    danger: '#DE1A1A',
    warning: '#F7B801',
    done: '#6a5acd',
    grey0: '#FFFFFF',
    grey1: '#EBF1F7',
    grey2: '#C6D3E2',
    grey3: '#7A89A1',
    grey4: '#57637A',
    grey5: '#334670',
    grey6: '#202e50',
    grey7: '#192338',
    grey8: '#202C46',
    grey9: '#192338',
    grey10: '#1D273F',
    grey11: '#131A2A',
  },
};

const light = {
  name: 'light',
  shadowIntensity: 0.06,
  colors: {
    backgroundGradient: 'linear-gradient(#FFFFFF 0%, #FCFCFD 100%)',
    background: '#FFFFFF',
    primary: '#0093FF',
    primaryLight: '#33A9FF',
    success: '#48ab73',
    danger: '#DE1A1A',
    warning: '#F7B801',
    done: '#6a5acd',
    grey0: '#000000',
    grey1: '#0E111A',
    grey2: '#343F52',
    grey3: '#657894',
    grey4: '#57637A',
    grey5: '#DDE7F0',
    grey6: '#D7E3F1',
    grey7: '#FAFAFD',
    grey8: '#FFFFFF',
    grey9: '#FFFFFF',
    grey10: '#FCFCFD',
    grey11: '#FFFFFF',
  },
};

const blackout = {
  name: 'blackout',
  shadowIntensity: 0.24,
  colors: {
    backgroundGradient: 'linear-gradient(#000000 0%, #000000 100%)',
    background: '#000000',
    primary: '#0093FF',
    primaryLight: '#33A9FF',
    success: '#2DD881',
    danger: '#DE1A1A',
    warning: '#F7B801',
    done: '#6a5acd',
    grey0: '#FFFFFF',
    grey1: '#EBF1F7',
    grey2: '#C6D3E2',
    grey3: '#7A89A1',
    grey4: '#57637A',
    grey5: '#282c2f',
    grey6: '#202033',
    grey7: '#161624',
    grey8: '#0D0D18',
    grey9: '#000000',
    grey10: '#000000',
    grey11: '#000000',
  },
};

const solarizedLight = {
  name: 'solarizedLight',
  shadowIntensity: 0.12,
  colors: {
    backgroundGradient: 'linear-gradient(#fdf6e3 0%, #eee8d5 100%)',
    background: '#fdf6e3',
    primary: '#268bd2',
    primaryLight: '#33A9FF',
    success: '#2aa198',
    danger: '#dc322f',
    warning: '#b58900',
    done: '#6a5acd',
    grey0: '#002b36',
    grey1: '#073642',
    grey2: '#073642',
    grey3: '#586e75',
    grey4: '#657b83',
    grey5: '#C1C5BB',
    grey6: '#93a1a1',
    grey7: '#eee8d5',
    grey8: '#fdf6e3',
    grey9: '#fdf6e3',
    grey10: '#eee8d5',
    grey11: '#fdf6e3',
  },
};

const solarizedDark = {
  name: 'solarizedDark',
  shadowIntensity: 0.24,
  colors: {
    backgroundGradient: 'linear-gradient(#002b36 0%, #073642 100%)',
    background: '#fdf6e3',
    primary: '#268bd2',
    primaryLight: '#33A9FF',
    success: '#2aa198',
    danger: '#dc322f',
    warning: '#b58900',
    done: '#6a5acd',
    grey0: '#fdf6e3',
    grey1: '#eee8d5',
    grey2: '#eee8d5',
    grey3: '#657b83',
    grey4: '#586e75',
    grey5: '#365A62',
    grey6: '#1f454e',
    grey7: '#04313C',
    grey8: '#073642',
    grey9: '#002b36',
    grey10: '#073642',
    grey11: '#002b36',
  },
};

const darcula = {
  name: 'darcula',
  shadowIntensity: 0.24,
  colors: {
    backgroundGradient: 'linear-gradient(#242424 0%, #2B2B2B 100%)',
    background: '#242424',
    primary: '#0093FF',
    primaryLight: '#33A9FF',
    success: '#7bac5e',
    danger: '#dc322f',
    warning: '#FFC66D',
    done: '#6a5acd',
    grey0: '#A9B7C6',
    grey1: '#BBBBBB',
    grey2: '#BBBBBB',
    grey3: '#87939A',
    grey4: '#6F7070',
    grey5: '#555555',
    grey6: '#3C3F41',
    grey7: '#373737',
    grey8: '#313335',
    grey9: '#242424',
    grey10: '#313335',
    grey11: '#242424',
  },
};

const halloween = {
  name: 'halloween',
  shadowIntensity: 0.24,
  colors: {
    backgroundGradient: 'linear-gradient(#252422 0%, #252422 100%)',
    background: '#252422',
    primary: '#ff7200',
    primaryLight: '#fc743d',
    success: '#7bac5e',
    danger: '#dc322f',
    warning: '#FFC66D',
    done: '#6a5acd',
    grey0: '#FFFFFF',
    grey1: '#EBF1F7',
    grey2: '#C6D3E2',
    grey3: '#EB5E28',
    grey4: '#847D75',
    grey5: '#403D39',
    grey6: '#514754',
    grey7: '#443722',
    grey8: '#322F2A',
    grey9: '#252422',
    grey10: '#403D39',
    grey11: '#252422',
  },
};

const winterWonderland = {
  name: 'winterWonderland',
  shadowIntensity: 0.12,
  colors: {
    backgroundGradient: 'linear-gradient(#E1E8EF 0%, #E1E8EF 100%)',
    background: '#E1E8EF',
    primary: '#256832',
    primaryLight: '#3a7746',
    success: '#256832',
    danger: '#871616',
    warning: '#FFC66D',
    done: '#6a5acd',
    grey0: '#2d2f33',
    grey1: '#414b56',
    grey2: '#586973',
    grey3: '#586973',
    grey4: '#586973',
    grey5: '#BBD0DD',
    grey6: '#C6D3E2',
    grey7: '#c9d7ef',
    grey8: '#d9e1e7',
    grey9: '#E1E8EF',
    grey10: '#e7ecf2',
    grey11: '#E1E8EF',
  },
};

export default {
  dark,
  light,
  blackout,
  solarizedLight,
  solarizedDark,
  darcula,
  halloween,
  winterWonderland,
};
