import styledCss from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import styled from 'styled-components';
import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  typography,
} from 'styled-system';

import { variant } from '@northflank/utilities/styled-system/variant';

const Box = styled.div.withConfig({
  shouldForwardProp,
})(
  space,
  layout,
  grid,
  background,
  color,
  flexbox,
  typography,
  border,
  position,
  shadow,
  ({ _css }) => styledCss({ ..._css }),
  ({ hover, hoverBorder, theme, pt, pb }) =>
    variant({
      variants: {
        dashboard: {
          mb: 9,
        },
        icon: {
          minWidth: '24px',
        },
        flex: {
          display: 'flex',
        },
        verticalCenter: {
          alignItems: 'center',
        },
        cursor: {
          cursor: 'pointer',
        },
        hover: {
          '&:hover': {
            bg: theme?.colors?.[hover] || 'grey7',
            border: hoverBorder && '1px solid',
            borderColor: hoverBorder && theme.colors[hoverBorder],
          },
        },
        header: {
          bg: 'grey10',
          color: 'grey3',
          fontSize: 0,
          fontFamily: 'monospace',
        },
        bounding: {
          maxWidth: 'max',
          mx: 'auto',
          px: [10, 10, 12],
          py: [12, 12, '96px'],
          pt,
          pb,
        },
        boundingWide: {
          maxWidth: 'wide',
          mx: 'auto',
          px: [10, 10, 12],
          py: [12, 12, '96px'],
        },
      },
    })
);

export default Box;
