import React from 'react';

import Text from '@northflank/components/Text';
import {
  calculate1kMinPlanPrice,
  calculateExamplePrice,
} from '@northflank/pricing';

const UsageExample = ({
  selectedPlan,
  isJob,
  isBuildService,
  onDemand,
  freeProject,
  northflankResourcesPrices,
}) => {
  if (freeProject) return null;
  if (!(selectedPlan && (isJob || isBuildService))) return null;
  const pricePer1kMin = calculate1kMinPlanPrice(
    selectedPlan,
    onDemand,
    northflankResourcesPrices
  );

  let label = 'build';
  if (isJob) label = 'job';

  let action = 'built';
  if (isJob) action = 'ran it';

  let number = 100;
  if (isJob) number = 100;

  let duration = 3;
  if (isJob) duration = 15;
  return (
    <Text fontSize={1} color="grey3" ml={2} mt={4}>
      If your {label} took {duration} minutes to complete and you {action}{' '}
      {number} times a month, the cost would be $
      {parseFloat(
        calculateExamplePrice(pricePer1kMin, number, duration)
      ).toFixed(2)}
    </Text>
  );
};

export default UsageExample;
