import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import css from '@styled-system/css';
import styled from 'styled-components';

import Box from '@northflank/components/Box';
import Heading from '@northflank/components/Heading';
import MarkdownBody from '@northflank/components/marketing/MarkdownBody';

const Items = styled.ul`
  list-style: none;
  li + li {
    margin-top: 16px;
  }
`;

const StyledLink = styled.span(({ active }) =>
  css({
    cursor: 'pointer',
    color: active ? 'grey1' : 'grey2',
    fontWeight: active ? 600 : 400,
    textDecoration: 'none',
    fontSize: [3, 3, 4],
    transition: '200ms',
    '&:hover': {
      color: 'grey1',
    },
  })
);

const Legal = ({ title, html }) => {
  const { pathname } = useLocation();
  return (
    <Box variant="bounding">
      <Box variant="flex" flexDirection={['column', 'column', 'row']}>
        <Box width={[1, 1, 1 / 5]} mb={12}>
          <Items>
            <li>
              <Link to="/legal/terms">
                <StyledLink active={pathname.endsWith('/terms')}>
                  Terms of Service
                </StyledLink>
              </Link>
            </li>
            <li>
              <Link to="/legal/privacy">
                <StyledLink active={pathname.endsWith('/privacy')}>
                  Privacy Policy
                </StyledLink>
              </Link>
            </li>
            <li>
              <Link to="/legal/cookies">
                <StyledLink active={pathname.endsWith('/cookies')}>
                  Cookie Policy
                </StyledLink>
              </Link>
            </li>
          </Items>
        </Box>
        <Box width={[1, 1, 4 / 5]}>
          <Heading as="h1" fontSize={[6, 6, 7]} color="grey1" mb={13}>
            {title}
          </Heading>
          <MarkdownBody dangerouslySetInnerHTML={{ __html: html }} />
        </Box>
      </Box>
    </Box>
  );
};

export default Legal;
