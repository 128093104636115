import React, { useContext } from 'react';

import { AddToQueue } from '@styled-icons/boxicons-regular/AddToQueue';
import { Cloud } from '@styled-icons/boxicons-regular/Cloud';
import { Customize } from '@styled-icons/boxicons-regular/Customize';
import { Devices } from '@styled-icons/boxicons-regular/Devices';
import { Directions } from '@styled-icons/boxicons-regular/Directions';
import { Edit } from '@styled-icons/boxicons-regular/Edit';
import { GitPullRequest } from '@styled-icons/boxicons-regular/GitPullRequest';
import { Group } from '@styled-icons/boxicons-regular/Group';
import { History } from '@styled-icons/boxicons-regular/History';
import { LockAlt } from '@styled-icons/boxicons-regular/LockAlt';
import { Refresh } from '@styled-icons/boxicons-regular/Refresh';
import { Rocket } from '@styled-icons/boxicons-regular/Rocket';
import { Shuffle } from '@styled-icons/boxicons-regular/Shuffle';
import { Time } from '@styled-icons/boxicons-regular/Time';
import { Trip } from '@styled-icons/boxicons-regular/Trip';
import { ThemeContext } from 'styled-components';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Grid from '@northflank/components/Grid';
import Heading from '@northflank/components/Heading';
import Icon from '@northflank/components/Icon';
import PrimaryFeatureBlock from '@northflank/components/marketing/PrimaryFeatureBlock';
import SEO from '@northflank/components/marketing/SEO';
import { TestimonialHeader } from '@northflank/components/marketing/Testimonial';
import Text from '@northflank/components/Text';

import DemoVideo from '../../components/DemoVideo';
import DevExpSection from '../../components/DevExpSection';
import FancyQuote from '../../components/FancyQuote';
import GetStartedCta from '../../components/GetStartedCta';
import HeroHeading from '../../components/HeroHeading';
import LargeCarousel from '../../components/LargeCarousel';
import PreHeading from '../../components/PreHeading';
import StatsSection from '../../components/StatsSection';
import texture from '../../components/Texture';
import topog from '../../components/Topog';
import UseCasesFooterLinks from '../../components/UseCasesFooterLinks';
import DevStepsDiagram from '../../svg/DevStepsDiagram.svg';

const PreviewEnvironmentsPage = () => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <SEO
        title="Preview environments — Use cases"
        description="Spin up ephemeral, full-stack preview environments—including databases, microservices, and jobs—on every pull request."
      />

      <Box
        background={`radial-gradient(60% 100% at 70% 50%, rgba(0, 147, 255, 0.1) 0%, #151C28 100%),
        radial-gradient(circle at 70% 50%, rgba(0, 0, 0, 0) 0%, #151C28 100%),
         ${topog}`}
        borderBottom="1px solid"
        borderColor="grey5"
        mt="-80px"
        pt="80px"
      >
        <Box variant="bounding">
          <Grid
            gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
            alignItems="center"
            gridGap={12}
          >
            <Box>
              <HeroHeading
                as="h1"
                fontSize={[6, 6, 7]}
                color="grey1"
                mb={8}
                style={{ letterSpacing: 'normal', lineHeight: 1.2 }}
              >
                Full-stack preview environments: fast and fully automated
              </HeroHeading>
              <Text
                fontSize={[5, 5, 6]}
                color="grey2"
                mb={[12, 12, 13]}
                css={{ lineHeight: 1.2 }}
              >
                Spin up ephemeral, full-stack preview environments—including
                databases, microservices, and jobs—on every pull request.
              </Text>
              <Text fontSize={4} color="grey2" mb={[12, 12, 13]}>
                Accelerate development with immediate previews triggered via Git
                PRs and feature branches. Move faster and fearlessly promote
                workloads between environments.
              </Text>
              <Grid
                gridTemplateColumns={['1fr', '1fr', 'repeat(2, max-content)']}
                gridGap={6}
                mb={6}
              >
                <a href="https://app.northflank.com/signup">
                  <Button
                    variant={['primary', 'large', 'noWrap']}
                    width={['100%', '100%', 'auto']}
                  >
                    <Icon Content={Cloud} Size={16} mr={4} />
                    Try Northflank Now
                  </Button>
                </a>
                <DemoVideo buttonProps={{ width: ['100%', '100%', 'auto'] }} />
              </Grid>
              <Text color="grey3">
                Prefer a live walkthrough?{' '}
                <a
                  href="https://cal.com/team/northflank/northflank-demo?duration=30"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Schedule a demo &rarr;
                </a>
              </Text>
            </Box>
            <Box width="100%">
              <DevStepsDiagram />
            </Box>
          </Grid>
        </Box>
      </Box>

      <Box variant="bounding">
        <Grid
          gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
          alignItems="center"
          gridGap={14}
          mb={[13, 13, 14]}
        >
          <Box>
            <Box mb={[12, 12, 13]}>
              <PreHeading mb={6}>
                Accelerate Your Development Workflow
              </PreHeading>
              <Heading fontSize={[5, 5, '32px']} mb={6}>
                Instant full-stack previews
              </Heading>
              <Text fontSize="18px" color="grey2">
                Automatically create full-stack preview environments—including
                databases, microservices, and jobs—for every feature branch.
                Triggered via Git PRs, these ephemeral environments let you test
                and iterate faster.
              </Text>
            </Box>
            <PrimaryFeatureBlock
              subFeatures={[
                {
                  Icon: GitPullRequest,
                  heading: 'Triggered by Git pull requests',
                  text: 'Integrate seamlessly with your Git workflow. Every pull request or feature branch can automatically spin up a complete preview environment, mirroring your production stack.',
                },
                {
                  Icon: Customize,
                  heading: 'Ephemeral environments with full-stack support',
                  text: 'Include everything from databases to microservices and background jobs. Reproduce complex environments effortlessly, enabling thorough testing and faster feedback loops.',
                },
                {
                  Icon: Rocket,
                  heading: 'Snapshots and caching for speed',
                  text: 'Leverage snapshots and caching to recreate environments rapidly. Reduce build times and resource usage. Focus your teams on development rather than infrastructure.',
                },
                {
                  Icon: Shuffle,
                  heading: 'Seamless integration with your workflow',
                  text: 'Use Northflank’s intuitive interface or IaC templates to manage your preview environments. Reference version control metadata and automate repetitive tasks for a smoother developer experience.',
                },
                {
                  Icon: Edit,
                  heading: 'Craft your workflows, not your workflow tool',
                  text: 'Build workflows on day one, ready for preview environments and easy promotion. Tools like Argo and Tekton require you to build the system before you can use it. This leads to “YAML wack-a-mole,” where you’re constantly rewriting YAML to ensure workflows incorporate changes.',
                },
              ]}
              subFeaturesGrid="1fr"
              subFeaturesGap={[12, 12, 13]}
              subFeaturesAlign="stretch"
            />
          </Box>
          <Box height="700px" overflow="hidden" position="relative" p={8}>
            <Box
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
              background={`linear-gradient(to right, transparent 0%, transparent 70%, ${theme.colors.background} 95%)`}
              backgroundSize="cover"
              zIndex={-1}
            />
            <Box
              as="img"
              src="/images/solutions/use-cases/preview.png"
              position="relative"
              height="100%"
              border="1px solid"
              borderColor="grey5"
              borderRadius={3}
              boxShadow="dropDown"
              zIndex={-2}
            />
          </Box>
        </Grid>

        <Grid
          gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
          alignItems="center"
          gridGap={14}
          mb={[13, 13, 14]}
        >
          <Box height="700px" overflow="hidden" position="relative" p={8}>
            <Box
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
              background={`linear-gradient(to left, transparent 0%, transparent 70%, ${theme.colors.background} 95%)`}
              backgroundSize="cover"
              zIndex={-1}
            />
            <Box
              as="img"
              src="/images/solutions/use-cases/preview.png"
              position="absolute"
              right={0}
              height="684px"
              border="1px solid"
              borderColor="grey5"
              borderRadius={3}
              boxShadow="dropDown"
              zIndex={-2}
            />
          </Box>
          <Box>
            <Box mb={[12, 12, 13]}>
              <PreHeading mb={6}>Enhance Developer Experience</PreHeading>
              <Heading fontSize={[5, 5, '32px']} mb={6}>
                Move faster with better tools
              </Heading>
              <Text fontSize="18px" color="grey2">
                Empower your team with tools that enhance productivity.
                Northflank’s preview environments reduce friction, allowing
                developers to test features quickly and get instant feedback.
              </Text>
            </Box>
            <PrimaryFeatureBlock
              subFeatures={[
                {
                  Icon: Refresh,
                  heading: 'Fast feedback loops',
                  text: 'Instantly share your work-in-progress with stakeholders and team members. Accelerate code reviews and QA testing by providing a live environment for every feature.',
                },
                {
                  Icon: Group,
                  heading: 'Simplified collaboration',
                  text: 'Collaborate seamlessly across teams. Share URLs to your preview environments, making it easy for non-technical stakeholders to view and interact with new features.',
                },
                {
                  Icon: Devices,
                  heading: 'Consistent environments',
                  text: 'Eliminate the “it works on my machine” problem. Ensure everyone is testing in the same environment that mirrors production, reducing bugs and integration issues.',
                },
              ]}
              subFeaturesGrid="1fr"
              subFeaturesGap={[12, 12, 13]}
              subFeaturesAlign="stretch"
            />
          </Box>
        </Grid>

        <Grid
          gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
          alignItems="center"
          gridGap={14}
          mb={[13, 13, 14]}
        >
          <Box>
            <Box mb={[12, 12, 13]}>
              <PreHeading mb={6}>Security and Efficiency</PreHeading>
              <Heading fontSize={[5, 5, '32px']} mb={6}>
                Safe and cost-effective deployments
              </Heading>
              <Text fontSize="18px" color="grey2">
                Manage resources efficiently with automatic cleanup and secure
                handling of secrets. Northflank ensures your preview
                environments are both safe and cost-effective.
              </Text>
            </Box>
            <PrimaryFeatureBlock
              subFeatures={[
                {
                  Icon: Time,
                  heading: 'Automatic resource management',
                  text: 'Set policies to automatically shut down idle preview environments. Save on costs by ensuring resources are used only when needed.',
                },
                {
                  Icon: LockAlt,
                  heading: 'Secure secret management',
                  text: 'Keep your sensitive data secure with encrypted secret storage. Inject variables and arguments at runtime without exposing them in your codebase.',
                },
                {
                  Icon: History,
                  heading: 'Easy rollbacks and auditing',
                  text: 'Maintain an auditable history of changes. Roll back to previous versions effortlessly, ensuring stability and confidence in your deployments.',
                },
              ]}
              subFeaturesGrid="1fr"
              subFeaturesGap={[12, 12, 13]}
              subFeaturesAlign="stretch"
            />
          </Box>
          <Box height="700px" overflow="hidden" position="relative" p={8}>
            <Box
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
              background={`linear-gradient(to right, transparent 0%, transparent 70%, ${theme.colors.background} 95%)`}
              backgroundSize="cover"
              zIndex={-1}
            />
            <Box
              as="img"
              src="/images/solutions/use-cases/previewduration.png"
              position="relative"
              height="100%"
              border="1px solid"
              borderColor="grey5"
              borderRadius={3}
              boxShadow="dropDown"
              zIndex={-2}
            />
          </Box>
        </Grid>

        <FancyQuote
          body={
            <>
              Northflank is way easier than gluing a bunch of tools together to
              spin up apps and databases. It’s the ideal platform to deploy
              containers in our cloud account, avoiding the brain damage of big
              cloud and Kubernetes. It’s more powerful and flexible than
              traditional PaaS – all within our VPC.{' '}
              <Text as="span" color="success" fontWeight={500}>
                Northflank has become a go-to way to deploy workloads at Sentry
              </Text>
              .
            </>
          }
          attribution={
            <TestimonialHeader
              name="David Cramer"
              position="Co-Founder and CPO @ Sentry"
              avatar="/images/landing/quotes/david-c.jpeg"
              linkedin="https://www.linkedin.com/in/dmcramer/"
              mb={0}
            />
          }
        />
      </Box>

      <Box background="linear-gradient(to bottom, #141c28 0%, #192338 30%, #192338 70%, #141c28 100%)">
        <Box variant="bounding" py={[12, 12, '120px !important']}>
          <DevExpSection />
        </Box>
      </Box>

      <Box variant="bounding">
        <StatsSection />
      </Box>

      <Box
        background={`linear-gradient(to bottom, #141c28 0%, transparent 30%, #192338 70%, #141c28 100%), ${texture}, linear-gradient(to bottom, #141c28 0%, #192338 30%, #192338 70%, #141c28 100%)`}
      >
        <Box variant="bounding" py={[12, 12, '120px !important']}>
          <Grid
            gridTemplateColumns={['1fr', '1fr', '1fr 1.2fr']}
            gridGap={[12, 12, 13]}
            mb={[12, 12, 13]}
          >
            <Box>
              <PreHeading mb={6}>
                Overcoming Common Development Challenges
              </PreHeading>
              <Heading fontSize={[5, 5, '32px']} color="grey1" mb={8}>
                Why preview environments matter
              </Heading>
              <Text fontSize="18px" color="grey2">
                Building realistic preview environments is essential for modern
                development, yet it presents challenges that can slow down your
                workflow. Northflank addresses these issues head-on.
              </Text>
            </Box>
            <Box as="img" src="/images/landing/k8s.png" width="100%" />
          </Grid>
          <Box mb={[12, 12, 13]}>
            <PrimaryFeatureBlock
              subFeatures={[
                {
                  Icon: Directions,
                  heading: 'Complexity of full-stack previews',
                  text: 'Setting up previews that include databases, microservices, and jobs is complex. Northflank simplifies this with automated, reproducible environments.',
                },
                {
                  Icon: Trip,
                  heading: 'Seamless integration from Git to production',
                  text: 'How do you build, test, and deploy new code? You need preview environments, promotion rules, and production-like staging environments. Northflank integrates with your CI/CD pipelines, ensuring smooth transitions from development to production.',
                },
                {
                  Icon: AddToQueue,
                  heading: 'Efficient scaling',
                  text: 'How do you manage hundreds of ephemeral preview environments in a cost-effective way? Northflank scales up when needed and scales down when idle. You need a solution that saves you on resource costs, all automatically.',
                },
              ]}
              subFeaturesGrid={['1fr', '1fr', 'repeat(2, 1fr)']}
              subFeaturesGap={[12, 12, 13]}
              subFeaturesAlign="stretch"
            />
          </Box>
          <FancyQuote
            body={
              <>
                Northflank is the first batteries-included developer platform
                that doesn’t suffer from the invisible ceilings that hover over
                its competitors.{' '}
                <Text as="span" color="success" fontWeight={500}>
                  We could have built all of Slack with Northflank
                </Text>{' '}
                – and we would have, had it been available.
              </>
            }
            attribution={
              <TestimonialHeader
                name="Keith Adams"
                position="Formerly Chief Architect Slack, Facebook, VMware, GP @ Pebblebed"
                avatar="/images/landing/quotes/keith.jpeg"
                linkedin="https://linkedin.com/in/keith-adams-pb/"
                mb={0}
              />
            }
          />
        </Box>
      </Box>

      <Box py={[12, 12, '96px']}>
        <LargeCarousel
          images={[
            '/images/solutions/use-cases/backupschedule.png',
            '/images/solutions/use-cases/cluster.png',
            '/images/solutions/use-cases/clusterobserve.png',
            '/images/solutions/use-cases/dashboard.png',
            '/images/solutions/use-cases/jobruns.png',
            '/images/solutions/use-cases/logs.png',
            '/images/solutions/use-cases/metrics.png',
            '/images/solutions/use-cases/preview.png',
            '/images/solutions/use-cases/previewduration.png',
            '/images/solutions/use-cases/releaseflow.png',
          ]}
        />
      </Box>

      <Box variant="bounding">
        <UseCasesFooterLinks mb={[12, 12, 13]} />
        <GetStartedCta />
      </Box>
    </>
  );
};

export default PreviewEnvironmentsPage;
