import React from 'react';

import { Git } from '@styled-icons/boxicons-logos/Git';
import { Typescript } from '@styled-icons/boxicons-logos/Typescript';
import { Bell } from '@styled-icons/boxicons-regular/Bell';
import { Cloud } from '@styled-icons/boxicons-regular/Cloud';
import { CodeAlt } from '@styled-icons/boxicons-regular/CodeAlt';
import { CodeCurly } from '@styled-icons/boxicons-regular/CodeCurly';
import { Detail } from '@styled-icons/boxicons-regular/Detail';
import { GitCommit } from '@styled-icons/boxicons-regular/GitCommit';
import { Globe } from '@styled-icons/boxicons-regular/Globe';
import { Group } from '@styled-icons/boxicons-regular/Group';
import { Hive } from '@styled-icons/boxicons-regular/Hive';
import { Key } from '@styled-icons/boxicons-regular/Key';
import { Laptop } from '@styled-icons/boxicons-regular/Laptop';
import { LineChart } from '@styled-icons/boxicons-regular/LineChart';
import { Loader } from '@styled-icons/boxicons-regular/Loader';
import { LockAlt } from '@styled-icons/boxicons-regular/LockAlt';
import { NetworkChart } from '@styled-icons/boxicons-regular/NetworkChart';
import { PackageIcon } from '@styled-icons/boxicons-regular/PackageIcon';
import { PaperPlane } from '@styled-icons/boxicons-regular/PaperPlane';
import { PlayCircle } from '@styled-icons/boxicons-regular/PlayCircle';
import { Server } from '@styled-icons/boxicons-regular/Server';
import { ShieldQuarter } from '@styled-icons/boxicons-regular/ShieldQuarter';
import { Support } from '@styled-icons/boxicons-regular/Support';
import { Terminal } from '@styled-icons/boxicons-regular/Terminal';
import { User } from '@styled-icons/boxicons-regular/User';
import { UserCircle } from '@styled-icons/boxicons-regular/UserCircle';
import { Windows } from '@styled-icons/boxicons-regular/Windows';
import { Docker } from '@styled-icons/fa-brands/Docker';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Heading from '@northflank/components/Heading';
import FeatureNav from '@northflank/components/marketing/FeatureNav';
import FeatureWithIcon from '@northflank/components/marketing/FeatureWithIcon';
import SEO from '@northflank/components/marketing/SEO';
import NorthflankIcon from '@northflank/components/NorthflankIcon';
import Text from '@northflank/components/Text';

import {
  FeatureGroup,
  FeaturesWrapper,
} from '../../components/FeaturePageWrapper';
import PreHeading from '../../components/PreHeading';

const Platform = () => {
  return (
    <>
      <SEO
        title="Platform — Features"
        description="A self-service developer platform at the cutting edge. Northflank streamlines DevOps within organisations and empowers engineers to deploy ephemeral, stateful, and scheduled workloads to the cloud."
      />
      <FeatureNav />
      <FeaturesWrapper>
        <Box variant="bounding" pb="70px!important">
          <PreHeading mb={6}>Reliable, robust, and responsive</PreHeading>
          <Heading as="h1" color="grey1" fontSize={[6, 6, 7, 7]} mb={8}>
            Northflank Platform
          </Heading>
          <Text color="grey3" fontSize={4} width={[1, 1, 1 / 2]} mediaQuery>
            A self-service developer platform at the cutting edge. Northflank
            streamlines DevOps within organisations and empowers engineers to
            deploy ephemeral, stateful, and scheduled workloads to the cloud.
          </Text>
        </Box>
        <FeatureGroup
          heading="A Tailored User Experience"
          text="Northflank provides multiple interfaces so each engineer on your team can experience Northflank in the way that best suits them."
          wideImage="/images/feature/details/platform/interface.png"
          wideImageAlt="Screenshot of Northflank UI service dashboard"
          items={
            <>
              <FeatureWithIcon
                Icon={Laptop}
                iconGradient
                heading="Cloud Console"
                text="The web interface provides an intuitive, accurate, and real-time view of your infrastructure."
              />
              <FeatureWithIcon
                Icon={CodeCurly}
                iconGradient
                heading="Platform API"
                text="Build your platform on top of Northflank with our rich, feature&#8209;complete, fully documented API."
              />
              <FeatureWithIcon
                Icon={Terminal}
                iconGradient
                heading="Command Line"
                text="Interact with resources from your terminal. Tail logs, proxy services, execute into containers, and more."
              />
              <FeatureWithIcon
                Icon={Hive}
                iconGradient
                heading="Templates"
                text="Codify your infrastructure and implement repeatability across your stack and organisation."
              />
              <FeatureWithIcon
                Icon={GitCommit}
                iconGradient
                heading="GitOps"
                text="Stop fearing production releases and improve collaboration across complex infrastructure."
              />
              <FeatureWithIcon
                Icon={Typescript}
                iconGradient
                heading="TypeScript Client"
                text="Use a full TypeScript SDK to build your own platforms with Northflank."
              />
            </>
          }
        />
        <FeatureGroup
          heading="Compatible with Your Existing Workflows"
          text="The Northflank platform is designed to streamline your preferred workflows without disruption. Easy to adopt, easy to pick-and-mix."
          wideImage="/images/feature/details/platform/vcs.png"
          wideImageAlt="Screenshot of Northflank VCS account linking interface"
          items={
            <>
              <FeatureWithIcon
                Icon={Git}
                iconGradient
                heading="All Major VCS Platforms"
                text="Connect repositories from GitHub via GitHub Apps, and connect GitLab or Bitbucket via OAuth."
              />
              <FeatureWithIcon
                Icon={PlayCircle}
                iconGradient
                heading="Actions"
                text="Integrate Northflank deployments into your existing GitHub Actions and GitLab or Bitbucket pipelines."
              />
              <FeatureWithIcon
                Icon={Docker}
                iconGradient
                heading="Container Registries"
                text="Deploy both public and private Docker images from any container registry."
              />
              <FeatureWithIcon
                Icon={PaperPlane}
                iconGradient
                heading="Log Sinks"
                text="Forward logs from your Northflank resources to your existing external logging platforms, like Datadog."
              />
              <FeatureWithIcon
                Icon={Globe}
                iconGradient
                heading="CDN"
                text="Add your favourite CDN such as Cloudflare, Fastly, CloudFront, or Akamai in front of your Northflank services."
              />
              <FeatureWithIcon
                Icon={Server}
                iconGradient
                heading="Self-Hosted Git"
                text="Connect Northflank with self-hosted VCS deployments such as GitHub Enterprise and GitLab CE/EE."
              />
            </>
          }
        />
        <FeatureGroup
          heading="Your Choice: Managed Cloud or BYOC"
          text="Whether you prefer to rapidly deploy your workloads into Northflank’s managed cloud, or connect your own cloud account for ultimate control, you can expect the same elevated platform experience."
          wideImage="/images/feature/details/platform/byoc.png"
          wideImageAlt="Screenshot of Northflank Kubernetes cluster status page"
          items={
            <>
              <FeatureWithIcon
                Icon={NorthflankIcon}
                iconGradient
                heading="Managed Cloud"
                text="Start deploying instantly into Northflank’s secure, multi-tenant cloud. Zero configuration and zero barriers to entry."
                cta={
                  <a
                    href="https://app.northflank.com/signup"
                    target="_blank"
                    style={{ display: 'inline-block' }}
                    rel="noreferrer"
                  >
                    <Button variant="gradient">Get started for free</Button>
                  </a>
                }
              />
              <FeatureWithIcon
                Icon={Cloud}
                iconGradient
                heading="In Your Cloud"
                text="Build and deploy in your own cloud. Let Northflank seamlessly orchestrate Kubernetes in your accounts on GCP, AWS, Azure, and Civo."
                cta={
                  <a
                    href="https://app.northflank.com/signup"
                    target="_blank"
                    style={{ display: 'inline-block' }}
                    rel="noreferrer"
                  >
                    <Button variant="gradient">Get started for free</Button>
                  </a>
                }
              />
              <FeatureWithIcon
                Icon={Server}
                iconGradient
                heading="Custom & Bare-Metal"
                text="Northflank works wherever Kubernetes does – including fast, optimised bare-metal machines. Contact us for enterprise offerings."
                cta={
                  <a
                    href="https://cal.com/team/northflank/northflank-demo?duration=30"
                    target="_blank"
                    style={{ display: 'inline-block' }}
                    rel="noreferrer"
                  >
                    <Button>Schedule a call</Button>
                  </a>
                }
              />
            </>
          }
        />
        <FeatureGroup
          heading="Teams and Organisations"
          wideImage="/images/feature/details/platform/team.png"
          wideImageAlt="Screenshot of Northflank team members list"
          items={
            <>
              <FeatureWithIcon
                Icon={PackageIcon}
                iconGradient
                heading="Projects"
                text="Group your cloud resources by project, team, and organisation. Projects can be deployed to a specific region or worker cluster."
              />
              <FeatureWithIcon
                Icon={Loader}
                iconGradient
                heading="Real-Time Multiplayer"
                text="The Northflank interface updates in real-time to reflect changes made by you or by your teammates. No more refreshing pages to fetch updates."
              />
              <FeatureWithIcon
                Icon={Group}
                iconGradient
                heading="Teams of Any Size"
                text="Invite up to 25 members to your team for free and add more as you scale. Collaborate on projects more effectively with Northflank."
              />
              <FeatureWithIcon
                Icon={UserCircle}
                iconGradient
                heading="Role-Based Access Control"
                text="Configure fine-grained permissions at every platform level. Increase security, and simplify your team’s experience by only showing them what they need."
              />
              <FeatureWithIcon
                Icon={CodeAlt}
                iconGradient
                heading="Real-Time Diff Views"
                text="When editing secret variables or infrastructure as code, live diffs are shown to prevent overwriting changes when your colleagues are editing the same item."
              />
              <FeatureWithIcon
                Icon={Windows}
                iconGradient
                heading="Seamless Context Switching"
                text="Search and jump between teams and projects in a matter of clicks. All your resources will be loaded and accessible instantly."
              />
            </>
          }
        />
        <FeatureGroup
          heading="Security"
          wideImage="/images/feature/details/platform/connectiondetails.png"
          wideImageAlt="Screenshot of Northflank addon connection details interface"
          items={
            <>
              <FeatureWithIcon
                Icon={LockAlt}
                iconGradient
                heading="Secure Runtime"
                text={
                  <>
                    Northflank’s secure runtime is built for multi-tenancy in a
                    cloud native Kubernetes environment, without sacrificing
                    speed or capability. Security features include:
                    <Box as="ul" pl="1em" mt={6}>
                      <li>Sandboxed microVMs</li>
                      <li>Service mesh with mTLS</li>
                      <li>Project network firewalls</li>
                      <li>Hierarchy by namespaces</li>
                    </Box>
                  </>
                }
              />
              <FeatureWithIcon
                Icon={ShieldQuarter}
                iconGradient
                heading="Secret Management"
                text="Environment variables are encrypted at rest and securely injected at runtime into your containers and builds. You have fine-grained control over which resources inherit specific secrets from secret groups. Automatically inherit connection details from your databases."
              />
              <FeatureWithIcon
                Icon={User}
                iconGradient
                heading="SSO & MFA"
                text="Use single sign-on with Google, GitHub, GitLab and Bitbucket. Enable OTP 2-factor auth for extra security. For teams with advanced security needs, Northflank offers SAML and OIDC, with support for Azure Active Directory, Google Workspaces, and more."
              />
              <FeatureWithIcon
                Icon={Key}
                iconGradient
                heading="Authentication Tokens"
                text="When using the API or CLI, authenticate yourself with tokens. These tokens follow our granular permission model and can be restricted to certain projects and users."
              />
              <FeatureWithIcon
                Icon={NetworkChart}
                iconGradient
                heading="Connect Locally"
                text="Services and databases can be deployed with private networking to limit connectivity by project namespace. You can connect to these private endpoints locally using the Northflank CLI proxy."
              />
            </>
          }
        />
        <FeatureGroup
          heading="Billing and Support"
          wideImage="/images/feature/details/platform/billing.png"
          wideImageAlt="Screenshot of a Northflank spend breakdown by project"
          items={
            <>
              {/* <FeatureWithIcon */}
              {/*  Icon={PurchaseTag} */}
              {/*  iconGradient */}
              {/*  heading="Get started for free" */}
              {/*  text="Build and deploy for free with a limited number of services, jobs and addons. Upgrade to a paid project when you are ready to grow." */}
              {/* /> */}
              {/* <FeatureWithIcon */}
              {/*  Icon={CreditCard} */}
              {/*  iconGradient */}
              {/*  heading="Handled with Stripe" */}
              {/*  text="All payments are handled by Stripe and charged to a debit or credit card. You are charged on a monthly basis." */}
              {/* /> */}
              <FeatureWithIcon
                Icon={LineChart}
                iconGradient
                heading="Consumption Based"
                text="Running services, jobs, and addons are pro-rated by the second. Cost is based on the size of the plan and the number of replicas deployed. Additional cost is based on disk allocation, network bandwidth, and number of HTTP requests."
              />
              <FeatureWithIcon
                Icon={Detail}
                iconGradient
                heading="Detailed Project Usage"
                text="Monthly invoices are stored for your records, and historic invoices and current usage are broken-down so you can see exactly what you’re spending on, by project and by resource."
              />
              <FeatureWithIcon
                Icon={Bell}
                iconGradient
                heading="Billing Alerts"
                text="Create billing alerts to get notified when your spending reaches a specified threshold."
              />
              <FeatureWithIcon
                Icon={Group}
                iconGradient
                heading="Invoice Access"
                text="Add additional email addresses to share invoices, or invite team members that only have access to billing and not to your infrastructure."
              />
              <FeatureWithIcon
                Icon={Support}
                iconGradient
                heading="Support"
                text="Quality support is offered to all customers via email. Upgrade your support package to get dedicated support via a shared Slack channel, contractual obligations, and SLA."
                cta={
                  <a
                    href="https://cal.com/team/northflank/northflank-demo?duration=30"
                    target="_blank"
                    style={{ display: 'inline-block' }}
                    rel="noreferrer"
                  >
                    <Button>Schedule a call</Button>
                  </a>
                }
              />
            </>
          }
        />
      </FeaturesWrapper>
    </>
  );
};

export default Platform;
