import React from 'react';

import { AddToQueue } from '@styled-icons/boxicons-regular/AddToQueue';
import { Area } from '@styled-icons/boxicons-regular/Area';
import { Cloud } from '@styled-icons/boxicons-regular/Cloud';
import { Data } from '@styled-icons/boxicons-regular/Data';
import { Duplicate } from '@styled-icons/boxicons-regular/Duplicate';
import { GitCommit } from '@styled-icons/boxicons-regular/GitCommit';
import { Group } from '@styled-icons/boxicons-regular/Group';
import { Hdd } from '@styled-icons/boxicons-regular/Hdd';
import { Hive } from '@styled-icons/boxicons-regular/Hive';
import { PackageIcon } from '@styled-icons/boxicons-regular/PackageIcon';
import { Server } from '@styled-icons/boxicons-regular/Server';
import { TrendingUp } from '@styled-icons/boxicons-regular/TrendingUp';
import { World } from '@styled-icons/boxicons-regular/World';

import Box from '@northflank/components/Box';
import Heading from '@northflank/components/Heading';
import FeatureNav from '@northflank/components/marketing/FeatureNav';
import FeatureWithIcon from '@northflank/components/marketing/FeatureWithIcon';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';

import {
  FeatureGroup,
  FeaturesWrapper,
} from '../../components/FeaturePageWrapper';
import PreHeading from '../../components/PreHeading';

const Scale = () => {
  return (
    <>
      <SEO
        title="Scale — Features"
        description="A fully integrated suite of developer tools. Learn more about Northflank’s operate & scale capabilities."
      />
      <FeatureNav />
      <FeaturesWrapper>
        <Box variant="bounding" pb="70px!important">
          <PreHeading mb={6}>Feature breakdown</PreHeading>
          <Heading as="h1" color="grey1" fontSize={[6, 6, 7, 7]} mb={8}>
            Scale on Northflank
          </Heading>
          <Text color="grey3" fontSize={4} width={[1, 1, 1 / 2]} mediaQuery>
            Northflank scales with you. As the demand for your project or
            product grows, simply increase your resources, add more replicas,
            invite more team members, and we’ll handle the rest.
          </Text>
        </Box>
        <FeatureGroup
          heading="Scaling"
          wideImage="/images/feature/details/scale/autoscale.png"
          wideImageAlt="Screenshot of Northflank autoscaling configuration"
          items={
            <>
              <FeatureWithIcon
                Icon={Area}
                iconGradient
                heading="Vertical Scaling"
                text="Scale vertically by upgrading the compute plan your services use to unlock more CPU power & memory capacity."
              />
              <FeatureWithIcon
                Icon={Duplicate}
                iconGradient
                heading="Horizontal Scaling"
                text="Scale horizontally by increasing the number of instances of your service to enable automatic load balancing."
              />
              <FeatureWithIcon
                Icon={TrendingUp}
                iconGradient
                heading="Auto-Scaling"
                text="Automatically scale horizontally when CPU, memory usage, or requests per second reach a specific threshold."
              />
            </>
          }
        />
        <FeatureGroup
          heading="Scale Operations"
          wideImage="/images/feature/details/scale/projects.png"
          wideImageAlt="Screenshot of Northflank projects list"
          items={
            <>
              <FeatureWithIcon
                Icon={Group}
                iconGradient
                heading="Teams & Organisations"
                text="Northflank can scale to your requirements from 5 engineers to 5,000 engineers. Segment your engineers into teams and roles and assign them to relevant projects."
              />
              <FeatureWithIcon
                Icon={PackageIcon}
                iconGradient
                heading="Projects"
                text="No matter your requirements, segment cloud resources across as many projects as you need. This can be driven by organisational and multi-tenancy needs or simply separation of concerns."
              />
              <FeatureWithIcon
                Icon={AddToQueue}
                iconGradient
                heading="High Availability & Multi-AZ"
                text="Configure your workloads to deploy with high availability and spread them over multiple availability zones to increase resilience and reduce likelihood of downtime."
              />
              <FeatureWithIcon
                Icon={World}
                iconGradient
                heading="Global Geo"
                text="Northflank offers easy deployment in several multi-tenant regions. You can also Bring Your Own Cloud to seamlessly deploy into 60+ regions around the globe to benefit from low latency and data residency requirements."
              />
              <FeatureWithIcon
                Icon={Cloud}
                iconGradient
                heading="Clouds"
                text="Operate your workloads across Northflank’s cloud or with BYOC in hyperscalers such as AWS, GCP, and Azure. Unlock any compute scale, region requirements, or cloud provider preference in a single platform."
              />
              <FeatureWithIcon
                Icon={GitCommit}
                iconGradient
                heading="GitOps"
                text="Store your resource definitions in version control. Modify and scale your resources while keeping your existing Git workflows and security policies."
              />
              <FeatureWithIcon
                Icon={Hive}
                iconGradient
                heading="Templates"
                text="Build trusted and reusable resources with templates. Copy the specification from an existing resource and add it to a new project. Level-up the self-service developer experience within your organisation."
              />
            </>
          }
        />
        <FeatureGroup
          heading="Storage"
          wideImage="/images/feature/details/scale/addon.png"
          wideImageAlt="Screenshot of Northflank compute/disk scaling configuration"
          items={
            <>
              <FeatureWithIcon
                Icon={Data}
                iconGradient
                heading="Addons"
                text="Scale up your database storage by resizing the volume. Start with as little as 4GB and scale up to 1TB+."
              />
              <FeatureWithIcon
                Icon={Hdd}
                iconGradient
                heading="Volumes"
                text="Quickly resize persistent volumes attached to your services as your storage requirements increase."
              />
              <FeatureWithIcon
                Icon={Server}
                iconGradient
                heading="Storage Tiers"
                text="Select the disk size, performance, and price that suits your workload, with HDD and SSD storage options."
              />
            </>
          }
        />
      </FeaturesWrapper>
    </>
  );
};

export default Scale;
