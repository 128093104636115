import React from 'react';

import Box from '@northflank/components/Box';
import Grid from '@northflank/components/Grid';
import Heading from '@northflank/components/Heading';
import FeatureWithIcon from '@northflank/components/marketing/FeatureWithIcon';
import FlexibleImage from '@northflank/components/marketing/FlexibleImage';

const PrimaryFeatureBlock = ({
  preHeading,
  heading,
  body,
  image,
  video,
  noImageShadow,
  subFeatures,
  reverse,
  mb = 13,
  forwardedRef,
  subFeaturesGrid = 'repeat(3, 1fr)',
  subFeaturesGap = 12,
  subFeaturesAlign = 'start',
  subFeatureCards,
  ...rest
}) => (
  <Box ref={forwardedRef} {...rest}>
    {(heading || body) && (
      <Grid
        gridTemplateColumns={[
          '1fr',
          '1fr',
          reverse ? '1.66fr 1fr' : '1fr 1.66fr',
        ]}
        gridGap={13}
        alignItems="center"
        mb={mb}
      >
        <Box
          _css={{
            gridColumnStart: reverse ? [1, 1, 2] : 1,
            gridRowStart: ['auto', 'auto', 1],
          }}
        >
          {preHeading && <Box mb={6}>{preHeading}</Box>}
          <Heading fontSize={[5, 5, 6]} color="grey1" mb={8}>
            {heading}
          </Heading>
          {body}
        </Box>
        <Box
          _css={{
            gridColumnStart: reverse ? 1 : [1, 1, 2],
            gridRowStart: ['auto', 'auto', 1],
          }}
        >
          {image && (
            <FlexibleImage
              src={image}
              alt={heading}
              width="100%"
              aspectRatio="16 / 9"
              borderRadius={3}
              boxShadow={!noImageShadow && 'image'}
            />
          )}
          {video && (
            <Box
              as="video"
              autoPlay
              loop
              muted
              playsInline
              width="100%"
              borderRadius={3}
              boxShadow={!noImageShadow && 'image'}
            >
              <source src={video} />
            </Box>
          )}
        </Box>
      </Grid>
    )}
    {!!subFeatures?.length && (
      <Grid
        gridTemplateColumns={
          Array.isArray(subFeaturesGrid)
            ? subFeaturesGrid
            : ['1fr', '1fr', subFeaturesGrid]
        }
        gridGap={subFeaturesGap}
        alignItems={subFeaturesAlign}
      >
        {subFeatures.map(({ Icon, image, noImageShadow, heading, text }, i) => (
          <FeatureWithIcon
            key={i}
            Icon={Icon}
            iconGradient
            iconBg
            image={image}
            noImageShadow={noImageShadow}
            heading={heading}
            text={text}
            card={subFeatureCards}
            inline
          />
        ))}
      </Grid>
    )}
  </Box>
);

export default React.forwardRef((props, ref) => (
  <PrimaryFeatureBlock forwardedRef={ref} {...props} />
));
