import { NorthflankError, NorthflankErrorOptions } from './northflank-error';

export interface VCSErrorOptions extends NorthflankErrorOptions {
  displayMessage?: boolean;
  preventUnlinking?: boolean;
  noRetry?: boolean;
  rateLimit?: VCSErrorRateLimitData;
}

export interface VCSErrorRateLimitData {
  limit?: number;
  remaining?: number;
  used?: number;
  reset?: number;
  resource?: string;
}

export class VCSError extends NorthflankError {
  displayMessage: boolean;
  preventUnlinking: boolean;
  noRetry: boolean;
  rateLimit?: VCSErrorRateLimitData;

  constructor(
    error: VCSErrorOptions = {
      code: 500,
      message: 'Internal Server Error',
    }
  ) {
    super(error);

    const { displayMessage, preventUnlinking, noRetry } = error;

    this.displayMessage = displayMessage || false;
    this.preventUnlinking = preventUnlinking || false;
    this.noRetry = noRetry || false;
  }
}
