import React from 'react';

import Box from '@northflank/components/Box';

const WrapLink = ({ link, children, ...rest }) => {
  return link ? (
    <Box as="a" href={link} {...rest}>
      {children}
    </Box>
  ) : (
    children
  );
};

export default WrapLink;
