import React, { useContext } from 'react';

import { AddToQueue } from '@styled-icons/boxicons-regular/AddToQueue';
import { CheckCircle } from '@styled-icons/boxicons-regular/CheckCircle';
import { Cloud } from '@styled-icons/boxicons-regular/Cloud';
import { CodeAlt } from '@styled-icons/boxicons-regular/CodeAlt';
import { Customize } from '@styled-icons/boxicons-regular/Customize';
import { Data } from '@styled-icons/boxicons-regular/Data';
import { Directions } from '@styled-icons/boxicons-regular/Directions';
import { GitBranch } from '@styled-icons/boxicons-regular/GitBranch';
import { PlayCircle } from '@styled-icons/boxicons-regular/PlayCircle';
import { Search } from '@styled-icons/boxicons-regular/Search';
import { Terminal } from '@styled-icons/boxicons-regular/Terminal';
import { UserCircle } from '@styled-icons/boxicons-regular/UserCircle';
import { Wrench } from '@styled-icons/boxicons-regular/Wrench';
import { Kubernetes } from '@styled-icons/simple-icons/Kubernetes';
import { ThemeContext } from 'styled-components';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Grid from '@northflank/components/Grid';
import Heading from '@northflank/components/Heading';
import Icon from '@northflank/components/Icon';
import PrimaryFeatureBlock from '@northflank/components/marketing/PrimaryFeatureBlock';
import SEO from '@northflank/components/marketing/SEO';
import { TestimonialHeader } from '@northflank/components/marketing/Testimonial';
import Text from '@northflank/components/Text';

import DemoVideo from '../components/DemoVideo';
import DevExpSection from '../components/DevExpSection';
import FancyQuote from '../components/FancyQuote';
import GetStartedCta from '../components/GetStartedCta';
import HeroHeading from '../components/HeroHeading';
import LargeCarousel from '../components/LargeCarousel';
import PreHeading from '../components/PreHeading';
import StatsSection from '../components/StatsSection';
import texture from '../components/Texture';
import topog from '../components/Topog';
import UseCasesFooterLinks from '../components/UseCasesFooterLinks';
import DevStepsDiagram from '../svg/DevStepsDiagram.svg';

const Newspaper = () => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <SEO
        title="Accelerate your path to production"
        description="The complete developer platform to confidently scale all your workloads across environments."
      />

      <Box
        background={`radial-gradient(60% 100% at 70% 50%, rgba(0, 147, 255, 0.1) 0%, #151C28 100%),
        radial-gradient(circle at 70% 50%, rgba(0, 0, 0, 0) 0%, #151C28 100%),
         ${topog}`}
        borderBottom="1px solid"
        borderColor="grey5"
        mt="-80px"
        pt="80px"
      >
        <Box variant="bounding">
          <Grid
            gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
            alignItems="center"
            gridGap={12}
          >
            <Box>
              <HeroHeading
                as="h1"
                fontSize={[6, 6, 7]}
                color="grey1"
                mb={8}
                style={{ letterSpacing: 'normal', lineHeight: 1.2 }}
              >
                Accelerate your path to production
              </HeroHeading>
              <Text
                fontSize={[5, 5, 6]}
                color="grey2"
                mb={[12, 12, 13]}
                css={{ lineHeight: 1.2 }}
              >
                The complete developer platform to confidently scale all your
                workloads across environments.
              </Text>
              <Text fontSize={4} color="grey2" mb={[12, 12, 13]}>
                Effortlessly deploy microservices, databases, and jobs to the
                cloud with Northflank’s unified, end-to-end post-commit
                platform. Automate your entire workflow—from build and
                deployment to autoscaling and disaster recovery—in your VPC,
                AWS, GCP, Azure, or on-prem.
              </Text>
              <Grid
                gridTemplateColumns={['1fr', '1fr', 'repeat(2, max-content)']}
                gridGap={6}
                mb={6}
              >
                <a href="https://app.northflank.com/i/newspaper0425">
                  <Button
                    variant={['primary', 'large', 'noWrap']}
                    width={['100%', '100%', 'auto']}
                  >
                    <Icon Content={Cloud} Size={16} mr={4} />
                    Claim your $300 in credit
                  </Button>
                </a>
                <a
                  href="https://cal.com/team/northflank/northflank-demo?duration=30"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    variant={['large', 'noWrap']}
                    width={['100%', '100%', 'auto']}
                  >
                    <Icon Content={UserCircle} Size={16} mr={4} />
                    Schedule a demo
                  </Button>
                </a>
              </Grid>
              <Text color="grey3">
                Or{' '}
                <DemoVideo
                  component={
                    <Text color="primary" as="span" css={{ cursor: 'pointer' }}>
                      watch a demo &rarr;
                    </Text>
                  }
                />
              </Text>
              <Text fontSize={3} color="grey2" mt={10}>
                <Text
                  as="span"
                  fontSize="28px"
                  css={{ verticalAlign: 'middle' }}
                >
                  🇬🇧
                </Text>{' '}
                Proudly built in the United Kingdom
              </Text>
            </Box>
            <Box width="100%">
              <DevStepsDiagram />
            </Box>
          </Grid>
        </Box>
      </Box>

      <Box overflow="hidden">
        <Box variant="bounding">
          <Box position={['static', 'static', 'relative']} mb={[13, 13, 14]}>
            <Box
              position={['relative', 'relative', 'absolute']}
              top={0}
              left={[0, 0, '50%']}
              width="100%"
              height="100%"
              zIndex={-1}
              overflow="hidden"
              _css={{ aspectRatio: ['16 / 12', '16 / 12', 'unset'] }}
            >
              <Box
                position="absolute"
                top={0}
                bottom={0}
                left={0}
                right={0}
                background={[
                  `linear-gradient(to bottom, transparent 0%, transparent 60%, ${theme.colors.background} 90%), linear-gradient(to right, transparent 0%, transparent 30%, ${theme.colors.background} 100%)`,
                  `linear-gradient(to bottom, transparent 0%, transparent 60%, ${theme.colors.background} 90%), linear-gradient(to right, transparent 0%, transparent 30%, ${theme.colors.background} 100%)`,
                  `linear-gradient(to bottom, transparent 0%, transparent 60%, ${theme.colors.background} 95%), linear-gradient(to right, transparent 0%, transparent 30%, ${theme.colors.background} 50%)`,
                ]}
                backgroundSize="cover"
                zIndex={-1}
              />
              <Box
                as="img"
                src="/images/solutions/use-cases/dashboard.png"
                position="absolute"
                top="10px"
                left={['10px', '10px', '60px']}
                width={['auto', 'auto', '1400px']}
                height={['1000px', '1000px', 'auto']}
                border="1px solid"
                borderColor="grey5"
                borderRadius={3}
                boxShadow="dropDown"
                zIndex={-2}
              />
            </Box>
            <Box width={[1, 1, 1 / 2]}>
              <PreHeading mb={6}>The Power of a Unified Platform</PreHeading>
              <Heading fontSize={[5, 5, '32px']} color="grey1" mb={8}>
                How Northflank works
              </Heading>
              <Text fontSize="18px" color="grey2" mb={[12, 12, 13]}>
                Northflank unites all of your cloud solutions into one polished
                view. We abstract infrastructure, pipelines, and tedious
                workflows so you can focus on shipping apps.
              </Text>
              <PrimaryFeatureBlock
                subFeatures={[
                  {
                    Icon: PlayCircle,
                    heading: 'All workloads welcome',
                    text: 'Run any kind of workload effortlessly. Build, deploy, and scale microservices, databases, batch jobs, background workers, and more—all ready out of the box. Choose from our managed add-ons like MongoDB, Redis, PostgreSQL, MySQL, MinIO, and others. Manage all your workloads from a single interface, simplifying your operations.',
                  },
                  {
                    Icon: CodeAlt,
                    heading: 'Control & flexibility without compromise',
                    text: 'Gain full control without compromising developer agility. Our flexible, reusable IaC templates let operations manage infrastructure effortlessly, while developers enjoy self-service deployment. Every change syncs back to Git in standardized JSON manifests, ensuring your team stays aligned and efficient.',
                  },
                  {
                    Icon: AddToQueue,
                    heading: 'Pick and mix',
                    text: 'Integrate seamlessly with your preferred tools and workflows. Our platform is easy to adopt and customize, allowing you to pick and mix logging tools, CI providers, and more. Streamline your development process without disruption, so you can focus on shipping software faster.',
                  },
                  {
                    Icon: Cloud,
                    heading: 'BYOC and PaaS support',
                    text: 'Seamlessly scale from our managed PaaS to your own cloud infrastructure with Bring Your Own Cloud (BYOC) support. Avoid the “graduation problem” by having a consistent platform that grows with you, whether you’re on AWS, GCP, Azure, or on-premises.',
                  },
                ]}
                subFeaturesGrid="1fr"
                subFeaturesGap={[12, 12, 13]}
                subFeaturesAlign="stretch"
              />
            </Box>
          </Box>

          <FancyQuote
            body={
              <>
                Northflank is way easier than gluing a bunch of tools together
                to spin up apps and databases. It’s the ideal platform to deploy
                containers in our cloud account, avoiding the brain damage of
                big cloud and Kubernetes. It’s more powerful and flexible than
                traditional PaaS – all within our VPC.{' '}
                <Text as="span" color="success" fontWeight={500}>
                  Northflank has become a go-to way to deploy workloads at
                  Sentry
                </Text>
                .
              </>
            }
            attribution={
              <TestimonialHeader
                name="David Cramer"
                position="Co-Founder and CPO @ Sentry"
                avatar="/images/landing/quotes/david-c.jpeg"
                linkedin="https://www.linkedin.com/in/dmcramer/"
                mb={0}
              />
            }
            mb={[13, 13, 14]}
          />

          <PrimaryFeatureBlock
            subFeatures={[
              {
                Icon: Wrench,
                heading: 'Manage all of your environments',
                text: 'Create, manage, and operate all your environments—preview, staging, production—through intuitive pipelines. Streamline your deployment process and improve collaboration across teams, ensuring consistency and reliability at every stage.',
              },
              {
                Icon: Kubernetes,
                heading: 'Powered by Kubernetes',
                text: 'Leverage the power of Kubernetes without the complexity. Our platform abstracts the details allowing you to harness Kubernetes’ robust capabilities effortlessly. Work without worrying about YAML files or cluster management.',
              },
              {
                Icon: Terminal,
                heading: 'Consistent power across all interfaces',
                text: 'Enjoy consistent, powerful capabilities across our real-time UI, API, CLI, GitOps, and Templates. Whether you’re scripting deployments or using the dashboard, you get the same robust features and performance, tailored to your workflow.',
              },
              {
                Icon: GitBranch,
                heading: 'Automate post-commit',
                text: (
                  <>
                    <Text fontSize={3} color="grey2" mb={8}>
                      Automate your entire pipeline after code commit. Once you
                      push your code, our platform handles everything—from
                      Docker builds to deployments, observability, auto-scaling,
                      and disaster recovery.
                    </Text>
                    <Box
                      bg="grey11"
                      border="1px solid"
                      borderColor="grey5"
                      borderRadius={2}
                      p={8}
                    >
                      <Box fontSize={3} fontFamily="monospace" color="grey2">
                        Git push{' '}
                        <Text as="span" color="grey3" whiteSpace="nowrap">
                          --&gt;
                        </Text>{' '}
                        Docker build{' '}
                        <Text as="span" color="grey3" whiteSpace="nowrap">
                          --&gt;
                        </Text>{' '}
                        Registry upload{' '}
                        <Text as="span" color="grey3" whiteSpace="nowrap">
                          --&gt;
                        </Text>{' '}
                        Preview environment{' '}
                        <Text as="span" color="grey3" whiteSpace="nowrap">
                          --&gt;
                        </Text>{' '}
                        Pre-production release{' '}
                        <Text as="span" color="grey3" whiteSpace="nowrap">
                          --&gt;
                        </Text>{' '}
                        Promote to production{' '}
                        <Text as="span" color="grey3" whiteSpace="nowrap">
                          --&gt;
                        </Text>{' '}
                        Observability{' '}
                        <Text as="span" color="grey3" whiteSpace="nowrap">
                          --&gt;
                        </Text>{' '}
                        Auto-scaling{' '}
                        <Text as="span" color="grey3" whiteSpace="nowrap">
                          --&gt;
                        </Text>{' '}
                        Disaster recovery{' '}
                        <Text as="span" color="grey3" whiteSpace="nowrap">
                          --&gt;
                        </Text>{' '}
                        <Box
                          as="span"
                          display="inline-flex"
                          alignItems="center"
                        >
                          <Text as="span" color="success">
                            Done
                          </Text>
                          <Box
                            display="inline-flex"
                            width="18px"
                            height="18px"
                            position="relative"
                            ml={4}
                            _css={{
                              '&::before': {
                                content: `""`,
                                display: 'block',
                                width: '20px',
                                height: '20px',
                                position: 'absolute',
                                bg: 'success',
                                borderRadius: 5,
                                opacity: 0.75,
                                filter: 'blur(20px)',
                              },
                            }}
                          >
                            <Icon
                              Content={CheckCircle}
                              Size={18}
                              color="success"
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </>
                ),
              },
            ]}
            subFeaturesGrid={['1fr', '1fr', 'repeat(2, 1fr)']}
            subFeaturesGap={[12, 12, 13]}
            subFeaturesAlign="stretch"
          />
        </Box>
      </Box>

      <Box background="linear-gradient(to bottom, #141c28 0%, #192338 30%, #192338 70%, #141c28 100%)">
        <Box variant="bounding" py={[12, 12, '120px !important']}>
          <DevExpSection />
        </Box>
      </Box>

      <Box variant="bounding">
        <StatsSection />
      </Box>

      <Box
        background={`linear-gradient(to bottom, #141c28 0%, transparent 30%, #192338 70%, #141c28 100%), ${texture}, linear-gradient(to bottom, #141c28 0%, #192338 30%, #192338 70%, #141c28 100%)`}
      >
        <Box variant="bounding" py={[12, 12, '120px !important']}>
          <Grid
            gridTemplateColumns={['1fr', '1fr', '1fr 1.2fr']}
            gridGap={[12, 12, 13]}
            mb={[12, 12, 13]}
          >
            <Box>
              <PreHeading mb={6}>
                What stands in the way of you and your dream platform?
              </PreHeading>
              <Heading fontSize={[5, 5, '32px']} color="grey1" mb={8}>
                Real-world challenges of running containers in production
              </Heading>
              <Text fontSize="18px" color="grey2">
                You want a platform that unites your cloud solutions. Everything
                from CI pipelines to CDNs needs to be thought through, tested,
                and built to work in the ephemeral world of containers.
              </Text>
            </Box>
            <Box as="img" src="/images/landing/k8s.png" width="100%" />
          </Grid>
          <Box mb={[12, 12, 13]}>
            <PrimaryFeatureBlock
              subFeatures={[
                {
                  Icon: Directions,
                  heading: 'Rigid self-service tools limit operational control',
                  text: 'Developers need to self-serve in order to succeed. Unfortunately, most platforms achieve self-service by rigidly constraining operations. This puts Dev and Ops in tension – the worst case for DevOps.',
                },
                {
                  Icon: Search,
                  heading: 'Multiple tools with partial views',
                  text: `To understand what's going on with your application, you have to juggle several tools—one for logs, another for builds, maybe another for deployments. This sort of sprawl complicates the already challenging job of troubleshooting.`,
                },
                {
                  Icon: Data,
                  heading:
                    'Stateful workloads are hard, Kubernetes even harder',
                  text: 'Microservice restarts are fine, but database restarts lead to late-night pages and manual intervention. Stateful workloads require backups, recovery logic and observability.',
                },
                {
                  Icon: Customize,
                  heading: 'Essential features absent from standard platforms',
                  text: 'Key components like BYOC, handling stateful services, and disaster recovery are often not covered by standard platforms. This leaves you piecing together solutions. RBAC, SSO, and healthchecks need to be set up in every tool that falls outside of your platform.',
                },
              ]}
              subFeaturesGrid={['1fr', '1fr', 'repeat(2, 1fr)']}
              subFeaturesGap={[12, 12, 13]}
              subFeaturesAlign="stretch"
            />
          </Box>
          <FancyQuote
            body={
              <>
                Northflank is the first batteries-included developer platform
                that doesn’t suffer from the invisible ceilings that hover over
                its competitors.{' '}
                <Text as="span" color="success" fontWeight={500}>
                  We could have built all of Slack with Northflank
                </Text>{' '}
                – and we would have, had it been available.
              </>
            }
            attribution={
              <TestimonialHeader
                name="Keith Adams"
                position="Formerly Chief Architect Slack, Facebook, VMware, GP @ Pebblebed"
                avatar="/images/landing/quotes/keith.jpeg"
                linkedin="https://linkedin.com/in/keith-adams-pb/"
                mb={0}
              />
            }
          />
        </Box>
      </Box>

      <Box py={[12, 12, '96px']}>
        <LargeCarousel
          images={[
            '/images/solutions/use-cases/backupschedule.png',
            '/images/solutions/use-cases/cluster.png',
            '/images/solutions/use-cases/clusterobserve.png',
            '/images/solutions/use-cases/dashboard.png',
            '/images/solutions/use-cases/jobruns.png',
            '/images/solutions/use-cases/logs.png',
            '/images/solutions/use-cases/metrics.png',
            '/images/solutions/use-cases/preview.png',
            '/images/solutions/use-cases/previewduration.png',
            '/images/solutions/use-cases/releaseflow.png',
          ]}
        />
      </Box>

      <Box variant="bounding">
        <UseCasesFooterLinks mb={[12, 12, 13]} />
        <GetStartedCta />
      </Box>
    </>
  );
};

export default Newspaper;
