import React from 'react';

export const ViewContext = React.createContext({});

export const ProjectLimitContext = React.createContext({});

export const CollaboratorContext = React.createContext({});

export const ViewContextProvider = ViewContext.Provider;
export const ViewContextConsumer = ViewContext.Consumer;

export const OrganisationAccessControlContext = React.createContext({});

export const OrganisationAccessControlContextProvider =
  OrganisationAccessControlContext.Provider;
export const OrganisationAccessControlContextConsumer =
  OrganisationAccessControlContext.Consumer;

export const TeamAccessControlContext = React.createContext({});

export const TeamAccessControlContextProvider =
  TeamAccessControlContext.Provider;
export const TeamAccessControlContextConsumer =
  TeamAccessControlContext.Consumer;

export const ProjectAccessControlContext = React.createContext({});

export const ProjectAccessControlContextProvider =
  ProjectAccessControlContext.Provider;
export const ProjectAccessControlContextConsumer =
  ProjectAccessControlContext.Consumer;

export const ThemePreferenceContext = React.createContext({});

export const ThemePreferenceContextProvider = ThemePreferenceContext.Provider;
export const ThemePreferenceContextConsumer = ThemePreferenceContext.Consumer;

export const ProjectSelectorContext = React.createContext({});

export const ProjectSelectorContextProvider = ProjectSelectorContext.Provider;
export const ProjectSelectorContextConsumer = ProjectSelectorContext.Consumer;

export const TemplateProjectContext = React.createContext({
  isTemplate: false,
  templateProjectIsSelected: false,
  templateProjectData: {},
  templateProjectResources: {},
  loadingTemplateProjectResources: false,
  templateDefinedProjectData: {},
  templateDefinedResources: [],
});

export const AddonBackupUploadHelperContext = React.createContext({
  uploadHelper: null,
});
