import React, { useContext } from 'react';

import { ThemeContext } from 'styled-components';

import Box from '@northflank/components/Box';
import Text from '@northflank/components/Text';

import graphpaper from '../GraphPaper';

const FancyQuote = ({ body, attribution, small, ...rest }) => {
  const theme = useContext(ThemeContext);

  return (
    <Box
      display="flex"
      flexDirection="column"
      background={`radial-gradient(circle at 10% 200%, ${theme.colors.grey8} 20%, transparent 90%), ${graphpaper}, ${theme.colors.grey11}`}
      border="1px solid"
      borderColor="grey5"
      borderRadius={3}
      boxShadow="dropDown"
      {...rest}
    >
      <Box display="flex" alignItems="center" flexGrow={1} p={12}>
        <Text fontSize={small ? [3, 3, 4] : [4, 4, 5]}>{body}</Text>
      </Box>
      <Box
        bg="grey11"
        borderTop="1px solid"
        borderTopColor="grey5"
        borderBottomLeftRadius={3}
        borderBottomRightRadius={3}
        px={12}
        py={9}
      >
        {attribution}
      </Box>
    </Box>
  );
};

export default FancyQuote;
