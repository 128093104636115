import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { ThemeContext } from 'styled-components';

import Box from '@northflank/components/Box';
import Heading from '@northflank/components/Heading';
import IconContainer from '@northflank/components/Icon';
import Text from '@northflank/components/Text';
import { capitalize } from '@northflank/constants-base/functions';
import useBackgroundCursorPosition from '@northflank/utilities/hooks/useBackgroundCursorPosition';

const getCloudCardText = (value, engine) => {
  switch (value) {
    case 'northflank':
      return 'Deploy into regions available on Northflank’s Managed Cloud';
    case 'gpus':
      return 'Deploy containers for GPU workloads into your cloud provider account';
    default:
      return `Deploy in your ${
        value.length > 3 ? capitalize(value) : value.toUpperCase()
      } account with ${engine}`;
  }
};
const CloudProviderCard = ({ provider }) => {
  if (!provider) {
    return null;
  }

  const { value, label, link, icon, engine } = provider;

  const theme = useContext(ThemeContext);

  const [bgRef, background] = useBackgroundCursorPosition({
    backgroundColor: theme.colors.grey10,
    highlightColor: theme.colors.grey8,
    highlightSize: '300px',
  });

  return (
    <Link to={link}>
      <Box
        ref={bgRef}
        height="100%"
        border="1px solid"
        borderColor="grey6"
        borderRadius={2}
        p={9}
        style={{ background }}
        _css={{
          transition: 'border-color 100ms',
          '&:hover': {
            borderColor: 'grey5',
          },
        }}
      >
        <Box variant="flex" alignItems="center" mb={6}>
          <IconContainer
            Content={icon}
            Size={24}
            color="url(#gradient_svg__blueSvgGradient)"
            mr={7}
          />
          <Heading color="grey1">{label}</Heading>
        </Box>
        <Text color="grey2" fontSize={3}>
          {getCloudCardText(value, engine)}
        </Text>
      </Box>
    </Link>
  );
};

export default CloudProviderCard;
