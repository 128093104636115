import React from 'react';

import { LinkExternal } from '@styled-icons/boxicons-regular/LinkExternal';

import Box from '@northflank/components/Box';
import Icon from '@northflank/components/Icon';
import Label from '@northflank/components/Label';
import Text from '@northflank/components/Text';

import WrapLink from '../../../WrapLink';
import { gpuRounding } from '../../utility/gpuRounding';

const CloudProviderTableGpu = ({ value, isMobile, path }) =>
  value.length
    ? value.map((gpu, i) => {
        const onPage = path.includes(gpu.name);
        return (
          <WrapLink
            key={`${gpu.name}-${i}`}
            link={!onPage ? `/cloud/gpus/${gpu.name}` : null}
            color="grey1"
            _css={{
              '&:hover': { color: 'primary' },
            }}
          >
            <Box variant="flex" alignItems="center">
              <Text mr={2}>{gpuRounding(gpu.count)} x</Text>
              {!isMobile ? <Text mr={2}>{`${gpu.manufacturer} `}</Text> : null}
              <Text>{`${gpu.displayName} `}</Text>
              {gpu.memoryInfo?.sizeInMiB && !isMobile ? (
                <Label ml={4}>
                  {Math.floor(gpu.memoryInfo.sizeInMiB / 1024)} GB
                </Label>
              ) : null}
              {!onPage ? (
                <Icon Content={LinkExternal} Size={12} color="grey3" ml={3} />
              ) : null}
            </Box>
          </WrapLink>
        );
      })
    : null;

export default CloudProviderTableGpu;
