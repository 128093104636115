import React from 'react';

import { useField } from 'formik';

import Range from '@northflank/components/Range';
import Text from '@northflank/components/Text';

const FormikRange = (props) => {
  const [field, meta, helpers] = useField(props);
  const { value } = field;
  const { error, touched } = meta;
  const { setValue } = helpers;

  return (
    <>
      <Range
        value={value}
        onChange={(v) => {
          setValue(v);
        }}
        {...props}
      />
      {error && touched && (
        <Text color="danger" fontSize={0} mt={5}>
          {error}
        </Text>
      )}
    </>
  );
};

export default FormikRange;
