import React, { useState } from 'react';

import Box from '@northflank/components/Box';
import Collapsible from '@northflank/components/Collapsible';

import CloudProviderHeader from '../CloudProviderHeader';
import DisplayRegion from '../DisplayRegion';

const DisplayProviderRegions = ({ option, displayedRegions, hideTitle }) => {
  const [showRegions, setShowRegions] = useState(true);

  return (
    <Box
      key={option.value}
      bg="grey10"
      border="1px solid"
      borderColor="grey5"
      borderRadius={3}
      p={4}
      py={6}
      pl={8}
    >
      {!hideTitle && (
        <CloudProviderHeader
          option={option}
          show={showRegions}
          setShow={setShowRegions}
        />
      )}
      <Collapsible direction="height" expanded={showRegions}>
        <Box width="100%" _css={{ columnCount: [1, 2, 3, 4] }}>
          {displayedRegions[option.value]?.length ? (
            displayedRegions[option.value].map((r) => (
              <DisplayRegion region={r} option={option} key={r.id} />
            ))
          ) : (
            <Box p={3} pb={8}>
              No available regions
            </Box>
          )}
        </Box>
      </Collapsible>
    </Box>
  );
};

export default DisplayProviderRegions;
