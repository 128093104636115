import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Git } from '@styled-icons/boxicons-logos/Git';
import { Bell } from '@styled-icons/boxicons-regular/Bell';
import { Chip } from '@styled-icons/boxicons-regular/Chip';
import { Cloud } from '@styled-icons/boxicons-regular/Cloud';
import { CloudUpload } from '@styled-icons/boxicons-regular/CloudUpload';
import { CodeAlt } from '@styled-icons/boxicons-regular/CodeAlt';
import { Cog } from '@styled-icons/boxicons-regular/Cog';
import { CreditCard } from '@styled-icons/boxicons-regular/CreditCard';
import { Customize } from '@styled-icons/boxicons-regular/Customize';
import { Data } from '@styled-icons/boxicons-regular/Data';
import { DollarCircle } from '@styled-icons/boxicons-regular/DollarCircle';
import { GitBranch } from '@styled-icons/boxicons-regular/GitBranch';
import { Globe } from '@styled-icons/boxicons-regular/Globe';
import { Group } from '@styled-icons/boxicons-regular/Group';
import { Heart } from '@styled-icons/boxicons-regular/Heart';
import { Key } from '@styled-icons/boxicons-regular/Key';
import { LockAlt } from '@styled-icons/boxicons-regular/LockAlt';
import { Radar } from '@styled-icons/boxicons-regular/Radar';
import { Reset } from '@styled-icons/boxicons-regular/Reset';
import { Shuffle } from '@styled-icons/boxicons-regular/Shuffle';
import { Sitemap } from '@styled-icons/boxicons-regular/Sitemap';
import { Sort } from '@styled-icons/boxicons-regular/Sort';
import { Terminal } from '@styled-icons/boxicons-regular/Terminal';
import { TrendingUp } from '@styled-icons/boxicons-regular/TrendingUp';
import { UserCheck } from '@styled-icons/boxicons-regular/UserCheck';
import { UserCircle } from '@styled-icons/boxicons-regular/UserCircle';
import { World } from '@styled-icons/boxicons-regular/World';
import { Wrench } from '@styled-icons/boxicons-regular/Wrench';
import css from '@styled-system/css';
import slugify from 'slugify';
import styled from 'styled-components';

import Box from '@northflank/components/Box';
import Icon from '@northflank/components/Icon';

const staticNavItems = [
  {
    title: 'Overview',
    href: '/features/overview',
  },
  {
    title: 'Platform',
    href: '/features/platform',
    subItems: [
      {
        icon: Terminal,
        title: 'A Tailored User Experience',
      },
      {
        icon: Git,
        title: 'Compatible with Your Existing Workflows',
      },
      {
        icon: Cloud,
        title: 'Your Choice: Managed Cloud or BYOC',
      },
      {
        icon: Group,
        title: 'Teams and Organisations',
      },
      {
        icon: LockAlt,
        title: 'Security',
      },
      {
        icon: CreditCard,
        title: 'Billing and Support',
      },
    ],
  },
  {
    title: 'Build',
    href: '/features/build',
    subItems: [
      {
        icon: Wrench,
        title: 'Implement Continuous Integration',
      },
      {
        icon: Cog,
        title: 'Customise Your Build Process',
      },
      {
        icon: Chip,
        title: 'Optimise Your Builds',
      },
    ],
  },
  {
    title: 'Run',
    href: '/features/run',
    subItems: [
      {
        icon: Cloud,
        title: 'Runtime Environment',
      },
      {
        icon: CloudUpload,
        title: 'Continuous Deployment',
      },
      {
        icon: Cog,
        title: 'Advanced Configuration',
      },
      {
        icon: Globe,
        title: 'Networking',
      },
      {
        icon: Key,
        title: 'Variables and Secrets',
      },
    ],
  },
  {
    title: 'Release',
    href: '/features/release',
    subItems: [
      {
        icon: Shuffle,
        title: 'Pipelines',
      },
      {
        icon: Sitemap,
        title: 'Release Flows',
      },
    ],
  },
  {
    title: 'Scale',
    href: '/features/scale',
    subItems: [
      {
        icon: TrendingUp,
        title: 'Scaling',
      },
      {
        icon: UserCircle,
        title: 'Scale Operations',
      },
      {
        icon: Data,
        title: 'Storage',
      },
    ],
  },
  {
    title: 'Observe',
    href: '/features/observe',
    subItems: [
      {
        icon: Radar,
        title: 'Observability',
      },
      {
        icon: Bell,
        title: 'Alerting',
      },
      {
        icon: Heart,
        title: 'Health Checks',
      },
    ],
  },
  {
    title: 'Databases',
    href: '/features/databases',
    subItems: [
      {
        icon: Data,
        title: 'Deploy Data Stores',
      },
      {
        icon: Sort,
        title: 'Deploy Caches and Queues',
      },
      {
        icon: LockAlt,
        title: 'Secure and Scale Stateful Workloads',
      },
      {
        icon: Reset,
        title: 'Backups and Restores',
      },
    ],
  },
  {
    title: 'Templates',
    href: '/features/templates',
    subItems: [
      {
        icon: CodeAlt,
        title: 'Templates',
      },
      {
        icon: GitBranch,
        title: 'GitOps',
      },
    ],
  },
  {
    title: 'Managed Cloud',
    href: '/features/managed-cloud',
    subItems: [
      {
        icon: CloudUpload,
        title: 'The Simplest Way to Deploy',
      },
      {
        icon: Customize,
        title: 'Total Flexibility',
      },
      {
        icon: UserCheck,
        title: 'Stress-Free Infrastructure',
      },
    ],
  },
  {
    title: 'Bring Your Cloud',
    href: '/features/bring-your-own-cloud',
    subItems: [
      {
        icon: Cloud,
        title: 'Choice of Cloud',
      },
      {
        icon: World,
        title: 'Choice of Regions and Zones',
      },
      {
        icon: DollarCircle,
        title: 'Cost Optimisation',
      },
      {
        icon: LockAlt,
        title: 'Security',
      },
    ],
  },
];

const NavList = styled.ul(() =>
  css({
    listStyle: 'none',
    'li + li': { mt: 8 },
    '&:not(:first-child)': {
      mt: 8,
    },
  })
);

const NavLink = styled.span(({ subItem, active }) =>
  css({
    fontSize: subItem ? 3 : [3, 3, 4],
    color: active ? 'grey1' : 'grey2',
    fontWeight: subItem ? 400 : active ? 600 : 400,
    display: 'flex',
    alignItems: 'flex-start',

    transition: '200ms',
    '&:hover': {
      color: 'grey1',
    },
    svg: {
      fill: active ? 'success' : 'grey3',
      transition: '200ms',
    },
  })
);

const FeaturePageNav = ({ asPath, customNavItems }) => {
  const [active, setActive] = useState();
  const [enableObserver, setEnableObserver] = useState(true);

  const navItems = customNavItems || staticNavItems;

  useEffect(() => {
    const sections = document.querySelectorAll('section[id]');
    if (sections?.[0]) setActive(sections[0].id);
  }, []);

  useEffect(() => {
    let scrollDirection = 'up';

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const entryRect = entry.target.getBoundingClientRect();
          const activeRect = document
            .querySelector(`section#${active}`)
            ?.getBoundingClientRect();
          if (
            enableObserver &&
            entry.isIntersecting &&
            ((scrollDirection === 'up' && entryRect.top < activeRect?.top) ||
              (scrollDirection === 'down' && entryRect.top > activeRect?.top))
          ) {
            setActive(entry.target.id);
          }
        });
      },
      { rootMargin: '-50% 0px' }
    );

    const sections = document.querySelectorAll('section[id]');

    sections.forEach((section) => {
      observer.observe(section);
    });

    let lastScrollTop = 0;
    const handleScroll = () => {
      setEnableObserver(true);
      const scroll = document.documentElement.scrollTop;
      if (scroll > lastScrollTop) scrollDirection = 'down';
      else scrollDirection = 'up';
      lastScrollTop = scroll;
    };

    document.addEventListener('scroll', handleScroll);

    return () => {
      observer.disconnect();
      document.removeEventListener('scroll', handleScroll);
    };
  }, [active, enableObserver]);

  return (
    <Box
      position={['static', 'static', 'sticky']}
      top={['80px', '80px', '110px', '110px', '80px']}
      pt={[12, 12, '96px']}
      pb={[9, 9, 12]}
      pl={[9, 9, 12]}
      pr={[9, 9, 0]}
    >
      <Box bg="rgba(155, 155, 255, 0.04)" borderRadius={3} p={10}>
        <NavList>
          {navItems
            .filter((item) => asPath.startsWith(item.href))
            .map((item) => (
              <li key={item.href}>
                <Link to={item.href}>
                  <NavLink active={asPath.startsWith(item.href)}>
                    {item.title}
                  </NavLink>
                </Link>
                {item.subItems?.length && (
                  <NavList>
                    {item.subItems.map((subItem) => {
                      const anchor = slugify(subItem.title, {
                        remove: /[^a-zA-Z0-9- ]/g,
                        lower: true,
                      });
                      return (
                        <li
                          key={`${item.href}#${anchor}`}
                          onClick={() => {
                            setEnableObserver(false);
                            setTimeout(() => setActive(anchor), 100);
                          }}
                        >
                          <NavLink
                            as="a"
                            href={`${item.href}#${anchor}`}
                            active={anchor === active}
                            subItem
                          >
                            <Box
                              mt="1px"
                              mr={4}
                              position="relative"
                              _css={{
                                '&::before': {
                                  content: '""',
                                  display: 'block',
                                  position: 'absolute',
                                  width: '100%',
                                  height: '100%',
                                  bg: 'success',
                                  opacity: anchor === active ? 1 : 0,
                                  borderRadius: 5,
                                  filter: 'blur(20px)',
                                  transition: '200ms',
                                },
                              }}
                            >
                              <Icon Content={subItem.icon} Size={18} />
                            </Box>
                            {subItem.title}
                          </NavLink>
                        </li>
                      );
                    })}
                  </NavList>
                )}
              </li>
            ))}
        </NavList>
      </Box>
    </Box>
  );
};

export default FeaturePageNav;
