import React, { useContext } from 'react';

import { AddToQueue } from '@styled-icons/boxicons-regular/AddToQueue';
import { Bell } from '@styled-icons/boxicons-regular/Bell';
import { BuildingHouse } from '@styled-icons/boxicons-regular/BuildingHouse';
import { Cloud } from '@styled-icons/boxicons-regular/Cloud';
import { CodeAlt } from '@styled-icons/boxicons-regular/CodeAlt';
import { Customize } from '@styled-icons/boxicons-regular/Customize';
import { Data } from '@styled-icons/boxicons-regular/Data';
import { Error } from '@styled-icons/boxicons-regular/Error';
import { Group } from '@styled-icons/boxicons-regular/Group';
import { PlayCircle } from '@styled-icons/boxicons-regular/PlayCircle';
import { Pulse } from '@styled-icons/boxicons-regular/Pulse';
import { Shield } from '@styled-icons/boxicons-regular/Shield';
import { Terminal } from '@styled-icons/boxicons-regular/Terminal';
import { Time } from '@styled-icons/boxicons-regular/Time';
import { ThemeContext } from 'styled-components';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Grid from '@northflank/components/Grid';
import Heading from '@northflank/components/Heading';
import Icon from '@northflank/components/Icon';
import PrimaryFeatureBlock from '@northflank/components/marketing/PrimaryFeatureBlock';
import SEO from '@northflank/components/marketing/SEO';
import { TestimonialHeader } from '@northflank/components/marketing/Testimonial';
import Text from '@northflank/components/Text';

import DemoVideo from '../../components/DemoVideo';
import DevExpSection from '../../components/DevExpSection';
import FancyQuote from '../../components/FancyQuote';
import GetStartedCta from '../../components/GetStartedCta';
import HeroHeading from '../../components/HeroHeading';
import LargeCarousel from '../../components/LargeCarousel';
import PreHeading from '../../components/PreHeading';
import StatsSection from '../../components/StatsSection';
import texture from '../../components/Texture';
import topog from '../../components/Topog';
import UseCasesFooterLinks from '../../components/UseCasesFooterLinks';
import DevStepsDiagram from '../../svg/DevStepsDiagram.svg';

const DisasterRecoveryPage = () => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <SEO
        title="Disaster recovery — Use cases"
        description="Rest easy knowing Northflank automates snapshotting, backup storage, and other essential aspects of DR."
      />

      <Box
        background={`radial-gradient(60% 100% at 70% 50%, rgba(0, 147, 255, 0.1) 0%, #151C28 100%),
        radial-gradient(circle at 70% 50%, rgba(0, 0, 0, 0) 0%, #151C28 100%),
         ${topog}`}
        borderBottom="1px solid"
        borderColor="grey5"
        mt="-80px"
        pt="80px"
      >
        <Box variant="bounding">
          <Grid
            gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
            alignItems="center"
            gridGap={12}
          >
            <Box>
              <HeroHeading
                as="h1"
                fontSize={[6, 6, 7]}
                color="grey1"
                mb={8}
                style={{ letterSpacing: 'normal', lineHeight: 1.2 }}
              >
                Disaster recovery that you can sleep on
              </HeroHeading>
              <Text
                fontSize={[5, 5, 6]}
                color="grey2"
                mb={[12, 12, 13]}
                css={{ lineHeight: 1.2 }}
              >
                Rest easy knowing Northflank automates snapshotting, backup
                storage, and other essential aspects of DR.
              </Text>
              <Text fontSize={4} color="grey2" mb={[12, 12, 13]}>
                Both stateful and stateless apps benefit from Northflank’s
                included alerting, team spaces and automated failure recovery.
              </Text>
              <Grid
                gridTemplateColumns={['1fr', '1fr', 'repeat(2, max-content)']}
                gridGap={6}
                mb={6}
              >
                <a href="https://app.northflank.com/signup">
                  <Button
                    variant={['primary', 'large', 'noWrap']}
                    width={['100%', '100%', 'auto']}
                  >
                    <Icon Content={Cloud} Size={16} mr={4} />
                    Try Northflank Now
                  </Button>
                </a>
                <DemoVideo buttonProps={{ width: ['100%', '100%', 'auto'] }} />
              </Grid>
              <Text color="grey3">
                Prefer a live walkthrough?{' '}
                <a
                  href="https://cal.com/team/northflank/northflank-demo?duration=30"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Schedule a demo &rarr;
                </a>
              </Text>
            </Box>
            <Box width="100%">
              <DevStepsDiagram />
            </Box>
          </Grid>
        </Box>
      </Box>

      <Box variant="bounding">
        <Grid
          gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
          alignItems="center"
          gridGap={14}
          mb={[13, 13, 14]}
        >
          <Box>
            <Box mb={[12, 12, 13]}>
              <PreHeading mb={6}>
                Failover to replicas quickly and securely
              </PreHeading>
              <Heading fontSize={[5, 5, '32px']} mb={6}>
                Uptime & recovery
              </Heading>
              <Text fontSize="18px" color="grey2">
                Your DevOps team deserves a better database experience. Set up
                and tear down databases from Northflank’s addons page for a
                managed DB experience. Replication and other essentials are
                configurable in a few clicks. Securely generate connection
                credentials in seconds.
              </Text>
            </Box>
            <PrimaryFeatureBlock
              subFeatures={[
                {
                  Icon: Data,
                  heading: 'Auto-magic backups',
                  text: 'Restore an addon to a former state from any backup you have made in the past. Perform optimised backups using each addon’s native dump feature. Use incremental disk snapshots for optimised backup & recovery with cloud provider native storage (CSI).',
                },
                {
                  Icon: Time,
                  heading: 'Backup schedules',
                  text: 'Define schedules and perform backups as often as you need them. Configure details like backup retention period from Northflank’s web UI / CLI / API.',
                },
                {
                  Icon: AddToQueue,
                  heading: 'High availability & replication',
                  text: 'Configure high availability using multi‑replica services and health‑checks. Ensure healthy containers are always serving requests during deploy and steady state operation. Enable replication or create a new database by forking a database you already have running.',
                },
                {
                  Icon: Pulse,
                  heading: 'Health checks',
                  text: 'Configure HTTP(S), TCP, and CMD liveness/readiness health checks to monitor the status of your deployed services.',
                },
              ]}
              subFeaturesGrid="1fr"
              subFeaturesGap={[12, 12, 13]}
              subFeaturesAlign="stretch"
            />
          </Box>
          <Box height="700px" overflow="hidden" position="relative" p={8}>
            <Box
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
              background={`linear-gradient(to right, transparent 0%, transparent 70%, ${theme.colors.background} 95%)`}
              backgroundSize="cover"
              zIndex={-1}
            />
            <Box
              as="img"
              src="/images/solutions/use-cases/backupschedule.png"
              position="relative"
              height="100%"
              border="1px solid"
              borderColor="grey5"
              borderRadius={3}
              boxShadow="dropDown"
              zIndex={-2}
            />
          </Box>
        </Grid>

        <Grid
          gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
          alignItems="center"
          gridGap={14}
          mb={[13, 13, 14]}
        >
          <Box height="700px" overflow="hidden" position="relative" p={8}>
            <Box
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
              background={`linear-gradient(to left, transparent 0%, transparent 70%, ${theme.colors.background} 95%)`}
              backgroundSize="cover"
              zIndex={-1}
            />
            <Box
              as="img"
              src="/images/solutions/use-cases/logs.png"
              position="absolute"
              right={0}
              height="684px"
              border="1px solid"
              borderColor="grey5"
              borderRadius={3}
              boxShadow="dropDown"
              zIndex={-2}
            />
          </Box>
          <Box>
            <Box mb={[12, 12, 13]}>
              <PreHeading mb={6}>
                Get a better troubleshooting experience
              </PreHeading>
              <Heading fontSize={[5, 5, '32px']} mb={6}>
                Observability
              </Heading>
              <Text fontSize="18px" color="grey2">
                For developers to be successful, you need observability.
                Northflank automatically grants monitoring, logging, alerting,
                and other observability essentials to every service.
              </Text>
            </Box>
            <PrimaryFeatureBlock
              subFeatures={[
                {
                  Icon: PlayCircle,
                  heading: 'Real-time log tailing & metrics',
                  text: 'Debug your builds, deployments, jobs, addons, backups, and restores in real-time by watching your logs live. Keep track of how your builds and deployments are performing with detailed metrics such as CPU & memory usage, number of requests, and etc.',
                },
                {
                  Icon: Customize,
                  heading: 'Plug-and-play logging & metrics',
                  text: 'Log sinks allow you to forward logs from containers to your preferred log aggregator or observability platform. Forward specific projects or your whole account at once. Northflank’s included logging and metrics solutions store data for up to 60 days.',
                },
                {
                  Icon: Bell,
                  heading: 'Notification integrations',
                  text: 'Send notifications to Slack, Discord, Webhooks, or Teams. Use platform alerts to receive notifications when builds, backups, job runs, billing, or autoscaling events occur on the platform. Use infrastructure alerts to receive notifications when resource usage spikes, or your apps and databases are crashing.',
                },
              ]}
              subFeaturesGrid="1fr"
              subFeaturesGap={[12, 12, 13]}
              subFeaturesAlign="stretch"
            />
          </Box>
        </Grid>

        <Grid
          gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
          alignItems="center"
          gridGap={14}
          mb={[13, 13, 14]}
        >
          <Box>
            <Box mb={[12, 12, 13]}>
              <PreHeading mb={6}>
                Connect and investigate when you need to
              </PreHeading>
              <Heading fontSize={[5, 5, '32px']} mb={6}>
                Live debugging
              </Heading>
              <Text fontSize="18px" color="grey2">
                Sometimes you just need to SSH in and see what’s happening for
                yourself. For those cases, Northflank has you covered.
              </Text>
            </Box>
            <PrimaryFeatureBlock
              subFeatures={[
                {
                  Icon: Terminal,
                  heading: 'Shell access',
                  text: 'Use shell access to debug and troubleshoot your containers (in development and production). Execute commands within a container and view the environment, filesystem, and processes. Connect to private database endpoints locally using the Northflank CLI proxy.',
                },
                {
                  Icon: Group,
                  heading: 'Real-time multiplayer',
                  text: 'The Northflank interface updates in real-time to reflect changes made by you or by your teammates. No more refreshing pages to fetch updates.',
                },
                {
                  Icon: CodeAlt,
                  heading: 'Real-time diff views',
                  text: 'When editing secret variables or infrastructure as code, live diffs are shown to prevent overwriting changes when your colleagues are editing the same item.',
                },
                {
                  Icon: BuildingHouse,
                  heading: 'Jump between teams',
                  text: 'Group your cloud resources by project, team, and organisation. Projects can be deployed to a specific region or worker cluster. Search and jump between teams and projects in a matter of clicks. All your resources will be loaded and accessible instantly.',
                },
              ]}
              subFeaturesGrid="1fr"
              subFeaturesGap={[12, 12, 13]}
              subFeaturesAlign="stretch"
            />
          </Box>
          <Box height="700px" overflow="hidden" position="relative" p={8}>
            <Box
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
              background={`linear-gradient(to right, transparent 0%, transparent 70%, ${theme.colors.background} 95%)`}
              backgroundSize="cover"
              zIndex={-1}
            />
            <Box
              as="img"
              src="/images/solutions/use-cases/jobruns.png"
              position="relative"
              height="100%"
              border="1px solid"
              borderColor="grey5"
              borderRadius={3}
              boxShadow="dropDown"
              zIndex={-2}
            />
          </Box>
        </Grid>

        <FancyQuote
          body={
            <>
              Northflank is way easier than gluing a bunch of tools together to
              spin up apps and databases. It’s the ideal platform to deploy
              containers in our cloud account, avoiding the brain damage of big
              cloud and Kubernetes. It’s more powerful and flexible than
              traditional PaaS – all within our VPC.{' '}
              <Text as="span" color="success" fontWeight={500}>
                Northflank has become a go-to way to deploy workloads at Sentry
              </Text>
              .
            </>
          }
          attribution={
            <TestimonialHeader
              name="David Cramer"
              position="Co-Founder and CPO @ Sentry"
              avatar="/images/landing/quotes/david-c.jpeg"
              linkedin="https://www.linkedin.com/in/dmcramer/"
              mb={0}
            />
          }
        />
      </Box>

      <Box background="linear-gradient(to bottom, #141c28 0%, #192338 30%, #192338 70%, #141c28 100%)">
        <Box variant="bounding" py={[12, 12, '120px !important']}>
          <DevExpSection />
        </Box>
      </Box>

      <Box variant="bounding">
        <StatsSection />
      </Box>

      <Box
        background={`linear-gradient(to bottom, #141c28 0%, transparent 30%, #192338 70%, #141c28 100%), ${texture}, linear-gradient(to bottom, #141c28 0%, #192338 30%, #192338 70%, #141c28 100%)`}
      >
        <Box variant="bounding" py={[12, 12, '120px !important']}>
          <Grid
            gridTemplateColumns={['1fr', '1fr', '1fr 1.2fr']}
            gridGap={[12, 12, 13]}
            mb={[12, 12, 13]}
          >
            <Box>
              <PreHeading mb={6}>
                Remove the pain of late night pages
              </PreHeading>
              <Heading fontSize={[5, 5, '32px']} color="grey1" mb={8}>
                Challenges for disaster recovery
              </Heading>
              <Text fontSize="18px" color="grey2">
                If you never recover, it’s just a disaster. Stateful workloads
                need alerts and clean UX to help you identify failure
                conditions. Redeploying a database is error prone and
                challenging for even the most seasoned admin.
              </Text>
            </Box>
            <Box as="img" src="/images/landing/k8s.png" width="100%" />
          </Grid>
          <Box mb={[12, 12, 13]}>
            <PrimaryFeatureBlock
              subFeatures={[
                {
                  Icon: Data,
                  heading: 'Backups, backups, backups',
                  text: 'Securely creating, storing, and testing backups is a pain. Each microservice needs to be backed by its own data store or relational DB. When databases run without automation, it’s not just a challenge, it’s impossible to manage backups properly. Northflank automates database management with proven solutions that you can find under add-ons.',
                },
                {
                  Icon: Shield,
                  heading: 'Accessibility vs security, FIGHT!',
                  text: 'How do you make databases and data stores accessible to SREs, while also keeping them secure? Your data in the Cloud needs to be encrypted and secure, while remaining accessible during disasters and debugging sessions. Northflank sets up databases with secure defaults, and hardens data-in-transit with features like mTLS and RBAC.',
                },
                {
                  Icon: Error,
                  heading: 'Failures in stateless apps',
                  text: 'In the Cloud, every service you depend on is constantly at risk of failure. Even stateless apps like NEXT.js need a recovery plan. How do you ensure each app is instrumented for observability? Northflank automatically instruments deployments, and includes solutions for alerting and mapping ownership of services by team.',
                },
              ]}
              subFeaturesGrid={['1fr', '1fr', 'repeat(2, 1fr)']}
              subFeaturesGap={[12, 12, 13]}
              subFeaturesAlign="stretch"
            />
          </Box>
          <FancyQuote
            body={
              <>
                Northflank is the first batteries-included developer platform
                that doesn’t suffer from the invisible ceilings that hover over
                its competitors.{' '}
                <Text as="span" color="success" fontWeight={500}>
                  We could have built all of Slack with Northflank
                </Text>{' '}
                – and we would have, had it been available.
              </>
            }
            attribution={
              <TestimonialHeader
                name="Keith Adams"
                position="Formerly Chief Architect Slack, Facebook, VMware, GP @ Pebblebed"
                avatar="/images/landing/quotes/keith.jpeg"
                linkedin="https://linkedin.com/in/keith-adams-pb/"
                mb={0}
              />
            }
          />
        </Box>
      </Box>

      <Box py={[12, 12, '96px']}>
        <LargeCarousel
          images={[
            '/images/solutions/use-cases/backupschedule.png',
            '/images/solutions/use-cases/cluster.png',
            '/images/solutions/use-cases/clusterobserve.png',
            '/images/solutions/use-cases/dashboard.png',
            '/images/solutions/use-cases/jobruns.png',
            '/images/solutions/use-cases/logs.png',
            '/images/solutions/use-cases/metrics.png',
            '/images/solutions/use-cases/preview.png',
            '/images/solutions/use-cases/previewduration.png',
            '/images/solutions/use-cases/releaseflow.png',
          ]}
        />
      </Box>

      <Box variant="bounding">
        <UseCasesFooterLinks mb={[12, 12, 13]} />
        <GetStartedCta />
      </Box>
    </>
  );
};

export default DisasterRecoveryPage;
