import css from '@styled-system/css';
import styled from 'styled-components';

import Box from '@northflank/components/Box';

const FlexibleImage = styled(Box)(({ src, height = '100%', aspectRatio }) =>
  css({
    backgroundImage: `url(${src})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height,
    aspectRatio,
  })
);

export default FlexibleImage;
