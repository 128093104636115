import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Landing from './pages';
import NotFound from './pages/404';
import Error from './pages/500';
import About from './pages/about';
import Author from './pages/author';
import BlogIndex from './pages/blog';
import BlogPost from './pages/blog/post';
import CareersIndex from './pages/careers';
import CareersPost from './pages/careers/post';
import ChangelogIndex from './pages/changelog';
import ChangelogPost from './pages/changelog/post';
import CloudProvidersIndex from './pages/cloud';
import CloudGpusIndex from './pages/cloud/gpus';
import CloudGpusGpu from './pages/cloud/gpus/gpu';
import CloudProvidersProvider from './pages/cloud/provider';
import CloudProvidersProviderInstances from './pages/cloud/provider/instances';
import CloudProvidersProviderInstancesInstance from './pages/cloud/provider/instances/instance';
import CloudProvidersProviderRegions from './pages/cloud/provider/regions';
import CloudProvidersProviderRegionsRegion from './pages/cloud/provider/regions/region';
import Contact from './pages/contact';
import CmsPage from './pages/dynamic-cms';
import FeaturesIndex from './pages/features';
import FeaturesByoc from './pages/features/bring-your-own-cloud';
import FeaturesBuild from './pages/features/build';
import FeaturesDatabases from './pages/features/databases';
import FeaturesManagedCloud from './pages/features/managed-cloud';
import FeaturesObserve from './pages/features/observe';
import FeaturesPlatform from './pages/features/platform';
import FeaturesRelease from './pages/features/release';
import FeaturesRun from './pages/features/run';
import FeaturesScale from './pages/features/scale';
import FeaturesTemplates from './pages/features/templates';
import Gpu from './pages/gpu';
import GuideIndex from './pages/guides';
import GuidePost from './pages/guides/post';
import LegalCookies from './pages/legal/cookies';
import LegalPrivacy from './pages/legal/privacy';
import LegalTerms from './pages/legal/terms';
import Newspaper from './pages/newspaper';
import Pricing from './pages/pricing';
import Security from './pages/security';
import StackIndex from './pages/stacks';
import StackPost from './pages/stacks/post';
import TLDR from './pages/tldr';
import UseCasesAppPlatform from './pages/use-cases/app-platform-for-kubernetes';
import UseCasesByoc from './pages/use-cases/bring-your-own-cloud-app-platform-kubernetes';
import UseCasesDisasterRecovery from './pages/use-cases/disaster-recovery-for-kubernetes';
import UseCasesIdp from './pages/use-cases/internal-developer-platform-idp-for-kubernetes';
import UseCasesPreviewEnvs from './pages/use-cases/preview-environments-backend-for-kubernetes';
import UseCasesProductionWorkloads from './pages/use-cases/production-workloads-deployment-platform';
import UseCasesRightSized from './pages/use-cases/right-sized-infrastructure-optimisation-for-kubernetes';
import UseCasesSelfService from './pages/use-cases/self-service-developer-experience-for-kubernetes';

const Routes = ({ pageData, notFound, error }) => {
  if (notFound) return <NotFound />;

  if (error) return <Error />;

  return (
    <Switch>
      {/* About */}
      <Route path="/about" render={() => <About />} />

      {/* Author */}
      <Route
        path="/author/:slug"
        render={() => (
          <Author
            author={pageData.author}
            renderedBio={pageData.renderedBio}
            posts={pageData.posts}
          />
        )}
      />

      {/* Blog */}
      <Route
        path="/blog/tag/:tag"
        render={({
          match: {
            params: { tag },
          },
        }) => (
          <BlogIndex
            posts={pageData.taggedBlogPosts?.blogs?.data}
            total={pageData.taggedBlogPosts?.blogs?.meta?.pagination?.total}
            tag={tag}
            tags={pageData.tags?.tags?.data}
          />
        )}
      />
      <Route
        path="/blog/:slug"
        render={() => (
          <BlogPost
            post={pageData.blogPost}
            renderedPost={pageData.renderedBlogPost}
            newerPosts={pageData.newerBlogPosts}
            related={pageData.relatedBlogPosts}
          />
        )}
      />
      <Route
        path="/blog"
        render={() => (
          <BlogIndex
            posts={pageData.blogPosts?.blogs?.data}
            total={pageData.blogPosts?.blogs?.meta?.pagination?.total}
          />
        )}
      />

      {/* Careers */}
      <Route
        path="/careers/:slug"
        render={() => <CareersPost job={pageData.job} />}
      />
      <Route
        path="/careers"
        render={() => <CareersIndex jobs={pageData.jobs} />}
      />

      {/* Changelog */}
      <Route
        path="/changelog/:slug"
        render={() => (
          <ChangelogPost
            post={pageData.changelogPost}
            renderedPost={pageData.renderedChangelogPost}
            newerPosts={pageData.newerChangelogPosts}
            isOldHtmlPost={pageData.isOldHtmlPost}
          />
        )}
      />
      <Route
        path="/changelog"
        render={() => (
          <ChangelogIndex
            posts={pageData.changelogPosts?.changelogs?.data}
            total={pageData.changelogPosts?.changelogs?.meta?.pagination?.total}
          />
        )}
      />

      {/* Cloud providers */}
      <Route
        path="/cloud/gpus/:gpu"
        render={() => (
          <CloudGpusGpu
            gpusOfType={pageData.gpusOfType}
            otherGPUs={pageData.otherGPUs}
          />
        )}
      />
      <Route
        path="/cloud/gpus"
        render={() => <CloudGpusIndex providerGpus={pageData.providerGpus} />}
      />
      <Route
        path="/cloud/:provider/instances/:nodeType"
        render={() => (
          <CloudProvidersProviderInstancesInstance
            cloudProvider={pageData.cloudProvider}
            providerNodeType={pageData.providerNodeType}
            providerNodes={pageData.providerNodes}
            nodeRegions={pageData.nodeRegions}
            otherGpus={pageData.otherGpus}
          />
        )}
      />
      <Route
        path="/cloud/:provider/instances"
        render={() => (
          <CloudProvidersProviderInstances
            cloudProvider={pageData.cloudProvider}
            providerNodes={pageData.providerNodes}
          />
        )}
      />
      <Route
        path="/cloud/:provider/regions/:region"
        render={() => (
          <CloudProvidersProviderRegionsRegion
            cloudProvider={pageData.cloudProvider}
            providerRegion={pageData.providerRegion}
            regions={pageData.regions}
            providerNodes={pageData.providerNodes}
          />
        )}
      />
      <Route
        path="/cloud/:provider/regions"
        render={() => (
          <CloudProvidersProviderRegions
            cloudProvider={pageData.cloudProvider}
            providerRegions={pageData.providerRegions}
          />
        )}
      />
      <Route
        path="/cloud/:provider"
        render={() => (
          <CloudProvidersProvider
            cloudProvider={pageData.cloudProvider}
            providerRegions={pageData.providerRegions}
            providerNodes={pageData.providerNodes}
            gpuNodes={pageData.gpuNodes}
          />
        )}
      />
      <Route
        path="/cloud"
        render={() => (
          <CloudProvidersIndex cloudProviders={pageData.cloudProviders} />
        )}
      />

      {/* Contact */}
      <Route path="/contact" render={() => <Contact />} />

      {/* Features */}
      <Route
        path="/features/bring-your-own-cloud"
        render={() => (
          <FeaturesByoc
            providers={pageData.cloudProviders}
            regions={pageData.cloudRegions}
          />
        )}
      />
      <Route path="/features/build" render={() => <FeaturesBuild />} />
      <Route path="/features/databases" render={() => <FeaturesDatabases />} />
      <Route
        path="/features/managed-cloud"
        render={() => <FeaturesManagedCloud />}
      />
      <Route path="/features/observe" render={() => <FeaturesObserve />} />
      <Route path="/features/platform" render={() => <FeaturesPlatform />} />
      <Route path="/features/release" render={() => <FeaturesRelease />} />
      <Route path="/features/run" render={() => <FeaturesRun />} />
      <Route path="/features/scale" render={() => <FeaturesScale />} />
      <Route path="/features/templates" render={() => <FeaturesTemplates />} />
      <Route
        path="/features"
        render={() => <FeaturesIndex dbaasPages={pageData.dbaasPages} />}
      />

      {/* GPUs */}
      <Route
        path="/gpu"
        render={() => (
          <Gpu gpus={pageData.gpus} gpuProviders={pageData.gpuProviders} />
        )}
      />

      {/* Guides */}
      <Route
        path="/guides/category/:category"
        render={() => (
          <GuideIndex
            posts={pageData.guidePosts?.guides?.data}
            total={pageData.guidePosts?.guides?.meta?.pagination?.total}
            categories={pageData.guideCategoriesAndTags?.guideCategories}
            tags={pageData.guideCategoriesAndTags?.tags}
            currentCategory={pageData.guideCurrentCategory}
          />
        )}
      />
      <Route
        path="/guides/tag/:tag"
        render={() => (
          <GuideIndex
            posts={pageData.guidePosts?.guides?.data}
            total={pageData.guidePosts?.guides?.meta?.pagination?.total}
            categories={pageData.guideCategoriesAndTags?.guideCategories}
            tags={pageData.guideCategoriesAndTags?.tags}
            currentTag={pageData.guideCurrentTag}
          />
        )}
      />
      <Route
        path="/guides/:slug"
        render={() => (
          <GuidePost
            post={pageData.guidePost}
            renderedPost={pageData.renderedGuidePost}
            newerGuides={pageData.newerGuides}
            related={pageData.relatedGuides}
          />
        )}
      />
      <Route
        path="/guides"
        render={() => (
          <GuideIndex
            posts={pageData.guidePosts?.guides?.data}
            total={pageData.guidePosts?.guides?.meta?.pagination?.total}
            categories={pageData.guideCategoriesAndTags?.guideCategories}
            tags={pageData.guideCategoriesAndTags?.tags}
          />
        )}
      />

      {/* Legal */}
      <Route path="/legal/cookies" render={() => <LegalCookies />} />
      <Route path="/legal/privacy" render={() => <LegalPrivacy />} />
      <Route path="/legal/terms" render={() => <LegalTerms />} />

      {/* Newspaper */}
      <Route path="/newspaper" render={() => <Newspaper />} />

      {/* Pricing */}
      <Route
        path="/pricing"
        render={() => (
          <Pricing plans={pageData.plans} addonTypes={pageData.addonTypes} />
        )}
      />

      {/* Security */}
      <Route path="/security" render={() => <Security />} />

      {/* Stacks */}
      <Route
        path="/stacks/:slug"
        render={() => (
          <StackPost
            post={pageData.stackPost}
            renderedPost={pageData.renderedStackPost}
          />
        )}
      />
      <Route
        path="/stacks"
        render={() => (
          <StackIndex
            stacks={pageData.stackGuides?.stackGuides?.data}
            categories={pageData.stackCategories?.stackCategories?.data}
          />
        )}
      />

      {/* TLDR */}
      <Route path="/tldr" render={() => <TLDR />} />

      {/* Use cases */}
      <Route
        path="/use-cases/app-platform-for-kubernetes"
        render={() => <UseCasesAppPlatform />}
      />
      <Route
        path="/use-cases/bring-your-own-cloud-app-platform-kubernetes"
        render={() => <UseCasesByoc />}
      />
      <Route
        path="/use-cases/disaster-recovery-for-kubernetes"
        render={() => <UseCasesDisasterRecovery />}
      />
      <Route
        path="/use-cases/internal-developer-platform-idp-for-kubernetes"
        render={() => <UseCasesIdp />}
      />
      <Route
        path="/use-cases/preview-environments-backend-for-kubernetes"
        render={() => <UseCasesPreviewEnvs />}
      />
      <Route
        path="/use-cases/production-workloads-deployment-platform"
        render={() => <UseCasesProductionWorkloads />}
      />
      <Route
        path="/use-cases/right-sized-infrastructure-optimisation-for-kubernetes"
        render={() => <UseCasesRightSized />}
      />
      <Route
        path="/use-cases/self-service-developer-experience-for-kubernetes"
        render={() => <UseCasesSelfService />}
      />

      {/* Landing */}
      <Route path="/" render={() => <Landing />} exact />

      {/* CMS page or 404 */}
      <Route
        path="*"
        render={() =>
          pageData.cmsPage ? (
            <CmsPage
              page={pageData.cmsPage}
              renderedPage={pageData.renderedCmsPage}
              canPreview={pageData.canPreview}
            />
          ) : (
            <NotFound />
          )
        }
      />
    </Switch>
  );
};

export default Routes;
