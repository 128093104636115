import React, { useContext, useEffect, useMemo, useState } from 'react';

import { lighten } from 'polished';
import styled, { keyframes, ThemeContext } from 'styled-components';

import { mapColors } from '@northflank/theme/core';

const CONTINENTS = [
  {
    name: 'Australia',
    origin: [74, 32],
    area: [
      [4, 0],
      [7, 1],
      [15, 7],
      [13, 9],
      [0, 6],
      [0, 2],
    ],
    dots: [
      [4, 0, 1],
      [2, 1, 6],
      [0, 2, 9],
      [0, 3, 10],
      [0, 4, 10],
      [0, 5, 3],
      [5, 5, 5],
      [5, 6, 4],
      [15, 7, 1],
      [14, 8, 1],
      [13, 9, 1],
    ],
  },
  {
    name: 'Asia',
    origin: [52, 1],
    area: [
      [16, 0],
      [38, 5],
      [40, 7],
      [28, 17],
      [24, 25],
      [29, 29],
      [19, 29],
      [11, 24],
      [3, 23],
      [0, 20],
      [0, 19],
      [6, 13],
      [7, 6],
    ],
    dots: [
      [16, 0, 1],
      [17, 1, 2],
      [18, 2, 2],
      [15, 3, 6],
      [28, 3, 1],
      [30, 3, 1],
      [10, 4, 2],
      [13, 4, 10],
      [24, 4, 1],
      [9, 5, 22],
      [32, 5, 1],
      [38, 5, 1],
      [7, 6, 2],
      [10, 6, 1],
      [12, 6, 27],
      [7, 7, 34],
      [7, 8, 31],
      [7, 9, 26],
      [34, 9, 3],
      [7, 10, 22],
      [31, 10, 1],
      [33, 10, 1],
      [7, 11, 21],
      [32, 11, 2],
      [7, 12, 21],
      [32, 12, 1],
      [6, 13, 22],
      [32, 13, 1],
      [6, 14, 22],
      [5, 15, 22],
      [3, 16, 2],
      [6, 16, 20],
      [2, 17, 3],
      [6, 17, 16],
      [24, 17, 1],
      [28, 17, 1],
      [1, 18, 22],
      [26, 18, 2],
      [0, 19, 24],
      [0, 20, 5],
      [6, 20, 17],
      [2, 21, 5],
      [10, 21, 14],
      [2, 22, 5],
      [11, 22, 4],
      [16, 22, 4],
      [3, 23, 3],
      [11, 23, 2],
      [17, 23, 3],
      [23, 23, 1],
      [11, 24, 2],
      [18, 24, 2],
      [23, 24, 1],
      [24, 25, 1],
      [18, 26, 1],
      [22, 26, 1],
      [18, 27, 1],
      [20, 27, 4],
      [18, 28, 1],
      [21, 28, 1],
      [23, 28, 1],
      [26, 28, 3],
      [19, 29, 1],
      [28, 29, 2],
    ],
  },
  {
    // 21X, 40Y
    name: 'Africa',
    origin: [40, 19],
    area: [
      [3, 0],
      [6, 0],
      [11, 2],
      [16, 7],
      [16, 15],
      [11, 18],
      [9, 18],
      [0, 6],
      [0, 3],
    ],
    dots: [
      [3, 0, 4],
      [2, 1, 6],
      [9, 1, 2],
      [1, 2, 11],
      [0, 3, 13],
      [0, 4, 14],
      [0, 5, 14],
      [0, 6, 16],
      [1, 7, 16],
      [2, 8, 2],
      [6, 8, 11],
      [7, 9, 9],
      [7, 10, 8],
      [7, 11, 7],
      [8, 12, 7],
      [7, 13, 8],
      [7, 14, 7],
      [16, 14, 1],
      [8, 15, 5],
      [15, 15, 2],
      [8, 16, 5],
      [9, 17, 3],
      [9, 18, 3],
    ],
  },
  {
    name: 'Europe',
    origin: [39, 2],
    area: [
      [8, 0],
      [10, 0],
      [20, 2],
      [19, 11],
      [18, 13],
      [14, 16],
      [3, 16],
      [0, 7],
    ],
    dots: [
      [8, 0, 3],
      [9, 1, 1],
      [20, 2, 1],
      [19, 3, 1],
      [12, 4, 1],
      [19, 4, 1],
      [9, 5, 6],
      [9, 6, 7],
      [17, 6, 3],
      [0, 7, 1],
      [8, 7, 12],
      [7, 8, 3],
      [11, 8, 9],
      [7, 9, 3],
      [11, 9, 9],
      [4, 10, 1],
      [7, 10, 1],
      [9, 10, 1],
      [11, 10, 9],
      [3, 11, 2],
      [7, 11, 13],
      [4, 12, 1],
      [6, 12, 13],
      [4, 13, 15],
      [5, 14, 3],
      [9, 14, 4],
      [15, 14, 2],
      [3, 15, 3],
      [8, 15, 1],
      [10, 15, 5],
      [6, 15, 2],
      [3, 16, 2],
      [10, 16, 5],
    ],
  },
  {
    name: 'South America',
    origin: [22, 26],
    area: [
      [2, 0],
      [5, 0],
      [11, 4],
      [11, 8],
      [3, 18],
      [2, 17],
      [0, 4],
      [0, 3],
    ],
    dots: [
      [2, 0, 4],
      [1, 1, 7],
      [1, 2, 7],
      [0, 3, 10],
      [0, 4, 12],
      [1, 5, 11],
      [2, 6, 9],
      [3, 7, 8],
      [3, 8, 8],
      [3, 9, 6],
      [3, 10, 6],
      [3, 11, 5],
      [3, 12, 3],
      [2, 13, 3],
      [2, 14, 3],
      [2, 15, 2],
      [2, 16, 2],
      [2, 17, 2],
      [3, 18, 1],
    ],
  },
  {
    name: 'North America',
    origin: [0, 0],
    area: [
      [21, 0],
      [39, 0],
      [39, 6],
      [22, 26],
      [16, 23],
      [2, 12],
      [0, 7],
    ],
    dots: [
      [22, 0, 6],
      [29, 0, 1],
      [31, 0, 1],
      [33, 0, 5],
      [20, 1, 1],
      [22, 1, 1],
      [24, 1, 2],
      [27, 1, 13],
      [17, 2, 1],
      [20, 2, 5],
      [26, 2, 13],
      [13, 3, 1],
      [19, 3, 1],
      [21, 3, 3],
      [26, 3, 14],
      [14, 4, 1],
      [16, 4, 4],
      [21, 4, 3],
      [29, 4, 11],
      [12, 5, 3],
      [16, 5, 1],
      [18, 5, 1],
      [20, 5, 3],
      [24, 5, 1],
      [30, 5, 8],
      [14, 6, 3],
      [19, 6, 1],
      [22, 6, 4],
      [31, 6, 8],
      [0, 7, 15],
      [16, 7, 1],
      [18, 7, 4],
      [24, 7, 2],
      [30, 7, 7],
      [2, 8, 20],
      [24, 8, 3],
      [29, 8, 5],
      [2, 9, 20],
      [24, 9, 2],
      [30, 9, 3],
      [1, 10, 18],
      [23, 10, 2],
      [31, 10, 1],
      [2, 11, 2],
      [8, 11, 11],
      [23, 11, 2],
      [26, 11, 1],
      [2, 12, 1],
      [8, 12, 13],
      [24, 12, 3],
      [10, 13, 12],
      [23, 13, 5],
      [11, 14, 17],
      [11, 15, 9],
      [21, 15, 6],
      [28, 15, 2],
      [11, 16, 11],
      [23, 16, 4],
      [11, 17, 14],
      [12, 18, 11],
      [12, 19, 12],
      [13, 20, 9],
      [15, 21, 3],
      [22, 21, 1],
      [16, 22, 2],
      [16, 23, 2],
      [20, 23, 1],
      [23, 23, 1],
      [18, 24, 3],
      [21, 25, 1],
      [22, 26, 1],
    ],
  },
];

const FACTOR = 10;

const maxCoordinate = (a, b) => [Math.max(a[0], b[0]), Math.max(a[1], b[1])];

const getSvgStringFromDot = ({ dot, origin, extent, count: inCount }) => {
  const count = inCount ?? dot[2];
  const spots = [];
  for (let i = 0; i < count; i += 1) spots.push('h0');
  const dotCommands = spots.join(' m10,0 ');
  const x = FACTOR * (origin[0] + dot[0] + 1);
  const y = FACTOR * (origin[1] + dot[1] + 1);
  return {
    string: `M${x},${y} ${dotCommands}`,
    extent: extent
      ? maxCoordinate(extent, [origin[0] + dot[0] + dot[2], origin[1] + dot[1]])
      : undefined,
  };
};

const buildContinentState = ({ area, dots, origin }) => {
  let extent = [...origin];

  const stateDots = dots
    .map((dot) => {
      const { string, extent: newExtent } = getSvgStringFromDot({
        dot,
        origin,
        extent,
      });
      extent = newExtent;
      return string;
    })
    .join(' ');

  const stateArea = `${area
    .map((point, index) => {
      const x = FACTOR * (point[0] + origin[0] + 1);
      const y = FACTOR * (point[1] + origin[1] + 1);
      return `${index === 0 ? 'M' : 'L'}${x},${y}`;
    })
    .join(' ')} Z`;

  const mid = [
    origin[0] + (extent[0] - origin[0]) / 2,
    origin[1] + (extent[1] - origin[1]) / 2,
  ];

  return {
    area: stateArea,
    dots: stateDots,
    origin,
    extent,
    mid,
  };
};

const continentsState = CONTINENTS.reduce((acc, continent) => {
  acc[continent.name] = buildContinentState(continent);
  return acc;
}, {});

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const StyledWorldMap = styled.svg`
  width: 100%;

  .connectingCurveGlow {
    filter: blur(8px);
  }

  g.in {
    animation: ${fadeIn} 500ms linear forwards;
  }
  g.out {
    animation: ${fadeOut} 500ms linear forwards;
  }
`;

const randomIntBetween = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);

const Map = () => {
  const [tick, setTick] = useState(0);
  const [locations, setLocations] = useState([]);

  const theme = useContext(ThemeContext);

  const getRandomColor = () => {
    return mapColors[Math.floor(Math.random() * mapColors.length)];
  };

  const continents = useMemo(
    () =>
      Object.keys(continentsState).map((name) => {
        const { area, dots } = continentsState[name];
        return (
          <g key={name}>
            <path stroke="none" fill="#fff" fillOpacity="0.00" d={area} />
            <path
              d={dots}
              strokeLinecap="round"
              shapeRendering="geometricPrecision"
              strokeWidth="4"
              stroke={theme.colors.grey4}
            />
          </g>
        );
      }),
    [theme.colors.grey4]
  );

  const places = useMemo(
    () =>
      locations.map((loc) => {
        const { d, colour, duration, prevDot, hasLine, animateOut, removed } =
          loc;

        if (removed) return null;

        let curve;
        let curveLength;

        if (prevDot && !prevDot.animateOut && !prevDot.removed && hasLine) {
          const prevD = prevDot.d;
          const prevCoords = prevD
            .replace('M', '')
            .replace(' h0', '')
            .split(',');
          const coords = d.replace('M', '').replace(' h0', '').split(',');

          curveLength = Math.sqrt(
            Math.abs(coords[0] - prevCoords[0]) ** 2 +
              Math.abs(coords[1] - prevCoords[1]) ** 2
          );

          curve = `${d} C ${coords[0]} ${coords[1] - 60}, ${prevCoords[0]} ${prevCoords[1] - 60}, ${
            prevCoords[0]
          } ${prevCoords[1]}`;
        }

        return (
          <g key={d} className={animateOut ? 'out' : 'in'}>
            <path
              strokeLinecap="round"
              shapeRendering="geometricPrecision"
              strokeWidth="6"
              stroke={colour}
              d={d}
            />
            <path
              key={`${d}-anim`}
              strokeLinecap="round"
              shapeRendering="geometricPrecision"
              strokeWidth="6"
              stroke={colour}
              d={d}
            >
              <animate
                attributeName="stroke-width"
                dur={`${duration}ms`}
                from="6"
                to="40"
                fill="freeze"
                repeatCount="indefinite"
              />
              <animate
                attributeType="CSS"
                attributeName="opacity"
                dur={`${duration}ms`}
                from="0.6"
                to="0"
                fill="freeze"
                repeatCount="indefinite"
              />
            </path>
            {curve && curveLength > 60 && (
              <>
                <path
                  key={curve}
                  d={curve}
                  fill="none"
                  stroke="url(#grad)"
                  strokeWidth="2"
                  strokeDasharray={curveLength}
                  strokeDashoffset={curveLength * 2}
                  opacity="0.7"
                >
                  <animate
                    attributeName="stroke-dashoffset"
                    dur={`${duration}ms`}
                    from={curveLength * 2}
                    to="0"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  key={`${curve}-glow`}
                  d={curve}
                  fill="none"
                  stroke={theme.colors.success}
                  strokeWidth="3"
                  strokeDasharray={curveLength}
                  strokeDashoffset={curveLength * 2}
                  className="connectingCurveGlow"
                >
                  <animate
                    attributeName="stroke-dashoffset"
                    dur={`${duration}ms`}
                    from={curveLength * 2}
                    to="0"
                    repeatCount="indefinite"
                  />
                </path>
              </>
            )}
          </g>
        );
      }),
    [tick]
  );

  useEffect(() => {
    setLocations((l) => {
      const currentLocations = [...l];

      currentLocations
        .filter((loc) => !loc.removed)
        .forEach((loc) => {
          if (!loc.animateOut && loc.created + loc.lifetime < Date.now()) {
            loc.animateOut = Date.now();
          }
          if (
            typeof loc.animateOut === 'number' &&
            Date.now() - loc.animateOut > 750
          ) {
            loc.removed = true;
          }
        });

      if (
        currentLocations.filter((loc) => !loc.animateOut && !loc.removed)
          .length >= 15
      ) {
        return currentLocations;
      }

      const randomContinent =
        CONTINENTS[Math.floor(Math.random() * CONTINENTS.length)];
      const randomDot =
        randomContinent.dots[
          Math.floor(Math.random() * randomContinent.dots.length)
        ];

      const d = getSvgStringFromDot({
        dot: randomDot,
        origin: randomContinent.origin,
        count: 1,
      }).string;

      if (
        currentLocations.filter(
          (loc) => !loc.animateOut && !loc.removed && loc.d === d
        ).length === 0
      ) {
        const duration = randomIntBetween(1000, 3000);
        const lifetime = duration * randomIntBetween(1, 6);

        const eligibleConnectingDots = currentLocations.filter((loc) => {
          const elapsed = Date.now() - loc.created;
          const lifeRemaining = loc.lifetime - elapsed;
          return !loc.animateOut && !loc.removed && lifeRemaining > lifetime;
        });
        const prevDot =
          eligibleConnectingDots[
            Math.floor(Math.random() * eligibleConnectingDots.length)
          ];

        currentLocations.push({
          d,
          colour: getRandomColor(),
          duration,
          lifetime,
          created: Date.now(),
          prevDot,
          hasLine:
            Math.random() > 0.4 &&
            currentLocations.filter(
              (loc) => !!loc.hasLine && !loc.animateOut && !loc.removed
            ).length < 3,
          animateOut: false,
          removed: false,
        });
      }

      return currentLocations;
    });

    setTimeout(() => setTick((t) => t + 1), randomIntBetween(1000, 2000));
  }, [tick, setLocations]);

  return (
    <StyledWorldMap viewBox="0 -25 940 500" preserveAspectRatio="xMinYMin meet">
      <defs>
        <linearGradient id="grad">
          <stop stopColor={theme.colors.success} />
          <stop offset="100%" stopColor={lighten(0.4, theme.colors.success)} />
        </linearGradient>
      </defs>
      <g stroke="none" fill="none" fillRule="evenodd">
        {continents}
      </g>
      {places}
    </StyledWorldMap>
  );
};

export default Map;
