import React from 'react';

import { AddToQueue } from '@styled-icons/boxicons-regular/AddToQueue';
import { Certification } from '@styled-icons/boxicons-regular/Certification';
import { CheckShield } from '@styled-icons/boxicons-regular/CheckShield';
import { Chip } from '@styled-icons/boxicons-regular/Chip';
import { Cloud } from '@styled-icons/boxicons-regular/Cloud';
import { CloudUpload } from '@styled-icons/boxicons-regular/CloudUpload';
import { CodeAlt } from '@styled-icons/boxicons-regular/CodeAlt';
import { Cog } from '@styled-icons/boxicons-regular/Cog';
import { Copy } from '@styled-icons/boxicons-regular/Copy';
import { File } from '@styled-icons/boxicons-regular/File';
import { Globe } from '@styled-icons/boxicons-regular/Globe';
import { Hdd } from '@styled-icons/boxicons-regular/Hdd';
import { Health } from '@styled-icons/boxicons-regular/Health';
import { HeartSquare } from '@styled-icons/boxicons-regular/HeartSquare';
import { Layer } from '@styled-icons/boxicons-regular/Layer';
import { LockAlt } from '@styled-icons/boxicons-regular/LockAlt';
import { MapPin } from '@styled-icons/boxicons-regular/MapPin';
import { NetworkChart } from '@styled-icons/boxicons-regular/NetworkChart';
import { Outline } from '@styled-icons/boxicons-regular/Outline';
import { PauseCircle } from '@styled-icons/boxicons-regular/PauseCircle';
import { PlayCircle } from '@styled-icons/boxicons-regular/PlayCircle';
import { Run as RunIcon } from '@styled-icons/boxicons-regular/Run';
import { Server } from '@styled-icons/boxicons-regular/Server';
import { Shield } from '@styled-icons/boxicons-regular/Shield';
import { Terminal } from '@styled-icons/boxicons-regular/Terminal';
import { TransferAlt } from '@styled-icons/boxicons-regular/TransferAlt';
import { Docker } from '@styled-icons/fa-brands/Docker';

import Box from '@northflank/components/Box';
import Heading from '@northflank/components/Heading';
import FeatureNav from '@northflank/components/marketing/FeatureNav';
import FeatureWithIcon from '@northflank/components/marketing/FeatureWithIcon';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';

import {
  FeatureGroup,
  FeaturesWrapper,
} from '../../components/FeaturePageWrapper';
import PreHeading from '../../components/PreHeading';

const Run = () => {
  return (
    <>
      <SEO
        title="Run — Features"
        description="A fully integrated suite of developer tools. Learn more about Northflank’s deploy & release capabilities."
      />
      <FeatureNav />
      <FeaturesWrapper>
        <Box variant="bounding" pb="70px!important">
          <PreHeading mb={6}>Feature breakdown</PreHeading>
          <Heading as="h1" color="grey1" fontSize={[6, 6, 7, 7]} mb={8}>
            Run on Northflank
          </Heading>
          <Text color="grey3" fontSize={4} width={[1, 1, 1 / 2]} mediaQuery>
            Make continuous deployment easy with Northflank. Deploy your own
            code built with Northflank, or any public or private Docker image.
            Automatic DNS & TLS built in.
          </Text>
        </Box>
        <FeatureGroup
          heading="Runtime Environment"
          wideImage="/images/feature/details/run/containers.png"
          wideImageAlt="Screenshot of Northflank containers list"
          items={
            <>
              <FeatureWithIcon
                Icon={Layer}
                iconGradient
                heading="Services, Workers & Apps"
                text="Run any type of workload: be it a micro-service, web API, monolithic service, background worker, or web application."
              />
              <FeatureWithIcon
                Icon={Cloud}
                iconGradient
                heading="Container Deployment"
                text="Leverage Northflank container orchestration to seamlessly deploy your workloads onto scalable cloud infrastructure."
              />
              <FeatureWithIcon
                Icon={Chip}
                iconGradient
                heading="Right Sized Compute"
                text="Select from plans with different CPU and memory levels based on the requirements of your containers."
              />
              <FeatureWithIcon
                Icon={RunIcon}
                iconGradient
                heading="Jobs and Cron Jobs"
                text="Run workloads as jobs or cron jobs. Trigger via UI, API, or CLI, or set up a cron schedule to reliably run workloads on repeat."
              />
              <FeatureWithIcon
                Icon={Copy}
                iconGradient
                heading="Zero Downtime Deploys"
                text="Configure high availability using multi&#8209;replica services and health&#8209;checks. Ensure healthy containers are always serving requests during deploy and steady state operation."
              />
              <FeatureWithIcon
                Icon={Docker}
                iconGradient
                heading="Deploy External Images"
                text="Deploy images from public and private Docker registries such as DockerHub, GCHR, ECR, Google Artifact Registry, and any other registry by using a valid .dockerconfig."
              />
            </>
          }
        />
        <FeatureGroup
          heading="Continuous Deployment"
          wideImage="/images/feature/details/run/commits.png"
          wideImageAlt="Screenshot of Northflank recent commit list interface"
          items={
            <>
              <FeatureWithIcon
                Icon={CloudUpload}
                iconGradient
                heading="Continuous Deployment"
                text="Select an image to deploy from your saved builds via the UI, API, or CLI, or automatically trigger a new deployment on a new build to a branch or pull request."
              />
              <FeatureWithIcon
                Icon={PauseCircle}
                iconGradient
                heading="CD Control"
                text="At any time you are able to pause continuous deployment, pause and restart running services, and disable a service altogether to stop builds and deployments."
              />
              <FeatureWithIcon
                Icon={PlayCircle}
                iconGradient
                heading="Release & Preview"
                text="Deploy containers reliably and repeatedly with release flows, templates, and preview environments."
              />
            </>
          }
        />
        <FeatureGroup
          heading="Advanced Configuration"
          wideImage="/images/feature/details/run/override.png"
          wideImageAlt="Screenshot of Northflank advanced Docker options configuration"
          items={
            <>
              <FeatureWithIcon
                Icon={HeartSquare}
                iconGradient
                heading="Health Checks"
                text="Configure HTTP(S), TCP, and CMD liveness/readiness health checks to monitor the status of your deployed services."
              />
              <FeatureWithIcon
                Icon={CodeAlt}
                iconGradient
                heading="CMD & Entrypoint Override"
                text="Set a custom Docker or Buildpack startup command or entrypoint to override the default configuration."
              />
              <FeatureWithIcon
                Icon={AddToQueue}
                iconGradient
                heading="Autoscaling"
                text="Scale horizontally based on how your containers are performing with CPU, memory, and request thresholds. Set minimum and maximum replicas."
              />
              <FeatureWithIcon
                Icon={Terminal}
                iconGradient
                heading="Shell Access"
                text="Debug and troubleshoot your containers in development and production using shell access - allowing you to execute commands within a container and view the environment, filesystem, and processes."
              />
              <FeatureWithIcon
                Icon={Cog}
                iconGradient
                heading="Job Configuration"
                text="Spawn containers with control over concurrency policies, time limits, retries, and environment variable overrides. Set up an automatic job run trigger when the CI source updates."
              />

              <FeatureWithIcon
                Icon={Hdd}
                iconGradient
                heading="Volumes"
                text="Create and attach volumes to your services to enable storage that persists across container restarts."
              />
            </>
          }
        />
        <FeatureGroup
          heading="Networking"
          wideImage="/images/feature/details/run/port.png"
          wideImageAlt="Screenshot of Northflank port configuration interface"
          items={
            <>
              <FeatureWithIcon
                Icon={TransferAlt}
                iconGradient
                heading="Flexible Networking"
                text="Configure any number of public HTTP/1.1 or HTTP/2 and private TCP/UDP ports for each of your deployment services."
              />
              <FeatureWithIcon
                Icon={LockAlt}
                iconGradient
                heading="Private Network"
                text="Configure private ports for inter-service communication and keep your endpoints secure."
              />
              <FeatureWithIcon
                Icon={Server}
                iconGradient
                heading="Load Balancing"
                text="Northflank automatically handles your network load balancing, making it easy to spin up multiple instances of your services."
              />
              <FeatureWithIcon
                Icon={Certification}
                iconGradient
                heading="Automatic TLS"
                text="All HTTP ports are automatically configured with a Let’s Encrypt TLS certificate."
              />
              <FeatureWithIcon
                Icon={Globe}
                iconGradient
                heading="Custom Domains"
                text="Bring your own domains to Northflank and link them to your services on a per-port basis in just a few clicks."
              />
              <FeatureWithIcon
                Icon={Health}
                iconGradient
                heading="Wildcard Domains and Certificates"
                text="Northflank supports wildcard domains and certificates to enable rapid adoption of a large number of domains and endpoints."
              />
              <FeatureWithIcon
                Icon={Shield}
                iconGradient
                heading="Bring Your Own Certs"
                text="Custom wildcard certificates can be used to enable connectivity to 3rd party proxies and CDNs."
              />
              <FeatureWithIcon
                Icon={NetworkChart}
                iconGradient
                heading="Automatic mTLS"
                text="Secure service-to-service communication without the need for any code changes — traffic is encrypted in transit within a Northflank project namespace."
              />
              <FeatureWithIcon
                Icon={CheckShield}
                iconGradient
                heading="Network Security"
                text="Set up basic HTTP authentication and add IP or CIDR block allow/deny rules on a per-port basis to control unwanted access to your publicly exposed services."
              />
              <FeatureWithIcon
                Icon={MapPin}
                iconGradient
                heading="Egress Gateway"
                text="Route traffic via an egress gateway with a static IP address. Outbound traffic from your services and jobs will route via this fixed IP address."
              />
            </>
          }
        />
        <FeatureGroup
          heading="Variables and Secrets"
          wideImage="/images/feature/details/run/secrets.png"
          wideImageAlt="Screenshot of Northflank secrets list"
          items={
            <>
              <FeatureWithIcon
                Icon={LockAlt}
                iconGradient
                heading="Environment Variables"
                text="Make environment variables available at runtime. Variables are stored and injected securely, and can be set on an individual service or project-wide level."
              />
              <FeatureWithIcon
                Icon={Outline}
                iconGradient
                heading="Access Database Credentials"
                text="Give your services automatic access to your database credentials by linking your database using a secret group."
              />
              <FeatureWithIcon
                Icon={File}
                iconGradient
                heading="Secret Files"
                text="Make secrets available as files to your workloads securely. Secret templating allows you to dynamically inherit other available variables in a single file. Loading configuration or authentication files becomes simple."
              />
            </>
          }
        />
      </FeaturesWrapper>
    </>
  );
};

export default Run;
