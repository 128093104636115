import React from 'react';
import { Link } from 'react-router-dom';

import css from '@styled-system/css';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { layout, space } from 'styled-system';

import Box from '@northflank/components/Box';
import Icon from '@northflank/components/Icon';
import Image from '@northflank/components/Image';
import Text from '@northflank/components/Text';
import Tooltip from '@northflank/components/Tooltip';
import variant from '@northflank/utilities/styled-system/variant';
import withComponentId from '@northflank/utilities/withComponentId';

const SelectBoxStyled = styled.button(
  layout,
  space,
  ({ bg, hoverBg, alignItems }) =>
    css({
      borderWidth: '1px',
      borderColor: 'grey6',
      borderStyle: 'solid',
      cursor: 'pointer',
      display: 'flex',
      flexGrow: 1,
      width: '100%',
      alignItems: alignItems || 'flex-start',
      borderRadius: 2,
      padding: 8,
      bg: bg || 'grey10',
      textAlign: 'left',
      appearance: 'none',
      '&:hover, &:focus': {
        bg: hoverBg || 'grey8',
        outline: 0,
      },
    }),
  () =>
    variant({
      variants: {
        selected: {
          borderColor: 'primary',
          bg: 'primaryOpacity9',
          '&:hover, &:focus': { bg: 'primaryOpacity9' },
        },
        disabled: {
          opacity: 0.5,
          borderColor: 'grey6',
          cursor: 'not-allowed',
          '&:hover': {
            bg: 'grey10',
          },
        },
        small: {
          px: 7,
          py: 6,
        },
      },
    })
);

const StyledLink = styled(Link)(
  () => layout,
  css({
    display: 'flex',
    flexGrow: 1,
  })
);

const SelectBox = ({ to, disabled, children, height, onClick, ...rest }) =>
  to && !disabled && !onClick ? (
    <StyledLink height={height} to={to}>
      <SelectBoxStyled height={height} as="div" {...rest}>
        {children}
      </SelectBoxStyled>
    </StyledLink>
  ) : (
    <SelectBoxStyled {...rest} disabled={disabled} onClick={onClick}>
      {children}
    </SelectBoxStyled>
  );

const WrapTooltip = ({ tooltip, children }) =>
  tooltip ? <Tooltip title={tooltip}>{children}</Tooltip> : children;

const SelectCard = ({
  uid,
  title,
  label,
  description,
  disabled = false,
  icon,
  iconColor,
  selected = false,
  handleSelect,
  highlightIcon = false,
  children = null,
  small = false,
  to,
  image,
  color,
  inline,
  bg,
  hoverBg,
  tooltip,
  imageSize,
  metaLabel,
  ...rest
}) => (
  <WrapTooltip tooltip={tooltip}>
    <SelectBox
      id={uid && `card-${uid}`}
      onClick={!disabled && handleSelect}
      variant={[
        disabled && 'disabled',
        selected && 'selected',
        small && 'small',
      ]}
      to={to}
      disabled={disabled}
      type="button"
      bg={bg}
      hoverBg={hoverBg}
      {...rest}
    >
      {icon && (
        <Icon
          Content={icon}
          mr={6}
          color={
            iconColor || (selected && highlightIcon && 'primary') || 'grey2'
          }
        />
      )}
      {image && (
        <Image
          src={image.location}
          alt={image.alt}
          width={imageSize || '16px'}
          mr={6}
        />
      )}
      {color && (
        <Box
          bg={color}
          minWidth="12px"
          minHeight="12px"
          borderRadius="50%"
          boxShadow="0 0 6px 0 rgba(0, 0, 0, 0.2)"
          mr={6}
          mt={1}
        />
      )}
      <Box variant="flex" flexDirection={inline ? 'row' : 'column'}>
        <Box
          variant="flex"
          alignItems="center"
          mb={description && (inline ? 0 : 6)}
          mr={description && (inline ? 6 : 0)}
        >
          {title && (
            <Text color={selected ? 'grey1' : 'grey2'} fontSize={2}>
              {label ? `${title} (${label})` : title}
            </Text>
          )}
          {metaLabel && (
            <>
              <Icon
                Content={metaLabel.icon}
                Size={14}
                color={metaLabel.color ?? 'grey3'}
                ml={6}
                mr={2}
              />
              <Text fontSize={1} color={metaLabel.color ?? 'grey3'}>
                {metaLabel.label}
              </Text>
            </>
          )}
        </Box>
        {description && (
          <Text color="grey3" fontSize={2} style={{ whiteSpace: 'normal' }}>
            {description}
          </Text>
        )}
      </Box>
      {children}
    </SelectBox>
  </WrapTooltip>
);

SelectCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.node.isRequired,
  selected: PropTypes.bool,
  handleSelect: PropTypes.func,
  highlightIcon: PropTypes.bool,
  children: PropTypes.node,
  small: PropTypes.bool,
  iconColor: PropTypes.string,
  to: PropTypes.string,
  image: PropTypes.string,
  color: PropTypes.string,
  tooltip: PropTypes.string,
};

export default withComponentId(SelectCard);
