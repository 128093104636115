import React from 'react';

import { Alarm } from '@styled-icons/boxicons-regular/Alarm';
import { Bell } from '@styled-icons/boxicons-regular/Bell';
import { Calendar } from '@styled-icons/boxicons-regular/Calendar';
import { CheckCircle } from '@styled-icons/boxicons-regular/CheckCircle';
import { CodeAlt } from '@styled-icons/boxicons-regular/CodeAlt';
import { HeartCircle } from '@styled-icons/boxicons-regular/HeartCircle';
import { LineChart } from '@styled-icons/boxicons-regular/LineChart';
import { PaperPlane } from '@styled-icons/boxicons-regular/PaperPlane';
import { PlayCircle } from '@styled-icons/boxicons-regular/PlayCircle';
import { Poll } from '@styled-icons/boxicons-regular/Poll';
import { Pulse } from '@styled-icons/boxicons-regular/Pulse';
import { UserVoice } from '@styled-icons/boxicons-regular/UserVoice';

import Box from '@northflank/components/Box';
import Heading from '@northflank/components/Heading';
import FeatureNav from '@northflank/components/marketing/FeatureNav';
import FeatureWithIcon from '@northflank/components/marketing/FeatureWithIcon';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';

import {
  FeatureGroup,
  FeaturesWrapper,
} from '../../components/FeaturePageWrapper';
import PreHeading from '../../components/PreHeading';

const Observe = () => {
  return (
    <>
      <SEO
        title="Observe — Features"
        description="Northflank provides out-of-the-box tooling to help you stay on top of your logs, container metrics and important infrastructure & deployment alerts."
      />
      <FeatureNav />
      <FeaturesWrapper>
        <Box variant="bounding" pb="70px!important">
          <PreHeading mb={6}>Feature breakdown</PreHeading>
          <Heading as="h1" color="grey1" fontSize={[6, 6, 7, 7]} mb={8}>
            Observability on Northflank
          </Heading>
          <Text color="grey3" fontSize={4} width={[1, 1, 1 / 2]} mediaQuery>
            Northflank provides out-of-the-box tooling to help you stay on top
            of your logs and container metrics. Prefer to ship your logs
            elsewhere for aggregation and processing? We support that too.
          </Text>
        </Box>
        <FeatureGroup
          heading="Observability"
          wideImage="/images/feature/details/observe/observe.png"
          wideImageAlt="Screenshot of Northflank service logs view"
          items={
            <>
              <FeatureWithIcon
                Icon={Poll}
                iconGradient
                heading="Real-Time Log Tailing"
                text="Debug your builds, deployments, jobs, addons, backups, and restores in real-time by watching logs come in as they happen."
              />
              <FeatureWithIcon
                Icon={LineChart}
                iconGradient
                heading="Real-Time Metrics"
                text="Keep track of how your builds and deployments are performing with detailed metrics such as CPU & memory usage, number of requests etc."
              />
              <FeatureWithIcon
                Icon={Calendar}
                iconGradient
                heading="60 Day Retention"
                text="Logs and metrics are stored for 60 days so you can go back and fetch past data."
              />
              <FeatureWithIcon
                Icon={PaperPlane}
                iconGradient
                heading="Log Sinks"
                text="Log sinks allow you to forward logs from containers to your preferred log aggregator or observability platform. Forward specific projects or your whole account at once."
              />
              <FeatureWithIcon
                Icon={CodeAlt}
                iconGradient
                heading="Logs and Metrics API"
                text="Integrate your logs and metrics into your existing systems using our HTTP and WebSocket API."
              />
            </>
          }
        />
        <FeatureGroup
          heading="Alerting"
          wideImage="/images/feature/details/observe/alerts.png"
          wideImageAlt="Screenshot of Northflank alert condition configuration"
          items={
            <>
              <FeatureWithIcon
                Icon={UserVoice}
                iconGradient
                heading="Notification Integrations"
                text="Send notifications to Slack, Discord, Webhooks, and Teams to stay up to date and integrate with your existing alerting flow."
              />
              <FeatureWithIcon
                Icon={Bell}
                iconGradient
                heading="Platform Alerts"
                text="Use platform alerts to receive notifications when builds, backups, job runs, billing, or autoscaling events occur on the platform."
              />
              <FeatureWithIcon
                Icon={Alarm}
                iconGradient
                heading="Infrastructure Alerts"
                text="Use infrastructure alerts to receive notifications when resource usage spikes, or your apps and databases are crashing."
              />
            </>
          }
        />
        <FeatureGroup
          heading="Health Checks"
          wideImage="/images/feature/details/observe/healthcheck.png"
          wideImageAlt="Screenshot of Northflank health check configuration"
          items={
            <>
              <FeatureWithIcon
                Icon={Pulse}
                iconGradient
                heading="Health Checks"
                text="Configure health checks to ensure maximum availability of your services."
              />
              <FeatureWithIcon
                Icon={PlayCircle}
                iconGradient
                heading="Readiness"
                text="Test whether a container is able to receive traffic after it has initialised. Traffic will only be routed to healthy containers."
              />
              <FeatureWithIcon
                Icon={HeartCircle}
                iconGradient
                heading="Liveness"
                text="Test a given endpoint for a service to make sure it’s available. If the check fails, the container will be terminated and a new container will be started."
              />
              <FeatureWithIcon
                Icon={CheckCircle}
                iconGradient
                heading="Startup"
                text="Any configured liveness and readiness probes will not be called until the startup checks have succeeded."
              />
            </>
          }
        />
      </FeaturesWrapper>
    </>
  );
};

export default Observe;
