import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { AddToQueue } from '@styled-icons/boxicons-regular/AddToQueue';
import { Check } from '@styled-icons/boxicons-regular/Check';
import { ChevronLeft } from '@styled-icons/boxicons-regular/ChevronLeft';
import { ChevronRight } from '@styled-icons/boxicons-regular/ChevronRight';
import { Cloud } from '@styled-icons/boxicons-regular/Cloud';
import { CloudUpload } from '@styled-icons/boxicons-regular/CloudUpload';
import { CodeAlt } from '@styled-icons/boxicons-regular/CodeAlt';
import { Data } from '@styled-icons/boxicons-regular/Data';
import { Detail } from '@styled-icons/boxicons-regular/Detail';
import { GitCommit } from '@styled-icons/boxicons-regular/GitCommit';
import { Globe } from '@styled-icons/boxicons-regular/Globe';
import { Hive } from '@styled-icons/boxicons-regular/Hive';
import { Link as LinkIcon } from '@styled-icons/boxicons-regular/Link';
import { LockAlt } from '@styled-icons/boxicons-regular/LockAlt';
import { NetworkChart } from '@styled-icons/boxicons-regular/NetworkChart';
import { PlayCircle } from '@styled-icons/boxicons-regular/PlayCircle';
import { Radar } from '@styled-icons/boxicons-regular/Radar';
import { Run } from '@styled-icons/boxicons-regular/Run';
import { Terminal } from '@styled-icons/boxicons-regular/Terminal';
import { Time } from '@styled-icons/boxicons-regular/Time';
import { Windows } from '@styled-icons/boxicons-regular/Windows';
import { Wrench } from '@styled-icons/boxicons-regular/Wrench';
import { Docker } from '@styled-icons/simple-icons/Docker';
import { transparentize } from 'polished';
import { ThemeContext } from 'styled-components';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Grid from '@northflank/components/Grid';
import Heading from '@northflank/components/Heading';
import Icon from '@northflank/components/Icon';
import {
  FeatureMasonry,
  MasonryCard,
} from '@northflank/components/marketing/FeatureMasonry';
import FeatureSwitcher from '@northflank/components/marketing/FeatureSwitcher';
import FeatureTabs from '@northflank/components/marketing/FeatureTabs';
import { HiddenText, LinkOrAnchor } from '@northflank/components/marketing/Nav';
import PerspectiveBox from '@northflank/components/marketing/PerspectiveBox';
import PrimaryFeatureBlock from '@northflank/components/marketing/PrimaryFeatureBlock';
import SEO from '@northflank/components/marketing/SEO';
import { TestimonialHeader } from '@northflank/components/marketing/Testimonial';
import Text from '@northflank/components/Text';

import CloudProviderCard from '../components/CloudProviders/CloudProviderCard';
import { cloudProviderOptions } from '../components/CloudProviders/utility/cloudProviderOptions';
import FancyQuote from '../components/FancyQuote';
import FlexibilityImage from '../components/FlexibilityImage';
import FlowImage from '../components/FlowImage';
import GetStartedCta from '../components/GetStartedCta';
import graphpaper from '../components/GraphPaper';
import HeroHeading from '../components/HeroHeading';
import { logos } from '../components/LogoCarousel';
import PreHeading from '../components/PreHeading';
import texture from '../components/Texture';

const noiseSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="200" height="200">
  <filter id="noise" x="0" y="0">
    <feTurbulence
      type="fractalNoise"
      baseFrequency="0.65"
      numOctaves="3"
      stitchTiles="stitch"
    />
  </filter>
  <rect width="100%" height="100%" filter="url(#noise)" opacity="0.3"/>
</svg>`;

// const integrationIcons = [
//   {
//     title: 'GitHub',
//     icon: Github,
//   },
//   {
//     title: 'GitLab',
//     icon: Gitlab,
//   },
//   {
//     title: 'Bitbucket',
//     icon: Bitbucket,
//     iconSize: 24,
//   },
//   {
//     title: 'Docker Registries',
//     icon: Docker,
//   },
//   {
//     title: 'Northflank Managed Cloud',
//     iconOverride: (
//       <Box
//         height="28px"
//         _css={{ svg: { fill: 'url(#gradient_svg__greySvgGradient)' } }}
//       >
//         <NorthflankIcon width="28px" />
//         <HiddenText>Northflank Managed Cloud</HiddenText>
//       </Box>
//     ),
//   },
//   {
//     title: 'Google Cloud Platform',
//     icon: GoogleCloud,
//   },
//   {
//     title: 'Amazon Web Services',
//     icon: Amazonaws,
//   },
//   {
//     title: 'Microsoft Azure',
//     icon: Microsoftazure,
//     iconSize: 24,
//   },
//   {
//     title: 'Your Kubernetes Cluster',
//     icon: Kubernetes,
//     iconSize: 26,
//   },
// ];

const getInterfaceImageProps = (key) => {
  switch (key) {
    case 'ui':
      return {
        bg: '#192338',
      };

    case 'gitops':
      return {
        bg: 'black',
      };
    case 'api':
    case 'cli':
      return {
        bg: '#0f1521',
      };

    case 'templates':
      return {
        bg: '#161e2e',
      };
    default:
      return {};
  }
};

const heroTabs = [
  {
    icon: Wrench,
    value: 'build',
    label: 'Build',
    title: (
      <>
        <Text as="span" color="success">
          Build
        </Text>{' '}
        Any Environment
      </>
    ),
    body: (
      <ul>
        <li>
          Easily build and deploy preview, staging, and production environments
        </li>
        <li>
          Connect and build Git repositories using Dockerfiles and Buildpacks
        </li>
        <li>Set up continuous integration, caching, and multi-step builds</li>
      </ul>
    ),
    cta: {
      text: 'Learn more',
      link: '/features/build',
    },
    video: 'https://assets.northflank.com/video1_0654550613.mp4',
  },
  {
    icon: PlayCircle,
    value: 'run',
    label: 'Run',
    title: (
      <>
        <Text as="span" color="success">
          Run
        </Text>{' '}
        Everything from One Platform
      </>
    ),
    body: (
      <ul>
        <li>Deploy apps, databases, queues, and cron jobs from one platform</li>
        <li>Accelerate continuous deployments via real-time UI and GitOps</li>
        <li>
          Run secure ephemeral, stateful, and scheduled containers in seconds
        </li>
      </ul>
    ),
    cta: {
      text: 'Learn more',
      link: '/features/run',
    },
    video: 'https://assets.northflank.com/run_template2_fbd776831b.mp4',
  },
  {
    icon: CloudUpload,
    value: 'release',
    label: 'Release',
    title: (
      <>
        <Text as="span" color="success">
          Release
        </Text>{' '}
        Automatically
      </>
    ),
    body: (
      <ul>
        <li>
          Release confidently with zero-downtime deploys, built-in rollbacks,
          and restores
        </li>
        <li>
          Reduce repetitive tasks by templating workflows, resources, and
          projects
        </li>
        <li>
          Configure automated release flows for preview, staging, and production
        </li>
      </ul>
    ),
    cta: {
      text: 'Learn more',
      link: '/features/release',
    },
    video:
      'https://assets.northflank.com/northflank_template_with_pipeline_and_releaseflow_89037b8914.mp4',
  },
  {
    icon: AddToQueue,
    value: 'scale',
    label: 'Scale',
    title: (
      <>
        <Text as="span" color="success">
          Scale
        </Text>{' '}
        On Demand
      </>
    ),
    body: (
      <ul>
        <li>
          Easily scale your resources with vertical scaling, horizontal scaling,
          and auto-scaling
        </li>
        <li>
          Manage teams of any size with advanced RBAC, FinOps, and real-time
          collaboration
        </li>
        <li>
          Scale from one to hundreds of highly available services or databases
          in one unified interface
        </li>
      </ul>
    ),
    cta: {
      text: 'Learn more',
      link: '/features/scale',
    },
    video:
      'https://assets.northflank.com/marketing_tabs_scaling_V2_c8ecf725b1.mp4',
  },
  {
    icon: Radar,
    value: 'observe',
    label: 'Observe',
    title: (
      <>
        <Text as="span" color="success">
          Observe
        </Text>{' '}
        in Real-time
      </>
    ),
    body: (
      <ul>
        <li>
          Monitor your deployments with built-in healthchecks, metrics, and
          alerts
        </li>
        <li>Accelerate debugging with real-time log tailing and dashboards</li>
        <li>Seamlessly integrate with external observability platforms</li>
      </ul>
    ),
    cta: {
      text: 'Learn more',
      link: '/features/observe',
    },
    video:
      'https://assets.northflank.com/marketing_tabs_observe_V1_32a31cef34.mp4',
  },
  {
    icon: Cloud,
    value: 'cloud',
    label: 'Any Cloud',
    title: (
      <>
        Choose Your{' '}
        <Text as="span" color="success">
          Cloud
        </Text>
      </>
    ),
    body: (
      <ul>
        <li>
          Deploy to your preferred cloud provider or Kubernetes environment
        </li>
        <li>
          Use Northflank’s fully managed cloud with zero infrastructure
          management
        </li>
        <li>
          Or, bring your own cloud for advanced control, flexibility, and data
          security
        </li>
      </ul>
    ),
    cta: {
      text: 'Learn more',
      link: '/features/bring-your-own-cloud',
    },
    video:
      'https://assets.northflank.com/marketing_tabs_cloud_V2_93c075cfb0.mp4',
  },
  {
    icon: PlayCircle,
    value: 'demo',
    label: 'Product Demo',
    title: (
      <>
        Watch{' '}
        <Text as="span" color="success">
          Demo
        </Text>
      </>
    ),
    body: (
      <>
        <Box
          display="flex"
          alignItems="center"
          bg={transparentize(0.5, '#192338')}
          border="1px solid"
          borderColor="grey5"
          borderRadius={2}
          px={8}
          py={7}
          mb={8}
        >
          <Box
            as="img"
            src="/images/headshot/Will.jpg"
            width="44px"
            borderRadius={5}
            mr={6}
          />
          <Box>
            <Text>by Will Stewart</Text>
            <Text color="grey3">CEO & Co-founder</Text>
          </Box>
        </Box>
        <Text mb={5}>
          Watch as Will talks through building and deploying a project on
          Northflank in a matter of minutes.
        </Text>
        <Text>Prefer to chat and see a demo live?</Text>
      </>
    ),
    cta: {
      text: 'Try it for yourself',
      link: 'https://app.northflank.com/signup',
      external: true,
    },
    video:
      'https://assets.northflank.com/northflank+demo+video+full+with+pauses+final.mp4',
  },
];

const dxTabs = [
  {
    icon: Windows,
    value: 'ui',
    label: 'UI',
    caption: 'A fast, real-time cloud console that keeps you up to date.',
    captionIcon: NetworkChart,
  },
  {
    icon: CodeAlt,
    value: 'api',
    label: 'API',
    caption:
      'Create resources programmatically and integrate into your existing systems with a powerful API.',
    captionIcon: NetworkChart,
  },
  {
    icon: Terminal,
    value: 'cli',
    label: 'CLI',
    caption:
      'Securely connect to private databases and services on your machine.',
    captionIcon: NetworkChart,
  },
  {
    icon: Hive,
    value: 'templates',
    label: 'Templates',
    caption:
      'Bundle resources together in a template for repeatability and easy sharing.',
    captionIcon: NetworkChart,
  },
  {
    icon: GitCommit,
    value: 'gitops',
    label: 'GitOps',
    caption:
      'Update resource definitions directly from your Git repository to codify your workflow.',
    captionIcon: NetworkChart,
  },
];

const platformComponents = [
  { text: 'gVisor', tooltip: 'Sandbox workloads with gVisor & Northflank.' },
  {
    text: 'Kata Containers',
    tooltip: 'Deliver a secure runtime with Kata Containers & Northflank.',
  },
  {
    text: 'Cloud Hypervisor',
    tooltip:
      'Orchestrate high performance and feature-rich secure workloads with CLH & Northflank.',
  },
  {
    text: 'Firecracker',
    tooltip: 'Spin up microVMs with Firecracker & Northflank.',
  },
  { text: 'Istio', tooltip: 'Enable service mesh with Istio & Northflank.' },
  {
    text: 'Linkerd',
    tooltip: 'Operate a lightweight service mesh with Linkerd & Northflank.',
  },
  {
    text: 'Network policy',
    tooltip:
      'Secure your network and leverage egress gateways with Cilium & Northflank.',
  },
  {
    text: 'Build platform',
    tooltip:
      'Offer an advanced build platform with Kaniko, Buildkit, Buildpacks, & Northflank.',
  },
  {
    text: 'BYO Cloud, Logs, DNS, Secrets',
    tooltip:
      'Keep secrets, logs, and DNS within your own cloud boundary for maximum control.',
  },
  {
    text: 'VCS Provider Integration',
    tooltip:
      'Integrate with GitHub, GitLab, and Bitbucket for self-service build with Northflank.',
  },
];

const PlatformComponents = () => {
  const [active, setActive] = useState();
  const [show, setShow] = useState(false);
  const timeout = useRef();
  return (
    <Box position="relative">
      <Text
        fontFamily="monospace"
        fontSize={0}
        fontWeight="bold"
        color="grey3"
        textTransform="uppercase"
        mb={6}
      >
        Cutting edge components at your fingertips
      </Text>
      <Box
        variant="flex"
        alignItems="center"
        justifyContent="center"
        flexWrap="wrap"
        mx={-3}
        my={-4}
      >
        {platformComponents.map(({ text, tooltip }) => (
          <Box
            key={`text:${text}`}
            onMouseEnter={() => {
              if (timeout.current) clearTimeout(timeout.current);
              setActive(tooltip);
              setShow(true);
            }}
            onMouseLeave={() => {
              timeout.current = setTimeout(() => {
                setShow(false);
                timeout.current = setTimeout(() => {
                  setActive(undefined);
                }, 250);
              }, 75);
            }}
            bg="grey10"
            border="1px solid"
            borderColor="grey5"
            borderRadius="20px"
            mx={3}
            my={4}
            zIndex={2}
            _css={{
              transition: '200ms',
              cursor: 'default',
              '&:hover': {
                bg: 'grey6',
                boxShadow: 'dropDown',
                transform: 'translateY(-2px)',
              },
            }}
          >
            <Box px={8} py={6}>
              {text}
            </Box>
            <HiddenText>{tooltip}</HiddenText>
          </Box>
        ))}
      </Box>
      <Text
        position="absolute"
        top="calc(100% + 4px)"
        left="50%"
        maxWidth="80vw"
        width={['100%', '100%', 'max-content']}
        bg="grey11"
        color="grey1"
        fontWeight={500}
        border="1px solid"
        borderColor="grey5"
        borderRadius={2}
        boxShadow="dropDown"
        opacity={show ? 1 : 0}
        px={6}
        py={3}
        zIndex={2}
        css={{
          transform: `translateX(-50%) translateY(${show ? '0px' : '-8px'})`,
          transition: '200ms',
        }}
      >
        {active}
      </Text>
    </Box>
  );
};

const BulletedList = ({ items = [] }) => (
  <Box as="ul" _css={{ listStyle: 'none', 'li + li': { mt: 6 } }}>
    {items.map((item) => (
      <Box
        key={`bl-${item}`}
        as="li"
        variant="flex"
        alignItems="flex-start"
        ml="-3px"
      >
        <Box minWidth="22px" mt="-1px" mr={5}>
          <Icon Content={Check} Size={22} color="success" />
        </Box>
        <Text fontSize={3}>{item}</Text>
      </Box>
    ))}
  </Box>
);

// better modulo - properly handle negative `n`
function mod(n, m) {
  return ((n % m) + m) % m;
}

const Index = () => {
  const [heroActiveTab, setHeroActiveTab] = useState('build');
  const [activeTab, setActiveTab] = useState('ui');

  const theme = useContext(ThemeContext);

  const videoRef = useRef();

  useEffect(() => {
    if (window.location.hash) {
      const content = window.location.hash.replace('#', '');
      if (heroTabs.find((t) => t.value === content)) setHeroActiveTab(content);
    }
  }, []);

  const bgElements = [
    `radial-gradient(80% 40% at 25% 40%, rgba(0, 147, 255, 0.25) 0%, transparent 100%)`,
    `radial-gradient(50% 40% at 90% 60%, rgba(45, 216, 129, 0.15) 0%, #151C28 100%)`,
  ];

  const heroTabContent = heroTabs.find((t) => t.value === heroActiveTab);

  return (
    <>
      <SEO />
      {/* preload video thumbnails so they are ready before tabs changed */}
      {heroTabs.map((tab) => (
        <link
          key={`tabValue:${tab.value}`}
          rel="preload"
          href={`https://assets.northflank.com/marketing/image/thumbnails/${tab.value}-thumb.png`}
          as="image"
        />
      ))}
      <Box
        position="relative"
        pt={['64px', '64px', '80px']}
        mt={['-64px', '-64px', '-80px']}
        zIndex={0}
        overflow="hidden"
      >
        <Box
          background={`url("data:image/svg+xml,${encodeURIComponent(noiseSvg)}")`}
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          zIndex={-1}
          _css={{ filter: 'invert(1) brightness(0)' }}
        />
        <Box
          background={graphpaper}
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          zIndex={-2}
        />
        <Box
          display={['none', 'none', 'block']}
          background={bgElements.join(',')}
          position="absolute"
          top="-20%"
          left="-30%"
          width="160%"
          height="140%"
          zIndex={-3}
          _css={{ transform: 'rotate(15deg)' }}
        />
        <Box
          variant="bounding"
          position="relative"
          zIndex={0}
          pt={[12, 12, '60px', '60px', '60px', '96px !important']}
        >
          <Box textAlign="center">
            <HeroHeading
              as="h1"
              fontSize={[7, 7, 7, '54px']}
              color="grey1"
              mb={[8, 8, 8, 8, 8, 10]}
            >
              Automate Deployments.
              <br />
              Any Workload, Any Cloud.
            </HeroHeading>
            <Text
              fontSize={[4, 4, 4, 5, 5, '29px']}
              color="grey2"
              maxWidth={['680px', '680px', '680px', '810px', '810px', '1000px']}
              mx="auto"
              mb={12}
            >
              The self-service developer platform for your{' '}
              <Text as="span" color="grey2" fontWeight={600}>
                apps, databases, and jobs
              </Text>
              . Start with one workload, scale to hundreds on compute or GPUs.
            </Text>
            <Box
              display="flex"
              flexDirection={['row', 'row', 'row']}
              justifyContent="center"
              mb={14}
            >
              <a href="https://app.northflank.com/signup">
                <Button
                  id="hero-get-started"
                  variant={['primary', 'large']}
                  mr={6}
                >
                  Get started for free
                </Button>
              </a>
              <a
                href="https://cal.com/team/northflank/northflank-demo?duration=30"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button id="hero-schedule-demo" variant={['large']}>
                  Book a live demo
                </Button>
              </a>
            </Box>
            <Box
              borderRadius={3}
              boxShadow="dropDown"
              border="1px solid"
              borderColor="grey5"
            >
              <Box
                bg="grey8"
                borderBottom="1px solid"
                borderBottomColor="grey5"
                borderTopLeftRadius={3}
                borderTopRightRadius={3}
                py={3}
              >
                <Box
                  variant="flex"
                  alignItems="center"
                  justifyContent={[
                    'space-between',
                    'space-between',
                    'space-between',
                    'center',
                  ]}
                  px={7}
                >
                  <Button
                    variant={['icon', 'noBorder', 'noBackground']}
                    display={['flex', 'flex', 'flex', 'none']}
                    onClick={() => {
                      const index = heroTabs.findIndex(
                        (t) => t.value === heroActiveTab
                      );
                      setHeroActiveTab(
                        Object.values(heroTabs)[
                          mod(index - 1, Object.values(heroTabs).length)
                        ].value
                      );
                    }}
                  >
                    <ChevronLeft size={32} />
                  </Button>
                  <FeatureTabs
                    tabs={heroTabs}
                    activeTab={heroActiveTab}
                    setActiveTab={(t) => {
                      if (videoRef.current) videoRef.current.pause();
                      setHeroActiveTab(t);
                    }}
                    bg="grey8"
                    mx={6}
                    scrollOnChange
                  />
                  <Button
                    variant={['icon', 'noBorder', 'noBackground']}
                    display={['flex', 'flex', 'flex', 'none']}
                    onClick={() => {
                      const index = heroTabs.findIndex(
                        (t) => t.value === heroActiveTab
                      );
                      setHeroActiveTab(
                        Object.values(heroTabs)[
                          mod(index + 1, Object.values(heroTabs).length)
                        ].value
                      );
                    }}
                  >
                    <ChevronRight size={32} />
                  </Button>
                </Box>
              </Box>
              <Box
                display="grid"
                gridTemplateColumns={['1fr', '1fr', '1fr', '1fr 3fr']}
                gridGap={10}
                alignItems="start"
                background={`radial-gradient(circle at 10% 15%, ${transparentize(
                  0.9,
                  theme.colors.success
                )} 0%, transparent 35%), ${theme.colors.grey9}`}
                width="100%"
                borderBottomLeftRadius={3}
                borderBottomRightRadius={3}
                p={10}
              >
                <Box
                  height="100%"
                  variant="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  textAlign="left"
                >
                  <Box>
                    <Text fontSize={5} fontWeight={600} mb={8}>
                      {heroTabContent.title}
                    </Text>
                    <Box
                      fontSize={3}
                      color="grey2"
                      mb={11}
                      _css={{
                        ul: {
                          listStyle: 'none',
                          pl: '22px',
                          li: {
                            position: 'relative',
                            mb: 4,
                            '&::before, &::after': {
                              content: `""`,
                              display: 'block',
                              width: '20px',
                              height: '20px',
                              position: 'absolute',
                              left: '-26px',
                              top: '2px',
                            },
                            '&::before': {
                              backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' style='fill: %232DD881' %3E%3Cpath d='M10.296 7.71 14.621 12l-4.325 4.29 1.408 1.42L17.461 12l-5.757-5.71z'%3E%3C/path%3E%3Cpath d='M6.704 6.29 5.296 7.71 9.621 12l-4.325 4.29 1.408 1.42L12.461 12z'%3E%3C/path%3E%3C/svg%3E")`,
                            },
                            '&::after': {
                              bg: 'success',
                              borderRadius: 5,
                              opacity: 0.25,
                              filter: 'blur(16px)',
                            },
                          },
                        },
                      }}
                    >
                      {heroTabContent.body}
                      {heroTabContent.value === 'demo' && (
                        <Text
                          as="a"
                          href="https://cal.com/team/northflank/northflank-demo?duration=30"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => {
                            if (videoRef.current) videoRef.current.pause();
                          }}
                          display="inline-block"
                          mt={2}
                        >
                          Find a time &rarr;
                        </Text>
                      )}
                    </Box>
                  </Box>
                  <LinkOrAnchor
                    href={heroTabContent.cta.link}
                    external={heroTabContent.cta.external}
                    onClick={() => {
                      if (videoRef.current) videoRef.current.pause();
                    }}
                  >
                    <Button variant={['primary', 'large']} width="100%">
                      {heroTabContent.cta.text}
                    </Button>
                  </LinkOrAnchor>
                </Box>
                <Box
                  variant="flex"
                  alignItems="center"
                  justifyContent="center"
                  backgroundImage={`url("https://assets.northflank.com/marketing/image/thumbnails/${heroTabContent.value}-thumb.png"), linear-gradient(45deg, #1D273F 0%, #202E50 100%)`}
                  backgroundSize="100% auto"
                  backgroundPosition="center"
                  backgroundRepeat="no-repeat"
                  border="1px solid"
                  borderColor="grey5"
                  borderRadius={3}
                  boxShadow="dropDown"
                  overflow="hidden"
                  width="100%"
                  _css={{ aspectRatio: '16 / 9.8' }}
                >
                  <Box
                    key={heroTabContent.value}
                    ref={videoRef}
                    as="video"
                    preload="metadata"
                    muted={heroTabContent.value !== 'demo'}
                    autoPlay={heroTabContent.value === heroActiveTab}
                    playsInline
                    loop={heroTabContent.value !== 'demo'}
                    controls={heroTabContent.value === 'demo'}
                    width="100%"
                  >
                    <source src={heroTabContent.video} type="video/mp4" />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mt={[13, 13, '96px', '96px', '96px', '120px']}>
            <Text color="grey3" fontSize={4} textAlign="center" mb={10}>
              Trusted in production by tens of thousands of developers
            </Text>
            <Box
              gridGap={8}
              display="flex"
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
              borderRadius={3}
            >
              {logos.map((logo) => (
                <Box
                  key={logo.key}
                  as="a"
                  href={logo.link}
                  target="_blank"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width={['150px', '150px', '150px', '185px']}
                  height="100px"
                  _css={{
                    img: { transition: '200ms' },
                    '&:hover': {
                      img: {
                        filter: 'brightness(1.5)',
                        transform: 'scale(1.01)',
                      },
                    },
                  }}
                >
                  <Box
                    as="img"
                    src={`/images/landing/logos/${logo.key}.svg`}
                    alt={`${logo.key} logo`}
                    maxWidth={['120px', '120px', '120px', '150px']}
                    maxHeight={['60px', '60px', '60px', '70px']}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box borderTop="1px solid" borderColor="grey6">
        <Box maxWidth="1920px" mx="auto">
          <Box variant="bounding" maxWidth="1500px !important">
            <Heading
              fontSize={[5, 5, '32px']}
              color="grey1"
              textAlign="center"
              mb={[12, 12, 13]}
            >
              The{' '}
              <HeroHeading
                as="span"
                fontSize={[5, 5, '32px']}
                fontWeight="600 !important"
                letterSpacing="normal"
              >
                Northflank
              </HeroHeading>{' '}
              Difference
            </Heading>
            <PrimaryFeatureBlock
              subFeatures={[
                {
                  Icon: LinkIcon,
                  heading: 'Unify and Accelerate Deployments',
                  text: (
                    <BulletedList
                      items={[
                        'Seconds to onboard, minutes to deploy',
                        'Unified deployment of apps, databases & jobs',
                        'Real-time, self-service experience',
                        'Secure multi-player collaboration',
                      ]}
                    />
                  ),
                },
                {
                  Icon: Time,
                  heading: 'Simplify Developer Experience',
                  text: (
                    <BulletedList
                      items={[
                        'Choice of UI, CLI, APIs & GitOps',
                        'Any cloud – Managed cloud, AWS, GCP & Azure',
                        'Re-usable templates for every stage',
                        'Configurable automated release flows',
                      ]}
                    />
                  ),
                },
                {
                  Icon: Cloud,
                  heading: 'Orchestrate Any App or Workload',
                  text: (
                    <BulletedList
                      items={[
                        'Run compute workloads or GPU & fractional GPU workloads',
                        'Kubernetes-ready app platform',
                        'Run anywhere, on any Kubernetes cluster, on any cloud',
                        'Ship your IDP with Northflank',
                      ]}
                    />
                  ),
                },
                {
                  Icon: AddToQueue,
                  heading: 'Lower Costs and Scale Effortlessly',
                  text: (
                    <BulletedList
                      items={[
                        'Pay-as-you-go, lower cost than alternatives',
                        'Vertical & horizontal auto-scaling',
                        'One platform vs. many disparate tools',
                        'Granular observability,automated DR',
                      ]}
                    />
                  ),
                },
              ]}
              subFeaturesGrid={[
                '1fr',
                '1fr',
                'repeat(2, 1fr)',
                'repeat(4, 1fr)',
              ]}
              subFeaturesGap={8}
              subFeaturesAlign="stretch"
              subFeatureCards
            />
          </Box>
          <Box variant="bounding" maxWidth="1500px !important">
            <Heading
              fontSize={[5, 5, '32px']}
              color="grey1"
              textAlign="center"
              mb={12}
            >
              What do{' '}
              <HeroHeading
                as="span"
                fontSize={[5, 5, '32px']}
                fontWeight="600 !important"
                letterSpacing="normal"
              >
                DevTool pioneers
              </HeroHeading>{' '}
              have to say?
            </Heading>
            <Grid
              gridTemplateColumns={['1fr', '1fr', '1fr', 'repeat(2, 1fr)']}
              mb={9}
            >
              <FancyQuote
                body={
                  <>
                    Northflank is way easier than gluing a bunch of tools
                    together to spin up apps and databases. It’s the ideal
                    platform to deploy containers in our cloud account, avoiding
                    the brain damage of big cloud and Kubernetes. It’s more
                    powerful and flexible than traditional PaaS – all within our
                    VPC.{' '}
                    <Text as="span" color="success" fontWeight={500}>
                      Northflank has become a go-to way to deploy workloads at
                      Sentry
                    </Text>
                    .
                  </>
                }
                attribution={
                  <TestimonialHeader
                    name="David Cramer"
                    position="Co-Founder and CPO @ Sentry"
                    avatar="/images/landing/quotes/david-c.jpeg"
                    linkedin="https://www.linkedin.com/in/dmcramer/"
                    mb={0}
                  />
                }
                height="100%"
                small
              />
              <FancyQuote
                body={
                  <>
                    Cycle time is everything. With Northflank, I can make 100
                    commits and 100 deployments in a single day – it keeps up
                    with my pace like nothing else. When it comes to debugging,
                    the meantime to resolution is unbeatable.{' '}
                    <Text as="span" color="success" fontWeight={500}>
                      I can identify issues and deploy fixes faster than
                      customers can even report them
                    </Text>
                    . I know about problems before they do and have them fixed
                    before they call.
                  </>
                }
                attribution={
                  <TestimonialHeader
                    name="Joshua McKenty"
                    position="CEO @ Polyguard, Former Field CTO @ Cloud Foundry, OpenStack, NASA"
                    avatar="/images/landing/quotes/joshua.jpeg"
                    linkedin="https://www.linkedin.com/in/joshuamckenty/"
                    mb={0}
                  />
                }
                height="100%"
                small
              />
            </Grid>
            <Grid gridTemplateColumns={['1fr', '1fr', '1fr', 'repeat(2, 1fr)']}>
              <FancyQuote
                body={
                  <>
                    Northflank is the first batteries-included developer
                    platform that doesn’t suffer from the invisible ceilings
                    that hover over its competitors.{' '}
                    <Text as="span" color="success" fontWeight={500}>
                      We could have built all of Slack with Northflank
                    </Text>{' '}
                    – and we would have, had it been available.
                  </>
                }
                attribution={
                  <TestimonialHeader
                    name="Keith Adams"
                    position="GP @ Pebblebed, Formerly Chief Architect @ Slack, Facebook, VMware"
                    avatar="/images/landing/quotes/keith.jpeg"
                    linkedin="https://linkedin.com/in/keith-adams-pb/"
                    mb={0}
                  />
                }
                height="100%"
                small
              />
              <FancyQuote
                body={
                  <>
                    By far the most complete product like this I’ve seen.{' '}
                    <Text as="span" color="success" fontWeight={500}>
                      This is how Kubernetes should be used
                    </Text>
                    .
                  </>
                }
                attribution={
                  <TestimonialHeader
                    name="Darren Shepherd"
                    position="CTO @ Acorn Labs, Formerly CTO @ Rancher"
                    avatar="/images/landing/quotes/darren.jpeg"
                    linkedin="https://linkedin.com/in/ibuildthecloud"
                    mb={0}
                  />
                }
                height="100%"
                small
              />
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box
        background={`radial-gradient(circle at 60% 50%, rgba(130, 130, 255, 0.075) 0%, transparent 15%), linear-gradient(to bottom, #141c28 0%, transparent 30%, transparent 70%, #141c28 100%), ${texture}, linear-gradient(to bottom, #192338, #161E2F)`}
      >
        <Box variant="bounding" py={[12, 12, '120px !important']}>
          <Grid
            gridTemplateColumns={['1fr', '1fr', '1fr 1.5fr']}
            gridGap={[12, 12, 13]}
          >
            <Box>
              <PreHeading mb={6}>
                Standardise and optimise your DevOps
              </PreHeading>
              <Heading fontSize={[5, 5, '32px']} color="grey1" mb={8}>
                A Golden Path to Production
              </Heading>
              <Text fontSize="18px" color="grey2" mb={7}>
                Accelerate every step from push to production with highly
                configurable self-service workflows, pipelines, templates, and
                GitOps.
              </Text>
              <Text fontSize="18px" color="grey2" mb={10}>
                Securely deploy preview, staging, and production environments
                with observability tooling, backups, restores, and rollbacks
                included.
              </Text>
              <Link to="/features">
                <Text id="golden-path-learn-more" fontSize={3}>
                  Learn more &rarr;
                </Text>
              </Link>
            </Box>
            <FlowImage />
          </Grid>
        </Box>
      </Box>

      <Box>
        <Box variant="bounding">
          <Grid
            gridTemplateColumns={['1fr', '1fr', '1.3fr 1fr']}
            gridGap={[12, 12, 13]}
          >
            <Box gridRow={[2, 2, 1]}>
              <FlexibilityImage />
            </Box>
            <Box>
              <PreHeading mb={6}>
                Deploy any code, on any cloud, at any scale
              </PreHeading>
              <Heading fontSize={[5, 5, '32px']} color="grey1" mb={8}>
                Flexibility without Compromise
              </Heading>
              <Text fontSize="18px" color="grey2" mb={7}>
                Northflank seamlessly integrates with your preferred tooling,
                and can accommodate any tech stack.
              </Text>
              <Text fontSize="18px" color="grey2" mb={10}>
                Whether you deploy on Northflank’s secure infrastructure or on
                your own cloud account, you get the same exceptional developer
                experience, and total control over your data residency,
                deployment regions, security, and cloud expenses.
              </Text>
              <Link to="/features/platform">
                <Text id="flexibility-learn-more" fontSize={3}>
                  Learn more &rarr;
                </Text>
              </Link>
            </Box>
          </Grid>
        </Box>
      </Box>

      <Box
        background={`linear-gradient(to bottom, #141c28 0%, transparent 30%, transparent 70%, #141c28 100%), ${texture}, linear-gradient(to bottom, #192338, #161E2F)`}
      >
        <Box variant="bounding" py={[12, 12, '120px !important']}>
          <Grid
            gridTemplateColumns={['1fr', '1fr', '1fr 1.5fr']}
            gridGap={[12, 12, 13]}
          >
            <Box>
              <PreHeading mb={6}>
                Cloud native without the complexity
              </PreHeading>
              <Heading fontSize={[5, 5, '32px']} color="grey1" mb={8}>
                Kubernetes, Simplified
              </Heading>
              <Text fontSize="18px" color="grey2" mb={7}>
                Northflank leverages Kubernetes as an operating system to give
                you the best of cloud native, without the overhead.
              </Text>
              <Text fontSize="18px" color="grey2" mb={10}>
                Deploy to Northflank’s cloud for maximum simplicity, or connect
                your GKE, EKS, AKS, or bare-metal to deliver a managed platform
                experience in minutes.
              </Text>
              <Link to="/features/bring-your-own-cloud">
                <Text id="kubernetes-learn-more" fontSize={3}>
                  Learn more &rarr;
                </Text>
              </Link>
            </Box>
            <Box as="img" src="/images/landing/k8s.png" width="100%" />
          </Grid>
        </Box>
      </Box>

      <Box>
        <Box variant="bounding">
          <Box
            width={[1, 1, 2 / 3]}
            mx="auto"
            textAlign="center"
            mb={[12, 12, 14]}
          >
            <PreHeading mb={6}>Any workload, any cloud</PreHeading>
            <Heading fontSize={[5, 5, '32px']} color="grey1" mb={8}>
              Deploy In Your Preferred Cloud
            </Heading>
            <Text fontSize="18px" color="grey2">
              Deploy into your own GCP, AWS, Azure, and Civo accounts with Bring
              Your Own Cloud (BYOC), or use Northflank’s Managed Cloud with no
              infrastructure setup required. Provision compute nodes with the
              latest GPU models across cloud providers.
            </Text>
          </Box>
          <Box
            display="grid"
            gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
            gridGap={9}
            alignItems="stretch"
          >
            {cloudProviderOptions.map((provider) => (
              <CloudProviderCard key={provider.value} provider={provider} />
            ))}
          </Box>
        </Box>
      </Box>

      <Box background="linear-gradient(to bottom, #141c28 0%, #192338 30%, #192338 70%, #141c28 100%)">
        <Box variant="bounding">
          <Box
            width={[1, 1, 1 / 2]}
            mx="auto"
            textAlign="center"
            mb={[12, 12, 13]}
          >
            <PreHeading mb={6}>Supercharge your productivity</PreHeading>
            <Heading fontSize={[5, 5, '32px']} color="grey1" mb={8}>
              The Ultimate Developer Experience
            </Heading>
            <Text fontSize="18px" color="grey2">
              Northflank makes managing complex cloud native applications
              intuitive. With support for IaC and a robust API, CLI, and UI,
              Northflank has an interface for everyone.
            </Text>
          </Box>
          <PerspectiveBox
            bg="grey5"
            borderRadius={3}
            boxShadow="dropDown"
            p="1px"
            withBgSpotlight
            wrapperProps={{
              overflow: 'hidden',
              width: '100%',
              maxWidth: '700px',
              margin: '0 auto',
            }}
            noRotation
          >
            <Box
              bg="grey8"
              borderRadius="8px 8px 0 0"
              borderBottom="0"
              py={3}
              mb="1px"
              overflowX="auto"
            >
              <FeatureTabs
                tabs={dxTabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                bg="grey8"
                mx="auto"
              />
            </Box>
            {dxTabs.map((tab) => (
              <Box
                key={tab.value}
                display={tab.value === activeTab ? 'block' : 'none'}
                borderRadius="0 0 8px 8px"
              >
                <Box
                  display="flex"
                  borderRadius={tab.caption ? 0 : '0 0 8px 8px'}
                  p={3}
                  {...getInterfaceImageProps(tab.value)}
                >
                  <Box
                    as="img"
                    src={`/images/landing/${tab.value}.png`}
                    alt={`Screenshot of Northflank ${tab.label}`}
                    width="100%"
                  />
                </Box>
                {tab.caption && (
                  <Box
                    variant="flex"
                    alignItems="center"
                    bg="grey9"
                    borderRadius="0 0 8px 8px"
                    px={8}
                    py={6}
                    mt="1px"
                  >
                    {tab.captionIcon && (
                      <Icon
                        Content={tab.captionIcon}
                        Size={18}
                        color="grey3"
                        mr={3}
                      />
                    )}
                    <Text fontSize={2}>{tab.caption}</Text>
                  </Box>
                )}
              </Box>
            ))}
          </PerspectiveBox>
        </Box>
      </Box>

      <Box>
        <Box variant="bounding">
          <Box
            width={[1, 1, 2 / 3]}
            mx="auto"
            textAlign="center"
            mb={[12, 12, 14]}
          >
            <PreHeading mb={6}>Infinitely capable</PreHeading>
            <Heading fontSize={[5, 5, '32px']} color="grey1" mb={8}>
              A Complete Platform Toolkit
            </Heading>
            <Text fontSize="18px" color="grey2" mb={12}>
              From provisioning cloud resources to deploying your own secure
              multi-tenant runtime, Northflank provides all the tools you need
              to deliver on your roadmap in record time.
            </Text>
            <PlatformComponents />
          </Box>
          <FeatureSwitcher
            items={[
              {
                icon: Docker,
                label: 'Deploy Docker Images',
                heading: 'Rapidly Deploy Docker Images',
                body: (
                  <Text color="grey3" fontSize={3}>
                    Deploy any container image built on Northflank or hosted
                    with external Docker registries. Configure public or private
                    networking in your project. Set environment variables,
                    attach persistent disks, and configure resources such as
                    CPU, RAM, and ephemeral storage.
                  </Text>
                ),
                code: `await apiClient.create.service.deployment({
  parameters: {
    "projectId": "default-project"
  },
  data: {
    "name": "Example Service",
    "description": "A service description",
    "billing": {
      "deploymentPlan": "nf-compute-20"
    },
    "deployment": {
      "instances": 1,
      "external": {
        "imagePath": "nginx:latest"
      }
    },
    "ports": [
      {
        "name": "port-1",
        "internalPort": 8080,
        "public": true,
        "domains": [
          "app.example.com"
        ],
        "protocol": "HTTP"
      }
    ]
  }
});`,
                cmd: 'northflank create service deployment --file payload.json',
                docsLink: {
                  api: 'https://northflank.com/docs/v1/api/services/create-deployment-service',
                  app: 'https://northflank.com/docs/v1/application/run/run-an-image-from-a-container-registry',
                },
              },
              {
                icon: Wrench,
                label: 'Build Containers',
                heading: 'Build Your Repos Programmatically',
                body: (
                  <Text color="grey3" fontSize={3}>
                    Trigger builds for feature branches or pull requests either
                    on push or on demand using the commit’s SHA. Supply custom
                    build arguments. Choose from multiple build engines
                    including Buildkit, Kaniko, or Buildpacks.
                  </Text>
                ),
                code: `await apiClient.start.service.build({
  parameters: {
    "projectId": "production-project",
    "serviceId": "web-api"
  },
  data: {
    "branch": "main",
    "sha": "e15238f32e0d36aa855133427253a19c3fb283d7"
  }
});`,
                cmd: 'northflank start service build --file payload.json',
                docsLink: {
                  api: 'https://northflank.com/docs/v1/api/services/start-service-build',
                  app: 'https://northflank.com/docs/v1/application/build/build-your-code-on-northflank',
                },
              },
              {
                icon: Data,
                label: 'Trigger Backups',
                heading: 'Create Database Backups on Demand',
                body: (
                  <Text color="grey3" fontSize={3}>
                    Back up all your stateful workloads with fast incremental
                    disk snapshots or native database dumps. Programmatically
                    trigger backups based on events and webhooks. Configure
                    custom data retention policies.
                  </Text>
                ),
                code: `await apiClient.backup.addon({
  parameters: {
    "projectId": "production-project",
    "addonId": "postgres-db"
  },
  data: {
    "name": "Release #21 backup",
    "backupType": "snapshot",
    "compressionType": "gz"
  }
});`,
                cmd: 'northflank backup addon --file payload.json',
                docsLink: {
                  api: 'https://northflank.com/docs/v1/api/addons/backup-addon',
                  app: 'https://northflank.com/docs/v1/application/databases-and-persistence/backup-restore-and-import-data',
                },
              },
              {
                icon: Run,
                label: 'Orchestrate Jobs',
                heading: 'Run Jobs via Trigger or Schedule',
                body: (
                  <Text color="grey3" fontSize={3}>
                    Spawn containers with control over concurrency policies,
                    time limits, retries, and environment variable overrides.
                    Trigger via UI, API, or CLI, or set up a cron schedule to
                    reliably run workloads on repeat.
                  </Text>
                ),
                code: `await apiClient.start.job.run({
  parameters: {
    "projectId": "default-project",
    "jobId": "example-job"
  },
  data: {
    "runtimeEnvironment": {
      "foo": "...",
      "bar": "...",
      "baz": "..."
    },
  }
});`,
                cmd: 'northflank start job run --file payload.json',
                docsLink: {
                  api: 'https://northflank.com/docs/v1/api/jobs/run-job',
                  app: 'https://northflank.com/docs/v1/application/run/run-an-image-once-or-on-a-schedule',
                },
              },
              {
                icon: Detail,
                label: 'Monitor Workloads',
                heading: 'Tail, Search, and Filter Container Logs',
                body: (
                  <Text color="grey3" fontSize={3}>
                    Efficiently fetch log lines from a specified time window or
                    tail real-time logs directly within your own application.
                    For instance, you can render real-time logs for a Docker
                    build, micro-service, or database replica in a custom React
                    UI.
                  </Text>
                ),
                code: `const parameters = {
  projectId: "<my-project>",
  serviceId: "<deployment-service>"
};
const options = { lineLimit: 5 };

// Create a new LogsClient object with the given parameters and options.
const logsClient = await apiClient.get.service.logTail({
  parameters,
  options
});

// On the 'logs-received' event process the log
logsClient.on('logs-received', async (logLines) => {
  // Print out each log line's details from the batch of logs received
  logLines.forEach((l) => {
    console.log(\`Received log "\${l.log}" at \${l.ts.toTimeString()}\`);
  });

  // Monitor for a specific string using regex
  // Close the connection once received.
  const endLine = logLines.find(l => l.log.match(/END LOGS/));
  if (endLine) {
    await logsClient.stop();
  };
});

// Handle the error, open, and close events.
logsClient.on('error', (error) => console.log('error', error));

logsClient.on('open', () => console.log('open'));

logsClient.on('close', () => console.log('close'));

// Start the LogsClient to open a connection and begin receiving logs.
await logsClient.start();`,
                cmd: 'northflank get service logs --tail',
                docsLink: {
                  api: 'https://northflank.com/docs/v1/api/log-tailing',
                  app: 'https://northflank.com/docs/v1/application/observe/view-logs',
                },
              },
              {
                icon: LockAlt,
                label: 'Manage Secrets',
                heading: 'Securely Group Environment Vars & Files',
                body: (
                  <Text color="grey3" fontSize={3}>
                    Secret groups can be used to automatically inherit
                    connection strings from databases and share common
                    configuration files and environment variables amongst
                    micro-services. Configure inheritance priority, use secret
                    templating, and set service and job-level restrictions.
                  </Text>
                ),
                code: `await apiClient.create.secret({
  parameters: {
    project: "default-project"
  },
  data: {
    "name": "GitLab OAuth",
    "description": "OAuth data for GitLab integration",
    "secretType": "environment",
    "priority": 10,
    "restrictions": {
      "restricted": true,
      "nfObjects": [
        {
          "nfObjectId": "repo-manager",
          "nfObjectType": "service"
        }
      ]
    },
    "secrets": {
      "variables": {
        "OAUTH_CLIENT_ID": "...",
        "OAUTH_SECRET": "..."
      }
    }
  }
});`,
                cmd: 'northflank create secret --file payload.json',
                docsLink: {
                  api: 'https://northflank.com/docs/v1/api/secrets/create-secret',
                  app: 'https://northflank.com/docs/v1/application/secure/manage-secret-groups',
                },
              },
              {
                icon: Globe,
                label: 'Add Domains',
                heading: 'Add Custom Domains to Northflank',
                body: (
                  <Text color="grey3" fontSize={3}>
                    Add a domain, verify its ownership, and associate it with a
                    specific service in just a couple of API calls.
                    Programmatically provision subdomains, vanity domains, and
                    wildcards. Leverage automatic Let’s Encrypt certificate
                    creation and renewal to ensure secure communication with
                    your domains.
                  </Text>
                ),
                code: `await apiClient.create.domain({
  data: {
    "domain": "example.com",
    "redirect": {
      "mode": "default"
    },
    "certificates": {
      "mode": "default"
    }
  }
});`,
                cmd: 'northflank create domain --file payload.json',
                docsLink: {
                  api: 'https://northflank.com/docs/v1/api/domains/create-new-domain',
                  app: 'https://northflank.com/docs/v1/application/domains/domains-on-northflank',
                },
              },
            ]}
            mb={[13, 13, 14]}
          />
        </Box>
      </Box>

      <Box variant="bounding">
        <Box
          width={[1, 1, 1 / 2]}
          textAlign="center"
          mx="auto"
          mb={[12, 12, 13]}
        >
          <PreHeading mb={6}>You’re in good company</PreHeading>
          <Heading fontSize={[5, 5, '32px']} color="grey1">
            Don’t just take our word for it...
          </Heading>
        </Box>
        <FeatureMasonry width="100% !important">
          <MasonryCard
            header={
              <TestimonialHeader
                name="Keith Adams"
                position="Formerly Chief Architect Slack, Facebook, VMware, GP @ Pebblebed"
                avatar="/images/landing/quotes/keith.jpeg"
                linkedin="https://linkedin.com/in/keith-adams-pb/"
              />
            }
            text="Northflank is the first batteries-included developer platform that doesn’t suffer from the invisible ceilings that hover over its competitors. We could have built all of Slack with Northflank - and we would have, had it been available."
            rows={4}
            cols={15}
          />
          <MasonryCard
            header={
              <TestimonialHeader
                name="Darren Shepherd"
                position="CTO @ Acorn Labs, Formerly CTO Rancher"
                avatar="/images/landing/quotes/darren.jpeg"
                linkedin="https://linkedin.com/in/ibuildthecloud"
              />
            }
            text="By far the most complete product like this I’ve seen. This is how Kubernetes should be used."
            rows={4}
            cols={10}
          />
          <MasonryCard
            header={
              <TestimonialHeader
                name="Steve Manuel"
                position="CEO & Co-founder @ Dylibso, Formerly Cloudflare"
                avatar="/images/landing/quotes/steve.jpeg"
                linkedin="https://linkedin.com/in/stevemanuel"
              />
            }
            text="If you’re starting to sweat about clusters, secrets, GitOps, CI/CD, scaling, monitoring, rollouts, or anything in between, this is your answer!"
            rows={4}
            cols={12}
          />
          <MasonryCard
            header={
              <TestimonialHeader
                name="Tom Pickard"
                position="CTO @ Clock"
                avatar="/images/landing/quotes/tom.jpeg"
                linkedin="https://linkedin.com/in/tom-pickard"
              />
            }
            text="Going into our first steps with Northflank I was of the mindset, “how can it be easier than AWS AppRunner or Google Cloud Run?” but in reality it’s much more powerful and user friendly – templates and GitOps are fantastic for keeping configurations in-sync with code repos, whilst instrumentation, metrics and monitoring are top-dollar."
            rows={5}
            cols={13}
          />
          <MasonryCard
            header={
              <TestimonialHeader
                name="Thomas Parslow"
                position="Founder @ Buzzshot"
                avatar="/images/landing/quotes/tomp.png"
                linkedin="https://linkedin.com/in/tparslow"
              />
            }
            text="Transitioning to Northflank from Heroku has been a refreshing experience. With their user-friendly platform, managing DevOps tasks has become a breeze. The attentive and helpful support team at Northflank has exceeded my expectations. Highly recommended!"
            rows={5}
            cols={12}
          />
          <MasonryCard
            header={
              <TestimonialHeader
                name="Justin Adkins"
                position="CEO @ Hubble Vote, Engineer @ Taskrabbit"
                avatar="/images/landing/quotes/justin.jpeg"
                linkedin="https://linkedin.com/in/jmadkins"
              />
            }
            text="I love the UI. It’s easy to plug and play and bring my own cloud, and I didn’t have to make any changes to integrate with Northflank on my end."
            rows={4}
            cols={13}
          />
          <MasonryCard
            header={
              <TestimonialHeader
                name="Ruud Schuurmans"
                position="Former Lead Software Architect @ Plauti"
                avatar="/images/landing/quotes/ruud.png"
                linkedin="https://linkedin.com/in/ruudschuurmans"
              />
            }
            text="Northflank eradicated all of our DevOps overhead. You only have to provide a Dockerfile to have a secure, production-ready build pipeline and hosting environment. This allows our teams to focus on building great software, with confidence."
            rows={6}
            cols={9}
          />
          <MasonryCard
            header={
              <TestimonialHeader
                name="Charlie Egan"
                position="Dev Advocate @ Styra, OPA Maintainer"
                avatar="/images/landing/quotes/charlie.jpg"
                linkedin="https://linkedin.com/in/charlieegan3"
              />
            }
            text="I found Northflank while hunting for a Heroku replacement. I’ve found the platform to be very stable and fairly priced. I deploy updates to my apps on Northflank weekly, and it’s been a key factor in my ability to quickly build more side projects."
            rows={6}
            cols={9}
          />
          <MasonryCard
            header={
              <TestimonialHeader
                name="Michael Bromley"
                position="Co-Founder & CTO @ Vendure"
                avatar="/images/landing/quotes/michael.jpeg"
                linkedin="https://linkedin.com/in/michael-bromley-ab1704156"
              />
            }
            text="The thoughtful UX of Northflank’s web dashboard takes away the complexity of having to manage everything. They have clearly thought hard about how to make developers’ lives easier."
            rows={6}
            cols={7}
          />
          <MasonryCard
            header={
              <TestimonialHeader
                name="Matthew Moss"
                position="Lead Full Stack Developer @ Acquire Digital"
                avatar="/images/landing/quotes/matty.jpeg"
                linkedin="https://linkedin.com/in/mattymoss"
              />
            }
            text="The UI is easy to navigate yet powerful. From quickly creating simple test containers to setting up production ready environments, Northflank provides a seamless user experience for both beginners and seasoned professionals. Plus, their customer support is exceptional, frequently going above and beyond!"
            rows={5}
            cols={14}
          />
          <MasonryCard
            header={
              <TestimonialHeader
                name="Matt de Sousa"
                position="Founder @ WideBundle"
                avatar="/images/landing/quotes/mat.jpeg"
                linkedin="https://linkedin.com/in/mat-de-sousa-20a365134"
              />
            }
            text="Northflank makes everything easier. I am not a server guy, but the tools on the dashboard are amazing and easy to understand. On top of that, you add their amazing team, and you get the perfect solution for every SaaS."
            rows={5}
            cols={11}
          />
        </FeatureMasonry>
      </Box>

      <Box variant="bounding">
        <GetStartedCta />
      </Box>
    </>
  );
};

export default Index;
