import React from 'react';

import PlanSelect from '@northflank/components/Billing/PlanSelect';
import Box from '@northflank/components/Box';
import FieldLabel from '@northflank/components/FieldLabel';
import { FormikInput } from '@northflank/components/FormikInput';
import { resourcePriceObject } from '@northflank/components/marketing/Pricing/data';
import { byocResourcePriceObject } from '@northflank/pricing';

const Job = ({ resource, formikIndex, plans }) => {
  return (
    <Box>
      <PlanSelect
        label="Plan"
        name={`resources.${formikIndex}.plan`}
        plans={plans}
        onDemand={false}
        northflankResourcesPrices={
          resource.isByoc ? byocResourcePriceObject : resourcePriceObject
        }
        isJob
        useId
        useInternalId
      />
      <Box mt={7}>
        <FieldLabel mb={6}>Monthly runtime minutes</FieldLabel>
        <FormikInput
          type="number"
          name={`resources.${formikIndex}.runMinutes`}
        />
      </Box>
    </Box>
  );
};

export default Job;
