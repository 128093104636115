import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import slugify from 'slugify';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Grid from '@northflank/components/Grid';
import Heading from '@northflank/components/Heading';
import Image from '@northflank/components/Image';
import { pages as featurePages } from '@northflank/components/marketing/FeatureNav';
import FeaturePageNav from '@northflank/components/marketing/FeaturePageNav';
import FlexibleImage from '@northflank/components/marketing/FlexibleImage';
import Text from '@northflank/components/Text';

import GetStartedCta from './GetStartedCta';
import topog from './Topog';

export const FeatureGroup = ({
  heading,
  text,
  image,
  wideImage,
  wideImagePosition = 'top',
  wideImageAlt,
  singleItem,
  items,
  link,
  pt,
  ...rest
}) => (
  <Box position="relative" px={[9, 9, 12]} {...rest}>
    <Box
      as="section"
      id={slugify(heading, { remove: /[^a-zA-Z0-9- ]/g, lower: true })}
      position="absolute"
      left={0}
      top={['-40px', '-40px', '-150px', '-120px', '-100px']}
      bottom={0}
      width="1px"
    />
    <Box
      as="section"
      variant="bounding"
      px="0!important"
      py={[
        '64px!important',
        '64px!important',
        '64px!important',
        '96px!important',
      ]}
      borderTop="1px solid"
      borderColor="grey6"
    >
      {(heading || text) && (
        <Grid
          gridTemplateColumns={['1fr', '1fr', image ? 'repeat(2, 1fr)' : '1fr']}
          mb={13}
        >
          <Box>
            <Heading color="grey1" fontSize={[5, 5, 6]}>
              {heading}
            </Heading>
            {text && (
              <Text fontSize={4} color="grey3" mt={6}>
                {text}
              </Text>
            )}
          </Box>
          {image && <Image src={image} alt={heading} />}
        </Grid>
      )}
      {singleItem}
      {wideImage && wideImagePosition === 'top' && (
        <FlexibleImage
          src={wideImage}
          alt={wideImageAlt}
          aspectRatio="5 / 2"
          border="1px solid"
          borderColor="grey6"
          borderRadius={4}
          boxShadow="image"
          mb={13}
        />
      )}
      <Grid
        gridTemplateColumns={['1fr', '1fr', '1fr', 'repeat(3, 1fr)']}
        gridGap={12}
        alignItems="start"
      >
        {items}
      </Grid>
      {link && (
        <Box mt={13} fontSize={4}>
          <Link to={link}>Learn more about {heading} &rarr;</Link>
        </Box>
      )}
      {wideImage && wideImagePosition === 'bottom' && (
        <FlexibleImage
          src={wideImage}
          alt={wideImageAlt}
          aspectRatio="5 / 2"
          borderRadius={4}
          boxShadow="image"
          mt={13}
        />
      )}
    </Box>
  </Box>
);

export const FeaturesWrapper = ({
  children,
  customNavItems,
  cloudProviderPage,
}) => {
  const { pathname } = useLocation();

  const flattenedFeaturePages = featurePages.reduce((acc, cur) => {
    acc = acc.concat(cur.items);
    return acc;
  }, []);
  const pageIndex = flattenedFeaturePages.findIndex((p) => p.path === pathname);
  const nextPage = flattenedFeaturePages[pageIndex + 1];

  return (
    <Box
      maxWidth="1920px"
      mx="auto"
      background={[
        'none',
        'none',
        `radial-gradient(50% 1000px at 65% 400px, rgba(0, 147, 255, 0.05) 0%, #151C28 50%),
        radial-gradient(50% 1000px at 65% 400px, rgba(0, 0, 0, 0) 0%, #151C28 60%), ${topog}`,
      ]}
      pt="80px"
      mt="-80px"
    >
      <Grid
        gridTemplateColumns={['1fr', '1fr', '300px auto']}
        gridGap={8}
        alignItems="start"
        maxWidth={cloudProviderPage ? '1500px' : 'max'}
        mx="auto"
        position="relative"
      >
        <FeaturePageNav asPath={pathname} customNavItems={customNavItems} />
        <Box>
          {children}
          <Box as="section" px={[9, 9, 12]}>
            <Box
              variant="bounding"
              px="0px!important"
              py={[
                '64px!important',
                '64px!important',
                '64px!important',
                '96px!important',
              ]}
              borderTop="1px solid"
              borderColor="grey6"
            >
              <GetStartedCta
                fontSize="26px"
                secondaryButton={
                  nextPage ? (
                    <Link to={nextPage.path}>
                      <Button
                        variant={['large']}
                        mr="auto"
                        ml={['auto', 'auto', 0]}
                        mb={[6, 6, 6, 0]}
                        width="200px"
                      >
                        Learn more
                      </Button>
                    </Link>
                  ) : undefined
                }
              />
            </Box>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};
