import React from 'react';

import Error from '../pages/500';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  static getDerivedStateFromError(error) {
    return { error: error.message };
  }

  componentDidUpdate(prevProps, prevState) {
    /*
      if the previous state was an error and we change paths, assume the error
      is no longer present
    */
    if (
      prevState.error &&
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      this.setState({ error: null });
    }
  }

  componentDidCatch(error, _errorInfo) {
    console.error(error);
  }

  render() {
    const { error } = this.state;

    if (error) {
      return <Error logo={!!this.props.logo} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
