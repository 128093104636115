import React, { useEffect, useState } from 'react';

import { Microchip as MicrochipRegular } from '@styled-icons/boxicons-regular/Microchip';
import { Microchip as MicrochipSolid } from '@styled-icons/boxicons-solid/Microchip';
import slugify from 'slugify';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Heading from '@northflank/components/Heading';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';

import Breadcrumbs from '../../../components/Breadcrumbs';
import CloudProviderGpuTable from '../../../components/CloudProviders/CloudProviderGpuTable';
import CloudProvidersNav from '../../../components/CloudProviders/CloudProvidersNav';
import { FeaturesWrapper } from '../../../components/FeaturePageWrapper';
import { Section } from '../provider';

const GPUs = ({ gpusOfType, otherGPUs }) => {
  const [isMobile, setIsMobile] = useState(false);

  const { name, displayName, manufacturer } =
    gpusOfType[0].resources.gpuInfo.gpus[0];

  const gpuName = name.replace(/[._()]/g, '');

  useEffect(() => {
    const match = window.matchMedia('(max-width: 768px)');
    const query = (m) => {
      setIsMobile(m.matches);
    };
    query(match);
    match.addListener(query);
  }, []);

  const navItem = [
    {
      title: `${manufacturer} ${gpuName}`,
      href: `/cloud/gpus/${name}`,
      subItems: [
        {
          icon: MicrochipSolid,
          title: `${gpuName} Instances Available on Northflank`,
        },
        {
          icon: MicrochipRegular,
          title: `Other GPU Instances Available on Northflank`,
        },
      ],
    },
  ];

  return (
    <>
      <SEO
        title={`${displayName} | GPU Instances | Cloud Providers`}
        description={`Deploy GPU accelerated workloads into your preferred cloud provider with ${manufacturer} ${displayName} GPU instances on Northflank.`}
      />
      <CloudProvidersNav />
      <FeaturesWrapper cloudProviderPage customNavItems={navItem}>
        <Box variant="bounding">
          <Breadcrumbs mb={6} />
          <Heading as="h1" color="grey1" fontSize={[5, 5, 6, 6]} mb={8}>
            Deploy with {manufacturer} {displayName} GPUs on Northflank
          </Heading>
          <Text fontSize={4} mediaQuery mb={13}>
            Deploy GPU workloads on {manufacturer} {displayName} instances for
            AI, machine learning, and HPC workloads.
          </Text>
          <a
            href="https://app.northflank.com/s/account/cloud/clusters/new"
            target="_blank"
            style={{ display: 'inline-block' }}
            rel="noreferrer"
          >
            <Button variant={['large', 'primary']}>
              Deploy {displayName} GPUs now
            </Button>
          </a>
        </Box>
        <Section
          anchor={`${slugify(gpuName, { lower: true })}-instances-available-on-northflank`}
        >
          <Heading color="grey1" fontSize={[5, 5, 6, 6]} mb={10}>
            {displayName} Instances Available on Northflank
          </Heading>
          <CloudProviderGpuTable
            nodes={gpusOfType}
            isMobile={isMobile}
            showProvider
          />
        </Section>
        {otherGPUs.length ? (
          <Section anchor="other-gpu-instances-available-on-northflank">
            <Heading color="grey1" fontSize={[5, 5, 6, 6]} mb={10}>
              Other GPU Instances Available on Northflank
            </Heading>
            <CloudProviderGpuTable
              nodes={otherGPUs}
              isMobile={isMobile}
              showProvider
            />
          </Section>
        ) : null}
      </FeaturesWrapper>
    </>
  );
};

export default GPUs;
