import React, { useContext, useRef, useState } from 'react';

import { CheckCircle } from '@styled-icons/boxicons-regular/CheckCircle';
import { Cloud } from '@styled-icons/boxicons-regular/Cloud';
import { CloudDownload } from '@styled-icons/boxicons-regular/CloudDownload';
import { CloudUpload } from '@styled-icons/boxicons-regular/CloudUpload';
import { GitCommit } from '@styled-icons/boxicons-regular/GitCommit';
import { Revision } from '@styled-icons/boxicons-regular/Revision';
import { Wrench } from '@styled-icons/boxicons-regular/Wrench';
import styled, { css, keyframes, ThemeContext } from 'styled-components';

import Box from '@northflank/components/Box';
import Icon from '@northflank/components/Icon';
import Text from '@northflank/components/Text';
import { useInterval } from '@northflank/utilities/hooks/useInterval';

const stages = [
  {
    icon: GitCommit,
    color: 'grey3',
    text: 'New commit',
  },
  {
    icon: CloudDownload,
    color: 'primary',
    text: 'Cloning repository',
  },
  {
    icon: Wrench,
    color: 'primary',
    text: 'Build in progress',
  },
  {
    icon: CloudUpload,
    color: 'primary',
    text: 'Uploading image',
  },
  {
    icon: CheckCircle,
    color: 'success',
    text: 'Build successful',
  },
  {
    icon: Revision,
    color: 'primary',
    text: 'Container is starting',
  },
  {
    icon: Cloud,
    color: 'success',
    text: 'Container is running',
  },
];

const slideOut = keyframes`
  0% {
    transform: translateY(0px);
  }
  10% {
    transform: translateY(-150px);
  }
  90% {
    transform: translateY(-150px);
  }
  100% {
    transform: translateY(-300px);
  }
`;

const StageBox = styled(Box)`
  animation: ${({ animate }) =>
    animate !== undefined
      ? css`
          ${slideOut} 3s forwards
        `
      : ''};
`;

const RealtimePlatform = () => {
  const index = useRef(0);
  const [currentStages, setCurrentStages] = useState(stages.slice(0, 2));

  const theme = useContext(ThemeContext);

  useInterval(() => {
    setCurrentStages((c) => {
      const clone = [...c];
      clone.shift();
      clone.push(stages[(index.current + 2) % stages.length]);
      return clone;
    });
    index.current = index.current + 1;
  }, 3000);

  return (
    <Box
      height="100%"
      position="relative"
      overflow="hidden"
      pt="150px"
      _css={{
        '&::before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: `linear-gradient(to bottom, ${theme.colors.grey10} 5%, transparent 25%, transparent 70%, ${theme.colors.grey10} 90%)`,
          zIndex: 1,
          pointerEvents: 'none',
        },
      }}
    >
      {currentStages.map((stage) => (
        <StageBox
          key={`stage-${stage.text}`}
          bg="grey9"
          border="2px solid"
          borderColor="grey5"
          borderRadius={2}
          boxShadow="dropDown"
          variant="flex"
          justifyContent="space-between"
          position="relative"
          p={7}
          _css={{
            '&::after': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: '100%',
              left: '21px',
              width: '4px',
              height: '1000px',
              bg: 'grey5',
            },
          }}
          animate
        >
          <Box variant="flex">
            <Icon Content={stage.icon} Size={22} color={stage.color} mr={6} />
            <Text fontSize={3}>{stage.text}</Text>
          </Box>
          <Text color="grey3" fontFamily="monospace" mt="1px">
            now
          </Text>
        </StageBox>
      ))}
    </Box>
  );
};

export default RealtimePlatform;
