import React from 'react';

import { Check } from '@styled-icons/boxicons-regular/Check';
import css from '@styled-system/css';
import { transparentize } from 'polished';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';

import Box from '@northflank/components/Box';
import Text from '@northflank/components/Text';
import withComponentId from '@northflank/utilities/withComponentId';

const Container = styled.label(space, ({ theme, color, error }) =>
  css({
    display: 'flex',
    alignItems: 'flex-start',
    cursor: 'pointer',
    input: {
      opacity: 0,
      height: 0,
      width: 0,
      '&:focus': {
        '& ~ .check': {
          bg: 'grey7',
        },
      },
      '&:checked': {
        '& ~ .check': {
          bg: transparentize(
            0.88,
            color ? theme.colors[color] : theme.colors.success
          ),
          borderColor: error
            ? 'danger'
            : color
              ? theme.colors[color]
              : 'success',
          svg: {
            display: 'block',
          },
        },
        '&:hover': {
          '& ~ .check': {
            bg: transparentize(
              0.88,
              color ? theme.colors[color] : theme.colors.success
            ),
          },
        },
      },
    },
    '.check': {
      display: 'inline-flex',
      bg: 'grey9',
      border: '1px solid',
      borderColor: error ? 'danger' : 'grey5',
      borderRadius: 1,
      width: '16px',
      height: '16px',
      minWidth: '16px',
      minHeight: '16px',
      svg: {
        fill: color ? theme.colors[color] : 'success',
        display: 'none',
      },
    },
    '&:hover': {
      '.check': {
        bg: 'grey8',
      },
    },
    '&[disabled]': {
      cursor: 'not-allowed !important',
      span: {
        color: 'grey4',
      },
      '.check': {
        opacity: 0.4,
      },
      '&:hover': {
        '.check': {
          bg: 'grey9',
        },
      },
    },
  })
);

/*
  Props get spread onto Container to allow styling by props. If you want to
  pass props to the input itself (such as name, value etc.), use the
  `inputProps` object, which will be spread onto the input element.
*/
const Checkbox = ({
  uid,
  label,
  color,
  reversed,
  disabled,
  checked,
  inputProps,
  name,
  error,
  hideError,
  required,
  formikRequired,
  ...props
}) => (
  <>
    <Container color={color} disabled={disabled} error={error} {...props}>
      {label && reversed && (
        <Text
          as="span"
          display="inline-block"
          color="grey1"
          mr={6}
          lineHeight="16px"
          fontSize={1}
        >
          {label}
        </Text>
      )}
      <input
        id={uid && `checkbox-${uid}`}
        type="checkbox"
        disabled={disabled}
        name={name}
        checked={checked}
        {...inputProps}
      />
      <Box alignItems="center" justifyContent="center" className="check">
        <Check size={14} />
      </Box>
      {label && !reversed && (
        <Text
          as="span"
          display="inline-block"
          color="grey1"
          ml={6}
          lineHeight="16px"
          fontSize={1}
        >
          {label}
        </Text>
      )}
      {(required || formikRequired) && (
        <Text
          as="span"
          fontFamily="monospace"
          fontSize={1}
          color="grey3"
          lineHeight="16px"
          ml={6}
        >
          *
        </Text>
      )}
    </Container>
    {!hideError && error && (
      <Text color="danger" fontSize={0} mt={5}>
        {error}
      </Text>
    )}
  </>
);

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  reversed: PropTypes.bool,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  name: PropTypes.string,
  error: PropTypes.string,
};

export default withComponentId(Checkbox);
