import React from 'react';

import { ChevronsUp } from '@styled-icons/boxicons-regular/ChevronsUp';
import { EditAlt } from '@styled-icons/boxicons-regular/EditAlt';
import { Star } from '@styled-icons/boxicons-regular/Star';
import { XCircle } from '@styled-icons/boxicons-regular/XCircle';
import css from '@styled-system/css';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Box from '@northflank/components/Box';
import Cost from '@northflank/components/Cost';
import Grid from '@northflank/components/Grid';
import Icon from '@northflank/components/Icon';
import Text from '@northflank/components/Text';
import Tooltip from '@northflank/components/Tooltip';
import { getResourcesFromPlan } from '@northflank/constants-base';
import {
  calculate1kMinPlanPrice,
  calculateHourlyPlanPrice,
  calculateMonthlyPlanPrice,
} from '@northflank/pricing';
import variant from '@northflank/utilities/styled-system/variant';
import withComponentId from '@northflank/utilities/withComponentId';

const StyledPlanBox = styled.button(
  ({ disabled, error }) =>
    css({
      appearance: 'none',
      width: '100%',
      borderColor: error ? 'danger' : 'grey6',
      borderWidth: '0 0 1px 0',
      borderStyle: 'solid',
      outline: 'none',
      cursor: 'pointer',
      bg: 'transparent',
      color: 'grey1',
      fontSize: 2,
      fontFamily: 'body',
      textAlign: 'left',
      '&:hover, &:focus': {
        bg: !disabled && 'grey9',
        outline: 0,
      },
    }),
  ({ error }) =>
    variant({
      variants: {
        selectionHeader: {
          bg: 'grey10',
          border: '1px solid',
          borderColor: error ? 'danger' : 'grey6',
          borderRadius: 1,
          '&:hover, &:focus': {
            bg: 'grey10',
          },
        },
        disabled: {
          opacity: 0.5,
          cursor: 'not-allowed',
          '&:hover, &:focus': {
            bg: 'grey10',
          },
        },
      },
    })
);

const PlanBox = withComponentId(({ uid, ...rest }) => (
  <StyledPlanBox id={`button-${uid}`} {...rest} />
));

PlanBox.defaultProps = {
  type: 'button',
};

const getLabel = (isJob) => {
  if (isJob) return 'run';
  return 'CI';
};

const PlanCard = ({
  northflankResourcesPrices,
  plan,
  selected,
  handleSelect,
  selectionHeader,
  showSelection,
  isBuildService,
  isJob,
  onDemand,
  freeProject,
  hidePrices,
  disabled,
  fontSize = 2,
  highlightPlan,
  highlightPlanTooltip,
  error,
}) => {
  const pricePerHour = calculateHourlyPlanPrice(
    plan,
    onDemand,
    northflankResourcesPrices
  );
  const pricePerMonth = calculateMonthlyPlanPrice(
    plan,
    onDemand,
    northflankResourcesPrices
  );

  const pricePer1kMin = calculate1kMinPlanPrice(
    plan,
    onDemand,
    northflankResourcesPrices
  );

  if (!plan) return null;

  plan.currency = 'usd';
  plan.hourlyCost = pricePerHour;
  plan.monthlyCost = pricePerMonth;

  const { name, currency, monthlyCost, hourlyCost } = plan;

  const planResources = getResourcesFromPlan(plan);

  return (
    <Tooltip
      title={plan.disabledReason}
      disabled={!plan.disabled || !plan.disabledReason}
    >
      <PlanBox
        variant={[
          ...(selectionHeader ? ['selectionHeader'] : []),
          ...(disabled || plan.disabled ? ['disabled'] : []),
        ]}
        onClick={!disabled && handleSelect}
        disabled={disabled}
        error={error}
      >
        <Grid
          gridTemplateColumns={[
            '1fr 1fr',
            '1fr 1fr',
            `${
              freeProject || hidePrices
                ? 'repeat(3, 1fr)'
                : `${isBuildService || isJob ? '1.5fr' : '1.75fr'} 1fr 1fr 1.5fr`
            } 18px`,
          ]}
          p={7}
          gridGap={6}
          alignItems="start"
        >
          <Box>
            {selectionHeader && (
              <Text fontSize={0} color="grey3" mb={1}>
                Plan
              </Text>
            )}
            <Box variant="flex" alignItems="center">
              <Text
                fontSize={fontSize}
                color={!selectionHeader && selected ? 'primary' : 'grey1'}
              >
                {name}
              </Text>
              {name === highlightPlan && (
                <Box ml={3}>
                  <Tooltip title={highlightPlanTooltip}>
                    <Icon Content={Star} Size={16} color="primary" />
                  </Tooltip>
                </Box>
              )}
            </Box>
          </Box>
          <Box>
            {selectionHeader && (
              <Text fontSize={0} color="grey3" mb={1}>
                vCPU
              </Text>
            )}
            <Box variant="flex" alignItems="center">
              <Text fontSize={fontSize}>
                {planResources.cpu}{' '}
                <Text as="span" fontSize={0}>
                  {planResources.cpu < 1 ? 'shared' : 'dedicated'}
                </Text>
              </Text>
              {planResources.cpuUnbounded && (
                <Tooltip title="CPU is unbounded">
                  <Icon Content={ChevronsUp} Size={16} color="primary" ml={1} />
                </Tooltip>
              )}
            </Box>
          </Box>
          <Box>
            {selectionHeader && (
              <Text fontSize={0} color="grey3" mb={1}>
                Memory
              </Text>
            )}
            <Text fontSize={fontSize}>
              {planResources.memory}{' '}
              <Text as="span" fontSize={0}>
                MB
              </Text>
            </Text>
          </Box>
          {!freeProject && !hidePrices && (
            <Box>
              {selectionHeader && (
                <Text fontSize={0} color="grey3" mb={1}>
                  Price
                </Text>
              )}
              <Box variant="flex" alignItems="flex-end">
                {isJob || isBuildService ? (
                  <Cost
                    isJobOrBuild={isJob || isBuildService}
                    currency={currency}
                    cost={pricePer1kMin}
                    label={`/1000 ${getLabel(isJob)} minutes`}
                    fontSize={fontSize}
                  />
                ) : (
                  <>
                    <Cost
                      currency={currency}
                      cost={monthlyCost}
                      label="/mo"
                      fontSize={fontSize}
                    />
                    <Box
                      variant="flex"
                      alignItems="center"
                      color="grey3"
                      fontSize={0}
                      ml={4}
                    >
                      (
                      <Cost
                        currency={currency}
                        cost={hourlyCost}
                        label="/hr"
                        full
                        fontSize={0}
                      />
                      )
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          )}
          {selectionHeader ? (
            <Icon
              Content={showSelection ? XCircle : EditAlt}
              Size={18}
              color="grey3"
              mt={6}
            />
          ) : (
            <div />
          )}
        </Grid>
      </PlanBox>
    </Tooltip>
  );
};

PlanCard.propTypes = {
  plan: PropTypes.object.isRequired,
  handleSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
};

PlanCard.defaultProps = {
  selectionHeader: true,
  selected: false,
  disabled: false,
};

export default PlanCard;
