import React from 'react';

import { Check } from '@styled-icons/boxicons-regular/Check';
import { InfoCircle } from '@styled-icons/boxicons-regular/InfoCircle';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Heading from '@northflank/components/Heading';
import Icon from '@northflank/components/Icon';
import Text from '@northflank/components/Text';
import Tooltip from '@northflank/components/Tooltip';

const itemBorders = {
  highlight: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'grey5',
    borderRadius: [0, 0, '4px'],
  },
  first: {
    borderWidth: ['1px 1px 0 1px', '1px 1px 0 1px', '1px 0px 1px 1px'],
    borderStyle: 'solid',
    borderColor: 'grey6',
    borderRadius: ['4px 4px 0 0', '4px 4px 0 0', '4px 0 0 4px'],
  },
  last: {
    borderWidth: ['1px 1px 1px 1px'],
    borderStyle: 'solid',
    borderColor: 'grey6',
    borderRadius: ['0 0 4px 4px', '0 0 4px 4px', '0 4px 4px 0'],
  },
  normal: {
    borderWidth: ['0 1px 0 1px', '0 1px 0 1px', '1px 0px 1px 0px'],
    borderStyle: 'solid',
    borderColor: 'grey6',
    borderRadius: 0,
  },
};

// export const tierIcons = {
//   free: PurchaseTag,
//   payg: TrendingUp,
//   onyourcloud: Cloud,
//   enterprise: Buildings,
// };

export const CheckListItem = ({ text, tooltip, isMarketing }) => (
  <Box as="li" variant="flex" alignItems="start">
    <Box minWidth={isMarketing ? '18px' : '16px'} mt="2px" mr={6}>
      <Icon Content={Check} Size={isMarketing ? 18 : 16} color="success" />
    </Box>
    <Text fontSize={isMarketing ? [2, 2, 2, 3] : 2} color="grey2">
      {text}
    </Text>
    {tooltip && (
      <Box mt="2px" ml={4}>
        <Tooltip title={tooltip}>
          <Icon
            Content={InfoCircle}
            Size={isMarketing ? 18 : 16}
            color="grey3"
          />
        </Tooltip>
      </Box>
    )}
  </Box>
);

const TierItem = ({ tier }) => (
  <Box
    variant="flex"
    flexDirection="column"
    justifyContent="space-between"
    height={
      tier.attributes.highlight ? ['100%', '100%', 'calc(100% + 40px)'] : '100%'
    }
    bg={tier.attributes.highlight ? 'grey10' : 'grey9'}
    boxShadow={tier.attributes.highlight ? 'dropDown' : 'none'}
    p={[8, 8, 8, 10]}
    _css={{
      ...itemBorders[tier.attributes.highlight ? 'highlight' : 'normal'],
      '&:first-child': { ...itemBorders.first },
      '&:last-child': { ...itemBorders.last },
    }}
    gridColumn={tier.attributes.gridColumn}
  >
    <Box mt={tier.attributes.highlight ? '20px' : 0}>
      <Box variant="flex" alignItems="flex-start" mb={7}>
        <Box
          minWidth="24px"
          mt="2px"
          mr={5}
          position="relative"
          _css={{
            '&::before': {
              content: '""',
              display: 'block',
              width: '100%',
              height: '100%',
              position: 'absolute',
              bg: 'primary',
              borderRadius: 5,
              opacity: 0.6,
              filter: 'blur(20px)',
            },
          }}
        >
          <Icon Content={tier.attributes.icon} Size={20} color="primary" />
        </Box>
        <Heading fontSize={4}>{tier.attributes.title}</Heading>
      </Box>
      <Text color="grey3" fontSize={3} mb={8}>
        {tier.attributes.description}
      </Text>
      {(tier.attributes.cost ||
        tier.attributes.dollarCost ||
        tier.attributes.additionalCost) && (
        <Box display="flex" alignItems="center" height="61px" mb={8}>
          <Box width="100%" bg="grey8" borderRadius={2} px={8} py={6}>
            <Text fontSize={3} fontWeight={500}>
              {tier.attributes.cost ?? (
                <>
                  Starting at ${tier.attributes.dollarCost}
                  <Text as="span" color="grey2" fontSize={3}>
                    /mo
                  </Text>
                </>
              )}
              {tier.attributes.additionalCost && (
                <>
                  <br />
                  <Text
                    as="span"
                    color="grey3"
                    fontSize={1}
                    fontWeight="bold"
                    textTransform="uppercase"
                  >
                    + {tier.attributes.additionalCost}
                  </Text>
                </>
              )}
            </Text>
          </Box>
        </Box>
      )}
      <Box as="ul" _css={{ listStyle: 'none', '> * + *': { mt: 6 } }}>
        {tier.attributes.features?.map((feature) =>
          typeof feature === 'object' && feature.text ? (
            <CheckListItem
              key={feature.text}
              text={feature.text}
              tooltip={feature.tooltip}
              isMarketing
            />
          ) : (
            <CheckListItem key={feature} text={feature} isMarketing />
          )
        )}
      </Box>
    </Box>
    <Box mt={[8, 8, 8, 10]} mb={tier.attributes.highlight ? '20px' : 0}>
      <a
        href={tier.attributes.linkUrl}
        target={
          !tier.attributes.linkUrl.includes('app.northflank.com') && '_blank'
        }
      >
        <Button
          id={`pricing-card-get-started-${tier.attributes.key}`}
          variant={['primary', 'large']}
          width="100%"
        >
          {tier.attributes.linkText}
        </Button>
      </a>
    </Box>
  </Box>
);

export default TierItem;
