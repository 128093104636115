import { DBPlan } from '@northflank/schema-types';
import { NorthflankError } from '@northflank/errors';
import { ResourcePlanTypes } from '../../data';
import { capitalize } from '../index';

const checkResourceLimits = (configuration, resource: 'cpu' | 'memory') => {
  if (
    configuration.resources[resource].resources.limit <
    configuration.resources[resource].resources.request
  ) {
    throw new NorthflankError({
      code: 400,
      message: 'Resource limits can not be smaller than requests.',
      details: {
        key: `configuration.resources.${resource}.resources.limit`,
      },
    });
  }
};

const checkResourceRequirement = (
  configuration,
  resource: 'cpu' | 'memory',
  type: 'limit' | 'request'
) => {
  if (!configuration.resources?.[resource]?.resources?.[type]) {
    throw new NorthflankError({
      code: 400,
      message: `${resource === 'cpu' ? 'CPU' : capitalize(resource)} ${type} must be specified.`,
      details: {
        key: `configuration.resources.${resource}.resources.${type}`,
      },
    });
  }
};

export const validatePlanConfiguration = (configuration: DBPlan['configuration']) => {
  switch (configuration.type) {
    case ResourcePlanTypes.K8S:
      checkResourceRequirement(configuration, 'cpu', 'request');
      checkResourceRequirement(configuration, 'memory', 'limit');
      checkResourceRequirement(configuration, 'memory', 'request');

      // Decide whether to delete limit or set to requests
      if (configuration.resources.cpu.options?.unbounded) {
        delete configuration.resources.cpu.resources.limit;
      } else {
        checkResourceRequirement(configuration, 'cpu', 'limit');
        checkResourceLimits(configuration, 'cpu');
      }
      checkResourceLimits(configuration, 'memory');
      break;
    case ResourcePlanTypes.NF:
      delete configuration.resources.cpu.resources.request;
      delete configuration.resources.memory.resources.request;

      checkResourceRequirement(configuration, 'cpu', 'limit');
      checkResourceRequirement(configuration, 'memory', 'limit');
      break;
    default:
      throw new NorthflankError({
        code: 400,
        message: 'Invalid configuration type specified.',
        details: {
          key: `configuration.resources.type`,
        },
      });
  }

  return { success: true };
};

export const validatePlanConfigurationFrontend = (
  configuration: DBPlan['configuration'],
  asSpec?: boolean
) => {
  try {
    return validatePlanConfiguration(configuration);
  } catch (e) {
    if (e instanceof NorthflankError) {
      return {
        success: false,
        message: e.message,
        key: e.details?.key ? `${asSpec ? 'spec.' : ''}${e.details.key}` : undefined,
      };
    }
    return { success: true };
  }
};
