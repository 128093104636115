import css from '@styled-system/css';
import styled from 'styled-components';
import {
  background,
  border,
  color,
  layout,
  space,
  system,
  typography,
} from 'styled-system';

import textTransform from '@northflank/utilities/styled-system/text-transform';

const Heading = styled.h2(
  space,
  layout,
  background,
  color,
  typography,
  border,
  textTransform,
  system({
    textTransform: true,
  }),
  ({ fontSize = 5, fontWeight = 3, fontFamily = 'body', lineHeight = 1.2 }) =>
    css({
      fontSize,
      fontWeight,
      fontFamily,
      lineHeight,
    })
);

export default Heading;
