import React from 'react';

import Box from '@northflank/components/Box';
import Grid from '@northflank/components/Grid';
import Heading from '@northflank/components/Heading';
import Icon from '@northflank/components/Icon';
import MarkdownBody from '@northflank/components/marketing/MarkdownBody';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';
import X from '@northflank/images/icons/X';

import { GuideItem } from './guides';

const Author = ({ author, renderedBio, posts }) => {
  return (
    <>
      <SEO
        title={`${author.attributes.name} — Authors`}
        description={author.attributes.bio}
        image={author.attributes.picture?.data?.attributes.url}
      />
      <Box variant="bounding">
        <Box variant="flex" alignItems="center" mb={10}>
          <Box
            as="img"
            src={
              author.attributes.picture?.data?.attributes.url ||
              '/images/headshot/default.png'
            }
            borderRadius={5}
            width="75px"
            height="75px"
            boxShadow="dropDown"
            mr={10}
          />
          <Box>
            <Heading as="h1" color="grey1" fontSize={[5, 5, '30px']}>
              {author.attributes.name}
            </Heading>
            {author.attributes.twitter && (
              <Box
                as="a"
                href={`https://x.com/${author.attributes.twitter.replace('@', '')}`}
                target="_blank"
                display="flex"
                alignItems="center"
                color="grey3"
                mt={3}
              >
                <Icon Content={X} Size={20} mr={3} />
                <Text lineHeight={1} mb="1px">
                  {author.attributes.twitter}
                </Text>
              </Box>
            )}
          </Box>
        </Box>
        <Box pb={12} mb={12} borderBottom="1px solid" borderColor="grey6">
          {renderedBio ? (
            <MarkdownBody
              dangerouslySetInnerHTML={{ __html: renderedBio }}
              fontSize={3}
            />
          ) : (
            <Text as="em" color="grey3" fontSize={3}>
              No bio yet
            </Text>
          )}
        </Box>
        <Box>
          <Heading mb={8}>Posts by {author.attributes.name}</Heading>
          <Grid gridTemplateColumns="1fr" gridGap={12} alignItems="start">
            {posts?.map((post) => (
              <GuideItem key={post.id} post={post} />
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Author;
