import React, { useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { X } from '@styled-icons/boxicons-regular/X';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Grid from '@northflank/components/Grid';
import Heading from '@northflank/components/Heading';
import Input from '@northflank/components/Input';
import Label from '@northflank/components/Label';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';

import PreHeading from '../../components/PreHeading';
import topog from '../../components/Topog';

const StackCard = ({ stack }) => {
  const {
    template: { description, name },
    attributes: {
      // stack_categories: { data },
      internalId,
      header: {
        data: {
          attributes: { url },
        },
      },
    },
  } = stack;

  return (
    <Link to={`/stacks/${internalId}`}>
      <Box
        height="100%"
        bg="grey9"
        border="1px solid"
        borderColor="grey6"
        borderRadius={2}
        boxShadow="dropDown"
        p={8}
        _css={{
          transition: '150ms',
          '&:hover': {
            bg: 'grey10',
            borderColor: 'grey5',
          },
        }}
      >
        <Box
          variant="flex"
          alignItems="center"
          mb={5}
          pb={6}
          borderBottom="1px solid"
          borderColor="grey6"
        >
          <Box
            bg="grey11"
            width="32px"
            height="32px"
            border="1px solid"
            borderColor="grey5"
            borderRadius={2}
            mr={7}
            _css={{
              backgroundImage: `url(${url})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
          <Text fontSize={3} fontWeight={500} color="grey1">
            {name}
          </Text>
        </Box>
        {/* <Box mb={3} py={2} px={1}> */}
        {/*  {data?.map((c) => ( */}
        {/*    <Label key={c.attributes.title} color="grey1"> */}
        {/*      {c.attributes.title} */}
        {/*    </Label> */}
        {/*  ))} */}
        {/* </Box> */}
        <Text
          mt={8}
          fontSize={3}
          color="grey2"
          css={{
            display: '-webkit-box',
            'line-clamp': '4',
            '-webkit-line-clamp': '4',
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
          }}
        >
          {description}
        </Text>
      </Box>
    </Link>
  );
};

const Stacks = ({ stacks = [], categories }) => {
  const { _search } = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(_search);
  const category = params.get('category');
  const search = params.get('search');

  const [searchInputValue, setSearchInputValue] = useState(search);

  const filteredStacks = useMemo(() => {
    if (category) {
      return stacks.filter(
        (stack) =>
          stack.attributes?.stack_categories?.data.some((attributes) => {
            return attributes.slug === category;
          }) &&
          (search
            ? stack.template.name
                .toLowerCase()
                .includes(search.toLowerCase()) ||
              stack.template.description
                .toLowerCase()
                .includes(search.toLowerCase())
            : true)
      );
    }
    if (search) {
      return stacks.filter(
        (stack) =>
          stack.template.name.toLowerCase().includes(search.toLowerCase()) ||
          stack.template.description
            .toLowerCase()
            .includes(search.toLowerCase())
      );
    }
    return stacks;
  }, [category, search]);

  const handleSearch = (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    const query = form.get('search');
    history.push(`/stacks?search=${query}`);
  };

  return (
    <>
      <SEO
        title="Stack Templates"
        description="Deploy your favourite application stacks in a single click with our curated array of pre-built templates."
      />
      <Box
        maxWidth="1920px"
        mx="auto"
        background={[
          'none',
          'none',
          `radial-gradient(40% 400px at 40% 400px, rgba(0, 147, 255, 0.05) 0%, #151C28 100%),
        radial-gradient(circle at 40% 100px, rgba(0, 0, 0, 0) 0%, #151C28 55%), ${topog}`,
        ]}
        minHeight="calc(100vh - 80px - 340px)"
      >
        <Box variant="bounding">
          <Box width={[1, 1, 1 / 2]} mb={13}>
            <PreHeading mb={6}>One-click deployments</PreHeading>
            <Heading as="h1" fontSize={[6, 6, 7]} color="grey1" mb={8}>
              Stack Templates
            </Heading>
            <Text fontSize={4} color="grey2">
              Curated templates to deploy your favourite application stacks on
              Northflank in a single click.
            </Text>
          </Box>
          <Box as="form" onSubmit={handleSearch} mb={10}>
            <Box variant="flex" width={['100%', '100%', '50%']}>
              <Input
                name="search"
                value={searchInputValue}
                onChange={(e) => setSearchInputValue(e.target.value)}
                placeholder="Search stack templates"
                width="100%"
                wrapperProps={{ flexGrow: 1, mr: 6 }}
                internalButton={
                  search && (
                    <Button
                      type="button"
                      variant={['icon', 'noBorder']}
                      onClick={() => {
                        history.push('/stacks');
                        setSearchInputValue('');
                      }}
                    >
                      <X size={16} />
                    </Button>
                  )
                }
              />
              <Button variant="primary">Search</Button>
            </Box>
          </Box>
          <Box mb={14}>
            <Text
              color="grey3"
              fontWeight={500}
              fontSize={2}
              textTransform="uppercase"
              mb={7}
            >
              Categories
            </Text>
            <Box
              variant="flex"
              flexWrap="wrap"
              width="100%"
              overflowX="auto"
              pb={3}
            >
              <Box
                _css={{
                  '&:hover': { textDecoration: 'underline' },
                  'white-space': 'nowrap',
                }}
                mt={4}
              >
                <Link to="/stacks">
                  <Box color="grey1">
                    <Label
                      containerProps={{ maxHeight: '32px' }}
                      px={8}
                      py={4}
                      mr={4}
                      fontSize={3}
                      fontWeight={500}
                    >
                      All
                    </Label>
                  </Box>
                </Link>
              </Box>
              {categories?.map((c) => (
                <Box
                  key={`category-${c.attributes.slug}`}
                  _css={{
                    '&:hover': { textDecoration: 'underline' },
                    'white-space': 'nowrap',
                  }}
                  mt={4}
                >
                  <Link to={`/stacks?category=${c.attributes.slug}`}>
                    <Box color="grey1">
                      <Label
                        key={c.attributes.title}
                        color={
                          category === c.attributes.slug ? 'primary' : 'grey1'
                        }
                        containerProps={{ maxHeight: '32px' }}
                        px={8}
                        py={4}
                        mr={4}
                        fontSize={3}
                        fontWeight={500}
                      >
                        {c.attributes.title}
                      </Label>
                    </Box>
                  </Link>
                </Box>
              ))}
            </Box>
          </Box>
          <Box _css={{ '> * + *': { mt: 12 } }}>
            <Grid
              gridTemplateColumns={[
                '1fr',
                '1fr',
                'repeat(2, 1fr)',
                'repeat(4, 1fr)',
              ]}
              gridGap={8}
              alignItems="stretch"
            >
              {filteredStacks?.length ? (
                <>
                  {filteredStacks.map((stack) => (
                    <StackCard
                      key={`stack-${stack.attributes.internalId}`}
                      stack={stack}
                    />
                  ))}
                </>
              ) : (
                <Text fontSize={4} color="grey3">
                  There is nothing here yet.
                </Text>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Stacks;
