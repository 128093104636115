import React, { useEffect, useState } from 'react';

import slugify from 'slugify';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Heading from '@northflank/components/Heading';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';

import Breadcrumbs from '../../../components/Breadcrumbs';
import CloudProviderGpuTable from '../../../components/CloudProviders/CloudProviderGpuTable';
import CloudProviderHeader from '../../../components/CloudProviders/CloudProviderHeader';
import CloudProvidersNav from '../../../components/CloudProviders/CloudProvidersNav';
import { cloudProviderOptions } from '../../../components/CloudProviders/utility/cloudProviderOptions';
import { FeaturesWrapper } from '../../../components/FeaturePageWrapper';
import { Section } from '../provider';

const GPUs = ({ providerGpus }) => {
  const [isMobile, setIsMobile] = useState(false);
  const options = Object.keys(providerGpus);
  const cloudProviders = cloudProviderOptions.filter((p) =>
    options.includes(p.value)
  );

  useEffect(() => {
    const match = window.matchMedia('(max-width: 768px)');
    const query = (m) => {
      setIsMobile(m.matches);
    };
    query(match);
    match.addListener(query);
  }, []);

  const navSubItems = cloudProviders.map((provider) => {
    return {
      icon: provider.icon,
      title: `${provider.label} GPU Instances`,
    };
  });

  const navItem = [
    {
      title: `GPUs on Northflank`,
      href: `/cloud/gpus`,
      subItems: navSubItems,
    },
  ];

  const providersList = cloudProviders
    .map((p, i) =>
      i === cloudProviders.length - 1 ? `and ${p.label}` : p.label
    )
    .join(', ');

  return (
    <>
      <SEO
        title="GPU Instances | Cloud Providers"
        description={`Deploy GPU workloads for AI, machine learning, and HPC workloads on your ${providersList} accounts.`}
      />
      <CloudProvidersNav />
      <FeaturesWrapper cloudProviderPage customNavItems={navItem}>
        <Box variant="bounding">
          <Breadcrumbs mb={6} />
          <Heading as="h1" color="grey1" fontSize={[5, 5, 6, 6]} mb={8}>
            GPU instances on Northflank
          </Heading>
          <Text fontSize={4} mediaQuery mb={8}>
            Deploy GPU workloads into your own cloud provider account with
            Northflank Bring Your Own Cloud (BYOC) for AI, machine learning, and
            HPC workloads.
          </Text>
          <Text color="grey2" fontSize={3} mediaQuery mb={13}>
            Access GPU instances on {providersList} on your Northflank account.
          </Text>
          <a
            href="https://app.northflank.com/s/account/cloud/clusters/new"
            target="_blank"
            style={{ display: 'inline-block' }}
            rel="noreferrer"
          >
            <Button variant={['large', 'primary']}>Deploy GPUs now</Button>
          </a>
        </Box>
        {cloudProviders.length
          ? cloudProviders.map((provider) => (
              <Section
                key={provider.value}
                anchor={`${slugify(provider.label, { lower: true })}-gpu-instances`}
              >
                <CloudProviderHeader option={provider} />
                <CloudProviderGpuTable
                  nodes={providerGpus[provider.value]}
                  providerId={provider.value}
                  isMobile={isMobile}
                />
              </Section>
            ))
          : null}
      </FeaturesWrapper>
    </>
  );
};

export default GPUs;
