import React, { useEffect, useRef, useState } from 'react';

import { CopyAlt } from '@styled-icons/boxicons-regular/CopyAlt';
import PropTypes from 'prop-types';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import { copyToClipboard } from '@northflank/components/CopyToClipboard';
import Icon from '@northflank/components/Icon';
import Label from '@northflank/components/Label';
import Text from '@northflank/components/Text';
import Tooltip from '@northflank/components/Tooltip';

const WrapTooltip = ({
  tooltipText,
  text,
  noTooltip,
  showTooltip,
  alwaysShowTooltip,
  children,
  ...rest
}) =>
  noTooltip || (!showTooltip && !alwaysShowTooltip) ? (
    children
  ) : (
    <Tooltip useContext html={tooltipText || text || ''} {...rest}>
      {children}
    </Tooltip>
  );

const LabelWithIcon = ({
  text,
  color,
  icon,
  tooltip,
  tooltipText,
  iconColor,
  iconSize,
  children,
  alwaysShowTooltip,
  neverCollapse,
  iconDisplay,
  rightAlignIcon,
  hover,
  ellipse,
  wrapperProps = { overflow: 'hidden' },
  noTooltip,
  iconOnly,
  showCopyButton,
  tooltipProps,
  noEllipsis,
  ...props
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const match = window.matchMedia('(max-width: 1480px)');
    const query = (m) => {
      setShowTooltip(m.matches);
    };
    query(match);
    match.addListener(query);
  }, []);

  const textRef = useRef();

  return (
    <Box display="inline-block" maxHeight="24px" {...wrapperProps}>
      <WrapTooltip
        tooltipText={tooltipText}
        text={text}
        noTooltip={noTooltip}
        showTooltip={showTooltip}
        alwaysShowTooltip={alwaysShowTooltip}
        tooltipProps={tooltipProps}
      >
        <Label
          hover={hover}
          alignItems="center"
          px={iconOnly ? 2 : undefined}
          {...props}
        >
          {icon && !rightAlignIcon && (
            <Icon
              color={iconColor || 'grey3'}
              Content={icon}
              Size={iconSize || 16}
              display={iconDisplay || 'block'}
              mr={iconOnly ? 0 : neverCollapse ? 3 : [0, 0, 3]}
              lineHeight={1}
            />
          )}
          {children}
          {text && !iconOnly && (
            <Text
              ref={textRef}
              variant={!noEllipsis ? 'ellipse' : undefined}
              color={color || 'grey2'}
              mediaQuery
              display={
                neverCollapse
                  ? 'block'
                  : ['none', 'none', 'block', 'block', 'block']
              }
              ml={rightAlignIcon ? 2 : 0}
              lineHeight="14px"
            >
              {text}
            </Text>
          )}
          {showCopyButton && (
            <Button
              onClick={(e) => {
                copyToClipboard(textRef, e, tooltipText);
              }}
              variant={['icon', 'noBorder', 'noBackground']}
              minWidth="16px"
              minHeight="16px"
              ml={3}
            >
              <CopyAlt size={12} />
            </Button>
          )}
          {icon && rightAlignIcon && (
            <Icon
              color={iconColor || 'grey3'}
              Content={icon}
              Size={iconSize || 16}
              display={iconDisplay || 'block'}
              ml={3}
              mb={1}
            />
          )}
        </Label>
      </WrapTooltip>
    </Box>
  );
};

LabelWithIcon.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.element,
  tooltip: PropTypes.bool,
  tooltipText: PropTypes.string,
  iconColor: PropTypes.string,
  children: PropTypes.node,
};

export default LabelWithIcon;
