import { STRIPE_BILLING_KEYS } from '../data';

export class InvoicePreviewUtils {
  constructor({ prices }) {
    this.prices = prices;
  }

  getGroupedLineItems(lineItems = [], paasUsage = {}, byocUsage = {}) {
    const meteredPricesCloud = [
      this.prices.STRIPE_HOSTING_METERED_PRICE,
      this.prices.STRIPE_NETWORK_EGRESS_PRICE,
      this.prices.STRIPE_HTTP_REQUESTS_PRICE,
    ];

    const meteredPricesByoc = [this.prices.STRIPE_BYOC_METERED_PRICE];

    const meteredPricesOther = [
      this.prices.STRIPE_LOG_SINK_PRICE,
      this.prices.STRIPE_EGRESS_IP_PRICE,
    ];

    const getItemTotal = (line) => {
      if (line.price.id === this.prices.STRIPE_HOSTING_METERED_PRICE) {
        return paasUsage?.price?.total ?? 0;
      }
      if (line.price.id === this.prices.STRIPE_BYOC_METERED_PRICE) {
        return byocUsage?.price?.total ?? 0;
      }
      return (line.quantity ?? 0) * (line.price.unit_amount ?? 0);
    };

    const meteredPrices = [...meteredPricesCloud, ...meteredPricesByoc, ...meteredPricesOther];

    const filterAddPrice = (prices) =>
      lineItems
        ?.filter((l) => prices.includes(l.price.id))
        ?.map((l) => ({ ...l, total: getItemTotal(l) }));

    // Get metered byoc line items
    const meteredLinesByoc = filterAddPrice(meteredPricesByoc);

    // Get metered cloud line items
    const meteredLinesCloud = filterAddPrice(meteredPricesCloud);

    // Get other metered items
    const meteredLinesOther = filterAddPrice(meteredPricesOther);

    // Filter BYOC & PaaS metered items as well as any item which is 0
    const fixedSubscriptionsLines = lineItems
      .filter((l) => !meteredPrices.includes(l.price.id))
      .map((l) => ({ ...l, total: getItemTotal(l) }));

    // TODO: review
    const usageTotalToRemove = 0; // (paasUsage?.price?.total ?? 0) + (byocUsage?.price?.total ?? 0);
    const subTotal =
      [
        ...meteredLinesByoc,
        ...meteredLinesCloud,
        ...meteredLinesOther,
        ...fixedSubscriptionsLines,
      ].reduce((a, l) => a + l.total, 0) - usageTotalToRemove;

    return {
      meteredLinesByoc,
      meteredLinesCloud,
      meteredLinesOther,
      fixedSubscriptionsLines,
      subTotal,
    };
  }

  getGroupInvoice(invoicePreview, customer) {
    const { tax, total, paasUsage, byocUsage } = invoicePreview || {};

    const lineItems = customer?.subscriptions
      ?.find((s) => s.metadata.key === STRIPE_BILLING_KEYS.subscription.key)
      ?.items?.data?.filter((l) => l);

    const { meteredLinesOther, meteredLinesCloud, fixedSubscriptionsLines, subTotal } =
      this.getGroupedLineItems(lineItems, paasUsage, byocUsage);

    return { meteredLinesOther, meteredLinesCloud, fixedSubscriptionsLines, subTotal };
  }

  getLineName(line, platformBillingPlans = []) {
    const getPlanName = () => {
      // Check if price id belongs to a BYOC plan
      if (line?.price?.id) {
        const billingPlan = platformBillingPlans.find((p) => line.price.id === p.stripePriceId);
        if (billingPlan) return `Plan - ${billingPlan.name}`;
      }

      switch (line?.price?.id) {
        case this.prices.STRIPE_HOSTING_METERED_PRICE:
          return 'Cloud Runtime';
        case this.prices.STRIPE_NETWORK_EGRESS_PRICE:
          return 'Networking Egress';
        case this.prices.STRIPE_EGRESS_IP_PRICE:
          return 'Static Egress IP';
        case this.prices.STRIPE_HTTP_REQUESTS_PRICE:
          return 'HTTP Requests';
        case this.prices.STRIPE_SUPPORT_PRICE:
          return 'Support';
        case this.prices.STRIPE_ENTERPRISE_FEATURE_SET_PRICE:
          return 'Enterprise Feature Set';
        case this.prices.STRIPE_INFRASTRUCTURE_COMMITMENT_PRICE:
          return 'Infrastructure Commitment';
        case this.prices.STRIPE_LOG_SINK_PRICE:
          return 'Log Sinks';
        case this.prices.STRIPE_BYOC_PRICE:
          return 'Clusters';
        case this.prices.STRIPE_BYOC_METERED_PRICE:
          return 'Cluster, CPU & Memory Spend';
        default:
          return 'Other';
      }
    };

    const planName = getPlanName();

    if (line?.proration?.amount !== 0) {
      if (line.total < 0) return `Proration: Unused time - ${planName}`;

      return `${planName}`;
    }

    return planName;
  }
}
