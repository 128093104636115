export const regionNames = [
  {
    value: 'All',
    label: 'All',
  },
  {
    value: 'EMEA',
    label: 'EMEA',
  },
  {
    value: 'Americas',
    label: 'Americas',
  },
  {
    value: 'Asia Pacific',
    label: 'Asia Pacific',
  },
];
