import css from '@styled-system/css';
import styled from 'styled-components';

import Icon from '@northflank/components/Icon';

const IconWithGlow = styled(Icon)(() =>
  css({
    position: 'relative',
    '&::before': {
      content: '""',
      display: 'block',
      width: '100%',
      height: '100%',
      position: 'absolute',
      bg: 'success',
      borderRadius: 5,
      opacity: 0,
      filter: 'blur(16px)',
      transition: '150ms',
    },
  })
);

export default IconWithGlow;
