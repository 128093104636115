import Prism from 'prismjs';

import React, { useCallback, useContext, useEffect, useState } from 'react';

import '../util/line-numbers';

import { LinkExternal } from '@styled-icons/boxicons-regular/LinkExternal';
import css from '@styled-system/css';
import { transparentize } from 'polished';
import styled, { ThemeContext } from 'styled-components';
import { border, layout, space, typography } from 'styled-system';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Icon from '@northflank/components/Icon';
import Image from '@northflank/components/Image';
import IconWithGlow from '@northflank/components/marketing/IconWithGlow';
import Text from '@northflank/components/Text';

export const Tab = styled.button(
  ({ theme, active }) =>
    css({
      appearance: 'none',
      display: 'flex',
      alignItems: 'center',
      bg: active ? transparentize(0.8, theme.colors.success) : 'transparent',
      color: active ? 'success' : 'grey2',
      fontFamily: 'body',
      fontSize: 2,
      whiteSpace: 'nowrap',
      border: 0,
      borderRadius: 2,
      px: 6,
      py: 4,
      cursor: 'pointer',
      transition: '150ms',
      svg: {
        fill: active ? 'success' : 'grey3',
        transition: '150ms',
      },
      '&:hover': {
        bg: active ? transparentize(0.8, theme.colors.success) : 'grey8',
        svg: {
          fill: 'success',
        },
      },
    }),
  ({ active }) => `
    ${IconWithGlow} {
      &::before {
        opacity: ${active ? 0.5 : 0};
      }
    }
    &:hover {
      ${IconWithGlow} {
        &::before {
          opacity: 0.5;
        }
      }
    }
  `,
  typography,
  space,
  layout,
  border
);

const PrismCode = ({ code }) => {
  const callback = useCallback((node) => {
    if (node !== null) {
      Prism.highlightElement(node);
    }
  }, []);

  return (
    <Box
      ref={callback}
      as="code"
      fontFamily="monospace"
      lineHeight={1.2}
      color="grey2"
    >
      {code}
    </Box>
  );
};

const FeatureSwitcher = ({ items, cycle, interval = 5000, ...rest }) => {
  const [selected, setSelected] = useState(0);
  const [autoChange, setAutoChange] = useState(false);

  const theme = useContext(ThemeContext);

  useEffect(() => {
    if (cycle) setAutoChange(true);

    const tick = setInterval(() => {
      setSelected((s) => (s + 1) % items.length);
    }, interval);

    if (!autoChange) clearInterval(tick);

    return () => {
      clearInterval(tick);
    };
  }, [autoChange]);

  const scrollTo = (index) => {
    const el = document.querySelector(`#FeatureSwitcher-Tab-${index}`);
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
  };

  return (
    <Box
      background={`radial-gradient(circle at 60% 50%, ${theme.colors.grey6} 0%, ${theme.colors.grey10} 70%)`}
      borderRadius={3}
      border="1px solid"
      borderColor="grey5"
      boxShadow="dropDown"
      {...rest}
    >
      <Box
        variant="flex"
        alignItems="center"
        borderBottom="1px solid"
        borderBottomColor="grey5"
        p={7}
        mb={10}
      >
        <Box
          width={[1, 1, 2 / 3, 1]}
          variant="flex"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
          _css={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            'scrollbar-width': 'none',
          }}
          mx={[-3, 'auto', 'auto', -3]}
          my={-3}
        >
          {items.map((item, i) => (
            <Tab
              key={item.label}
              id={`FeatureSwitcher-Tab-${i}`}
              onClick={() => {
                scrollTo(i);
                setSelected(i);
                setAutoChange(false);
              }}
              active={i === selected}
              m={3}
            >
              {item.icon && (
                <IconWithGlow Content={item.icon} Size={16} mr={4} />
              )}
              {item.label}
            </Tab>
          ))}
        </Box>
      </Box>

      <Box
        display={['block', 'block', 'grid']}
        gridTemplateColumns={['1fr', '1fr', '40% 60%']}
        gridGap={9}
        alignItems="center"
        width={['100%', '100%', 'calc(100% - 20px)']}
      >
        <Box px={[9, 9, 12]} mb={10}>
          <Text color="grey1" fontSize={4} fontWeight={500} mb={7}>
            {items[selected].heading}
          </Text>
          {items[selected].body}
          {items[selected].docsLink?.api && (
            <Box variant="flex" alignItems="center">
              <Box
                as="a"
                href={items[selected].docsLink.api}
                target="_blank"
                display="inline-block"
                mt={10}
              >
                <Button variant="primary">View API docs</Button>
              </Box>
            </Box>
          )}
        </Box>
        {items[selected].image && (
          <Image
            src={items[selected].image}
            borderRadius={3}
            width={['calc(100vw - 20px)', 'calc(100vw - 20px)', '100%']}
            ml={['-15px', '-15px', -9]}
            mr={['15px', '15px', 9]}
            mb={[0, 0, -12]}
          />
        )}
        {items[selected].video && (
          <Box
            as="video"
            autoPlay
            loop
            muted
            borderRadius={3}
            boxShadow="dropDown"
            width={['calc(100vw - 20px)', 'calc(100vw - 20px)', '100%']}
            ml={['-15px', '-15px', -9]}
            mr={['15px', '15px', 9]}
            mb={[0, 0, -12]}
          >
            <source src={items[selected].video} />
          </Box>
        )}
        {items[selected].code && (
          <Box
            bg="grey11"
            borderRadius={3}
            border="1px solid"
            borderColor="grey5"
            boxShadow="dropDown"
            width={['calc(100vw - 20px)', 'calc(100vw - 20px)', '100%']}
            ml={['-15px', '-15px', -9]}
            mr={['15px', '15px', 9]}
            mb={[0, 0, -12]}
          >
            <Box
              as="pre"
              maxHeight="275px"
              minHeight="275px"
              overflow="auto"
              className="line-numbers language-javascript"
              _css={{
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                'scrollbar-width': 'none',
              }}
            >
              <PrismCode key={Math.random()} code={items[selected].code} />
            </Box>
            {items[selected].cmd && (
              <Box
                bg="grey9"
                p={8}
                borderTop="1px solid"
                borderColor="grey5"
                borderBottomLeftRadius={3}
                borderBottomRightRadius={3}
              >
                <Text fontSize={0} fontWeight={600} mb={3}>
                  VIA COMMAND LINE
                </Text>
                <Text fontFamily="monospace" color="grey3">
                  ${' '}
                  <Text as="span" color="grey2">
                    {items[selected].cmd}
                  </Text>
                </Text>
              </Box>
            )}
            {items[selected].docsLink?.app && (
              <Box
                bg="grey9"
                p={8}
                borderTop="1px solid"
                borderColor="grey5"
                borderBottomLeftRadius={3}
                borderBottomRightRadius={3}
                display="flex"
                alignItems="center"
              >
                <Text fontSize={0} fontWeight={600} lineHeight={1} mr={7}>
                  VIA THE UI
                </Text>
                <Box
                  as="a"
                  href={items[selected].docsLink.app}
                  target="_blank"
                  lineHeight={1}
                  display="flex"
                  alignItems="center"
                  color="grey2"
                  _css={{
                    '&:hover': {
                      color: 'grey1',
                    },
                  }}
                >
                  View the docs
                  <Icon Content={LinkExternal} Size={14} ml={3} />
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FeatureSwitcher;
