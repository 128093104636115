export const freeProjectMinimumBackupIntervalHours = 1;
// minimum amount of time between successful manual backup in ms
export const freeProjectMinimumBackupInterval =
  freeProjectMinimumBackupIntervalHours * 60 * 60 * 1000;

export enum BackupScheduleIntervals {
  WEEKLY = 'weekly',
  DAILY = 'daily',
  HOURLY = 'hourly',
}

export const allowedFreePlanBackupScheduleFrequencies = [BackupScheduleIntervals.WEEKLY];

export enum AddonActions {
  PAUSE = 'pause',
  RESUME = 'resume',
  REDEPLOY = 'redeploy',
  FORCE_APPLY = 'force-apply',
  FULL_RESET = 'full-reset',
  BACKUP = 'backup',
  RESTORE = 'restore',
  VERSION_UPGRADE = 'versionUpgrade',
}

export enum AddonBackupTypes {
  NATIVE = 'sameAddon',
  SNAPSHOT = 'snapshot',
  GLOBAL = 'global',
  IMPORT_LINK = 'externalDump',
  IMPORT_UPLOAD = 'fileUpload',
  IMPORT_CONNECTION_STRING = 'liveInstance',
}

export enum AddonBackupCompressionTypes {
  GZIP = 'gz',
  ZSTD = 'zstd',
}

export const redisMaxMemoryPolicyOptions = [
  'noeviction',
  'allkeys-lru',
  'allkeys-lfu',
  'volatile-lru',
  'volatile-lfu',
  'allkeys-random',
  'volatile-random',
  'volatile-ttl',
];

export const postgresWalLevelOptions = ['replica', 'logical']; // minimal disable to ensure replication works properly

export const redisMaxMemoryPolicyDefaultSetting = 'noeviction';
export const postgresWalLevelDefaultSetting = 'replica';

export const addonBackupTypesWithLogs = ['liveInstance', 'sameAddon', 'externalDump'];

export const addonRestoreTypesWithLogs = ['dump-restore'];

export const redisSentinelPlanResources = {
  cpuResource: 0.1,
  ramResource: 100,
};

export const uiRelevantActions = [
  AddonActions.PAUSE,
  AddonActions.BACKUP,
  AddonActions.REDEPLOY,
  AddonActions.RESUME,
  AddonActions.RESTORE,
  AddonActions.FULL_RESET,
  AddonActions.VERSION_UPGRADE,
];

export const addonActionLabels = {
  [AddonActions.PAUSE]: {
    name: 'Pause',
    description: 'Addon will be paused',
  },
  [AddonActions.BACKUP]: {
    name: 'Backup',
    description: 'A backup will be made',
  },
  [AddonActions.REDEPLOY]: {
    name: 'Redeploy',
    description: 'Addon will be redeployed',
  },
  [AddonActions.RESUME]: {
    name: 'Resume',
    description: 'Addon will be resumed',
  },
  [AddonActions.RESTORE]: {
    name: 'Restore',
    description: 'A restore will be made',
  },
  [AddonActions.FULL_RESET]: {
    name: 'Reset',
    description: 'Addon will be reset',
  },
  [AddonActions.VERSION_UPGRADE]: {
    name: 'Upgrade',
    description: 'Addon will be upgraded',
  },
};
