import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Grid from '@northflank/components/Grid';
import Heading from '@northflank/components/Heading';
import Input from '@northflank/components/Input';
import SEO from '@northflank/components/marketing/SEO';
import Select from '@northflank/components/Select';
import Text from '@northflank/components/Text';

import topog from '../components/Topog';

const Contact = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData(e.target);

    const token = await executeRecaptcha('marketing_contact');

    try {
      const res = await fetch(`/api/send-contact-message`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: form.get('email'),
          category: form.get('category'),
          message: form.get('message'),
          token,
        }),
      });

      if (res.ok) {
        res.json().then((data) => {
          if (data.success) {
            setError(null);
            setSuccess(true);
          }
        });
      } else {
        res.json().then((data) => {
          setError(JSON.stringify(data));
          setSuccess(false);
        });
      }
    } catch (err) {
      setError(err.message);
      setSuccess(false);
    }
  };

  return (
    <>
      <SEO
        title="Contact Us"
        description="Please don’t hesitate to get in touch with us if you have a query or want to give some feedback."
      />
      <Box
        maxWidth="1920px"
        mx="auto"
        background={[
          'none',
          'none',
          `radial-gradient(30% 40% at 40% 40%, rgba(0, 147, 255, 0.05) 0%, #151C28 100%),
        radial-gradient(circle at 40% 30%, rgba(0, 0, 0, 0) 0%, #151C28 55%), ${topog}`,
        ]}
      >
        <Box variant="bounding" maxWidth="850px!important">
          <Heading fontSize={[6, 6, 7]} color="grey1" mb={6}>
            Contact us
          </Heading>
          <Text fontSize={4} color="grey3" mb={[12, 12, 13]}>
            Please don’t hesitate to get in touch with us if you have a query or
            want to give some feedback. Alternatively you can email us at{' '}
            <a href="mailto:contact@northflank.com">contact@northflank.com</a>.
          </Text>
          {!success && (
            <form onSubmit={handleSubmit}>
              <Grid
                gridTemplateColumns={['1fr', '1fr', '1fr 1fr']}
                gridGap={8}
                mb={8}
              >
                <Input
                  type="email"
                  name="email"
                  label="Your email address"
                  placeholder="name@example.com"
                  required
                />
                <Select
                  name="category"
                  options={[
                    {
                      value: 'general',
                      label: 'General',
                    },
                    {
                      value: 'support',
                      label: 'Support',
                    },
                    {
                      value: 'bug',
                      label: 'Bug report',
                    },
                    {
                      value: 'feature',
                      label: 'Feature request',
                    },
                    {
                      value: 'other',
                      label: 'Other',
                    },
                  ]}
                  label="Type of query"
                  required
                  noTarget
                />
              </Grid>
              <Input
                rows="10"
                name="message"
                label="Message"
                mb={10}
                required
              />
              <Button variant={['primary', 'large']} type="submit">
                Submit
              </Button>
              <Text fontSize={0} color="grey4" mt={10}>
                This site is protected by reCAPTCHA and the Google{' '}
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a
                  href="https://policies.google.com/terms"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Service
                </a>{' '}
                apply.
              </Text>
              {error && (
                <Text fontSize={3} color="danger" mt={10}>
                  Sorry, there was an error sending your message. Please try
                  again soon!
                </Text>
              )}
            </form>
          )}
          {success && (
            <Text fontSize={4} color="success">
              Thanks! Your message was submitted successfully. We’ll get back to
              you as soon as we can.
            </Text>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Contact;
