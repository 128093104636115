import React from 'react';

import { getIn, useFormikContext } from 'formik';

import PlanSelect from '@northflank/components/Billing/PlanSelect';
import Box from '@northflank/components/Box';
import FieldLabel from '@northflank/components/FieldLabel';
import { resourcePriceObject } from '@northflank/components/marketing/Pricing/data';
import NumberSelector from '@northflank/components/NumberSelector';
import { maxInstances } from '@northflank/constants-base';
import { byocResourcePriceObject } from '@northflank/pricing';

const Service = ({ resource, formikIndex, plans }) => {
  const { values, setFieldValue, errors } = useFormikContext();

  return (
    <Box>
      <PlanSelect
        label="Plan"
        name={`resources.${formikIndex}.plan`}
        plans={plans}
        onDemand={false}
        northflankResourcesPrices={
          resource.isByoc ? byocResourcePriceObject : resourcePriceObject
        }
        useId
        useInternalId
      />
      <Box mt={7}>
        <FieldLabel mb={6}>Instances</FieldLabel>
        <NumberSelector
          label="Instances"
          options={[...Array(maxInstances + 1).keys()]}
          selected={values.resources[formikIndex]?.instances ?? 1}
          handleSelect={(val) =>
            setFieldValue(`resources.${formikIndex}.instances`, val)
          }
          min={0}
          max={maxInstances}
          error={getIn(errors, `resources.${formikIndex}.instances`)}
          initialValue={1}
          allowCustom
        />
      </Box>
    </Box>
  );
};

export default Service;
