import {
  cloudProviderPrices,
  genericCloudProviderPrices,
  networkEgressPrices,
  resourcePriceObject,
} from '@northflank/components/marketing/Pricing/data';
import { getResourcesFromPlan } from '@northflank/constants-base';
import {
  byocResourcePriceObject,
  calculate1MinPlanPrice,
  calculateCPURate,
  calculateMonthlyAddonPrice,
  calculateMonthlyPlanPrice,
  calculateMonthlyStoragePrice,
  calculateRAMRate,
} from '@northflank/pricing';

export const calculateCpuPrice = (count, isByoc) => {
  let total = 0;

  const managed = calculateCPURate(
    { cpuResource: count },
    false,
    isByoc ? byocResourcePriceObject : resourcePriceObject
  );

  const provider = isByoc
    ? calculateCPURate(
        { cpuResource: count },
        false,
        genericCloudProviderPrices
      )
    : 0;

  total += managed;
  total += provider;

  const cloudProviders = Object.entries(cloudProviderPrices).reduce(
    (acc, [key, priceObj]) => {
      acc[key] = calculateCPURate({ cpuResource: count }, false, priceObj);
      return acc;
    },
    {}
  );

  return { managed, provider, total, cloudProviders };
};

export const calculateMemoryPrice = (count, isByoc) => {
  let total = 0;

  const managed = calculateRAMRate(
    { ramResource: count * 1024 },
    false,
    isByoc ? byocResourcePriceObject : resourcePriceObject
  );

  const provider = isByoc
    ? calculateRAMRate(
        { ramResource: count * 1024 },
        false,
        genericCloudProviderPrices
      )
    : 0;

  total += managed;
  total += provider;

  const cloudProviders = Object.entries(cloudProviderPrices).reduce(
    (acc, [key, priceObj]) => {
      acc[key] = calculateRAMRate(
        { ramResource: count * 1024 },
        false,
        priceObj
      );
      return acc;
    },
    {}
  );

  return { managed, provider, total, cloudProviders };
};

export const calculateServicePrice = (resource, plans) => {
  let total = 0;

  const plan = plans.find((p) => p.internalId === resource.plan);

  const managed =
    calculateMonthlyPlanPrice(
      plan,
      false,
      resource.isByoc ? byocResourcePriceObject : resourcePriceObject
    ) * Number(resource.instances);

  const provider = resource.isByoc
    ? calculateMonthlyPlanPrice(plan, false, genericCloudProviderPrices) *
      Number(resource.instances)
    : 0;

  total += managed;
  total += provider;

  return { managed, provider, total };
};

export const calculateJobPrice = (resource, plans) => {
  let total = 0;

  const plan = plans.find((p) => p.internalId === resource.plan);

  const managed =
    calculate1MinPlanPrice(
      plan,
      false,
      resource.isByoc ? byocResourcePriceObject : resourcePriceObject
    ) * Number(resource.runMinutes);

  const provider = resource.isByoc
    ? calculate1MinPlanPrice(plan, false, genericCloudProviderPrices) *
      Number(resource.runMinutes)
    : 0;

  total += managed;
  total += provider;

  return { managed, provider, total };
};

export const calculateAddonPrice = (resource, plans) => {
  let total = 0;

  if (!resource.kind) return { managed: 0, provider: 0, total: 0 };

  const plan = plans.find((p) => p.internalId === resource.plan);

  const planResources = getResourcesFromPlan(plan);

  const managed = calculateMonthlyAddonPrice(
    planResources.cpu,
    planResources.memory,
    resource.storage,
    resource.storageClass,
    resource.replicas,
    false,
    resource.isByoc ? byocResourcePriceObject : resourcePriceObject
  );

  const provider = resource.isByoc
    ? calculateMonthlyAddonPrice(
        planResources.cpu,
        planResources.memory,
        resource.storage,
        resource.storageClass,
        resource.replicas,
        false,
        genericCloudProviderPrices
      )
    : 0;

  total += managed;
  total += provider;

  return { managed, provider, total };
};

export const calculateVolumePrice = (resource) => {
  let total = 0;

  const managed = calculateMonthlyStoragePrice(
    resource.storage,
    resource.storageClass,
    resource.isByoc ? byocResourcePriceObject : resourcePriceObject
  );

  const provider = resource.isByoc
    ? calculateMonthlyStoragePrice(
        resource.storage,
        resource.storageClass,
        genericCloudProviderPrices
      )
    : 0;

  total += managed;
  total += provider;

  return { managed, provider, total };
};

export const calculateClusterPrice = () => ({
  managed: byocResourcePriceObject.cluster,
  provider: 0,
  total: byocResourcePriceObject.cluster,
});

export const calculateBuildPrice = (resource, plans) => {
  let total = 0;

  const plan = plans.find((p) => p.internalId === resource.plan);

  const managed =
    calculate1MinPlanPrice(
      plan,
      false,
      resource.isByoc ? byocResourcePriceObject : resourcePriceObject
    ) * Number(resource.buildMinutes);

  const provider = resource.isByoc
    ? calculate1MinPlanPrice(plan, false, genericCloudProviderPrices) *
      Number(resource.buildMinutes)
    : 0;

  total += managed;
  total += provider;

  return { managed, provider, total };
};

export const calculateNetworkEgressPrice = (resource) => {
  let total = 0;

  total += networkEgressPrices.gb * Number(resource.gb);
  total += networkEgressPrices.reqs * (Number(resource.reqs) / 1e6);

  return { managed: total, provider: 0, total };
};

export const calculateResourcePrice = (resource, ...args) => {
  switch (resource.type) {
    case 'service':
      return calculateServicePrice(resource, ...args);
    case 'job':
      return calculateJobPrice(resource, ...args);
    case 'addon':
      return calculateAddonPrice(resource, ...args);
    case 'volume':
      return calculateVolumePrice(resource, ...args);
    case 'cluster':
      return calculateClusterPrice(resource, ...args);
    case 'build':
      return calculateBuildPrice(resource, ...args);
    case 'network':
      return calculateNetworkEgressPrice(resource, ...args);
    default:
      return 0;
  }
};

export const calculateClustersPrice = (count, isByoc) => {
  if (!isByoc) {
    return {
      managed: 0,
      provider: 0,
      total: 0,
    };
  }

  const { total } = calculateClusterPrice();
  return {
    managed: total * count,
    provider: genericCloudProviderPrices.cluster * count,
    total: total * count,
  };
};
