export const byocBaseResourcePrices = [
  {
    version: 'v1',
    default: true,
    cluster: 100.0, // per month
    cpu: 2.0, // per month & CPU unit
    memory: 0.3, // per month & GB
    storage: {
      ssd: 0.3,
      hdd: 0.15,
    }, // per month & GB
  },
];

export const byocResourcePriceObject = byocBaseResourcePrices.find((p) => p.default);

export const dollarsToCents = (dollars) => dollars * 100;

export const organizationPlanPrice = dollarsToCents(1000);

export const seatsIncluded = 25;

export const perSeatPrice = dollarsToCents(20);

export const byocClusterPrice = dollarsToCents(byocResourcePriceObject.cluster);

export const byocCPUPrice = dollarsToCents(byocResourcePriceObject.cpu);

export const byocMemoryPrice = dollarsToCents(byocResourcePriceObject.memory);

export const standardBYOCPriceTiers = {
  'clusters': [
    {
      upTo: 'inf',
      unitAmount: byocClusterPrice,
    },
  ],
  'memory': [
    {
      upTo: 'inf',
      unitAmount: byocMemoryPrice,
    },
  ],
  'vcpu': [
    {
      upTo: 'inf',
      unitAmount: byocCPUPrice,
    },
  ],
  'seats': [
    {
      upTo: 'inf',
      unitAmount: perSeatPrice,
    },
  ],
};
