const isChild = (parent, child) => {
  return (
    parent.id !== child.id &&
    !parent.proration &&
    child.proration &&
    parent.plan.id === child.plan.id
  );
};

const isSibling = (line, sibling) => {
  return line.id !== sibling.id && sibling.proration && line.plan.id === sibling.plan.id;
};

// Has an issue if no parent without pro-ration
const getGroupedLines = (lineItems) => {
  const mapOfPricesWithoutParent = {};
  return lineItems
    .reduce((a, l) => {
      const hasParent = lineItems.some((p) => isChild(p, l));
      if (hasParent) return a;

      // If line is a parent
      if (!l.proration) {
        return [
          ...a,
          {
            line: l,
            prorationOnly: false,
            children: lineItems.filter((c) => isChild(l, c)),
          },
        ];
      }

      // If line is a pro-ration amount without parent

      // Only want to aggregate siblings once, so keep track of which prices we have handled
      const priceId = l.price.id;
      if (mapOfPricesWithoutParent[priceId]) return a;
      mapOfPricesWithoutParent[priceId] = true;

      return [
        ...a,
        {
          line: l,
          prorationOnly: true,
          children: [l, ...lineItems.filter((c) => isSibling(l, c))],
        },
      ];
    }, [])
    .map((item) => {
      const prorationAmount = item.children.reduce((a, c) => c.amount + a, 0);

      // Amount already takes discount into account
      const { amount } = item.line;

      const discountAmount = [item.line, ...item.children].reduce(
        (a, i) => i.discount_amounts.reduce((a, c) => c.amount + 0, 0) + a,
        0
      );

      return {
        ...item,
        // Proration amount
        proration: { amount: prorationAmount },
        discount: { amount: discountAmount },
        // Fixed monthly fee
        amount: item.prorationOnly ? prorationAmount : amount,
        total: item.prorationOnly ? prorationAmount : amount + prorationAmount,
      };
    });
};

export class InvoiceUtils {
  constructor({ prices }) {
    this.prices = prices;
  }

  getGroupedLineItems(invoice) {
    const { lineItems = [] } = invoice;
    const meteredPricesCloud = [
      // TODO: add networking, storage, etc.
      this.prices.STRIPE_HOSTING_METERED_PRICE,
      this.prices.STRIPE_NETWORK_EGRESS_PRICE,
      this.prices.STRIPE_HTTP_REQUESTS_PRICE,
    ];

    const meteredPricesByoc = [this.prices.STRIPE_BYOC_METERED_PRICE];

    const meteredPricesOther = [this.prices.STRIPE_LOG_SINK_PRICE];

    const meteredPrices = [...meteredPricesCloud, ...meteredPricesByoc, ...meteredPricesOther];

    // Get metered byoc line items
    const meteredLinesByoc = lineItems.filter(
      (l) => meteredPricesByoc.includes(l.price.id) && l.amount !== 0
    );

    // Get metered cloud line items
    const meteredLinesCloud = lineItems.filter(
      (l) => meteredPricesCloud.includes(l.price.id) && l.amount !== 0
    );

    // Get other metered items
    const meteredLinesOther = lineItems.filter(
      (l) => meteredPricesOther.includes(l.price.id) && l.amount !== 0
    );

    // Filter BYOC & PaaS metered items as well as any item which is 0
    const fixedSubscriptionsLines = lineItems.filter(
      (l) => !meteredPrices.includes(l.price.id) && l.amount !== 0
    );

    const groupedMeteredByocLinesByPrice = getGroupedLines(meteredLinesByoc);
    const groupedMeteredCloudLinesByPrice = getGroupedLines(meteredLinesCloud);
    const groupedMeteredOtherLinesByPrice = getGroupedLines(meteredLinesOther);
    const groupedSubscriptionsLinesByPrice = getGroupedLines(fixedSubscriptionsLines);

    return {
      groupedMeteredByocLinesByPrice,
      groupedMeteredCloudLinesByPrice,
      groupedMeteredOtherLinesByPrice,
      groupedSubscriptionsLinesByPrice,
    };
  }

  getLineName(line, platformBillingPlans = []) {
    const getPlanName = () => {
      if (line.line?.name) return line.line.name;

      // Check if price id belongs to a BYOC plan
      if (line.line?.price?.id) {
        const billingPlan = platformBillingPlans.find(
          (p) => line.line.price.id === p.stripePriceId
        );
        if (billingPlan) return `Plan - ${billingPlan.name}`;
      }

      switch (line.line?.price?.id ?? line.line?.line?.price?.id) {
        case this.prices.STRIPE_HOSTING_METERED_PRICE:
          return 'Cloud Runtime';
        case this.prices.STRIPE_NETWORK_EGRESS_PRICE:
          return 'Networking Egress';
        case this.prices.STRIPE_EGRESS_IP_PRICE:
          return 'Static Egress IP';
        case this.prices.STRIPE_HTTP_REQUESTS_PRICE:
          return 'HTTP Requests';
        case this.prices.STRIPE_SUPPORT_PRICE:
          return 'Support';
        case this.prices.STRIPE_ENTERPRISE_FEATURE_SET_PRICE:
          return 'Enterprise Feature Set';
        case this.prices.STRIPE_INFRASTRUCTURE_COMMITMENT_PRICE:
          return 'Infrastructure Commitment';
        case this.prices.STRIPE_LOG_SINK_PRICE:
          return 'Log Sinks';
        case this.prices.STRIPE_BYOC_PRICE:
          return 'Clusters';
        case this.prices.STRIPE_BYOC_METERED_PRICE:
          return 'Cluster, CPU & Memory Spend';
        default:
          return 'Other';
      }
    };

    const planName = getPlanName();

    if (line?.proration?.amount !== 0) {
      if (line.total < 0) return `Proration: Unused time - ${planName}`;

      return `${planName}`;
    }

    return planName;
  }

  getDiscountInformation(invoice) {
    const totalDiscountAmount =
      invoice?.stripeInvoice?.total_discount_amounts?.reduce((a, v) => a + v.amount, 0) ?? 0;

    return { totalDiscountAmount };
  }

  showPaaSExplorer(line) {
    return line.line.price.id === this.prices.STRIPE_HOSTING_METERED_PRICE;
  }

  showBYOCExplorer(line) {
    return line.line.price.id === this.prices.STRIPE_BYOC_METERED_PRICE;
  }
}
