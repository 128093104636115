import React from 'react';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Heading from '@northflank/components/Heading';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';

import CloudProviderCard from '../../components/CloudProviders/CloudProviderCard';
import { cloudProviderOptions } from '../../components/CloudProviders/utility/cloudProviderOptions';
import GetStartedCta from '../../components/GetStartedCta';
import PreHeading from '../../components/PreHeading';
import topog from '../../components/Topog';

const Cloud = ({ cloudProviders }) => {
  const providers = cloudProviders.filter(
    (p) => p.id !== 'northflank' && p.id !== 'gpus'
  );

  const providersList = providers
    .map((p, i) => (i === providers.length - 1 ? `or ${p.name}` : p.name))
    .join(', ');

  return (
    <>
      <SEO
        title="Cloud Providers"
        description="Deploy Northflank projects across your preferred cloud providers."
      />
      <Box
        maxWidth="1920px"
        mx="auto"
        background={[
          'none',
          'none',
          `radial-gradient(50% 1000px at 65% 400px, rgba(0, 147, 255, 0.05) 0%, #151C28 50%),
        radial-gradient(50% 1000px at 65% 400px, rgba(0, 0, 0, 0) 0%, #151C28 60%), ${topog}`,
        ]}
        mt="-80px"
        pt="80px"
      >
        <Box alignItems="start" maxWidth="max" mx="auto" position="relative">
          <Box variant="bounding" pb="70px!important">
            <PreHeading mb={6}>Compare Providers</PreHeading>
            <Heading as="h1" fontSize={[5, 5, 6, 6]} color="grey1" mb={8}>
              Deploy Into Your Own Cloud Account
            </Heading>
            <Text fontSize={4} mediaQuery mb={13}>
              Deploy into your own {providersList} accounts with Bring Your Own
              Cloud (BYOC). Enjoy all the benefits of Northflank’s platform
              layer while maintaining control over your data residency,
              deployment regions, cloud expenses, and security.
            </Text>
            <a
              href="https://app.northflank.com/s/account/cloud/clusters/new"
              target="_blank"
              style={{ display: 'inline-block' }}
              rel="noreferrer"
            >
              <Button variant={['large', 'primary']}>
                Deploy a cluster now
              </Button>
            </a>
          </Box>
          {cloudProviders.length ? (
            <Box
              display="grid"
              gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
              gridGap="20px"
              alignItems="stretch"
              variant="bounding"
              py={[
                '64px!important',
                '64px!important',
                '64px!important',
                '96px!important',
              ]}
              borderTop="1px solid"
              borderColor="grey6"
            >
              {cloudProviders.map((provider) => (
                <CloudProviderCard
                  key={provider.id}
                  provider={cloudProviderOptions.find(
                    (o) => o.value === provider.id
                  )}
                />
              ))}
            </Box>
          ) : null}
        </Box>
        <Box variant="bounding" borderTop="1px solid" borderColor="grey6">
          <GetStartedCta fontSize="26px" />
        </Box>
      </Box>
    </>
  );
};

export default Cloud;
