import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { Chip } from '@styled-icons/boxicons-regular/Chip';
import { Server } from '@styled-icons/boxicons-regular/Server';
import { transparentize } from 'polished';
import { ThemeContext } from 'styled-components';

import Box from '@northflank/components/Box';
import Grid from '@northflank/components/Grid';
import Heading from '@northflank/components/Heading';
import ExpandableQA from '@northflank/components/marketing/ExpandableQA';
import PricingCalculator from '@northflank/components/marketing/Pricing/Calculator';
import {
  allPlansInclude,
  tiers,
} from '@northflank/components/marketing/Pricing/data';
import FeatureMatrix from '@northflank/components/marketing/Pricing/FeatureMatrix';
import InfraPricing from '@northflank/components/marketing/Pricing/InfraPricing';
import TierItem, {
  CheckListItem,
} from '@northflank/components/marketing/Pricing/TierItem';
import SEO from '@northflank/components/marketing/SEO';
import NorthflankIcon from '@northflank/components/NorthflankIcon';
import SlidingTabs from '@northflank/components/SlidingTabs';
import Text from '@northflank/components/Text';

import graphpaper from '../components/GraphPaper';
import PreHeading from '../components/PreHeading';
import matrix from '../data/featurematrix';

const Page = ({ plans, addonTypes }) => {
  const [calculatorMode, setCalculatorMode] = useState('workload');

  const theme = useContext(ThemeContext);

  return (
    <>
      <SEO
        title="Pricing"
        description="Only pay for the resources your services consume during build and runtime. Scale both horizontally and vertically with no unexpected spend. Teams included at no extra cost."
      />
      <Box
        position="relative"
        pt={['64px', '64px', '80px']}
        mt={['-64px', '-64px', '-80px']}
      >
        <Box
          background={`radial-gradient(circle at 50% 30%, ${transparentize(
            0.82,
            theme.colors.primary
          )} 0%, transparent 60%), linear-gradient(to bottom, transparent 90%, ${
            theme.colors.background
          } 100%), ${graphpaper}`}
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          zIndex={-2}
          opacity="0.66"
        />
        <Box
          id="tiers"
          variant="bounding"
          pb={[12, 12, '80px']}
          pt={[12, 12, '80px']}
        >
          <Box width={[1, 1, 1 / 2]} mx="auto" textAlign="center">
            <PreHeading mb={6}>Transparent, Usage-based Billing</PreHeading>
            <Heading as="h1" fontSize={[6, 6, 7, 7]} color="grey1">
              Northflank Pricing
            </Heading>
          </Box>
        </Box>
        {!!tiers?.length && (
          <Box variant="boundingWide" pt="0px!important">
            <Grid
              gridTemplateColumns={[
                '1fr',
                '1fr',
                `repeat(${tiers.length}, 1fr)`,
              ]}
              gridGap={0}
              boxShadow="dropDown"
            >
              {tiers.map((tier, i) => (
                <TierItem key={`tier-${i}`} tier={tier} />
              ))}
            </Grid>
          </Box>
        )}
        <Box id="pricing" variant="boundingWide" pt="0px!important">
          <Box width={[1, 1, 1 / 2]} mb={[8, 8, 12]}>
            <Heading fontSize={6} color="grey1" mb={8}>
              Infrastructure Pricing
            </Heading>
            <Text fontSize={4} color="grey2">
              Flexible compute plans allow you to deploy correctly sized
              infrastructure from the start. Autoscale in seconds based on
              compute requirements.
            </Text>
          </Box>
          <Grid
            gridTemplateColumns={['1fr', '1fr', '1fr', '2.5fr 1fr']}
            gridGap={12}
            alignItems="start"
          >
            <Box id="plans">
              <InfraPricing plans={plans} Link={Link} isMarketing />
            </Box>
            <Box
              bg="grey9"
              border="1px solid"
              borderColor="grey5"
              borderRadius={2}
              boxShadow="dropDown"
              p={10}
              gridRow={1}
              gridColumn={[1, 1, 1, 2]}
            >
              <Box variant="flex" alignItems="flex-start" mb={8}>
                <Box width="26px" color="grey3" mt="1px" mr={6}>
                  <NorthflankIcon />
                </Box>
                <Heading>All Plans Include</Heading>
              </Box>
              <Box
                as="ul"
                _css={{ listStyle: 'none', '> * + *': { mt: 6 } }}
                mb={10}
              >
                {allPlansInclude.map((item, i) => (
                  <CheckListItem
                    key={`all-plans-${i}`}
                    text={typeof item === 'string' ? item : item?.text}
                    tooltip={
                      typeof item === 'object' ? item?.tooltip : undefined
                    }
                    isMarketing
                  />
                ))}
              </Box>
              <Box as="a" href="#compare">
                <Box
                  bg="grey8"
                  borderRadius={2}
                  p={6}
                  _css={{
                    '&:hover': {
                      bg: 'grey6',
                    },
                  }}
                >
                  <Text color="grey1" textAlign="center">
                    And more &rarr;
                  </Text>
                </Box>
              </Box>
              <Box
                as="a"
                id="pricing-schedule-demo"
                href="https://cal.com/team/northflank/northflank-demo?duration=30"
                target="_blank"
                display="block"
                mt={6}
              >
                <Box
                  bg="grey8"
                  borderRadius={2}
                  p={6}
                  _css={{
                    '&:hover': {
                      bg: 'grey6',
                    },
                  }}
                >
                  <Text color="grey1" textAlign="center">
                    Schedule a demo
                  </Text>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Box>

      <Box variant="bounding" id="calculator">
        <Box width={[1, 1, 1 / 2]} mb={12}>
          <Heading fontSize={6} color="grey1" mb={8}>
            Pricing Calculator
          </Heading>
          <Text fontSize={4} color="grey2">
            Add your required resources to the calculator below to get an
            estimation of your monthly spend on Northflank.
          </Text>
        </Box>
        <SlidingTabs
          options={[
            {
              key: 'workload',
              title: 'Workload',
              titleIcon: Server,
              description:
                'I know each of the components I want to deploy on Northflank',
            },
            {
              key: 'infrastructure',
              title: 'Infrastructure',
              titleIcon: Chip,
              description:
                'I have an idea of the total resources I will consume on Northflank',
            },
          ]}
          selected={calculatorMode}
          handleSelect={(v) => setCalculatorMode(v)}
          border="1px solid"
          borderColor="grey6"
          mb={12}
        />
        <PricingCalculator
          plans={plans}
          addonTypes={addonTypes}
          simple={calculatorMode === 'infrastructure'}
          setCalculatorMode={setCalculatorMode}
        />
      </Box>

      {!!matrix?.length && (
        <Box variant="bounding" id="compare">
          <Box width={[1, 1, 1 / 2]} mb={12}>
            <Heading fontSize={6} color="grey1" mb={8}>
              Full Feature Comparison
            </Heading>
            <Text fontSize={4} color="grey2">
              A detailed comparison of all of the features available across our
              different tiers. Decide which is right for you based on your
              project requirements.
            </Text>
          </Box>
          <FeatureMatrix tiers={tiers} matrix={matrix} />
        </Box>
      )}

      <Box variant="bounding">
        <Heading fontSize={6} color="grey1" textAlign="center" mb={12}>
          Frequently Asked Questions
        </Heading>
        <Box maxWidth={['100%', '100%', '66%']} mx="auto">
          <ExpandableQA
            question="What forms of payment do you accept?"
            answer="Payments are handled by Stripe and we accept Visa, Mastercard, and American Express."
            questionFontSize={[3, 3, 4]}
          />
          <ExpandableQA
            question="How does consumption based pricing work?"
            answer={
              <Text>
                You’ll be billed at the end of your monthly billing cycle based
                on your configured resources and instances, prorated to the
                second of usage. Read our{' '}
                <Link to="/legal/terms">terms of service</Link> for more
                details. Estimates are exclusive of VAT and GST.
              </Text>
            }
            questionFontSize={[3, 3, 4]}
          />
          <ExpandableQA
            question="Will taxes be included in my monthly invoice?"
            answer="We’re required by law to collect taxes in some countries."
            questionFontSize={[3, 3, 4]}
          />
          <ExpandableQA
            question="Am I charged when I enter my credit card?"
            answer="No — when you enter a card we only verify the card. Your card is only charged at the end of the billing cycle."
            questionFontSize={[3, 3, 4]}
          />
          <ExpandableQA
            question="What currency will I be charged in?"
            answer="You will be charged in US dollars."
            questionFontSize={[3, 3, 4]}
          />
        </Box>
      </Box>
    </>
  );
};

export default Page;
