import React from 'react';

import { MinusCircle } from '@styled-icons/boxicons-regular/MinusCircle';
import { PlusCircle } from '@styled-icons/boxicons-regular/PlusCircle';

import Box from '@northflank/components/Box';
import Icon from '@northflank/components/Icon';
import Text from '@northflank/components/Text';

const ExpandableQA = ({
  question,
  answer,
  questionFontSize = 3,
  answerFontSize = 3,
  ...rest
}) => {
  return (
    <Box
      as="details"
      borderTop="1px solid"
      borderColor="grey6"
      p={8}
      _css={{
        '.open': {
          display: 'none',
        },
        '&:open': {
          '.open': {
            display: 'flex',
          },
          '.closed': {
            display: 'none',
          },
        },
        '&:last-child': {
          borderBottom: '1px solid',
          borderColor: 'grey6',
        },
      }}
      {...rest}
    >
      <Box
        as="summary"
        variant="flex"
        alignItems="center"
        _css={{
          cursor: 'pointer',
        }}
      >
        <Icon
          className="closed"
          Content={PlusCircle}
          Size={20}
          color="grey3"
          mr={8}
        />
        <Icon
          className="open"
          Content={MinusCircle}
          Size={20}
          color="grey3"
          mr={8}
        />
        <Text
          fontSize={questionFontSize}
          color="grey0"
          css={{ userSelect: 'none' }}
        >
          {question}
        </Text>
      </Box>
      <Box>
        <Box
          fontSize={answerFontSize}
          color="grey2"
          mt={6}
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      </Box>
    </Box>
  );
};

export default ExpandableQA;
