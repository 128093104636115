import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { Github } from '@styled-icons/boxicons-logos/Github';
import { Gitlab } from '@styled-icons/boxicons-logos/Gitlab';
import { AddToQueue } from '@styled-icons/boxicons-regular/AddToQueue';
import { BoltCircle } from '@styled-icons/boxicons-regular/BoltCircle';
import { Cloud } from '@styled-icons/boxicons-regular/Cloud';
import { CloudUpload } from '@styled-icons/boxicons-regular/CloudUpload';
import { GitBranch } from '@styled-icons/boxicons-regular/GitBranch';
import { Globe } from '@styled-icons/boxicons-regular/Globe';
import { Group } from '@styled-icons/boxicons-regular/Group';
import { LineChart } from '@styled-icons/boxicons-regular/LineChart';
import { ListPlus } from '@styled-icons/boxicons-regular/ListPlus';
import { PlayCircle } from '@styled-icons/boxicons-regular/PlayCircle';
import { Radar } from '@styled-icons/boxicons-regular/Radar';
import { RightArrowAlt } from '@styled-icons/boxicons-regular/RightArrowAlt';
import { SearchAlt2 } from '@styled-icons/boxicons-regular/SearchAlt2';
import { Support } from '@styled-icons/boxicons-regular/Support';
import { WindowAlt } from '@styled-icons/boxicons-regular/WindowAlt';
import { World } from '@styled-icons/boxicons-regular/World';
import { Wrench } from '@styled-icons/boxicons-regular/Wrench';
import { Bitbucket } from '@styled-icons/simple-icons/Bitbucket';
import { transparentize } from 'polished';
import { ThemeContext } from 'styled-components';

import Box from '@northflank/components/Box';
import Grid from '@northflank/components/Grid';
import Heading from '@northflank/components/Heading';
import Icon from '@northflank/components/Icon';
import FeatureCard from '@northflank/components/marketing/FeatureCard';
import {
  FeatureMasonry,
  MasonryCard,
} from '@northflank/components/marketing/FeatureMasonry';
import FeatureNav from '@northflank/components/marketing/FeatureNav';
import RealtimePlatform from '@northflank/components/marketing/Graphics/RealtimePlatform';
import LargeHeading from '@northflank/components/marketing/LargeHeading';
import { HiddenText } from '@northflank/components/marketing/Nav';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';

import GetStartedCta from '../../components/GetStartedCta';
import PreHeading from '../../components/PreHeading';
import topog from '../../components/Topog';

const sections = [
  {
    icon: Wrench,
    name: 'Build',
    title: [
      'Continuous integration for all your code.',
      'Build repositories from all of your favourite VCS platforms using Dockerfiles or Buildpacks.',
    ],
    items: [
      {
        heading: 'All Your Code',
        body: 'Northflank is completely language, framework, and architecture agnostic. Build anything.',
        image: '/images/dockerfile.png',
        link: '/features/build#customise-your-build-process',
      },
      {
        heading: 'CI/CD',
        body: 'Build and deploy every commit you make, or create rules for branches and pull requests.',
        image: '/images/commit-list.png',
        link: '/features/build#implement-continuous-integration',
      },
      {
        heading: 'VCS Integration',
        body: 'Link Northflank with GitHub, Bitbucket, or GitLab (both gitlab.com and self-hosted).',
        image: '/images/integrations.png',
        link: '/features/build#implement-continuous-integration',
      },
    ],
  },
  {
    icon: PlayCircle,
    name: 'Run',
    title: [
      'Make continuous deployment easy.',
      'Deploy your services and jobs from existing builds or images from external registries.',
    ],
    items: [
      {
        heading: 'Services, Jobs, and Databases',
        body: 'Run microservices, configure cron jobs, and deploy stateful addons to store data.',
        image: '/images/service.png',
        link: '/features/run#runtime-environment',
      },
      {
        heading: 'External Images',
        body: 'Deploy public or private Docker images from external registry providers.',
        image: '/images/external-images.png',
        link: '/features/run#runtime-environment',
      },
      {
        heading: 'Secure Environments',
        body: 'Securely store both build args and environment variables to be passed into your containers.',
        image: '/images/env-vars.png',
        link: '/features/run#variables-and-secrets',
      },
    ],
  },
  {
    icon: CloudUpload,
    name: 'Release',
    title: [
      'Release with confidence.',
      'Complex releases on Northflank are made simple with pipelines and release flows.',
    ],
    items: [
      {
        heading: 'Release Pipelines',
        body: 'Pipelines allow you to build advanced workflows for releasing code to different environments.',
        image: '/images/pipeline.png',
        link: '/features/release#pipelines',
      },
      {
        heading: 'Templates',
        body: 'Deploy complex, interdependent infrastructure as code with highly customisable JSON templates.',
        image: '/images/template.png',
        link: '/features/templates',
      },
      {
        heading: 'Highly Configurable Networking',
        body: 'Create public and private HTTP/TCP/UDP ports with (sub)domains, IP policies, and basic auth.',
        image: '/images/ports.png',
        link: '/features/run#networking',
      },
    ],
  },
  {
    icon: AddToQueue,
    name: 'Scale',
    title: [
      'Northflank scales with you.',
      'Scale your resources horizontally and vertically as demand grows. Or, enable auto-scaling and let us handle it for you.',
    ],
    items: [
      {
        heading: 'Scale in Seconds',
        body: 'Scale both vertically and horizontally with automatic load balancing.',
        image: '/images/scaling.png',
        link: '/features/scale',
      },
      {
        heading: 'Auto-Scaling',
        body: 'Configure horizontal auto-scaling based on CPU and memory usage thresholds.',
        image: '/images/autoscaling.png',
        link: '/features/scale#scaling',
      },
      {
        heading: 'Persistent Storage',
        body: 'Store persistent data with addons or attach volumes to services. Increase capacity in a click.',
        image: '/images/volumes.png',
        link: '/features/scale#storage',
      },
    ],
  },
  {
    icon: Radar,
    name: 'Observe',
    title: [
      'Monitor your infrastructure.',
      'Real-time logging and metrics for all your workloads mean you never have to worry about missing important events.',
    ],
    items: [
      {
        heading: 'Logs',
        body: 'Real-time logs allow you to observe and inspect your services immediately.',
        image: '/images/logs.png',
        link: '/features/observe#observability',
      },
      {
        heading: 'Metrics',
        body: 'Keep track of performance with detailed stats on CPU, memory usage, and more.',
        image: '/images/metrics.png',
        link: '/features/observe#observability',
      },
      {
        heading: 'Health Checks',
        body: 'Keep your deployments healthy with HTTP, TCP, and CMD health checks.',
        image: '/images/health-checks.png',
        link: '/features/observe#health-checks',
      },
    ],
  },
  {
    icon: WindowAlt,
    name: 'Platform',
    title: [
      'Making DevOps accessible to all.',
      'We are on a mission to simplify application deployment in the era of cloud native.',
    ],
    items: [
      {
        heading: 'Fully Real-Time',
        body: 'Actions, updates, and changes by all users are instantly reflected in the user interface.',
        image: '/images/team-members-2.png',
        link: '/features/platform#teams-and-organisations',
      },
      {
        heading: 'Powerful RBAC',
        body: 'Fine-tune permissions for team members via our role-based access control system.',
        image: '/images/permissions.png',
        link: '/features/platform#teams-and-organisations',
      },
      {
        heading: 'Granular Billing',
        body: 'Compute resources are billed by the second. Invoices are broken down on a per resource basis.',
        image: '/images/granular-billing.png',
        link: '/features/platform#billing-and-support',
      },
    ],
  },
];

const Features = ({ dbaasPages }) => {
  const theme = useContext(ThemeContext);
  return (
    <>
      <SEO
        title="Features"
        description="Northflank provides a comprehensive suite of features to support you from inception to production. Put your DevOps lifecycle on autopilot."
      />
      <FeatureNav />
      <Box>
        <Box
          background={`radial-gradient(80% 70% at 32.5% 5%, ${transparentize(
            0.8,
            theme.colors.success
          )} 0%, transparent 100%), radial-gradient(circle at 35% 50%, transparent 0%, ${
            theme.colors.grey9
          } 70%), ${topog}, ${theme.colors.grey9}`}
          backgroundPosition="left"
          borderBottom="1px solid"
          borderColor="grey5"
        >
          <Box variant="bounding">
            <Box variant="flex" flexDirection="column" width={[1, 1, 1 / 2]}>
              <PreHeading mb={6}>Your Developer Platform</PreHeading>
              <Heading as="h1" fontSize={[6, 6, 7, 7]} color="grey1" mb={9}>
                Code. Build. Deploy. Release. Repeat.
              </Heading>
              <Text fontSize={5} color="grey3">
                Northflank provides a comprehensive suite of features to support
                you from inception to production. Put your DevOps lifecycle on
                autopilot.
              </Text>
            </Box>
          </Box>
        </Box>
        <Box bg="grey11">
          {sections.map((section, i) => (
            <Box key={`sectionName:${section.name}`}>
              <Box
                variant="bounding"
                position="relative"
                zIndex={0}
                pt={i === 0 ? '96px!important' : undefined}
              >
                <Box
                  bg="primary"
                  width="4px"
                  height="100%"
                  position="absolute"
                  top={0}
                  left={['54px', '54px', '62px']}
                  zIndex={-1}
                />
                <Box
                  bg="primary"
                  width="4px"
                  height="100%"
                  position="absolute"
                  top={0}
                  left={['54px', '54px', '62px']}
                  zIndex={-1}
                  _css={{ filter: 'blur(8px)' }}
                />
                <LargeHeading
                  icon={section.icon}
                  subHeading={section.name}
                  firstLine={section.title[0]}
                  followingLines={section.title[1]}
                  iconBorderRadius={3}
                  iconBoxShadow="none"
                  iconWithLineBoxShadow={`0px 0px 32px 48px ${theme.colors.grey11}`}
                  iconBg={theme.colors.grey11}
                  iconColor="url(#gradient_svg__blueSvgGradient)"
                  mb={14}
                />
                <Grid
                  gridTemplateColumns={['1fr', '1fr', 'repeat(3, 1fr)']}
                  gridGap={8}
                  alignItems="stretch"
                >
                  {section.items.map((item, i) => (
                    <FeatureCard
                      key={i}
                      heading={item.heading}
                      body={item.body}
                      image={item.image}
                      link={item.link}
                    />
                  ))}
                </Grid>
              </Box>
            </Box>
          ))}
        </Box>
        <Box
          background={`linear-gradient(to bottom, ${theme.colors.grey9} 0%, ${theme.colors.background} 100%)`}
        >
          <Box
            maxWidth="1920px"
            mx="auto"
            background={[
              'none',
              'none',
              `radial-gradient(circle at 60% 55%, rgba(0, 147, 255, 0.05) 0%, rgba(0, 0, 0, 0) 40%)`,
            ]}
          >
            <Box variant="bounding">
              <Box
                width={[1, 1, 1 / 2]}
                textAlign="center"
                mx="auto"
                mb={[12, 12, 13]}
              >
                <PreHeading mb={6}>Release, operate, and scale</PreHeading>
                <Heading fontSize={[6, 6, 7]} color="grey1" mb={8}>
                  Anything, Anywhere
                </Heading>
                <Text fontSize={4} color="grey2" mb={12}>
                  From static sites to complex web apps, microservices to APIs,
                  cron jobs to databases — Northflank has you covered.
                  Rationalise your infrastructure and get started in minutes.
                </Text>
              </Box>
              <FeatureMasonry
                gridTemplateColumns={['auto', 'auto', 'repeat(3, 1fr)']}
              >
                <MasonryCard
                  icon={World}
                  title="Universally Suitable"
                  text="From hobby projects to critical production workloads – Northflank caters to your use case. We lower the barrier to entry while pushing the boundaries of what a DevOps platform can do."
                  imageVertical="/images/icons.png"
                  imageProps={{
                    width: ['100%', '100%', 'calc(100% + 64px)'],
                    left: '50%',
                    bottom: '32px',
                    _css: {
                      transform: ['none', 'none', 'translateX(-50%)'],
                    },
                  }}
                  rows={13}
                  cols={1}
                />
                <MasonryCard
                  icon={BoltCircle}
                  title="Live, Powerful Sandbox"
                  text="The Northflank interface is fully real-time and lightning fast. Switching between your IDE and Northflank becomes seamless. Gain instant feedback and observability."
                  afterText={
                    <Box
                      position={['static', 'static', 'static', 'absolute']}
                      bottom={0}
                      top="50%"
                      left="48px"
                      right="48px"
                      mt={10}
                    >
                      <RealtimePlatform />
                    </Box>
                  }
                  textBoxProps={{ pb: '0px !important' }}
                  rows={10}
                  cols={1}
                />
                <MasonryCard
                  icon={Support}
                  title="Exceptional Support"
                  text={[
                    'If you have any issues or questions, our team of experts has you covered with prompt, personalised support.',
                    'Run important workloads confidently with our optional dedicated Slack channels and SLA contracts.',
                  ]}
                  rows={7}
                  cols={1}
                />
                <MasonryCard
                  icon={SearchAlt2}
                  title="Simplifying the Landscape"
                  text="We rationalise the requirements of the DevOps process as a small number of key concepts. Combining these concepts, you are able to build powerful workflows and craft complex infrastructure."
                  imageVertical="/images/concepts-sidebar.png"
                  imageProps={{ ml: '34px', top: '-40px' }}
                  rows={13}
                  cols={1}
                />
                <MasonryCard
                  icon={LineChart}
                  title="Unlock Your Productivity"
                  text={[
                    <>
                      Let your developers focus on the code. Northflank handles
                      the entire post-commit pipeline, making DevOps as simple
                      as a ‘git&nbsp;push’.
                    </>,
                    'With zero interaction, Northflank will build your latest commit, deploy the image to the cloud, configure TLS, and give you a domain. Get a project online in under a minute.',
                  ]}
                  rows={10}
                  cols={1}
                />
                <MasonryCard
                  icon={ListPlus}
                  title="Comprehensive Capability"
                  text="Northflank prioritises developer experience over everything else. Each feature is finely crafted to ensure maximum productivity."
                  rows={7}
                  cols={1}
                />
                <MasonryCard
                  icon={Group}
                  title="Collaborative & Secure by Default"
                  text="Powerful role-based access control for teams gives you complete control. Granular permissions and project scoping give your developers access to exactly what they need."
                  imageHorizontal="/images/permissions.png"
                  imageProps={{
                    height: '90%',
                    width: 'auto',
                    top: '20px',
                    right: '-90px',
                  }}
                  cols={2}
                  rows={7}
                />
                <MasonryCard
                  icon={Cloud}
                  title="Flexible Cloud"
                  text="Either use Northflank’s Cloud solution or link your own GCP, AWS, or Azure account and use Northflank to deploy workloads into your own cloud environment."
                  imageVertical="/images/byoc-components.png"
                  imageProps={{ right: '48px', bottom: '48px', width: '98%' }}
                  rows={14}
                  cols={1}
                />
                <MasonryCard
                  icon={Globe}
                  title="Language Agnostic"
                  text="Build and deploy anything with a Dockerfile or Buildpack. No matter which language you use, which framework you prefer, or how your project is structured — just add a Dockerfile and deploy as a Northflank service."
                  rows={7}
                  cols={1}
                />
                <MasonryCard
                  icon={GitBranch}
                  title="Your Favourite VCS"
                  text="Link Northflank with GitHub, Bitbucket, or GitLab (both gitlab.com and self-hosted)."
                  afterText={
                    <Grid
                      gridTemplateColumns="repeat(3, 30px)"
                      gridGap={8}
                      mt={12}
                    >
                      <Box>
                        <Icon Content={Github} Size={32} color="grey2" />
                        <HiddenText>GitHub</HiddenText>
                      </Box>
                      <Box>
                        <Icon Content={Gitlab} Size={32} color="grey2" />
                        <HiddenText>GitLab</HiddenText>
                      </Box>
                      <Box>
                        <Icon Content={Bitbucket} Size={28} color="grey2" />
                        <HiddenText>Bitbucket</HiddenText>
                      </Box>
                    </Grid>
                  }
                  rows={7}
                  cols={1}
                />
              </FeatureMasonry>
            </Box>
          </Box>
        </Box>
      </Box>
      {!!dbaasPages?.pages.data.length && (
        <Box bg="grey9">
          <Box variant="bounding">
            <Box width={[1, 1, 1 / 2]} mb={12}>
              <PreHeading mb={6}>Full DBaaS offering</PreHeading>
              <Heading fontSize={[5, 5, 6]} color="grey1">
                Database as a Service
              </Heading>
            </Box>
            <Grid
              gridTemplateColumns={['1fr', '1fr', 'repeat(3, 1fr)']}
              gridGap={7}
            >
              {dbaasPages.pages.data.map(({ attributes: db }) => (
                <Link
                  key={`dbaas-${db.slug}`}
                  to={`/${db.slug.replace(/~/g, '/')}`}
                >
                  <Box
                    variant="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    bg="grey10"
                    border="1px solid"
                    borderColor="grey5"
                    borderRadius={1}
                    p={8}
                    _css={{
                      '&:hover': {
                        bg: 'grey8',
                      },
                    }}
                  >
                    <Text color="grey1" fontSize={3}>
                      {db.title}
                    </Text>
                    <Icon Content={RightArrowAlt} Size={24} color="grey3" />
                  </Box>
                </Link>
              ))}
            </Grid>
          </Box>
        </Box>
      )}
      <Box variant="bounding">
        <GetStartedCta />
      </Box>
    </>
  );
};

export default Features;
