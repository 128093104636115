export enum CDNProviders {
  FASTLY = 'fastly',
  CLOUDFRONT = 'cloudfront',
}

export const customCdnProviders = [CDNProviders.CLOUDFRONT];

export const nfCdnProviders = [CDNProviders.FASTLY, CDNProviders.CLOUDFRONT];

export type CDNProviderTypes = CDNProviders.CLOUDFRONT | CDNProviders.FASTLY;

export enum FastlyCompressionOptions {
  GZIP = 'gzip',
  BROTLI = 'brotli',
}

export const fastlyCompressionOptions = [
  {
    value: FastlyCompressionOptions.GZIP,
    label: 'gzip',
  },
  {
    value: FastlyCompressionOptions.BROTLI,
    label: 'Brotli',
  },
];

export const cdnProviderMap = {
  [CDNProviders.FASTLY]: 'Fastly',
  [CDNProviders.CLOUDFRONT]: 'CloudFront',
};

export const cdnProviderFeatureFlagMap = {
  [CDNProviders.FASTLY]: 'cdnFastly',
  [CDNProviders.CLOUDFRONT]: 'cdnCloudFront',
};

export const cdnProviderFeatureFlags = [
  cdnProviderFeatureFlagMap[CDNProviders.FASTLY],
  cdnProviderFeatureFlagMap[CDNProviders.CLOUDFRONT],
];

export const cdnProviderOptions = [
  {
    value: CDNProviders.FASTLY,
    label: cdnProviderMap[CDNProviders.FASTLY],
    featureFlag: cdnProviderFeatureFlagMap[CDNProviders.FASTLY],
  },
  {
    value: CDNProviders.CLOUDFRONT,
    label: cdnProviderMap[CDNProviders.CLOUDFRONT],
    featureFlag: cdnProviderFeatureFlagMap[CDNProviders.CLOUDFRONT],
  },
];

export const updatingCdnStates = ['enabling', 'upserting'];
export const disablingCdnStates = ['disabling'];

export const blockedCdnStates = [...updatingCdnStates, ...disablingCdnStates];
export const unblockedCdnStates = ['error', 'deployed', 'disabled'];

export const fastlyCdnDefaultOptions = {
  service: {
    forceTlsEnableHsts: true,
    hstsDuration: 31557600,
    staleIfError: true,
    staleIfErrorTtl: 43200,
    defaultTtl: 3600,
  },
  logging: {
    enabled: true,
  },
  http3: {
    enabled: false,
  },
  compression: {
    enabled: false,
    mode: FastlyCompressionOptions.GZIP,
  },
  // apexRedirect: {
  //   enabled: false,
  //   statusCode: 301,
  // },
  vclSnippets: [],
  cacheSettings: [],
};

export const fastlyCacheSettingsActions = [
  { value: 'pass', label: 'Pass' },
  { value: 'cache', label: 'Cache' },
  { value: 'restart', label: 'Restart' },
];

export const fastlyVclSnippetTypes = [
  { value: 'init', label: 'init' },
  { value: 'recv', label: 'recv' },
  { value: 'hash', label: 'hash' },
  { value: 'hit', label: 'hit' },
  { value: 'miss', label: 'miss' },
  { value: 'pass', label: 'pass' },
  { value: 'fetch', label: 'fetch' },
  { value: 'error', label: 'error' },
  { value: 'deliver', label: 'deliver' },
  { value: 'log', label: 'log' },
  { value: 'none', label: 'none' },
];
