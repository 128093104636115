import React from 'react';
import { Link } from 'react-router-dom';

import css from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { transparentize } from 'polished';
import styled from 'styled-components';
import {
  background,
  border,
  color,
  layout,
  position,
  shadow,
  space,
  system,
  typography,
} from 'styled-system';

import Box from '@northflank/components/Box';
import Icon from '@northflank/components/Icon';
import Loading from '@northflank/components/Loading';
import { variant } from '@northflank/utilities/styled-system/variant';
import withComponentId from '@northflank/utilities/withComponentId';

const opacity = system({
  prop: 'opacity',
  cssProperty: 'opacity',
});

const StyledButton = styled.button.withConfig({
  shouldForwardProp,
})(
  space,
  layout,
  background,
  color,
  typography,
  border,
  position,
  shadow,
  opacity,
  border,
  ({
    px = 7,
    py = 5,
    bg,
    border = '1px solid',
    borderColor = 'grey5',
    borderRadius = 1,
    fontWeight = 2,
    fontFamily = 'body',
    fontSize = 1,
    boxShadow,
    color,
    display = 'flex',
    alignItems = 'center',
    lineHeight = 1,
    theme,
    loading,
    justifyContent = 'center',
    disabledOpacity,
    delimiterAfter,
  }) =>
    css({
      minHeight: 9,
      px,
      py,
      fontFamily,
      fontWeight,
      fontSize,
      bg: bg || 'grey10',
      color: loading ? 'grey3' : color || 'grey1',
      border,
      borderColor,
      borderRadius,
      boxShadow: boxShadow || theme.shadows.button.small,
      display,
      flexDirection: delimiterAfter ? 'row-reverse' : 'row',
      alignItems,
      lineHeight,
      textDecoration: 'none',
      justifyContent,
      '&:hover, &:focus': {
        outline: 0,
        bg: !loading && 'grey8',
        color: loading ? 'grey3' : 'grey0',
        boxShadow: !loading && theme.shadows.button.smallHover,
      },
      '&[disabled]': {
        bg: bg || 'grey10',
        color: 'grey4',
        borderColor: 'grey6',
        opacity: disabledOpacity || 0.75,
        cursor: loading ? 'progress' : 'not-allowed',
        boxShadow: 'none',
      },
      cursor: loading ? 'progress' : 'pointer',
      '*': {
        lineHeight,
      },
    }),
  ({
    px,
    py,
    bg,
    theme,
    iconColor,
    iconHoverColor,
    side,
    minWidth,
    minHeight,
    loading,
    disabled,
    active,
    delimiterBorderColor,
    alwaysShowDelimiterColor,
  }) =>
    variant({
      variants: {
        primary: {
          bg: !loading ? 'primary' : 'primaryDark1',
          borderColor: !loading ? 'primary' : 'primaryDark1',
          color: !loading ? 'static.grey1' : 'static.grey2',
          boxShadow: theme.shadows.button.medium,
          '&:hover, &:focus': {
            bg: !loading ? 'primaryLight1' : 'primaryDark1',
            borderColor: !loading ? 'primaryLight1' : 'primaryDark1',
            color: !loading ? 'static.grey0' : 'static.grey2',
          },
          '&[disabled]': {
            bg: 'primaryDark3',
            borderColor: 'primaryDark3',
            color: 'grey3',
            [`${Delimiter}`]: {
              color: 'grey3',
              borderColor: delimiterBorderColor || 'primaryDark4',
            },
          },
          [`${Delimiter}`]: {
            color: iconColor || 'static.grey1',
            borderColor: delimiterBorderColor || 'primaryDark1',
            p: 3,
          },
        },
        danger: {
          bg: !loading ? 'danger' : 'dangerDark1',
          borderColor: !loading ? 'danger' : 'dangerDark1',
          color: !loading ? 'static.grey1' : 'static.grey2',
          '&:hover, &:focus': {
            bg: !loading ? 'dangerLight1' : 'dangerDark1',
            borderColor: !loading ? 'dangerLight1' : 'dangerDark1',
            color: !loading ? 'static.grey0' : 'static.grey2',
          },
          '&[disabled]': {
            bg: 'dangerDark4',
            borderColor: 'dangerDark4',
            color: 'dangerLight4',
            [`${Delimiter}`]: {
              color: 'dangerLight4',
              borderColor: delimiterBorderColor || 'dangerDark5',
            },
          },
          [`${Delimiter}`]: {
            color: iconColor || 'static.grey1',
            borderColor: delimiterBorderColor || 'dangerDark2',
            p: 3,
          },
        },
        warning: {
          bg: !loading ? 'warning' : 'warningDark1',
          borderColor: !loading ? 'warning' : 'warningDark1',
          color: !loading ? 'static.grey11' : 'static.grey11',
          '&:hover, &:focus': {
            bg: !loading ? 'warningLight1' : 'warningDark1',
            borderColor: !loading ? 'warningLight1' : 'warningDark1',
            color: !loading ? 'static.grey11' : 'static.grey11',
          },
          '&[disabled]': {
            bg: 'warningDark4',
            borderColor: 'warningDark4',
            color: 'warningLight4',
            [`${Delimiter}`]: {
              color: 'warningLight4',
              borderColor: delimiterBorderColor || 'warningDark5',
            },
          },
          [`${Delimiter}`]: {
            color: iconColor || 'static.grey11',
            borderColor: delimiterBorderColor || 'warningDark2',
            p: 3,
          },
        },
        icon: {
          px: px || 0,
          py: py || 0,
          minWidth: minWidth || '24px',
          minHeight: minHeight || '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'grey3',
          svg: {
            color: iconColor || 'grey3',
            fill: iconColor || 'grey3',
          },
          '&:hover, &:focus': {
            svg: {
              color: loading
                ? 'grey4'
                : (iconHoverColor ??
                  (iconColor ? `${iconColor}Light1` : 'grey0')),
              fill: loading
                ? 'grey4'
                : (iconHoverColor ??
                  (iconColor ? `${iconColor}Light1` : 'grey0')),
            },
          },
          '&:active': {
            svg: {
              color: loading
                ? 'grey4'
                : (iconHoverColor ??
                  (iconColor ? `${iconColor}Light1` : 'grey0')),
              fill: loading
                ? 'grey4'
                : (iconHoverColor ??
                  (iconColor ? `${iconColor}Light1` : 'grey0')),
            },
          },
          '&[disabled]': {
            color: 'grey4',
            borderColor: 'grey6',
            bg: bg || 'grey10',
            boxShadow: 'none',
            opacity: 0.75,
            svg: {
              color: 'grey4',
              fill: 'grey4',
            },
            '&:hover, &:focus': {
              boxShadow: 'none',
              svg: {
                color: 'grey4',
                fill: 'grey4',
              },
            },
            '&:active': {
              svg: {
                color: 'grey4',
                fill: 'grey4',
              },
            },
          },
        },
        noPadding: {
          px: px || 0,
          py: py || 0,
        },
        extraSmall: {
          height: '16px !important',
          minHeight: '16px',
          minWidth: '16px',
        },
        small: {
          px: px || 7,
          py: py || '5px',
          fontSize: 0,
        },
        large: {
          px: px || 11,
          py: py || 7,
          fontSize: 2,
        },
        gradient: {
          background: theme.colors.buttonGradient,
          borderColor: 'primary',
          backgroundSize: '100% 100%',
          transition: '300ms',
          '&:hover': {
            backgroundSize: '250% 100%',
          },
        },
        noBorder: {
          border: 0,
          boxShadow: 'none',
          '&:hover, &:focus': {
            boxShadow: 'none',
          },
          '&:focus-visible': {
            border: '1px solid !important',
            borderColor: `${theme.colors.grey5} !important`,
          },
        },
        noBackground: {
          bg: active
            ? theme.name.toLowerCase().includes('light')
              ? 'grey7'
              : 'grey8'
            : 'transparent',
          boxShadow: 'none',
          '&:hover, &:focus': {
            bg: active
              ? theme.name.toLowerCase().includes('light')
                ? 'grey7'
                : 'grey8'
              : 'transparent',
            boxShadow: 'none',
          },
          '&:focus-visible': {
            border: '1px solid !important',
            borderColor: `${theme.colors.grey5} !important`,
          },
          '&:active': {
            bg: active
              ? theme.name.toLowerCase().includes('light')
                ? 'grey7'
                : 'grey8'
              : 'transparent',
            boxShadow: 'none',
          },
          '&[disabled]': {
            bg: 'transparent',
            borderColor: 'grey9',
          },
        },
        noInteractiveBg: {
          bg: 'transparent',
          boxShadow: 'none',
          '&:hover, &:focus': {
            bg: 'transparent',
            boxShadow: 'none',
          },
          '&:active': {
            bg: 'transparent',
            boxShadow: 'none',
          },
          '&[disabled]': {
            bg: 'transparent',
          },
        },
        pipelineContextMenu: {
          bg: bg || 'grey8',
          '&:hover': {
            bg: 'grey9',
          },
          borderRadius: 0,
          justifyContent: 'left',
          '&:first-of-type': {
            borderRadius: '2px 2px 0 0',
          },
          '&:last-of-type': {
            borderRadius: '0px 0px 2px 2px',
            '&:focus': {
              bg: transparentize(0.85, theme.colors.danger),
            },
          },
          '&[disabled]': {
            '&:hover': {
              bg: 'grey5',
            },
          },
        },
        sideMenu: {
          position: 'absolute',
          '-webkit-transition': `${side} 0.6s`,
          '-moz-transition': `${side} 0.6s`,
          '-o-transition': `${side} 0.6s`,
          transition: `${side} 0.6s`,
          borderRadius: side === 'right' ? '25px 0 0 25px' : '0 25px 25px 0',
          top: 'calc(50% - 12px)',
          pl: 0,
          pr: 0,
          py: 2,
        },
        withTextInput: {
          position: 'absolute',
          right: 0,
          top: 0,
          px: 1,
        },
        oauth: {
          py: 7,
          px: 7,
        },
        link: {
          '&:hover, &:focus': {
            outline: 0,
            borderColor: 'primary',
          },
        },
        unlink: {
          borderColor: 'danger',
          '&:hover, &:focus': {
            bg: 'danger',
            svg: {
              fill: 'grey1',
            },
          },
        },
        auto: {
          height: 'auto',
        },
        pagination: {
          bg: active ? 'grey10' : 'transparent',
          border: 0,
          color: active ? 'grey1' : 'grey2',
          cursor: active ? 'not-allowed' : 'pointer',
        },
        paginationArrow: {
          bg: 'transparent',
          border: 0,
          cursor: active ? 'not-allowed' : 'pointer',
          '&:hover, &:focus': {
            color: active ? 'grey2' : 'grey1',
            bg: active ? 'transparent' : 'grey10',
          },
          '&:active': {
            color: 'grey2',
            bg: 'transparent',
          },
        },
        delimiter: {
          display: 'flex',
          p: 0,
          borderRadius: 1,
          border: 'none',
          color: disabled
            ? 'static.grey3'
            : loading
              ? 'static.grey2'
              : 'static.grey1',
          bg: !loading ? 'primary' : 'primaryDark1',
          [`${Delimiter}`]: {
            p: 6,
            color: disabled
              ? 'static.grey3'
              : loading
                ? 'static.grey2'
                : 'static.grey1',
            bg: disabled
              ? 'primaryDark3'
              : loading
                ? 'primaryDark2'
                : 'primaryDark1',
            borderColor: disabled
              ? 'primaryDark4'
              : loading
                ? 'primaryDark3'
                : 'primaryDark2',
          },
          '&:hover, &:focus': {
            bg: !loading && 'primaryLight2',
            color: disabled
              ? 'static.grey3'
              : loading
                ? 'static.grey2'
                : 'static.grey1',
            [`${Delimiter}`]: {
              bg: disabled
                ? 'primaryDark3'
                : loading
                  ? 'primaryDark2'
                  : 'primary',
              borderColor: disabled
                ? 'primaryDark4'
                : loading
                  ? 'primaryDark3'
                  : 'primaryDark1',
            },
          },
          '&[disabled]': {
            bg: 'primaryDark3',
            color: 'static.grey3',
            boxShadow: 'none',
            [`${Delimiter}`]: {
              color: 'static.grey3',
              bg: 'primaryDark4',
              borderColor: 'primaryDark5',
            },
          },
        },
        smallDelimiter: {
          display: 'flex',
          p: 0,
          fontSize: 0,
          fontWeight: 1,
          height: '24px',
          [`${Delimiter}`]: {
            p: 3,
            borderColor: delimiterBorderColor || 'grey5',
            color: iconColor || 'grey1',
          },
          '&:hover, &:focus': {
            [`${Delimiter}`]: {
              color: (!loading && !disabled && iconColor) || 'grey0',
            },
          },
          '&:active': {
            [`${Delimiter}`]: {
              color: (!loading && !disabled && iconColor) || 'static.grey0',
            },
          },
          '&[disabled]': {
            bg: 'grey10',
            opacity: 0.75,
            [`${Delimiter}`]: {
              color: alwaysShowDelimiterColor ? iconColor : 'grey3',
              borderColor: 'grey6',
            },
          },
        },
        infoboxPrimary: {
          borderRadius: 2,
          bg: !loading ? 'primary' : 'primaryDark2',
          border: 'none',
          color: 'background',
          '&:hover, &:focus': {
            color: loading ? 'background' : 'backgroundLight3',
            bg: !loading ? 'primaryLight1' : 'primaryDark2',
          },

          '&[disabled]': {
            bg: 'primaryDark3',
            color: 'background',
            cursor: loading ? 'progress' : 'not-allowed',
          },
        },
        infoboxSuccess: {
          borderRadius: 2,
          bg: !loading ? 'successDark1' : 'successDark3',
          border: 'none',
          boxShadow: 'none',
          color: 'background',
          '&:hover, &:focus': {
            color: loading ? 'background' : 'backgroundLight3',
            bg: !loading ? 'success' : 'successDark3',
          },

          '&[disabled]': {
            bg: 'successDark4',
            color: 'background',
            cursor: loading ? 'progress' : 'not-allowed',
          },
        },
        infoboxWarning: {
          borderRadius: 2,
          bg: !loading ? 'warningDark1' : 'warningDark3',
          border: 'none',
          color: 'background',
          '&:hover,&:focus': {
            color: loading ? 'background' : 'backgroundLight3',
            bg: loading ? 'warningDark3' : 'warning',
          },
          '&[disabled]': {
            bg: 'warningDark4',
            color: 'background',
            cursor: loading ? 'progress' : 'not-allowed',
          },
        },
        infoboxDanger: {
          borderRadius: 2,
          bg: !loading ? 'dangerDark1' : 'dangerDark3',
          border: 'none',
          color: !loading ? 'grey0' : 'grey2',
          '&:hover, &:focus': {
            color: !loading ? 'grey0' : 'grey2',
            bg: !loading ? 'danger' : 'dangerDark3',
          },
          '&[disabled]': {
            bg: 'dangerDark4',
            color: 'grey3',
            cursor: loading ? 'progress' : 'not-allowed',
          },
        },
        primaryDark: {
          bg: !loading ? 'primaryDark1' : 'primaryDark2',
          borderColor: !loading ? 'primaryDark1' : 'primaryDark2',
          borderLeftColor: 'primaryDark2',
          color: !loading ? 'grey1' : 'grey2',
          boxShadow: theme.shadows.button.medium,
          '&:hover, &:focus': {
            bg: !loading ? 'primary' : 'primaryDark2',
            borderColor: !loading ? 'primary' : 'primaryDark2',
            color: !loading ? 'grey1' : 'grey2',
          },
          '&[disabled]': {
            bg: 'primaryDark3',
            borderColor: 'primaryDark3',
            color: 'grey3',
          },
        },
        text: {
          px: 5,
          color: 'grey3',
          '&:hover, &:focus': {
            color: 'grey2',
          },
        },
        modalFilter: {
          bg: active && 'grey5',
          '&:active, &:hover, &:focus': {
            bg: 'grey5',
            boxShadow: 'none',
          },
        },
        noWrap: {
          whiteSpace: 'nowrap',
        },
      },
    })
);

const Delimiter = styled(Box)(({ collapseDelimiter, delimiterAfter }) =>
  css(
    delimiterAfter
      ? {
          borderRadius: '0 2px 2px 0',
          borderLeft: collapseDelimiter
            ? ['none', 'none', 'none', 'none', '1px solid']
            : '1px solid',
        }
      : {
          borderRadius: '2px 0 0 2px',
          borderRight: collapseDelimiter
            ? ['none', 'none', 'none', 'none', '1px solid']
            : '1px solid',
        }
  )
);

const WrapLink = ({
  to,
  disabled,
  target,
  externalLink,
  linkStyle,
  children,
}) =>
  to && !disabled ? (
    externalLink ? (
      <a
        href={to}
        target={target}
        style={{
          textDecoration: 'none',
          display: 'inline-block',
          outline: 0,
          ...linkStyle,
        }}
      >
        {children}
      </a>
    ) : (
      <Link
        to={to}
        target={target}
        style={{
          textDecoration: 'none',
          display: 'inline-block',
          outline: 0,
          ...linkStyle,
        }}
      >
        {children}
      </Link>
    )
  ) : (
    children
  );

const Button = ({
  uid,
  children,
  loading,
  disabled,
  onClick,
  icon,
  iconSize,
  delimiterProps = {},
  delimiterItem,
  to,
  target,
  externalLink,
  linkStyle,
  forwardedRef,
  collapseToIcon,
  delimiterAfter,
  ...props
}) => {
  const iconPresent =
    props?.variant === 'icon' || props?.variant?.includes('icon');
  const isSmallDelimiter =
    props?.variant === 'smallDelimiter' ||
    props?.variant?.includes('smallDelimiter');

  return (
    <WrapLink
      to={to}
      disabled={loading || disabled}
      target={target}
      externalLink={externalLink}
      linkStyle={linkStyle}
    >
      <StyledButton
        as={to ? 'div' : 'button'}
        id={uid && `button-${uid}`}
        ref={forwardedRef}
        loading={loading}
        disabled={loading || disabled}
        onClick={!loading && !disabled ? onClick : undefined}
        delimiterAfter={delimiterAfter}
        {...props}
      >
        {(icon || delimiterItem) && (
          <Delimiter
            loading={loading}
            disabled={loading || disabled}
            collapseDelimiter={isSmallDelimiter && collapseToIcon}
            delimiterAfter={delimiterAfter}
            {...delimiterProps}
          >
            {loading ? (
              <Loading size={12} mb={1} mx={1} />
            ) : (
              <>{delimiterItem ?? <Icon Content={icon} Size={iconSize} />}</>
            )}
          </Delimiter>
        )}
        <Box
          variant={['flex', 'verticalCenter']}
          px={icon ? 7 : 0}
          display={
            isSmallDelimiter && collapseToIcon
              ? ['none', 'none', 'none', 'none', 'flex']
              : 'flex'
          }
          flexGrow={isSmallDelimiter ? 1 : undefined}
          justifyContent="center"
        >
          {loading && iconPresent ? (
            <Loading mb={1} size={12} />
          ) : loading && !icon ? (
            <Box variant={['flex', 'verticalCenter']} px={4}>
              <Box variant={['flex', 'verticalCenter']}>{children}</Box>
              <Loading size={12} ml={4} mb={1} />
            </Box>
          ) : (
            <Box
              variant={['flex', 'verticalCenter']}
              px={iconPresent || icon ? 0 : 4}
            >
              {children}
            </Box>
          )}
        </Box>
      </StyledButton>
    </WrapLink>
  );
};

export default withComponentId(
  React.forwardRef((props, ref) => <Button forwardedRef={ref} {...props} />)
);
