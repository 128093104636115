export const determineProjectLimits = (entity, project) => {
  const free = project?.billing?.free;

  const isSelfHostedCluster = project?.deployment?.isSelfHostedCluster;
  const entityLimits = free ? entity?.limits?.freeProjects : entity?.limits;

  const projectLimits = project?.billing?.limits;

  const defaultLimits = free ? Meteor.settings.public.FREE_PROJECT_LIMITS : {};

  const getRelevantBuildLimit = (path) =>
    !isSelfHostedCluster
      ? projectLimits?.resources?.builds?.[path] ??
        entityLimits?.resources?.builds?.[path] ??
        defaultLimits.resources?.builds?.[path]
      : undefined;

  const getRelevantResourceLimit = (path) =>
    !isSelfHostedCluster
      ? projectLimits?.resources?.[path] ??
        entityLimits?.resources?.[path] ??
        defaultLimits.resources?.[path]
      : undefined;

  const getRelevantCreationLimit = (path) =>
    !isSelfHostedCluster
      ? projectLimits?.creation?.[path] ??
        entityLimits?.creation?.[path] ??
        defaultLimits.creation?.[path]
      : undefined;

  const limits = {
    resources: {
      builds: {
        cpu: getRelevantBuildLimit('cpu'),
        ram: getRelevantBuildLimit('ram'),
        ephemeralStorage: getRelevantBuildLimit('ephemeralStorage'),
      },
      cpu: getRelevantResourceLimit('cpu'),
      ram: getRelevantResourceLimit('ram'),
      storage: getRelevantResourceLimit('storage'),
      replicas: getRelevantResourceLimit('replicas'),
      instances: getRelevantResourceLimit('instances'),
      shmSize: getRelevantResourceLimit('shmSize'),
      ephemeralStorage: getRelevantResourceLimit('ephemeralStorage'),
      volumeStorage: getRelevantResourceLimit('volumeStorage'),
      volumeStorageClasses: getRelevantResourceLimit('volumeStorageClasses'),
    },
    creation: {
      addons: getRelevantCreationLimit('addons'),
      jobs: getRelevantCreationLimit('jobs'),
      services: getRelevantCreationLimit('services'),
    },
  };

  return {
    free,
    errorMessage: free
      ? 'Not available due to free project limits'
      : 'Not available due to account limits. Contact support@northflank.com to request an increase.',
    useLimits: free || !!entity?.limits,
    limits,
  };
};
