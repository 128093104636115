/* eslint-disable no-continue,no-plusplus */
import moment from 'moment/moment';

export const getTimeout = (() => {
  // IIFE
  const _setTimeout = setTimeout; // Reference to the original setTimeout
  const map = {}; // Map of all timeouts with their end times

  // TODO best way of doing this?
  // eslint-disable-next-line no-global-assign
  setTimeout = (callback, delay, ...params) => {
    // Modify setTimeout
    const id = _setTimeout(callback, delay, ...params); // Run the original, and store the id
    map[id] = Date.now() + delay; // Store the end time
    return id; // Return the id
  };

  return (id) => {
    // The actual getTimeout function
    // If there was no timeout with that id, return NaN, otherwise, return the time left clamped to 0
    return map[id] ? Math.max(map[id] - Date.now(), 0) : NaN;
  };
})();

// https://github.com/gjtorikian/isBinaryFile/blob/main/src/index.ts
const MAX_BYTES = 512;
export const isFileBinary = (fileBuffer, bytesRead) => {
  // empty file. no clue what it is.
  if (bytesRead === 0) {
    return false;
  }

  let suspiciousBytes = 0;
  const totalBytes = Math.min(bytesRead, MAX_BYTES);

  // UTF-8 BOM
  if (
    bytesRead >= 3 &&
    fileBuffer[0] === 0xef &&
    fileBuffer[1] === 0xbb &&
    fileBuffer[2] === 0xbf
  ) {
    return false;
  }

  // UTF-32 BOM
  if (
    bytesRead >= 4 &&
    fileBuffer[0] === 0x00 &&
    fileBuffer[1] === 0x00 &&
    fileBuffer[2] === 0xfe &&
    fileBuffer[3] === 0xff
  ) {
    return false;
  }

  // UTF-32 LE BOM
  if (
    bytesRead >= 4 &&
    fileBuffer[0] === 0xff &&
    fileBuffer[1] === 0xfe &&
    fileBuffer[2] === 0x00 &&
    fileBuffer[3] === 0x00
  ) {
    return false;
  }

  // GB BOM
  if (
    bytesRead >= 4 &&
    fileBuffer[0] === 0x84 &&
    fileBuffer[1] === 0x31 &&
    fileBuffer[2] === 0x95 &&
    fileBuffer[3] === 0x33
  ) {
    return false;
  }

  if (totalBytes >= 5 && fileBuffer.slice(0, 5).toString() === '%PDF-') {
    /* PDF. This is binary. */
    return true;
  }

  // UTF-16 BE BOM
  if (bytesRead >= 2 && fileBuffer[0] === 0xfe && fileBuffer[1] === 0xff) {
    return false;
  }

  // UTF-16 LE BOM
  if (bytesRead >= 2 && fileBuffer[0] === 0xff && fileBuffer[1] === 0xfe) {
    return false;
  }

  for (let i = 0; i < totalBytes; i++) {
    if (fileBuffer[i] === 0) {
      // NULL byte--it's binary!
      return true;
    }
    if ((fileBuffer[i] < 7 || fileBuffer[i] > 14) && (fileBuffer[i] < 32 || fileBuffer[i] > 127)) {
      // UTF-8 detection
      if (fileBuffer[i] > 193 && fileBuffer[i] < 224 && i + 1 < totalBytes) {
        i++;
        if (fileBuffer[i] > 127 && fileBuffer[i] < 192) {
          continue;
        }
      } else if (fileBuffer[i] > 223 && fileBuffer[i] < 240 && i + 2 < totalBytes) {
        i++;
        if (
          fileBuffer[i] > 127 &&
          fileBuffer[i] < 192 &&
          fileBuffer[i + 1] > 127 &&
          fileBuffer[i + 1] < 192
        ) {
          i++;
          continue;
        }
      }

      suspiciousBytes++;
      // Read at least 32 fileBuffer before making a decision
      if (i > 32 && (suspiciousBytes * 100) / totalBytes > 10) {
        return true;
      }
    }
  }

  if ((suspiciousBytes * 100) / totalBytes > 10) {
    return true;
  }

  return false;
};

export const getUserDisplayName = (user = {}, options = { includeEmail: false }) => {
  const username = user.profile?.name ?? user.username ?? user.internalId;

  return options?.includeEmail ? `${username} (${user.emails?.[0]?.address})` : username;
};

// Failure threshold in seconds after which we stop displaying it in the UI
// Used in container statuses
export const FAILURE_THRESHOLD = 300;

export const FAILURE_STATUSES = [
  'ERROR_IMAGE_PULL',
  'INVALID_IMAGE_NAME',
  'IMAGE_PULL_BACK_OFF',
  'CRASH_LOOP_BACK_OFF',
  'OOM_KILLED',
  'ERROR',
];

// Helper to check if a timestamp is within the failure threshold
export const isWithinThreshold = (timestamp) => {
  // Handle if timestamp isn't defined
  if (!timestamp) return false;

  return moment().unix() - moment(timestamp).unix() < FAILURE_THRESHOLD;
};

export const getLastFailure = (instance, type = 'status') => {
  if (!instance) return undefined;

  // Handle probe type separately
  if (type === 'probe' && instance.probeStatus) {
    /*
      We only let users set one of each, so arrays will only contain one healthcheck
      {
        liveness: [{ timestamp:..., status:... }]
        readiness: [{ timestamp:..., status:... }]
     */
    const entries = Object.entries(instance.probeStatus);

    /*
      [
        { timestamp:... message: "liveness is failing", reason:... },
        { timestamp:... message: "readiness is failing", reason:... }
       ]
    */
    const failures = entries
      .map(([k, v]) =>
        Object.values(v)
          .filter((status) => status.state !== 'success')
          .map(({ timestamp, message: reason }) => ({
            timestamp,
            probeType: k,
          }))
      )
      .flat(1);

    // Want to reduce into one object:
    // Two probes (liveness, readiness) are failing, <timestamp>

    const mostRecentTimestamp = failures
      .map(({ timestamp }) => timestamp)
      .sort(({ timestamp: a }, { timestamp: b }) => (a < b ? 1 : -1))[0];

    if (isWithinThreshold(mostRecentTimestamp)) {
      const failingTypes = failures.map(({ probeType }) => probeType).join(', ');

      return {
        timestamp: moment(mostRecentTimestamp),
        message: `Healthchecks (${failingTypes}) are failing`,
      };
    }
  }

  // Map of common failure reasons to user-friendly messages
  const failureMap = {
    OOMKilled: 'Out-of-Memory killed (OOMKilled)',
    OOM_KILLED: 'Out-of-Memory killed (OOMKilled)',
    CRASH_LOOP_BACK_OFF: 'Container is crashing (check logs)',
    EVICTED: 'Pod evicted (Evicted)',
    ERROR_IMAGE_PULL: 'Not able to pull image (image pull)',
    TASK_STAGING: "Pod can't schedule",
    ERROR: 'Runtime failure',
  };

  // Check for TASK_STAGING state that's older than 1 minute
  if (instance.status === 'TASK_STAGING' && instance.statusHistory?.length === 1) {
    const stagingStatus = instance.statusHistory[0];
    if (stagingStatus.status === 'TASK_STAGING') {
      const timestamp = moment(stagingStatus.timestamp);
      if (moment().diff(timestamp, 'm') > 1) {
        const condition = findMatchingCondition(instance, timestamp);
        return {
          timestamp,
          reason: failureMap.TASK_STAGING,
          message: failureMap.TASK_STAGING,
          tooltip: condition?.message,
        };
      }
    }
  }

  // Get failure timestamps
  const taintTimestamp = instance.taint && moment(instance.taint.timestamp);
  const podFailureTimestamp =
    instance.podStatus?.lastFailure && moment(instance.podStatus.lastFailure);
  const containerStatusFailures = instance.containerStatusReasons
    ? Object.entries(instance.containerStatusReasons)
        .filter(([status, timestamp]) => FAILURE_STATUSES.includes(status))
        .sort((a, b) => a[1] < b[1])
    : [];

  const [mostRecentContainerFailure, mostRecentContainerFailureTimestamp] =
    containerStatusFailures[0] || [];

  if (!taintTimestamp && !podFailureTimestamp && !mostRecentContainerFailureTimestamp) {
    return undefined;
  }

  // Convert container timestamp to moment object if it exists
  const containerMoment =
    mostRecentContainerFailureTimestamp && moment.unix(mostRecentContainerFailureTimestamp);

  // Get all valid timestamps in an array
  const failures = [
    { timestamp: taintTimestamp, reason: instance.taint?.reason },
    { timestamp: podFailureTimestamp, reason: instance.podStatus?.lastFailureReason },
  ].filter((t) => t?.timestamp);

  // If we have a container status
  if (
    containerMoment &&
    // And the instance is killing or killed
    (instance.status === 'TASK_KILLING' || instance.status === 'TASK_KILLED')
  ) {
    // Did the container error happen over 10 seconds before the instance was last updated? (i.e. terminated)
    const diff = Math.abs(containerMoment.diff(moment.unix(instance.updatedAt), 's'));
    if (diff > 10) {
      // Add the container failure to the array of failures, otherwise we ignore so 'Runtime Error' doesn't overwrite
      // More important failures https://linear.app/northflank/issue/FLANK-150/pods-that-are-terminated-display-runtime-error
      failures.push({ timestamp: containerMoment, reason: mostRecentContainerFailure });
    }
  } else {
    failures.push({ timestamp: containerMoment, reason: mostRecentContainerFailure });
  }

  // If only one timestamp exists, return it
  if (failures.length === 1) {
    return createFailureObject(failures[0].timestamp, failures[0].reason, failureMap);
  }

  // Check if podFailureTimestamp is within 60 seconds of any other timestamp

  const isPodFailureNearOthers = failures.some((t) => {
    if (!podFailureTimestamp) return false;
    if (t.timestamp === podFailureTimestamp) return false;
    const diff = Math.abs(podFailureTimestamp.diff(t.timestamp, 's'));
    return diff <= 60;
  });

  if (isPodFailureNearOthers) {
    // Use podFailureTimestamp if it's within 60 seconds of another failure
    return createFailureObject(
      podFailureTimestamp,
      instance.podStatus?.lastFailureReason,
      failureMap
    );
  }

  // Otherwise, find the most recent timestamp
  const mostRecent =
    failures && failures.length > 0
      ? failures.reduce((a, b) => {
          // Handle if either object is undefined/null
          if (!a) return b;
          if (!b) return a;

          // Handle if either timestamp is undefined/null
          if (!a.timestamp) return b;
          if (!b.timestamp) return a;

          return a.timestamp > b.timestamp ? a : b;
        })
      : null;

  if (mostRecent?.reason && mostRecent?.timestamp) {
    return createFailureObject(mostRecent.timestamp, mostRecent.reason, failureMap);
  }

  return undefined;
};
// Helper function to find matching condition within a 20-second window
const findMatchingCondition = (instance, timestamp) => {
  return instance?.conditions?.find((c) => {
    const conditionTimestamp = moment(c.lastTransitionTime);
    const start = timestamp.clone().subtract(10, 's');
    const end = timestamp.clone().add(10, 's');
    return conditionTimestamp.isBetween(start, end);
  });
};

// Helper function to create failure object
const createFailureObject = (timestamp, reason, failureMap) => ({
  timestamp,
  reason,
  message: failureMap[reason] || reason,
});

export const getLastNodeFailure = (node, type) => {
  if (node.unschedulable) {
    if (node.taints) {
      const taint = node?.taints?.find((t) => t.effect === 'NoSchedule');
      if (taint) {
        return {
          timestamp: moment(taint.timeAdded),
          reason: 'Node is unschedulable',
        };
      }
    }
  }
  // Check for the ready condition
  const readyCondition = node.conditions.find((c) => c.type === 'Ready');

  if (readyCondition && readyCondition.status === 'True') {
    return {
      timestamp: moment(readyCondition.lastTransitionTime),
      reason: 'Node is not ready',
    };
  }
};
