import React, { useEffect, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';

import css from '@styled-system/css';
import styled from 'styled-components';
import { space } from 'styled-system';

import Box from '@northflank/components/Box';
import Grid from '@northflank/components/Grid';
import Heading from '@northflank/components/Heading';
import MarkdownBody from '@northflank/components/marketing/MarkdownBody';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';

import { BackLink } from '../changelog/post';

const StyledDivider = styled('hr')(
  () =>
    css({
      border: 0,
      height: '1px',
      bg: 'grey6',
    }),
  space
);

const JobPost = ({ job }) => {
  const containerRef = useRef();

  useEffect(() => {
    // This will define the initial settings for the Ashby embed script.
    // The load function can take any setting, so that these settings could change each time the job board is loaded.
    window.__Ashby = {
      settings: {
        ashbyBaseJobBoardUrl: 'https://jobs.ashbyhq.com/northflank.com',
        displayMode: 'application-form-only',
        jobPostingId: job.id,
        autoLoad: true,
        autoScroll: false,
        customCssUrl: 'https://northflank.com/css/ashby.css',
      },
    };

    const script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://jobs.ashbyhq.com/northflank.com/embed?version=2'
    );
    containerRef.current.appendChild(script);
  }, []);

  const compensationSummary = useMemo(() => {
    const parts = [];

    if (job?.compensation) {
      const salary = job.compensation.summaryComponents?.find(
        (c) => c.compensationType === 'Salary'
      );
      if (salary) parts.push(salary.summary);

      const equity = job.compensation.summaryComponents?.find(
        (c) => c.compensationType === 'EquityPercentage'
      );
      if (equity) {
        if (equity.summary === 'Offers Equity') parts.push(equity.summary);
        else parts.push(`${equity.summary} Equity`);
      }

      const commission = job.compensation.summaryComponents?.find(
        (c) => c.compensationType === 'Commission'
      );
      if (commission) parts.push(commission.summary);
    }

    return parts.join(' • ');
  }, [JSON.stringify(job?.compensation ?? {})]);

  return (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(job.linkedData, null, 2),
        }}
      />
      <SEO
        title={`${job.title} — Careers`}
        description={job.descriptionSocial}
      />
      <Box
        maxWidth="1920px"
        mx="auto"
        background={[
          'none',
          'none',
          `radial-gradient(circle at calc((1920px - 600px) / 2) 400px, rgba(0, 147, 255, 0.05) 0%, rgba(0, 0, 0, 0) 400px)`,
        ]}
      >
        <Box ref={containerRef} variant="bounding" maxWidth="800px !important">
          <Link to="/careers">
            <BackLink>&larr; Back to Careers</BackLink>
          </Link>
          <Box mb={13}>
            <Heading
              fontWeight="600"
              fontSize={[6, 6, 7, 7]}
              as="h1"
              color="grey1"
              mb={8}
            >
              {job.title}
            </Heading>
            <StyledDivider mb={8} />
            {job.compensation.shouldDisplayCompensation && (
              <Text fontSize={3} mb={6}>
                {compensationSummary}
              </Text>
            )}
            <Text fontSize={3} color="grey3" mb={6}>
              {job.locationName},{' '}
              {job.employmentType.replace(/([a-z])([A-Z])/g, '$1 $2')}
            </Text>
          </Box>
          <Grid
            gridTemplateColumns={['1fr', '1fr', 'auto auto']}
            alignItems="start"
            gridGap={12}
            mb={14}
          >
            <MarkdownBody
              dangerouslySetInnerHTML={{ __html: job.descriptionHtml }}
            />
          </Grid>
          <Text fontSize={4} fontWeight="bold" mb={8}>
            Apply for this position
          </Text>
          <Box
            id="ashby_embed"
            border="1px solid"
            borderColor="grey6"
            borderRadius={2}
            p={8}
          />
        </Box>
      </Box>
    </>
  );
};

export default JobPost;
