import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Box from '@northflank/components/Box';

const CollapsibleBox = styled(Box)(
  ({ direction, minimum, maximum, expanded }) => ({
    overflow: 'hidden',
    right: 0,
    display: 'block',
    position: 'relative',
    // Set the transition between max and min height
    // Direction prop (width or height) specifies which direction it collapses
    '-webkit-transition': `min-${direction} 0.6s, max-${direction} 0.6s`,
    '-moz-transition': `min-${direction} 0.6s, max-${direction} 0.6s`,
    '-o-transition': `min-${direction} 0.6s, max-${direction} 0.6s`,
    transition: `min-${direction} 0.6s, max-${direction} 0.6s`,
    [`min-${direction}`]: expanded ? maximum : minimum || '0px',
    [`max-${direction}`]: expanded ? maximum : minimum || '0px',
    '&:active .mask': {
      right: '100%',
    },
  })
);

// The direction refers to collapsing along the width or the height
const Collapsible = ({
  direction,
  minimum,
  maximum,
  children,
  expanded,
  ...props
}) => {
  const [hideChildren, setHideChildren] = useState(!expanded);

  // Append pixels if not a string
  const max = typeof maximum === 'number' ? `${maximum}px` : maximum;
  const min = typeof minimum === 'number' ? `${minimum}px` : minimum;

  useEffect(() => {
    // If we are expanding instantly show children
    if (expanded) {
      setHideChildren(false);
    } else {
      // Otherwise wait the transition time then hide children
      const timer = setTimeout(() => setHideChildren(!expanded), 600);
      // Clean up timer
      return () => clearTimeout(timer);
    }
  }, [expanded]);

  return (
    <CollapsibleBox
      expanded={expanded}
      minimum={min}
      maximum={max}
      direction={direction}
      {...props}
    >
      {!hideChildren && children}
    </CollapsibleBox>
  );
};

export default Collapsible;

Collapsible.propTypes = {
  direction: PropTypes.oneOf(['width', 'height']).isRequired,
  maximum: PropTypes.number,
  minimum: PropTypes.number,
  expanded: PropTypes.bool.isRequired,
};
