import React from 'react';

import { Chip } from '@styled-icons/boxicons-regular/Chip';
import { Hdd } from '@styled-icons/boxicons-regular/Hdd';
import { Microchip } from '@styled-icons/boxicons-regular/Microchip';
import { Microchip as GpuChip } from '@styled-icons/boxicons-solid/Microchip';

import Box from '@northflank/components/Box';
import Grid from '@northflank/components/Grid';
import Icon from '@northflank/components/Icon';
import Label from '@northflank/components/Label';
import Text from '@northflank/components/Text';
import { capitalize } from '@northflank/constants-base/functions';

import { gpuRounding } from '../utility/gpuRounding';

const Content = ({ contentIcon, primaryText, secondaryText, extra }) => (
  <Box variant={['flex', 'verticalCenter']}>
    <Icon Content={contentIcon} Size={20} mr={6} color="grey3" />
    <Text as="span" fontSize={[3, 3, 4]} mr={6}>
      {primaryText}{' '}
    </Text>
    <Text as="span" fontSize={[2, 2, 3]} color="grey2">
      {secondaryText}
    </Text>
    {extra}
  </Box>
);

const NodeDescription = ({
  cloudProviderId,
  cloudProviderName,
  providerNodeType,
  gpus,
  ...rest
}) => {
  const {
    name,
    id,
    oci,
    resources: { vcpu, memory, disk },
  } = providerNodeType;

  let cpu;
  let ram;
  const gpuCount = gpuRounding(gpus?.count);
  const gpu = gpus ? (
    <>
      {gpus.manufacturer} {gpus.displayName}
    </>
  ) : (
    false
  );
  const extra = gpus?.memoryInfo?.sizeInMiB ? (
    <Label ml={6}>{Math.floor(gpus.memoryInfo.sizeInMiB / 1024)} GB</Label>
  ) : (
    false
  );

  if (cloudProviderId === 'oci' && oci?.isFlex) {
    cpu = `${providerNodeType.resources.vcpuMin % 1 === 0 ? providerNodeType.resources.vcpuMin : providerNodeType.resources.vcpuMin.toFixed(2)} - ${providerNodeType.resources.vcpuMax % 1 === 0 ? providerNodeType.resources.vcpuMax : providerNodeType.resources.vcpuMax.toFixed(2)}`;
    ram = `${providerNodeType.resources.memoryMin % 1 === 0 ? providerNodeType.resources.memoryMin : providerNodeType.resources.memoryMin.toFixed(1)} - ${providerNodeType.resources.memoryMax % 1 === 0 ? providerNodeType.resources.memoryMax : providerNodeType.resources.memoryMax.toFixed(1)}`;
  } else {
    cpu = vcpu % 1 === 0 ? vcpu : vcpu.toFixed(2);
    ram = memory % 1 === 0 ? memory : memory.toFixed(1);
  }

  return (
    <Box fontSize={4} {...rest}>
      {cloudProviderName}{' '}
      <Text as="span" color="grey1" fontWeight={500}>
        {name || id}
      </Text>{' '}
      nodes offer:
      <Grid
        mt={9}
        gridTemplateColumns={
          gpu ? (disk ? 'repeat(4, 1fr)' : 'repeat(3, 1fr)') : 'repeat(2, 1fr)'
        }
        bg="grey10"
        border="1px solid"
        borderColor="grey5"
        borderRadius={3}
        p={4}
        py={6}
        pl={8}
      >
        <Content contentIcon={Chip} primaryText={cpu} secondaryText="vCPU" />
        <Content
          contentIcon={Microchip}
          primaryText={ram}
          secondaryText="GB memory"
        />
        {gpu ? (
          <Content
            contentIcon={GpuChip}
            primaryText={gpuCount}
            secondaryText={gpu}
            extra={extra}
          />
        ) : null}
        {disk ? (
          <Content
            contentIcon={Hdd}
            primaryText={parseFloat(disk.size?.toPrecision(2))}
            secondaryText={`GB ${disk?.disk ? `${capitalize(disk.disk)} ` : ''}storage`}
          />
        ) : null}
      </Grid>
    </Box>
  );
};

export default NodeDescription;
