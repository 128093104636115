import React from 'react';
import { useLocation } from 'react-router-dom';

import css from '@styled-system/css';
import styled from 'styled-components';

import Box from '@northflank/components/Box';

import PreHeading from '../PreHeading';

export const StyledLink = styled.a(() =>
  css({
    '&:hover': {
      borderBottom: '1px solid',
      borderColor: '#30F18E',
    },
  })
);

const Breadcrumbs = ({ mb }) => {
  const { pathname } = useLocation();
  const path = pathname.split('#');

  if (path.length) {
    const breadcrumbs = path[0].split('/').slice(1);
    const accumulatedLink = [];

    return (
      <Box variant="flex" mb={mb}>
        {breadcrumbs.map((crumb, i) => {
          accumulatedLink.push(`${i > 0 ? `/` : ``}${crumb}`);
          return (
            <Box key={crumb} ml={i > 0 && 2}>
              <PreHeading>
                {i > 0 && ` / `}
                <StyledLink href={`/${accumulatedLink.join('')}`}>
                  {decodeURIComponent(crumb)}
                </StyledLink>
              </PreHeading>
            </Box>
          );
        })}
      </Box>
    );
  }

  return null;
};

export default Breadcrumbs;
