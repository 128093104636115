import React from 'react';

import Text from '@northflank/components/Text';

const OciNote = ({ gpu }) => (
  <Text fontSize={2} color="grey3" mediaQuery mb={8}>
    OCI measures nodes in OCPU (Oracle CPU), for comparison we have converted
    OCPU values to vCPU.
    {!gpu &&
      ' OCI also offers flex nodes, which have user-defined vCPU and memory values.'}{' '}
    Available node types on OCI depend on your account and region, and may
    differ from the data shown here.
  </Text>
);

export default OciNote;
