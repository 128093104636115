import React, { useContext } from 'react';

import css from '@styled-system/css';
import styled, { ThemeContext } from 'styled-components';

import Box from '@northflank/components/Box';
import Grid from '@northflank/components/Grid';
import Icon from '@northflank/components/Icon';
import Text from '@northflank/components/Text';
import Tooltip from '@northflank/components/Tooltip';

const TabButton = styled.button(({ active, small }) =>
  css({
    background: 'none',
    border: 'none',
    color: active ? 'grey1' : 'grey2',
    fontFamily: 'body',
    fontSize: 2,
    cursor: 'pointer',
    px: 4,
    py: small ? 2 : 4,
    zIndex: 2,
    '&:hover': {
      color: 'grey1',
    },
    '&[disabled]': {
      color: 'grey4',
      cursor: 'not-allowed',
      '&:hover': {
        color: 'grey4',
      },
    },
  })
);

const WrapTooltip = ({ title, children }) =>
  title ? <Tooltip title={title}>{children}</Tooltip> : children;

const SlidingTabs = ({
  options,
  permissionCheckFunction,
  selected,
  handleSelect,
  small,
  fontSize = 2,
  tabGap,
  ...rest
}) => {
  const { name: themeName } = useContext(ThemeContext);

  const GAP = tabGap !== undefined ? tabGap : 4; // px

  const sliderWidth = `calc((100% / ${options.length}) - ${GAP * options.length}px)`;
  const index = options.findIndex((opt) => opt.key === selected);
  const left = `calc(((100% / ${options.length}) * ${index}) + ${Math.max(index, 1) * GAP}px)`;

  return (
    <Grid
      gridTemplateColumns={`repeat(${options.length}, 1fr)`}
      gridGap={`${GAP}px`}
      bg={themeName === 'dark' ? 'grey11' : 'grey7'}
      borderRadius={2}
      p={`${GAP}px`}
      position="relative"
      zIndex={0}
      {...rest}
    >
      {options.map((opt) => {
        const hasPermission =
          !opt.permission ||
          (permissionCheckFunction && permissionCheckFunction(opt.permission));

        const disabled = opt.disabled || !hasPermission;
        const tooltip = disabled
          ? (opt.disabledTooltip ?? 'Insufficient permissions')
          : opt.tooltip;
        return (
          <TabButton
            key={`tab-${opt.key}`}
            type="button"
            onClick={() => {
              if (!opt.preventDefaultAction) handleSelect(opt.key);
              if (typeof opt.onClick === 'function') opt.onClick(opt.key);
            }}
            active={selected === opt.key}
            disabled={disabled}
            small={small}
          >
            {opt.label && (
              <Box
                variant="flex"
                alignItems="center"
                justifyContent="center"
                mb={1}
              >
                {opt.icon && (
                  <Icon Content={opt.icon} Size={14} color="grey3" mr={2} />
                )}
                <Text color="grey3" fontSize={0}>
                  {opt.label}
                </Text>
              </Box>
            )}
            <WrapTooltip title={tooltip}>
              <Box variant="flex" alignItems="center" justifyContent="center">
                {opt.titleIcon && (
                  <Icon
                    Content={opt.titleIcon}
                    Size={16}
                    color={
                      opt.titleIconColor ??
                      (selected === opt.key
                        ? 'primary'
                        : disabled
                          ? 'grey4'
                          : 'grey3')
                    }
                    mr={4}
                  />
                )}
                <Text textAlign="center" fontSize={fontSize}>
                  {opt.title}
                </Text>
              </Box>
            </WrapTooltip>
            {opt.description && (
              <Text color="grey3" mt={3}>
                {opt.description}
              </Text>
            )}
          </TabButton>
        );
      })}
      {selected && (
        <Box
          bg={themeName === 'dark' ? 'grey7' : 'grey9'}
          width={sliderWidth}
          height={`calc(100% - ${GAP * 2}px)`}
          borderRadius={1}
          position="absolute"
          left={left}
          zIndex={1}
          _css={{ transition: '150ms ease' }}
        />
      )}
    </Grid>
  );
};

export default SlidingTabs;
