import React, { useState } from 'react';

import { PlayCircle } from '@styled-icons/boxicons-regular/PlayCircle';
import styled, { keyframes } from 'styled-components';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Icon from '@northflank/components/Icon';
import { Lightbox } from '@northflank/components/marketing/MarkdownBody';

const rotate = keyframes`
  from {
    transform: translate(-50%, -50%) rotate(0);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

const AnimatedBackground = styled(Box)`
  &::before {
    content: '';
    display: block;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      ${({ theme }) => theme.colors.primary} 50%,
      rgba(255, 255, 255, 0) 100%
    );
    height: 400px;
    width: 50px;
    position: absolute;
    animation: ${rotate} 7s linear forwards infinite;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform-origin: center;
  }
`;

const DemoVideo = ({ buttonProps, component }) => {
  const [play, setPlay] = useState(false);

  return (
    <>
      {component ? (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <span
          onClick={() => {
            setPlay(true);
          }}
        >
          {component}
        </span>
      ) : (
        <AnimatedBackground
          bg="grey5"
          borderRadius={1}
          position="relative"
          overflow="hidden"
          display="inline-block"
          p="1px"
        >
          <Button
            variant={['large', 'noBorder', 'noWrap']}
            onClick={() => {
              setPlay(true);
            }}
            position="relative"
            zIndex={1}
            {...buttonProps}
          >
            <Icon Content={PlayCircle} Size={16} mr={4} />
            Watch the demo
          </Button>
        </AnimatedBackground>
      )}

      {play && (
        <Lightbox
          close={() => {
            setPlay(false);
          }}
        >
          <Box
            as="video"
            preload="metadata"
            autoPlay
            playsInline
            controls
            width="100%"
            height="100%"
            borderRadius={3}
          >
            <source
              src="https://assets.northflank.com/northflank+demo+video+full+with+pauses+final.mp4"
              type="video/mp4"
            />
          </Box>
        </Lightbox>
      )}
    </>
  );
};

export default DemoVideo;
