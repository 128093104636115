import React from 'react';

import Box from '@northflank/components/Box';
import Grid from '@northflank/components/Grid';
import Heading from '@northflank/components/Heading';
import Text from '@northflank/components/Text';

import PreHeading from './PreHeading';

const Stat = ({ number, description }) => (
  <Box
    bg="grey9"
    variant="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    textAlign="center"
    borderRadius={3}
    p={8}
  >
    <Text fontSize={5} fontWeight={500} mb={6}>
      {number}
    </Text>
    <Text color="grey3">{description}</Text>
  </Box>
);

const StatsSection = ({ statsOnly }) => (
  <Box>
    {!statsOnly && (
      <Box width={[1, 1, 2 / 3]} textAlign="center" mx="auto" mb={[12, 12, 13]}>
        <PreHeading mb={6}>Experience, trust and scale</PreHeading>
        <Heading fontSize={[5, 5, '32px']} color="grey1" mb={8}>
          You’re in good company
        </Heading>
        <Text fontSize="18px" color="grey2">
          Northflank is a robust, scalable platform built by and trusted by
          developers worldwide since 2019. Whether you’re running 1 or 1,000
          services within a single project, we seamlessly support your workload
          and infrastructure requirements.
        </Text>
      </Box>
    )}
    <Grid
      gridTemplateColumns={['1fr', '1fr', 'repeat(5, 1fr)']}
      alignItems="stretch"
    >
      <Stat number="1.5M+" description="Monthy containers" />
      <Stat number="100B+" description="Requests processed" />
      <Stat number="300+" description="Availability zones" />
      <Stat number="25k+" description="Developers in production" />
      <Stat number="$24M+" description="Raised in funding" />
    </Grid>
  </Box>
);

export default StatsSection;
