import React, { useEffect, useState } from 'react';

import { Chip } from '@styled-icons/boxicons-regular/Chip';
import { Cloud } from '@styled-icons/boxicons-regular/Cloud';
import { Microchip } from '@styled-icons/boxicons-regular/Microchip';
import { World } from '@styled-icons/boxicons-regular/World';
import slugify from 'slugify';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Grid from '@northflank/components/Grid';
import Heading from '@northflank/components/Heading';
import Label from '@northflank/components/Label';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';

import Breadcrumbs from '../../../components/Breadcrumbs';
import CloudProviderGpuTable from '../../../components/CloudProviders/CloudProviderGpuTable';
import CloudProviderNodeTable from '../../../components/CloudProviders/CloudProviderNodeTable';
import CloudProviderRegions from '../../../components/CloudProviders/CloudProviderRegions';
import CloudProvidersNav from '../../../components/CloudProviders/CloudProvidersNav';
import { cloudProviderOptions } from '../../../components/CloudProviders/utility/cloudProviderOptions';
import { getDisplayId } from '../../../components/CloudProviders/utility/getDisplayId';
import { FeaturesWrapper } from '../../../components/FeaturePageWrapper';

export const Section = ({ anchor, children }) => (
  <Box
    position="relative"
    px={[9, 9, 12]}
    _css={{ '&:first-child > div': { borderTop: 0 } }}
  >
    {anchor && (
      <Box
        as="section"
        id={anchor}
        position="absolute"
        left={0}
        top={['-40px', '-40px', '-150px', '-120px', '-100px']}
        bottom={0}
        width="1px"
      />
    )}
    <Box
      as="section"
      variant="bounding"
      px="0px!important"
      py={[
        '64px!important',
        '64px!important',
        '64px!important',
        '96px!important',
      ]}
      borderTop="1px solid"
      borderTopColor="grey6"
    >
      {children}
    </Box>
  </Box>
);

const BYOCProvider = ({
  cloudProvider,
  providerNodes,
  providerRegions,
  gpuNodes,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const { id, name, engine, kubernetesVersions } = cloudProvider;

  const slugCloudName = slugify(name, { lower: true });
  const slugEngine = slugify(engine, { remove: /[._()]/g, lower: true });
  const displayId = getDisplayId(id);

  const [provider] = cloudProviderOptions.filter((p) => p.value === id);
  const providerImage = provider?.image ? provider.image : false;
  const ProviderIcon = provider?.icon ? provider.icon : Cloud;

  const navSubItems = () => {
    return gpuNodes?.length
      ? [
          {
            icon: ProviderIcon,
            title: `${engine} On Northflank`,
          },
          {
            icon: World,
            title: `${name} Regions Available on Northflank`,
          },
          {
            icon: Chip,
            title: `${name} Instances Available on Northflank`,
          },
          {
            icon: Microchip,
            title: `${name} GPU Instances Available on Northflank`,
          },
        ]
      : [
          {
            icon: ProviderIcon,
            title: `${engine} On Northflank`,
          },
          {
            icon: World,
            title: `${name} Regions Available on Northflank`,
          },
          {
            icon: Chip,
            title: `${name} Instances Available on Northflank`,
          },
        ];
  };

  const navItem = [
    {
      title: `${name}`,
      href: `/cloud/${id}`,
      subItems: navSubItems(),
    },
  ];

  useEffect(() => {
    const match = window.matchMedia('(max-width: 768px)');
    const query = (m) => {
      setIsMobile(m.matches);
    };
    query(match);
    match.addListener(query);
  }, []);

  return (
    <>
      <SEO
        title={`${name} on Northflank | Cloud Providers`}
        description={`Enhance your self-service developer experience or deliver an Internal Developer Platform (IDP) or Application Delivery Platform (ADP) on ${engine} using Northflank with your ${name} account.`}
      />
      <CloudProvidersNav />
      <FeaturesWrapper cloudProviderPage customNavItems={navItem}>
        <Section anchor={`${slugEngine}-on-northflank`}>
          <Breadcrumbs mb={6} />
          <Heading as="h1" color="grey1" fontSize={[5, 5, 6, 6]} mb={8}>
            {name} on Northflank
          </Heading>
          <Text fontSize={4} mediaQuery mb={8}>
            Kubernetes done right. Deliver apps, databases and cron jobs to
            production{' '}
            <Text as="span" color="grey1" fontWeight={500}>
              {engine ? ` with ${engine}` : ` on ${name}`}
            </Text>{' '}
            without losing sleep. Your complete application platform, on{' '}
            {displayId}, now.
          </Text>
          <Text color="grey2" fontSize={3} mediaQuery mb={8}>
            Easily create or import{' '}
            <Text as="span" color="grey1" fontWeight={500}>
              {engine || name} clusters
            </Text>{' '}
            with Northflank BYOC in your own cloud account, benefiting from
            Northflank’s extensive platform capabilities in your VPC while
            maintaining control over your data residency, deployment regions,
            cloud expenses, and security.
          </Text>
          <Text color="grey2" fontSize={3} mediaQuery mb={13}>
            Deploy, release, and scale workloads across {displayId} regions or
            availability zones with advanced workflow automation and DevOps
            multiplayer collaboration. Northflank BYOC is ideal for enhancing{' '}
            <Text as="span" color="grey1" fontWeight={500}>
              self-service developer experience
            </Text>{' '}
            or delivering an{' '}
            <Text as="span" color="grey1" fontWeight={500}>
              Internal Developer Platform (IDP)
            </Text>{' '}
            or{' '}
            <Text as="span" color="grey1" fontWeight={500}>
              Application Delivery Platform (ADP)
            </Text>{' '}
            on{' '}
            <Text as="span" color="grey1" fontWeight={500}>
              {engine || name}
            </Text>
            .
          </Text>
          <Grid
            gridTemplateColumns={['1fr', '1fr', '1fr', 'repeat(3, 1fr)']}
            alignItems="start"
            gridGap={12}
          >
            <Box my="auto">
              <a
                href={`https://app.northflank.com/s/account/cloud/clusters/new/${id}`}
                target="_blank"
                style={{ display: 'inline-block' }}
                rel="noreferrer"
              >
                <Button variant={['large', 'primary']}>
                  Deploy on {displayId} now
                </Button>
              </a>
            </Box>
            <Box>
              Engine
              <Label mt={4}>{engine}</Label>
            </Box>
            <Box>
              Kubernetes versions
              <Box variant="flex">
                {kubernetesVersions.map((l, index) => (
                  <Label key={l} ml={index > 0 ? 6 : 0} mt={4}>
                    {l}
                  </Label>
                ))}
              </Box>
            </Box>
          </Grid>
          {providerImage ? (
            <Box
              as="img"
              src={providerImage}
              width="100%"
              mt={14}
              alt={`Diagram showing ${engine} paired with the Northflank developer platform and control plane with database and queues, container registries, CDNs, observability, version control, and languages and frameworks.`}
            />
          ) : null}
        </Section>
        <Section anchor={`${slugCloudName}-regions-available-on-northflank`}>
          <CloudProviderRegions
            cloudProviderId={id}
            regions={providerRegions}
            title={`${name} Regions Available on Northflank`}
          />
        </Section>
        <Section anchor={`${slugCloudName}-instances-available-on-northflank`}>
          <Heading color="grey1" fontSize={[5, 5, 6, 6]} mb={10}>
            {name} Instances Available on Northflank
          </Heading>
          <CloudProviderNodeTable nodeTypes={providerNodes} providerId={id} />
        </Section>
        {gpuNodes?.length ? (
          <Section
            anchor={`${slugCloudName}-gpu-instances-available-on-northflank`}
          >
            <Heading color="grey1" fontSize={[5, 5, 6, 6]} mb={10}>
              {name} GPU Instances Available on Northflank
            </Heading>
            <CloudProviderGpuTable
              providerId={id}
              nodes={gpuNodes}
              isMobile={isMobile}
            />
          </Section>
        ) : null}
      </FeaturesWrapper>
    </>
  );
};

const NorthflankProvider = ({
  cloudProvider,
  providerNodes,
  providerRegions,
}) => {
  const { id, name } = cloudProvider;

  const navItem = [
    {
      title: `${name}`,
      href: `/cloud/${id}`,
      subItems: [
        {
          icon: World,
          title: `Northflank Regions`,
        },
        {
          icon: Chip,
          title: `Northflank Plans`,
        },
      ],
    },
  ];

  return (
    <>
      <SEO
        title={`Northflank's Managed Cloud | Cloud Providers`}
        description="Northflank’s managed cloud gives you the power of the Northflank platform, with no infrastructure setup required. Deploy any project with a hassle-free Kubernetes experience."
      />
      <CloudProvidersNav />
      <FeaturesWrapper cloudProviderPage customNavItems={navItem}>
        <Box variant="bounding" pb="70px!important">
          <Breadcrumbs mb={6} />
          <Heading as="h1" color="grey1" fontSize={[5, 5, 6, 6]} mb={8}>
            Northflank’s Managed Cloud
          </Heading>
          <Text fontSize={4} mediaQuery mb={8}>
            Northflank’s managed cloud gives you the power of the Northflank
            platform, with no infrastructure setup required. Deploy any project
            with a hassle-free Kubernetes experience.
          </Text>
        </Box>
        <Section anchor="northflank-regions">
          <CloudProviderRegions
            cloudProviderId={id}
            regions={providerRegions}
            title={`${name} Regions`}
          />
        </Section>
        <Section anchor="northflank-plans">
          <Heading color="grey1" fontSize={[5, 5, 6]} mb={10}>
            {name} Plans
          </Heading>
          <CloudProviderNodeTable nodeTypes={providerNodes} providerId={id} />
        </Section>
      </FeaturesWrapper>
    </>
  );
};

const Provider = ({
  cloudProvider,
  providerRegions,
  providerNodes,
  gpuNodes,
}) => {
  const northflank = cloudProvider?.id && cloudProvider.id === 'northflank';

  return northflank ? (
    <NorthflankProvider
      cloudProvider={cloudProvider}
      providerRegions={providerRegions}
      providerNodes={providerNodes}
    />
  ) : (
    <BYOCProvider
      cloudProvider={cloudProvider}
      providerRegions={providerRegions}
      providerNodes={providerNodes}
      gpuNodes={gpuNodes}
    />
  );
};

export default Provider;
