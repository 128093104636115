import React, { useContext, useEffect, useRef, useState } from 'react';

import styled, { ThemeContext } from 'styled-components';

import Box from '@northflank/components/Box';

import Svg from './flexibility.svg';

const Wrapper = styled(Box)`
  position: relative;

  .glow {
    filter: blur(4px);
    backdrop-filter: blur(4px);
    opacity: 0.6;
  }

  .path {
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
  }
`;

const animatedGroups = [
  { id: 'path-vcs' },
  { id: 'path-cr' },
  { id: 'path-cdn' },
  { id: 'path-ci' },
  { id: 'path-lang', reverse: true },
  { id: 'path-db', reverse: true },
  { id: 'path-logs', reverse: true },
  { id: 'path-resources', reverse: true },
  { id: 'path-cloud' },
];

const MemoSvg = React.memo(Svg);

const FlexibilityImage = () => {
  const [tooltip, setTooltip] = useState();

  const imageRef = useRef();

  const theme = useContext(ThemeContext);

  useEffect(() => {
    const handleMouseEnter = (e) => {
      const { id } = e.target;
      const [_, _type, text] = id.split('-');
      setTooltip({
        show: true,
        text: text.replaceAll('_', ' '),
        id,
        pos: {
          x:
            e.target.getBoundingClientRect().left -
            imageRef.current.getBoundingClientRect().left,
          y:
            e.target.getBoundingClientRect().top -
            imageRef.current.getBoundingClientRect().top,
        },
      });
    };

    const handleMouseLeave = () => {
      setTooltip((t) => {
        return { ...t, show: false };
      });
    };

    const isSafari =
      navigator.userAgent.includes('Safari') &&
      !navigator.userAgent.includes('Chrome') &&
      !navigator.userAgent.includes('Chromium');

    if (imageRef.current) {
      const tooltipElements =
        imageRef.current.querySelectorAll(`[id*="tooltip"]`);

      for (const el of tooltipElements) {
        el.addEventListener('mouseenter', handleMouseEnter);
        el.addEventListener('mouseleave', handleMouseLeave);
      }

      for (const { id, reverse } of animatedGroups) {
        const animatedPaths = imageRef.current.querySelectorAll(
          `#flexibility_svg__${id} path`
        );

        const greenIndex = Math.floor(Math.random() * 3);

        animatedPaths.forEach((path, i) => {
          const dash = [];

          for (let j = 0; j <= 5; j++) {
            dash.push(
              Math.floor(Math.random() * 20) + 3,
              Math.floor(Math.random() * 10) + 1
            );
          }

          const color =
            greenIndex === i ? theme.colors.success : theme.colors.primary;

          path.setAttributeNS(null, 'stroke', color);
          path.setAttributeNS(null, 'stroke-dasharray', dash.join(','));
          path.setAttributeNS(null, 'class', 'path');

          if (!isSafari) {
            const animate = document.createElementNS(
              'http://www.w3.org/2000/svg',
              'animate'
            );
            animate.setAttributeNS(null, 'attributeName', 'stroke-dashoffset');
            animate.setAttributeNS(null, 'dur', '10s');
            animate.setAttributeNS(null, 'from', reverse ? '-500' : '500');
            animate.setAttributeNS(null, 'to', '0');
            animate.setAttributeNS(null, 'repeatCount', 'indefinite');
            path.appendChild(animate);

            const group = imageRef.current.querySelector(
              `g#flexibility_svg__${id}`
            );

            const pathGlow = document.createElementNS(
              'http://www.w3.org/2000/svg',
              'path'
            );
            pathGlow.setAttributeNS(null, 'stroke', color);
            pathGlow.setAttributeNS(null, 'stroke-width', '4');
            pathGlow.setAttributeNS(null, 'd', path.getAttribute('d'));
            pathGlow.setAttributeNS(null, 'class', 'path glow');
            group.appendChild(pathGlow);
          }
        });
      }

      return () => {
        for (const el of tooltipElements) {
          el.removeEventListener('mouseenter', handleMouseEnter);
          el.removeEventListener('mouseleave', handleMouseLeave);
        }
      };
    }
  }, []);

  return (
    <Wrapper>
      <Box ref={imageRef}>
        <MemoSvg />
      </Box>
      {tooltip && (
        <Box
          position="absolute"
          left={tooltip.pos.x + 24}
          top={tooltip.pos.y - 38}
          bg="grey11"
          color="grey1"
          fontWeight={500}
          border="1px solid"
          borderColor="grey5"
          borderRadius={2}
          opacity={tooltip.show ? 1 : 0}
          boxShadow="dropDown"
          px={6}
          py={3}
          _css={{
            pointerEvents: 'none',
            transform: 'translateX(-50%)',
            transition: '200ms',
          }}
        >
          {tooltip.text}
        </Box>
      )}
    </Wrapper>
  );
};

export default FlexibilityImage;
