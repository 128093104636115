import React, { useEffect, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Prompt, useHistory, useLocation } from 'react-router-dom';

import 'prismjs';
import 'prismjs/components/prism-markup-templating';
import 'prismjs/components/prism-jsx';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-elixir';
import 'prismjs/components/prism-ruby';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-rust';
import 'prismjs/components/prism-go';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-csharp';
import 'prismjs/components/prism-c';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-yaml';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-docker';
import 'prismjs/components/prism-log';
import 'prismjs/components/prism-markdown';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-git';
import '@northflank/components/marketing/prism/prism-custom.css';
import '../../public/font/JetBrainsMono/jetbrains.css';
import '../../public/font/inter/inter.css';
import '../../public/font/instrumentserif.css';

import { ErrorCircle } from '@styled-icons/boxicons-regular/ErrorCircle';
import Cookies from 'js-cookie';
import memoize from 'memoizee';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

import Box from '@northflank/components/Box';
import Icon from '@northflank/components/Icon';
import Announcement from '@northflank/components/marketing/Announcement';
import Footer from '@northflank/components/marketing/Footer';
import Nav from '@northflank/components/marketing/Nav';
import { createNorthflankTheme } from '@northflank/theme/core';
import { GlobalStyle } from '@northflank/theme/globals';

import ErrorBoundary from './components/ErrorBoundary';
import Routes from './Routes';
import SvgGradient from './svg/gradient.svg';

export const theme = createNorthflankTheme({
  name: 'dark',
  customBreakpoints: 'marketingBreakpoints',
});

const CustomGlobalStyle = createGlobalStyle`
  :root {
    --app-height: 100%;
  }
  html {
    scroll-padding-top: 104px;
  }
  body {
    -webkit-font-smoothing: antialiased;
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 600 !important;
  }
  .grecaptcha-badge {
    visibility: hidden;
  }
`;

export const EditPreviewContext = React.createContext({});

const getAnnouncement = async () => {
  const res = await fetch('/api/fetch-announcement');
  if (res.ok) return await res.json();
  return null;
};

const getLatestAnnouncementMemo = memoize(getAnnouncement, {
  promise: true,
  maxAge: 1000 * 60 * 5,
});

/*
  We need to hold on to the data for the current page while we navigate away, so
  that data is not unloaded before navigation is complete. We hold page data in
  an object keyed by pathname, and if we have >10 keys stored then we find the
  oldest, and remove it. This stops the map getting large after lots of
  navigation events.

  We also need to keep some recent page data around to cater to browser back
  navigation, as this will not trigger the <Prompt /> data fetcher.
*/
const updatePageData = (location, current, newItem) => {
  const __ts = Date.now();

  current.pathname = location.pathname;
  current.search = location.search;
  current.data[location.pathname] = { ...newItem, __ts };
  current.__ts = __ts;

  const items = Object.values(current.data);
  if (items.length > 10) {
    const oldestTs = Math.min(...items.map((i) => i.__ts));
    const oldestIndex = items.findIndex((i) => i.__ts === oldestTs);
    if (oldestIndex > -1) {
      const oldestKey = Object.keys(current.data)[oldestIndex];
      if (oldestKey) delete current.data[oldestKey];
    }
  }

  return current;
};

const App = ({
  pathname: _pathname,
  search: _search,
  ssrData: _ssrData,
  error = false,
  clientEnvs = {},
}) => {
  const [ssrData, setSsrData] = useState({
    pathname: _pathname,
    search: _search,
    data: {
      [_pathname]: { ..._ssrData, __ts: Date.now() },
    },
    __ts: 0,
  });
  const [hasError, setHasError] = useState(!!error);
  const [isFirstNavigation, setIsFirstNavigation] = useState(true);

  const { pathname, search } = useLocation();
  const history = useHistory();

  /*
    `getPageData` in conjunction with the <Prompt /> component actually cancels
    client-side navigation, unless the `skipData` state is present. What it does
    instead is initiates a fetch to get any required page data, and only when it
    completes updates the `ssrData` state to contain the next page's path and
    data. The subsequent useEffect listens for this update, and then updates the
    history. This ensures page data is always ready before we try and render the
    page.
  */
  const getPageData = (location, currentLocation) => {
    if (location.state?.skipData) return true;

    if (
      location.pathname === currentLocation.pathname &&
      location.search === currentLocation.search
    ) {
      return true;
    }

    setHasError(false);

    let toFetch = `/api/get-page-data`;

    if (location.search) {
      toFetch += location.search;
      toFetch += `&pathname=${location.pathname}`;
    } else {
      toFetch += `?pathname=${location.pathname}`;
    }

    fetch(toFetch, { signal: AbortSignal.timeout(10000) })
      .then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            setSsrData((cur) => updatePageData(location, { ...cur }, data));
          });
        } else {
          setSsrData((cur) => updatePageData(location, { ...cur }, {}));
          setHasError(true);
        }
      })
      .catch(() => {
        setSsrData((cur) => updatePageData(location, { ...cur }, {}));
        setHasError(true);
      });

    return false;
  };

  /* see `getPageData` comment */
  useEffect(() => {
    if (ssrData.pathname) {
      let url = ssrData.pathname;
      if (ssrData.search) url += ssrData.search;

      /*
        If this is the initial landing page, we don't want to push to history,
        or we'll get a double entry
      */
      if (!isFirstNavigation) {
        history.push(url, { skipData: true });
      }

      setIsFirstNavigation(false);
    }
  }, [ssrData.pathname, ssrData.search, ssrData.__ts]);

  const [showEditPreview, setShowEditPreview] = useState(false);
  const [announcement, setAnnouncement] = useState();

  useEffect(() => {
    if (clientEnvs.POSTHOG_KEY) {
      posthog.init(clientEnvs.POSTHOG_KEY, {
        api_host: clientEnvs.POSTHOG_HOST,
        opt_out_capturing_by_default: true,
        person_profiles: 'identified_only',
        loaded: (posthog) => {
          if (clientEnvs.NODE_ENV === 'production') {
            posthog.opt_in_capturing();
          } else {
            posthog.debug(false);
            posthog.opt_out_capturing();
          }
        },
      });
    }
  }, []);

  useEffect(() => {
    setSsrData(({ data, ...rest }) => {
      const d = { ...data };

      /*
        If we navigate back to a page we already have data for, update the
        timestamp so that it gets bumped back to the top of our history stack
        and doesn't get removed.

        Don't update `ssrData.pathname` as we'd get extra PUSH events on the
        history stack.
      */
      if (d[pathname]) {
        d[pathname].__ts = Date.now();
      }

      return {
        ...rest,
        data: d,
      };
    });

    window.scrollTo(0, 0);

    if (showEditPreview) setShowEditPreview(false);

    (async () => {
      const ann = await getLatestAnnouncementMemo();
      const hidden = Cookies.get('announcement-hidden-id');
      if (ann && ann.id !== hidden) setAnnouncement(ann);
    })();

    posthog?.capture('$pageview');

    if (window.gtag) {
      window.gtag('config', 'G-9HR4ZZBLMY', {
        page_path: window.location.href,
      });
    }

    if (window.analytics) {
      window.analytics.page({
        url: window.location.href,
      });
    }
  }, [pathname, search]);

  const showNoScript =
    !pathname.startsWith('/blog/') &&
    !pathname.startsWith('/changelog/') &&
    !pathname.startsWith('/guides/') &&
    !pathname.startsWith('/stacks/');

  const pageData = ssrData.data[encodeURI(pathname)] ?? {};
  // console.log({
  //   pathname: encodeURI(pathname),
  //   ssrData,
  //   _ssrData,
  //   pageData,
  //   hasError,
  //   isFirstNavigation,
  // });

  return (
    <>
      {/* see `getPageData` comment */}
      <Prompt
        message={(location) => getPageData(location, { pathname, search })}
      />
      <PostHogProvider client={posthog}>
        <ThemeProvider theme={theme}>
          <GlobalStyle marketing />
          <CustomGlobalStyle />
          <div dangerouslySetInnerHTML={{ __html: pageData.__mdxStyles }} />
          <ErrorBoundary location={{ pathname }} logo>
            <Box position="absolute" top="-1000px" left="-1000px">
              <SvgGradient />
            </Box>
            <GoogleReCaptchaProvider reCaptchaKey="6LcwoK0UAAAAAP22Vtddbpx2xclT2MdMG8Tvd8V7">
              <EditPreviewContext.Provider
                value={{ showEditPreview, setShowEditPreview }}
              >
                <Box width={showEditPreview && '70%'}>
                  {showNoScript && (
                    <noscript>
                      <Box
                        variant="flex"
                        alignItems="center"
                        justifyContent="center"
                        bg="warning"
                        color="background"
                        p={6}
                      >
                        <Icon
                          Content={ErrorCircle}
                          Size={18}
                          mr={4}
                          title="Error"
                        />
                        Not all features of this website will work with
                        JavaScript disabled. Please consider enabling it.
                      </Box>
                    </noscript>
                  )}
                  {announcement && (
                    <Announcement
                      announcementId={announcement.id}
                      href={announcement.attributes.url}
                      text={announcement.attributes.text}
                      setAnnouncement={setAnnouncement}
                    />
                  )}
                  <React.Fragment key={pageData?.__ts}>
                    <Nav
                      onGuides={pathname.startsWith('/guides')}
                      onStacks={pathname.startsWith('/stacks')}
                    />
                    {/* 100vh - header height - footer height */}
                    <main
                      id="main"
                      style={{ minHeight: 'calc(100vh - 80px - 472px)' }}
                    >
                      <ErrorBoundary location={{ pathname }}>
                        <Routes
                          pageData={pageData}
                          notFound={!!pageData.notFound}
                          error={hasError}
                        />
                      </ErrorBoundary>
                    </main>
                    <Footer />
                  </React.Fragment>
                </Box>
              </EditPreviewContext.Provider>
            </GoogleReCaptchaProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </PostHogProvider>
    </>
  );
};

export default App;
