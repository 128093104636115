export class TemplateError extends Error {
  constructor(message, args?: any) {
    super();
    this.message = message;

    if (args) Object.assign(this, args);

    Error.captureStackTrace(this, TemplateError);
  }
}
