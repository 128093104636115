import React from 'react';
import { Link } from 'react-router-dom';

import { Chip } from '@styled-icons/boxicons-regular/Chip';
import slugify from 'slugify';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Heading from '@northflank/components/Heading';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import CloudProviderNodeTable from '../../../../components/CloudProviders/CloudProviderNodeTable';
import CloudProvidersNav from '../../../../components/CloudProviders/CloudProvidersNav';
import { getDisplayId } from '../../../../components/CloudProviders/utility/getDisplayId';
import { FeaturesWrapper } from '../../../../components/FeaturePageWrapper';
import { Section } from '../index';

const BYOCProvider = ({ cloudProvider, providerNodes }) => {
  const { id, name, engine } = cloudProvider;

  const displayId = getDisplayId(id);
  const slugCloudName = slugify(name, { lower: true });

  const navItem = [
    {
      title: `${name}`,
      href: `/cloud/${id}/instances`,
      subItems: [
        {
          icon: Chip,
          title: `${name} Instances on Northflank`,
        },
      ],
    },
  ];

  return (
    <>
      <SEO
        title={`${name} Instances | Cloud Providers`}
        description={`Deploy Northflank projects onto ${engine || name} instances.`}
      />
      <CloudProvidersNav />
      <FeaturesWrapper cloudProviderPage customNavItems={navItem}>
        <Section anchor={`${slugCloudName}-instances-on-northflank`}>
          <Breadcrumbs mb={6} />
          <Heading as="h1" color="grey1" fontSize={[5, 5, 6, 6]} mb={8}>
            {name} Instances on Northflank
          </Heading>
          <Text fontSize={4} mediaQuery mb={13}>
            Deploy into your own {name} account onto the following instances
            with{' '}
            <Link to="/features/bring-your-own-cloud">
              Bring Your Own Cloud (BYOC)
            </Link>
            . Enjoy all the benefits of Northflank’s platform layer while
            maintaining control over your data residency, deployment regions,
            cloud expenses, and security.
          </Text>
          <a
            href={`https://app.northflank.com/s/account/cloud/clusters/new/${id}`}
            target="_blank"
            style={{ display: 'inline-block' }}
            rel="noreferrer"
          >
            <Button variant={['large', 'primary']}>
              Deploy on {displayId} now
            </Button>
          </a>
        </Section>
        <Box
          variant="bounding"
          px="0px!important"
          py={[
            '64px!important',
            '64px!important',
            '64px!important',
            '96px!important',
          ]}
          borderTop="1px solid"
          borderColor="grey6"
        >
          <CloudProviderNodeTable nodeTypes={providerNodes} providerId={id} />
        </Box>
      </FeaturesWrapper>
    </>
  );
};

const NorthflankProvider = ({ cloudProvider, providerNodes }) => {
  const { id, name } = cloudProvider;

  const navItem = [
    {
      title: `${name}`,
      href: `/cloud/${id}/instances`,
      subItems: [
        {
          icon: Chip,
          title: `Northflank Plans`,
        },
      ],
    },
  ];

  return (
    <>
      <SEO
        title={`${name} | Cloud Providers`}
        description={`Deploy projects on ${name}.`}
      />
      <CloudProvidersNav />
      <FeaturesWrapper cloudProviderPage customNavItems={navItem}>
        <Box id="northflank-plans" variant="bounding" pb="70px!important">
          <Breadcrumbs mb={6} />
          <Heading as="h1" color="grey1" fontSize={[5, 5, 6, 6]} mb={8}>
            {name} Plans
          </Heading>
          <Text fontSize={4} mediaQuery mb={8}>
            Northflank’s managed cloud gives you the power of the Northflank
            platform, with no infrastructure setup required. Deploy any project
            with a hassle-free Kubernetes experience. Services, jobs, and addons
            can be deployed with the following resource plans.
          </Text>
          <CloudProviderNodeTable nodeTypes={providerNodes} providerId={id} />
        </Box>
      </FeaturesWrapper>
    </>
  );
};

const Instances = ({ cloudProvider, providerNodes }) => {
  if (!cloudProvider || !providerNodes.length) {
    return null;
  }

  const northflank = cloudProvider?.id && cloudProvider.id === 'northflank';

  return northflank ? (
    <NorthflankProvider
      cloudProvider={cloudProvider}
      providerNodes={providerNodes}
    />
  ) : (
    <BYOCProvider cloudProvider={cloudProvider} providerNodes={providerNodes} />
  );
};

export default Instances;
