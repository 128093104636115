import React, { useMemo } from 'react';

import { useField, useFormikContext } from 'formik';

import Select from '../Select';

const deepFind = (options, query) => {
  const iterator = options.values();

  let opt = iterator.next();
  while (!opt.done) {
    const { value } = opt;
    if (value.options) {
      const nestedResult = deepFind(value.options, query);
      if (nestedResult) return nestedResult;
    }
    if (query(value)) return value;
    opt = iterator.next();
  }
  return undefined;
};

const FormikSelect = (props) => {
  const {
    options = [],
    simpleValue,
    isMulti,
    onChange: propsOnChange,
    value: propsValue,
    isCreatable,
    customError,
  } = props;
  const { submitCount } = useFormikContext();
  const [field, meta, helpers] = useField(props);
  const { error, touched } = meta;
  const { setValue, setTouched } = helpers;
  const { value } = field;

  const selected = useMemo(() => {
    if (typeof value === 'undefined') return '';
    if (!simpleValue) return value;
    if (isMulti) {
      return value.map((v) => deepFind(options, (o) => o.value === v) || '');
    }
    const result = deepFind(options, (o) => o.value === value) || '';
    if (isCreatable && !result) {
      return { label: value, value };
    }
    return result;
  }, [value, simpleValue, options]);

  const onChange = (newValue) => {
    if (simpleValue) {
      if (isMulti) {
        if (!newValue) {
          if (propsOnChange) propsOnChange([]);
          else setValue([], true);
        } else if (propsOnChange) {
          propsOnChange(newValue.map((o) => o.value));
        } else {
          setValue(
            newValue.map((o) => o.value),
            true
          );
        }
      } else if (propsOnChange) {
        propsOnChange(newValue?.value);
      } else {
        setValue(newValue?.value, true);
      }
    } else if (propsOnChange) {
      propsOnChange(newValue);
    } else {
      setValue(newValue, true);
    }
  };

  return (
    <Select
      {...props}
      value={propsValue || selected}
      onChange={onChange}
      onBlur={() => setTouched(true)}
      error={(touched || submitCount > 0) && (customError || error)}
    />
  );
};

export default FormikSelect;
