import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import css from '@styled-system/css';
import { transparentize } from 'polished';
import styled from 'styled-components';

import Box from '@northflank/components/Box';
import IconWithGlow from '@northflank/components/marketing/IconWithGlow';
import Text from '@northflank/components/Text';

const Tab = styled.button(
  ({ theme, active }) =>
    css({
      appearance: 'none',
      display: 'flex',
      alignItems: 'center',
      bg: active ? transparentize(0.8, theme.colors.success) : 'transparent',
      color: active ? 'success' : 'grey1',
      fontFamily: 'body',
      fontSize: 3,
      whiteSpace: 'nowrap',
      border: 0,
      borderRadius: 2,
      px: 7,
      py: 6,
      cursor: 'pointer',
      transition: '150ms',
      svg: {
        fill: active ? 'success' : 'grey3',
        transition: '150ms',
      },
      '&:hover': {
        bg: active
          ? transparentize(0.8, theme.colors.success)
          : transparentize(0.8, theme.colors.grey5),
        svg: {
          fill: 'success',
        },
      },
    }),
  ({ active }) => `
    ${IconWithGlow} {
      &::before {
        opacity: ${active ? 0.5 : 0};
      }
    }
    &:hover {
      ${IconWithGlow} {
        &::before {
          opacity: 0.5;
        }
      }
    }
  `
);

const WrapLink = ({ href, useA, children }) => {
  if (useA) {
    return href ? <a href={href}>{children}</a> : children;
  }
  return href ? <Link to={href}>{children}</Link> : children;
};

const FeatureTabs = ({
  tabs,
  activeTab,
  setActiveTab,
  scrollOnChange,
  useA,
  ...rest
}) => {
  const containerRef = useRef();
  const activeTabRef = useRef();

  useEffect(() => {
    if (scrollOnChange && containerRef.current && activeTabRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const tabsWidth = Array.from(
        containerRef.current.querySelectorAll('button')
      ).reduce((acc, cur) => acc + cur.offsetWidth, 0);
      if (tabsWidth > containerWidth) {
        activeTabRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        });
      }
    }
  }, [activeTab]);

  return (
    <Box
      ref={containerRef}
      variant="flex"
      alignItems="center"
      width="fit-content"
      bg="grey11"
      borderRadius={3}
      p={4}
      maxWidth="100%"
      overflowX="auto"
      _css={{
        '> a': {
          display: 'inline-block',
        },
        '> * + *': {
          ml: 4,
        },
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
      {...rest}
    >
      {tabs.map(({ value, icon, label, link }) => (
        <WrapLink key={value} href={link} useA={useA}>
          <Tab
            ref={activeTab === value ? activeTabRef : undefined}
            onClick={() => {
              if (typeof setActiveTab === 'function') setActiveTab(value);
            }}
            active={activeTab === value}
          >
            <IconWithGlow Content={icon} Size={20} mr={5} />
            <Text fontSize={3}>{label}</Text>
          </Tab>
        </WrapLink>
      ))}
    </Box>
  );
};

export default FeatureTabs;
