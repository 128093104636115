import React, { useContext } from 'react';

import { transparentize } from 'polished';
import { ThemeContext } from 'styled-components';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';

import HeroHeading from '../HeroHeading';

const GetStartedCta = ({ fontSize = 6, secondaryButton, ...rest }) => {
  const theme = useContext(ThemeContext);
  return (
    <Box
      display={['block', 'block', 'flex']}
      alignItems="center"
      justifyContent="space-between"
      // background={`linear-gradient(to left, rgba(0, 0, 0, 0) 15%, #192338 70%), ${topog}`}
      backgroundColor="grey9"
      backgroundImage={[
        'none',
        'none',
        `linear-gradient(to left, ${transparentize(0.7, theme.colors.grey9)} 15%, ${
          theme.colors.grey9
        } 70%), url(/images/hero-2023.png)`,
      ]}
      backgroundSize="120%"
      backgroundPosition="0% 47%"
      border="1px solid"
      borderColor="grey5"
      borderRadius={3}
      boxShadow="dropDown"
      textAlign={['center', 'center', 'left']}
      p={12}
      py={[12, 12, 13]}
      {...rest}
    >
      <Box mr={[0, 0, 10]} mb={[10, 10, 0]}>
        <HeroHeading fontSize={fontSize}>
          Ready to level up your DevOps?
        </HeroHeading>
      </Box>
      <Box
        variant="flex"
        flexDirection={['column', 'column', 'column', 'row']}
        justifyContent="center"
      >
        {secondaryButton ?? (
          <a
            href="https://cal.com/team/northflank/northflank-demo?duration=30"
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: 'inline-block' }}
          >
            <Button
              id="level-up-schedule-demo"
              variant={['large']}
              mr="auto"
              ml={['auto', 'auto', 0]}
              mb={[6, 6, 6, 0]}
              width="200px"
            >
              Schedule a demo
            </Button>
          </a>
        )}
        <Box
          mr="auto"
          ml={['auto', 'auto', 'auto', 6]}
          width="200px"
          position="relative"
          zIndex={0}
          _css={{
            '&::before': {
              content: `""`,
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              bg: 'primary',
              borderRadius: 1,
              filter: 'blur(12px)',
              zIndex: -1,
            },
          }}
        >
          <a
            href="https://app.northflank.com/signup"
            style={{ display: 'inline-block' }}
          >
            <Button
              id="level-up-get-started"
              variant={['large', 'primary']}
              width="200px"
            >
              Get started for free
            </Button>
          </a>
        </Box>
      </Box>
    </Box>
  );
};

export default GetStartedCta;
