// https://www.digitalocean.com/docs/accounts/billing/taxes/
// http://kirste.userpage.fu-berlin.de/diverse/doc/ISO_3166.html

export const vatRates = [
  {
    country: 'Austria',
    iso_code: 'AT',
    percentage: {
      b2c: 20.0,
      b2b: 0,
    },
    acceptTaxIds: true,
  },
  {
    country: 'Belgium',
    iso_code: 'BE',
    percentage: {
      b2c: 21.0,
      b2b: 0,
    },
    acceptTaxIds: true,
  },
  {
    country: 'Bulgaria',
    iso_code: 'BG',
    percentage: {
      b2c: 20.0,
      b2b: 0,
    },
    acceptTaxIds: true,
  },
  {
    country: 'Croatia',
    iso_code: 'HR',
    percentage: {
      b2c: 25.0,
      b2b: 0,
    },
    acceptTaxIds: true,
  },
  {
    country: 'Cyprus',
    iso_code: 'CY',
    percentage: {
      b2c: 19.0,
      b2b: 0,
    },
    acceptTaxIds: true,
  },
  {
    country: 'Czech Republic',
    iso_code: 'CZ',
    percentage: {
      b2c: 21.0,
      b2b: 0,
    },
    acceptTaxIds: true,
  },
  {
    country: 'Denmark',
    iso_code: 'DK',
    percentage: {
      b2c: 25.0,
      b2b: 0,
    },
    acceptTaxIds: true,
  },
  {
    country: 'Estonia',
    iso_code: 'EE',
    percentage: {
      b2c: 20.0,
      b2b: 0,
    },
    acceptTaxIds: true,
  },
  {
    country: 'Finland',
    iso_code: 'FI',
    percentage: {
      b2c: 24.0,
      b2b: 0,
    },
    acceptTaxIds: true,
  },
  {
    country: 'France',
    iso_code: 'FR',
    percentage: {
      b2c: 20.0,
      b2b: 0,
    },
    acceptTaxIds: true,
  },
  {
    country: 'Germany',
    iso_code: 'DE',
    percentage: {
      b2c: 19.0,
      b2b: 0,
    },
    acceptTaxIds: true,
  },
  {
    country: 'Greece',
    iso_code: 'GR',
    percentage: {
      b2c: 24.0,
      b2b: 0,
    },
    acceptTaxIds: true,
  },
  {
    country: 'Ireland (Eire)',
    iso_code: 'IE',
    percentage: {
      b2c: 23.0,
      b2b: 0,
    },
    acceptTaxIds: true,
  },
  {
    country: 'Hungary',
    iso_code: 'HU',
    percentage: {
      b2c: 27.0,
      b2b: 0,
    },
    acceptTaxIds: true,
  },
  {
    country: 'Italy',
    iso_code: 'IT',
    percentage: {
      b2c: 22.0,
      b2b: 0,
    },
    acceptTaxIds: true,
  },
  {
    country: 'Latvia',
    iso_code: 'LV',
    percentage: {
      b2c: 21.0,
      b2b: 0,
    },
    acceptTaxIds: true,
  },
  {
    country: 'Lithuania',
    iso_code: 'LT',
    percentage: {
      b2c: 21.0,
      b2b: 0,
    },
    acceptTaxIds: true,
  },
  {
    country: 'Luxembourg',
    iso_code: 'LU',
    percentage: {
      b2c: 16.0,
      b2b: 0,
    },
    acceptTaxIds: true,
  },
  {
    country: 'Malta',
    iso_code: 'MT',
    percentage: {
      b2c: 18.0,
      b2b: 0,
    },
    acceptTaxIds: true,
  },
  {
    country: 'Monaco',
    iso_code: 'MC',
    percentage: {
      b2c: 20.0,
      b2b: 0,
    },
    acceptTaxIds: true,
  },
  {
    country: 'Netherlands',
    iso_code: 'NL',
    percentage: {
      b2c: 21.0,
      b2b: 0,
    },
    acceptTaxIds: true,
  },
  {
    country: 'Poland',
    iso_code: 'PL',
    percentage: {
      b2c: 23.0,
      b2b: 0,
    },
    acceptTaxIds: true,
  },
  {
    country: 'Portugal',
    iso_code: 'PT',
    percentage: {
      b2c: 23.0,
      b2b: 0,
    },
    acceptTaxIds: true,
  },
  {
    country: 'Romania',
    iso_code: 'RO',
    percentage: {
      b2c: 19.0,
      b2b: 0,
    },
    acceptTaxIds: true,
  },
  {
    country: 'Slovakia',
    iso_code: 'SK',
    percentage: {
      b2c: 20.0,
      b2b: 0,
    },
    acceptTaxIds: true,
  },
  {
    country: 'Slovenia',
    iso_code: 'SI',
    percentage: {
      b2c: 22.0,
      b2b: 0,
    },
    acceptTaxIds: true,
  },
  {
    country: 'Spain',
    iso_code: 'ES',
    percentage: {
      b2c: 21.0,
      b2b: 0,
    },
    acceptTaxIds: true,
  },
  {
    country: 'Sweden',
    iso_code: 'SE',
    percentage: {
      b2c: 25.0,
      b2b: 0,
    },
    acceptTaxIds: true,
  },
  {
    country: 'United Kingdom',
    iso_code: 'GB',
    percentage: {
      b2c: 20.0,
      b2b: 20.0,
    },
    acceptTaxIds: true,
  },
];
