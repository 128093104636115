import React from 'react';
import { useLocation } from 'react-router-dom';

import Box from '@northflank/components/Box';
import FeatureTabs from '@northflank/components/marketing/FeatureTabs';

import { cloudProviderOptions } from '../utility/cloudProviderOptions';

const CloudProvidersNav = () => {
  const { pathname } = useLocation();
  const path = pathname.split('/');
  const provider = path[2];

  return (
    <Box
      display="block"
      bg="grey11"
      position={['static', 'static', 'sticky']}
      top="80px"
      zIndex={11}
      borderTop="1px solid"
      borderBottom="1px solid"
      borderColor="grey5"
      boxShadow="dropDown"
      px={10}
      py={2}
    >
      <FeatureTabs
        tabs={cloudProviderOptions}
        activeTab={provider}
        mx="auto"
        flexWrap="wrap"
        justifyContent="center"
      />
    </Box>
  );
};

export default CloudProvidersNav;
