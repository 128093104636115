/* eslint-disable no-magic-numbers */
import {
  buildJobMultiplier,
  cpuOnDemandMultiplier,
  ramOnDemandMultiplier,
  useModifiedBuildJobPricing,
} from './index';

export const calculateEffectivePricing = (resource, northflankResourcePrices, modifiers, free) => {
  // TODO: switched from always returning 0 to returning object for storage, need to check if this still works as intended
  if (free) {
    switch (resource) {
      case 'gpu':
      case 'cpu':
      case 'memory':
        return 0;
      case 'storage':
        return {
          hdd: 0,
          ssd: 0,
        };
      default:
        throw new Error('Unsupported resource type');
    }
  }

  // TODO: decide how to deal with onDemand modifier
  const { onDemand = false, isBuildOrJob = false } = modifiers;

  // TODO: gpu
  const { gpu, cpu, memory, storage } = northflankResourcePrices;

  let price = 0;
  switch (resource) {
    case 'gpu':
      // TODO: gpu
      price = 0;
      break;
    case 'cpu':
      price = 100 * cpu * (onDemand ? cpuOnDemandMultiplier : 1);
      break;
    case 'memory':
      price = 100 * memory * (onDemand ? ramOnDemandMultiplier : 1);
      break;
    // on demand not relevant for storage costs or build/job pricing
    case 'storage':
      return {
        hdd: 100 * storage.hdd,
        ssd: 100 * storage.ssd,
      };
    default:
      throw new Error('Unsupported resource type');
  }

  if (useModifiedBuildJobPricing && isBuildOrJob) price *= buildJobMultiplier;
  return price;
};
