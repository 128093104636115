import React from 'react';

import Box from '@northflank/components/Box';
import { FormikInput } from '@northflank/components/FormikInput';

const NetworkEgress = ({ formikIndex }) => {
  return (
    <Box>
      <FormikInput
        type="number"
        name={`resources.${formikIndex}.gb`}
        label="Egress gigabytes"
        mb={7}
      />
      <FormikInput
        type="number"
        name={`resources.${formikIndex}.reqs`}
        label="Egress requests"
      />
    </Box>
  );
};

export default NetworkEgress;
