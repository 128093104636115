import React, { useContext, useEffect, useRef } from 'react';

import { lighten } from 'polished';
import { ThemeContext } from 'styled-components';

import Box from '@northflank/components/Box';
import useBackgroundCursorPosition from '@northflank/utilities/hooks/useBackgroundCursorPosition';

const clamp = (val, min, max) => Math.min(Math.max(val, min), max);

const PerspectiveBox = ({
  children,
  withBgSpotlight,
  bg = 'grey5',
  wrapperProps,
  noRotation,
  ...rest
}) => {
  const ref = useRef();

  const theme = useContext(ThemeContext);
  const bgValue = theme.colors[bg];

  const [bgRef, background] = useBackgroundCursorPosition({
    backgroundColor: bgValue,
    highlightColor: lighten(0.2, bgValue),
    highlightSize: '400px',
  });

  useEffect(() => {
    let active = false;
    let x = window.innerWidth / 2;
    let y = window.innerHeight / 5;

    const update = () => {
      if (ref.current) {
        const box = ref.current.getBoundingClientRect();
        const rotateX = clamp(-(y - box.y - box.height / 2) / 750, -1, 1);
        const rotateY = clamp((x - box.x - box.width / 2) / 750, -1, 1);
        ref.current.style.transform = active
          ? `perspective(200px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`
          : `rotateX(0deg) rotateY(0deg)`;
      }
    };

    const draw = () => {
      if (!noRotation) {
        update();
        window.requestAnimationFrame(draw);
      }
    };

    const handleMove = (e) => {
      const { clientX, clientY } = e;
      x = clientX;
      y = clientY;
    };

    const handleEnter = () => {
      ref.current.style.transition = 'transform 100ms';
      active = true;
    };
    const handleLeave = () => {
      ref.current.style.transition = 'transform 500ms';
      active = false;
    };

    ref.current?.addEventListener('mousemove', handleMove);
    ref.current?.addEventListener('mouseenter', handleEnter);
    ref.current?.addEventListener('mouseleave', handleLeave);

    draw();

    return () => {
      ref.current?.removeEventListener('mousemove', handleMove);
      ref.current?.removeEventListener('mouseenter', handleEnter);
      ref.current?.removeEventListener('mouseleave', handleLeave);
    };
  }, []);

  return (
    <Box ref={ref} {...wrapperProps}>
      <Box
        ref={withBgSpotlight ? bgRef : undefined}
        bg={bg}
        style={withBgSpotlight ? { background } : undefined}
        {...rest}
      >
        {children}
      </Box>
    </Box>
  );
};

export default PerspectiveBox;
