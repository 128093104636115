import React from 'react';

import { TransferAlt } from '@styled-icons/boxicons-regular/TransferAlt';

import Button from '@northflank/components/Button';
import Tooltip from '@northflank/components/Tooltip';

const RefModeSwitchButton = ({ refMode, setRefMode, ...rest }) => (
  <Tooltip title={`Switch to ${refMode ? 'rich' : 'refs'} mode`} {...rest}>
    <Button
      type="button"
      onClick={() => setRefMode((m) => !m)}
      variant={['icon', 'noBorder', 'noBackground']}
      minWidth="16px"
      minHeight="16px"
    >
      <TransferAlt size={16} />
    </Button>
  </Tooltip>
);

export default RefModeSwitchButton;
