import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { getIn, useFormikContext } from 'formik';
import { ThemeContext } from 'styled-components';

import PlanCard from '@northflank/components/Billing/PlanCard';
import Box from '@northflank/components/Box';
import FieldLabel from '@northflank/components/FieldLabel';
import { FormikInput } from '@northflank/components/FormikInput';
import RefModeSwitchButton from '@northflank/components/RefModeSwitchButton';
import Text from '@northflank/components/Text';
import { getResourcesFromPlan } from '@northflank/constants-base';
import { determineProjectLimits } from '@northflank/constants-client';
import { ProjectLimitContext, ViewContext } from '@northflank/contexts/App';
import useAvailableHeight from '@northflank/utilities/hooks/useAvailableHeight';
import useOnClickOutside from '@northflank/utilities/hooks/useOnClickOutside';

import UsageExample from './subcomponents/UsageExample';

export const PlanSelectInterface = ({
  label = 'Compute plan *',
  selected,
  handleSelect,
  useId,
  useInternalId,
  northflankResourcesPrices,
  plans: _plans,
  project: projectFromProps,
  onDemand,
  isBuildService,
  isJob,
  disabled,
  maxWidth,
  staticDropdown,
  fontSize,
  highlightPlan,
  highlightPlanTooltip,
  hidePrices,
  error,
  name,
  initialValue,
  isTemplate,
  ...rest
}) => {
  const [refMode, setRefMode] = useState(false);

  useEffect(() => {
    if (isTemplate && initialValue?.startsWith('${')) setRefMode(true);
  }, []);

  // Get the project.
  const { entity, project: projectFromContext } = useContext(ViewContext);

  const project = projectFromProps || projectFromContext;

  // Get the project's limits.
  const limitsFromContext = useContext(ProjectLimitContext);

  const limitContext = useMemo(
    () =>
      projectFromProps
        ? determineProjectLimits(entity, project)
        : limitsFromContext,
    [project, limitsFromContext]
  );

  const cpuLimit = isBuildService
    ? limitContext?.limits?.resources?.builds?.cpu
    : limitContext?.limits?.resources?.cpu;
  const ramLimit = isBuildService
    ? limitContext?.limits?.resources?.builds?.ram
    : limitContext?.limits?.resources?.ram;

  // Create new plan list with plans disabled due to above limits.
  const plans = limitContext?.useLimits
    ? _plans.map((p) => {
        const planResources = getResourcesFromPlan(p);
        const isDisabled =
          planResources.cpu > cpuLimit || planResources.memory > ramLimit;

        return {
          ...p,
          disabled: p.disabled || isDisabled,
          disabledReason:
            p.disabledReason ||
            (isDisabled ? limitContext.errorMessage : undefined),
        };
      })
    : _plans;

  // Setup dropdown.
  const [showSelection, setShowSelection] = useState(false);
  const selectionRef = useOnClickOutside(() => setShowSelection(false));
  const selectionDropdownRef = useRef();

  const availableHeight = useAvailableHeight(selectionDropdownRef, null, [
    showSelection,
  ]);
  const selectionMaxHeight = !staticDropdown ? availableHeight : undefined;

  // Get the selected plan.
  const selectedPlanId = useId ? selected : selected?._id;
  const selectedPlan =
    plans.find(
      (p) => (useInternalId ? p.internalId : p._id) === selectedPlanId
    ) ?? plans[0];

  useEffect(() => {
    if (!selected) {
      const p = plans.filter((p) => !p.disabled && !p.isCustomPlan)[0];
      if (p)
        handleSelect(useId ? (useInternalId ? p.internalId : p._id) : p, false);
    }
  }, []);

  const theme = useContext(ThemeContext);

  if (isTemplate && refMode) {
    return (
      <>
        <Box
          variant="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={6}
        >
          <FieldLabel>{label}</FieldLabel>
          <RefModeSwitchButton refMode={refMode} setRefMode={setRefMode} />
        </Box>
        <FormikInput name={name} />
      </>
    );
  }

  return (
    <Box ref={selectionRef} maxWidth={maxWidth || '800px'} {...rest}>
      <Box
        variant="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={6}
      >
        <FieldLabel>{label}</FieldLabel>
        {isTemplate && (
          <RefModeSwitchButton refMode={refMode} setRefMode={setRefMode} />
        )}
      </Box>
      <Box position="relative">
        <PlanCard
          selected
          handleSelect={() => setShowSelection(!showSelection)}
          northflankResourcesPrices={northflankResourcesPrices}
          plan={selectedPlan}
          onDemand={onDemand}
          isBuildService={isBuildService}
          isJob={isJob}
          freeProject={limitContext?.free}
          hidePrices={hidePrices || project?.deployment?.isSelfHostedCluster}
          disabled={disabled}
          key={selectedPlan?._id}
          fontSize={fontSize}
          showSelection={showSelection}
          highlightPlan={highlightPlan}
          highlightPlanTooltip={highlightPlanTooltip}
          error={error}
        />

        {showSelection && (
          <Box
            position={!staticDropdown ? 'absolute' : 'static'}
            left={0}
            right={0}
            top="calc(100% + 8px)"
            zIndex={20}
            pb={8}
            mt={staticDropdown ? 6 : 0}
            ref={selectionDropdownRef}
            maxHeight={selectionMaxHeight}
          >
            <Box
              bg="grey10"
              border="1px solid"
              borderColor="grey6"
              borderRadius={1}
              boxShadow={!staticDropdown && 'dropDown'}
              maxHeight={selectionMaxHeight - 16}
              overflowY="auto"
              _css={{
                '> button:last-child': { border: 0 },
                'scrollbar-color': `${theme.colors.grey5} ${theme.colors.grey10}`,
                '&::-webkit-scrollbar-track': { bg: 'grey10' },
                '&::-webkit-scrollbar-thumb': { borderColor: 'grey10' },
              }}
            >
              {plans.map((p) => (
                <PlanCard
                  selected={
                    (useInternalId ? p.internalId : p._id) === selectedPlanId
                  }
                  handleSelect={() => {
                    if (p.disabled) {
                      return;
                    }

                    handleSelect(
                      useId ? (useInternalId ? p.internalId : p._id) : p,
                      true
                    );

                    setShowSelection(false);
                  }}
                  northflankResourcesPrices={northflankResourcesPrices}
                  plan={p}
                  selectionHeader={false}
                  showSelection={showSelection}
                  isBuildService={isBuildService}
                  onDemand={onDemand}
                  isJob={isJob}
                  freeProject={limitContext?.free}
                  hidePrices={
                    hidePrices || project?.deployment?.isSelfHostedCluster
                  }
                  key={p._id}
                  fontSize={fontSize}
                  highlightPlan={highlightPlan}
                  highlightPlanTooltip={highlightPlanTooltip}
                />
              ))}
            </Box>
          </Box>
        )}
      </Box>
      {!!project && !project.deployment?.isSelfHostedCluster && (
        <UsageExample
          selectedPlan={selectedPlan}
          northflankResourcesPrices={northflankResourcesPrices}
          isJob={isJob}
          isBuildService={isBuildService}
          onDemand={onDemand}
          freeProject={limitContext?.free}
        />
      )}
      {error && (
        <Text color="danger" fontSize={0} mt={5}>
          {error}
        </Text>
      )}
    </Box>
  );
};

const PlanSelect = ({ name, onChange, ...rest }) => {
  // Get Formik values.
  const { values, setFieldValue, errors, initialValues } = useFormikContext();

  return (
    <PlanSelectInterface
      selected={getIn(values, name)}
      handleSelect={(v, userDidChange) => {
        setFieldValue(name, v);
        if (typeof onChange === 'function' && userDidChange) onChange(v);
      }}
      error={getIn(errors, name)}
      name={name}
      initialValue={getIn(initialValues, name)}
      {...rest}
    />
  );
};

export default PlanSelect;
