import React, { useEffect, useState } from 'react';

import { Chip } from '@styled-icons/boxicons-regular/Chip';
import { Microchip } from '@styled-icons/boxicons-regular/Microchip';
import { World } from '@styled-icons/boxicons-regular/World';
import slugify from 'slugify';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Heading from '@northflank/components/Heading';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import CloudProviderGpuTable from '../../../../components/CloudProviders/CloudProviderGpuTable';
import CloudProviderNodeTable from '../../../../components/CloudProviders/CloudProviderNodeTable';
import CloudProvidersNav from '../../../../components/CloudProviders/CloudProvidersNav';
import DisplayProviderRegions from '../../../../components/CloudProviders/DisplayProviderRegions';
import NodeDescription from '../../../../components/CloudProviders/NodeDescription';
import { cloudProviderOptions } from '../../../../components/CloudProviders/utility/cloudProviderOptions';
import { FeaturesWrapper } from '../../../../components/FeaturePageWrapper';
import { Section } from '../index';

const DisplayNorthflankPlan = ({ providerNodeType, providerNodes }) => {
  const { id, name } = providerNodeType;

  const navItem = [
    {
      title: `Northflank`,
      href: `/cloud/northflank/instances/${id}`,
      subItems: [
        {
          icon: Chip,
          title: `Other Plans Available on Northflank`,
        },
      ],
    },
  ];

  return (
    <>
      <SEO
        title={`${name || id} - Northflank - Cloud Providers`}
        description={`Deploy on the Northflank plan ${id}.`}
      />
      <CloudProvidersNav />
      <FeaturesWrapper cloudProviderPage customNavItems={navItem}>
        <Box variant="bounding">
          <Breadcrumbs mb={6} />
          <Heading color="grey1" fontSize={[5, 5, 6, 6]} mb={10}>
            Deploy on the {name || id} Northflank Plan
          </Heading>
          <NodeDescription
            cloudProviderName="Northflank"
            providerNodeType={providerNodeType}
          />
        </Box>
        <Section anchor="other-plans-available-on-northflank">
          <Heading color="grey1" fontSize={[5, 5, 6, 6]} mb={10}>
            Other Plans Available on Northflank
          </Heading>
          <CloudProviderNodeTable
            providerId="northflank"
            nodeTypes={providerNodes}
          />
        </Section>
      </FeaturesWrapper>
    </>
  );
};

const DisplayByocNode = ({
  providerNodeType,
  cloudProvider,
  nodeRegions,
  providerNodes,
  otherGpus,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const {
    id,
    name,
    provider,
    oci,
    resources: { gpuInfo },
  } = providerNodeType;
  const providerOption = cloudProviderOptions.find((o) => o.value === provider);
  const slugCloudName = slugify(cloudProvider.name, { lower: true });

  useEffect(() => {
    const match = window.matchMedia('(max-width: 768px)');
    const query = (m) => {
      setIsMobile(m.matches);
    };
    query(match);
    match.addListener(query);
  }, []);

  const navSubItems = () => {
    return typeof gpuInfo !== 'undefined'
      ? [
          {
            icon: World,
            title: `${id} Available in These Regions on ${cloudProvider.name}`,
          },
          {
            icon: Microchip,
            title: `Other Instances with GPUs`,
          },
        ]
      : [
          {
            icon: World,
            title: `${id} Available in These Regions on ${cloudProvider.name}`,
          },
          {
            icon: Chip,
            title: `Other Instances on ${cloudProvider.name}`,
          },
        ];
  };

  const navItem = [
    {
      title: `${cloudProvider.name}`,
      href: `/cloud/${cloudProvider.id}/instances/${id}`,
      subItems: navSubItems(),
    },
  ];

  const OciDescription = ({ mb }) => (
    <>
      {oci?.isFlex ? (
        <Text fontSize={3} color="grey3" mb={8}>
          This node type is a flexible shape, which means you can set the vCPU
          count and memory when creating your nodes.
        </Text>
      ) : null}
      <Text fontSize={3} color="grey3" mb={mb}>
        OCI measures nodes in OCPU (Oracle CPU), for comparison we have
        converted OCPU values to vCPU.{' '}
        {oci?.vcpusPerOcpu ? (
          <>This node type has {oci?.vcpusPerOcpu} VCPUs per OCPU.</>
        ) : null}
      </Text>
    </>
  );

  return (
    <>
      <SEO
        title={`${name || id} instances on ${cloudProvider.name} | Cloud Providers`}
        description={`Deploy ${id} instances on ${
          cloudProvider?.engine || cloudProvider?.name
        } with Northflank.`}
      />
      <CloudProvidersNav />
      <FeaturesWrapper cloudProviderPage customNavItems={navItem}>
        <Box variant="bounding">
          <Breadcrumbs mb={6} />
          <Heading as="h1" color="grey1" fontSize={[5, 5, 6, 6]} mb={8}>
            Deploy onto {name || id} nodes on {cloudProvider.name} with
            Northflank
          </Heading>
          <NodeDescription
            cloudProviderId={cloudProvider.id}
            cloudProviderName={cloudProvider.name}
            providerNodeType={providerNodeType}
            gpus={gpuInfo?.gpus ? gpuInfo.gpus[0] : false}
            mb={oci ? 8 : 13}
          />
          {provider === 'oci' ? (
            <OciDescription isFlex={oci?.isFlex} mb={13} />
          ) : null}
          <a
            href={`https://app.northflank.com/s/account/cloud/clusters/new/${cloudProvider.id}`}
            target="_blank"
            style={{ display: 'inline-block' }}
            rel="noreferrer"
          >
            <Button variant={['large', 'primary']}>
              Deploy {name} instances now
            </Button>
          </a>
        </Box>
        <Section
          anchor={`${slugify(id, {
            remove: /[._]/g,
            lower: true,
          })}-available-in-these-regions-on-${slugCloudName}`}
        >
          <Heading color="grey1" fontSize={[5, 5, 6, 6]} mb={10}>
            {name || id} Available in These Regions on {cloudProvider.name}
          </Heading>
          <DisplayProviderRegions
            option={providerOption}
            displayedRegions={nodeRegions}
            hideTitle
          />
        </Section>
        {otherGpus?.length ? (
          <Section anchor="other-instances-with-gpus">
            <Heading color="grey1" fontSize={[5, 5, 6, 6]} mb={10}>
              Other Instances with GPUs
            </Heading>
            <CloudProviderGpuTable
              nodes={otherGpus}
              isMobile={isMobile}
              showProvider
            />
          </Section>
        ) : (
          <Section anchor={`other-instances-on-${slugCloudName}`}>
            <Heading color="grey1" fontSize={[5, 5, 6, 6]} mb={10}>
              Other Instances on {cloudProvider.name}
            </Heading>
            <CloudProviderNodeTable
              nodeTypes={providerNodes}
              providerId={cloudProvider.id}
            />
          </Section>
        )}
      </FeaturesWrapper>
    </>
  );
};

const NodeType = ({
  cloudProvider,
  providerNodeType,
  providerNodes,
  nodeRegions,
  otherGpus,
}) => {
  const northflank = cloudProvider?.id === 'northflank';

  return northflank ? (
    <DisplayNorthflankPlan
      providerNodeType={providerNodeType}
      providerNodes={providerNodes}
    />
  ) : (
    <DisplayByocNode
      providerNodeType={providerNodeType}
      providerNodes={providerNodes}
      cloudProvider={cloudProvider}
      nodeRegions={nodeRegions}
      otherGpus={otherGpus}
    />
  );
};

export default NodeType;
