import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { File } from '@styled-icons/boxicons-regular/File';
import { News } from '@styled-icons/boxicons-regular/News';
import { Megaphone } from '@styled-icons/boxicons-solid/Megaphone';
import css from '@styled-system/css';
import styled from 'styled-components';

import Box from '@northflank/components/Box';
import Heading from '@northflank/components/Heading';
import FeatureTabs from '@northflank/components/marketing/FeatureTabs';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';

import Tags from '../../components/Tags';
import formatDate from '../../utils/formatDate';
import { AuthorAvatar, ExtraAuthors } from './post';

export const ArticleImage = styled(Box)(({ src, wide, width = '100%' }) =>
  css({
    backgroundImage: `url(${src})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: 0,
    width,
    border: '1px solid',
    borderColor: 'grey6',
    borderRadius: 1,
    paddingBottom: wide
      ? `calc((9 / 21) * ${width})`
      : `calc((9 / 16) * ${width})`,
  })
);

const StyledLink = styled.span(() =>
  css({
    '&:hover:not(:has(a:hover)) h2': {
      textDecoration: 'underline',
    },
  })
);

export const ResourcesNav = () => {
  const { pathname } = useLocation();
  return (
    <FeatureTabs
      tabs={[
        {
          value: 'guides',
          label: 'Guides',
          link: '/guides',
          icon: File,
        },
        {
          value: 'changelog',
          label: 'Changelog',
          link: '/changelog',
          icon: Megaphone,
        },
        {
          value: 'blog',
          label: 'Blog',
          link: '/blog',
          icon: News,
        },
      ]}
      activeTab={pathname.split('/')[1]}
      bg="background"
      border="1px solid"
      borderColor="grey6"
      mt={[0, 0, -13]}
      mb={13}
    />
  );
};

export const BlogCard = ({
  image,
  authors,
  published,
  title,
  excerpt,
  tags,
  link,
  wide,
  ellipsisTitle,
  ...props
}) => (
  <Box height="100%">
    <Link to={link}>
      <StyledLink>
        <Box
          display="grid"
          gridTemplateColumns={wide ? ['1fr', '1fr', '1fr', '2fr 1fr'] : '1fr'}
          gridGap={wide ? [8, 8, 8, '50px'] : 8}
          alignItems="center"
          {...props}
        >
          <ArticleImage src={image} />
          <Box variant="flex" overflow={ellipsisTitle && 'hidden'}>
            {authors && (
              <Box variant="flex" mr={8} flexDirection="column">
                {authors?.slice(0, 3).map((author, i) => (
                  <AuthorAvatar
                    key={author.id}
                    src={
                      author.attributes.picture?.data?.attributes.url ||
                      '/images/headshot/default.png'
                    }
                    mt={i > 0 && '-20px'}
                  />
                ))}
                {authors.length > 3 && (
                  <ExtraAuthors mt="-20px">+{authors.length - 3}</ExtraAuthors>
                )}
              </Box>
            )}
            <Box overflow={ellipsisTitle && 'hidden'}>
              {(authors || published) && (
                <Text color="grey3" fontSize={2} mb={6}>
                  {authors && (
                    <>
                      {authors
                        ?.map((author) => author.attributes.name)
                        .join(', ')}{' '}
                      &bull;{' '}
                    </>
                  )}
                  {published && formatDate(published)}
                </Text>
              )}
              <Heading
                color="grey1"
                fontSize={wide ? 4 : 3}
                fontWeight="600!important"
                css={
                  ellipsisTitle && {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }
                }
              >
                {title}
              </Heading>
              {excerpt && (
                <Text color="grey3" fontSize={3} mt={6}>
                  {excerpt}
                </Text>
              )}
              {!!tags?.length && (
                <Box mt={8}>
                  <Tags tags={tags} context="blog" />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </StyledLink>
    </Link>
  </Box>
);

const Blog = ({ posts = [], total, tag, tags = [] }) => {
  const [loadedPosts, setLoadedPosts] = useState(posts);
  const loadMoreRef = useRef();

  useEffect(() => {
    if (loadMoreRef.current) {
      const options = {
        rootMargin: '0px 0px 500px 0px',
        threshold: 1.0,
      };
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(({ isIntersecting }) => {
          if (isIntersecting && loadedPosts.length < total) {
            fetchNextPage(loadedPosts.length);
          }
        });
      }, options);
      observer.observe(loadMoreRef.current);

      return () => {
        if (loadMoreRef.current) observer.unobserve(loadMoreRef.current);
      };
    }
  }, [loadMoreRef, loadedPosts?.length]);

  const fetchNextPage = async (start) => {
    let url = `/api/blog/${start}`;
    if (tag) url += `?tag=${tag}`;

    const res = await fetch(url);
    const newPosts = await res.json();

    let existing = [...loadedPosts];

    if (newPosts?.blogs?.data.length) {
      existing = existing.concat(newPosts.blogs.data);
      setLoadedPosts(existing);
    }
  };

  const [featuredPost, ...remainingPosts] = loadedPosts;

  const existingTag = tags.find((t) => t.attributes.slug === tag);
  const title = tag
    ? `Blog posts tagged “${existingTag?.attributes.title ?? tag}”`
    : 'Blog';

  return (
    <>
      <SEO
        title={title}
        description="Stay up to date with the latest happenings at Northflank"
      />
      <Box variant="bounding">
        <Box mb={13}>
          <ResourcesNav />
          {tag && (
            <Text
              as={Link}
              to="/blog"
              display="inline-block"
              fontSize={3}
              color="grey3"
              mb={6}
            >
              &larr; All posts
            </Text>
          )}
          <Heading as="h1" fontSize={[6, 6, 7]} color="grey1" mb={8}>
            {title}
          </Heading>
          <Text color="grey3">
            <Text
              as="a"
              href="https://x.com/northflank"
              color="grey3"
              css={{ '&:hover': { color: '#C6D3E2' } }}
            >
              Follow us on X
            </Text>{' '}
            /{' '}
            <Text
              as="a"
              href="/blog/rss"
              color="grey3"
              css={{ '&:hover': { color: '#C6D3E2' } }}
            >
              Subscribe to RSS feed
            </Text>
          </Text>
        </Box>
        {!loadedPosts.length && (
          <Text fontSize={4} color="grey3">
            There is nothing here yet.
          </Text>
        )}
        {featuredPost && (
          <BlogCard
            key={featuredPost.id}
            image={featuredPost.attributes.header?.data?.attributes.url}
            authors={featuredPost.attributes.writers?.data}
            published={featuredPost.attributes.publication_date}
            title={featuredPost.attributes.title}
            excerpt={featuredPost.attributes.excerpt}
            tags={featuredPost.attributes.tags?.data}
            link={`/blog/${featuredPost.attributes.slug}`}
            wide
            mb="50px"
          />
        )}
        <Box
          display="grid"
          gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
          gridGap="50px"
          alignItems="start"
        >
          {remainingPosts?.map((post) => (
            <BlogCard
              key={post.id}
              image={post.attributes.header?.data?.attributes.url}
              authors={post.attributes.writers?.data}
              published={post.attributes.publication_date}
              title={post.attributes.title}
              excerpt={post.attributes.excerpt}
              tags={post.attributes.tags?.data}
              link={`/blog/${post.attributes.slug}`}
            />
          ))}
        </Box>
        {loadedPosts.length < total && <div ref={loadMoreRef} />}
      </Box>
    </>
  );
};

export default Blog;
