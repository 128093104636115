export const AUTOSCALING_CONSTS = {
  minReplicas: 1,
  cpu: {
    minThresholdPercentage: 10,
    maxThresholdPercentage: 100,
  },
  memory: {
    minThresholdPercentage: 10,
    maxThresholdPercentage: 100,
  },
  rps: {
    minThresholdValue: 1,
    maxThresholdValue: 100000,
  },
};

export const checkServiceAutoscalingEnabled = (service) => {
  const horizontalEnabled = service?.autoscaling?.horizontal?.enabled;
  const horizontalResourceEnabled =
    service?.autoscaling?.horizontal?.cpu?.enabled ||
    service?.autoscaling?.horizontal?.memory?.enabled;

  return horizontalEnabled && horizontalResourceEnabled;
};
