import styledCss from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import styled from 'styled-components';
import {
  background,
  border,
  color,
  flexbox,
  position,
  space,
  system,
  typography,
} from 'styled-system';

import { layout } from '@northflank/utilities/styled-system/layout';
import textTransform from '@northflank/utilities/styled-system/text-transform';
import { variant } from '@northflank/utilities/styled-system/variant';

const Text = styled.div.withConfig({
  shouldForwardProp,
})(
  space,
  layout,
  background,
  flexbox,
  color,
  typography,
  border,
  position,
  textTransform,
  system({
    textTransform: true,
    whiteSpace: true,
  }),
  ({ fontSize, fontFamily, fontWeight = 400, css }) =>
    styledCss({
      fontSize,
      fontFamily,
      fontWeight,
      ...css,
    }),
  ({ length }) =>
    variant({
      variants: {
        ellipse: {
          'text-overflow': 'ellipsis',
          'white-space': 'nowrap',
          overflow: 'hidden',
        },
        sha: {
          'text-overflow': 'ellipsis',
          'white-space': 'nowrap',
          overflow: 'hidden',
          maxWidth: '24ch',
        },
        commitMessage: {
          'text-overflow': 'ellipsis',
          'white-space': 'nowrap',
          overflow: 'hidden',
          maxWidth: '150ch',
        },
        dateShort: {
          textAlign: 'right',
          minWidth: length ? `${length + 1}ch` : '4ch',
        },
        contextMenuOption: {
          '&:first-of-type': {
            borderRadius: '5px 5px 0 0',
          },
        },
        inlineGrey: {
          '&:span': {
            color: 'grey3',
          },
        },
        error: {
          fontSize: 0,
          color: 'danger',
          whiteSpace: 'normal',
        },
        code: {
          bg: 'grey11',
          fontSize: 0,
          color: 'grey2',
          fontFamily: 'monospace',
          marginBottom: -2,
          paddingLeft: 3,
          paddingRight: 3,
          borderRadius: 1,
          display: 'inline',
        },
        noWrap: {
          display: 'inline-block',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        },
      },
    })
);

export default Text;
