import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import Box from '@northflank/components/Box';
import Label from '@northflank/components/Label';

const Tags = ({ tags, context }) => (
  <Box variant="flex" flexWrap="wrap" m={-2}>
    {tags.map((tag) => (
      <Box
        key={tag.attributes.slug}
        m={2}
        _css={{
          '&:hover': { textDecoration: 'underline' },
          'white-space': 'nowrap',
        }}
      >
        <Link
          to={`/${context}/${context === 'blog' ? 'tag' : 'category'}/${tag.attributes.slug}`}
        >
          <Box color="grey1">
            <Label key={tag.attributes.title}>{tag.attributes.title}</Label>
          </Box>
        </Link>
      </Box>
    ))}
  </Box>
);

Tags.propTypes = {
  tags: PropTypes.array.isRequired,
  context: PropTypes.oneOf(['blog', 'guides']).isRequired,
};

export default Tags;
