import React from 'react';

import PropTypes from 'prop-types';

import Box from '@northflank/components/Box';
import Text from '@northflank/components/Text';
import Tooltip from '@northflank/components/Tooltip';
import {
  displayCurrencySymbol,
  displayPrice,
} from '@northflank/utilities/Billing/Prices';

const Price = ({
  symbol,
  cost,
  full,
  label,
  fullCost,
  isJobOrBuild,
  ...rest
}) => {
  const price = `${symbol}${Number(cost).toFixed(full ? 4 : 2)}`;
  return (
    <Box variant="flex" alignItems="flex-end" {...rest}>
      {isJobOrBuild ? <Tooltip title={`$${fullCost}`}>{price}</Tooltip> : price}
      {label && (
        <Text
          as="span"
          color={isJobOrBuild ? 'grey3' : undefined}
          fontSize={0}
          ml={1}
        >
          {label}
        </Text>
      )}
    </Box>
  );
};

// Expecting cost in form of pennies
// This then gets split and displayed separately
const Cost = ({
  cost: _cost,
  currency,
  large,
  full,
  label,
  fontSize,
  isJobOrBuild,
  ...rest
}) => {
  if (!_cost) return null;

  const shortCost = Math.ceil(_cost * 100) / 100;
  const fullCost = Math.ceil(_cost * 10000) / 10000;
  const cost = full ? fullCost : shortCost;
  const displayCost = displayPrice(cost).split('.');
  const firstCost = displayCost[0];
  const secondCost = full ? displayCost[1] : displayCost[1].slice(0, -1);

  const symbol = displayCurrencySymbol(currency);

  return large ? (
    <Box variant="flex" color="grey2" mt={4} {...rest}>
      <Text fontSize={fontSize || 5} pt={3} mr={1}>
        {symbol}
      </Text>
      <Text fontSize={fontSize + 1 || 6}>{firstCost}</Text>
      <Text fontSize={fontSize || 5} pt={2}>
        .
      </Text>
      <Text fontSize={fontSize || 5} pt={2}>
        {secondCost}
      </Text>
      {label && (
        <Text color="grey3" fontSize={fontSize || 5} pt={2} ml={5}>
          {label}
        </Text>
      )}
    </Box>
  ) : (
    <Text fontSize={fontSize || 0}>
      <Price
        full={full}
        cost={cost}
        label={label}
        symbol={symbol}
        fullCost={fullCost}
        isJobOrBuild={isJobOrBuild}
        {...rest}
      />
    </Text>
  );
};

export default Cost;

Cost.propTypes = {
  cost: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  large: PropTypes.bool,
  full: PropTypes.bool,
  label: PropTypes.string,
  fontSize: PropTypes.number,
};

Cost.defaultProps = {
  large: false,
  full: false,
  label: '',
};
