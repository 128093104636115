import React, { useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import '@northflank/components/marketing/util/line-numbers';

import { Facebook } from '@styled-icons/boxicons-logos/Facebook';
import { Linkedin } from '@styled-icons/boxicons-logos/Linkedin';
import { File } from '@styled-icons/boxicons-regular/File';
import { Link as LinkIcon } from '@styled-icons/boxicons-regular/Link';
import { ThemeContext } from 'styled-components';

import Box from '@northflank/components/Box';
import Grid from '@northflank/components/Grid';
import Heading from '@northflank/components/Heading';
import Icon from '@northflank/components/Icon';
import MarkdownBody from '@northflank/components/marketing/MarkdownBody';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';
import Tooltip from '@northflank/components/Tooltip';
import X from '@northflank/images/icons/X';

import NewsletterPopup from '../../components/NewsletterPopup';
import PreHeading from '../../components/PreHeading';
import Tags from '../../components/Tags';
import formatDate from '../../utils/formatDate';
import useSyntaxHighlighting from '../../utils/useSyntaxHighlighting';
import { ResourcesNav } from '../blog';
import { AuthorAvatar, ExtraAuthors, StyledLink } from '../blog/post';
import { ArticleImage, BackLink, ShareLink } from '../changelog/post';
import { GuideItem } from './index';

export function getAnchor(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/[^a-z0-9 ]/g, '')
    .replace(/[ ]/g, '-');
}

export const HeadingAnchor = ({ level, children }) => {
  const anchor = getAnchor(children);
  const link = `#${anchor}`;

  const components = {
    2: 'h2',
    3: 'h3',
    4: 'h4',
    5: 'h5',
    6: 'h6',
  };
  const Component = components[level];

  return (
    <Box
      position="relative"
      _css={{ '&:hover': { '> a': { display: 'block' } } }}
    >
      <Box
        as="a"
        href={link}
        display="none"
        position="absolute"
        left="-26px"
        top="50%"
        _css={{ transform: 'translateY(-50%)' }}
        pr="10px"
      >
        <Icon Content={LinkIcon} Size={24} color="grey3" />
      </Box>
      <Component id={anchor}>{children}</Component>
    </Box>
  );
};

export const StickyContentsNav = ({ title, content }) => {
  const theme = useContext(ThemeContext);

  if (!content) return null;

  const headingRegex = /^#{2,} /; // starts with one or more hashes followed by space

  // find lines that start with #, but not inside code blocks
  const headings = [];

  let inCodeBlock = false;

  for (const line of content.split('\n')) {
    if (line.startsWith('```')) inCodeBlock = !inCodeBlock;
    if (headingRegex.test(line) && !inCodeBlock) headings.push(line);
  }

  const headingsWithLevels = headings.map((heading) => {
    const [match] = headingRegex.exec(heading);
    return {
      level: match.match(/#/g || []).length,
      text: heading.replace(match, ''),
      anchor: `#${getAnchor(heading.replace(match, ''))}`,
    };
  });

  const minLevel = Math.min(...headingsWithLevels.map((h) => h.level));

  return headingsWithLevels.length > 1 ? (
    <Box
      position="absolute"
      top={0}
      left={[
        'calc(100% + 32px)',
        'calc(100% + 32px)',
        'calc(100% + 32px)',
        'calc(100% + 32px)',
        'calc(100% + 32px)',
        'calc(100% + 128px)',
      ]}
      width="calc(((100vw - 750px) / 2) - 64px)"
      maxWidth="300px"
      height="100%"
      display={['none', 'none', 'none', 'block']}
    >
      <Box
        position="sticky"
        top="112px"
        bg="grey9"
        border="1px solid"
        borderColor="grey6"
        borderRadius={2}
        p={8}
        maxHeight="calc(100vh - 112px - 32px)"
        overflowY="auto"
        _css={{
          'scrollbar-color': `${theme.colors.grey5} ${theme.colors.grey9}`,
          '&::-webkit-scrollbar-track': { bg: 'grey9' },
          '&::-webkit-scrollbar-thumb': { borderColor: 'grey9' },
        }}
      >
        <PreHeading mb={6}>{title}</PreHeading>
        <Box _css={{ '> * + *': { mt: 4 } }}>
          {headingsWithLevels.map((heading) => (
            <Text
              key={heading.anchor}
              as="a"
              href={heading.anchor}
              color="grey1"
              display="block"
              ml={`${(heading.level - minLevel) * 12}px`}
              css={{ '&:hover': { textDecoration: 'underline' } }}
            >
              {heading.text}
            </Text>
          ))}
        </Box>
      </Box>
    </Box>
  ) : null;
};

const GuidePost = ({ post, renderedPost, related, newerGuides }) => {
  const { pathname } = useLocation();

  useSyntaxHighlighting([post.attributes.slug]);

  useEffect(() => {
    const snippets = document.querySelectorAll(`pre[class*='language-']`);
    snippets.forEach((el) => {
      el.classList.add('line-numbers');
    });
  }, []);

  const shareText = encodeURIComponent(
    `Northflank guides — ${post.attributes.title}`
  );

  return (
    <>
      <SEO
        title={post.attributes.title}
        description={post.attributes.excerpt}
        image={post.attributes.header?.data?.attributes.url}
      />
      <NewsletterPopup />
      <Box variant="bounding">
        <Box maxWidth="750px" mx="auto">
          <ResourcesNav />
          <Link to="/guides">
            <BackLink>&larr; Back to Guides</BackLink>
          </Link>
          {!post.attributes.alternate_header_style && (
            <Box mb={10}>
              <ArticleImage
                src={post.attributes.header?.data?.attributes.url}
                alt={`Header image for guide: ${post.attributes.title}`}
                width={['100%', '100%', '100%', '125%']}
                ml={[0, 0, 0, 'calc(((125% - 100%) / 2) * -1)']}
              />
            </Box>
          )}
          <Box
            display="flex"
            flexDirection={
              post.attributes.alternate_header_style
                ? 'column-reverse'
                : 'column'
            }
          >
            <Box
              variant="flex"
              alignItems={[
                post.attributes.writers.data.length > 1
                  ? 'flex-start'
                  : 'center',
                post.attributes.writers.data.length > 1
                  ? 'flex-start'
                  : 'center',
                'center',
                'center',
              ]}
              flexDirection={[
                post.attributes.writers.data.length > 1 ? 'column' : 'row',
                post.attributes.writers.data.length > 1 ? 'column' : 'row',
                'row',
                'row',
              ]}
              mt={post.attributes.alternate_header_style ? 10 : 0}
              mb={post.attributes.alternate_header_style ? 0 : 8}
            >
              {!!post.attributes.writers?.data.length && (
                <Box
                  variant="flex"
                  height="50px"
                  mr={6}
                  mb={
                    post.attributes.writers.data.length > 1 ? [6, 6, 0, 0] : 0
                  }
                >
                  {post.attributes.writers.data.slice(0, 3).map((author, i) => (
                    <Tooltip
                      key={author.id}
                      position="bottom"
                      distance={5}
                      ml={i > 0 && '-20px'}
                      html={
                        <Box>
                          <Text>{author.attributes.name}</Text>
                          {author.attributes.twitter && (
                            <StyledLink
                              href={`https://x.com/${author.attributes.twitter.replace(
                                '@',
                                ''
                              )}`}
                              target="_blank"
                            >
                              {author.attributes.twitter}
                            </StyledLink>
                          )}
                        </Box>
                      }
                      interactive
                      inline
                    >
                      <AuthorAvatar
                        src={
                          author.attributes.picture?.data?.attributes.url ||
                          '/images/headshot/default.png'
                        }
                        alt={`Profile image for ${author.attributes.name}`}
                      />
                    </Tooltip>
                  ))}
                  {post.attributes.writers.data.length > 3 && (
                    <ExtraAuthors ml="-20px">
                      +{post.attributes.writers.data.length - 3}
                    </ExtraAuthors>
                  )}
                </Box>
              )}
              <Box>
                {!!post.attributes.writers?.data.length && (
                  <Text>
                    By{' '}
                    {post.attributes.writers.data.map((author, i) => (
                      <React.Fragment key={author.attributes.slug}>
                        <Link to={`/author/${author.attributes.slug}`}>
                          <Text
                            as="span"
                            color="grey1"
                            css={{ '&:hover': { textDecoration: 'underline' } }}
                          >
                            {author.attributes.name}
                          </Text>
                        </Link>
                        {i < post.attributes.writers.data.length - 1 && ', '}
                      </React.Fragment>
                    ))}
                  </Text>
                )}
                <Text color="grey3">
                  Published {formatDate(post.attributes.publication_date)}
                </Text>
              </Box>
            </Box>
            <Box>
              {!!post.attributes.guide_categories?.data.length && (
                <Box variant="flex" mb={6}>
                  {post.attributes.guide_categories.data.map((category, i) => (
                    <Text
                      key={category.attributes.slug}
                      color="success"
                      textTransform="uppercase"
                      fontWeight={500}
                      fontSize={1}
                      mr={3}
                    >
                      <Link to={`/guides/category/${category.attributes.slug}`}>
                        <Text
                          as="span"
                          color="success"
                          css={{ '&:hover': { textDecoration: 'underline' } }}
                        >
                          {category.attributes.title}
                        </Text>
                      </Link>
                      {i < post.attributes.guide_categories.data.length - 1 &&
                        ','}
                    </Text>
                  ))}
                </Box>
              )}
              <Heading as="h1" color="grey1" fontSize={[5, 5, '30px']}>
                {post.attributes.title}
              </Heading>
              {!!post.attributes.tags?.data.length && (
                <Box variant="flex" mt={8}>
                  <Tags tags={post.attributes.tags.data} context="guides" />
                </Box>
              )}
            </Box>
          </Box>
          <Box
            pt={12}
            mt={12}
            borderTop="1px solid"
            borderColor="grey6"
            position="relative"
          >
            <MarkdownBody dangerouslySetInnerHTML={{ __html: renderedPost }} />
            <StickyContentsNav
              title="In this guide"
              content={post.attributes.content}
            />
          </Box>
          <Box pt={12} mt={12} borderTop="1px solid" borderColor="grey6">
            <Box>
              <PreHeading mb={6}>
                Share this article with your network
              </PreHeading>
            </Box>
            <ShareLink
              href={`https://x.com/intent/tweet?text=${shareText}&url=https://northflank.com${pathname}`}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Share to X"
            >
              <X size={24} />
            </ShareLink>
            <ShareLink
              href={`https://www.facebook.com/sharer/sharer.php?t=${shareText}&u=https://northflank.com${pathname}`}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Share to Facebook"
            >
              <Facebook size={32} />
            </ShareLink>
            <ShareLink
              href={`https://www.linkedin.com/shareArticle?mini=true&text=${shareText}&url=https://northflank.com${pathname}`}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Share to LinkedIn"
            >
              <Linkedin size={32} />
            </ShareLink>
          </Box>
          {!!related?.length && (
            <Box pt={12} mt={12} borderTop="1px solid" borderColor="grey6">
              <PreHeading mb={8}>Related guides</PreHeading>
              <Grid gridTemplateColumns="1fr" gridGap={12}>
                {related.map((post) => (
                  <GuideItem key={post.id} post={post} />
                ))}
              </Grid>
            </Box>
          )}
          {!!newerGuides?.length > 0 && (
            <Box pt={12} mt={12} borderTop="1px solid" borderColor="grey6">
              <PreHeading mb={6}>Next articles</PreHeading>
              <ul style={{ listStyle: 'none' }}>
                {newerGuides?.map((newGuide) => (
                  <li key={newGuide.id}>
                    <Box display="flex" alignItems="center" mb={2}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color="docs.textLight"
                        width="18px"
                        height="18px"
                        mr={6}
                      >
                        <File size={18} />
                      </Box>
                      <Link
                        color="primary"
                        fontSize="16px"
                        to={`/guides/${newGuide.attributes.slug}`}
                        css={{ textDecoration: 'none' }}
                      >
                        {newGuide.attributes.title}
                      </Link>
                    </Box>
                  </li>
                ))}
              </ul>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default GuidePost;
