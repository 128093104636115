import React from 'react';

import Box from '@northflank/components/Box';
import Text from '@northflank/components/Text';

import DisplayRegion from '../DisplayRegion';

const DisplayProviderAvailabilityZones = ({ availabilityZones }) => {
  return (
    <Box
      bg="grey10"
      border="1px solid"
      borderColor="grey5"
      borderRadius={3}
      p={4}
      py={6}
    >
      <Box _css={{ columnCount: 4 }}>
        {availabilityZones.length ? (
          availabilityZones.map((az) => (
            <DisplayRegion region={az} key={az.id} />
          ))
        ) : (
          <Text p={3} pb={8}>
            No availability zones
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default DisplayProviderAvailabilityZones;
