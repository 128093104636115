import css from '@styled-system/css';
import styled from 'styled-components';
import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  typography,
} from 'styled-system';

import { variant } from '@northflank/utilities/styled-system/variant';

const Image = styled.img(
  space,
  layout,
  grid,
  background,
  color,
  flexbox,
  typography,
  border,
  position,
  shadow,
  ({ maxWidth = '100%', height = 'auto', css: additionalCss }) =>
    css({ maxWidth, height, ...additionalCss }),
  () =>
    variant({
      variants: {
        avatar: {
          minWidth: '10px',
          borderRadius: 5,
          width: '16px',
        },
      },
    })
);

export default Image;
