import React from 'react';

import { CodeAlt } from '@styled-icons/boxicons-regular/CodeAlt';
import { GitBranch } from '@styled-icons/boxicons-regular/GitBranch';
import { GitCommit } from '@styled-icons/boxicons-regular/GitCommit';
import { Pointer } from '@styled-icons/boxicons-regular/Pointer';
import { Search } from '@styled-icons/boxicons-regular/Search';
import { Windows } from '@styled-icons/boxicons-regular/Windows';

import Box from '@northflank/components/Box';
import Heading from '@northflank/components/Heading';
import FeatureNav from '@northflank/components/marketing/FeatureNav';
import FeatureWithIcon from '@northflank/components/marketing/FeatureWithIcon';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';

import {
  FeatureGroup,
  FeaturesWrapper,
} from '../../components/FeaturePageWrapper';
import PreHeading from '../../components/PreHeading';

const Templates = () => {
  return (
    <>
      <SEO
        title="Templates — Features"
        description="Northflank Infrastructure as Code (IaC) solution lets you easily manage and repeat infrastructure operations, and can be stored on Northflank and in your Git repository."
      />
      <FeatureNav />
      <FeaturesWrapper>
        <Box variant="bounding" pb="70px!important">
          <PreHeading mb={6}>Feature breakdown</PreHeading>
          <Heading as="h1" color="grey1" fontSize={[6, 6, 7, 7]} mb={8}>
            Templates on Northflank
          </Heading>
          <Text color="grey3" fontSize={4} width={[1, 1, 1 / 2]} mediaQuery>
            Define your infrastructure as a single template object, putting all
            of your resource definitions in one convenient place. Our
            Infrastructure as Code (IaC) solution lets you easily manage and
            repeat infrastructure operations, and can be stored on Northflank
            and in your Git repository.
          </Text>
        </Box>
        <FeatureGroup
          heading="Templates"
          text="Group multiple services, jobs, and stateful workloads together into a single template that can be run repeatedly. Quickly scale and modify your existing resources, or create a copy of your full stack."
          wideImage="/images/feature/details/release/flow.png"
          wideImageAlt="Screenshot of Northflank visual release flow builder"
          items={
            <>
              <FeatureWithIcon
                Icon={CodeAlt}
                iconGradient
                heading="Codify Your Infrastructure"
                text="Automate the creation, modification, and running of specific resources or entire projects with templates."
              />
              <FeatureWithIcon
                Icon={Search}
                iconGradient
                heading="View Resource Spec"
                text="View the template specification of any resource you have already created to move it into a template with ease."
              />
              <FeatureWithIcon
                Icon={Pointer}
                iconGradient
                heading="Share One-Click Deployments"
                text="Create and share one-click deployment links based on your templates — within your team or to the world."
              />
              <FeatureWithIcon
                Icon={Windows}
                iconGradient
                heading="Stack Templates"
                text="Deploy popular application stacks in seconds with our provided stack templates."
              />
            </>
          }
        />
        <FeatureGroup
          heading="GitOps"
          text="Store your templates in your Git repository, keeping your resource definitions inside your version control. Keep all the benefits of your existing Git workflow and security policies."
          wideImage="/images/feature/details/templates/code.png"
          wideImageAlt="Screenshot of Northflank template code editor"
          items={
            <>
              <FeatureWithIcon
                Icon={GitBranch}
                iconGradient
                heading="Version Control for Your Infrastructure"
                text="Changes to your template are automatically committed to your repository. Integrating with version control means you get a full history of infrastructure changes and can roll back when necessary."
              />
              <FeatureWithIcon
                Icon={GitCommit}
                iconGradient
                heading="Trigger on Commit"
                text="Northflank updates your template to the latest version whenever you push to Git, and automatically modifies your infrastructure with the changes."
              />
            </>
          }
        />
      </FeaturesWrapper>
    </>
  );
};

export default Templates;
