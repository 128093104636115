import Noty from 'noty';

const defaultNotyOptions = {
  layout: 'bottomCenter',
  theme: 'nest',
  timeout: 3000,
  progressBar: true,
  dismissQueue: true,
  force: true,
  closeWith: ['click', 'button'],
  animation: {
    open: 'noty_bounceInUp',
    close: 'noty_bounceOutDown',
  },
};

const notify = (text, type = 'success', obj = {}) => {
  const options = { ...defaultNotyOptions, ...obj, text, type };
  new Noty(options).show();
};

export default notify;
