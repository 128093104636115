export enum TemplateRunStatusTypes {
  UNKNOWN = 'unknown',
  PENDING = 'pending',
  QUEUED = 'queued',
  RUNNING = 'running',
  ABORTING = 'aborting',
  SKIPPED = 'skipped',
  FAILURE = 'failure',
  SUCCESS = 'success',
  ABORTED = 'aborted',
}

export enum TemplateRunNodeStatusTypes {
  WAITING = 'waiting',
  RETRYING = 'retrying',
  SKIPPED = 'skipped',
  FAILURE = 'failure',
  SUCCESS = 'success',
  ABORTED = 'aborted',
}

export const templateRunActiveStatuses = [
  TemplateRunStatusTypes.PENDING,
  TemplateRunStatusTypes.RUNNING,
  TemplateRunStatusTypes.ABORTING,
];
export const templateRunPendingStatuses = [
  TemplateRunStatusTypes.QUEUED,
  ...templateRunActiveStatuses,
];
export const templateRunConcludedStatuses = [
  TemplateRunStatusTypes.FAILURE,
  TemplateRunStatusTypes.SUCCESS,
  TemplateRunStatusTypes.ABORTED,
];
