import React from 'react';

import { X } from '@styled-icons/boxicons-regular/X';
import styledCss from '@styled-system/css';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Icon from '@northflank/components/Icon';
import Image from '@northflank/components/Image';
import Text from '@northflank/components/Text';

const colorMap = {
  info: 'primary',
  success: 'success',
  warning: 'warning',
  danger: 'danger',
  note: 'background',
};

const StyledBox = styled(Box)(
  ({ type, css }) =>
    styledCss({
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: type === 'note' ? 'grey6' : colorMap[type],
      borderRadius: 2,
      bg: type === 'note' ? 'grey9' : `${colorMap[type]}Opacity9`,
      p: 8,
      width: '100%',
      ...css,

      '& li+li': {
        mt: 4,
      },
    }),
  space
);

const InfoBox = ({
  type,
  displayed,
  close,
  title,
  icon,
  image,
  children,
  ...rest
}) => {
  if (!displayed) return null;

  return (
    <StyledBox type={type} {...rest}>
      {(icon || title || close) && (
        <Box
          variant="flex"
          alignItems="center"
          width="100%"
          mb={children ? 6 : 0}
        >
          {icon && (
            <Icon
              Content={icon}
              color={type === 'note' ? 'grey1' : `${colorMap[type]}Light2`}
              mr={6}
            />
          )}
          {image && <Image src={image} width={20} mr={6} />}
          {title && (
            <Text
              fontWeight={2}
              fontSize={2}
              color={type === 'note' ? 'grey1' : `${colorMap[type]}Light2`}
            >
              {title}
            </Text>
          )}
          {close && (
            <Box flexGrow={1} variant="flex" justifyContent="flex-end">
              <Button
                type="button"
                onClick={() => close()}
                variant={['icon', 'noBackground', 'noBorder']}
                iconColor={type !== 'note' && `${colorMap[type]}Light2`}
              >
                <X size={16} />
              </Button>
            </Box>
          )}
        </Box>
      )}
      {children}
    </StyledBox>
  );
};

InfoBox.propTypes = {
  displayed: PropTypes.bool,
  noClose: PropTypes.bool,
  close: PropTypes.func,
  type: PropTypes.oneOf(['info', 'warning', 'danger', 'note']),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  icon: PropTypes.element,
  children: PropTypes.node,
};

InfoBox.defaultProps = {
  displayed: true,
  noClose: false,
  type: 'note',
  title: '',
  children: null,
};

export default InfoBox;
