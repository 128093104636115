import React from 'react';

import { Check } from '@styled-icons/boxicons-regular/Check';
import { Minus } from '@styled-icons/boxicons-regular/Minus';
import { X } from '@styled-icons/boxicons-regular/X';
import css from '@styled-system/css';
import styled from 'styled-components';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Grid from '@northflank/components/Grid';
import Icon from '@northflank/components/Icon';
import Text from '@northflank/components/Text';

const StyledTable = styled.ul(() =>
  css({
    width: '100%',
    fontSize: [2, 2, 2, 3],
    listStyle: 'none',
  })
);

const StyledHeader = styled.li(({ columns }) =>
  css({
    bg: 'grey9',
    border: '1px solid',
    borderColor: 'grey5',
    borderRadius: 1,
    boxShadow: 'dropDown',
    display: 'grid',
    gridTemplateColumns: columns,
    position: 'sticky',
    top: ['63px', '63px', '80px'],
    '> div': {
      p: 7,
      fontWeight: 600,
    },
  })
);

const StyledRow = styled.li(({ columns, rows }) =>
  css({
    borderBottom: '1px solid',
    borderColor: 'grey6',
    display: 'grid',
    gridTemplateColumns: columns,
    gridTemplateRows: rows,
    minHeight: '55px',
    '> div': {
      px: 7,
      py: [6, 6, 6, 4],
      alignSelf: 'center',
    },
  })
);

// memo to prevent dynamic flicker on re-render

const TierHeader = React.memo(({ tier }) => (
  <Box variant="flex" alignItems="flex-start" justifyContent="center">
    <Box minWidth="18px" mt={[0, 0, '1px']} mr={[0, 0, 5]}>
      <Icon Content={tier.attributes.icon} Size={18} color="primary" />
    </Box>
    <Text display={['none', 'none', 'block']}>
      {tier.attributes.shortTitle ?? tier.attributes.title}
    </Text>
  </Box>
));

TierHeader.displayName = 'TierHeader';

const SectionHeader = React.memo(({ section }) => (
  <Box variant="flex" alignItems="center" p={7}>
    <Icon Content={section.icon} Size={18} color="primary" mr={5} />
    <Text fontWeight={600}>{section.title}</Text>
    {section.description && (
      <Text color="grey3" fontSize={[1, 1, 2]} ml={6}>
        {section.description}
      </Text>
    )}
  </Box>
));

SectionHeader.displayName = 'SectionHeader';

const FeatureMatrix = ({ matrix, tiers }) => {
  const columns = [
    `repeat(${tiers.length}, 1fr)`,
    `repeat(${tiers.length}, 1fr)`,
    `2.5fr repeat(${tiers.length}, 1fr)`,
  ];
  const rows = ['1fr 1fr', '1fr 1fr', '1fr'];
  const gridSpan = `1/${tiers.length + 1}`;

  return (
    <Box position="relative">
      <StyledTable>
        <StyledHeader columns={columns}>
          <Box display={['none', 'none', 'block']} />
          {tiers.map((tier) => (
            <TierHeader key={`tier-${tier.attributes.key}`} tier={tier} />
          ))}
        </StyledHeader>
        {matrix?.map((section) => (
          <>
            <Box
              as="li"
              variant="flex"
              alignItems="flex-end"
              borderBottom="1px solid"
              borderColor="grey5"
              height="65px"
            >
              <SectionHeader section={section} />
            </Box>
            {section.items?.map((item, i) => (
              <StyledRow key={`matrix-row-${i}`} columns={columns} rows={rows}>
                <Box gridRow={1} gridColumn={[gridSpan, gridSpan, 1]}>
                  <Text fontSize={[2, 2, 2, 3]} color="grey2">
                    {item.title}
                  </Text>
                  {item.description && (
                    <Text color="grey3" fontSize={[1, 1, 1, 2]}>
                      {item.description}
                    </Text>
                  )}
                </Box>
                {tiers.map((tier, j) => {
                  // leaving out tiers is shorthand for all true
                  if (!item.tiers) {
                    return (
                      <Box
                        key={`matrix-row-${i}-item-${j}`}
                        gridRow={[2, 2, 1]}
                        textAlign="center"
                      >
                        <Box width="28px" mx="auto">
                          <Icon Content={Check} Size={28} color="success" />
                        </Box>
                      </Box>
                    );
                  }

                  const val = item.tiers[tier.attributes.key];
                  return (
                    <Box
                      key={`matrix-row-${i}-item-${j}`}
                      gridRow={[2, 2, 1]}
                      textAlign="center"
                    >
                      {typeof val === 'boolean' ||
                      val === 'true' ||
                      val === 'false' ? (
                        JSON.parse(val) ? (
                          <Box width="28px" mx="auto">
                            <Icon Content={Check} Size={28} color="success" />
                          </Box>
                        ) : (
                          <Box width="28px" mx="auto">
                            <Icon Content={X} Size={28} color="grey4" />
                          </Box>
                        )
                      ) : (
                        <>
                          {val === '-' ? (
                            <Box width="28px" mx="auto">
                              <Icon Content={Minus} Size={28} color="grey4" />
                            </Box>
                          ) : (
                            <>
                              {typeof val === 'string' ? (
                                <Text color="grey2">{val}</Text>
                              ) : (
                                val
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Box>
                  );
                })}
              </StyledRow>
            ))}
          </>
        ))}
        <Grid
          as="li"
          gridTemplateColumns={['1fr', '1fr', columns[2]]}
          gridGap={0}
        >
          <Box display={['none', 'none', 'block']} />
          {tiers.map((tier) => (
            <Box key={`button-${tier.attributes.key}`} px={[3, 3, 7]} mt={7}>
              <a
                id={`pricing-table-get-started-${tier.attributes.key}`}
                href={tier.attributes.linkUrl}
                target={
                  !tier.attributes.linkUrl.includes('northflank.com') &&
                  '_blank'
                }
              >
                <Button
                  variant="primary"
                  width="100%"
                  display={['none', 'none', 'flex']}
                >
                  {tier.attributes.linkText}
                </Button>
                <Button
                  variant="primary"
                  width="100%"
                  display={['flex', 'flex', 'none']}
                >
                  <Icon Content={tier.attributes.icon} mr={4} />
                  <strong>{tier.attributes.title}</strong>:&nbsp;
                  {tier.attributes.linkText}
                </Button>
              </a>
            </Box>
          ))}
        </Grid>
      </StyledTable>
    </Box>
  );
};

export default FeatureMatrix;
