import React, { useEffect, useRef, useState } from 'react';

import { FastField, useField, useFormikContext } from 'formik';

import Input from '@northflank/components/Input';
import { checkDuplicateFields } from '@northflank/utilities/Networking/ComponentUtils';

export const FormikInput = React.forwardRef((props, ref) => {
  const [field, meta] = useField(props);
  const { error, touched } = meta;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Input {...field} {...props} ref={ref} error={touched && error} />;
});

FormikInput.displayName = 'FormikInput';

export const FormikFastInput = React.forwardRef((props, ref) => (
  <FastField {...props}>
    {({ field, meta }) => {
      const { error, touched } = meta;
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Input {...field} {...props} ref={ref} error={touched && error} />;
    }}
  </FastField>
));

FormikFastInput.displayName = 'FormikFastInput';

export const FormikFastInputDuplicateCheck = ({
  field,
  entries,
  idx,
  additionalFields,
  ...rest
}) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = entries;
  }, [entries]);

  return (
    <FormikFastInput
      validate={checkDuplicateFields(field, ref, idx, additionalFields)}
      {...rest}
    />
  );
};

export const FormikDebouncedInput = React.forwardRef((props, ref) => {
  const [_value, _setValue] = useState('');

  const debounceTimeout = useRef();

  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  const { value, onChange, onBlur } = field;
  const { error, touched } = meta;

  delete field.value;
  delete field.onChange;
  delete field.onBlur;

  useEffect(() => {
    if (!debounceTimeout.current) _setValue(value);
  }, [value]);

  return (
    <Input
      {...field}
      {...props}
      value={_value}
      onChange={(e) => {
        e.persist();
        const val = e.target.value;
        _setValue(val);
        if (debounceTimeout.current) {
          clearTimeout(debounceTimeout.current);
          debounceTimeout.current = undefined;
        }
        debounceTimeout.current = setTimeout(() => {
          onChange(e);
          setFieldValue(field.name, val, true);
          if (typeof props.onChange === 'function') props.onChange(e);
          debounceTimeout.current = undefined;
        }, props.debounceTimeout || 250);
      }}
      onBlur={(e) => {
        onBlur(e);
        if (typeof props.onBlur === 'function') props.onBlur(e);
        setFieldValue(field.name, e.target.value, true);
      }}
      ref={ref}
      error={touched && error}
    />
  );
});

FormikDebouncedInput.displayName = 'FormikDebouncedInput';

export const FormikDebouncedInputDuplicateCheck = ({
  field,
  entries,
  idx,
  ...rest
}) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = entries;
  }, [entries]);

  return (
    <FormikDebouncedInput
      validate={checkDuplicateFields(field, ref, idx)}
      {...rest}
    />
  );
};
