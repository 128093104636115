import React from 'react';

import { LoaderAlt } from '@styled-icons/boxicons-regular/LoaderAlt';
import styled, { keyframes } from 'styled-components';

import Box from '@northflank/components/Box';

const LoadingSpin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoadingIcon = styled(LoaderAlt)`
  animation: ${LoadingSpin} 1s linear infinite;
`;

const Loading = ({ size = 12, ...props }) => (
  <Box variant="flex" alignItems="center" justifyContent="center" {...props}>
    <LoadingIcon size={size} />
  </Box>
);

export default Loading;
