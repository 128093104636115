import React, { useContext, useState } from 'react';

import { Award } from '@styled-icons/boxicons-regular/Award';
import { DollarCircle } from '@styled-icons/boxicons-regular/DollarCircle';
import { HelpCircle } from '@styled-icons/boxicons-regular/HelpCircle';
import { Star } from '@styled-icons/boxicons-solid/Star';
import { transparentize } from 'polished';
import { ThemeContext } from 'styled-components';

import Box from '@northflank/components/Box';
import Grid from '@northflank/components/Grid';
import Icon from '@northflank/components/Icon';
import FadedCollapsible from '@northflank/components/marketing/FadedCollapsible';
import { Tab } from '@northflank/components/marketing/FeatureSwitcher';
import {
  byocClusterResource,
  formatCost,
  resourcePriceObject,
  resources,
  sortPlans,
} from '@northflank/components/marketing/Pricing/data';
import ResourcePriceCard from '@northflank/components/marketing/Pricing/ResourcePriceCard';
import { CheckListItem } from '@northflank/components/marketing/Pricing/TierItem';
import Text from '@northflank/components/Text';
import Tooltip from '@northflank/components/Tooltip';
import { getResourcesFromPlan } from '@northflank/constants-base';
import {
  buildJobMultiplier,
  calculate1MinPlanPrice,
  calculateHourlyPlanPrice,
  calculateMonthlyPlanPrice,
} from '@northflank/pricing';

const pricingTabs = [
  {
    key: 'managed',
    title: 'Managed Cloud',
    features: [
      'The simplest way to deploy to the cloud and Kubernetes',
      'Deploy to global regions in seconds',
      'Low cost and secure multi-tenant infrastructure',
      'Monitored and scaled by Northflank SREs 24/7',
    ],
  },
  {
    key: 'byoc',
    title: 'Bring Your Own Cloud',
    features: [
      'Deploy to your own AWS, GCP, or Azure accounts',
      'Use your cloud credits and existing billing relationships',
      'Direct access and monitoring via K8s clusters',
      'Multi-cloud and bare-metal',
    ],
  },
];

const planLabels = {
  instance: {
    'nf-compute-20': 'Getting started',
    'nf-compute-50': 'Recommended for dev',
    'nf-compute-100-2': 'Most popular',
  },
  build: {
    'nf-compute-400': 'Default builder',
  },
  job: {
    'nf-compute-20': 'Getting started',
    'nf-compute-50': 'Recommended for dev',
    'nf-compute-100-2': 'Most popular',
  },
};

const Content = ({ priceTab, plans = [], isMarketing, Link }) => {
  const [pricing, setPricing] = useState('instance');
  const [pricePeriod, setPricePeriod] = useState('month');

  const plansWithResourceConfig = plans.map((p) => ({
    ...p,
    _resources: getResourcesFromPlan(p),
  }));

  return (
    <>
      {priceTab === 'managed' && !!plans?.length && (
        <Box
          bg="grey9"
          border="1px solid"
          borderColor="grey5"
          borderRadius={2}
          p={8}
          mb={7}
        >
          <Box>
            <Box
              variant="flex"
              flexDirection={['column', 'column', 'row']}
              alignItems="flex-start"
              justifyContent="space-between"
              mb={6}
            >
              <Box>
                <Box variant="flex" alignItems="flex-start">
                  <Box
                    position="relative"
                    mt="2px"
                    mr={5}
                    _css={{
                      '&::before': {
                        content: '""',
                        display: 'block',
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        bg: 'success',
                        borderRadius: 5,
                        opacity: 0.5,
                        filter: 'blur(16px)',
                      },
                    }}
                  >
                    <Icon Content={DollarCircle} Size={18} color="success" />
                  </Box>
                  <Text fontSize={3} fontWeight={isMarketing ? 600 : 500}>
                    Compute Plans
                  </Text>
                </Box>
                <Text color="grey3" mt={5}>
                  Northflank offers a wide range of predefined compute plans.
                  The infrastructure unit pricing that makes up these plans is
                  listed below the table. Custom plans are also available.
                </Text>
              </Box>
              <Box
                display="inline-flex"
                alignItems="center"
                bg="grey11"
                border="1px solid"
                borderColor="grey5"
                borderRadius="20px"
                p={2}
                mt={[7, 7, 0]}
              >
                <Tab
                  active={pricing === 'instance'}
                  onClick={() => setPricing('instance')}
                  fontSize={0}
                  height="auto"
                  lineHeight={1.4}
                  borderRadius="20px"
                  px={4}
                  py={0}
                >
                  Services/Addons
                </Tab>
                <Tab
                  active={pricing === 'job'}
                  onClick={() => setPricing('job')}
                  fontSize={0}
                  height="auto"
                  lineHeight={1.4}
                  borderRadius="20px"
                  px={4}
                  py={0}
                  mr={0}
                >
                  Jobs
                </Tab>
                <Tab
                  active={pricing === 'build'}
                  onClick={() => setPricing('build')}
                  fontSize={0}
                  height="auto"
                  lineHeight={1.4}
                  borderRadius="20px"
                  px={4}
                  py={0}
                  mr={0}
                >
                  Builds
                </Tab>
              </Box>
            </Box>
            <Grid
              gridTemplateColumns={[
                'repeat(4, 1fr)',
                'repeat(4, 1fr)',
                '1.5fr repeat(3, 1fr) 1.5fr',
              ]}
              gridColumnGap={[6, 6, '20px!important']}
              alignItems="end"
              px={[0, 0, 7]}
              py={8}
            >
              <Text
                fontSize={isMarketing ? [2, 2, 3] : 2}
                color="grey3"
                display={['none', 'none', 'block']}
                mediaQuery
              >
                Plan
              </Text>
              <Text fontSize={isMarketing ? [2, 2, 3] : 2} color="grey3">
                vCPU
              </Text>
              <Text fontSize={isMarketing ? [2, 2, 3] : 2} color="grey3">
                Memory
              </Text>
              <Box variant="flex" alignItems="center">
                <Text
                  fontSize={isMarketing ? [2, 2, 3] : 2}
                  color="grey3"
                  mr={4}
                >
                  Storage
                </Text>
                <Tooltip title="Ephemeral storage">
                  <Icon Content={HelpCircle} Size={16} color="grey4" />
                </Tooltip>
              </Box>
              <Text fontSize={isMarketing ? [2, 2, 3] : 2} color="grey3">
                Price per container
              </Text>
            </Grid>
            <FadedCollapsible collapsedHeight="250px">
              <Box>
                {plansWithResourceConfig
                  .filter(
                    (plan) =>
                      pricing !== 'build' ||
                      (plan._resources.cpu >= 4 && plan._resources.cpu <= 20)
                  )
                  .sort(sortPlans)
                  .map((plan, i) => {
                    const monthlyPrice = calculateMonthlyPlanPrice(
                      plan,
                      false,
                      resourcePriceObject
                    );
                    const hourlyPrice = calculateHourlyPlanPrice(
                      plan,
                      false,
                      resourcePriceObject
                    );
                    const buildPrice = calculate1MinPlanPrice(
                      plan,
                      false,
                      resourcePriceObject
                    );
                    const jobPrice = calculate1MinPlanPrice(
                      plan,
                      false,
                      resourcePriceObject
                    );

                    const planResources = getResourcesFromPlan(plan);

                    return (
                      <Box key={i} borderTop="1px solid" borderTopColor="grey6">
                        <Grid
                          gridTemplateColumns={[
                            'repeat(4, 1fr)',
                            'repeat(4, 1fr)',
                            '1.5fr repeat(3, 1fr) 1.5fr',
                          ]}
                          gridTemplateRows={['1fr 1fr', '1fr 1fr', '1fr']}
                          alignItems="center"
                          borderColor={plan.highlight ? 'grey5' : 'grey6'}
                          gridGap={0}
                          gridColumnGap={[6, 6, '20px!important']}
                          gridRowGap="12px!important"
                          px={[0, 0, 7]}
                          py={[6, 6, 0]}
                          height={['auto', 'auto', '60px']}
                        >
                          <Box
                            _css={{
                              gridRowStart: 1,
                              gridColumn: ['1 / span 4', '1 / span 4', 1],
                            }}
                          >
                            <Box variant="flex" alignItems="center">
                              <Text
                                fontSize={isMarketing ? 3 : 2}
                                color="grey1"
                                fontWeight={500}
                                lineHeight={1.2}
                              >
                                {plan.name}
                              </Text>
                              {plan.highlight && (
                                <Icon
                                  Content={Award}
                                  Size={24}
                                  color="primary"
                                  ml={3}
                                />
                              )}
                            </Box>
                            {planLabels[pricing]?.[plan.name] && (
                              <Box variant="flex" alignItems="center" mt={1}>
                                <Icon
                                  Content={Star}
                                  Size={12}
                                  color="primary"
                                  mr={2}
                                />
                                <Text
                                  color="grey3"
                                  fontSize={1}
                                  lineHeight={1.2}
                                >
                                  {planLabels[pricing][plan.name]}
                                </Text>
                              </Box>
                            )}
                          </Box>
                          <Box _css={{ gridRowStart: [2, 2, 1] }}>
                            <Text fontSize={isMarketing ? [2, 2, 3] : 2}>
                              {planResources.cpu}{' '}
                              <Text
                                as="span"
                                fontSize={isMarketing ? [1, 1, 2] : 1}
                                color="grey2"
                              >
                                {planResources.cpu >= 1
                                  ? 'dedicated'
                                  : 'shared'}
                              </Text>
                            </Text>
                          </Box>
                          <Box _css={{ gridRowStart: [2, 2, 1] }}>
                            <Text fontSize={isMarketing ? [2, 2, 3] : 2}>
                              {planResources.memory}{' '}
                              <Text
                                as="span"
                                fontSize={isMarketing ? [1, 1, 2] : 1}
                                color="grey2"
                              >
                                MB
                              </Text>
                            </Text>
                          </Box>
                          <Box _css={{ gridRowStart: [2, 2, 1] }}>
                            <Text fontSize={isMarketing ? [2, 2, 3] : 2}>
                              {pricing === 'build' ? '1 – 8' : '1'}{' '}
                              <Text
                                as="span"
                                fontSize={isMarketing ? [1, 1, 2] : 1}
                                color="grey2"
                              >
                                GB
                              </Text>
                            </Text>
                          </Box>
                          <Box _css={{ gridRowStart: [2, 2, 1] }}>
                            <Text fontSize={isMarketing ? [2, 2, 3] : 2} mb={2}>
                              <Text
                                as="span"
                                fontSize={isMarketing ? [1, 1, 2] : 1}
                              >
                                $
                              </Text>
                              {pricing === 'instance' ? (
                                <>
                                  {formatCost(monthlyPrice)}
                                  <Text
                                    as="span"
                                    fontSize={isMarketing ? [1, 1, 2] : 1}
                                    color="grey3"
                                  >
                                    /month
                                  </Text>
                                </>
                              ) : (
                                <>
                                  {pricing === 'job'
                                    ? jobPrice.toFixed(4)
                                    : buildPrice.toFixed(4)}
                                  <Text
                                    as="span"
                                    fontSize={isMarketing ? [1, 1, 2] : 1}
                                    color="grey3"
                                  >
                                    /minute
                                  </Text>
                                </>
                              )}
                            </Text>
                            <Text
                              fontSize={isMarketing ? [1, 1, 2] : 1}
                              color="grey3"
                            >
                              $
                              {(pricing === 'instance'
                                ? hourlyPrice
                                : hourlyPrice * buildJobMultiplier
                              ).toFixed(4)}
                              /hr
                            </Text>
                          </Box>
                        </Grid>
                      </Box>
                    );
                  })}
                <Box px={7} py={6} mt={6}>
                  <Box variant="flex" alignItems="center" mb={3}>
                    <Text
                      fontSize={3}
                      color="grey1"
                      fontWeight={500}
                      lineHeight={1.2}
                    >
                      Custom
                    </Text>
                  </Box>
                  <Text color="grey2">
                    If you need more resources, please{' '}
                    {isMarketing ? (
                      <Link href="/contact">get in touch</Link>
                    ) : (
                      <a
                        href="mailto:support@northflank.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        get in touch
                      </a>
                    )}{' '}
                    with us. We are happy to work with you on custom plans,
                    large numbers of replicas and more.
                  </Text>
                </Box>
              </Box>
            </FadedCollapsible>
          </Box>
        </Box>
      )}

      <Grid
        gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
        gridGap={7}
        alignItems="stretch"
      >
        {resources.map((resource) => (
          <ResourcePriceCard
            key={resource.title}
            resource={resource}
            pricePeriod={pricePeriod}
            setPricePeriod={setPricePeriod}
            isByoc={priceTab === 'byoc'}
            isMarketing={isMarketing}
          />
        ))}
        {priceTab === 'byoc' && (
          <>
            <ResourcePriceCard
              resource={byocClusterResource}
              pricePeriod={pricePeriod}
              setPricePeriod={setPricePeriod}
              isByoc={priceTab === 'byoc'}
              isMarketing={isMarketing}
            />
            <Box
              bg="grey10"
              border="1px solid"
              borderColor="grey5"
              borderRadius={2}
              p={6}
              gridRow={['4', '4', '4', '2']}
              gridColumn={['1', '1', '1 / 3', '1 / 5']}
            >
              <Text color="grey2" textAlign="center">
                The prices below only apply if you used Northflank’s managed
                offerings. These components can be self-hosted with Enterprise.
              </Text>
            </Box>
          </>
        )}
      </Grid>
    </>
  );
};

const InfraPricing = ({
  plans,
  Link,
  isMarketing,
  type = 'managed',
  ...rest
}) => {
  const [priceTab, setPriceTab] = useState(type);

  const theme = useContext(ThemeContext);

  if (!isMarketing)
    return (
      <Content priceTab={priceTab} plans={plans} isMarketing={isMarketing} />
    );

  return (
    <Box {...rest}>
      <Grid
        gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
        gridGap={4}
        alignItems="stretch"
        bg="grey9"
        border="1px solid"
        borderColor="grey5"
        borderRadius="8px 8px 0 0"
        p={4}
        position="relative"
        zIndex={0}
      >
        {pricingTabs.map((tab, i) => (
          <Box
            key={`pricing-tab-${tab.key}`}
            onClick={() => setPriceTab(tab.key)}
            bg={
              priceTab === tab.key
                ? transparentize(0.9, theme.colors.success)
                : 'transparent'
            }
            borderRadius={2}
            pl={i === 1 ? '36px' : 7}
            pr={i === 0 ? '36px' : 7}
            py={6}
            _css={{
              transition: '150ms',
              cursor: 'pointer',
              '&:hover': {
                bg:
                  priceTab === tab.key
                    ? transparentize(0.9, theme.colors.success)
                    : 'grey10',
              },
              '&::after': {
                content: i === 0 ? '"OR"' : '',
                position: 'absolute',
                bg: 'grey11',
                color: 'grey2',
                border: '1px solid',
                borderColor: 'grey5',
                borderRadius: '20px',
                fontSize: 3,
                fontWeight: 600,
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                px: 8,
                py: 3,
                zIndex: 2,
              },
            }}
          >
            <Text
              fontSize={3}
              fontWeight={500}
              color={priceTab === tab.key ? 'success' : 'grey1'}
              mb={6}
              css={{
                transition: '150ms',
              }}
            >
              {tab.title}
            </Text>
            <Box as="ul">
              {tab.features.map((feature, j) => (
                <CheckListItem
                  key={`pricing-tab-${tab.key}-feature-${j}`}
                  text={feature}
                  isMarketing={isMarketing}
                />
              ))}
            </Box>
          </Box>
        ))}
      </Grid>
      <Box
        borderWidth="0 1px 1px 1px"
        borderStyle="dashed"
        borderColor="grey5"
        borderRadius="0 0 8px 8px"
        p={10}
      >
        <Content
          priceTab={priceTab}
          plans={plans}
          isMarketing={isMarketing}
          Link={Link}
        />
      </Box>
    </Box>
  );
};

export default InfraPricing;
