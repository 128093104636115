import React from 'react';

import Box from '@northflank/components/Box';
import Heading from '@northflank/components/Heading';
import MarkdownBody from '@northflank/components/marketing/MarkdownBody';

import content from './content.md';

const Security = () => {
  return (
    <Box maxWidth="814px" mx="auto">
      <Box variant="bounding">
        <Heading as="h1" fontSize={[6, 6, 7]} color="grey1" mb={13}>
          Security
        </Heading>
        <MarkdownBody dangerouslySetInnerHTML={{ __html: content.html }} />
      </Box>
    </Box>
  );
};

export default Security;
