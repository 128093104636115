import React, { useContext } from 'react';

import { InfoCircle } from '@styled-icons/boxicons-regular/InfoCircle';
import { transparentize } from 'polished';
import { ThemeContext } from 'styled-components';

import Box from '@northflank/components/Box';
import Icon from '@northflank/components/Icon';
import Text from '@northflank/components/Text';
import Tooltip from '@northflank/components/Tooltip';

const ResourcePriceCard = ({
  resource,
  pricePeriod,
  setPricePeriod,
  isByoc,
  isMarketing,
}) => {
  const theme = useContext(ThemeContext);

  const {
    title,
    byocTitle,
    icon,
    price,
    hourlyPrice,
    byocPrice,
    byocHourlyPrice,
    priceOverride,
    unit,
    additionalPrice,
    additionalUnit,
    tooltip,
    description,
    byocDescription,
    small,
    onClick,
    gradientColor,
    gridColumn,
    byocGridRow,
    byocGridColumn,
    hideByoc,
    ...rest
  } = resource;

  if (isByoc && hideByoc) return null;

  const displayPrices = {
    minute: (isByoc ? byocHourlyPrice : hourlyPrice) / 60,
    hour: isByoc ? byocHourlyPrice : hourlyPrice,
    month: isByoc ? byocPrice : price,
  };
  const displayPrice = hourlyPrice ? displayPrices[pricePeriod] : price;

  return (
    <Box
      background={`radial-gradient(91% 198% at -3.5% -14%, ${
        gradientColor ?? theme.colors.grey8
      } 0%, ${theme.colors.grey9} 100%)`}
      bg="grey9"
      border="1px solid"
      borderColor="grey5"
      borderRadius={2}
      p={8}
      onClick={onClick}
      _css={{
        cursor: onClick ? 'pointer' : 'default',
      }}
      gridRow={isByoc ? byocGridRow : undefined}
      gridColumn={isByoc ? (byocGridColumn ?? gridColumn) : gridColumn}
      {...rest}
    >
      <Box variant="flex" alignItems="center" justifyContent="space-between">
        <Box variant="flex" alignItems="flex-start" mr={7}>
          <Box
            position="relative"
            mt="2px"
            mr={5}
            _css={{
              '&::before': {
                content: '""',
                display: 'block',
                width: '100%',
                height: '100%',
                position: 'absolute',
                bg: 'success',
                borderRadius: 5,
                opacity: 0.5,
                filter: 'blur(16px)',
              },
            }}
          >
            <Icon Content={icon} Size={18} color="success" />
          </Box>
          <Text fontSize={3} fontWeight={isMarketing ? 600 : 500}>
            {isByoc ? (byocTitle ?? title) : title}
          </Text>
        </Box>
        {!!hourlyPrice && (
          <Box
            fontSize={0}
            color="grey3"
            bg="grey11"
            border="1px solid"
            borderColor="grey5"
            borderRadius="20px"
            px={2}
            py={1}
          >
            {Object.keys(displayPrices).map((p) => (
              <Text
                key={p}
                as="span"
                color={p === pricePeriod ? 'success' : 'grey2'}
                bg={
                  p === pricePeriod
                    ? transparentize(0.8, theme.colors.success)
                    : 'transparent'
                }
                onClick={() => setPricePeriod(p)}
                px={4}
                borderRadius="20px"
                css={{ cursor: 'pointer', transition: '150ms' }}
              >
                {p}
              </Text>
            ))}
          </Box>
        )}
      </Box>
      {(priceOverride || displayPrice) && (
        <Box variant="flex" alignItems="flex-start" mt={6}>
          <Text
            fontSize={isMarketing ? (small ? 3 : 4) : 3}
            fontWeight={isMarketing ? 600 : 500}
          >
            {priceOverride ?? (
              <>
                $
                {displayPrice.toString().split('.')[1]?.length > 2
                  ? displayPrice.toFixed(5)
                  : displayPrice.toFixed(2)}
              </>
            )}{' '}
            <Text as="span" fontSize={isMarketing ? 3 : 2} color="grey2">
              {unit}
              {hourlyPrice ? ` / ${pricePeriod}` : ''}
            </Text>
          </Text>
          {additionalPrice && (
            <Text
              fontSize={isMarketing ? (small ? 3 : 4) : 3}
              fontWeight={isMarketing ? 600 : 500}
            >
              <Text as="span" color="grey2">
                ,
              </Text>{' '}
              $
              {additionalPrice.toString().split('.')[1]?.length > 2
                ? additionalPrice.toFixed(5)
                : additionalPrice.toFixed(2)}{' '}
              <Text as="span" fontSize={isMarketing ? 3 : 2} color="grey2">
                {additionalUnit}
              </Text>
            </Text>
          )}
          {tooltip && (
            <Tooltip title={tooltip} mt={1} ml={4}>
              <Icon Content={InfoCircle} Size={18} color="grey3" />
            </Tooltip>
          )}
        </Box>
      )}
      {((!isByoc && description) || (isByoc && byocDescription)) && (
        <Text color="grey2" fontSize={2} mt={6}>
          {isByoc ? byocDescription : description}
        </Text>
      )}
    </Box>
  );
};

export default ResourcePriceCard;
