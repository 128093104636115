import React from 'react';

import { CreditCard, UserPlus } from '@styled-icons/boxicons-regular';
import { Cloud } from '@styled-icons/boxicons-regular/Cloud';
import { CloudUpload } from '@styled-icons/boxicons-regular/CloudUpload';
import { Customize } from '@styled-icons/boxicons-regular/Customize';
import { Data } from '@styled-icons/boxicons-regular/Data';
import { Duplicate } from '@styled-icons/boxicons-regular/Duplicate';
import { GitBranch } from '@styled-icons/boxicons-regular/GitBranch';
import { Globe } from '@styled-icons/boxicons-regular/Globe';
import { Group } from '@styled-icons/boxicons-regular/Group';
import { InfoCircle } from '@styled-icons/boxicons-regular/InfoCircle';
import { Layer } from '@styled-icons/boxicons-regular/Layer';
import { PackageIcon } from '@styled-icons/boxicons-regular/PackageIcon';
import { Search } from '@styled-icons/boxicons-regular/Search';
import { Wrench } from '@styled-icons/boxicons-regular/Wrench';

import Box from '@northflank/components/Box';
import Icon from '@northflank/components/Icon';
import Text from '@northflank/components/Text';
import Tooltip from '@northflank/components/Tooltip';

export const SandboxLimitedResourcesTooltip = () => (
  <>
    Deploy up to $20/mo worth of free resources on Northflank’s managed cloud.
    <br />
    Use up to 12 vCPU and 48 GB of memory on your free BYOC cluster.
  </>
);

const featurematrix = [
  {
    title: 'Resources',
    description: null,
    icon: Layer,
    items: [
      {
        title: 'Projects',
        description: 'Organise your Northflank resources',
        tiers: {
          developer: '2',
          team: '∞',
          organisation: '∞',
          enterprise: '∞',
        },
      },
      {
        title: 'Services',
        description: 'Build and deploy your code and custom Docker images',
        tiers: {
          developer: '2',
          team: '∞',
          organisation: '∞',
          enterprise: '∞',
        },
      },
      {
        title: 'Jobs',
        description: 'Run your code on a cron schedule or as a one off',
        tiers: {
          developer: '2',
          team: '∞',
          organisation: '∞',
          enterprise: '∞',
        },
      },
      {
        title: 'Addons',
        description: 'Deploy databases and other stateful services',
        tiers: {
          developer: '1',
          team: '∞',
          organisation: '∞',
          enterprise: '∞',
        },
      },
      {
        title: 'Secret groups',
        description:
          'Secure and inject environment variables and other secrets',
        tiers: {
          developer: '∞',
          team: '∞',
          organisation: '∞',
          enterprise: '∞',
        },
      },
      {
        title: 'Pipelines',
        description: 'Set up complex release and deployment pipelines',
        tiers: {
          developer: '∞',
          team: '∞',
          organisation: '∞',
          enterprise: '∞',
        },
      },
      {
        title: 'Compute',
        description: 'Meet vCPU and memory requirements',
        tiers: {
          developer: (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Text>Limited</Text>
              <Tooltip html={<SandboxLimitedResourcesTooltip />}>
                <Icon Content={InfoCircle} color="grey3" ml={4} />
              </Tooltip>
            </Box>
          ),
          team: '∞',
          organisation: '∞',
          enterprise: '∞',
        },
      },
      {
        title: 'Custom compute plans',
        description: 'Request custom vCPU + memory combinations',
        tiers: {
          developer: 'false',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
    ],
  },
  {
    title: 'Build',
    description: null,
    icon: Wrench,
    items: [
      {
        title: 'Continuous integration',
        description: 'Build all new commits or create branch & PR rules',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Build with Dockerfiles',
        description: 'Build anything with a custom Dockerfile',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Build with Buildpacks',
        description: 'Build with zero configuration using Buildpacks',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Advanced builds',
        description: 'Caching, target Docker stage, skip and path rules',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
    ],
  },
  {
    title: 'Version control',
    description: null,
    icon: GitBranch,
    items: [
      {
        title: 'GitHub, GitLab & Bitbucket integration',
        description: 'Build and deploy from your preferred VCS',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Self-hosted GitLab integration',
        description: 'Build and deploy from your self-hosted GitLab instance',
        tiers: {
          developer: 'false',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Azure DevOps integration',
        description: 'Build and deploy from your Azure DevOps or Entra app',
        tiers: {
          developer: 'false',
          team: 'false',
          organisation: 'false',
          enterprise: 'true',
        },
      },
    ],
  },
  {
    title: 'Deployment',
    description: null,
    icon: CloudUpload,
    items: [
      {
        title: 'Continuous deployment',
        description: 'Automatically deploy changes on build and merge',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Continuous delivery',
        description: 'Release changes to staging and production environments',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Deploy container images built on Northflank',
        description: 'Deploy any commit you’ve built on Northflank in seconds',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Deploy container images from external registries',
        description: 'Deploy Docker images from public & private registries',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Rollback and select specific builds',
        description: 'Rapidly rollback or switch between your stored builds',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Volumes and persistence',
        description:
          'Easily attach, scale and mount persistent disks to your workloads',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'GPUs',
        description:
          'Run LLMs, ML inference, training and Jupyter notebooks seamlessly',
        tiers: {
          developer: 'false',
          team: 'false',
          organisation: 'false',
          enterprise: 'true',
        },
      },
    ],
  },
  {
    title: 'Platform',
    description: '',
    icon: PackageIcon,
    items: [
      {
        title: 'UI / API / CLI access',
        description: 'Use Northflank via the app, API, or command line',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Templates',
        description: 'Codify infrastructure in reusable IaC',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'GitOps',
        description: 'Manage your templates and releases with Git',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Release flows',
        description: 'Automated workflows with triggers for advanced releases',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Real-time platform',
        description: 'Work in a fully reactive environment',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Platform SDK',
        description:
          'Build and deliver your own comprehensive platform utilising Northflank’s APIs',
        tiers: {
          developer: 'false',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
    ],
  },
  {
    title: 'Teams',
    description: null,
    icon: Group,
    items: [
      {
        title: 'Users',
        description: 'Scale up as your organisation grows',
        tiers: {
          developer: '∞',
          team: '∞',
          organisation: '∞',
          enterprise: '∞',
        },
      },
      {
        title: 'Fine-grained RBAC',
        description:
          'Assign roles with detailed permissions to individual members',
        tiers: {
          developer: 'false',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Organisations',
        description: 'Handle organisations with multiple independent teams',
        tiers: {
          developer: 'false',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'SAML & OIDC',
        description: 'Advanced authorisation and authentication',
        tiers: {
          developer: 'false',
          team: 'Pay per integration',
          organisation: 'false',
          enterprise: 'Pay per integration',
        },
      },
    ],
  },
  {
    title: 'Networking',
    description: null,
    icon: Globe,
    items: [
      {
        title: 'Custom domains',
        description: 'Point your own domains at Northflank services',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Automatic TLS & DNS',
        description: 'Free TLS and global DNS on the code.run domain',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Load balancing across instances',
        description:
          'Scale up to multiple instances and get auto load balancing',
        tiers: {
          developer: 'false',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Northflank proxy',
        description:
          'Access your private services & databases via our CLI proxy',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Service mesh and mTLS',
        description: 'Cloud native service mesh with end-to-end encryption',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Network security',
        description: 'Create IP policies and basic authentication rules',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Egress IP & Gateway',
        description: 'Route egress traffic via a gateway for static IPs',
        tiers: {
          developer: 'false',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
    ],
  },
  {
    title: 'Databases',
    description: null,
    icon: Data,
    items: [
      {
        title: 'Datastores you know and love',
        description: 'PostgreSQL, MySQL, MongoDB®, Redis®, and more',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Queues',
        description: 'Use events and message brokers with RabbitMQ & Redis',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'High availability',
        description: 'Spin up multiple replicas of your stateful services',
        tiers: {
          developer: 'false',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Backups & restores',
        description: 'Make and restore dumps & disk images of your databases',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Fork databases',
        description: 'Create a new database from the existing state of another',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Zonal redundancy',
        description: 'Run database replicas across multiple availability zones',
        tiers: {
          developer: 'false',
          team: 'false',
          organisation: 'false',
          enterprise: 'true',
        },
      },
    ],
  },
  {
    title: 'Scaling',
    description: null,
    icon: Duplicate,
    items: [
      {
        title: 'Vertical scaling',
        description: 'Give your instances more CPU & RAM resources',
        tiers: {
          developer: 'Limited',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Horizontal scaling',
        description: 'Deploy multiple replicas of your services and addons',
        tiers: {
          developer: 'false',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Autoscaling',
        description: 'Automatically scale based on usage thresholds',
        tiers: {
          developer: 'false',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
    ],
  },
  {
    title: 'Observability',
    description: null,
    icon: Search,
    items: [
      {
        title: 'Real-time log tailing',
        description: 'Stream your application logs in real time',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Real-time metrics',
        description: 'View CPU usage, memory consumption, and more',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Log manipulation',
        description: 'Powerful log searching and filtering',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: '30 day retention',
        description: 'Logs and metrics are retained for a month',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Log sinks',
        description:
          'Stream your logs in real-time to 3rd party observability platforms',
        tiers: {
          developer: 'false',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Alerting',
        description: 'Trigger alerts based on important container events',
        tiers: {
          developer: 'false',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Notifications',
        description:
          'Send platform events to Slack, Email, Discord, Teams, and Webhooks',
        tiers: {
          developer: 'false',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Audit logging',
        description: 'Maintain a log of all actions and events within your org',
        tiers: {
          developer: 'false',
          team: 'false',
          organisation: 'false',
          enterprise: 'true',
        },
      },
    ],
  },
  {
    title: 'Cloud and Regions',
    description: null,
    icon: Cloud,
    items: [
      {
        title: 'Northflank’s managed cloud',
        description: 'Run workloads on Northflank’s multi-tenant cloud',
      },
      {
        title: 'Northflank’s regions',
        description: 'Choose from many regions distributed globally',
      },
      {
        title: 'Cloud providers',
        description: 'Link your GCP, AWS, Azure and Civo accounts',
        tiers: {
          developer: '1',
          team: 'All',
          organisation: 'All',
          enterprise: 'All',
        },
      },
      {
        title: 'AWS',
        description:
          'Deploy in minutes with your AWS account with Amazon Elastic Kubernetes Service (EKS)',
      },
      {
        title: 'Azure',
        description:
          'Deploy in minutes with your Azure account and Azure Kubernetes Service (AKS)',
      },
      {
        title: 'GCP',
        description:
          'Deploy in minutes with your GCP account with Google Kubernetes Engine (GKE)',
      },
      {
        title: 'Civo',
        description:
          'Deploy in minutes with your Civo account with Civo Kubernetes',
      },
      {
        title: 'Clusters',
        description:
          'Northflank provisions managed Kubernetes clusters for you',
        tiers: {
          developer: '1',
          team: '∞',
          organisation: '∞',
          enterprise: '∞',
        },
      },
      {
        title: 'Cloud provider regions',
        description: 'Make use of your provider’s expansive geo offerings',
      },
      {
        title: 'Bring your own Kubernetes',
        description:
          'Deploy Northflank to your on-prem, bare metal, or public cloud clusters',
        tiers: {
          developer: 'false',
          team: 'false',
          organisation: 'false',
          enterprise: 'true',
        },
      },
      {
        title: 'Cloud credits',
        description: 'Use your cloud credits from one or more providers',
      },
      {
        title: 'Savings Plans, Commits, and Spot',
        description: 'Use your savings plans, commitments, and spot instances',
      },
    ],
  },
  {
    title: 'Bring Your Own',
    description: null,
    icon: UserPlus,
    items: [
      {
        title: 'Cloud',
        description: '',
        tiers: {
          developer: 'true',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Self-deployable control plane',
        description: '',
        tiers: {
          developer: 'false',
          team: 'false',
          organisation: 'false',
          enterprise: 'true',
        },
      },
      {
        title: 'Logs',
        description: '',
        tiers: {
          developer: 'false',
          team: 'false',
          organisation: 'false',
          enterprise: 'true',
        },
      },
      {
        title: 'Metrics',
        description: '',
        tiers: {
          developer: 'false',
          team: 'false',
          organisation: 'false',
          enterprise: 'true',
        },
      },
      {
        title: 'Vault',
        description: '',
        tiers: {
          developer: 'false',
          team: 'false',
          organisation: 'false',
          enterprise: 'true',
        },
      },
      {
        title: 'DNS',
        description: '',
        tiers: {
          developer: 'false',
          team: 'false',
          organisation: 'false',
          enterprise: 'true',
        },
      },
      {
        title: 'Registry',
        description: '',
        tiers: {
          developer: 'false',
          team: 'false',
          organisation: 'false',
          enterprise: 'true',
        },
      },
    ],
  },
  {
    title: 'Extras',
    description: null,
    icon: Customize,
    items: [
      {
        title: 'Support',
        description:
          'We can support your onboarding and migration to Northflank',
        tiers: {
          developer: 'Best effort',
          team: 'Proactive',
          organisation: 'Custom',
          enterprise: 'Custom',
        },
      },
      {
        title: 'Dedicated Slack channel',
        description: 'Shared Slack channel for direct comms with our engineers',
        tiers: {
          developer: 'false',
          team: 'false',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'SLA',
        description: 'Service-level agreements to meet your requirements',
        tiers: {
          developer: 'false',
          team: 'false',
          organisation: 'Add-on available',
          enterprise: 'Custom',
        },
      },
      {
        title: 'White labelling',
        description:
          'Make the underlying Northflank platform native to your branding',
        tiers: {
          developer: 'false',
          team: 'false',
          organisation: 'false',
          enterprise: 'true',
        },
      },
    ],
  },
  {
    title: 'Billing',
    description: null,
    icon: CreditCard,
    items: [
      {
        title: 'To the second billing',
        description: 'Resources are billed by usage down to the second',
        tiers: {
          developer: '-',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Invoice history',
        description:
          'Detailed invoice breakdowns are available for every month',
        tiers: {
          developer: '-',
          team: 'true',
          organisation: 'true',
          enterprise: 'true',
        },
      },
      {
        title: 'Pricing',
        description: 'Pricing to suit every team at every size',
        tiers: {
          developer: 'Free',
          team: 'Starts at $0/mo',
          organisation: 'Starts at $1000/mo',
          enterprise: 'Custom',
        },
      },
    ],
  },
];

export default featurematrix;
