import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { X } from '@styled-icons/boxicons-regular/X';
import css from '@styled-system/css';
import qs from 'qs';
import styled from 'styled-components';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Grid from '@northflank/components/Grid';
import Heading from '@northflank/components/Heading';
import Input from '@northflank/components/Input';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';

import PreHeading from '../../components/PreHeading';
import Tags from '../../components/Tags';
import formatDate from '../../utils/formatDate';
import { ArticleImage, ResourcesNav } from '../blog/index';

const StyledLink = styled.span(() =>
  css({
    '&:hover': {
      h2: {
        textDecoration: 'underline',
      },
    },
  })
);

const StyledImageLink = styled.div(({ minWidth }) =>
  css({
    minWidth,
    '&:hover': {
      '& + div h2': {
        textDecoration: 'underline',
      },
    },
  })
);

export const GuideItem = ({ post }) => {
  const href = post.blog
    ? `/blog/${post.attributes.slug}`
    : post.stack
      ? `/stacks/${post.template.internalId}`
      : `/guides/${post.attributes.slug}`;

  return (
    <Box
      variant="flex"
      flexDirection={['column', 'column', 'row']}
      alignItems="flex-start"
    >
      <StyledImageLink as={Link} to={href} minWidth={['100%', '100%', '30%']}>
        <ArticleImage src={post.attributes.header?.data?.attributes.url} />
      </StyledImageLink>
      <Box ml={[0, 0, 10]} mt={[8, 8, 0]}>
        {!!post.attributes.guide_categories?.data.length && (
          <Box variant="flex" mb={6}>
            {post.attributes.guide_categories.data.map((category, i) => (
              <Text
                key={category.attributes.slug}
                color="success"
                textTransform="uppercase"
                fontWeight={500}
                fontSize={1}
                mr={3}
              >
                <Link to={`/guides/category/${category.attributes.slug}`}>
                  <Text
                    as="span"
                    color="success"
                    css={{ '&:hover': { textDecoration: 'underline' } }}
                  >
                    {category.attributes.title}
                  </Text>
                </Link>
                {i < post.attributes.guide_categories.data.length - 1 && ','}
              </Text>
            ))}
          </Box>
        )}
        {post.blog && (
          <Text
            color="success"
            textTransform="uppercase"
            fontWeight={500}
            fontSize={1}
            mb={6}
          >
            <Link to="/blog">
              <Text
                as="span"
                color="success"
                css={{ '&:hover': { textDecoration: 'underline' } }}
              >
                Blog
              </Text>
            </Link>
          </Text>
        )}
        {post.stack && (
          <Text
            color="success"
            textTransform="uppercase"
            fontWeight={500}
            fontSize={1}
            mb={6}
          >
            <Link to="/stacks">
              <Text
                as="span"
                color="success"
                css={{ '&:hover': { textDecoration: 'underline' } }}
              >
                Stack template
              </Text>
            </Link>
          </Text>
        )}
        <Link to={href}>
          <StyledLink>
            <Heading color="grey1" fontSize={4} mb={4}>
              {post.stack ? post.template.name : post.attributes.title}
            </Heading>
            <Text color="grey3" mb={8}>
              {post.stack ? post.template.description : post.attributes.excerpt}
            </Text>
            <Text color="grey3" mb={8}>
              {formatDate(post.attributes.publication_date)} &bull;{' '}
              {post.attributes.writers.data
                .map((author) => author.attributes.name)
                .join(', ')}
            </Text>
          </StyledLink>
        </Link>
        <Box variant="flex">
          <Tags tags={post.attributes.tags.data} context="guides" />
        </Box>
      </Box>
    </Box>
  );
};

const Guides = ({
  posts = [],
  total,
  categories,
  tags,
  currentCategory,
  currentTag,
  ssrUpdate,
}) => {
  const [loadedPosts, setLoadedPosts] = useState(posts);
  const [searchInputValue, setSearchInputValue] = useState('');
  const loadMoreRef = useRef();

  const { pathname, search: _search } = useLocation();
  const history = useHistory();

  const url = new URL(pathname, 'https://northflank.com');

  const params = new URLSearchParams(_search);
  const search = params.get('search');

  useEffect(() => {
    setSearchInputValue(search);
  }, [search]);

  useEffect(() => {
    setLoadedPosts(posts);
  }, [ssrUpdate]);

  useEffect(() => {
    if (loadMoreRef.current) {
      const options = {
        rootMargin: '0px 0px 500px 0px',
        threshold: 1.0,
      };
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(({ isIntersecting }) => {
          if (isIntersecting && loadedPosts.length < total) {
            fetchNextPage(loadedPosts.length);
          }
        });
      }, options);
      observer.observe(loadMoreRef.current);

      return () => {
        if (loadMoreRef.current) observer.unobserve(loadMoreRef.current);
      };
    }
  }, [loadMoreRef, loadedPosts?.length]);

  const fetchNextPage = async (start) => {
    const queryString = qs.stringify({
      category: currentCategory,
      tag: currentTag,
      search: searchInputValue,
    });
    const res = await fetch(`/api/guides/${start}?${queryString}`);
    const newPosts = await res.json();
    let existing = [...loadedPosts];
    if (newPosts.guides?.data?.length) {
      existing = existing.concat(newPosts.guides.data);
      setLoadedPosts(existing);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    const query = form.get('search');
    if (query) history.push(`${url.pathname}?search=${query}`);
    else history.push(url.pathname);
  };

  const currentCategoryName = categories?.data?.find(
    (category) => category.attributes.slug === currentCategory
  )?.attributes.title;
  const currentTagName = tags?.data?.find(
    (tag) => tag.attributes.slug === currentTag
  )?.attributes.title;

  return (
    <>
      <SEO
        title={
          currentCategoryName
            ? `${currentCategoryName} — Guides & Tutorials`
            : currentTagName
              ? `${currentTagName} — Guides & Tutorials`
              : 'Guides & Tutorials'
        }
        description="Helpful guides, technical tutorials and more. Level up your ability with detailed articles from the Northflank team."
      />
      <Box variant="bounding">
        <Box mb={14}>
          <ResourcesNav />
          {currentCategory && (
            <PreHeading mb={6}>Guides & Tutorials</PreHeading>
          )}
          <Heading as="h1" fontSize={[6, 6, 7]} color="grey1" mb={12}>
            {!currentCategory ? 'Guides & Tutorials' : currentCategoryName}
          </Heading>
          <form onSubmit={handleSearch}>
            <Box variant="flex" width={['100%', '100%', '50%']}>
              <Input
                name="search"
                value={searchInputValue}
                onChange={(e) => setSearchInputValue(e.target.value)}
                placeholder="Search guides & tutorials"
                width="100%"
                wrapperProps={{ flexGrow: 1, mr: 6 }}
                internalButton={
                  search && (
                    <Button
                      type="button"
                      variant={['icon', 'noBorder']}
                      onClick={() => {
                        history.push(url.pathname);
                        setSearchInputValue('');
                      }}
                    >
                      <X size={16} />
                    </Button>
                  )
                }
              />
              <Button variant="primary">Search</Button>
            </Box>
          </form>
          {currentTag ? (
            <Text color="grey3" mt={10}>
              Showing items tagged with “{currentTagName}” (
              <Link to="/guides">see all</Link>)
            </Text>
          ) : (
            <Box mt={10}>
              <Text
                color="grey3"
                fontWeight={500}
                fontSize={1}
                textTransform="uppercase"
                mb={7}
              >
                Categories
              </Text>
              <Tags tags={categories?.data} context="guides" />
            </Box>
          )}
        </Box>
        <Grid gridTemplateColumns="1fr" gridGap={12} alignItems="start">
          {loadedPosts?.length ? (
            loadedPosts.map((post) => <GuideItem key={post.id} post={post} />)
          ) : (
            <Text color="grey3" fontSize={3} textAlign="center">
              No posts to show.
            </Text>
          )}
        </Grid>
        {loadedPosts.length < total && <div ref={loadMoreRef} />}
      </Box>
    </>
  );
};

export default Guides;
