import React from 'react';

import { Cog } from '@styled-icons/boxicons-regular/Cog';
import { DollarCircle } from '@styled-icons/boxicons-regular/DollarCircle';
import { Group } from '@styled-icons/boxicons-regular/Group';
import { Layer } from '@styled-icons/boxicons-regular/Layer';
import { Server } from '@styled-icons/boxicons-regular/Server';
import { ShapeSquare } from '@styled-icons/boxicons-regular/ShapeSquare';
import { World } from '@styled-icons/boxicons-regular/World';

import Box from '@northflank/components/Box';
import Heading from '@northflank/components/Heading';
import { MasonryCard } from '@northflank/components/marketing/FeatureMasonry';
import FeatureNav from '@northflank/components/marketing/FeatureNav';
import FeatureWithIcon from '@northflank/components/marketing/FeatureWithIcon';
import Map from '@northflank/components/marketing/Map';
import SEO from '@northflank/components/marketing/SEO';
import { TestimonialHeader } from '@northflank/components/marketing/Testimonial';
import Text from '@northflank/components/Text';
import { capitalize } from '@northflank/constants-base/functions';

import BYOCRegions from '../../components/CloudProviders/BYOCRegions';
import { cloudProviderOptions } from '../../components/CloudProviders/utility/cloudProviderOptions';
import {
  FeatureGroup,
  FeaturesWrapper,
} from '../../components/FeaturePageWrapper';
import PreHeading from '../../components/PreHeading';

const Page = ({ regions, providers }) => {
  const providersList = cloudProviderOptions
    .filter((p) => p.value !== 'northflank' && p.value !== 'gpus')
    .map((p) =>
      p.value.length > 3 ? capitalize(p.value) : p.value.toUpperCase()
    );

  const providersText = providersList
    .map((p, i) => (i === providersList.length - 1 ? `and ${p}` : p))
    .join(', ');

  return (
    <>
      <SEO
        title="Bring Your Own Cloud — Features"
        description={`With Bring Your Own Cloud (BYOC) deploy into your own ${providersText} accounts. Enjoy all the benefits of Northflank’s platform layer while maintaining control over your data residency, deployment regions, cloud expenses, and security.`}
      />
      <FeatureNav />
      <FeaturesWrapper>
        <Box variant="bounding" pb="70px!important">
          <PreHeading mb={6}>Feature breakdown</PreHeading>
          <Heading as="h1" color="grey1" fontSize={[6, 6, 7, 7]} mb={8}>
            Northflank in Your Cloud
          </Heading>
          <Text color="grey3" fontSize={4} width={[1, 1, 1 / 2]} mediaQuery>
            Deploy into your own {providersText} accounts with Bring Your Own
            Cloud (BYOC). Enjoy all the benefits of Northflank’s platform layer
            while maintaining control over your data residency, deployment
            regions, cloud expenses, and security.
          </Text>
        </Box>
        <FeatureGroup
          heading="Choice of Cloud"
          text="Deploy projects across your preferred cloud providers. Get all the benefits of managed Kubernetes with: auto upgrades, fully managed clusters, elastic compute, and native cloud provider networking & storage."
          wideImage="/images/feature/details/byoc/creation.png"
          wideImageAlt="Screenshot of Northflank template code editor"
          items={
            <>
              {cloudProviderOptions
                .filter((p) => p.value !== 'northflank' && p.value !== 'gpus')
                .map((p) => (
                  <FeatureWithIcon
                    key={p.value}
                    Icon={p.icon}
                    iconGradient
                    heading={p.label}
                    text={`Deploy in your own ${
                      p.value.length > 3
                        ? capitalize(p.value)
                        : p.value.toUpperCase()
                    } account with ${p.engine}.`}
                  />
                ))}
              <FeatureWithIcon
                Icon={Server}
                iconGradient
                heading="Bare-Metal"
                text="Deploy Northflank to your on-prem hardware to meet advanced security requirements, make the most of existing investments, and drive platform adoption."
              />
            </>
          }
        />
        <Box id="choice-of-regions-and-zones" px={[9, 9, 12]}>
          <Box
            variant="bounding"
            px="0px!important"
            py={[
              '64px!important',
              '64px!important',
              '64px!important',
              '96px!important',
            ]}
            borderTop="1px solid"
            borderColor="grey6"
          >
            <Text fontSize={6} fontWeight={600} mb={6}>
              Choice of Regions and Zones
            </Text>
            <Text fontSize={4} color="grey3" mb={13}>
              Rapidly scale and deploy workloads close to your business
              operations with over 60 available regions across the Americas,
              Europe, Asia Pacific, and the Middle East.
            </Text>
            <Map />
            {providers?.length ? (
              <BYOCRegions regions={regions} providers={providers} mt={13} />
            ) : null}
          </Box>
        </Box>
        <FeatureGroup
          heading="Cost Optimisation"
          text="Minimise your overhead while maximising your productivity."
          wideImage="/images/feature/details/byoc/components.png"
          wideImageAlt="Screenshot of Northflank template code editor"
          items={
            <>
              <FeatureWithIcon
                Icon={Layer}
                iconGradient
                heading="Cloud Resources"
                text="Northflank minimises infrastructure sprawl and right-sizes your cloud resources so you only pay for what you need."
              />
              <FeatureWithIcon
                Icon={Cog}
                iconGradient
                heading="Cloud Management"
                text="Northflank streamlines all of your infrastructure management, so your engineers can spend less time writing YAML, and more time delivering business value."
              />
              <FeatureWithIcon
                Icon={DollarCircle}
                iconGradient
                heading="Existing Credits & Commitments"
                text="Make use of existing cloud credits, commitments, and discounts to get the best value for money."
              />
            </>
          }
        />
        <FeatureGroup
          heading="Security"
          singleItem={
            <MasonryCard
              header={
                <TestimonialHeader
                  name="Bartlomiej Baran"
                  position="Co-Founder & CTO @ Blynksolve"
                  avatar="/images/landing/quotes/bart.jpg"
                  linkedin="https://linkedin.com/in/bartlomiej-baran"
                />
              }
              text="“In the strict industry of pharmaceutical manufacturing, precision, accuracy, and compliance are non-negotiable. Northflank has empowered our team to maintain complete accountability for every aspect of our software development and deployment, providing us with clear change management, release control and traceability features. The additional flexibility of the platform to make use of Compliant Cloud via BYOC and optional on-prem deployment means that we can meet our customers wherever they may need us.”"
              cols={null}
              rows={null}
              mb={13}
            />
          }
          items={
            <>
              <FeatureWithIcon
                Icon={World}
                iconGradient
                heading="Data Residency"
                text="With BYOC, both the runtime environment and dataplane remain within your cloud boundary – so all of your sensitive resources remain under your control. Make regulatory compliance simple for standards like HIPAA, SOC 2, and ISO 27001."
              />
              <FeatureWithIcon
                Icon={ShapeSquare}
                iconGradient
                heading="Data Sovereignty"
                text="BYOC gives you complete control over your regional infrastructure - making it easy to keep your data within legislative borders and keep your customers’ sensitive data safe and secure."
              />
              <FeatureWithIcon
                Icon={Group}
                iconGradient
                heading="Multi-tenancy"
                text="Unlock multi-tenancy in minutes, not months. Enable Northflank’s secure multi-tenant runtime inside your own Kubernetes clusters, and let us handle the rest. Northflank provides: a sandboxed runtime, secure network policies, service mesh and mTLS, namespace isolation, secret injection, disaster recovery, and more."
              />
            </>
          }
        />
      </FeaturesWrapper>
    </>
  );
};
export default Page;
