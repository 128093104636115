import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { X } from '@styled-icons/boxicons-regular/X';
import css from '@styled-system/css';
import styled from 'styled-components';
import { variant } from 'styled-system';

import Box from '@northflank/components/Box';

// .BodyStyle used to apply same style inside other components

const Body = styled(Box)(
  ({ fontSize = '19px' }) =>
    css({
      fontSize,

      '.BodyStyle': {
        fontFamily: 'body',
        fontSize: 3,
        lineHeight: 1.4,
        fontWeight: 400,
        '& > :first-child, & > :first-child *': {
          mt: 0,
        },
      },

      '&, .BodyStyle': {
        '> * + *': {
          mt: 10,
        },
        'h1, h2, h3, h4, h5, h6': {
          color: 'grey1',
          fontFamily: 'body',
        },
        '* + h1, * + h2, * + h3, * + h4, * + h5, * + h6': {
          mt: 10,
        },
        'p, ol, ul': {
          color: 'grey2',
          wordBreak: 'break-word',
          lineHeight: '32px',
        },
        a: {
          color: 'primary',
          textDecoration: 'none',
          wordBreak: 'break-word',
        },
        'ol, ul': {
          ml: 9,
        },
        code: {
          bg: 'grey8',
          color: 'grey2',
          p: '1px 4px',
          fontFamily: 'monospace',
          lineHeight: 1.2,
          fontSize: 2,
          border: '1px solid',
          borderColor: 'grey6',
          borderRadius: 1,
        },
        pre: {
          bg: 'grey9',
          fontSize: 2,
          overflowX: 'auto',
          p: 2,
          border: '1px solid',
          borderColor: 'grey6',
          borderRadius: 2,
          lineHeight: 1.2,
          '> code': {
            background: 'none',
            color: 'grey2',
            p: 0,
            fontSize: 2,
            border: 0,
          },
        },
        blockquote: {
          borderLeft: '2px solid',
          borderColor: 'primary',
          pl: 9,
        },
        'p img, li img, .kg-image-card img': {
          borderRadius: '8px',
          mx: 'auto',
          display: 'block',
          maxWidth: '100%',
          width: '100%',
          height: 'auto',
          border: '2px solid',
          borderColor: 'grey6',
          cursor: 'pointer',
        },
        'p video': {
          borderRadius: '8px',
          overflow: 'hidden',
          fontSize: 0,
          border: '2px solid ',
          borderColor: 'grey6',
          width: ['100%', '100%', '100%', '100%', '100%', '125%'],
          marginLeft: [0, 0, 0, 0, 0, 'calc(((125% - 100%) / 2) * -1)'],
        },
        'p:has(> img)': {
          width: ['100%', '100%', '100%', '100%', '100%', '125%'],
          marginLeft: [0, 0, 0, 0, 0, 'calc(((125% - 100%) / 2) * -1)'],
        },
        hr: {
          border: 0,
          height: '1px',
          bg: 'grey6',
        },
        ol: {
          listStyle: 'none',
          counterReset: 'list-counter',
          '> li': {
            counterIncrement: 'list-counter',
            position: 'relative',
            ml: 7,
            '&::before': {
              content: 'counter(list-counter)',
              height: '21px',
              width: '21px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 2,
              bg: 'grey6',
              color: 'grey1',
              fontFamily: 'monospace',
              fontSize: 1,
              lineHeight: 1,
              position: 'absolute',
              top: '6px',
              left: '-32px',
            },
            '> * + *': {
              my: 8,
            },
          },
          'li + li': {
            mt: 3,
          },
          ul: {
            listStyle: 'none',
            '> li': {
              position: 'relative',
              '&::before': {
                content:
                  "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYigxMjIsIDEzNywgMTYxKTsiPjxwYXRoIGQ9Ik0xMC43MDcgMTcuNzA3IDE2LjQxNCAxMmwtNS43MDctNS43MDctMS40MTQgMS40MTRMMTMuNTg2IDEybC00LjI5MyA0LjI5M3oiPjwvcGF0aD48L3N2Zz4=')",
                width: '22px',
                height: '22px',
                position: 'absolute',
                top: '4px',
                left: '-26px',
              },
            },
          },
        },
        '.tableWrapper': {
          width: ['100%', '100%', '100%', '100%', '100%', '125%'],
          marginLeft: [0, 0, 0, 0, 0, 'calc(((125% - 100%) / 2) * -1)'],
          overflowX: 'auto',
        },
        table: {
          width: '100%',
          bg: 'grey11',
          fontSize: '17px',
          fontFamily: 'body',
          thead: {
            bg: 'grey9',
            'tr th': {
              fontFamily: 'body',
              fontWeight: '600',
              fontSize: 3,
              textAlign: 'left',
              lineHeight: 1.4,
            },
          },
        },
        'table, th, td': {
          border: '1px solid',
          borderColor: 'grey6',
          borderCollapse: 'collapse',
        },
        'th, td': {
          px: 7,
          py: 6,
          wordBreak: 'normal',
          code: {
            wordBreak: ['break-word', 'break-word', 'normal'],
          },
          maxWidth: ['min-content', 'min-content', 'none'],
        },
        figure: {
          'img, video': {
            borderRadius: '8px 8px 0 0',
          },
          figcaption: {
            border: '2px solid',
            borderColor: 'grey6',
            borderTop: 0,
            borderRadius: '0 0 8px 8px',
            color: 'grey3',
            fontSize: 3,
            fontStyle: 'italic',
            px: 6,
          },
        },
      },
    }),
  variant({
    variants: {
      sansSerif: css({
        fontFamily: 'body',
        lineHeight: 1.5,
        fontWeight: 400,
      }),
    },
  })
);

export const Lightbox = ({ image, alt, close, children }) => {
  useEffect(() => {
    const closeOnEsc = (e) => {
      if (e.key === 'Escape') close();
    };
    document.addEventListener('keydown', closeOnEsc);
    return () => {
      document.removeEventListener('keydown', closeOnEsc);
    };
  }, []);

  return (
    <Box
      bg="rgba(0, 0, 0, 0.85)"
      position="fixed"
      top={0}
      bottom={0}
      left={0}
      right={0}
      zIndex={13}
      onClick={close}
    >
      <Box
        as="button"
        onClick={close}
        position="fixed"
        top="20px"
        right="20px"
        color="white"
        bg="transparent"
        border={0}
        _css={{ cursor: 'pointer' }}
      >
        <X size={32} />
      </Box>
      {image && (
        <Box
          as="img"
          src={image}
          alt={alt}
          position="fixed"
          top="50%"
          left="50%"
          maxWidth="95vw"
          maxHeight="85vh"
          width="auto"
          height="auto"
          borderRadius="4px"
          zIndex={11}
          _css={{
            transform: 'translate(-50%, -50%)',
            boxShadow: '0 4px 24px 0 rgba(0, 0, 0, 0.24)',
          }}
          onClick={(e) => e.stopPropagation()}
        />
      )}
      {children && (
        <Box
          position="fixed"
          top="50%"
          left="50%"
          maxWidth="95vw"
          maxHeight="85vh"
          width="100%"
          height="auto"
          _css={{
            transform: 'translate(-50%, -50%)',
            boxShadow: '0 4px 24px 0 rgba(0, 0, 0, 0.24)',
            aspectRatio: '2 / 1',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

const MarkdownBody = ({ children, ...rest }) => {
  const bodyRef = useRef();
  const [expanded, setExpanded] = useState(null);

  const { pathname } = useLocation();

  useEffect(() => {
    const expandImage = ({ target: { src } }) => {
      setExpanded(src);
      document.querySelector('body').style.overflow = 'hidden';
    };

    const images = bodyRef.current
      ? bodyRef.current.querySelectorAll('img')
      : [];
    images.forEach((image) => {
      image.addEventListener('click', expandImage);
    });

    return () => {
      images.forEach((image) => {
        image.removeEventListener('click', expandImage);
      });
      setExpanded(null);
      document.querySelector('body').style.overflow = 'auto';
    };
  }, [children]);

  useEffect(() => {
    setExpanded(null);
    document.querySelector('body').style.overflow = 'auto';
  }, [pathname]);

  return (
    <>
      {expanded && (
        <Lightbox
          image={expanded}
          close={() => {
            setExpanded(null);
            document.querySelector('body').style.overflow = 'auto';
          }}
        />
      )}
      <Body ref={bodyRef} {...rest}>
        {children}
      </Body>
    </>
  );
};

export default MarkdownBody;
