import React, { useContext, useState } from 'react';

import Cal from '@calcom/embed-react';
import { CloudUpload } from '@styled-icons/boxicons-regular/CloudUpload';
import { Layer } from '@styled-icons/boxicons-regular/Layer';
import { LockAlt } from '@styled-icons/boxicons-regular/LockAlt';
import { SearchAlt } from '@styled-icons/boxicons-regular/SearchAlt';
import { UserCircle } from '@styled-icons/boxicons-regular/UserCircle';
import { Wrench } from '@styled-icons/boxicons-regular/Wrench';
import { Kubernetes } from '@styled-icons/simple-icons/Kubernetes';
import { Form, Formik } from 'formik';
import { ThemeContext } from 'styled-components';
import * as Yup from 'yup';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import { FormikInput } from '@northflank/components/FormikInput';
import Grid from '@northflank/components/Grid';
import Heading from '@northflank/components/Heading';
import Icon from '@northflank/components/Icon';
import { Lightbox } from '@northflank/components/marketing/MarkdownBody';
import SEO from '@northflank/components/marketing/SEO';
import { TestimonialHeader } from '@northflank/components/marketing/Testimonial';
import Text from '@northflank/components/Text';

import GetStartedCta from '../components/GetStartedCta';
import HeroHeading from '../components/HeroHeading';
import LogoCarousel from '../components/LogoCarousel';
import texture from '../components/Texture';
import topog from '../components/Topog';

export default function TLDR() {
  const [formData, setFormData] = useState();

  const theme = useContext(ThemeContext);

  return (
    <>
      <SEO
        title="Welcome, TLDR readers"
        description="Northflank is the fastest way to deploy and scale applications. Fully managed, in your own VPC."
      />

      <Box
        background={`radial-gradient(60% 100% at 70% 50%, rgba(0, 147, 255, 0.1) 0%, #151C28 100%),
        radial-gradient(circle at 70% 50%, rgba(0, 0, 0, 0) 0%, #151C28 100%),
         ${topog}`}
        borderBottom="1px solid"
        borderColor="grey5"
        mt="-80px"
        pt="80px"
      >
        <Box variant="bounding">
          <Grid
            gridTemplateColumns={['1fr', '1fr', '1fr 1.5fr']}
            alignItems="center"
            gridGap={[12, 12, 14]}
          >
            <Box>
              <Heading
                as="h1"
                fontSize={[6, 6, 7]}
                color="grey1"
                mb={8}
                style={{ letterSpacing: 'normal', lineHeight: 1.2 }}
              >
                👋 Welcome, <br />
                <Box as="span" color="#32AFFF">
                  T
                </Box>
                <Box as="span" color="#E9C15F">
                  L
                </Box>
                <Box as="span" color="#68C1AD">
                  D
                </Box>
                <Box as="span" color="#E36968">
                  R
                </Box>{' '}
                readers.
              </Heading>
              <Text fontSize={[4, 4, 5]} color="grey2" mb={7}>
                Northflank is the fastest way to deploy and scale applications.
                Fully managed, in your own VPC.
              </Text>
              <Text fontSize={[4, 4, 5]} color="grey2" mb={[11, 11, 12]}>
                Grab some time with us and we’ll show you how it works.
              </Text>
              <Formik
                initialValues={{ name: '', email: '' }}
                validationSchema={Yup.object({
                  name: Yup.string().required('Please enter your name'),
                  email: Yup.string()
                    .email('Please enter a valid email address')
                    .required('Please enter your email address'),
                })}
                onSubmit={async (values) => {
                  await fetch('/api/cio-form-submit', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      formId: 'tldr-book-demo',
                      data: {
                        name: values.name,
                        email: values.email,
                      },
                    }),
                  });

                  setFormData(values);
                }}
              >
                <Box as={Form}>
                  <Grid
                    gridTemplateColumns={[
                      '1fr',
                      '1fr',
                      '1fr',
                      'repeat(2, 1fr)',
                    ]}
                    gridGap={6}
                    alignItems="start"
                    mb={8}
                  >
                    <FormikInput
                      name="name"
                      label="Name"
                      placeholder="Your Name"
                      formikRequired
                    />
                    <FormikInput
                      type="email"
                      name="email"
                      label="Work email"
                      placeholder="name@example.com"
                      formikRequired
                    />
                  </Grid>
                  <Button variant={['primary', 'large']} width="100%">
                    Choose a time &rarr;
                  </Button>
                </Box>
              </Formik>
              {formData && (
                <Lightbox
                  close={() => {
                    setFormData(null);
                  }}
                >
                  <Cal
                    calLink="team/northflank/northflank-intro"
                    config={{
                      name: formData.name,
                      email: formData.email,
                    }}
                    style={{ maxHeight: '85vh', overflowY: 'auto' }}
                  />
                </Lightbox>
              )}
            </Box>
            <Box
              width="100%"
              bg="grey11"
              border="1px solid"
              borderColor="grey5"
              borderRadius={3}
              boxShadow="dropDown"
              overflow="hidden"
              _css={{ aspectRatio: '16 / 9.6' }}
            >
              <Box
                as="video"
                muted
                autoPlay
                playsInline
                loop
                preload
                width="100%"
              >
                <source
                  src="https://assets.northflank.com/marketing/video/tldr-hero-3.mp4"
                  type="video/mp4"
                />
              </Box>
            </Box>
          </Grid>
        </Box>
      </Box>

      <Box variant="bounding" maxWidth="100% !important">
        <Heading fontSize={[5, 5, '32px']} textAlign="center" mb={12}>
          <HeroHeading
            as="span"
            fontSize={[5, 5, '32px']}
            fontWeight="600 !important"
            letterSpacing="normal"
          >
            For teams who don’t check PagerDuty in bed
          </HeroHeading>{' '}
          <br />
          <Text
            as="span"
            fontSize={[3, 3, 4]}
            fontFamily="monospace"
            color="grey3"
          >
            (because Northflank is their infra)
          </Text>
        </Heading>
        <LogoCarousel noBorder />
      </Box>

      <Box variant="bounding">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            as="img"
            src="/images/landing/logos/sentry.svg"
            alt="Sentry logo"
            width="180px"
            mb={12}
          />
          <Text
            fontSize={[5, 5, 6]}
            fontFamily="serif"
            textAlign="center"
            mb={12}
          >
            <Text
              as="span"
              fontSize="60px"
              lineHeight={0}
              px={3}
              verticalAlign="middle"
            >
              “
            </Text>
            Northflank is way easier than gluing a bunch of tools together to
            spin up apps and databases. It’s the ideal platform to deploy
            containers in our cloud account, avoiding the brain damage of big
            cloud and Kubernetes. It’s more powerful and flexible than
            traditional PaaS – all within our VPC.{' '}
            <Text as="span" color="success" fontWeight={500}>
              Northflank has become a go-to way to deploy workloads at Sentry
            </Text>
            .
            <Text
              as="span"
              fontSize="60px"
              lineHeight={0}
              px={3}
              verticalAlign="middle"
            >
              ”
            </Text>
          </Text>
          <TestimonialHeader
            name="David Cramer"
            position="Co-Founder and CPO @ Sentry"
            avatar="/images/landing/quotes/david-c.jpeg"
            linkedin="https://www.linkedin.com/in/dmcramer/"
            mb={0}
          />
        </Box>
      </Box>

      <Box variant="bounding">
        <Heading fontSize={[5, 5, '32px']} textAlign="center" mb={12}>
          The{' '}
          <HeroHeading
            as="em"
            fontSize={[5, 5, '32px']}
            fontWeight="600 !important"
            fontFamily="serif"
            letterSpacing="normal"
          >
            tl;dr
          </HeroHeading>{' '}
          on Northflank
        </Heading>
        <Box
          background={`${texture}, radial-gradient(circle at 30% 20%, ${theme.colors.grey9} 10%, ${theme.colors.grey11} 70%)`}
          border="1px solid"
          borderColor="grey5"
          borderRadius={3}
          fontSize={[3, 3, 4]}
          width={[1, 1, '700px']}
          mx="auto"
          p={12}
          boxShadow="dropDown"
        >
          <Text mb={6}>
            DevOps teams no longer need to build a platform from scratch.
          </Text>
          <Text mb={10}>
            Northflank replaces all the tools you duct tape together. From push
            to release, all the way to galactic scale.
          </Text>
          <Box as="ul" _css={{ listStyle: 'none', '> li + li': { mt: 7 } }}>
            <Box as="li" variant="flex" alignItems="flex-start">
              <Box mt={1} mr={6}>
                <Icon Content={Layer} Size={24} color="grey3" />
              </Box>
              <Text>
                Deploy and scale{' '}
                <HeroHeading
                  as="em"
                  fontSize={[4, 4, 5]}
                  fontFamily="serif"
                  letterSpacing="normal"
                >
                  any workload
                </HeroHeading>
                —including services, apps, GPUs, databases, and scheduled jobs
              </Text>
            </Box>
            <Box as="li" variant="flex" alignItems="flex-start">
              <Box mt={1} mr={6}>
                <Icon Content={Wrench} Size={24} color="grey3" />
              </Box>
              <Text>
                Built-in{' '}
                <HeroHeading
                  as="em"
                  fontSize={[4, 4, 5]}
                  fontFamily="serif"
                  letterSpacing="normal"
                >
                  CI/CD
                </HeroHeading>{' '}
                pipelines with powerful releases and rollbacks
              </Text>
            </Box>
            <Box as="li" variant="flex" alignItems="flex-start">
              <Box mt={1} mr={6}>
                <Icon Content={Kubernetes} Size={24} color="grey3" />
              </Box>
              <Text>
                Run workloads on{' '}
                <HeroHeading
                  as="em"
                  fontSize={[4, 4, 5]}
                  fontFamily="serif"
                  letterSpacing="normal"
                >
                  your cloud of choice
                </HeroHeading>{' '}
                (AWS, GCP, Azure, metal)
              </Text>
            </Box>
            <Box as="li" variant="flex" alignItems="flex-start">
              <Box mt={1} mr={6}>
                <Icon Content={CloudUpload} Size={24} color="grey3" />
              </Box>
              <Text>
                Full environment orchestration, from{' '}
                <HeroHeading
                  as="em"
                  fontSize={[4, 4, 5]}
                  fontFamily="serif"
                  letterSpacing="normal"
                >
                  preview to production
                </HeroHeading>
              </Text>
            </Box>
            <Box as="li" variant="flex" alignItems="flex-start">
              <Box mt={1} mr={6}>
                <Icon Content={SearchAlt} Size={24} color="grey3" />
              </Box>
              <Text>
                Out-of-the-box{' '}
                <HeroHeading
                  as="em"
                  fontSize={[4, 4, 5]}
                  fontFamily="serif"
                  letterSpacing="normal"
                >
                  observability
                </HeroHeading>{' '}
                with logs, metrics, and autoscaling
              </Text>
            </Box>
            <Box as="li" variant="flex" alignItems="flex-start">
              <Box mt={1} mr={6}>
                <Icon Content={LockAlt} Size={24} color="grey3" />
              </Box>
              <Text>
                Secrets management, multi-tenancy, fine-grained RBAC, and audit
                logs{' '}
                <HeroHeading
                  as="em"
                  fontSize={[4, 4, 5]}
                  fontFamily="serif"
                  letterSpacing="normal"
                >
                  for enterprise
                </HeroHeading>
              </Text>
            </Box>
            <Box as="li" variant="flex" alignItems="flex-start">
              <Box mt={1} mr={6}>
                <Icon Content={UserCircle} Size={24} color="grey3" />
              </Box>
              <Text>
                First class developer experience: real-time{' '}
                <HeroHeading
                  as="em"
                  fontSize={[4, 4, 5]}
                  fontFamily="serif"
                  letterSpacing="normal"
                >
                  UI, CLI, API, GitOps
                </HeroHeading>{' '}
                and reusable templates
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box variant="bounding">
        <Heading
          fontSize={[5, 5, '32px']}
          textAlign="center"
          width={[1, 1, 1 / 2]}
          mx="auto"
          mb={12}
        >
          Northflank is what your internal platform team would build, <br />
          <HeroHeading
            as="span"
            fontSize={[5, 5, '32px']}
            fontWeight="600 !important"
            letterSpacing="normal"
          >
            without the overhead
          </HeroHeading>
        </Heading>
        <Box
          background={`linear-gradient(to right, ${theme.colors.background} 2%, transparent 12%, transparent 88%, ${theme.colors.background} 98%), url('/images/devops-path.svg')`}
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          backgroundPositition="center"
          _css={{
            aspectRatio: '21 / 9',
          }}
        />
      </Box>

      <Box variant="bounding">
        <GetStartedCta
          title="Not the chatty type? Northflank is fully self-service."
          fontSize="32px"
          secondaryButton={<></>}
        />
      </Box>
    </>
  );
}
