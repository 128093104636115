import React from 'react';

import { LinkExternal } from '@styled-icons/boxicons-regular/LinkExternal';

import Box from '@northflank/components/Box';
import Icon from '@northflank/components/Icon';
import Text from '@northflank/components/Text';

const CloudProviderTableNode = ({ providerId, value, original }) => (
  <Box
    as="a"
    href={`/cloud/${providerId}/instances/${value}`}
    color="grey1"
    _css={{ '&:hover': { color: 'primary' } }}
  >
    <Box variant="flex" alignItems="center">
      <Text>{original.name}</Text>
      <Icon Content={LinkExternal} Size={12} color="grey3" ml={3} />
    </Box>
    {value !== original.name && (
      <Text fontSize={0} color="grey2">
        {value}
      </Text>
    )}
  </Box>
);

export default CloudProviderTableNode;
