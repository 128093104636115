import React from 'react';

import { getIn, useFormikContext } from 'formik';

import PlanSelect from '@northflank/components/Billing/PlanSelect';
import Box from '@northflank/components/Box';
import FieldLabel from '@northflank/components/FieldLabel';
import { resourcePriceObject } from '@northflank/components/marketing/Pricing/data';
import NumberSelector from '@northflank/components/NumberSelector';
import SelectCardList from '@northflank/components/SelectCardList';
import { byocResourcePriceObject } from '@northflank/pricing';

import StorageSelector from './components/StorageSelector';

const addonColours = {
  redis: '#dc382d',
  mysql: '#00758f',
  minio: '#c72c48',
  mongodb: '#449845',
  rabbitmq: '#ff6600',
  postgresql: '#fff',
};

const Addon = ({ resource, formikIndex, plans, addonTypes = [] }) => {
  const { values, setFieldValue } = useFormikContext();

  const addonType = addonTypes.find(
    (type) => type.type === getIn(values, `resources.${formikIndex}.kind`)
  );

  return (
    <Box>
      <SelectCardList
        title="Type"
        cards={addonTypes.map((o) => ({
          key: o.type,
          title: o.name,
          image: o.image,
          color: addonColours[o.type],
          label: Array.isArray(o.labels) ? o.labels[0] : undefined,
          small: true,
        }))}
        handleSelect={(o) => {
          setFieldValue(`resources.${formikIndex}.kind`, o);
        }}
        selected={values.resources[formikIndex].kind}
        gridTemplateColumns={['1fr', '1fr', 'repeat(3, 1fr)']}
        gridGap={6}
        highlightIcon
        mb={7}
      />
      {resource.kind && (
        <>
          <PlanSelect
            label="Plan"
            name={`resources.${formikIndex}.plan`}
            plans={plans}
            onDemand={false}
            northflankResourcesPrices={
              resource.isByoc ? byocResourcePriceObject : resourcePriceObject
            }
            isBuildService={resource.kind === 'build'}
            useId
            useInternalId
            mt={7}
          />
          <Box mt={7}>
            <FieldLabel mb={6}>Replicas</FieldLabel>
            <NumberSelector
              label="Replicas"
              options={addonType.resources.replicas.options}
              selected={getIn(values, `resources.${formikIndex}.replicas`)}
              handleSelect={(val) =>
                setFieldValue(`resources.${formikIndex}.replicas`, val)
              }
              min={addonType.resources.replicas.minDisplayValue}
              max={addonType.resources.replicas.maxDisplayValue}
            />
          </Box>
          <Box mt={7}>
            <StorageSelector formikIndex={formikIndex} addonType={addonType} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default Addon;
