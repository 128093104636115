import css from '@styled-system/css';
import styled from 'styled-components';

const FieldLabel = styled.div(
  ({
    color,
    fontSize,
    fontWeight = 400,
    lineHeight,
    mb,
    mt,
    ml,
    mr,
    my,
    mx,
    pt,
    pb,
    pl,
    pr,
    px,
    py,
    display = 'block',
  }) =>
    css({
      fontFamily: 'monospace',
      color: color || 'grey3',
      fontSize: fontSize || 1,
      fontWeight,
      lineHeight,
      mb,
      mt,
      ml,
      mr,
      my,
      mx,
      pt,
      pb,
      pl,
      pr,
      px,
      py,
      display,
    })
);

export default FieldLabel;
