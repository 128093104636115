import React, { startTransition } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import unescape from 'lodash/unescape';

import App from './App';

const parseWindowData = (data) =>
  data
    ? JSON.parse(data, (k, v) => (typeof v === 'string' ? unescape(v) : v))
    : undefined;

const hydrate = () => {
  hydrateRoot(
    document.getElementById('root'),
    <BrowserRouter>
      <HelmetProvider>
        <App
          pathname={window.location.pathname}
          search={window.location.search}
          ssrData={parseWindowData(window._SSR_DATA)}
          error={!!window._SSR_ERROR}
          clientEnvs={parseWindowData(window._CLIENT_ENVS)}
        />
      </HelmetProvider>
    </BrowserRouter>
  );
};

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', hydrate);
} else {
  startTransition(hydrate);
}
