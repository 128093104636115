import React, { useEffect, useRef, useState } from 'react';

import Box from '@northflank/components/Box';
import Grid from '@northflank/components/Grid';
import Heading from '@northflank/components/Heading';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';

import { BlogCard, ResourcesNav } from '../blog';

const Changelog = ({ posts = [], total }) => {
  const [loadedPosts, setLoadedPosts] = useState(posts);
  const loadMoreRef = useRef();

  useEffect(() => {
    if (loadMoreRef.current) {
      const options = {
        rootMargin: '0px 0px 500px 0px',
        threshold: 1.0,
      };
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(({ isIntersecting }) => {
          if (isIntersecting && loadedPosts.length < total) {
            fetchNextPage(loadedPosts.length);
          }
        });
      }, options);
      observer.observe(loadMoreRef.current);

      return () => {
        if (loadMoreRef.current) observer.unobserve(loadMoreRef.current);
      };
    }
  }, [loadMoreRef, loadedPosts?.length]);

  const fetchNextPage = async (start) => {
    const res = await fetch(`/api/changelog/${start}`);
    const newPosts = await res.json();
    let existing = [...loadedPosts];
    if (newPosts?.changelogs?.data.length) {
      existing = existing.concat(newPosts.changelogs.data);
      setLoadedPosts(existing);
    }
  };

  const [featuredPost, ...remainingPosts] = loadedPosts;

  return (
    <>
      <SEO
        title="Changelog"
        description="Follow Northflank’s development with regular updates from the Northflank team."
      />
      <Box variant="bounding">
        <Box mb={13}>
          <ResourcesNav />
          <Heading as="h1" fontSize={[6, 6, 7]} color="grey1" mb={8}>
            Changelog
          </Heading>
          <Text color="grey3">
            <Text
              as="a"
              href="https://x.com/northflank"
              color="grey3"
              css={{ '&:hover': { color: '#C6D3E2' } }}
            >
              Follow us on X
            </Text>{' '}
            /{' '}
            <Text
              as="a"
              href="/blog/rss"
              color="grey3"
              css={{ '&:hover': { color: '#C6D3E2' } }}
            >
              Subscribe to RSS feed
            </Text>
          </Text>
        </Box>
        {!loadedPosts.length && (
          <Text fontSize={4} color="grey3">
            There is nothing here yet.
          </Text>
        )}
        {featuredPost && (
          <BlogCard
            key={featuredPost.id}
            image={featuredPost.attributes.header?.data?.attributes.url}
            published={featuredPost.attributes.publication_date}
            title={featuredPost.attributes.title}
            excerpt={featuredPost.attributes.excerpt}
            link={`/changelog/${featuredPost.attributes.slug}`}
            wide
            mb="50px"
          />
        )}
        <Grid
          gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
          gridGap="50px"
          alignItems="start"
        >
          {remainingPosts?.map((post) => (
            <BlogCard
              key={post.id}
              image={post.attributes.header?.data?.attributes.url}
              published={post.attributes.publication_date}
              title={post.attributes.title}
              excerpt={post.attributes.excerpt}
              link={`/changelog/${post.attributes.slug}`}
            />
          ))}
        </Grid>
        {loadedPosts.length < total && <div ref={loadMoreRef} />}
      </Box>
    </>
  );
};

export default Changelog;
