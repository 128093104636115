import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { BookOpen } from '@styled-icons/boxicons-regular/BookOpen';
import { Broadcast } from '@styled-icons/boxicons-regular/Broadcast';
import { ChevronDown } from '@styled-icons/boxicons-regular/ChevronDown';
import { Cloud } from '@styled-icons/boxicons-regular/Cloud';
import { Collection } from '@styled-icons/boxicons-regular/Collection';
import { Cookie } from '@styled-icons/boxicons-regular/Cookie';
import { File } from '@styled-icons/boxicons-regular/File';
import { Group } from '@styled-icons/boxicons-regular/Group';
import { LockAlt } from '@styled-icons/boxicons-regular/LockAlt';
import { Menu } from '@styled-icons/boxicons-regular/Menu';
import { News } from '@styled-icons/boxicons-regular/News';
import { Search } from '@styled-icons/boxicons-regular/Search';
import { X } from '@styled-icons/boxicons-regular/X';
import css from '@styled-system/css';
import slugify from 'slugify';
import styled, { keyframes } from 'styled-components';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Grid from '@northflank/components/Grid';
import Icon from '@northflank/components/Icon';
import Input from '@northflank/components/Input';
import {
  pages as featurePages,
  solutionPages,
} from '@northflank/components/marketing/FeatureNav';
import { composedPathPolyfill } from '@northflank/components/Modal';
import NorthflankIcon from '@northflank/components/NorthflankIcon';
import Text from '@northflank/components/Text';
import { capitalize } from '@northflank/constants-base';

import NFLogo from '../../../images/svg/Logo.svg';
import NFLogoType from '../../../images/svg/LogoType.svg';

export const LogoType = styled(NFLogoType)`
  height: 26px;
  margin-top: 2px;
`;

const Logo = styled(NFLogo)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 22px;
`;

export const HiddenText = styled.p(() =>
  css({
    width: '0px !important',
    height: '0px !important',
    overflow: 'hidden',
    opacity: '0 !important',
  })
);

const StyledNavLink = styled.span(({ href, asPath, mobile, new: isNew }) => {
  const active = asPath.startsWith(href) && href !== '/';
  return css({
    color: active ? 'grey0' : 'grey2',
    fontWeight: active ? 500 : 400,
    whiteSpace: 'nowrap',
    ml: mobile ? 0 : 10,
    display: 'block',
    '&:hover': {
      color: 'grey0',
      'span:first-of-type': {
        color: 'grey0',
      },
    },
    '> p': {
      position: 'relative',
    },
    '> p::after': isNew && {
      content: '""',
      height: '6px',
      width: '6px',
      bg: 'primary',
      borderRadius: 5,
      display: 'block',
      position: 'absolute',
      right: '-8px',
      top: '2px',
    },
  });
});

const NavLink = ({ children, textProps, ...rest }) => (
  <StyledNavLink {...rest}>
    <Text display="inline-block" {...textProps}>
      {children}
    </Text>
  </StyledNavLink>
);

const AnimatedChevron = styled(Icon)(({ open }) =>
  css({
    transform: `rotate(${open ? -180 : 0}deg)`,
    transition: '100ms',
    pointerEvents: 'none',
  })
);

const popIn = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, 5px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0px);
  }
`;

const popOut = keyframes`
  from {
    opacity: 1;
    transform: translate(-50%, 0px);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -5px);
  }
`;

const NavPopup = styled(Box)(() =>
  css({
    display: ['none', 'none', 'block'],
    position: 'absolute',
    left: '60px',
    top: 0,
    pt: 8,
    zIndex: -1,
  })
);

const AnimatedNavPopup = styled(NavPopup)`
  animation: ${({ out }) => (out ? popOut : popIn)} 200ms forwards;
`;

const NavPopupBody = styled(Box)(() =>
  css({
    bg: 'grey9',
    border: '1px solid',
    borderColor: 'grey5',
    borderRadius: 2,
    boxShadow: 'dropDown',
    p: 6,
    position: 'relative',

    '&::before': {
      content: '""',
      display: 'block',
      bg: 'grey9',
      width: '16px',
      height: '16px',
      position: 'absolute',
      top: '-8px',
      left: 'calc(50% - 8px)',
      border: '1px solid',
      borderColor: 'grey5',
      borderRadius: '4px 0 0 0',
      transform: 'rotate(45deg)',
      clipPath: 'polygon(100% 0, 0 0, 0 100%)',
      boxShadow: 'dropDown',
    },
  })
);

const NavPopupSection = styled.ul(({ columns }) =>
  css({
    listStyle: 'none',
    display: columns > 1 ? 'grid' : 'block',
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
  })
);

const items = [
  // {
  //   label: 'GPUs',
  //   path: '/gpu',
  //   new: true,
  // },
  {
    label: 'Platform',
    path: '/features',
    noLink: true,
    subitems: featurePages,
    subitemsCols: 2,
  },
  {
    label: 'Use cases',
    path: '/use-cases',
    noLink: true,
    subitems: solutionPages,
    subitemsCols: 2,
  },
  {
    label: 'Resources',
    path: '/resources',
    noLink: true,
    new: true,
    subitems: [
      {
        title: 'Product',
        items: [
          {
            label: 'Documentation',
            path: 'https://northflank.com/docs',
            icon: BookOpen,
            description: 'Comprehensive platform & API docs',
            external: true,
          },
          {
            label: 'Guides',
            path: '/guides',
            icon: File,
            description: 'Get deploying in minutes',
          },
          {
            label: 'Cloud providers',
            path: '/cloud',
            icon: Cloud,
            description: 'Explore supported cloud integrations',
          },
          {
            label: 'Stack templates',
            path: '/stacks',
            icon: Collection,
            description: 'Deploy your favourite apps in a click',
          },
          {
            label: 'Status',
            path: 'https://status.northflank.com',
            icon: Broadcast,
            description: 'Check platform uptime & issues',
            external: true,
          },
          {
            label: 'Security',
            path: '/security',
            icon: LockAlt,
            description: 'Learn about our security measures',
          },
        ],
      },
      {
        title: 'Company',
        items: [
          {
            label: 'Changelog',
            path: '/changelog',
            icon: News,
            description: 'Follow recent product updates',
          },
          {
            label: 'Blog',
            path: '/blog',
            icon: News,
            description: 'Company news and insights',
          },
          {
            label: 'About',
            path: '/about',
            icon: NorthflankIcon,
            description: 'Get to know our mission',
          },
          {
            label: 'Careers',
            path: '/careers',
            icon: Group,
            description: 'Join our growing team',
          },
        ],
      },
    ],
    subitemsCols: 2,
  },
  // {
  //   label: 'Changelog',
  //   path: '/changelog',
  //   new: true,
  // },
  // {
  //   label: 'Enterprise',
  //   path: '/enterprise',
  // },
  {
    label: 'Pricing',
    path: '/pricing',
  },
];

export const LinkOrAnchor = ({ external, children, href, ...rest }) =>
  external ? (
    <a href={href} target="_blank" rel="noreferrer" {...rest}>
      {children}
    </a>
  ) : (
    <Link to={href} {...rest}>
      {children}
    </Link>
  );

const Nav = ({ onGuides, onStacks }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const [expandedItem, setExpandedItem] = useState(null);
  const [expandedItemCursor, setExpandedItemCursor] = useState({
    item: null,
    menu: null,
  });
  const [expandedItemAnimateOut, setExpandedItemAnimateOut] = useState(false);

  const expandedItemMouseLeaveTimeoutRef = useRef();
  const expandedItemAnimateOutTimeoutRef = useRef();

  // Hidden by default to stop notice popping in and out when the user
  // has already dismissed it
  const [hideCookieNotice, setHideCookieNotice] = useState(true);

  const { pathname, hash } = useLocation();
  const history = useHistory();

  const [navScrolled, setNavScrolled] = useState(!!hash);

  useEffect(() => {
    composedPathPolyfill();

    const match = window.matchMedia('(max-width: 819px)');
    const query = (m) => {
      setIsMobile(m.matches);
    };
    query(match);
    match.addListener(query);

    const hide = window.localStorage.getItem('hideCookieNotice');
    if (hide !== 'true') {
      setHideCookieNotice(false);
    }

    const handleScroll = () => {
      setNavScrolled(window.scrollY > 0);
    };
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!expandedItemCursor.item && !expandedItemCursor.menu) {
      setExpandedItemAnimateOut(true);
      expandedItemAnimateOutTimeoutRef.current = setTimeout(() => {
        setExpandedItem(null);
        setExpandedItemAnimateOut(false);
      }, 200);
    } else if (expandedItemCursor.item !== expandedItemCursor.menu) {
      setExpandedItemAnimateOut(false);
      clearTimeout(expandedItemMouseLeaveTimeoutRef.current);
      clearTimeout(expandedItemAnimateOutTimeoutRef.current);
    }
  }, [expandedItemCursor.item, expandedItemCursor.menu]);

  useEffect(() => {
    const handleMouseMove = (e) => {
      const eventPath = e.path || (e.composedPath && e.composedPath());
      const movedOutsideHeader = !eventPath.some(
        (el) => el.tagName === 'HEADER'
      );
      if (movedOutsideHeader) {
        setExpandedItemCursor({ item: null, menu: null });
      }
    };

    if (expandedItem) document.addEventListener('mousemove', handleMouseMove);
    else document.removeEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [expandedItem]);

  useEffect(() => {
    setExpandedItemCursor({ item: null, menu: null });
  }, [pathname]);

  useEffect(() => {
    if (hash) setNavScrolled(true);
  }, [hash]);

  const hide = (e) => {
    setHideCookieNotice(true);
    e.preventDefault();
    window.localStorage.setItem('hideCookieNotice', 'true');
  };

  const section = onGuides ? 'guides' : onStacks ? 'stacks' : undefined;
  const navItems = items; // = section ? guideItems : items;

  const handleGuidesSearch = (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    const query = form.get('query');
    if (query) history.push(`/${section}?search=${query}`);
  };

  return (
    <>
      <Box
        as="header"
        width="100%"
        bg={[
          'background',
          'background',
          navScrolled ? 'background' : 'transparent',
        ]}
        position={menuOpen ? ['fixed', 'fixed', 'sticky'] : 'sticky'}
        top={0}
        zIndex={12}
        boxShadow={
          menuOpen
            ? 'none'
            : [
                'dropDown',
                'dropDown',
                navScrolled && !pathname.startsWith('/features')
                  ? 'dropDown'
                  : 'none',
              ]
        }
        borderBottom={[
          '1px solid',
          '1px solid',
          navScrolled ? '1px solid' : 'none',
        ]}
        borderColor={
          pathname.startsWith('/features')
            ? ['grey5', 'grey5', 'grey5']
            : ['grey6', 'grey6', 'grey6']
        }
        _css={{
          transition: '200ms',
        }}
      >
        <Box
          variant="flex"
          alignItems="center"
          justifyContent="space-between"
          maxWidth="max"
          height={['64px', '64px', '80px']}
          mx="auto"
          px={[10, 10, 12]}
        >
          <Button
            variant={['noBackground', 'noBorder', 'icon']}
            display={['flex', 'flex', 'none']}
            minWidth="32px"
            minHeight="32px"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <Icon Content={menuOpen ? X : Menu} Size={24} />
          </Button>
          <Box variant="flex" alignItems="center">
            <Link to="/">
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div onClick={() => setMenuOpen(false)}>
                {isMobile ? <Logo /> : <LogoType />}
                <HiddenText>Northflank</HiddenText>
              </div>
            </Link>
            {!!section && !isMobile && (
              <>
                <Link to={`/${section}`}>
                  <Box
                    bg="grey6"
                    borderRadius="20px"
                    boxShadow="dropDown"
                    px={7}
                    py={4}
                    ml={7}
                  >
                    <Text
                      color="primary"
                      fontSize={3}
                      fontWeight={600}
                      lineHeight={1}
                    >
                      {capitalize(section)}
                    </Text>
                  </Box>
                </Link>
                {pathname !== `/${section}` &&
                  pathname !== `/${section}/tag/[tag]` &&
                  pathname !== `/${section}/category/[category]` && (
                    <Box
                      borderLeft="1px solid"
                      borderLeftColor="grey5"
                      pl={8}
                      ml={8}
                    >
                      <form onSubmit={handleGuidesSearch}>
                        <Input
                          name="query"
                          placeholder={`Search ${section}`}
                          width="200px"
                          minHeight="28px"
                          height="28px"
                          internalButton={
                            <Button
                              type="submit"
                              variant={['icon', 'noBorder', 'noBackground']}
                              minHeight="18px"
                              minWidth="18px"
                              aria-label="Search"
                            >
                              <Search size={14} />
                            </Button>
                          }
                        />
                      </form>
                    </Box>
                  )}
              </>
            )}
          </Box>
          <Box
            variant="flex"
            alignItems="center"
            display={['none!important', 'none!important', 'flex!important']}
          >
            {navItems.map((item) => (
              <Box
                key={item.path}
                position="relative"
                display={
                  item.hideSmall ? ['none', 'none', 'none', 'block'] : 'block'
                }
              >
                {item.external ? (
                  <a href={item.path} target="_blank" rel="noreferrer">
                    <NavLink to={item.path} asPath={pathname} new={item.new}>
                      {item.label}
                    </NavLink>
                  </a>
                ) : (
                  <Box
                    variant="flex"
                    alignItems="center"
                    onMouseEnter={() => {
                      if (item.subitems) {
                        setExpandedItemCursor((value) => {
                          const v = { ...value };
                          v.item = item.path;
                          return v;
                        });
                        setExpandedItem(item.path);
                      }
                    }}
                    onMouseLeave={() => {
                      if (item.subitems) {
                        expandedItemMouseLeaveTimeoutRef.current = setTimeout(
                          () => {
                            setExpandedItemCursor((value) => {
                              const v = { ...value };
                              v.item = null;
                              return v;
                            });
                          },
                          100
                        );
                      }
                    }}
                  >
                    {item.path && !item.noLink ? (
                      <Link to={item.path}>
                        <NavLink
                          id={`nav-${slugify(item.label, { lower: true })}`}
                          asPath={pathname}
                          new={item.new}
                        >
                          {item.label}
                        </NavLink>
                      </Link>
                    ) : (
                      <StyledNavLink
                        as={Text}
                        asPath={pathname}
                        to={item.path}
                        ml={10}
                        style={{ cursor: 'pointer' }}
                      >
                        {item.label}
                      </StyledNavLink>
                    )}
                    {item.subitems && (
                      <AnimatedChevron
                        open={expandedItem === item.path}
                        Content={ChevronDown}
                        Size={16}
                        color="grey2"
                        ml={2}
                      />
                    )}
                  </Box>
                )}
                {item.subitems && expandedItem === item.path && (
                  <AnimatedNavPopup
                    onMouseEnter={() => {
                      setExpandedItemCursor((value) => {
                        const v = { ...value };
                        v.menu = item.path;
                        return v;
                      });
                      setExpandedItem(item.path);
                    }}
                    onMouseLeave={() => {
                      expandedItemMouseLeaveTimeoutRef.current = setTimeout(
                        () => {
                          setExpandedItemCursor((value) => {
                            const v = { ...value };
                            v.menu = null;
                            return v;
                          });
                        },
                        100
                      );
                    }}
                    out={expandedItemAnimateOut}
                  >
                    <Box height="24px" />
                    <NavPopupBody>
                      {item.subitems.map((subitem) => (
                        <Box
                          key={subitem.title}
                          _css={{
                            '&:not(:first-child)': {
                              mt: 6,
                            },
                          }}
                        >
                          {subitem.title && (
                            <Box
                              bg="grey8"
                              borderRadius={2}
                              px={8}
                              py={6}
                              mb={6}
                            >
                              <Text
                                fontSize={1}
                                fontWeight={600}
                                css={{ textTransform: 'uppercase' }}
                              >
                                {subitem.title}
                              </Text>
                            </Box>
                          )}
                          <NavPopupSection
                            columns={subitem.cols ?? item.subitemsCols ?? 1}
                          >
                            {subitem.items.map((subsubitem) => {
                              const active = pathname === subsubitem.path;
                              return (
                                <Box as="li" key={subsubitem.path} width="100%">
                                  <LinkOrAnchor
                                    href={subsubitem.path}
                                    external={subsubitem.external}
                                  >
                                    <NavLink
                                      id={`nav-${slugify(item.label, { lower: true })}-${slugify(
                                        subsubitem.label,
                                        { lower: true }
                                      )}`}
                                      asPath={pathname}
                                      textProps={{ display: 'block' }}
                                      mobile
                                    >
                                      <Box
                                        width="100%"
                                        borderRadius={2}
                                        px={8}
                                        py={7}
                                        _css={{
                                          '&:hover': {
                                            bg: 'grey8',
                                            svg: { fill: 'primary' },
                                          },
                                        }}
                                      >
                                        <Box variant="flex" alignItems="center">
                                          {subsubitem.icon && (
                                            <Icon
                                              Content={subsubitem.icon}
                                              Size={18}
                                              color={
                                                active ? 'primary' : 'grey3'
                                              }
                                              mr={4}
                                            />
                                          )}
                                          <Text
                                            as="span"
                                            color={active ? 'grey0' : 'grey2'}
                                            fontWeight={active ? 500 : 400}
                                            lineHeight={1}
                                          >
                                            {subsubitem.label}
                                          </Text>
                                        </Box>
                                        {subsubitem.description && (
                                          <Text
                                            fontSize={1}
                                            color="grey3"
                                            lineHeight={1}
                                            mt={4}
                                          >
                                            {subsubitem.description}
                                          </Text>
                                        )}
                                      </Box>
                                    </NavLink>
                                  </LinkOrAnchor>
                                </Box>
                              );
                            })}
                          </NavPopupSection>
                        </Box>
                      ))}
                    </NavPopupBody>
                  </AnimatedNavPopup>
                )}
              </Box>
            ))}
            <Box ml={10}>
              <Button
                id="nav-log-in"
                to="https://app.northflank.com/login"
                externalLink
              >
                Log in
              </Button>
            </Box>
            <Box ml={6}>
              <Button
                id="nav-sign-up"
                variant="primary"
                to="https://app.northflank.com/signup"
                externalLink
              >
                Get started
              </Button>
            </Box>
          </Box>
          {isMobile && (
            <Button
              id="nav-sign-up"
              to="https://app.northflank.com/signup"
              variant="primary"
              ml={10}
              px="4px!important"
              externalLink
            >
              Get started
            </Button>
          )}
          {menuOpen && (
            <Box
              bg="rgba(0, 0, 0, 0.8)"
              position="fixed"
              top="64px"
              left={0}
              height="calc(var(--app-height) - 64px)"
              zIndex={10}
              onClick={() => setMenuOpen(false)}
            >
              <Box
                width="100vw"
                maxHeight="calc(var(--app-height) - 64px)"
                bg="background"
                display={['block', 'block', 'none']}
                px={[9, 9, 12]}
                py={12}
                borderBottom="4px solid"
                borderColor="primary"
                onClick={(e) => e.stopPropagation()}
                overflowY="auto"
              >
                <Box as="ul" mb={12} _css={{ listStyle: 'none' }}>
                  {navItems.map((item) => (
                    <Box as="li" key={item.path} fontSize={3} mb={8}>
                      <Box
                        borderBottom={item.subitems ? '1px solid' : '0px'}
                        borderBottomColor="grey6"
                        pb={6}
                      >
                        {item.external ? (
                          <a href={item.path} target="_blank" rel="noreferrer">
                            <NavLink
                              to={item.path}
                              onClick={() => setMenuOpen(false)}
                              asPath={pathname}
                              new={item.new}
                              mobile
                            >
                              {item.label}
                            </NavLink>
                          </a>
                        ) : (
                          <Link to={item.path}>
                            <NavLink
                              id={`nav-${slugify(item.label, { lower: true })}`}
                              onClick={() => setMenuOpen(false)}
                              asPath={pathname}
                              new={item.new}
                              mobile
                            >
                              {item.label}
                            </NavLink>
                          </Link>
                        )}
                      </Box>
                      {item.subitems && (
                        <Box
                          as="ul"
                          display="grid"
                          gridTemplateColumns="1fr"
                          gridGap={6}
                          mt={6}
                          mb={8}
                          _css={{ listStyle: 'none' }}
                        >
                          {item.subitems.map((subitem) => (
                            <Box
                              key={subitem.title}
                              _css={{
                                '&:not(:first-child)': {
                                  mt: 6,
                                },
                              }}
                            >
                              {subitem.title && (
                                <Box
                                  bg="grey8"
                                  borderRadius={2}
                                  px={8}
                                  py={6}
                                  mb={6}
                                >
                                  <Text
                                    fontSize={1}
                                    fontWeight={600}
                                    css={{ textTransform: 'uppercase' }}
                                  >
                                    {subitem.title}
                                  </Text>
                                </Box>
                              )}
                              <Box
                                as="ul"
                                display="grid"
                                gridTemplateColumns="1fr 1fr"
                                gridGap={7}
                                _css={{ listStyle: 'none' }}
                              >
                                {subitem.items.map((subsubitem) => {
                                  const active = pathname === subsubitem.path;
                                  return (
                                    <Box
                                      as="li"
                                      key={subsubitem.path}
                                      width="100%"
                                    >
                                      <LinkOrAnchor
                                        href={subsubitem.path}
                                        external={subsubitem.external}
                                      >
                                        <NavLink
                                          id={`nav-${slugify(item.label, { lower: true })}-${slugify(
                                            subsubitem.label,
                                            { lower: true }
                                          )}`}
                                          onClick={() => setMenuOpen(false)}
                                          asPath={pathname}
                                          textProps={{ whiteSpace: 'wrap' }}
                                          mobile
                                        >
                                          <Box
                                            variant="flex"
                                            alignItems="flex-start"
                                            overflow="hidden"
                                          >
                                            {subsubitem.icon && (
                                              <Icon
                                                Content={subsubitem.icon}
                                                Size={16}
                                                color={
                                                  active ? 'primary' : 'grey3'
                                                }
                                                mr={5}
                                                mt="2px"
                                              />
                                            )}
                                            <Text
                                              as="span"
                                              color={active ? 'grey0' : 'grey2'}
                                              fontWeight={active ? 500 : 400}
                                            >
                                              {subsubitem.label}
                                            </Text>
                                          </Box>
                                        </NavLink>
                                      </LinkOrAnchor>
                                    </Box>
                                  );
                                })}
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
                <Grid gridTemplateColumns="repeat(2, 1fr)" gridGap={6}>
                  <Button
                    id="mobile-nav-log-in"
                    variant="large"
                    to="https://app.northflank.com/login"
                    width={1}
                    externalLink
                  >
                    Log in
                  </Button>
                  <Button
                    id="mobile-nav-sign-up"
                    variant={['primary', 'large']}
                    to="https://app.northflank.com/signup"
                    width={1}
                    externalLink
                  >
                    Sign up
                  </Button>
                </Grid>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {!hideCookieNotice && (
        <Box
          variant="flex"
          alignItems="center"
          px={6}
          py={5}
          bg="primary"
          borderRadius={2}
          position="fixed"
          left="16px"
          bottom="16px"
          boxShadow="dropDown"
          zIndex={9999}
        >
          <Box mr={8}>
            <Link to="/legal/cookies">
              <Box variant="flex" alignItems="center" color="grey1">
                <Icon Content={Cookie} Size={20} mr={6} />
                <Text fontSize={2} lineHeight={1}>
                  Read our cookie policy
                </Text>
              </Box>
            </Link>
          </Box>
          <Icon Content={X} Size={20} color="grey1" onClick={hide} />
        </Box>
      )}
    </>
  );
};

export default Nav;
