import React from 'react';

import css from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import styled from 'styled-components';
import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  typography,
} from 'styled-system';

import { variant } from '@northflank/utilities/styled-system/variant';

const Wrapper = styled.div.withConfig({
  shouldForwardProp,
})(
  space,
  layout,
  grid,
  background,
  color,
  flexbox,
  typography,
  border,
  position,
  shadow,
  ({
    Size,
    display = 'flex',
    alignItems = 'center',
    minHeight = '14px',
    minWidth = '14px',
    color,
  }) =>
    css({
      display,
      alignItems,
      minHeight,
      minWidth,
      overflow: 'visible !important',
      svg: {
        width:
          typeof Size === 'number'
            ? `${Size.toString()}px`
            : `${(Size?.width || 16).toString()}px`,
        height:
          typeof Size === 'number'
            ? `${Size.toString()}px`
            : `${(Size?.height || 16).toString()}px`,
        minWidth:
          typeof Size === 'number'
            ? `${Size.toString()}px`
            : `${(Size?.width || 16).toString()}px`,
        minHeight:
          typeof Size === 'number'
            ? `${Size.toString()}px`
            : `${(Size?.height || 16).toString()}px`,
        fill: color,
        'path[fill="currentColor"]': { fill: color },
      },
    }),
  () =>
    variant({
      variants: {},
    })
);

const Icon = ({ Content, Size, title, ...args }) => {
  if (!Content) {
    return <></>;
  }
  return (
    <Wrapper Size={Size} {...args}>
      <Content title={title} />
    </Wrapper>
  );
};

export default Icon;
