import React from 'react';

import { Building } from '@styled-icons/boxicons-regular/Building';
import { Chip } from '@styled-icons/boxicons-regular/Chip';
import { World } from '@styled-icons/boxicons-regular/World';
import slugify from 'slugify';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Grid from '@northflank/components/Grid';
import Heading from '@northflank/components/Heading';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import CloudProviderNodeTable from '../../../../components/CloudProviders/CloudProviderNodeTable';
import CloudProvidersNav from '../../../../components/CloudProviders/CloudProvidersNav';
import DisplayProviderAvailabilityZones from '../../../../components/CloudProviders/DisplayProviderAvailabilityZones';
import DisplayProviderRegions from '../../../../components/CloudProviders/DisplayProviderRegions';
import { cloudProviderOptions } from '../../../../components/CloudProviders/utility/cloudProviderOptions';
import { FeaturesWrapper } from '../../../../components/FeaturePageWrapper';
import { Section } from '../index';

const DisplayNorthflankRegion = ({
  providerRegion,
  regions,
  providerNodes,
}) => {
  const { id, name, regionName } = providerRegion;
  const slugRegionName = slugify(regionName, { lower: true });

  const navItem = [
    {
      title: `Northflank`,
      href: `/cloud/northflank/regions/${id}`,
      subItems: [
        {
          icon: Chip,
          title: `Plans Available in ${id} on Northflank`,
        },
        {
          icon: World,
          title: `Other Provider Regions in ${regionName}`,
        },
      ],
    },
  ];

  return (
    <>
      <SEO
        title={`${id} | ${regionName} | Northflank | Cloud Providers`}
        description={`Deploy into the ${id} region on Northflank.`}
      />
      <CloudProvidersNav />
      <FeaturesWrapper cloudProviderPage customNavItems={navItem}>
        <Box variant="bounding">
          <Breadcrumbs mb={6} />
          <Heading color="grey1" fontSize={[5, 5, 6, 6]} mb={10}>
            {name || id} on Northflank
          </Heading>
          <Text fontSize={4} mb={8}>
            Deploy your workloads into the{' '}
            {id !== name ? `${name} (${id})` : id} region on Northflank.
          </Text>
        </Box>
        <Section anchor={`plans-available-in-${id}-on-northflank`}>
          <Heading color="grey1" fontSize={[5, 5, 6, 6]} mb={10}>
            Plans Available in {name || id} on Northflank
          </Heading>
          <CloudProviderNodeTable
            providerId="northflank"
            nodeTypes={providerNodes}
          />
        </Section>
        <Section anchor={`other-provider-regions-in-${slugRegionName}`}>
          <Heading color="grey1" fontSize={[5, 5, 6, 6]} mb={10}>
            Other Provider Regions in {regionName}
          </Heading>
          <Grid gridTemplateColumns="1fr" gridGap={10}>
            {cloudProviderOptions.map(
              (option) =>
                option.value !== 'northflank' &&
                regions[option.value]?.length && (
                  <DisplayProviderRegions
                    key={option.value}
                    option={option}
                    displayedRegions={regions}
                  />
                )
            )}
          </Grid>
        </Section>
      </FeaturesWrapper>
    </>
  );
};

const DisplayByocRegion = ({
  cloudProvider,
  providerRegion,
  regions,
  providerNodes,
}) => {
  const { id, name, regionName, availabilityZones } = providerRegion;

  const slugCloudName = slugify(cloudProvider.name, { lower: true });
  const slugRegionName = slugify(regionName, { lower: true });

  const navItem = [
    {
      title: `${cloudProvider.name}`,
      href: `/cloud/${cloudProvider.id}/regions/${id}`,
      subItems: [
        {
          icon: Building,
          title: `Availability Zones in ${id} on ${cloudProvider.name}`,
        },
        {
          icon: Chip,
          title: `Instances Available in ${id} on ${cloudProvider.name}`,
        },
        {
          icon: World,
          title: `Other Provider Regions in ${regionName}`,
        },
      ],
    },
  ];

  return (
    <>
      <SEO
        title={`${id} - ${regionName} | ${cloudProvider.name} | Cloud Providers`}
        description={`Deploy Northflank projects into the ${id} region on ${cloudProvider.id}.`}
      />
      <CloudProvidersNav />
      <FeaturesWrapper cloudProviderPage customNavItems={navItem}>
        <Box variant="bounding">
          <Breadcrumbs mb={6} />
          <Heading color="grey1" fontSize={[5, 5, 6, 6]} mb={10}>
            Deploy to {id} on {cloudProvider.name} with Northflank
          </Heading>
          <Text fontSize={4} mb={13}>
            Deploy your workloads into the{' '}
            <Text as="span" color="grey1" fontWeight={500}>
              {name || id}
            </Text>{' '}
            region on {cloudProvider.name} with Northflank.
          </Text>
          <a
            href={`https://app.northflank.com/s/account/cloud/clusters/new/${cloudProvider.id}`}
            target="_blank"
            style={{ display: 'inline-block' }}
            rel="noreferrer"
          >
            <Button variant={['large', 'primary']}>Deploy in {id} now</Button>
          </a>
        </Box>
        <Section anchor={`availability-zones-in-${id}-on-${slugCloudName}`}>
          <Heading color="grey1" fontSize={[5, 5, 6, 6]} mb={10}>
            Availability Zones in {id} on {cloudProvider.name}
          </Heading>
          <DisplayProviderAvailabilityZones
            availabilityZones={availabilityZones}
          />
        </Section>
        <Section anchor={`instances-available-in-${id}-on-${slugCloudName}`}>
          <Heading color="grey1" fontSize={[5, 5, 6, 6]} mb={10}>
            Instances Available in {id} on {cloudProvider.name}
          </Heading>
          <CloudProviderNodeTable
            providerId={cloudProvider.id}
            nodeTypes={providerNodes}
          />
        </Section>
        <Section anchor={`other-provider-regions-in-${slugRegionName}`}>
          <Heading color="grey1" fontSize={[5, 5, 6, 6]} mb={10}>
            Other Provider Regions in {regionName}
          </Heading>
          <Grid gridTemplateColumns="1fr" gridGap={10}>
            {cloudProviderOptions.map(
              (option) =>
                option.value !== cloudProvider.id &&
                regions[option.value]?.length && (
                  <DisplayProviderRegions
                    key={option.value}
                    option={option}
                    displayedRegions={regions}
                  />
                )
            )}
          </Grid>
        </Section>
      </FeaturesWrapper>
    </>
  );
};

const Region = ({ cloudProvider, providerRegion, regions, providerNodes }) => {
  const northflank = cloudProvider?.id === 'northflank';

  return northflank ? (
    <DisplayNorthflankRegion
      providerRegion={providerRegion}
      regions={regions}
      providerNodes={providerNodes}
    />
  ) : (
    <DisplayByocRegion
      cloudProvider={cloudProvider}
      providerRegion={providerRegion}
      regions={regions}
      providerNodes={providerNodes}
    />
  );
};

export default Region;
