import React from 'react';

import { Mongodb } from '@styled-icons/boxicons-logos/Mongodb';
import { Postgresql } from '@styled-icons/boxicons-logos/Postgresql';
import { AddToQueue } from '@styled-icons/boxicons-regular/AddToQueue';
import { Certification } from '@styled-icons/boxicons-regular/Certification';
import { Data } from '@styled-icons/boxicons-regular/Data';
import { GitRepoForked } from '@styled-icons/boxicons-regular/GitRepoForked';
import { Hdd } from '@styled-icons/boxicons-regular/Hdd';
import { Key } from '@styled-icons/boxicons-regular/Key';
import { LockAlt } from '@styled-icons/boxicons-regular/LockAlt';
import { Reset } from '@styled-icons/boxicons-regular/Reset';
import { Time } from '@styled-icons/boxicons-regular/Time';
import { Undo } from '@styled-icons/boxicons-regular/Undo';
import { UpArrowCircle } from '@styled-icons/boxicons-regular/UpArrowCircle';
import { Mysql } from '@styled-icons/simple-icons/Mysql';
import { Rabbitmq } from '@styled-icons/simple-icons/Rabbitmq';
import { Redis } from '@styled-icons/simple-icons/Redis';

import Box from '@northflank/components/Box';
import Heading from '@northflank/components/Heading';
import FeatureNav from '@northflank/components/marketing/FeatureNav';
import FeatureWithIcon from '@northflank/components/marketing/FeatureWithIcon';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';

import {
  FeatureGroup,
  FeaturesWrapper,
} from '../../components/FeaturePageWrapper';
import PreHeading from '../../components/PreHeading';
import Minio from '../../svg/minio_bird.svg';

const Databases = () => {
  return (
    <>
      <SEO
        title="Databases — Features"
        description="Provision databases, queues and stateful workloads with Northflank addons on Kubernetes with high-availability, secure networking, upgrades, backups & restores."
      />
      <FeatureNav />
      <FeaturesWrapper>
        <Box variant="bounding" pb="70px!important">
          <PreHeading mb={6}>Feature breakdown</PreHeading>
          <Heading as="h1" color="grey1" fontSize={[6, 6, 7, 7]} mb={8}>
            Databases on Northflank
          </Heading>
          <Text color="grey3" fontSize={4} width={[1, 1, 1 / 2]} mediaQuery>
            Quickly provision popular databases and queues with Northflank
            Addons. High&#8209;availability, secure networking, upgrades,
            backups, and restores all come built-in.
          </Text>
        </Box>
        <FeatureGroup
          heading="Deploy Data Stores"
          wideImage="/images/feature/details/databases/creation.png"
          wideImageAlt="Screenshot of Northflank addon creation selection"
          items={
            <>
              <FeatureWithIcon
                Icon={Postgresql}
                iconGradient
                heading="PostgreSQL"
                text="PostgreSQL, also known as Postgres, is a free and open-source relational database management system emphasizing extensibility and SQL compliance."
              />
              <FeatureWithIcon
                Icon={Mysql}
                iconGradient
                heading="MySQL"
                text="MySQL is an open-source relational database management system."
              />
              <FeatureWithIcon
                Icon={Mongodb}
                p
                iconGradient
                heading="MongoDB®"
                text="MongoDB is a source-available cross-platform document-oriented database program."
              />
              <FeatureWithIcon
                Icon={Minio}
                iconGradient
                heading="MinIO®"
                text="MinIO is a High Performance Object Storage platform, compatible with Amazon S3."
              />
            </>
          }
        />
        <FeatureGroup
          heading="Deploy Caches and Queues"
          wideImage="/images/feature/details/databases/resources.png"
          wideImageAlt="Screenshot of Northflank addon resources configuration"
          items={
            <>
              <FeatureWithIcon
                Icon={Redis}
                iconGradient
                heading="Redis®"
                text="Redis is an in-memory data structure store, used as a distributed key–value database, cache, and message broker."
              />
              <FeatureWithIcon
                Icon={Rabbitmq}
                iconGradient
                heading="RabbitMQ"
                text="RabbitMQ is a fast and scalable open-source message broker."
              />
            </>
          }
        />
        <FeatureGroup
          heading="Secure and Scale Stateful Workloads"
          wideImage="/images/feature/details/platform/connectiondetails.png"
          wideImageAlt="Screenshot of Northflank addon connection details interface"
          items={
            <>
              <FeatureWithIcon
                Icon={LockAlt}
                iconGradient
                heading="Public or Private"
                text="Deploy your addons with public networking to reach them from anywhere, or with private networking to make them available only within your project."
              />
              <FeatureWithIcon
                Icon={Certification}
                iconGradient
                heading="Deploy with TLS"
                text="All addons with public networking enabled get free TLS security via Let’s Encrypt."
              />
              <FeatureWithIcon
                Icon={AddToQueue}
                iconGradient
                heading="High Availability"
                text="Deploy addons with multiple replicas to keep latency low and handle increasing demand with ease."
              />
              <FeatureWithIcon
                Icon={Key}
                iconGradient
                heading="Inherit Connection Details"
                text="Using secret groups, automatically and securely inject connection details from your addons into services and jobs in the same project."
              />
              <FeatureWithIcon
                Icon={UpArrowCircle}
                iconGradient
                heading="Managed Upgrades"
                text="Keep your addons up to date with the latest security updates and features without any hassle."
              />
            </>
          }
        />
        <FeatureGroup
          heading="Backups & Restores"
          wideImage="/images/feature/details/databases/schedule.png"
          wideImageAlt="Screenshot of Northflank backup schedule creation interface"
          items={
            <>
              <FeatureWithIcon
                Icon={Data}
                iconGradient
                heading="Native Backups"
                text="Perform optimised backups using each addon’s native dump feature."
              />
              <FeatureWithIcon
                Icon={Hdd}
                iconGradient
                heading="Disk Backups"
                text="Use incremental disk snapshots for optimised backup & recovery with cloud provider native storage (CSI)."
              />
              <FeatureWithIcon
                Icon={Undo}
                iconGradient
                heading="Restore from Backup"
                text="Restore an addon to a former state from any backup you have made in the past."
              />
              <FeatureWithIcon
                Icon={Time}
                iconGradient
                heading="Backup Schedules"
                text="Define schedules to perform automatic backups as often you need them, and configure how long backups should be retained."
              />
              <FeatureWithIcon
                Icon={GitRepoForked}
                iconGradient
                heading="Forking"
                text="Create a brand new database with existing data by forking a database you already have running."
              />
              <FeatureWithIcon
                Icon={Reset}
                iconGradient
                heading="Point-In-Time Recovery"
                text="Enable PITR to maintain a constant log of database transactions and roll back to any recorded point in time."
              />
            </>
          }
        />
      </FeaturesWrapper>
    </>
  );
};

export default Databases;
