import css from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import styled from 'styled-components';
import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  system,
  typography,
} from 'styled-system';

import { variant } from '@northflank/utilities/styled-system/variant';

const gridColumnEnd = system({
  prop: 'gridColumnEnd',
  cssProperty: 'grid-column-end',
});

const Grid = styled.div.withConfig({ shouldForwardProp })(
  space,
  layout,
  grid,
  background,
  color,
  flexbox,
  typography,
  border,
  position,
  shadow,
  gridColumnEnd,
  ({
    width = '100%',
    gridGap = 9,
    display = 'grid',
    alignItems = 'center',
    _css,
  }) =>
    css({
      width,
      display,
      gridGap,
      alignItems,
      ..._css,
    }),
  ({ gridTemplateColumns }) =>
    variant({
      variants: {
        scaffold: {
          py: 0,
          '*': {
            'text-overflow': 'normal',
            'white-space': 'wrap',
            overflow: 'visible',
          },
        },
        right: {
          gridColumnGap: 7,
          gridAutoFlow: 'column',
          justifyContent: 'end',
        },
        left: {
          gridColumnGap: 7,
          gridAutoFlow: 'column',
          justifyContent: 'start',
        },
        dashboard: {
          gridGap: 11,
          alignItems: 'start',
          gridTemplateColumns: gridTemplateColumns || [
            'minmax(0, 1fr)',
            'minmax(0, 1fr)',
            'minmax(0, 1fr)',
            'minmax(0, 1fr)',
            'repeat(2, minmax(0, 1fr))',
          ],
        },
        withWrap: {
          '*': { whiteSpace: 'normal' },
          alignItems: 'flex-start',
        },
        collapseAtMobile: {
          gridTemplateColumns: ['none', 'none', gridTemplateColumns],
          gridAutoFlow: ['row', 'row', 'unset'],
        },
        collapseAtTablet: {
          '@media screen and (max-width: 1000px)': {
            gridTemplateColumns: 'none',
            gridAutoFlow: 'row',
          },
        },
        highlight: {
          cursor: 'pointer',
        },
      },
    })
);

export default Grid;
