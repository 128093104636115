import React from 'react';
import { Link } from 'react-router-dom';

import Box from '@northflank/components/Box';
import Heading from '@northflank/components/Heading';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';

import HeroHeading from '../../components/HeroHeading';
import topog from '../../components/Topog';

const JobPost = ({ jobs = [] }) => {
  const departments = Array.from(
    jobs.reduce((acc, cur) => {
      acc.add(cur.departmentName);
      return acc;
    }, new Set())
  ).sort();

  return (
    <>
      <SEO
        title="Careers"
        description="Work with us! View a list of our currently open positions."
      />
      <Box
        maxWidth="1920px"
        mx="auto"
        background={[
          'none',
          'none',
          `radial-gradient(25% 250px at 40% 250px, rgba(0, 147, 255, 0.05) 0%, #151C28 100%),
        radial-gradient(circle at 40% 250px, rgba(0, 0, 0, 0) 0%, #151C28 55%), ${topog}`,
        ]}
      >
        <Box variant="bounding" maxWidth="800px !important">
          <Box mb={13}>
            <Heading as="h1" fontSize={[6, 6, 7, 7]} color="grey1" mb={8}>
              <HeroHeading as="span" fontSize={[6, 6, 7, 7]}>
                Join us at the forefront of the cloud native revolution
              </HeroHeading>{' '}
            </Heading>
            <Text fontSize={4} color="grey2">
              At Northflank, we are building a developer platform that can be
              run anywhere on any cloud – including ours. Our next-gen platform
              allows developers to reap all the benefits of Kubernetes without
              the hassle. Join us on our mission to redefine developer
              experience.
            </Text>
          </Box>
          {jobs.length ? (
            <Box _css={{ '> * + *': { mt: 12 } }}>
              {departments.map((department) => (
                <Box key={`department:${department}`}>
                  <Heading fontSize={5} color="grey1" mb={8}>
                    {department}
                  </Heading>
                  <Box
                    bg="background"
                    border="1px solid"
                    borderColor="grey6"
                    borderRadius={2}
                    boxShadow="dropDown"
                    px={9}
                    _css={{
                      '> * + *': {
                        borderTop: '1px solid',
                        borderTopColor: 'grey6',
                      },
                    }}
                  >
                    {jobs
                      .filter((j) => j.departmentName === department)
                      .map((job) => (
                        <Link key={`jobId:${job.id}`} to={`/careers/${job.id}`}>
                          <Box
                            variant="flex"
                            flexDirection={['column', 'column', 'row']}
                            alignItems={['flex-start', 'flex-start', 'center']}
                            justifyContent={[
                              'flex-start',
                              'flex-start',
                              'space-between',
                            ]}
                            fontSize={3}
                            py={8}
                            _css={{
                              '&:hover p:first-child': {
                                textDecoration: 'underline',
                              },
                            }}
                          >
                            <Text color="grey1">{job.title}</Text>
                            <Text color="grey3">
                              {job.locationName},{' '}
                              {job.employmentType.replace(
                                /([a-z])([A-Z])/g,
                                '$1 $2'
                              )}
                            </Text>
                          </Box>
                        </Link>
                      ))}
                  </Box>
                </Box>
              ))}
            </Box>
          ) : (
            <Text fontSize={4} color="grey3">
              Unfortunately, we don’t have any open positions right now. Check
              back soon!
            </Text>
          )}
        </Box>
      </Box>
    </>
  );
};

export default JobPost;
