import cronstrue from 'cronstrue';

export * from './cdn';
export * from './billing';
export * from './subdomains';
export * from './autoscaling';
export * from './templates';
export * from './vat-rates';
export * from './addons';
export * from './byoc-resource-profiles';
export * from './byoc-request-modifiers';
export * from './migration-tracker';

export const defaultProjectUidLength = 12;

export const urlDelimiter = '--';

export const dnsLengthLimit = 63;

// minimum length of 1 for port must be taken into account
export const serviceDnsLengthLimit = 63 - urlDelimiter.length - 1;

export const nfObjectTypeCollections = {
  job: 'jobs',
  service: 'services',
} as const;

export const serviceTypes = ['combined', 'build', 'deployment'];

export const entityTypeCollections = {
  user: 'users',
  team: 'teams',
};

export const nfObjectTypeCollectionsList = ['jobs', 'services'];

export const projectDependencyCollectionsList = ['services', 'jobs', 'addons'];

export const vcsServiceLabels = {
  github: 'GitHub',
  gitlab: 'GitLab',
  githubApp: 'GitHub',
  bitbucket: 'Bitbucket',
  'self-hosted': 'Self-hosted VCS',
};

export const httpPortProtocols = ['HTTP', 'HTTP/2'];
export const publicPortProtocols = [...httpPortProtocols];
export const privatePortProtocols = ['HTTP', 'HTTP/2', 'TCP', 'UDP'];

export const probeProtocols = ['HTTP', 'CMD', 'TCP'];
export const probeTypes = ['livenessProbe', 'readinessProbe', 'startupProbe'];

export const probeMinimumThreshold = 1;
export const probeMaximumThreshold = 255;

export const probeMinimumTimeout = 1;
export const probeMaximumTimeout = 60;

export const probeMinimumIntervalPeriod = 10;
export const probeMaximumIntervalPeriod = 600;

export const probeMinimumInitialDelay = 1;
export const probeMaximumInitialDelay = 180;

// TODO: make provider specific
export const MAXIMUM_NODEPOOL_COUNT = 20;
export const MAXIMUM_NODEPOOL_COUNT_ERROR = `Maximum of ${MAXIMUM_NODEPOOL_COUNT} node pools allowed`;

export const validRegistryProviders = [
  'dockerhub',
  'gcr',
  'gcr-asia',
  'gcr-eu',
  'gcr-us',
  'gitlab',
  'github',
  'custom',
];

export const cpuData = [
  { value: 0.1, label: '0.1 vCore' },
  { value: 0.2, label: '0.2 vCores' },
  { value: 0.5, label: '0.5 vCores' },
  { value: 1, label: '1.0 vCores' },
];

export const ramData = [
  { value: 256, label: '256 MB' },
  { value: 512, label: '512 MB' },
  { value: 1024, label: '1024 MB' },
  { value: 2048, label: '2048 MB' },
  { value: 4096, label: '4096 MB' },
];

export const storageData = [
  { value: 1024, label: '1 GB' },
  { value: 2048, label: '2 GB' },
  { value: 4096, label: '4 GB' },
];

export const currencyData = [
  { value: 'usd', label: 'USD' },
  { value: 'gbp', label: 'GBP' },
  { value: 'eur', label: 'EUR' },
];

export const typeData = [
  { value: 'build', label: 'Build' },
  { value: 'deployment', label: 'Deployment' },
];

export const intervalCountData = [{ value: 1, label: '1' }];

export const popularityData = [
  { value: false, label: 'Default' },
  { value: true, label: 'Popular' },
];

export const publishData = [
  { value: false, label: 'Not Published' },
  { value: true, label: 'Published' },
];

export const previewData = [
  { value: false, label: 'Live' },
  { value: true, label: 'Preview' },
];

export const featureData = [
  { value: 'hot_reload', label: 'Hot Reload' },
  { value: 'domain', label: 'Bring your own domain' },
  { value: 'scalability', label: 'Horizontal Scalability' },
  { value: 'ssl', label: 'SSL Certificate Management' },
  { value: 'ea', label: 'Early access to features' },
  { value: 'slack', label: 'Slack support' },
];

export enum ProjectSecretTypes {
  SECRET = 'secret',
  CONFIG = 'config',
}

export enum ProjectSecretScopes {
  ENV_ARGS = 'environment-arguments',
  ENVS = 'environment',
  ARGS = 'arguments',
}

export enum DockerCredentialProviders {
  ECR = 'ecr',
  GCR = 'gcr',
  DOCKERHUB = 'dockerhub',
  GITLAB = 'gitlab',
  GITHUB = 'github',
  CUSTOM = 'custom',
}

export const projectSecretScopeSelectOptions = [
  {
    label: 'Build & Runtime',
    value: ProjectSecretScopes.ENV_ARGS,
    description:
      'Kay value pairs will be injected at both run and build time. For builds, keys will have to be specified using the ARG key word in the Dockerfile.',
  },
  {
    label: 'Runtime',
    value: ProjectSecretScopes.ENVS,
    description: 'Key value pairs will be injected only at run time as environment variables.',
  },
  {
    label: 'Build',
    value: ProjectSecretScopes.ARGS,
    description:
      'Values will be inject at build time only. Keys will have to be specified using the ARG key word in the Dockerfile.',
  },
];

export const projectSecretTypeLabels = {
  'secret': 'Secret',
  'config': 'Config',
};

export const projectSecretScopeLabels = {
  'environment': 'Runtime',
  'arguments': 'Build',
  'environment-arguments': 'Build & Runtime',
};

export const featureTooltips = {
  hot_reload:
    'During restarts, replacements and updates the a new instance is started before the old one is stopped. This ensures the service stays reachable.',
  domain: 'Easily add your domain to the service including SSL.',
  scalability: 'Scale your service in any supported region with the click of a button.',
};

export const tagData = [{ value: 'Meteor', label: 'Meteor' }];

export const bitbucketLink = 'https://bitbucket.org';
export const githubLink = 'https://github.com';
export const gitlabLink = 'https://gitlab.com';

export const azureLink = 'https://login.microsoftonline.com';

export const vcsServiceUrls = {
  bitbucket: bitbucketLink,
  github: githubLink,
  gitlab: gitlabLink,
  azure: azureLink,
};

export const githubAvatarLink = 'https://avatars.githubusercontent.com';

export const mesosTaskDeadStatuses = ['TASK_FAILED', 'TASK_KILLED', 'TASK_FINISHED'];
export const mesosTaskAliveStatuses = [
  'TASK_STAGING',
  'TASK_STARTING',
  'TASK_RUNNING',
  'TASK_KILLING',
];

export const objectIdLength = 24;

// Important port, name & entity lengths influence dns length, which must not exceed 63 characters including delimiters
export const portNameMinLength = 1;
export const portNameMaxLength = 8;
// Important
export const usernameMinLength = 3;
// old
// export const usernameMaxLength = 20;
export const usernameMaxLength = 39;

// Important
export const nameMinLength = 3;
export const nameMaxLength = 20;
export const newNameMaxLength = 39; // TODO this will become nameMaxLength in future
export const dynamicLengthNameMaxLength = 54;
export const dynamicLengthJobNameMaxLength = 52;

export const newEntityInternalIdMaxLength = 45;

export const longNameMaxLength = 100;

// Important
export const entityInternalIdLength = 8;
export const entityShort = 4;
export const entityRandomChars = 4;
// Can also be one shorter as min name length is 3, not 4
export const entityDNSLength = entityShort + entityRandomChars + 1;

export const passwordMinLength = 10;

export const descriptionMinLength = 1;
export const descriptionMaxLength = 200;

export const minContainerPort = 1;
export const maxContainerPort = 65535;

export const feedbackMessageMaxLength = 1200;

export const maxPorts = 20;
export const maxInstances = 10;
export const maxByocInstances = 1000;

export const defaultParallelJobRunLimit = 10;

export const minGitLabGroupUrlLength = 2;
export const fetchDockerfileTimeout = 10000;

export const pathIgnoreRuleMaxLength = 260;
export const pathIgnoreRuleMaxRules = 200;

export const ciIgnoreFlagMaxLength = 72;
export const ciIgnoreFlagMaxRules = 200;

export const maxBackupsInRetentionWindow = 500;

// eslint-disable-next-line no-magic-numbers
export const forbiddenPortNumbers = [15000, 15020, 65000];

export const validBuildEngines = ['kaniko', 'buildkit'];

export const defaultBuildEngine = 'kaniko';

export const validBuildpackStacks = [
  'HEROKU_24',
  'HEROKU_22',
  'HEROKU_22_CLASSIC',
  'HEROKU_20',
  'HEROKU_18',
  'GOOGLE_22',
  'GOOGLE_V1',
  'CNB_ALPINE',
  'CNB_BIONIC',
  'PAKETO_TINY',
  'PAKETO_BASE',
  'PAKETO_FULL',
];

export const defaultBuildpackStack = 'HEROKU_24';

export const deletingLifecycleStates = ['deleting'];
export const terminalLifecycleStates = [...deletingLifecycleStates, 'deleted'];

export const blockedNFObjectLifecycleStates = [
  'upgrading',
  'upgrade_failed',
  'migrating',
  'migration_failed',
];

export const unprocessableNFObjectLifecycleStates = [
  ...blockedNFObjectLifecycleStates,
  'creating',
  'creation_failed',
  'upgrading',
  'deleting',
  'deletion_failed',
  'deleted',
  'cluster_deleted',
  'initializing',
];

export const specialCronExpressions = {
  '@yearly': 'At 12:00 AM, on day 1 of the month, only in January',
  '@annually': 'At 12:00 AM, on day 1 of the month, only in January',
  '@monthly': 'At 12:00 AM, on day 1 of the month',
  '@weekly': 'At 12:00 AM, only on Sunday',
  '@daily': 'At 12:00 AM',
  '@hourly': 'Every hour',
};

export const getHumanReadableCron = (cron, capitalizeFirstLetter = true) => {
  const text = Object.keys(specialCronExpressions).includes(cron)
    ? specialCronExpressions[cron]
    : cronstrue.toString(cron, { use24HourTimeFormat: true });

  return capitalizeFirstLetter ? text : text.charAt(0).toLowerCase() + text.slice(1);
};

export const minimumBillingCharge = 50;

export const defaultConcurrencyPolicy = 'forbid';
export const defaultRunOnSourceChange = 'never';
export const defaultBackoffLimit = 0;
export const defaultActiveDeadlineSeconds = 600;

const minSSDStorageGB = 4;
const maxSSDStorageGB = 1000;
const minHDDStorageGB = 40;
const maxHDDStorageGB = 1000;

const ssdStorageClassKey = 'ssd';
export const ssdStorageClass = {
  key: ssdStorageClassKey,
  // value is used as an alias for key for the select dropdowns
  value: ssdStorageClassKey,
  label: 'SSD',
  initialValue: 1024 * minSSDStorageGB,
  minValidValue: 1024 * minSSDStorageGB,
  maxValidValue: 1024 * maxSSDStorageGB,
  // validOptions: [],
  volumeValidOptions: [
    1024 * 5,
    1024 * 6,
    1024 * 10,
    1024 * 20,
    1024 * 40,
    1024 * 60,
    1024 * 80,
    1024 * 100,
    1024 * 120,
    1024 * 150,
    1024 * 200,
    1024 * 300,
    1024 * 400,
    1024 * 500,
    1024 * 600,
    1024 * 700,
    1024 * 800,
    1024 * 900,
    1024 * 1000,
  ],
  minimumRequirementMsg: `SSD has a minimum requirement of ${minSSDStorageGB} GB`,
};

const hddStorageClassKey = 'hdd';
const hddStorageClass = {
  key: hddStorageClassKey,
  // value is used as an alias for key for the select dropdowns
  value: hddStorageClassKey,
  label: 'HDD',
  initialValue: 1024 * minHDDStorageGB,
  minValidValue: 1024 * minHDDStorageGB,
  maxValidValue: 1024 * maxHDDStorageGB,
  // validOptions: [],
  volumeValidOptions: [
    1024 * 40,
    1024 * 60,
    1024 * 80,
    1024 * 100,
    1024 * 120,
    1024 * 150,
    1024 * 200,
    1024 * 300,
    1024 * 400,
    1024 * 500,
    1024 * 600,
    1024 * 700,
    1024 * 800,
    1024 * 900,
    1024 * 1000,
  ],
  minimumRequirementMsg: `HDD has a minimum requirement of ${minHDDStorageGB} GB`,
};

export const OCI_MINIMUM_STORAGE = 61440;

export const getMinimumStorageRequirement = (provider) => {
  switch (provider) {
    case 'oci':
      return OCI_MINIMUM_STORAGE;
    default:
      return 0;
  }
};

export const ociProviderStorageOverrides = {
  'provider': 'oci',
  'resources': {
    'storage': {
      'options': [
        OCI_MINIMUM_STORAGE,
        102400,
        153600,
        204800,
        256000,
        307200,
        409600,
        512000,
        614400,
      ],
      'default': OCI_MINIMUM_STORAGE,
      'minDisplayValue': OCI_MINIMUM_STORAGE,
      'maxDisplayValue': 614400,
    },
  },
};

// Addon type specific options should probably override these when they are available, but can exist as fallback & for volumes
export const storageClassMetaInformation = {
  'ssd': ssdStorageClass,
  'hdd': hddStorageClass,
  storageClassOptions: [ssdStorageClass, hddStorageClass],
  default: ssdStorageClass.key,
};

export const ephemeralStorageClassInformation = {
  options: [
    1024,
    1024 * 2,
    1024 * 5,
    1024 * 10,
    1024 * 20,
    1024 * 30,
    1024 * 40,
    1024 * 50,
    1024 * 60,
    1024 * 80,
    1024 * 100,
    1024 * 120,
    1024 * 150,
    1024 * 200,
  ],
  initialValue: 1024,
  minValidValue: 1024,
  maxValidValue: 1024 * 200,
  defaultValue: 1024,
};

export const buildEphemeralStorageClassInformation = {
  options: [1024 * 16, 1024 * 32, 1024 * 64, 1024 * 128, 1024 * 256, 1024 * 512],
  initialValue: 1024 * 16,
  minValidValue: 1024 * 16,
  maxValidValue: 1024 * 64,
  defaultValue: 1024 * 16,
};

export const persistentStorageClassInformation = {
  options: [
    1024,
    1024 * 2,
    1024 * 5,
    1024 * 10,
    1024 * 20,
    1024 * 30,
    1024 * 40,
    1024 * 50,
    1024 * 60,
    1024 * 80,
    1024 * 100,
    1024 * 120,
    1024 * 150,
    1024 * 200,
  ],
  initialValue: 1024,
  minValidValue: 1024,
  maxValidValue: 1024 * 200,
  defaultValue: 1024,
};

export const buildPersistentStorageClassInformation = {
  options: [1024 * 16, 1024 * 32, 1024 * 64, 1024 * 128, 1024 * 256, 1024 * 512],
  initialValue: 1024 * 16,
  minValidValue: 1024 * 16,
  maxValidValue: 1024 * 64,
  defaultValue: 1024 * 16,
};

export const shmSizeInformation = {
  options: [
    64,
    128,
    256,
    512,
    1024,
    2 * 1024,
    5 * 1024,
    10 * 1024,
    20 * 1024,
    30 * 1024,
    40 * 1024,
    50 * 1024,
    60 * 1024,
    80 * 1024,
    100 * 1024,
    120 * 1024,
    140 * 1024,
  ],
  initialValue: 64,
  minValidValue: 64,
  maxValidValue: 1024 * 60,
  defaultValue: 64,
};

export const gracePeriodSecondsInformation = {
  minValidValue: 15,
  defaultValue: 30,
  maxValidValue: 60 * 10,
};

export const volumeStorageMultiRwMaxObjects = 10;

export enum VolumeAccessModeTypes {
  SINGLE_RW = 'ReadWriteOnce',
  MULTI_RW = 'ReadWriteMany',
}

export const volumeAccessModeOptions = [
  { value: VolumeAccessModeTypes.SINGLE_RW, label: 'Single Read/Write' },
  {
    value: VolumeAccessModeTypes.MULTI_RW,
    label: 'Multi Read/Write',
    featureFlag: 'multiReadWriteVolumes',
  },
];

export enum ServiceDeploymentModes {
  DEPLOYMENT = 'deployment',
  STATEFUL_SET = 'statefulSet',
}

export enum RoutingModes {
  ROUND_ROBIN = 'roundRobin',
  CONSISTENT_HASH = 'consistentHash',
  CONSISTENT_REPLICA_ROUTING = 'consistentReplicaRouting',
}

export enum ConsistentHashRoutingModes {
  IP = 'ip',
  REQUEST_ID = 'requestId',
  CUSTOM_HEADER = 'customHeader',
}

export enum ConsistentReplicaRoutingModes {
  PATH = 'path',
  HEADER = 'header',
}

export const secretFilePathMessage = 'File path must be absolute';

export const variableRegexMessage =
  'Keys may only contain letters, numbers, hyphens, forward slashes and dots.';

export const getAddonExposeFeatureFlag = (addonType) =>
  ['mysql', 'postgres', 'postgresql'].includes(addonType) ? 'addonsExposeLB' : 'addonsExpose';

export const notificationIntegrationTypes = [
  {
    key: 'RAW_WEBHOOK',
    title: 'Webhook',
    requiresWebhook: true,
    requiresSecret: true,
  },
  {
    key: 'SLACK',
    title: 'Slack',
    requiresWebhook: false,
    webhookRegex: /^https:\/\/hooks\.slack\.com/i,
    requiresSecret: false,
  },
  {
    key: 'DISCORD',
    title: 'Discord',
    requiresWebhook: true,
    webhookRegex: /^https:\/\/discord(?:app)?\.com\/api\/webhooks/i,
    requiresSecret: false,
  },
  {
    key: 'TEAMS',
    title: 'Teams',
    requiresWebhook: true,
    webhookRegex: /^https:\/\/[a-zA-Z0-9]+\.webhook\.office\.com\/webhookb2\//i,
    requiresSecret: false,
  },
];

export const notificationIntegrationEvents = [
  {
    name: 'Services',
    subcategories: [
      {
        name: 'Deployment',
        events: [
          {
            id: 'trigger:service:autoscaling:event',
            name: 'Autoscaling Event',
          },
          {
            id: 'trigger:service:deployment:status-update',
            name: 'Deployment Status Update',
          },
        ],
      },
    ],
  },
  {
    name: 'Projects',
    subcategories: [
      {
        name: 'Tailscale',
        events: [
          {
            id: 'trigger:project:tailscale-regen-failure',
            name: 'Tailscale Regeneration Failed',
          },
        ],
      },
    ],
  },
  {
    name: 'Addons',
    subcategories: [
      {
        name: 'Backup',
        events: [
          {
            id: 'trigger:addon-backup:start',
            name: 'Started',
          },
          {
            id: 'trigger:addon-backup:success',
            name: 'Succeeded',
          },
          {
            id: 'trigger:addon-backup:failure',
            name: 'Failed',
          },
          {
            id: 'trigger:addon-backup:abort',
            name: 'Aborted',
          },
        ],
      },
    ],
  },
  {
    name: 'Builds',
    subcategories: [
      {
        name: 'General',
        events: [
          {
            id: 'trigger:build:start',
            name: 'Started',
          },
          {
            id: 'trigger:build:success',
            name: 'Succeeded',
          },
          {
            id: 'trigger:build:failure',
            name: 'Failed',
          },
          {
            id: 'trigger:build:abort',
            name: 'Aborted',
          },
        ],
      },
    ],
  },
  {
    name: 'Jobs',
    subcategories: [
      {
        name: 'Runs',
        events: [
          {
            id: 'trigger:job-run:start',
            name: 'Started',
          },
          {
            id: 'trigger:job-run:success',
            name: 'Succeeded',
          },
          {
            id: 'trigger:job-run:failure',
            name: 'Failed',
          },
          {
            id: 'trigger:job-run:abort',
            name: 'Aborted',
          },
          {
            id: 'trigger:job-run:terminate',
            name: 'Terminated',
          },
        ],
      },
    ],
  },
  {
    name: 'Pipelines',
    subcategories: [
      {
        name: 'Release Flow Runs',
        events: [
          {
            id: 'trigger:release-flow-template-run:start',
            name: 'Started',
          },
          {
            id: 'trigger:release-flow-template-run:update',
            name: 'Updated',
            supportedTypes: ['RAW_WEBHOOK'],
          },
          {
            id: 'trigger:release-flow-template-run:success',
            name: 'Succeeded',
          },
          {
            id: 'trigger:release-flow-template-run:failure',
            name: 'Failed',
          },
          {
            id: 'trigger:release-flow-template-run:aborted',
            name: 'Aborted',
          },
        ],
      },
    ],
  },
  {
    name: 'Templates',
    subcategories: [
      {
        name: 'Runs',
        events: [
          {
            id: 'trigger:template-run:queued',
            name: 'Queued',
          },
          {
            id: 'trigger:template-run:start',
            name: 'Started',
          },
          {
            id: 'trigger:template-run:update',
            name: 'Updated',
            supportedTypes: ['RAW_WEBHOOK'],
          },
          {
            id: 'trigger:template-run:success',
            name: 'Succeeded',
          },
          {
            id: 'trigger:template-run:failure',
            name: 'Failed',
          },
        ],
      },
    ],
  },
  {
    name: 'Domains',
    subcategories: [
      {
        name: 'Certificates',
        events: [
          {
            id: 'trigger:resource:certificate-success',
            name: 'Succeeded',
          },
          {
            id: 'trigger:resource:certificate-final-failure',
            name: 'Failed',
          },
        ],
      },
      {
        name: 'CDN',
        events: [
          {
            id: 'trigger:cdn:action-success',
            name: 'Action Success',
          },
          {
            id: 'trigger:cdn:action-failure',
            name: 'Action Failure',
          },
          {
            id: 'trigger:cdn:action-delay',
            name: 'Action Delay',
          },
        ],
      },
    ],
  },
  {
    name: 'Log Sinks',
    subcategories: [
      {
        name: 'General',
        events: [
          {
            id: 'trigger:log-sink:paused',
            name: 'Paused',
          },
        ],
      },
    ],
  },
  {
    name: 'Billing',
    subcategories: [
      {
        name: 'General',
        events: [
          {
            id: 'trigger:billing:billing-alert-exceeded',
            name: 'Billing Alerts',
          },
        ],
      },
      {
        name: 'Invoices',
        events: [
          {
            id: 'trigger:billing:invoice-payment-action-required',
            name: 'Payment Confirmation Required',
          },
          {
            id: 'trigger:billing:invoice-payment-failed',
            name: 'Charge Declined',
          },
          {
            id: 'trigger:billing:invoice-paid',
            name: 'Paid',
          },
          {
            id: 'trigger:billing:invoice-carried-over',
            name: 'Carried Over',
          },
        ],
      },
    ],
  },
  {
    name: 'Infrastructure',
    subcategories: [
      {
        name: 'Containers',
        events: [
          {
            id: 'trigger:infrastructure:service:container-crash',
            name: 'Service: Container Crashed',
          },
          {
            id: 'trigger:infrastructure:service:container-eviction',
            name: 'Service: Container Evicted',
          },
          {
            id: 'trigger:infrastructure:build:container-eviction',
            name: 'Build: Container Evicted',
          },
          {
            id: 'trigger:infrastructure:addon:container-crash',
            name: 'Addon: Container Crashed',
          },
          {
            id: 'trigger:infrastructure:addon:container-eviction',
            name: 'Addon: Container Evicted',
          },
          {
            id: 'trigger:infrastructure:job:container-crash',
            name: 'Job: Container Crashed',
          },
          {
            id: 'trigger:infrastructure:job:container-eviction',
            name: 'Job: Container Evicted',
          },
        ],
      },
      {
        name: 'CPU',
        events: [
          {
            id: 'trigger:infrastructure:build:container-cpuSpike90',
            name: 'Build CPU: 90% Usage Spike',
          },
          {
            id: 'trigger:infrastructure:build:container-cpuSustained90',
            name: 'Build CPU: 90% Usage Sustained',
          },
          {
            id: 'trigger:infrastructure:service:container-cpuSpike90',
            name: 'Service CPU: 90% Usage Spike',
          },
          {
            id: 'trigger:infrastructure:service:container-cpuSustained90',
            name: 'Service CPU: 90% Usage Sustained',
          },
          {
            id: 'trigger:infrastructure:job:container-cpuSpike90',
            name: 'Job CPU: 90% Usage Spike',
          },
          {
            id: 'trigger:infrastructure:job:container-cpuSustained90',
            name: 'Job CPU: 90% Usage Sustained',
          },
          {
            id: 'trigger:infrastructure:addon:container-cpuSpike90',
            name: 'Addon CPU: 90% Usage Spike',
          },
          {
            id: 'trigger:infrastructure:addon:container-cpuSustained90',
            name: 'Addon CPU: 90% Usage Sustained',
          },
        ],
      },
      {
        name: 'Memory',
        events: [
          {
            id: 'trigger:infrastructure:build:container-memorySpike90',
            name: 'Build Memory: 90% Usage Spike',
          },
          {
            id: 'trigger:infrastructure:build:container-memorySustained90',
            name: 'Build Memory: 90% Usage Sustained',
          },
          {
            id: 'trigger:infrastructure:service:container-memorySpike90',
            name: 'Service Memory: 90% Usage Spike',
          },
          {
            id: 'trigger:infrastructure:service:container-memorySustained90',
            name: 'Service Memory: 90% Usage Sustained',
          },
          {
            id: 'trigger:infrastructure:job:container-memorySpike90',
            name: 'Job Memory: 90% Usage Spike',
          },
          {
            id: 'trigger:infrastructure:job:container-memorySustained90',
            name: 'Job Memory: 90% Usage Sustained',
          },
          // {
          //   id: 'trigger:infrastructure:addon:container-memorySpike90',
          //   name: 'Addon Memory: 90% Usage Spike',
          // },
          // {
          //   id: 'trigger:infrastructure:addon:container-memorySustained90',
          //   name: 'Addon Memory: 90% Usage Sustained',
          // },
        ],
      },
      {
        name: 'Volumes',
        events: [
          {
            id: 'trigger:infrastructure:addon-volume:usage-75-exceeded',
            name: 'Addon Volume: 75% Usage Exceeded',
          },
          {
            id: 'trigger:infrastructure:addon-volume:usage-90-exceeded',
            name: 'Addon Volume: 90% Usage Exceeded',
          },
          {
            id: 'trigger:infrastructure:platform-volume:usage-75-exceeded',
            name: 'Platform Volume: 75% Usage Exceeded',
          },
          {
            id: 'trigger:infrastructure:platform-volume:usage-90-exceeded',
            name: 'Platform Volume: 90% Usage Exceeded',
          },
        ],
      },
      {
        name: 'Cluster',
        events: [
          {
            id: 'trigger:infrastructure:byoc:cluster:error',
            name: 'Cluster Provisioning Error',
          },
          {
            id: 'trigger:infrastructure:byoc:node-pool:error',
            name: 'Node pool Provisioning Error',
          },
          {
            id: 'trigger:infrastructure:byoc:scheduling:error',
            name: 'Container Scheduling Error',
          },
        ],
      },
    ],
  },
];

export const runOnSourceChangeOptions = [
  {
    value: 'never',
    label: 'Never',
    description: ['The job will never be triggered automatically on image change.'],
  },
  {
    value: 'cd-promote',
    label: 'CD & Pipeline Promotion',
    description: [
      'The job will be triggered, if a build finishes and CD is enabled, or on pipeline promotion.',
    ],
  },
  {
    value: 'always',
    label: 'Always',
    description: [
      'The job will be triggered when an image is deployed via the UI, if a build finishes and CD is enabled, or on pipeline promotion.',
    ],
  },
];

export const runOnSourceChangeOptionMap = runOnSourceChangeOptions.reduce(
  (a, i) => ({
    [i.value]: i.label,
    ...a,
  }),
  {}
);

export const runOnSourceChangeKeys = runOnSourceChangeOptions.map((o) => o.value);

export const objectCreationKeys = [
  {
    key: 'teams',
    label: 'Teams',
  },
  {
    key: 'projects',
    label: 'Projects',
  },
  {
    key: 'services',
    label: 'Services',
  },
  {
    key: 'addons',
    label: 'Addons',
  },
  {
    key: 'jobs',
    label: 'Jobs',
  },
  {
    key: 'platform-volumes',
    label: 'Platform Volumes',
  },
  {
    key: 'project-secrets',
    label: 'Project Secrets',
  },
  {
    key: 'pipelines',
    label: 'Pipelines',
  },
  {
    key: 'domains',
    label: 'Domains',
  },
  {
    key: 'subdomains',
    label: 'Subdomains',
  },
  {
    key: 'entity-docker-credentials',
    label: 'Docker Credentials',
  },
  {
    key: 'notification-integrations',
    label: 'Notification Integrations',
  },
  {
    key: 'billing-alerts',
    label: 'Billing Alerts',
  },
  {
    key: 'api-tokens',
    label: 'API Tokens',
  },
  {
    key: 'api-token-templates',
    label: 'API Roles',
  },
  {
    key: 'team-invitations',
    label: 'Team Invitations',
  },
];

export const DatadogRegionOptions = [
  { label: 'EU', value: 'eu' },
  { label: 'US1', value: 'us' },
  { label: 'US3', value: 'us3' },
  { label: 'US5', value: 'us5' },
];

export const DatadogRegionSiteMap = {
  eu: 'datadoghq.eu',
  us: 'datadoghq.com',
  us3: 'us3.datadoghq.com',
  us5: 'us5.datadoghq.com',
};

export const LogzioRegionOptions = [
  { label: 'EU-West-2', value: 'uk' },
  { label: 'US-East-1', value: 'us' },
  { label: 'EU-Central-1', value: 'eu' },
  { label: 'AP-Southeast-2', value: 'au' },
  { label: 'CA-Central-1', value: 'ca' },
  { label: 'West-Europe', value: 'nl' },
  { label: 'West-US', value: 'wa' },
];

export const LogzioRegionUriMap = {
  uk: 'listener-uk.logz.io',
  us: 'listener.logz.io',
  eu: 'listener-eu.logz.io',
  au: 'listener-au.logz.io',
  ca: 'listener-ca.logz.io',
  nl: 'listener-nl.logz.io',
  wa: 'listener-wa.logz.io',
};

export const CoralogixRegions = [
  { label: 'EU', value: 'eu' },
  { label: 'IN', value: 'in' },
  { label: 'USA', value: 'usa' },
  { label: 'EU2', value: 'eu2' },
  { label: 'SG', value: 'sg' },
];

export const CoralogixRegionSiteMap = {
  eu: 'coralogix.com',
  in: 'app.coralogix.in',
  usa: 'coralogix.us',
  eu2: 'eu2.coralogix.com',
  sg: 'oralogixsg.com',
};

export const AWSRegionOptions = [
  {
    label: 'EU-West-1',
    value: 'eu-west-1',
  },
  { label: 'EU-West-2', value: 'eu-west-2' },
  { label: 'EU-West-3', value: 'eu-west-3' },
  { label: 'EU-Central-1', value: 'eu-central-1' },
  { label: 'EU-South-1', value: 'eu-south-1' },
  { label: 'EU-North-1', value: 'eu-north-1' },
  { label: 'US-West-1', value: 'us-west-1' },
  { label: 'US-West-2', value: 'us-west-2' },
  { label: 'US-East-1', value: 'us-east-1' },
  { label: 'US-East-2', value: 'us-east-2' },
];

export const AxiomTokenTypes = [
  { label: 'API', value: 'api' },
  { label: 'Personal', value: 'personal' },
];

export const logSinkTypeLabels = {
  loki: 'Loki',
  datadog: 'Datadog',
  datadog_logs: 'Datadog',
  papertrail: 'Papertrail',
  http: 'HTTP',
  aws_s3: 'AWS S3',
  logdna: 'LogDNA',
  betterStack: 'Better Stack',
  logtail: 'Logtail',
  honeycomb: 'Honeycomb',
  logzio: 'Logz.io',
  axiom: 'Axiom',
  newRelic: 'New Relic',
};

export const logSinkStates = {
  running: 'running',
  creating: 'creating',
  paused: 'paused',
  failing: 'failing',
};

export const logSinkActions = {
  remove: 'remove',
  update: 'update',
  add: 'add',
  pause: 'pause',
  resume: 'resume',
};

export const ssoProviders = ['google', 'github', 'bitbucket', 'gitlab', 'workos'];

export const deployableBYOCClusterStates = ['ready', 'updating'];

export const dockerOverrideTypes = [
  'default',
  'customEntrypoint',
  'customCommand',
  'customEntrypointCustomCommand',
];

export const buildpackConfigTypes = [
  'default',
  'customProcess',
  'customCommand',
  'customEntrypointCustomCommand',
  'originalEntrypointCustomCommand',
];

export const maxInvoiceCCAddresses = 5;

export const defaultCIIgnoreFlags = [
  '[skip ci]',
  '[ci skip]',
  '[no ci]',
  '[skip nf]',
  '[nf skip]',
  '[northflank skip]',
  '[skip northflank]',
];

export const deploymentStrategies = [
  {
    value: 'recreate',
    label: 'Recreate',
    description:
      'Required for services with attached volumes. Removes the previous deployment before starting a new one.',
    isDisabled: true,
  },
  {
    value: 'rollout-steady',
    label: 'Steady Rollout',
    description: 'Will slowly replace running instances with the new deployment.',
    settings: {
      maxSurge: '25%',
      maxUnavailable: '0%',
    },
  },
  {
    value: 'rollout-balanced',
    label: 'Balanced Rollout',
    description:
      'Faster than a steady rollout, with a percentage of instances becoming unavailable during rollout.',
    settings: {
      maxSurge: '50%',
      maxUnavailable: '25%',
    },
  },
  {
    value: 'rollout-fast',
    label: 'Fast Rollout',
    description:
      'Will spawn a full set of new instances immediately. Fastest option, with a full set of running instances.',
    settings: {
      maxSurge: '100%',
      maxUnavailable: '0%',
    },
  },
];

export const emptySHA = '0000000000000000000000000000000000000000';

export const topologyKeys = [
  {
    value: 'kubernetes.io/hostname',
    label: 'hostname',
    description: 'Unique to a node. This restriction will spread workloads over different nodes.',
  },
];

export const topologyKeyValues = topologyKeys.map((t) => t.value);
export const sidecarProxyMode = ['istio', 'linkerd', 'none'];

export type SidecarProxyMode = 'linkerd' | 'istio' | 'none';

export enum DomainRedirectModes {
  DEFAULT = 'default',
  WILDCARD = 'wildcard',
}

export enum DomainCertificateModes {
  DEFAULT = 'default',
  WILDCARD = 'wildcard',
  WILDCARD_IMPORT = 'wildcard-import',
}

export enum SubdomainRedirectModes {
  DEFAULT = 'default',
  WILDCARD = 'wildcard',
}

// Time, in seconds, before VCS OAuth token expiry when the token should be refreshed.
export const refreshTokenBuffer = 600;

export const addonZonalRedundancyEnabledOptions = ['required'];
export const addonZonalRedundancyOptions = [...addonZonalRedundancyEnabledOptions, 'disabled'];

export enum ZonalRedundancyTypes {
  DISABLED = 'disabled',
  PREFERRED = 'preferred',
  REQUIRED = 'required',
}

export const serviceZonalRedundancyFormOptions = [
  {
    value: ZonalRedundancyTypes.DISABLED,
    label: 'Disabled',
    description: ['There is no requirement for this workload to be distributed across zones.'],
  },
  {
    value: ZonalRedundancyTypes.PREFERRED,
    label: 'Preferred',
    description: [
      'There is no hard requirement for this workload to be distributed across zones. Distribution will be on a best effort basis.',
    ],
  },
  {
    value: ZonalRedundancyTypes.REQUIRED,
    label: 'Required',
    description: [
      'There is a hard requirement for this workload to be distributed across zones. Containers will only be scheduled if there is capacity in the relevant zone.',
    ],
  },
];

export const serviceZonalRedundancyOptions = serviceZonalRedundancyFormOptions.map((o) => o.value);

export enum V1EntityTypes {
  USER = 'user',
  TEAM = 'team',
  ORG = 'org',
}

// 30 days
export const MAXIMUM_TOKEN_EXPIRY_IN_HOURS = 30 * 24;

// One hour
export const MINIMUM_TOKEN_EXPIRY_IN_HOURS = 1;

// One week
export const DEFAULT_TOKEN_EXPIRY_IN_HOURS = 7 * 24;

// Transform hours into ms
export const transformTokenExpiryInput = (input) => input * 60 * 60 * 1000;

// One week
export const DEFAULT_TOKEN_EXPIRY_IN_MS = transformTokenExpiryInput(DEFAULT_TOKEN_EXPIRY_IN_HOURS);

export enum ClusterFeatureFlags {
  STORAGE = 'storage',
  SNAPSHOTS = 'snapshots',
}

export type ClusterFeatureFlag = keyof typeof ClusterFeatureFlags;

export enum AwsVPCMode {
  DEFAULT_SUBNETS = 'default-subnets-for-azs',
  EXPLICIT_SUBNETS = 'explicit-subnets',
}

export const awsVpcModeOptions = [
  {
    label: 'Default VPC',
    value: AwsVPCMode.DEFAULT_SUBNETS,
  },
  {
    label: 'Custom VPC',
    value: AwsVPCMode.EXPLICIT_SUBNETS,
    feature: 'byoc-custom-vpc',
  },
];

export enum GetOutputModeOptions {
  DUPLICATE = 'duplicate',
}

export enum BuildSourceTypes {
  GIT = 'git',
  BUNDLE = 'bundle',
}

export enum TemplateDraftStates {
  OPEN = 'open',
  CLOSED = 'closed',
  ACCEPTED = 'accepted',
}

export const templateAPIVersion = 'v1.2';

export enum ResourcePlanTypes {
  NF = 'northflank',
  K8S = 'kubernetes',
}

export enum TagMatchConditionTypes {
  OR = 'or',
  AND = 'and',
}

export const exampleDateString = '2000-01-01T12:00:00.000Z';

export const minimumConditionTimeoutDuration = 30;
export const maximumConditionTimeoutDuration = 14400;

export const reservedPlanPrefix = 'nf-';

export const previewNameOptions = [
  { value: 'PR-${args.pullRequestId}', label: 'Pull request ID', description: 'pr-1234' },
  {
    value: '${fn.slice(args.branch, 0, 20)}',
    label: 'Branch name',
    description: 'feature-my-branch',
  },
  { value: "${fn.date('YYYY-MM-DD-HHmm')}", label: 'Timestamp', description: '24-01-31-1301' },
  { value: '', label: 'Random words', description: 'general-question' },
];
