import React from 'react';

import Box from '@northflank/components/Box';
import Heading from '@northflank/components/Heading';
import Text from '@northflank/components/Text';

import PreHeading from './PreHeading';

const DevExpSection = () => (
  <Box>
    <Box width={[1, 1, 1 / 2]} textAlign="center" mx="auto" mb={[12, 12, 13]}>
      <PreHeading mb={6}>Optimised for Developer Happiness</PreHeading>
      <Heading fontSize={[5, 5, '32px']} color="grey1" mb={8}>
        A developer experience like no other
      </Heading>
      <Text fontSize="18px" color="grey2">
        Northflank simplifies complex infrastructure, providing a robust control
        plane with real-time updates. Access controls are intuitive, and
        everything is designed to keep developers focused on what they do best:
        writing code.
      </Text>
    </Box>
    <Box
      border="1px solid"
      borderColor="grey5"
      borderRadius={3}
      boxShadow="dropDown"
      overflow="hidden"
      _css={{
        aspectRatio: '7 / 2',
      }}
    >
      <Box
        as="img"
        src="/images/solutions/use-cases/dashboard.png"
        width="100%"
      />
    </Box>
  </Box>
);

export default DevExpSection;
