import React from 'react';

import Legal from '@northflank/components/marketing/Legal';
import SEO from '@northflank/components/marketing/SEO';

import cookies from './content.md';

const TermsOfService = () => (
  <>
    <SEO title="Cookie Policy" />
    <Legal
      title="Cookies"
      description={`Northflank's Cookie Policy`}
      html={cookies.html}
    />
  </>
);

export default TermsOfService;
