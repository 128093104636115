import {
  nameMinLength,
  nameMaxLength,
  newNameMaxLength,
  descriptionMinLength,
  descriptionMaxLength,
  minContainerPort,
  maxContainerPort,
  portNameMinLength,
  portNameMaxLength,
  longNameMaxLength,
  forbiddenPortNumbers,
  dynamicLengthNameMaxLength,
  dynamicLengthJobNameMaxLength,
} from '../data';

export const textError = `Please only use letters, numbers, and punctuation`;

export const nameError = `Names must start with a letter and only contain letters and numbers separated by a single space or hyphen`;
export const displayNameError = `Names must start with a letter and only contain letters and numbers separated by a single space, hyphen or apostrophe`;

export const sluggedNameError = `Names must start with a letter and only contain letters and numbers separated by a single hyphen`;

export const alphanumericNameError = `Names must only contain letters and numbers separated by a single space or hyphen`;

export const backupNameError = `Names must only contain letters and numbers separated by a single space or hyphen / or :`;

export const nameLengthError = `Please choose a name between ${nameMinLength} and ${nameMaxLength} characters`;
export const dynamicNameLengthError = `Please choose a name between ${nameMinLength} and ${dynamicLengthNameMaxLength} characters`;
export const dynamicJobNameLengthError = `Please choose a name between ${nameMinLength} and ${dynamicLengthJobNameMaxLength} characters`;

export const newNameLengthError = `Please choose a name between ${nameMinLength} and ${newNameMaxLength} characters`;

export const longNameLengthError = `Please choose a name between ${nameMinLength} and ${longNameMaxLength} characters`;

export const portNameLengthError = `Please choose a name between ${portNameMinLength} and ${portNameMaxLength} characters`;

export const descriptionLengthError = `Please choose a description between ${descriptionMinLength} and ${descriptionMaxLength} characters`;

export const forbiddenPortError = `Port may not be one of ${JSON.stringify(forbiddenPortNumbers)}`;
export const containerPortError = `Please choose a port between ${minContainerPort} and ${maxContainerPort}`;
export const containerPortErrorMinShort = `Must be at least ${minContainerPort}`;
export const containerPortErrorMaxShort = `Must be at most ${maxContainerPort}`;

export const invalidJWTAuthErrorMessage = 'Verification code invalid';
