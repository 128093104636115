import { GoogleCloud } from '@styled-icons/boxicons-logos/GoogleCloud';
import { Chip } from '@styled-icons/boxicons-regular/Chip';
import { Amazonaws } from '@styled-icons/simple-icons/Amazonaws';
import { Civo } from '@styled-icons/simple-icons/Civo';
import { Microsoftazure } from '@styled-icons/simple-icons/Microsoftazure';
import { Oracle } from '@styled-icons/simple-icons/Oracle';

import NorthflankIcon from '@northflank/components/NorthflankIcon';

export const cloudProviderOptions = [
  {
    value: 'gcp',
    label: 'Google Cloud Platform',
    link: '/cloud/gcp',
    icon: GoogleCloud,
    image: 'https://assets.northflank.com/GCP_64487e646f.png',
    footerText: 'Google Cloud Platform on Northflank',
    engine: 'Google Kubernetes Engine (GKE)',
  },
  {
    value: 'aws',
    label: 'Amazon Web Services',
    link: '/cloud/aws',
    icon: Amazonaws,
    image: 'https://assets.northflank.com/AWS_9fc5766266.png',
    footerText: 'Amazon Web Services on Northflank',
    engine: 'Elastic Kubernetes Service (EKS)',
  },
  {
    value: 'azure',
    label: 'Microsoft Azure',
    link: '/cloud/azure',
    icon: Microsoftazure,
    image: 'https://assets.northflank.com/Azure_c866a5658c.png',
    footerText: 'Microsoft Azure on Northflank',
    engine: 'Azure Kubernetes Service (AKS)',
  },
  {
    value: 'civo',
    label: 'Civo',
    link: '/cloud/civo',
    icon: Civo,
    image: 'https://assets.northflank.com/Civo_5e0a66928b.png',
    footerText: 'Civo on Northflank',
    engine: 'Civo Kubernetes',
  },
  {
    value: 'oci',
    label: 'Oracle',
    link: '/cloud/oci',
    icon: Oracle,
    image: 'https://assets.northflank.com/Oracle_bfb4b52321.png',
    footerText: 'Oracle on Northflank',
    engine: 'Oracle Kubernetes Engine OKE',
  },
  {
    value: 'northflank',
    label: 'Northflank',
    link: '/cloud/northflank',
    icon: NorthflankIcon,
  },
  {
    value: 'gpus',
    label: 'GPUs',
    link: '/cloud/gpus',
    icon: Chip,
    footerText: 'GPUs on Northflank',
  },
];
