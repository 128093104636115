import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { LeftArrowAlt } from '@styled-icons/boxicons-regular/LeftArrowAlt';
import { RightArrowAlt } from '@styled-icons/boxicons-regular/RightArrowAlt';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Grid from '@northflank/components/Grid';
import { Lightbox } from '@northflank/components/marketing/MarkdownBody';

const LargeCarousel = ({ images = [] }) => {
  const [items, setItems] = useState(images);
  const [lightbox, setLightbox] = useState();

  const displaying = items.slice(0, 3);

  return (
    <Box>
      <Helmet>
        {images.map((src) => (
          <link key={src} as="image" href={src} rel="preload" />
        ))}
      </Helmet>
      <Box
        variant="flex"
        justifyContent="center"
        overflowX="hidden"
        px={[10, 10, 12]}
        mb={10}
      >
        <Grid
          gridTemplateColumns={['1fr', '1fr', '1fr 1.33fr 1fr']}
          gridGap={8}
          alignItems="center"
          minWidth={[0, 0, '2200px']}
        >
          {displaying.map((src, i) => (
            <Box
              key={src}
              as="img"
              src={src}
              width={['100%', '100%', 'auto']}
              height={i === 1 ? ['auto', 'auto', '650px'] : '400px'}
              border="1px solid"
              borderColor="grey5"
              borderRadius={3}
              boxShadow="dropDown"
              justifySelf={i === 0 ? 'end' : i === 1 ? 'center' : 'start'}
              display={i === 1 ? 'block' : ['none', 'none', 'block']}
              onClick={
                i === 1
                  ? () => {
                      setLightbox(src);
                    }
                  : undefined
              }
              _css={{ cursor: i === 1 ? 'pointer' : 'default' }}
            />
          ))}
        </Grid>
      </Box>
      <Box variant="flex" alignItems="center" justifyContent="center">
        <Button
          onClick={() => {
            setItems((c) => {
              const cur = [...c];
              const last = cur.pop();
              cur.unshift(last);
              return cur;
            });
          }}
          mr={6}
        >
          <LeftArrowAlt size={24} />
        </Button>
        <Button
          onClick={() => {
            setItems((c) => {
              const cur = [...c];
              const first = cur.shift();
              cur.push(first);
              return cur;
            });
          }}
        >
          <RightArrowAlt size={24} />
        </Button>
      </Box>
      {lightbox && (
        <Lightbox
          image={lightbox}
          close={() => {
            setLightbox(undefined);
          }}
        />
      )}
    </Box>
  );
};

export default LargeCarousel;
