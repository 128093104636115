import React from 'react';

import styled, { keyframes } from 'styled-components';

import Box from '@northflank/components/Box';

const scroll = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
`;

const StyledMarqueeContent = styled(Box)`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 100%;
  gap: var(--gap);
  animation: ${scroll} ${(props) => props.time} linear infinite;
  animation-direction: ${(props) => (props.reverse ? 'reverse' : 'normal')};

  > * {
    max-width: ${(props) => props.fixedWidth ?? 'none'};
    width: ${(props) => (props.fixedWidth ? 'calc(100vw - 100px)' : 'auto')};
  }
`;

const StyledMarquee = styled(Box)`
  --gap: ${(props) => props.gap};
  display: flex;
  overflow: hidden;
  gap: var(--gap);

  &:hover {
    ${StyledMarqueeContent} {
      animation-play-state: paused;
    }
  }
`;

const Marquee = ({
  children,
  gap = '24px',
  fixedWidth,
  time = '60s',
  reverse,
  ...rest
}) => (
  <StyledMarquee gap={gap} {...rest}>
    <StyledMarqueeContent fixedWidth={fixedWidth} time={time} reverse={reverse}>
      {children}
    </StyledMarqueeContent>
    <StyledMarqueeContent
      fixedWidth={fixedWidth}
      time={time}
      reverse={reverse}
      aria-hidden
    >
      {children}
    </StyledMarqueeContent>
  </StyledMarquee>
);

export default Marquee;
