import React from 'react';

import { Globe } from '@styled-icons/boxicons-regular/Globe';
import { LinkExternal } from '@styled-icons/boxicons-regular/LinkExternal';

import Box from '@northflank/components/Box';
import Icon from '@northflank/components/Icon';
import Text from '@northflank/components/Text';

import WrapLink from '../../WrapLink';

const DisplayRegion = ({ region, option }) => {
  const { id, name } = region;

  return (
    <Box
      p={3}
      mb={2}
      _css={{ breakInside: 'avoid-column' }}
      variant={option?.link && 'hover'}
      borderRadius={2}
    >
      <WrapLink link={option?.link ? `${option.link}/regions/${id}` : false}>
        <Box variant="flex">
          <Icon
            Content={Globe}
            Size={12}
            color="grey3"
            alignSelf="flex-start"
            pt={3}
            mr={3}
          />
          <Box>
            <Box variant="flex">
              <Text color="grey1">{id}</Text>
              {option?.link ? (
                <Icon Content={LinkExternal} Size={10} color="grey3" ml={3} />
              ) : null}
            </Box>
            {id !== name && (
              <Text fontSize={0} color="grey2">
                {name}
              </Text>
            )}
          </Box>
        </Box>
      </WrapLink>
    </Box>
  );
};

export default DisplayRegion;
