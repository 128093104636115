import { Transfer } from '@styled-icons/boxicons-regular/Transfer';
import isEqual from 'lodash/isEqual';

export const Sizing = {
  reduced: '50px 0.7fr 0.6fr 0.6fr 30px',
  full: '50px 0.6fr 0.6fr 0.6fr 2fr 0.5fr 30px',
  view: '50px 0.6fr 0.6fr 0.6fr 2fr 1fr 30px',
  dashboard: '50px 60px 60px 60px 1fr 0.5fr',
};

export const publicPortProtocolOptions = [
  { options: [{ label: 'HTTP', value: 'HTTP' }] },
  { options: [{ label: 'HTTP/2', value: 'HTTP/2' }] },
  {
    label: 'Private only',
    options: [
      { label: 'TCP', value: 'TCP', disabled: true },
      { label: 'UDP', value: 'UDP', disabled: true },
    ],
  },
];
export const privatePortProtocolOptions = [
  {
    label: 'HTTP',
    value: 'HTTP',
    description: ['HTTP/1.1 connectivity for common web servers'],
    icon: Transfer,
  },
  {
    label: 'HTTP/2',
    value: 'HTTP/2',
    description: ['HTTP/2 connectivity for modern web servers and GRPC'],
    icon: Transfer,
  },
  {
    label: 'TCP',
    value: 'TCP',
    description: ['TCP connectivity for common applications'],
    icon: Transfer,
  },
  {
    label: 'UDP',
    value: 'UDP',
    description: ['UDP connectivity for VoIP, Multiplayer and DNS'],
    icon: Transfer,
  },
];

// Maps through domains, extracting name and id
export const getDomainOptions = (domains) =>
  domains.map((d) => ({ label: d.fullName, value: d._id }));

// Takes ports and removes dns information
export const formatPorts = (ports) =>
  ports.map(({ dns, ...rest }, i) => ({ ...rest, renderKey: `initial${i}` }));

// Maps through all entries checking for a duplicate in a field
export const checkDuplicateFields =
  (field, ref, index, additionalFields = []) =>
  (value) => {
    const entries = ref.current || [];

    const element = entries.find((p, i) => i === index);

    if (!value) return false;
    // Check if there is a duplicate value
    const dupe = entries.find((p, i) => {
      if (i === index) return false;
      if (!p[field]) return false;
      if (p[field] === value) {
        if (additionalFields.length === 0) return true;
        return additionalFields.every((f) => isEqual(element[f], p[f]));
      }
      return false;
    });
    if (dupe) return 'Must be unique across all entries';
    return undefined;
  };
