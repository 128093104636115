import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import Box from '@northflank/components/Box';
import Text from '@northflank/components/Text';

import { TableRow } from '../CloudProviderNodeTable';
import OciNote from '../OciNote';
import CloudProviderTableGpu from '../TableComponents/CloudProviderTableGPU';
import CloudProviderTableNode from '../TableComponents/CloudProviderTableNode';
import CloudProviderTableProvider from '../TableComponents/CloudProviderTableProvider';

const getTableColumns = ({ showProvider, providerId, path, isMobile }) => {
  const cols = [];

  if (showProvider) {
    cols.push({
      Header: 'Provider',
      accessor: 'provider',
      Cell: ({ value }) => <CloudProviderTableProvider value={value} />,
      gridWidth: isMobile ? '1.5fr' : '2fr',
    });
  }

  cols.push(
    {
      Header: 'Node type',
      accessor: 'id',
      Cell: ({ value, row: { original } }) => (
        <CloudProviderTableNode
          providerId={original.provider}
          value={value}
          original={original}
        />
      ),
      gridWidth: isMobile ? '3fr' : '5fr',
    },
    {
      Header: 'GPU',
      accessor: 'resources.gpuInfo.gpus',
      Cell: ({ value }) => (
        <CloudProviderTableGpu
          value={value || false}
          isMobile={isMobile}
          path={path}
        />
      ),
      gridWidth: '5fr',
    },
    {
      Header: 'vCPU',
      accessor: 'resources.vcpu',
      Cell: ({ value }) => `${value % 1 === 0 ? value : value.toFixed(2)} vCPU`,
      gridWidth: '1.5fr',
    }
  );

  if (providerId !== 'civo') {
    cols.push({
      Header: 'Memory',
      accessor: 'resources.memory',
      Cell: ({ value }) => `${value % 1 === 0 ? value : value.toFixed(2)} GB`,
      gridWidth: '1.5fr',
    });
  } else {
    cols.push({
      Header: 'Memory',
      accessor: 'resources.memory',
      Cell: ({ value }) => `${value % 1 === 0 ? value : value.toFixed(0)} GB`,
      gridWidth: '1.5fr',
    });
  }

  if (providerId !== 'gcp' && providerId !== 'oci') {
    cols.push({
      Header: 'Disk',
      accessor: 'resources.disk',
      Cell: ({ value }) => {
        if (value?.size) {
          return `${parseFloat(value.size.toPrecision(1))} GB${value.disk ? ` ${value.disk}` : ''}`;
        }
        return <Text color="grey3">—</Text>;
      },
      gridWidth: '1.5fr',
    });
  }

  return cols;
};

const CloudProviderGpuTable = ({
  nodes,
  isMobile,
  providerId,
  showProvider,
  ...rest
}) => {
  const { pathname } = useLocation();
  const path = pathname.split('/');

  const columns = useMemo(() => {
    return getTableColumns({ showProvider, providerId, path, isMobile });
  }, [nodes, isMobile, providerId]);

  return (
    <Box {...rest}>
      {providerId === 'oci' ? <OciNote gpu /> : null}
      <Box
        bg="grey9"
        display="grid"
        gridTemplateColumns={columns.map((col) => col.gridWidth).join(' ')}
        gridGap={6}
        border="1px solid"
        borderColor="grey6"
        borderRadius={1}
        px={8}
        py={7}
      >
        {columns.map((col) => (
          <Text
            key={col.accessor}
            fontFamily="monospace"
            fontSize={1}
            color="grey3"
          >
            {col.Header}
          </Text>
        ))}
      </Box>
      <Box as="ul" _css={{ listStyle: 'none' }}>
        {nodes.map((node) => (
          <TableRow key={node.id} item={node} columns={columns} />
        ))}
      </Box>
    </Box>
  );
};

export default CloudProviderGpuTable;
