import React from 'react';

import PropTypes from 'prop-types';

import Box from '@northflank/components/Box';
import FieldLabel from '@northflank/components/FieldLabel';
import Grid from '@northflank/components/Grid';
import SelectCard from '@northflank/components/SelectCard';

const SelectCardList = ({
  title = '',
  cards,
  highlightIcon = false,
  handleSelect,
  selected = '',
  gridTemplateColumns = ['1fr', '1fr', 'repeat(3, 1fr)'],
  gridGap = 8,
  disabled = false,
  getFullValue = false,
  ...rest
}) => (
  <Box {...rest}>
    {title && <FieldLabel mb={6}>{title}</FieldLabel>}
    <Grid
      gridTemplateColumns={gridTemplateColumns}
      gridGap={gridGap}
      alignItems="stretch"
    >
      {cards.map((o) => (
        <SelectCard
          {...o}
          key={o.key}
          handleSelect={() => handleSelect(getFullValue ? o : o.key)}
          selected={o.key === selected}
          disabled={disabled || o.disabled}
          highlightIcon={highlightIcon}
        />
      ))}
    </Grid>
  </Box>
);

SelectCardList.propTypes = {
  title: PropTypes.string,
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
  gridTemplateColumns: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  highlightIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  handleSelect: PropTypes.func.isRequired,
  selected: PropTypes.string,
  getFullValue: PropTypes.bool,
};

export default SelectCardList;
