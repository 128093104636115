import { vatRates } from '../data';

export const uuidV4Regex = new RegExp(
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89ABab][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/
);

export const alphanumericRegex = new RegExp(/^[a-zA-Z0-9]+$/); // Matches alphanumeric only, no spaces or other characters

export const mailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const k8sMaxLabelLength = 63;
// export const k8sLabelNameRegex = /^([a-z0-9A-Z]+(.|-|_)?)+$/;

// copied 1:1 from k8s
export const k8sLabelNameRegex = /^([A-Za-z0-9][-A-Za-z0-9_.]*)?[A-Za-z0-9]$/;
// the below is slightly modified from k8s, as that also allows empty values
// export const k8sLabelValueRegex = /^([A-Za-z0-9][-A-Za-z0-9_.]*)?[A-Za-z0-9]$/;

export const k8sLabelRegexError =
  "Must consist of alphanumeric characters, '-', '_' or '.', and must start and end with an alphanumeric character";

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/gm; // Must be 10 characters and contain an uppercase letter, lowercase letter, and a number

export const slugRegex = /^[a-z]-?[a-z0-9]+(-[a-z0-9]+)*$/; // Matches lowercase alphanumeric separated by hyphens
export const baseInternalIdRegex = /[a-zA-Z](-?[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*)?/; // Matches alphanumeric (including uppercase) separated by hyphens
export const sluggedNameRegex = new RegExp(`^${baseInternalIdRegex.source}$`); // Matches alphanumeric (including uppercase) separated by hyphens

export const internalIdRegex = new RegExp(sluggedNameRegex);

export const usernameRegex = sluggedNameRegex;
export const sluggedAlphanumericNameRegex = /^[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$/; // Also allows names to begin with a number
export const nodePoolIdRegex = new RegExp(`(${sluggedNameRegex.source})|(${uuidV4Regex.source})`);

export const usernameOrEmailRegex = new RegExp(`(${usernameRegex.source})|(${mailRegex.source})`); // Matches any text excluding brackets, commas, semi-colons, quote-marks and whitespace
export const nameRegex = /^[a-zA-Z]((-|\s)?[a-zA-Z0-9]+((-|\s)[a-zA-Z0-9]+)*)?$/; // Matches alphanumeric separated by spaces or hyphens, must start with a letter
export const displayNameRegex =
  /^[a-zA-Z](('[a-zA-Z])?(-|\s)?[a-zA-Z0-9]+(('[a-zA-Z])?(-|\s)[a-zA-Z0-9]+)*)?$/;

export const alphanumericNameRegex = /^[a-zA-Z0-9]+((-|\s)[a-zA-Z0-9]+)*$/; // Matches alphanumeric separated by spaces or hyphens

export const backupNameRegex = /^[a-zA-Z0-9]+((-|\s|\/|:)[a-zA-Z0-9]+)*$/;

export const descriptionRegex = /^[a-zA-Z0-9.,?\s\\/'"()[\];`%^&*\-_:!]+$/; // Matches alphanumeric and punctuation

export const appIdRegex = /^\/[a-z]-?[a-zA-Z0-9-]+\/[a-zA-Z0-9-]+\/[a-zA-Z0-9-]+$/; // Matches a filepath with alphanumeric characters and three slashes (e.g. /here/we/are)

export const noFirstNumberRegex = /^\D.*/; // matches if first char is not a digit

const subdomainVar = '([a-zA-Z0-9-]{2,}\\.)';
const portVar = '([a-zA-Z0-9-]{2,}\\-)';
const dnsRegexVar = `^${subdomainVar}{2,}[a-zA-Z]{2,}$`;
const previewDNSRegexVar = `^${portVar}*(\\$identifier\\.)${subdomainVar}{2,}([a-zA-Z]{2,})$`;

// TODO: support wildcard?
export const dnsRegex = new RegExp(dnsRegexVar);
export const previewDNSRegex = new RegExp(previewDNSRegexVar);
export const previewOrNormalDNSRegex = new RegExp(`(${dnsRegexVar})|(${previewDNSRegexVar})`);

export const domainRegex =
  /^((www(\.[a-zA-Z0-9\-]{2,})+\.)?[a-zA-Z0-9\-]{2,})(\.([a-zA-Z0-9\-]{2,}))+$/;

export const subdomainRegex =
  /^\*|^@$|^([0-9a-z]([0-9a-z\-]*[0-9a-z])?\.)*[0-9a-z]([0-9a-z\-]*[0-9a-z])?$/;

export const fqdnRegex = /^(([a-z0-9][a-z0-9\-]*)|[a-z0-9]\.)*([a-z]+|xn\-\-[a-z0-9]+)\.?$/;

// https://docs.docker.com/engine/reference/commandline/tag/
export const imagePathRegex =
  /^[a-z0-9]+((\.|(_{1,2})|(-+))[a-z0-9]+)*(\/[a-z0-9]+((\.|(_{1,2})|(-+))[a-z0-9]+)*)*((:[a-zA-Z0-9_][a-zA-Z0-9_.-]{0,127})|(@[a-z0-9]+:[0-9a-f]+))$/;

// TODO: try to unify the different schemas
// Used to extract different parts of the hostname
export const imageHostnameRegex =
  /^(?:(?:https?:\/\/)?([a-zA-Z0-9\-]+\.[a-zA-Z0-9\.\-]+)(\/v1)?)?(?:\/)?([a-zA-Z/-9\.\-_]+)(?:\:([a-zA-Z/-9\.\-_\:]+)|\@([a-zA-Z/-9\.\-_\:]+))$/;

// https://regex101.com/r/hP8bK1/1
// Used to actually validate the hostname
export const secondImageHostnameRegex = imageHostnameRegex;
// /^(?:(?=[^:\/]{4,253})(?!-)[a-zA-Z0-9-]{1,63}(?<!-)(?:\.(?!-)[a-zA-Z0-9-]{1,63}(?<!-))*(?::[0-9]{1,5})?\/)?((?![._-])(?:[a-z0-9._-]*)(?<![._-])(?:\/(?![._-])[a-z0-9._-]*(?<![._-]))*)(?::(?![.-])[a-zA-Z0-9_.-]{1,128})?$/;

export const urlRegex =
  /^(https:\/\/)?((www(\.[a-zA-Z0-9\-]{2,})+\.)?[a-zA-Z0-9\-]{2,})(\.([a-zA-Z0-9\-]{2,}))+\/?$/;

export const vcsUrlRegex = urlRegex;

// Very permissive url regex to allow localhost etc.
export const customVCSUrlRegex = /^https?:\/\/[a-zA-Z0-9\-]+(\.[a-zA-Z0-9\-]+)*(:[0-9]+)?(\/.*)?$/;

export const repoUrlRegex =
  /^(https:\/\/)?((www(\.[a-zA-Z0-9\-]{2,})+\.)?[a-zA-Z0-9\-]{2,})(\.([a-zA-Z0-9\-]{2,}))+(\/([a-zA-Z0-9\-._]{2,}))+?$/;

export const vatIdRegex =
  /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|↵(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|↵(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|↵(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|↵(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|↵(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|↵(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|↵(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/;

export const textRegex = /^([a-zA-Z0-9\&\+\-\%\$()\s\.\,\']|[^\u0000-\u007F])*$/;

// Could just be shell dependent, so might be able to allow anything
export const variableRegex = /^[a-zA-Z0-9_./-]*$/;

export const filePathRegex = /^\/([a-zA-Z0-9-._]+\/)*[a-zA-Z0-9-._]+$/; // matches filepath (eg. /alphanumeric123/dot-dash_underscore.js)
export const directoryRegex = /^\/([a-zA-Z0-9-._]+\/)*[a-zA-Z0-9-._]*$/; // as above, but accepts single and terminating /
export const dockerStageNameRegex = /^[a-zA-Z0-9-_]+$/; // as above, but accepts single and terminating /

export const relativePathRegex = /^((?!\/)).+$/; // may not start with '/' -> must be relative path
// This also prevents '....' but probably an acceptable edge case oversight
export const volumeMountPathRegex = /^((?!\.\.).)*$/; // may not contain '..'
export const containerMountPathRegex = /^((?!:).)*$/; // may not contain ':'

export const colorRegex = /^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/;

export const restrictionRegex = /^[a-zA-Z/*0-9%\-.#_!'();,&=+]*$/; // Matches alphanumeric, %, *, and valid branch name characters that are not converted by encodeURI
export const unencodedRestrictionRegex = /^[^?:@$~ [\]{}]*$/; // Matches any unicode character except those excluded above

export const gitLabGroupUrlRegex = /^[a-zA-Z0-9_]+[a-zA-Z0-9_\.-]*[a-zA-Z0-9_]$/; // Allows names to start and end with letters, numbers, or underscores, and may contain dots or dashes (but not at start or end)

export const bitbucketTrimRegex = /^(https?:\/\/)?bitbucket.org\/[^/]+\/[^/]+/; // Trims Bitbucket urls as repo url often ends in /src/:branchName

export const vcsTrimRegex = {
  bitbucket: bitbucketTrimRegex,
};
/// ^(?:(?:(?:0{0,2}\d|0?[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.){3}(?:0{0,2}\d|0?[1-9]\d|1\d\d|2[0-4]\d|25[0-5])(?:\/(?:\d|[1-2]\d|3[0-2]))?)$/
export const ipAddressWithOrWithoutCIDRRegex =
  /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2][0-9]|3[0-2]))?$/;

export const ipAddressWithoutCIDRRegex =
  /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;

export const ipAddressWithCIDRRegex =
  /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2][0-9]|3[0-2]))$/;

// TODO: generalize this
export const regionValidator = /^[A-Za-z0-9]+(-[A-Za-z0-9]+)*$/;

export const connectionStringRegex = {
  mongodb:
    /^mongodb(?:\+srv)?:\/\/(?:.+:.+@)?([\w.-]+(:[\d]+)*,?)+\/[^ "$*<>:|?]+(?:\?[A-Za-z0-9=&]+)?$/,
  mysql: /^jdbc:([a-z]+:)+\/\/.*$/,
  postgres: /^postgres(ql)?:\/\/.*$/,
};

export const validTaxIdRegex = new RegExp(
  `^(${vatRates
    .filter((r) => r.acceptTaxIds)
    .map((r) => r.iso_code)
    .join('|')})`,
  'i'
);

export const customDbNameRegex = {
  mongodb: /^[a-zA-Z0-9_][\^a-zA-Z0-9_\-!~]{0,62}$/, // https://www.mongodb.com/docs/manual/reference/limits/#mongodb-limit-Use-of-Case-in-Database-Names
  mysql: /(?!^\d+$)^[a-zA-Z0-9_$]{1,63}$/, // https://dev.mysql.com/doc/refman/8.0/en/identifiers.html
  postgres: /^[a-zA-Z_][a-zA-Z0-9_]{0,62}$/, // https://www.postgresql.org/docs/7.0/syntax525.htm
  rabbitmq: /^.{1,63}$/, // https://groups.google.com/g/rabbitmq-users/c/JolwmLSZwDE
};

// Template regex

export const refCheckRegex = /.*\${.*}.*/;

export const genericRefRegex = /\${[A-Za-z0-9=_.-]+}/;

export const argumentRegex = /\$\{args\.([A-Za-z0-9=_-]+(\.([A-Za-z0-9=_-]+))*)}/;

export const resourceRegex =
  /\$\{resources\.((services|jobs|addons|secretGroups|pipelines)\.([A-Za-z0-9=_-]+)\.([A-Za-z0-9=_-]+(\.([A-Za-z0-9=_-]+))*))}/;

export const referenceRegex =
  /\$\{refs\.(([A-Za-z0-9=_-]+)\.([A-Za-z0-9=_-]+(\.([A-Za-z0-9=_-]+))*))}/;

export const ociNodePoolIdRegex = /^nf[a-z0-9]{20}$/;
export const azureNodePoolIdRegex = /^nf[a-z0-9]{10}$/;

export const routingUriRegex = /^\/([_a-zA-Z0-9-&?=.]*)((\/[_a-zA-Z0-9-&?=.]+)*(\/)?)?$/;

export const k8sDurationRegex = /^[1-9][0-9]*(s|ms)$/;
export const k8sLongDurationRegex = /^[1-9][0-9]*(s|m|h)$/;

export const httpHeaderRegex = /^[a-zA-Z0-9_\-%$+]+$/;

export const resourceProfileCpuRegex = /([0-9]+(\.[0-9]+)?)(m)/;
export const resourceProfileMemoryRegex = /([0-9]+)|([0-9]+\.[0-9]+)(Mi|Gi)/;

export const backupDestinationPrefixRegex = /^([a-zA-Z0-9-_]+)\/$/;

export const k8sLabelAnnotationKeyRegex =
  /^([a-z0-9A-Z]+\.[a-z0-9A-Z]+\.[a-z0-9A-Z]+\/)?[a-z0-9A-Z]([a-z0-9A-Z-._]{0,61})[a-z0-9A-Z]$/;
export const k8sLabelValueRegex = /^([a-z0-9A-Z]([a-z0-9A-Z-._]{0,61}))?[a-z0-9A-Z]$/;

export const tailscaleAuthKeyTagRegex = /^tag:.+/;
