import assign from 'object-assign';

export const merge = (a, b) => {
  const result = assign({}, a, b);
  for (const key in a) {
    if (!a[key] || typeof b[key] !== 'object') continue;
    assign(result, {
      [key]: assign(a[key], b[key]),
    });
  }
  return result;
};

// sort object-value responsive styles
const sort = (obj) => {
  const next = {};
  Object.keys(obj)
    .sort((a, b) =>
      a.localeCompare(b, undefined, {
        numeric: true,
        sensitivity: 'base',
      })
    )
    .forEach((key) => {
      next[key] = obj[key];
    });
  return next;
};

const defaults = {
  breakpoints: [40, 52, 64].map((n) => `${n}em`),
};
const createMediaQuery = (n) => `@media screen and (min-width: ${n})`;
const getValue = (n, scale) => get(scale, n, n);

export const get = (obj, key, def, p, undef) => {
  key = key && key.split ? key.split('.') : [key];
  for (p = 0; p < key.length; p++) {
    obj = obj ? obj[key[p]] : undef;
  }
  return obj === undef ? def : obj;
};

// This parses our css props
export const createParser = (config) => {
  const cache = {};
  const parse = (props) => {
    let styles = {};
    let shouldSort = false;
    const isCacheDisabled = props.theme && props.theme.disableStyledSystemCache;

    // we go through our props object key by key and parse the props
    for (const key in props) {
      if (!config[key]) continue;
      const sx = config[key];
      const raw = props[key];
      const scale = get(props.theme, sx.scale, sx.defaults);

      // raw contains our individual style props, if its an object and on the dashboard
      // we use our dashboard breakpoints
      // so if the prop passed to the component looks like width='200px'
      // then 'width' is our key and raw would be '200px'
      if (typeof raw === 'object' && props.onDashboard) {
        cache.dashboardBreakpoints =
          (!isCacheDisabled && cache.dashboardBreakpoints) ||
          get(
            props.theme,
            'dashboardBreakpoints',
            defaults.dashboardBreakpoints
          );
        // if we have an array of props (i.e. variant={['icon','noBorder']}
        if (Array.isArray(raw)) {
          // We create our mediaQuery array from our theme defined breakpoints
          cache.dashboardMedia = (!isCacheDisabled && cache.dashboardMedia) || [
            null,
            ...cache.dashboardBreakpoints.map(createMediaQuery),
          ];
          // we then create a responsive style object
          // this translates width={['100px', '200px']} to a css media query
          styles = merge(
            styles,
            // we dont want media queries to apply to variants by default, so we check if the key = 'variant'
            // if it does, the 'raw' prop contains our variants and should not be breakpointed
            // this can be overridden by supplying the 'mediaQuery' prop to the component
            parseResponsiveStyle(
              cache.dashboardMedia,
              sx,
              scale,
              raw,
              props,
              key === 'variant'
            )
          );
          continue;
        }
        if (raw !== null) {
          styles = merge(
            styles,
            parseResponsiveObject(
              cache.dashboardBreakpoints,
              sx,
              scale,
              raw,
              props
            )
          );
          shouldSort = true;
        }
        continue;
      }

      // This is the same as above, just using our non-dashboard breakpoints
      if (typeof raw === 'object' && !props.onDashboard) {
        cache.breakpoints =
          (!isCacheDisabled && cache.breakpoints) ||
          get(props.theme, 'breakpoints', defaults.breakpoints);
        if (Array.isArray(raw)) {
          cache.media = (!isCacheDisabled && cache.media) || [
            null,
            ...cache.breakpoints.map(createMediaQuery),
          ];
          styles = merge(
            styles,
            parseResponsiveStyle(
              cache.media,
              sx,
              scale,
              raw,
              props,
              key === 'variant'
            )
          );
          continue;
        }
        if (raw !== null) {
          styles = merge(
            styles,
            parseResponsiveObject(cache.breakpoints, sx, scale, raw, props)
          );
          shouldSort = true;
        }
        continue;
      }

      assign(styles, sx(raw, scale, props));
    }

    // sort object-based responsive styles
    if (shouldSort) {
      styles = sort(styles);
    }
    return styles;
  };
  parse.config = config;
  parse.propNames = Object.keys(config);
  parse.cache = cache;

  const keys = Object.keys(config).filter((k) => k !== 'config');
  if (keys.length > 1) {
    keys.forEach((key) => {
      parse[key] = createParser({ [key]: config[key] });
    });
  }

  return parse;
};

// mediaQueries: our theme defined breakpoints, sx: a function to create a style from props,
// scale: object with the prop as a key and its actual css attribute inside
// raw: our prop value (i.e. width value or variant name)
// _props: all props passed to the component, isVariant: if the current props being parsed are variants
const parseResponsiveStyle = (
  mediaQueries,
  sx,
  scale,
  raw,
  _props,
  isVariant
) => {
  const styles = {};
  // imagine we have passed width={['100px', '200px']} to the component
  // raw is an array containing ['100px', '200px']
  // mediaQueries contains our theme defined breakpoints, so we shave off any extra values that exceed
  // our defined breakpoint with raw.slice(0, mediaQueries.length)
  // we then iterate through each value in 'raw'
  raw.slice(0, mediaQueries.length).forEach((value, i) => {
    // check for the mediaQuery prop (forces variants to be breakpointed)
    const { mediaQuery } = _props;
    // finds the corresponding breakpoint
    const media = mediaQueries[i];
    // translates our width value of 100px into a css style
    const style = sx(value, scale, _props);

    // if we are forcing breakpointing / not a variant
    if ((mediaQuery || !isVariant) && media) {
      // we create a css media query with our width value of 100px
      assign(styles, {
        [media]: assign({}, styles[media], style),
      });
      // otherwise we ignore breakpointing and apply the width value of 100px
      // this is then overwritten in the next iteration by our width value of 200px,
      // so the result is that our component has a width of 200px
    } else {
      assign(styles, style);
    }
  });

  return styles;
};

const parseResponsiveObject = (breakpoints, sx, scale, raw, _props) => {
  const styles = {};
  for (const key in raw) {
    const breakpoint = breakpoints[key];
    const value = raw[key];
    const style = sx(value, scale, _props);

    if (!breakpoint) {
      assign(styles, style);
    } else {
      const media = createMediaQuery(breakpoint);
      assign(styles, {
        [media]: assign({}, styles[media], style),
      });
    }
  }
  return styles;
};

export const createStyleFunction = ({
  properties,
  property,
  scale,
  transform = getValue,
  defaultScale,
}) => {
  properties = properties || [property];
  const sx = (value, scale, _props) => {
    const result = {};
    const n = transform(value, scale, _props);
    if (n === null) return;
    properties.forEach((prop) => {
      result[prop] = n;
    });
    return result;
  };
  sx.scale = scale;
  sx.defaults = defaultScale;
  return sx;
};

// new v5 API
export const system = (args = {}) => {
  const config = {};
  Object.keys(args).forEach((key) => {
    const conf = args[key];
    if (conf === true) {
      // shortcut definition
      config[key] = createStyleFunction({
        property: key,
        scale: key,
      });
      return;
    }
    if (typeof conf === 'function') {
      config[key] = conf;
      return;
    }
    config[key] = createStyleFunction(conf);
  });

  const parser = createParser(config);
  return parser;
};

export const compose = (...parsers) => {
  const config = {};
  parsers.forEach((parser) => {
    if (!parser || !parser.config) return;
    assign(config, parser.config);
  });
  const parser = createParser(config);

  return parser;
};
