export const sortFavourite = (a, b) => {
  if (a.favourite && !b.favourite) return -1;
  if (!a.favourite && b.favourite) return 1;
  return 0;
};

export const setFavourite = (nfObjectType, config, nfObjectId, favourite) => {
  const { entityType, entityId, projectId } = config;
  Meteor.call(
    'resource.favourite.set',
    {
      entityType,
      entityId,
      projectId,
      nfObjectType,
      nfObjectId,
      favourite,
    },
    (_err, _res) => {}
  );
};

export const sortFavouriteTeams = (favouriteTeams) => (a, b) => {
  if (favouriteTeams.includes(a._id) && !favouriteTeams.includes(b._id))
    return -1;
  if (favouriteTeams.includes(b._id) && !favouriteTeams.includes(a._id))
    return 1;
  return 0;
};

export const setFavouriteTeam = (teamId, favourite) => {
  Meteor.call(
    'users.favouriteTeam.set',
    {
      teamId,
      favourite,
    },
    (_err, _res) => {}
  );
};
