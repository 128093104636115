import React, { useEffect, useState } from 'react';

import Box from '@northflank/components/Box';
import Heading from '@northflank/components/Heading';
import FeatureTabs from '@northflank/components/marketing/FeatureTabs';

import DisplayRegion from '../DisplayRegion';
import { cloudProviderOptions } from '../utility/cloudProviderOptions';
import { regionNames } from '../utility/regionNames';

const CloudProviderRegions = ({ cloudProviderId, regions, title, ...rest }) => {
  const [selectedRegion, setSelectedRegion] = useState('All');
  const [displayedRegions, setDisplayedRegions] = useState(regions);

  const providerOption = cloudProviderOptions.find(
    (o) => o.value === cloudProviderId
  );

  useEffect(() => {
    const regionsToDisplay = [];

    if (regions) {
      regions.map(
        (r) =>
          (selectedRegion === r.regionName || selectedRegion === 'All') &&
          regionsToDisplay.push(r)
      );
    }

    setDisplayedRegions(regionsToDisplay);
  }, [selectedRegion, cloudProviderId]);

  return (
    <Box {...rest}>
      {title ? (
        <Heading color="grey1" fontSize={[5, 5, 6]} mb={10}>
          {title}
        </Heading>
      ) : null}
      <FeatureTabs
        tabs={regionNames}
        activeTab={selectedRegion}
        setActiveTab={(t) => {
          setSelectedRegion(t);
        }}
        mx="auto"
        mb={10}
        flexWrap="wrap"
        justifyContent="center"
        borderRadius={3}
        border="1px solid"
        borderColor="grey5"
        bg="grey8"
        width="100%"
      />
      <Box
        bg="grey10"
        border="1px solid"
        borderColor="grey5"
        borderRadius={3}
        py={[2, 2, 8]}
        pl={[2, 2, 8]}
        mb={10}
      >
        <Box _css={{ columnCount: [1, 2, 3, 4, 5] }}>
          {displayedRegions.length ? (
            displayedRegions.map((r) => (
              <DisplayRegion region={r} option={providerOption} key={r.id} />
            ))
          ) : (
            <Box p={3} mb={2}>
              No available regions
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CloudProviderRegions;
