import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { X } from '@styled-icons/boxicons-regular/X';
import css from '@styled-system/css';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, position, space } from 'styled-system';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import IconContainer from '@northflank/components/Icon';
import Text from '@northflank/components/Text';
import {
  CollapsibleScrollContext,
  SelectTargetContext,
} from '@northflank/contexts/Modal';
import { variant } from '@northflank/utilities/styled-system/variant';

export const ModalFullScreenBackground = styled(Box)(({ relative, zIndex }) =>
  css({
    width: relative ? '100%' : '100vw',
    height: relative ? '100%' : 'calc(var(--vh, 1vh) * 100)',
    position: relative ? 'absolute' : 'fixed',
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))',
    zIndex,
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    m: '0px !important',
  })
);

const ModalFullScreenSelectTarget = styled(Box)(({ relative, zIndex }) =>
  css({
    width: relative ? '100%' : '100vw',
    height: relative ? '100%' : 'calc(var(--vh, 1vh) * 100)',
    position: relative ? 'absolute' : 'fixed',
    zIndex: zIndex + 10,
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
  })
);

export const ModalBox = styled(Box)(
  ({ zIndex, allowOverflow }) =>
    css({
      bg: 'grey8',
      border: '1px solid',
      borderColor: 'grey5',
      zIndex: zIndex + 1,
      borderRadius: 1,
      maxHeight: 'calc(calc(var(--vh, 1vh) * 100) - 200px)',
      maxWidth: '560px',
      width: 'calc(100vw - 32px)',
      position: 'absolute',
      left: '50%',
      top: '100px',
      transform: 'translateX(-50%)',
      boxShadow: 'dropDown',
      overflow: allowOverflow ? 'visible' : 'hidden',
    }),
  () =>
    variant({
      variants: {
        wide: { maxWidth: '850px' },
        small: { maxWidth: '400px' },
        debugger: { maxWidth: '1000px', zIndex: '100' },
        pipeline: { maxWidth: '800px' },
        domains: { maxWidth: '600px' },
        mid: { maxWidth: '700px' },
      },
    })
);

const ModalHeader = styled(Box)(
  () =>
    css({
      justifyContent: 'space-between',
      alignItems: 'center',
      minHeight: '56px',
      borderBottom: '1px solid',
      borderColor: 'grey5',
      px: 12,
    }),
  variant({
    variants: {
      noBorder: {
        borderBottom: 'transparent',
      },
    },
  })
);

const ModalFooter = styled(Box)(
  () =>
    css({
      alignItems: 'center',
      minHeight: '56px',
      px: 12,
      borderTop: '1px solid',
      borderColor: 'grey5',
    }),
  variant({
    variants: {
      noBorder: {
        borderTop: 'transparent',
      },
    },
  })
);

const ModalContentWrapper = styled(Box)(({ theme, hasFooter, allowOverflow }) =>
  variant({
    variants: {
      scrollable: {
        overflowY: allowOverflow ? 'visible' : 'auto',
        maxHeight: hasFooter
          ? 'calc(calc(var(--vh, 1vh) * 100) - 200px - 56px - 56px)'
          : 'calc(calc(var(--vh, 1vh) * 100) - 200px - 56px)',
        'scrollbar-color': `${theme.colors.grey5} ${theme.colors.grey8}`,
        '&::-webkit-scrollbar-thumb': {
          borderColor: 'grey8',
        },
        '&::-webkit-scrollbar-track': {
          bg: 'grey8',
        },
      },
    },
  })
);

const defaultEdgeSpacing = [12, 28];

export const FullscreenModal = styled(Box)(
  ({ edgeSpacing = defaultEdgeSpacing }) =>
    css({
      display: 'flex',
      flexDirection: 'column',
      bg: 'grey11',
      border: '1px solid',
      borderColor: 'grey5',
      borderRadius: 2,
      width: [
        `calc(100vw - ${edgeSpacing[0] * 2}px)`,
        `calc(100vw - ${edgeSpacing[0] * 2}px)`,
        `calc(100vw - ${edgeSpacing[0] * 2}px)`,
        `calc(100vw - ${edgeSpacing[1] * 2}px)`,
      ],
      height: [
        `calc(calc(var(--vh, 1vh) * 100) - ${edgeSpacing[0] * 2}px)`,
        `calc(calc(var(--vh, 1vh) * 100) - ${edgeSpacing[0] * 2}px)`,
        `calc(calc(var(--vh, 1vh) * 100) - ${edgeSpacing[0] * 2}px)`,
        `calc(calc(var(--vh, 1vh) * 100) - ${edgeSpacing[1] * 2}px)`,
      ],
      position: 'fixed',
      top: [
        `${edgeSpacing[0]}px`,
        `${edgeSpacing[0]}px`,
        `${edgeSpacing[0]}px`,
        `${edgeSpacing[1]}px`,
      ],
      left: [
        `${edgeSpacing[0]}px`,
        `${edgeSpacing[0]}px`,
        `${edgeSpacing[0]}px`,
        `${edgeSpacing[1]}px`,
      ],
      boxShadow: 'dropDown',
    }),
  position
);

export const FullscreenModalHeader = styled(Box)(
  () =>
    css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      bg: 'grey9',
      borderBottom: '1px solid',
      borderBottomColor: 'grey5',
      borderTopLeftRadius: 2,
      borderTopRightRadius: 2,
      px: 8,
      py: 7,
    }),
  color,
  space
);

export const FullscreenModalFooter = styled(Box)(() =>
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    bg: 'grey9',
    borderTop: '1px solid',
    borderTopColor: 'grey5',
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
    px: 8,
    py: 6,
  })
);

export const composedPathPolyfill = () => {
  // Event.composedPath polyfill from https://stackoverflow.com/a/57554722
  /* eslint-disable */
  (function (e, d, w) {
    if (!e.composedPath) {
      e.composedPath = function () {
        if (this.path) {
          return this.path;
        }
        var target = this.target;

        this.path = [];
        while (target.parentNode !== null) {
          this.path.push(target);
          target = target.parentNode;
        }
        this.path.push(d, w);
        return this.path;
      };
    }
  })(Event.prototype, document, window);
  /* eslint-enable */
};

export const clickedPopover = (path) =>
  path.some(
    (el) =>
      el &&
      el.classList &&
      [...el.classList].includes('react-tiny-popover-container')
  );

const Modal = ({
  Icon,
  Help,
  IconSize,
  title,
  close,
  children,
  footer,
  noHeaderBorder,
  noFooterBorder,
  noCloseIcon,
  noCloseOnClickOutside,
  notScrollable,
  variant,
  relative,
  portal,
  zIndex = 10,
  allowOverflow,
  showScrollToBottom,
  iconColor = 'grey2',
  noEscape,
  onClick,
  headerButton,
  mainElementId = 'main',
}) => {
  const modal = useRef();

  const scrollRef = useRef(null);
  const contentScrollListener = useRef(null);
  const [hideScrollButton, setHideScrollButton] = useState(false);
  const selectTarget = useRef(null);

  const appMainDiv = document.getElementById(mainElementId);

  /*
    prevent scrolling beneath modal when open
    required on mobile, particularly for select dropdowns to work
  */
  useEffect(() => {
    if (!relative) appMainDiv.style.position = 'fixed';
    return () => {
      appMainDiv.style.position = 'unset';
    };
  }, []);

  const handleScroll = () => {
    /*
       scrollHeight: total content height including overflow in pixels
       scrollTop: distance between top of page and top of scroll bar in pixels
       clientHeight: current height of visible content
     */
    const { scrollHeight, scrollTop, clientHeight } =
      contentScrollListener?.current || {};

    if (scrollHeight) {
      const percentRemaining =
        ((scrollHeight - scrollTop - clientHeight) / scrollHeight) * 100;
      setHideScrollButton(percentRemaining < 20);
    }
  };

  useEffect(() => {
    // Only add the scroll listener if we want the button to show
    if (showScrollToBottom) {
      const div = contentScrollListener?.current;
      div.addEventListener('scroll', handleScroll);
      // Also want to calculate whether we show the button when window resizing effects overflow
      window.addEventListener('resize', handleScroll);
      handleScroll();
      return () => {
        div?.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    composedPathPolyfill();

    // If we open a modal from within another modal, the former must be portalled.
    // This will cause the parent modal to be closed when the child modal is clicked.
    // Here, we check if the eventPath contains an element with the class name `NFModalPortal`,
    // and prevent the close action if we find one.
    const clickedPortalledModal = (path) =>
      path.some(
        (el) =>
          el && el.classList && [...el.classList].includes('NFModalPortal')
      );

    const closeModal = (e) => {
      const eventPath = e.path || (e.composedPath && e.composedPath());
      if (
        !eventPath.includes(modal.current) &&
        !noCloseOnClickOutside &&
        !clickedPopover(eventPath) &&
        !clickedPortalledModal(eventPath) &&
        close
      ) {
        close();
      }
    };

    const escapeModal = (e) => {
      if (e.key === 'Escape' && close && !noEscape) close();
    };

    window.addEventListener('mousedown', closeModal);
    window.addEventListener('keyup', escapeModal);

    return () => {
      window.removeEventListener('mousedown', closeModal);
      window.removeEventListener('keyup', escapeModal);
    };
  }, []);

  const modalContent = (
    <ModalFullScreenBackground relative={relative} zIndex={zIndex}>
      <ModalFullScreenSelectTarget
        relative={relative}
        zIndex={zIndex}
        ref={selectTarget}
      >
        <SelectTargetContext.Provider value={{ selectTarget }}>
          <ModalBox
            as="section"
            ref={modal}
            variant={variant}
            zIndex={zIndex}
            onClick={onClick}
            allowOverflow={allowOverflow}
            className={portal && 'NFModalPortal'}
          >
            {(Icon || title || (close && !noCloseIcon)) && (
              <ModalHeader variant={['flex', noHeaderBorder && 'noBorder']}>
                <Box variant="flex" alignItems="center">
                  {Icon && (
                    <Box color={iconColor} mr={6}>
                      <IconContainer
                        Content={Icon}
                        Size={{ width: 16, height: 16, ...IconSize }}
                      />
                    </Box>
                  )}
                  {title && (
                    <Text
                      color="grey2"
                      fontWeight={2}
                      fontSize={3}
                      mr={6}
                      fontFamily="body"
                      as="h1"
                    >
                      {title}
                    </Text>
                  )}
                  {Help && Help}
                </Box>

                <Box
                  variant="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  {headerButton}
                  {showScrollToBottom && !hideScrollButton && (
                    <Button
                      variant="small"
                      type="button"
                      zIndex={zIndex + 1}
                      onClick={() =>
                        scrollRef.current.scrollIntoView({
                          behavior: 'smooth',
                          block: 'end',
                        })
                      }
                    >
                      Scroll to bottom
                    </Button>
                  )}
                  {close && !noCloseIcon && (
                    <Button type="button" onClick={close} variant="icon">
                      <X size={16} title="Close" />
                    </Button>
                  )}
                </Box>
              </ModalHeader>
            )}
            <CollapsibleScrollContext.Provider
              value={{ calculateScrollPercentage: () => handleScroll() }}
            >
              <ModalContentWrapper
                variant={!notScrollable && 'scrollable'}
                overflow={allowOverflow ? 'visible' : 'hidden'}
                ref={contentScrollListener}
                hasFooter={!!footer}
                allowOverflow={allowOverflow}
              >
                {children}
                <div ref={scrollRef} />
              </ModalContentWrapper>
              {footer && (
                <ModalFooter variant={['flex', noFooterBorder && 'noBorder']}>
                  {footer}
                </ModalFooter>
              )}
            </CollapsibleScrollContext.Provider>
          </ModalBox>
        </SelectTargetContext.Provider>
      </ModalFullScreenSelectTarget>
    </ModalFullScreenBackground>
  );

  if (portal) return createPortal(modalContent, appMainDiv);
  return modalContent;
};

Modal.propTypes = {
  Icon: PropTypes.object,
  portal: PropTypes.bool,
  close: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  footer: PropTypes.node,
  noHeaderBorder: PropTypes.bool,
  noFooterBorder: PropTypes.bool,
  noCloseIcon: PropTypes.bool,
  noCloseOnClickOutside: PropTypes.bool,
};

export default Modal;
