import { NorthflankError, NorthflankErrorOptions } from './northflank-error';

export interface OAuthErrorOptions extends NorthflankErrorOptions {
  displayMessage?: boolean;
}

export class OAuthError extends NorthflankError {
  displayMessage: boolean;

  constructor(
    { displayMessage }: OAuthErrorOptions = {
      code: 500,
      message: 'Internal Server Error',
    }
  ) {
    super();

    this.displayMessage = displayMessage || false;
  }
}
