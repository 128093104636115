import React, { useState } from 'react';

import { ChevronsDown } from '@styled-icons/boxicons-regular/ChevronsDown';
import css from '@styled-system/css';
import styled from 'styled-components';

import Box from '@northflank/components/Box';

const StyledButton = styled.button(() =>
  css({
    appearance: 'none',
    display: 'flex',
    alignItems: 'center',
    bg: 'transparent',
    border: 0,
    color: 'primary',
    fontSize: 3,
    fontFamily: 'body',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  })
);

const FadedCollapsible = ({ collapsedHeight = '400px', children }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <Box
        maxHeight={expanded ? 'none' : collapsedHeight}
        overflow={expanded ? 'visible' : 'hidden'}
        _css={{
          '-webkit-mask-image': expanded
            ? 'none'
            : '-webkit-gradient(linear, left 50%, left 100%, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)))',
        }}
      >
        {children}
      </Box>
      {!expanded && (
        <Box variant="flex" justifyContent="center" mt={10}>
          <StyledButton onClick={() => setExpanded(true)}>
            Show more
            <Box ml={4}>
              <ChevronsDown size={20} />
            </Box>
          </StyledButton>
        </Box>
      )}
    </>
  );
};

export default FadedCollapsible;
