import css from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import styled from 'styled-components';
import { border, layout, space } from 'styled-system';

import variant from '@northflank/utilities/styled-system/variant';

const inputStyles = ({
  bg = 'grey9',
  color = 'grey1',
  border = '1px solid',
  borderColor = 'grey5',
  borderRadius = 1,
  fontFamily = 'body',
  fontSize = 1,
  px = 7,
  py = 6,
  width = '100%',
  minHeight = '36px',
  hoverBorderColor = 'grey4',
  focusBorderColor = 'primary',

  theme,

  error,
  prefix,
  suffix,

  css: _css,
}) =>
  css({
    appearance: 'none',
    bg,
    color,
    border,
    borderColor: error ? 'danger' : borderColor,
    borderTopLeftRadius: prefix ? 0 : borderRadius,
    borderTopRightRadius: suffix ? 0 : borderRadius,
    borderBottomLeftRadius: prefix ? 0 : borderRadius,
    borderBottomRightRadius: suffix ? 0 : borderRadius,
    fontFamily,
    fontSize,
    px,
    py,
    width,
    minHeight,
    maxWidth: '100%',
    '&:hover': {
      color: 'grey1',
      borderColor: error ? 'danger' : hoverBorderColor,
    },
    '&:focus': {
      color: 'grey1',
      borderColor: error ? 'danger' : focusBorderColor,
      outline: 0,
    },
    '&::placeholder': {
      color: 'grey4',
    },
    '&::-webkit-calendar-picker-indicator': {
      color: 'transparent',
      background: 'none',
      display: 'none',
    },
    '&[disabled]': {
      bg: 'grey9',
      borderColor: 'grey8',
      cursor: 'not-allowed',
      color: 'grey3',
      '-webkit-text-fill-color': theme.colors.grey3,
      opacity: 0.75,
      '&:hover': {
        borderColor: 'grey8',
      },
    },
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    '&:-webkit-autofill': {
      '-webkit-text-fill-color': theme.colors.grey1,
      '-webkit-box-shadow': `0 0 0px 1000px ${theme.colors.grey9} inset`,
      'caret-color': theme.colors.grey1,
    },
    '&:-webkit-autofill::first-line': {
      fontFamily,
      fontSize,
      color,
    },
    ..._css,
  });

const inputVariants = ({ error }) =>
  variant({
    variants: {
      dataEntry: {
        minHeight: '24px',
        maxHeight: '24px',
        px: 5,
        py: 3,
        fontSize: 0,
      },
      table: {
        border: 0,
        borderRadius: 0,
        bg: error ? 'dangerOpacity9' : 'transparent',
        '&:focus': {
          bg: 'grey9',
        },
      },
    },
  });

export const StyledInput = styled.input.withConfig({ shouldForwardProp })(
  space,
  layout,
  border,
  inputStyles,
  inputVariants
);
export const StyledTextArea = styled.textarea(
  space,
  layout,
  border,
  inputStyles
);

const inputGroupTextStyles = ({
  bg = 'grey9',
  color = 'grey1',
  borderWidth = '1px',
  borderStyle = 'solid',
  borderColor = 'grey5',
  borderRadius = 1,
  fontFamily = 'body',
  fontSize = 1,
  px = 7,
  py = 6,
  minHeight = '36px',
  type,
  disabled,
}) =>
  css({
    bg,
    color,
    border,
    borderTopWidth: borderWidth,
    borderBottomWidth: borderWidth,
    borderLeftWidth: type === 'prefix' ? borderWidth : 0,
    borderRightWidth: type === 'suffix' ? borderWidth : 0,
    borderStyle,
    borderColor: disabled ? 'grey8' : borderColor,
    borderTopLeftRadius: type === 'prefix' ? borderRadius : 0,
    borderBottomLeftRadius: type === 'prefix' ? borderRadius : 0,
    borderTopRightRadius: type === 'suffix' ? borderRadius : 0,
    borderBottomRightRadius: type === 'suffix' ? borderRadius : 0,
    fontFamily,
    fontSize,
    px,
    py,
    minHeight,
  });

export const StyledInputGroupText = styled.div(
  space,
  layout,
  border,
  inputGroupTextStyles
);
