import React, { useContext, useEffect, useRef } from 'react';

import styled, { ThemeContext } from 'styled-components';

import Box from '@northflank/components/Box';

import Svg from './flow.svg';

const Wrapper = styled(Box)`
  position: relative;

  .glow {
    filter: blur(4px);
    backdrop-filter: blur(4px);
    opacity: 0.6;
  }

  .path {
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
  }
`;

const animatedGroups = [
  { id: 'path-1' },
  { id: 'path-2' },
  { id: 'path-3', reverse: true },
  { id: 'path-4' },
  { id: 'path-5', reverse: true },
  { id: 'path-6', reverse: true },
  { id: 'path-7' },
  { id: 'path-8' },
];

const FlowImage = () => {
  const imageRef = useRef();

  const theme = useContext(ThemeContext);

  useEffect(() => {
    const isSafari =
      navigator.userAgent.includes('Safari') &&
      !navigator.userAgent.includes('Chrome') &&
      !navigator.userAgent.includes('Chromium');

    if (imageRef.current) {
      for (const { id, reverse } of animatedGroups) {
        const animatedPaths = imageRef.current.querySelectorAll(
          `#flow_svg__${id} path`
        );

        const greenIndex = Math.floor(Math.random() * 3);

        animatedPaths.forEach((path, i) => {
          const dash = [];

          for (let j = 0; j <= 5; j++) {
            dash.push(
              Math.floor(Math.random() * 20) + 3,
              Math.floor(Math.random() * 10) + 1
            );
          }

          const color =
            greenIndex === i ? theme.colors.success : theme.colors.primary;

          path.setAttributeNS(null, 'stroke', color);
          path.setAttributeNS(null, 'stroke-dasharray', dash.join(','));
          path.setAttributeNS(null, 'class', 'path');

          if (!isSafari) {
            const animate = document.createElementNS(
              'http://www.w3.org/2000/svg',
              'animate'
            );
            animate.setAttributeNS(null, 'attributeName', 'stroke-dashoffset');
            animate.setAttributeNS(null, 'dur', '10s');
            animate.setAttributeNS(null, 'from', reverse ? '-500' : '500');
            animate.setAttributeNS(null, 'to', '0');
            animate.setAttributeNS(null, 'repeatCount', 'indefinite');
            path.appendChild(animate);

            const group = imageRef.current.querySelector(`g#flow_svg__${id}`);

            const pathGlow = document.createElementNS(
              'http://www.w3.org/2000/svg',
              'path'
            );
            pathGlow.setAttributeNS(null, 'stroke', color);
            pathGlow.setAttributeNS(null, 'stroke-width', '4');
            pathGlow.setAttributeNS(null, 'd', path.getAttribute('d'));
            pathGlow.setAttributeNS(null, 'class', 'path glow');
            group.appendChild(pathGlow);
          }
        });
      }
    }
  }, []);

  return (
    <Wrapper>
      <Box ref={imageRef}>
        <Svg />
      </Box>
    </Wrapper>
  );
};

export default FlowImage;
