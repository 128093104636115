import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import ReactSelect, { components } from 'react-select';
import Creatable from 'react-select/creatable';
import { useVirtual } from 'react-virtual';

import { ChevronDown } from '@styled-icons/boxicons-regular/ChevronDown';
import { ChevronUp } from '@styled-icons/boxicons-regular/ChevronUp';
import { Customize } from '@styled-icons/boxicons-regular/Customize';
import { Hive } from '@styled-icons/boxicons-regular/Hive';
import { PurchaseTag } from '@styled-icons/boxicons-regular/PurchaseTag';
import { X } from '@styled-icons/boxicons-regular/X';
import { Star } from '@styled-icons/boxicons-solid/Star';
import css from '@styled-system/css';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { space } from 'styled-system';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Icon from '@northflank/components/Icon';
import { WrapLabel } from '@northflank/components/Input';
import LabelWithIcon from '@northflank/components/LabelWithIcon';
import Text from '@northflank/components/Text';
import Tooltip from '@northflank/components/Tooltip';
import { SelectTargetContext } from '@northflank/contexts/Modal';
import useAvailableHeight from '@northflank/utilities/hooks/useAvailableHeight';
import { sortFavourite } from '@northflank/utilities/List/favourite';
import withComponentId from '@northflank/utilities/withComponentId';

const CursorStyle = styled.div(({ disabled }) =>
  css({
    cursor: disabled ? 'not-allowed' : 'default',
    overflow: 'visible',
  })
);

const StyledReactSelect = styled(ReactSelect)(space, () =>
  css({
    fontFamily: 'body',
  })
);

const StyledReactCreatable = styled(Creatable)(space, () =>
  css({
    fontFamily: 'body',
  })
);

const MultiValueRemove = ({
  selectProps: { isDisabled },
  innerProps: { ref, ...restInnerProps },
}) =>
  isDisabled ? null : (
    <div {...restInnerProps} ref={ref}>
      <X size={16} />
    </div>
  );

MultiValueRemove.propTypes = {
  innerProps: PropTypes.object,
};

const ClearIndicator = (props) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props)}
    >
      <X size={16} />
    </div>
  );
};

ClearIndicator.propTypes = {
  getStyles: PropTypes.func,
  innerProps: PropTypes.object,
};

const DropdownIndicator = (props) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
    selectProps: { menuIsOpen },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('dropdownIndicator', props)}
    >
      {menuIsOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
    </div>
  );
};

DropdownIndicator.propTypes = {
  getStyles: PropTypes.func,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
};

const Menu = (props) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  const style = getStyles('menu', props);
  const {
    marginBottom,
    marginTop,
    paddingTop,
    position,
    top,
    width,
    zIndex,
    ...innerStyle
  } = style;
  const outerStyle = {
    marginBottom,
    marginTop,
    paddingTop,
    position,
    top,
    width,
    zIndex,
  };
  return (
    <Box style={outerStyle} pb={8}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        {...restInnerProps}
        ref={ref}
        style={innerStyle}
        onClick={(e) => e.stopPropagation()}
      >
        {props.children}
        {props.selectProps.menuPromptMessage && (
          <Box borderTop="1px solid" borderTopColor="grey5" p={4}>
            <Box bg="grey9" borderRadius={2} px={6} py={3}>
              {typeof props.selectProps.menuPromptMessage === 'string' ? (
                <Text fontSize={0} color="grey3">
                  {props.selectProps.menuPromptMessage}
                </Text>
              ) : (
                props.selectProps.menuPromptMessage
              )}
            </Box>
          </Box>
        )}
      </div>
    </Box>
  );
};

export const TemplateResourceIndicator = ({ icon, text }) => (
  <Box variant="flex" alignItems="center" ml={5}>
    <Icon Content={Hive} Size={16} color="grey3" mr={3} />
    <LabelWithIcon
      icon={icon}
      text={text}
      iconColor="primary"
      borderColor="grey5"
      noTooltip
      neverCollapse
    />
  </Box>
);

const Option = ({ data, ...props }) => (
  <components.Option {...props}>
    <Tooltip title={data.isDisabled ? data.disabledTooltip : undefined}>
      <Box
        variant="flex"
        alignItems="center"
        justifyContent="space-between"
        id={data.id}
      >
        <Box variant="flex" alignItems="center">
          <Text>{data.label}</Text>
          {data.favourite && (
            <Icon Content={Star} Size={12} color="warning" ml={4} />
          )}
          {data.inlineIcon && (
            <Icon Content={data.inlineIcon} Size={18} ml={1} />
          )}
          {data.free && <LabelWithIcon icon={PurchaseTag} text="Free" ml={4} />}
        </Box>
        {data.typeLabel && <TemplateResourceIndicator {...data.typeLabel} />}
      </Box>
    </Tooltip>
  </components.Option>
);

const DefaultMenuList = ({ children, ...props }) => {
  const listRef = useRef();
  const availableHeight = useAvailableHeight(listRef, null, [
    props.selectProps.menuIsOpen,
  ]);
  return (
    <components.MenuList
      {...props}
      innerRef={listRef}
      maxHeight={Math.min(Math.max(50, availableHeight - 16), 400)}
    >
      {children}
    </components.MenuList>
  );
};

// Adds a load more button to dropdown, required if options.length is large
const LoadMoreList = ({ children, ...props }) => {
  const loadMoreCount = props.selectProps.loadMoreCount ?? 15;
  const initialLoaded = props.selectProps.loadedCount ?? loadMoreCount;

  const [loaded, setLoaded] = useState(initialLoaded);

  const truncatedChildren = useMemo(
    () => (Array.isArray(children) ? children.slice(0, loaded) : children),
    [children, loaded, props.selectProps.inputValue]
  );

  useEffect(() => {
    setLoaded(initialLoaded);
  }, [props.selectProps.inputValue]);

  useEffect(() => {
    if (typeof props.selectProps.onLoadMore === 'function') {
      props.selectProps.onLoadMore(loaded);
    }
  }, [loaded]);

  const listRef = useRef();

  const availableHeight = useAvailableHeight(listRef, null, [
    props.selectProps.menuIsOpen,
  ]);

  return (
    <components.MenuList
      {...props}
      innerRef={listRef}
      maxHeight={Math.min(Math.max(50, availableHeight - 16), 400)}
    >
      {truncatedChildren}
      {(children.length > loaded || props.selectProps.forceLoadMore) && (
        <Box p={6}>
          <Button
            variant="small"
            width="100%"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setLoaded((l) => l + loadMoreCount);
            }}
          >
            Load more
          </Button>
        </Box>
      )}
    </components.MenuList>
  );
};

const groupHeadingHeight = 32;

const VirtualisedMenuList = ({ children, ...props }) => {
  const flattenedOptions = useMemo(() => {
    const childrenArray = React.Children.toArray(children);

    if (
      childrenArray.length === 1 &&
      (childrenArray[0].props.children === 'No options' ||
        childrenArray[0].props.children === 'Loading...')
    ) {
      return [children];
    }

    const hasNestedOptions = props.options.some((opt) => !!opt.options?.length);

    if (!hasNestedOptions) return children;

    return childrenArray.reduce((result, child) => {
      const {
        props: { children: nestedChildren = [] },
      } = child;

      return Array.isArray(nestedChildren)
        ? [
            ...result,
            React.cloneElement(child, { type: 'group' }, []),
            ...nestedChildren,
          ]
        : [...result, React.cloneElement(child)];
    }, []);
  }, [children]);

  const listRef = useRef();

  const rowHeight = props.selectProps.optionHeight ?? 35;

  const rowVirtualizer = useVirtual({
    size: flattenedOptions.length,
    parentRef: listRef,
    estimateSize: React.useCallback(() => rowHeight, []),
    overscan: 5,
  });

  const headingDelta =
    (rowHeight - groupHeadingHeight) *
    flattenedOptions.filter((child) => child?.props?.type === 'group').length;

  const getItemTop = (index, start) => {
    if (headingDelta === 0) return start;

    const precedingGroupHeadings = flattenedOptions.filter(
      (child, i) => i < index && child?.props?.type === 'group'
    ).length;
    return start - (rowHeight - groupHeadingHeight) * precedingGroupHeadings;
  };

  const availableHeight = useAvailableHeight(listRef, null, [
    props.selectProps.menuIsOpen,
  ]);

  return (
    <components.MenuList
      {...props}
      innerRef={listRef}
      maxHeight={Math.min(Math.max(50, availableHeight - 16), 400)}
    >
      <Box
        height={`${rowVirtualizer.totalSize - headingDelta}px`}
        position="relative"
      >
        {rowVirtualizer.virtualItems.map((virtualRow) => {
          const child = flattenedOptions[virtualRow.index];
          return (
            <Box
              key={virtualRow.index}
              position="absolute"
              top={`${getItemTop(virtualRow.index, virtualRow.start)}px`}
              left={0}
              right={0}
              width="100%"
              height={
                child?.props?.type !== 'group'
                  ? `${virtualRow.size}px`
                  : `${groupHeadingHeight}px`
              }
              className={
                child?.props?.type === 'group' ? 'groupHeading' : undefined
              }
            >
              {child}
            </Box>
          );
        })}
      </Box>
    </components.MenuList>
  );
};

const SingleValue = ({ data, ...props }) => {
  return (
    <components.SingleValue {...props}>
      <Box
        variant="flex"
        alignItems="center"
        justifyContent="space-between"
        overflow="hidden"
      >
        <Text variant="ellipse">{data.label}</Text>
        {data.typeLabel && <TemplateResourceIndicator {...data.typeLabel} />}
      </Box>
    </components.SingleValue>
  );
};

const Select = ({
  uid,
  label,
  labelRight,
  labelHidden,
  disabled,
  error,
  hideError,
  isCreatable,
  borderRadius,
  required,
  formikRequired,
  components: propComponents,
  options,
  stickyOptions,
  variant = '',
  my,
  mt,
  mb,
  width,
  loadMore,
  noVirtual,
  menuPromptMessage,
  isSearchable = true,
  placeholder,
  styleOverrides = {},
  ...props
}) => {
  const theme = useContext(ThemeContext);

  const isTableVariant = variant.includes('table');

  const getCss = (styles) => css({ ...styles })({ theme });

  const getBorderColor = ({ disabled, focused, error }) => {
    if (disabled) return 'grey8';
    if (error) return 'danger';
    if (focused) return 'primary';
    return 'grey5';
  };

  const getHoverBorderColor = ({ focused, error, disabled }) => {
    if (disabled) return 'grey8';
    if (error) return 'dangerLight1';
    if (focused) return 'primary';
    return 'grey4';
  };

  const styles = {
    control: (provided, state) => ({
      ...provided,
      ...getCss({
        bg: isTableVariant
          ? error
            ? 'dangerOpacity9'
            : 'transparent'
          : 'grey9',
        opacity: state.isDisabled ? 0.75 : 1,
        borderWidth: isTableVariant ? 0 : '1px',
        borderStyle: 'solid',
        borderColor: getBorderColor({
          disabled: state.isDisabled,
          focused: state.isFocused,
          error,
        }),
        borderRadius: borderRadius || 1,
        transition: 'none',
        outline: 0,
        boxShadow: 0,
        fontSize: 0,
        color: state.isDisabled ? 'grey3' : 'grey1',
        minHeight: props.small ? '24px' : '36px',
        '&:hover': {
          borderColor: getHoverBorderColor({
            disabled: state.isDisabled,
            focused: state.isFocused,
            error,
          }),
        },
        '& > div:first-of-type': {
          ...props.innerControlStyle,
        },
      }),
    }),
    placeholder: (provided, state) => ({
      ...provided,
      ...getCss({
        color: state.isDisabled ? 'grey5' : 'grey4',
        ...styleOverrides.placeholder,
      }),
    }),
    input: (provided) => ({
      ...provided,
      ...getCss({
        color: 'grey1',
        fontSize: 1,
        height: props.small && '20px',
        input: {
          fontFamily: 'body',
        },
      }),
    }),
    singleValue: (provided, state) => ({
      ...provided,
      ...getCss({
        color: state.isDisabled ? 'grey3' : 'grey1',
        fontSize: 1,
        maxWidth: 'calc(100% - 12px)',
        width: '100%',
      }),
    }),
    valueContainer: (provided) => ({
      ...provided,
      ...getCss({
        py: props.small && 0,
        height: props.small && '22px',
        ...styleOverrides.valueContainer,
      }),
    }),
    menu: (provided) => ({
      ...provided,
      ...getCss({
        bg: 'grey8',
        border: '1px solid',
        borderColor: 'grey5',
        borderRadius: 1,
        py: 0,
        my: 6,
        boxShadow: 'dropDown',
        zIndex: 10,
        overflow: 'visible',
        ...styleOverrides.menu,
      }),
    }),
    menuList: (provided) => ({
      ...provided,
      ...getCss({
        py: 0,
        'scrollbar-color': `${theme.colors.grey5} ${theme.colors.grey8}`,
        '&::-webkit-scrollbar-track': { bg: 'grey8' },
        '&::-webkit-scrollbar-thumb': { borderColor: 'grey8' },
        '.groupHeading:not(:first-child)': {
          borderTop: '1px solid',
          borderTopColor: 'grey5',
        },
        ...styleOverrides.menuList,
      }),
    }),
    menuPortal: (provided) => ({
      ...provided,
      ...getCss({
        zIndex: 20,
      }),
    }),
    option: (provided, state) => ({
      ...provided,
      ...getCss({
        bg: state.isFocused ? 'grey7' : 'grey8',
        fontSize: 1,
        color: state.isDisabled
          ? 'grey4'
          : state.isSelected
            ? 'primary'
            : 'grey2',
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
        '&:hover, &:active': {
          bg: state.isDisabled ? 'grey8' : 'grey7',
        },
        ...styleOverrides.option,
      }),
    }),
    multiValue: (provided) => ({
      ...provided,
      ...getCss({
        bg: 'grey9',
        border: '1px solid',
        borderColor: 'grey6',
        borderRadius: '20px',
        my: props.small ? '0px' : '2px',
        ...styleOverrides.multiValue,
      }),
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      ...getCss({
        color: state.isDisabled ? 'grey3' : 'grey2',
        px: 4,
        py: props.small ? '0px' : '3px',
        ...styleOverrides.multiValue,
      }),
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      ...getCss({
        bg: state.isFixed ? 'red' : 'transparent',
        color: 'grey4',
        cursor: 'pointer',
        pl: 0,
        '&:hover': {
          bg: 'transparent',
          color: 'grey3',
        },
        ...styleOverrides.multiValueRemove,
      }),
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      ...getCss({
        color: 'grey2',
        fontSize: 1,
      }),
    }),
    indicatorSeparator: () => ({
      ...getCss({
        display: 'none',
      }),
    }),
    clearIndicator: (provided) => ({
      ...provided,
      ...getCss({
        color: 'grey3',
        p: 0,
        ml: 4,
        transition: 'none',
        cursor: 'pointer',
        '&:hover': {
          color: 'grey1',
        },
      }),
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      ...getCss({
        color: state.isDisabled ? 'grey5' : 'grey4',
        p: 0,
        ml: 4,
        mr: 6,
        transition: 'none',
        cursor: 'pointer',
        '&:hover': {
          color: 'grey1',
        },
      }),
    }),
    group: (provided) => ({
      ...provided,
      ...getCss({
        borderBottom: '1px solid',
        borderColor: 'grey5',
        '&:last-child': {
          borderBottom: 0,
        },
      }),
    }),
    groupHeading: (provided) => ({
      ...provided,
      ...getCss({
        color: 'grey3',
        fontFamily: 'monospace',
        fontSize: 1,
      }),
    }),
  };

  const Component = isCreatable ? StyledReactCreatable : StyledReactSelect;
  const [favouriteSortedOptions, setFavouriteSortedOptions] = useState([]);

  // TODO @tom, need to review
  useEffect(() => {
    options.forEach((opt, i) => {
      if (uid) opt.id = opt.id ?? `option-${uid}_${i}`;
      if (opt.options) {
        opt.options = opt.options.sort(sortFavourite);
        opt.options.forEach((nopt, j) => {
          nopt.id = nopt?.id ?? `option-${uid}_${i}_${j}`;
        });
      }
    });

    const newFavouriteSortedOptions = options.sort(sortFavourite);
    const withStickyOptions = stickyOptions?.length
      ? stickyOptions.concat(newFavouriteSortedOptions)
      : newFavouriteSortedOptions;
    setFavouriteSortedOptions(withStickyOptions);
  }, [options]);

  const { selectTarget } = useContext(SelectTargetContext);

  const totalOptionsCount = useMemo(() => {
    const hasNestedOptions = options?.some((opt) => !!opt.options?.length);
    if (!hasNestedOptions) return options?.length;
    return options.reduce(
      (acc, cur) =>
        (acc += Array.isArray(cur.options) ? cur.options.length : 1),
      0
    );
  }, [options]);

  return (
    <Box my={my} mt={mt} mb={mb} width={width}>
      <WrapLabel
        label={label}
        labelRight={labelRight}
        labelHidden={labelHidden}
        required={formikRequired || required}
      >
        <CursorStyle disabled={disabled}>
          <Component
            id={uid && `select-${uid}`}
            options={favouriteSortedOptions}
            styles={styles}
            components={{
              MultiValueRemove,
              ClearIndicator,
              DropdownIndicator,
              Menu,
              Option,
              MenuList: loadMore
                ? LoadMoreList
                : totalOptionsCount > 20 && !noVirtual
                  ? VirtualisedMenuList
                  : DefaultMenuList,
              SingleValue,
              ...propComponents,
            }}
            isDisabled={disabled}
            isRequired={required}
            isSearchable={isSearchable}
            placeholder={
              placeholder ??
              (isSearchable ? 'Select or type to search...' : 'Select...')
            }
            menuPortalTarget={selectTarget && selectTarget.current}
            onClick={(e) => {
              e.stopPropagation();
            }}
            menuPromptMessage={
              menuPromptMessage ??
              (isCreatable ? (
                <Box variant="flex" alignItems="center">
                  <Icon Content={Customize} Size={14} color="grey3" mr={3} />
                  <Text color="grey3" fontSize={0}>
                    Or start typing a custom value
                  </Text>
                </Box>
              ) : undefined)
            }
            formatCreateLabel={(inputValue) => `Use “${inputValue}”`}
            {...props}
          />
        </CursorStyle>
      </WrapLabel>
      {!hideError && error && (
        <Text color="danger" fontSize={0} mt={5}>
          {error}
        </Text>
      )}
    </Box>
  );
};

Select.propTypes = {
  label: PropTypes.string,
};

export default withComponentId(Select);
