export const getMonacoTheme = (colors) => ({
  base: 'vs-dark',
  inherit: true,
  colors: {
    // 'editor.foreground': '#151C28',
    'editor.background': colors.grey11,
    'editorCursor.foreground': colors.grey1,
    'editor.lineHighlightBackground': colors.grey7,
    'editorLineNumber.foreground': colors.grey4,
    'editor.selectionBackground': colors.grey6,
    'selection.background': colors.grey6,
    'editor.inactiveSelectionBackground': colors.grey6,
    'editor.InactiveSelection': '#3A3D41',
    'editor.IndentGuides': colors.grey6,
    'editor.SelectionHighlight': '#ADD6FF26',
    'slider.background': colors.grey9,
    'scrollbar.shadow': '#1d273f',
    'scrollbarSlider.background': colors.grey7, // only styles diffOverViewSliders, not editor sliders
    'scrollbarSlider.hoverBackground': colors.grey7,
    'scrollbarSlider.activeBackground': colors.grey7,
    'editorOverviewRuler.border': '#273454',
    'editorCodeLens.foreground': colors.grey3,
  },
  rules: [
    { token: '', foreground: colors.grey2, background: '1E1E1E' },
    { token: 'invalid', foreground: 'f44747' },
    { token: 'emphasis', fontStyle: 'italic' },
    { token: 'strong', fontStyle: 'bold' },

    { token: 'variable', foreground: colors.primary },
    { token: 'variable.predefined', foreground: colors.primary },
    { token: 'variable.parameter', foreground: colors.primary },
    { token: 'constant', foreground: colors.primary },
    { token: 'comment', foreground: colors.primaryLight },
    { token: 'number', foreground: '#ff6982' },
    { token: 'number.hex', foreground: '#ff6982' },
    { token: 'regexp', foreground: '#ff6982' },
    { token: 'annotation', foreground: '#ff6982' },
    { token: 'type', foreground: '#ff6982' },
    { token: 'boolean', foreground: '#ff6982' },

    { token: 'delimiter', foreground: colors.grey3 },
    { token: 'delimiter.html', foreground: '808080' },
    { token: 'delimiter.xml', foreground: '808080' },

    { token: 'tag', foreground: '569CD6' },
    { token: 'tag.id.jade', foreground: '4F76AC' },
    { token: 'tag.class.jade', foreground: '4F76AC' },
    { token: 'meta.scss', foreground: 'A79873' },
    { token: 'meta.tag', foreground: 'CE9178' },
    { token: 'metatag', foreground: 'DD6A6F' },
    { token: 'metatag.content.html', foreground: '9CDCFE' },
    { token: 'metatag.html', foreground: '569CD6' },
    { token: 'metatag.xml', foreground: '569CD6' },
    { token: 'metatag.php', fontStyle: 'bold' },

    { token: 'key', foreground: colors.primary },
    { token: 'string.key.json', foreground: colors.primary },
    { token: 'string.value.json', foreground: colors.success },

    { token: 'attribute.name', foreground: colors.primary },
    { token: 'attribute.value', foreground: '#ff6982' },
    { token: 'attribute.value.number.css', foreground: 'B5CEA8' },
    { token: 'attribute.value.unit.css', foreground: 'B5CEA8' },
    { token: 'attribute.value.hex.css', foreground: 'D4D4D4' },

    { token: 'string', foreground: 'CE9178' },
    { token: 'string.sql', foreground: 'FF0000' },

    { token: 'keyword', foreground: colors.primary },
    { token: 'keyword.flow', foreground: colors.primary },
    { token: 'keyword.json', foreground: colors.primary },
    { token: 'keyword.flow.scss', foreground: colors.primary },

    { token: 'operator.scss', foreground: '909090' },
    { token: 'operator.sql', foreground: '778899' },
    { token: 'operator.swift', foreground: '909090' },
    { token: 'predefined.sql', foreground: 'FF00FF' },
  ],
});
