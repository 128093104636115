import React, { useEffect, useRef, useState } from 'react';

import { CaretDown } from '@styled-icons/boxicons-regular/CaretDown';
import { CaretUp } from '@styled-icons/boxicons-regular/CaretUp';
import { Hide } from '@styled-icons/boxicons-regular/Hide';
import { Show } from '@styled-icons/boxicons-regular/Show';
import css from '@styled-system/css';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import FieldLabel from '@northflank/components/FieldLabel';
import Text from '@northflank/components/Text';
import VisuallyHidden from '@northflank/components/VisuallyHidden';
import withComponentId from '@northflank/utilities/withComponentId';

import { StyledInput, StyledInputGroupText, StyledTextArea } from './styles';
import { mergeRefs } from './utils';

export const WrapLabel = ({
  label,
  labelRight,
  labelHidden,
  required,
  children,
  htmlFor,
}) => {
  // For accessibility, we should always have a label. If it shouldn't appear
  // visually, we can hide it with a VisuallyHidden component.
  if (label && labelHidden) {
    return (
      <label htmlFor={htmlFor}>
        <VisuallyHidden>
          {label}
          {required && ' (Required field)'}
        </VisuallyHidden>
        {children}
      </label>
    );
  }

  return label || labelRight ? (
    <>
      <FieldLabel as="label" htmlFor={htmlFor}>
        <Box
          variant="flex"
          alignItems="center"
          justifyContent="space-between"
          pb={6}
        >
          {label && (
            <Text as="span" display="flex" alignItems="center">
              {label}
              {required && (
                <>
                  <Text
                    ml={6}
                    as="span"
                    aria-hidden="true"
                    title="Required field"
                  >
                    *
                  </Text>
                  <VisuallyHidden>(Required field)</VisuallyHidden>
                </>
              )}
            </Text>
          )}
          {labelRight}
        </Box>
        {/* With no `for` attribute, we want the content within the label */}
        {!htmlFor && children}
      </FieldLabel>
      {/* With a `for` attribute, place the content outside the label, optionally
       * allowing for more complex interactive content without invalid HTML */}
      {htmlFor && children}
    </>
  ) : (
    children
  );
};

WrapLabel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelRight: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
  required: PropTypes.bool,
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string,
};

const SpinButton = styled.button(() =>
  css({
    appearance: 'none',
    background: 'none',
    border: 0,
    color: 'grey2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    height: '50%',
    cursor: 'pointer',
    '&:first-child': {
      borderBottom: '1px solid',
      borderBottomColor: 'grey5',
    },
    '&:hover': {
      color: 'grey1',
    },
    '&[disabled]': {
      borderColor: 'grey8',
      cursor: 'not-allowed',
      color: 'grey3',
      opacity: 0.75,
      '&:hover': {
        borderColor: 'grey8',
      },
    },
  })
);

const Input = ({
  uid,
  type,
  rows,
  label,
  labelRight,
  labelHidden,
  error,
  hideError,
  hideErrorBorder,
  forwardedRef,
  my,
  mt,
  mb,
  required,
  formikRequired,
  internalButton,
  hidePasswordRevealButton,
  disablePasswordManagers = true,
  wrapperProps,
  prefix,
  suffix,
  showNumberSpin,
  increment,
  decrement,
  inputPr,
  htmlFor,
  ...props
}) => {
  const [internalButtonWidth, setInternalButtonWidth] = useState();
  const [revealPasswordType, setRevealPasswordType] = useState();
  const innerRef = useRef(null);
  const internalButtonRef = useRef();

  useEffect(() => {
    if (type === 'number' && !showNumberSpin) {
      const input = innerRef.current;
      const blur = () => input.blur();
      input.addEventListener('mousewheel', blur);
      return () => {
        input?.removeEventListener('mousewheel', blur);
      };
    }
    if (type === 'password') {
      setRevealPasswordType('password');
    }
  }, []);

  useEffect(() => {
    if (internalButtonRef.current) {
      setInternalButtonWidth(internalButtonRef.current.offsetWidth + 12);
    }
  }, [internalButton]);

  const hasPasswordRevealButton =
    type === 'password' && !hidePasswordRevealButton;

  if (hasPasswordRevealButton) {
    internalButton = internalButton ? (
      <Box variant="flex">
        <Button
          type="button"
          variant="icon"
          onClick={() =>
            setRevealPasswordType((t) =>
              t === 'password' ? 'text' : 'password'
            )
          }
        >
          {revealPasswordType === 'password' ? (
            <Show size={16} title="Reveal value" />
          ) : (
            <Hide size={16} title="Hide value" />
          )}
        </Button>
        {internalButton}
      </Box>
    ) : (
      <Button
        type="button"
        variant="icon"
        onClick={() =>
          setRevealPasswordType((t) => (t === 'password' ? 'text' : 'password'))
        }
      >
        {revealPasswordType === 'password' ? (
          <Show size={16} title="Reveal value" />
        ) : (
          <Hide size={16} title="Hide value" />
        )}
      </Button>
    );
  }

  // Ideally we want to use the `htmlFor` prop to associate the label with the
  // input, but as this component also contains the password reveal button, we
  // need additional logic to determine the correct `htmlFor` value.
  // In future, we should split the password reveal functionality into a
  // separate component to simplify this, as we do with the NameCheckField.
  const derivedFor = htmlFor || (hasPasswordRevealButton && props.name);

  return (
    <Box my={my} mt={mt} mb={mb} {...wrapperProps}>
      <WrapLabel
        label={label}
        labelRight={labelRight}
        labelHidden={labelHidden}
        required={formikRequired || required}
        htmlFor={derivedFor}
      >
        {rows ? (
          <StyledTextArea
            id={derivedFor || (uid && `input-${uid}`)}
            rows={rows}
            error={!hideErrorBorder && !!error}
            ref={mergeRefs(forwardedRef, innerRef)}
            required={required}
            display="block" // required to prevent strange extra spacing under input
            data-lpignore={disablePasswordManagers} // prevent LastPass showing browser extension UI over the input
            data-1p-ignore={disablePasswordManagers} // same as above for 1Password
            {...props}
          />
        ) : (
          <Box position="relative" variant="flex">
            {prefix && (
              <StyledInputGroupText type="prefix">
                {prefix}
              </StyledInputGroupText>
            )}
            <StyledInput
              id={derivedFor || (uid && `input-${uid}`)}
              type={revealPasswordType || type}
              error={!hideErrorBorder && !!error}
              ref={mergeRefs(forwardedRef, innerRef)}
              required={required}
              pr={
                inputPr ||
                (internalButton && `${internalButtonWidth}px!important`)
              }
              data-lpignore={disablePasswordManagers} // prevent LastPass showing browser extension UI over the input
              data-1p-ignore={disablePasswordManagers} // same as above for 1Password
              prefix={!!prefix}
              suffix={!!suffix || showNumberSpin}
              className={
                revealPasswordType === 'text' ? 'ph-no-capture' : undefined
              }
              {...props}
            />
            {type === 'number' &&
              showNumberSpin &&
              typeof increment === 'function' &&
              typeof decrement === 'function' && (
                <StyledInputGroupText
                  type="suffix"
                  px={0}
                  py={0}
                  disabled={props.disabled}
                  minHeight={props.minHeight}
                >
                  <SpinButton
                    type="button"
                    onClick={() => increment(props.min, props.max)}
                    disabled={props.disabled}
                  >
                    <CaretUp size={12} />
                  </SpinButton>
                  <SpinButton
                    type="button"
                    onClick={() => decrement(props.min, props.max)}
                    disabled={props.disabled}
                  >
                    <CaretDown size={12} />
                  </SpinButton>
                </StyledInputGroupText>
              )}
            {suffix && (
              <StyledInputGroupText type="suffix">
                {suffix}
              </StyledInputGroupText>
            )}
            {props.button && props.button}
            {internalButton && (
              <Box
                position="absolute"
                right="6px"
                top="6px"
                ref={internalButtonRef}
              >
                {internalButton}
              </Box>
            )}
          </Box>
        )}
      </WrapLabel>
      {!hideError && error && (
        <Text color="danger" fontSize={0} mt={5}>
          {error}
        </Text>
      )}
    </Box>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  rows: PropTypes.number,
  label: PropTypes.string,
  error: PropTypes.string,
  hideError: PropTypes.bool,
  disablePasswordManagers: PropTypes.bool,
};

export default withComponentId(
  React.forwardRef((props, ref) => <Input forwardedRef={ref} {...props} />)
);
