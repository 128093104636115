import React, { useContext } from 'react';

import { ThemeContext } from 'styled-components';

import Box from '@northflank/components/Box';
import IconContainer from '@northflank/components/Icon';
import Image from '@northflank/components/Image';
import Text from '@northflank/components/Text';

const FeatureWithIcon = ({
  Icon,
  iconColor,
  iconBg,
  iconGradient,
  largeIcon,
  image,
  noImageShadow,
  heading,
  text,
  center,
  right,
  inline,
  cta,
  card,
  ...rest
}) => {
  const theme = useContext(ThemeContext);
  return (
    <Box
      background={
        card
          ? `linear-gradient(to bottom, ${theme.colors.primary} 0%, transparent 100%)`
          : undefined
      }
      borderRadius={card ? 3 : undefined}
      p={card ? '1px' : undefined}
    >
      <Box
        textAlign={center ? 'center' : right ? 'right' : 'left'}
        bg={card ? 'background' : undefined}
        p={card ? 9 : undefined}
        borderRadius={card ? 3 : undefined}
        height="100%"
        {...rest}
      >
        <Box
          display="flex"
          flexDirection={inline ? 'row' : 'column'}
          alignItems="flex-start"
          mb={8}
        >
          {image && (
            <Image
              src={image}
              alt={heading}
              borderRadius={3}
              boxShadow={!noImageShadow && 'dropDown'}
              mr={inline ? 8 : 0}
              mb={inline ? 0 : 8}
            />
          )}
          {Icon && (
            <Box
              variant="flex"
              justifyContent={
                center ? 'center' : right ? 'flex-end' : 'flex-start'
              }
              width={center ? '100%' : 'auto'}
              mr={inline ? 8 : 0}
              mb={inline ? 0 : 8}
            >
              <Box
                borderRadius={5}
                position="relative"
                _css={{
                  ...(iconBg
                    ? {
                        '&::before': {
                          content: '""',
                          display: 'block',
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                          bg: iconGradient ? 'primary' : iconColor,
                          borderRadius: 5,
                          opacity: 0.5,
                          filter: 'blur(24px)',
                        },
                      }
                    : {}),
                }}
              >
                <IconContainer
                  Content={Icon}
                  Size={largeIcon ? 48 : 32}
                  color={
                    iconGradient
                      ? 'url(#gradient_svg__blueSvgGradient)'
                      : iconColor || 'grey2'
                  }
                />
              </Box>
            </Box>
          )}
          <Box>
            {heading && (
              <Text fontSize={4} fontWeight={500} color="grey1" mt="1px">
                {heading}
              </Text>
            )}
          </Box>
        </Box>
        {typeof text === 'string' ? (
          <Text fontSize={3} color="grey2">
            {text}
          </Text>
        ) : (
          text
        )}
        {cta && <Box mt={8}>{cta}</Box>}
      </Box>
    </Box>
  );
};

export default FeatureWithIcon;
