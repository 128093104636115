import React from 'react';

import { Bookmarks } from '@styled-icons/boxicons-regular/Bookmarks';
import { ChevronsRight } from '@styled-icons/boxicons-regular/ChevronsRight';
import { CodeAlt } from '@styled-icons/boxicons-regular/CodeAlt';
import { GitCommit } from '@styled-icons/boxicons-regular/GitCommit';
import { GitMerge } from '@styled-icons/boxicons-regular/GitMerge';
import { Label } from '@styled-icons/boxicons-regular/Label';
import { ListUl } from '@styled-icons/boxicons-regular/ListUl';
import { LockAlt } from '@styled-icons/boxicons-regular/LockAlt';
import { PlayCircle } from '@styled-icons/boxicons-regular/PlayCircle';
import { Reset } from '@styled-icons/boxicons-regular/Reset';
import { Search } from '@styled-icons/boxicons-regular/Search';
import { Shuffle } from '@styled-icons/boxicons-regular/Shuffle';
import { Sitemap } from '@styled-icons/boxicons-regular/Sitemap';
import { Dashboard } from '@styled-icons/boxicons-solid/Dashboard';

import Box from '@northflank/components/Box';
import Heading from '@northflank/components/Heading';
import FeatureNav from '@northflank/components/marketing/FeatureNav';
import FeatureWithIcon from '@northflank/components/marketing/FeatureWithIcon';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';

import {
  FeatureGroup,
  FeaturesWrapper,
} from '../../components/FeaturePageWrapper';
import PreHeading from '../../components/PreHeading';

const Release = () => {
  return (
    <>
      <SEO
        title="Release — Features"
        description="A fully integrated suite of developer tools. Learn more about Northflank’s deploy & release capabilities."
      />
      <FeatureNav />
      <FeaturesWrapper>
        <Box variant="bounding" pb="70px!important">
          <PreHeading mb={6}>Feature breakdown</PreHeading>
          <Heading as="h1" color="grey1" fontSize={[6, 6, 7, 7]} mb={8}>
            Release on Northflank
          </Heading>
          <Text color="grey3" fontSize={4} width={[1, 1, 1 / 2]} mediaQuery>
            Remove the stress of releasing with Northflank. Organise your
            environments with Pipelines, and codify your release processes with
            Release Flows.
          </Text>
        </Box>
        <FeatureGroup
          heading="Pipelines"
          wideImage="/images/feature/details/release/pipeline.png"
          wideImageAlt="Screenshot of Northflank pipeline view"
          items={
            <>
              <FeatureWithIcon
                Icon={Bookmarks}
                iconGradient
                heading="Organise Environments"
                text="Categorise your resources into development, staging, and production environments."
              />
              <FeatureWithIcon
                Icon={Dashboard}
                iconGradient
                heading="Environment Dashboard"
                text="Monitor the state of all your resources with pipeline stage overviews, health status, and quick links."
              />
              <FeatureWithIcon
                Icon={ChevronsRight}
                iconGradient
                heading="Promote Stages"
                text="Promote builds from one stage to the next with release flows. Run migrations and perform database backups with each release."
              />
              <FeatureWithIcon
                Icon={Label}
                iconGradient
                heading="Manual Releases"
                text="Trigger releases through the UI, selecting branch, commit, or build data for each linked service and job."
              />
              <FeatureWithIcon
                Icon={GitMerge}
                iconGradient
                heading="Git Releases"
                text="Automatically run release flows when you push to specific branches or pull requests. Reference version control data within the template."
              />
              <FeatureWithIcon
                Icon={Search}
                iconGradient
                heading="Preview Environments"
                text="Set up templates to provision full preview environments on triggers from Git or other events. Tear them down and clean up resources when they are no longer needed."
              />
            </>
          }
        />
        <FeatureGroup
          heading="Release Flows"
          wideImage="/images/feature/details/release/flow.png"
          wideImageAlt="Screenshot of Northflank visual release flow builder"
          items={
            <>
              <FeatureWithIcon
                Icon={GitCommit}
                iconGradient
                heading="GitOps"
                text="Codify your release and preview process in code. Drive updates with bi-directional syncs on Git and Northflank."
              />
              <FeatureWithIcon
                Icon={Sitemap}
                iconGradient
                heading="Visual Workflow Builder"
                text="Drag and drop nodes to build up sophisticated workflows without writing any code."
              />
              <FeatureWithIcon
                Icon={CodeAlt}
                iconGradient
                heading="Webhooks"
                text="Trigger a release or preview environment with webhooks. Integrate with your existing tools such as GitHub Actions."
              />
              <FeatureWithIcon
                Icon={PlayCircle}
                iconGradient
                heading="Release Action Nodes"
                text="Compose actions into a workflow: triggering builds, deploying images, promoting deployments, running migrations, creating database backups, and other actions."
              />
              <FeatureWithIcon
                Icon={ListUl}
                iconGradient
                heading="Run History"
                text="Access a list of historical runs for release and preview environments. For each run, Northflank stores duration, error occurrences, and the state of each node."
              />
              {/* <FeatureWithIcon */}
              {/*  Icon={CodeAlt} */}
              {/*  iconGradient */}
              {/*  heading="Infrastructure as Data" */}
              {/*  text="Each node and step within the run can reference and understand the state of each step allowing you to connect nodes together." */}
              {/* /> */}
              <FeatureWithIcon
                Icon={Reset}
                iconGradient
                heading="Roll Back"
                text="Release with confidence knowing that you can roll a release back in a matter of clicks."
              />
              <FeatureWithIcon
                Icon={LockAlt}
                iconGradient
                heading="Secure Arguments"
                text="Securely store your secrets outside of GitOps using Northflank’s secure engine to inject variables and arguments into nodes at runtime."
              />
              <FeatureWithIcon
                Icon={Shuffle}
                iconGradient
                heading="Build Robust Workflows"
                text="Build workflows that perform actions in parallel or sequentially. Dynamically reference resources and other actions to create a sophisticated all-in-one pipeline."
              />
            </>
          }
        />
      </FeaturesWrapper>
    </>
  );
};

export default Release;
