import { useEffect, useRef, useState } from 'react';

const useBackgroundCursorPosition = ({
  backgroundColor,
  highlightColor,
  borderColor,
  borderHighlightColor,
  highlightSize = '400px',
  active = true,
}) => {
  const [cursorInItem, setCursorInItem] = useState(false);
  const [cursorPos, setCursorPos] = useState([0, 0]);

  const elementRef = useRef();

  useEffect(() => {
    if (active) {
      const handleMouseEnter = () => {
        setCursorInItem(true);
      };
      const handleMouseLeave = () => {
        setCursorInItem(false);
      };
      if (elementRef.current) {
        elementRef.current.addEventListener('mouseenter', handleMouseEnter);
        elementRef.current.addEventListener('mouseleave', handleMouseLeave);
        return () => {
          elementRef.current?.removeEventListener(
            'mouseenter',
            handleMouseEnter
          );
          elementRef.current?.removeEventListener(
            'mouseleave',
            handleMouseLeave
          );
        };
      }
    }
  }, [elementRef, active]);

  useEffect(() => {
    if (active) {
      const handleMouseMove = (e) => {
        const rect = elementRef.current.getBoundingClientRect();
        const x = e.clientX - rect.x;
        const y = e.clientY - rect.y;
        const xPerc = (x / rect.width) * 100;
        const yPerc = (y / rect.height) * 100;
        setCursorPos([xPerc, yPerc]);
      };
      if (elementRef.current) {
        if (cursorInItem) {
          elementRef.current.addEventListener('mousemove', handleMouseMove);
        } else {
          elementRef.current.removeEventListener('mousemove', handleMouseMove);
        }
        return () => {
          elementRef.current?.removeEventListener('mousemove', handleMouseMove);
        };
      }
    }
  }, [elementRef, cursorInItem, active]);

  return [
    elementRef,
    cursorInItem
      ? `radial-gradient(circle at ${cursorPos[0]}% ${cursorPos[1]}%, ${highlightColor} 0%, ${backgroundColor} ${highlightSize})`
      : backgroundColor,
    cursorPos,
    borderColor && borderHighlightColor
      ? {
          content: '""',
          position: 'absolute',
          inset: '-1px',
          borderRadius: 'inherit',
          background: cursorInItem
            ? `radial-gradient(circle at calc(var(--cursorXPos) * 1%) calc(var(--cursorYPos) * 1%), ${borderHighlightColor} 0%, ${borderColor} ${highlightSize})`
            : borderColor,
          zIndex: -1,
        }
      : undefined,
  ];
};

export default useBackgroundCursorPosition;
