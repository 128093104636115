import { NorthflankErrorCodeStrings } from './error-codes';
import { generateDefaultError } from './http-messages';

export interface NorthflankErrorOptions {
  code: number;
  message?: string;
  details?: any;
  description?: any;
  id?: NorthflankErrorCodeStrings;
}

export class NorthflankError extends Error {
  code: number;
  details: any;
  // Used to pass in details to the user in a template context
  description: any;
  id: NorthflankErrorCodeStrings | undefined;

  constructor(
    { code, message, details, description, id }: NorthflankErrorOptions = {
      code: 500,
      message: 'Internal Server Error',
    }
  ) {
    super();

    // eslint-disable-next-line no-magic-numbers
    const errorCode = code || 500;

    this.code = errorCode;
    this.message = message || generateDefaultError(errorCode);
    this.details = details;
    this.description = description;
    if (id && !id.startsWith('nf-')) {
      // @ts-ignore
      this.id = `nf-${id}`;
    } else {
      this.id = id;
    }
  }
}
