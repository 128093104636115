export type NorthflankErrorCodeStrings =
  | 'nf-invalid-request-body'
  | 'nf-invalid-query-params'
  | 'nf-internal-server-error'
  | 'nf-external-image-no-credentials'
  | 'nf-external-image-revoked-credentials'
  | 'nf-external-image-invalid-credentials'
  | 'nf-external-image-not-found'
  | 'nf-org-webhook-disabled'
  | 'nf-vcs-not-found'
  | 'nf-vcs-auth-failed'
  | 'nf-vcs-disallowed-team-context'
  | 'nf-vcs-repo-creation-failed'
  | 'nf-vcs-clone-failed'
  | 'nf-vcs-push-failed'
  | 'nf-directory-sync-failed'
  | 'nf-template-parsing-failed';
