import { V1ReleaseFlowTemplateNodeBaseSchema } from '@northflank/schema-types';

export const getNodesOfType = (
  node: V1ReleaseFlowTemplateNodeBaseSchema,
  nodeKind: V1ReleaseFlowTemplateNodeBaseSchema['kind']
) => {
  // Get the build action nodes from workflows recursively.
  if (node.kind === 'Workflow') {
    return node.spec.steps.reduce((a, s) => [...a, ...getNodesOfType(s, nodeKind)], []);
  }

  switch (node.kind) {
    case nodeKind:
      return [node];
    default:
      return [];
  }
};
