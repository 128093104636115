export const stripPlatformEgressIpRangesAuthorizedIpRanges = (
  PLATFORM_EGRESS_IP_RANGES,
  ipRanges
) => {
  const authorizedIpRanges = ipRanges.filter(
    (ipRange) => !PLATFORM_EGRESS_IP_RANGES.includes(ipRange)
  );

  return authorizedIpRanges;
};

export const getGpuGiBFromMiB = (sizeInMiB) => Math.floor(sizeInMiB / 1024);
