export const resourceProfileCustomOption = {
  value: 'custom',
  label: 'Custom',
  isDisabled: true,
  description: 'You have selected a custom configuration.',
};

// TODO: decide on names
export enum ByocResourceProfiles {
  DEV = 'dev',
  PROD_DEFAULT = 'prod-default',
}

export const predefinedByocResourceProfiles = [
  {
    id: ByocResourceProfiles.DEV,
    name: 'Development',
    description:
      'A lightweight networking and DNS set up for small clusters hosting development workloads.',

    definition: {
      istio: {
        components: {
          istiod: {
            minReplicas: 1,
            maxReplicas: 2,
            resources: {
              limits: {
                cpu: '100m',
                memory: '20Mi',
              },
              requests: {
                cpu: '100m',
                memory: '20Mi',
              },
            },
          },
          ingressGateway: {
            minReplicas: 1,
            maxReplicas: 2,
            resources: {
              limits: {
                cpu: '500m',
                memory: '500Mi',
              },
              requests: {
                cpu: '50m',
                memory: '20Mi',
              },
            },
          },
          sidecar: {
            resources: {
              limits: {
                cpu: '500m',
                memory: '512Mi',
              },
              requests: {
                cpu: '15m',
                memory: '64Mi',
              },
            },
          },
        },
      },
      coredns: {
        components: {
          main: {
            replicas: 1,
            resources: {
              limits: {
                cpu: '500m',
                memory: '170Mi',
              },
              requests: {
                cpu: '100m',
                memory: '70Mi',
              },
            },
          },
        },
      },
      linkerd: {
        enabled: false,
      },
      cilium: {
        enabled: true,
      },
    },
  },
  {
    id: ByocResourceProfiles.PROD_DEFAULT,
    name: 'Production - Default',
    description:
      'A High Availability networking and DNS set up for clusters hosting actively used production workloads.',

    definition: {
      istio: {
        components: {
          istiod: {
            minReplicas: 3,
            maxReplicas: 3,
            resources: {
              limits: {
                cpu: '100m',
                memory: '20Mi',
              },
              requests: {
                cpu: '100m',
                memory: '20Mi',
              },
            },
          },
          ingressGateway: {
            minReplicas: 3,
            maxReplicas: 5,
            resources: {
              limits: {
                cpu: '500m',
                memory: '500Mi',
              },
              requests: {
                cpu: '50m',
                memory: '20Mi',
              },
            },
          },
          sidecar: {
            resources: {
              limits: {
                cpu: '500m',
                memory: '512Mi',
              },
              requests: {
                cpu: '15m',
                memory: '64Mi',
              },
            },
          },
        },
      },
      coredns: {
        components: {
          main: {
            replicas: 2,
            resources: {
              limits: {
                cpu: '500m',
                memory: '170Mi',
              },
              requests: {
                cpu: '100m',
                memory: '70Mi',
              },
            },
          },
        },
      },
      linkerd: {
        enabled: false,
      },
      cilium: {
        enabled: true,
      },
    },
    default: true,
  },
];

export const defaultByocResourceProfile = ByocResourceProfiles.PROD_DEFAULT;
export const defaultByocResourceProfileOption = predefinedByocResourceProfiles.find(
  (o) => o.id === defaultByocResourceProfile
);

if (!defaultByocResourceProfileOption) {
  throw new Error(
    `defaultByocResourceProfileOption not found for default profile ${defaultByocResourceProfile}`
  );
}
