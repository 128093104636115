import React from 'react';

import { Refresh } from '@styled-icons/boxicons-regular/Refresh';

import Box from '@northflank/components/Box';
import Heading from '@northflank/components/Heading';
import Icon from '@northflank/components/Icon';
import { LogoType } from '@northflank/components/marketing/Nav';
import Text from '@northflank/components/Text';

const Error = ({ logo }) => (
  <Box variant="bounding">
    {logo && (
      <Box mb={10}>
        <a href="/">
          <LogoType />
        </a>
      </Box>
    )}
    <Box variant="flex" alignItems="center" mb={10}>
      <Icon Content={Refresh} Size={60} color="grey3" mr={8} />
      <Heading color="grey1" fontSize={[6, 6, 7, 7]} lineHeight={1}>
        We’ll be right back...
      </Heading>
    </Box>
    <Text fontSize={4} width={[1, 1, 1 / 2]} mediaQuery>
      Unfortunately, there was an error processing that request. Please try
      again soon, and don’t hesitate to <a href="/contact">get in touch</a> if
      the error persists.
    </Text>
  </Box>
);
export default Error;
