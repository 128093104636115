import React, { useContext } from 'react';

import { ArrowToTop } from '@styled-icons/boxicons-regular/ArrowToTop';
import { BarChartSquare } from '@styled-icons/boxicons-regular/BarChartSquare';
import { Bell } from '@styled-icons/boxicons-regular/Bell';
import { Chip } from '@styled-icons/boxicons-regular/Chip';
import { Cloud } from '@styled-icons/boxicons-regular/Cloud';
import { Data } from '@styled-icons/boxicons-regular/Data';
import { Expand } from '@styled-icons/boxicons-regular/Expand';
import { Globe } from '@styled-icons/boxicons-regular/Globe';
import { Hdd } from '@styled-icons/boxicons-regular/Hdd';
import { PackageIcon } from '@styled-icons/boxicons-regular/PackageIcon';
import { Server } from '@styled-icons/boxicons-regular/Server';
import { Time } from '@styled-icons/boxicons-regular/Time';
import { TrendingUp } from '@styled-icons/boxicons-regular/TrendingUp';
import { ThemeContext } from 'styled-components';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Grid from '@northflank/components/Grid';
import Heading from '@northflank/components/Heading';
import Icon from '@northflank/components/Icon';
import PrimaryFeatureBlock from '@northflank/components/marketing/PrimaryFeatureBlock';
import SEO from '@northflank/components/marketing/SEO';
import { TestimonialHeader } from '@northflank/components/marketing/Testimonial';
import Text from '@northflank/components/Text';

import DemoVideo from '../../components/DemoVideo';
import DevExpSection from '../../components/DevExpSection';
import FancyQuote from '../../components/FancyQuote';
import GetStartedCta from '../../components/GetStartedCta';
import HeroHeading from '../../components/HeroHeading';
import LargeCarousel from '../../components/LargeCarousel';
import PreHeading from '../../components/PreHeading';
import StatsSection from '../../components/StatsSection';
import texture from '../../components/Texture';
import topog from '../../components/Topog';
import UseCasesFooterLinks from '../../components/UseCasesFooterLinks';
import DevStepsDiagram from '../../svg/DevStepsDiagram.svg';

const RightSizingPage = () => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <SEO
        title="Right-sized by default — Use cases"
        description="Efficient resource usage is at odds with readiness for traffic spikes. Northflank’s bin packing and auto-scaling resolve this conflict."
      />

      <Box
        background={`radial-gradient(60% 100% at 70% 50%, rgba(0, 147, 255, 0.1) 0%, #151C28 100%),
        radial-gradient(circle at 70% 50%, rgba(0, 0, 0, 0) 0%, #151C28 100%),
         ${topog}`}
        borderBottom="1px solid"
        borderColor="grey5"
        mt="-80px"
        pt="80px"
      >
        <Box variant="bounding">
          <Grid
            gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
            alignItems="center"
            gridGap={12}
          >
            <Box>
              <HeroHeading
                as="h1"
                fontSize={[6, 6, 7]}
                color="grey1"
                mb={8}
                style={{ letterSpacing: 'normal', lineHeight: 1.2 }}
              >
                Right size your cloud
              </HeroHeading>
              <Text
                fontSize={[5, 5, 6]}
                color="grey2"
                mb={[12, 12, 13]}
                css={{ lineHeight: 1.2 }}
              >
                Optimise resource density and eliminate under-utilisation with
                built-in bin packing.
              </Text>
              <Text fontSize={4} color="grey2" mb={[12, 12, 13]}>
                Worried about traffic spikes? Don’t be! Set your minimum and
                maximum replica counts, and let auto-scaling do the heavy
                lifting during high traffic events.
              </Text>
              <Grid
                gridTemplateColumns={['1fr', '1fr', 'repeat(2, max-content)']}
                gridGap={6}
                mb={6}
              >
                <a href="https://app.northflank.com/signup">
                  <Button
                    variant={['primary', 'large', 'noWrap']}
                    width={['100%', '100%', 'auto']}
                  >
                    <Icon Content={Cloud} Size={16} mr={4} />
                    Try Northflank Now
                  </Button>
                </a>
                <DemoVideo buttonProps={{ width: ['100%', '100%', 'auto'] }} />
              </Grid>
              <Text color="grey3">
                Prefer a live walkthrough?{' '}
                <a
                  href="https://cal.com/team/northflank/northflank-demo?duration=30"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Schedule a demo &rarr;
                </a>
              </Text>
            </Box>
            <Box width="100%">
              <DevStepsDiagram />
            </Box>
          </Grid>
        </Box>
      </Box>

      <Box variant="bounding">
        <Grid
          gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
          alignItems="center"
          gridGap={14}
          mb={[13, 13, 14]}
        >
          <Box>
            <Box mb={[12, 12, 13]}>
              <PreHeading mb={6}>
                Your cloud should be efficient & elastic
              </PreHeading>
              <Heading fontSize={[5, 5, '32px']} mb={6}>
                Compute optimally
              </Heading>
              <Text fontSize="18px" color="grey2">
                Maximise your cloud spend with dynamic elasticity. Scale up to
                meet increased demand for your microservices and databases,
                ensuring optimal performance when you need it most.
              </Text>
            </Box>
            <PrimaryFeatureBlock
              subFeatures={[
                {
                  Icon: Server,
                  heading: 'Maximise container density',
                  text: 'Eliminate under-utilised nodes with advanced bin-packing algorithms for maximum container density per server. Optimise your microservices and databases with precisely the right amount of compute.',
                },
                {
                  Icon: TrendingUp,
                  heading: 'Auto-scaling based on demand',
                  text: 'Easily configure auto-scaling based on thresholds for CPU, Memory, and Requests Per Second (RPS). When the traffic pours in, Northflank responds.',
                },
                {
                  Icon: Expand,
                  heading: 'Scale horizontally & vertically',
                  text: 'Scale horizontally by adding more instances of your service with automatic load balancing, or scale vertically by upgrading your compute plan to access more CPU & memory capacity.',
                },
                {
                  Icon: Time,
                  heading: 'Scheduled jobs',
                  text: 'Run compute jobs on demand or a custom schedule. With automatic infrastructure scaling, resources are only provisioned while your job runs, optimising costs. Use affordable spot instances for lower-priority workloads.',
                },
              ]}
              subFeaturesGrid="1fr"
              subFeaturesGap={[12, 12, 13]}
              subFeaturesAlign="stretch"
            />
          </Box>
          <Box height="700px" overflow="hidden" position="relative" p={8}>
            <Box
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
              background={`linear-gradient(to right, transparent 0%, transparent 70%, ${theme.colors.background} 95%)`}
              backgroundSize="cover"
              zIndex={-1}
            />
            <Box
              as="img"
              src="/images/solutions/use-cases/clusterobserve.png"
              position="relative"
              height="100%"
              border="1px solid"
              borderColor="grey5"
              borderRadius={3}
              boxShadow="dropDown"
              zIndex={-2}
            />
          </Box>
        </Grid>

        <Grid
          gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
          alignItems="center"
          gridGap={14}
          mb={[13, 13, 14]}
        >
          <Box height="700px" overflow="hidden" position="relative" p={8}>
            <Box
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
              background={`linear-gradient(to left, transparent 0%, transparent 70%, ${theme.colors.background} 95%)`}
              backgroundSize="cover"
              zIndex={-1}
            />
            <Box
              as="img"
              src="/images/solutions/use-cases/metrics.png"
              position="absolute"
              right={0}
              height="684px"
              border="1px solid"
              borderColor="grey5"
              borderRadius={3}
              boxShadow="dropDown"
              zIndex={-2}
            />
          </Box>
          <Box>
            <Box mb={[12, 12, 13]}>
              <PreHeading mb={6}>
                Your choice for hot & cold data storage
              </PreHeading>
              <Heading fontSize={[5, 5, '32px']} mb={6}>
                Storage flexibility
              </Heading>
              <Text fontSize="18px" color="grey2">
                Easy access to managed service options for object storage,
                relational databases, message queues, and in-memory databases.
                Set up in seconds with just a few clicks.
              </Text>
            </Box>
            <PrimaryFeatureBlock
              subFeatures={[
                {
                  Icon: Data,
                  heading: 'Addons like “no ops” cloud databases',
                  text: 'Get your favourite data store as a managed service on your Kubernetes cluster. Pick from a catalogue of options including Redis, Postgres, MySQL, MongoDB, RabbitMQ, or MinIO.',
                },
                {
                  Icon: Hdd,
                  heading: 'Volumes',
                  text: 'Start with as little as 4GB and scale up to 1TB+. Quickly resize persistent volumes attached to your services as your storage requirements increase.',
                },
                {
                  Icon: PackageIcon,
                  heading: 'Storage tiers',
                  text: 'Select the disk size, performance, and price that suits your workload. Choose from HDD and SSD storage options.',
                },
              ]}
              subFeaturesGrid="1fr"
              subFeaturesGap={[12, 12, 13]}
              subFeaturesAlign="stretch"
            />
          </Box>
        </Grid>

        <Grid
          gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
          alignItems="center"
          gridGap={14}
          mb={[13, 13, 14]}
        >
          <Box>
            <Box mb={[12, 12, 13]}>
              <PreHeading mb={6}>
                Prevent run away infrastructure spending
              </PreHeading>
              <Heading fontSize={[5, 5, '32px']} mb={6}>
                Infrastructure guardrails & scaling
              </Heading>
              <Text fontSize="18px" color="grey2">
                Automated scaling is scary without a safety net. Set limits,
                receive alerts, and stay in control.
              </Text>
            </Box>
            <PrimaryFeatureBlock
              subFeatures={[
                {
                  Icon: Bell,
                  heading: 'Billing awareness & cost analysis',
                  text: 'Set spending alerts and monitor expenses at the workload level for precise cost control. View current usage by service, project, or account.',
                },
                {
                  Icon: ArrowToTop,
                  heading: 'Min/max your nodes',
                  text: 'Define minimum and maximum node limits for autoscaling. Scale fearlessly, knowing a runaway process won’t spiral out of control overnight.',
                },
                {
                  Icon: Chip,
                  heading: 'Scale by resource usage',
                  text: 'Trigger autoscaling with thresholds on CPU, memory, and RPS. Right size your compute, and rest easy knowing auto-scaling has you covered if there’s a traffic spike.',
                },
                {
                  Icon: Globe,
                  heading: 'Universal traffic management',
                  text: 'Enjoy a fully managed and Istio & Linkerd experience with battle-tested service mesh features. Secure data in transit with mutual TLS encryption and strong identity-based authentication and authorization.',
                },
              ]}
              subFeaturesGrid="1fr"
              subFeaturesGap={[12, 12, 13]}
              subFeaturesAlign="stretch"
            />
          </Box>
          <Box height="700px" overflow="hidden" position="relative" p={8}>
            <Box
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
              background={`linear-gradient(to right, transparent 0%, transparent 70%, ${theme.colors.background} 95%)`}
              backgroundSize="cover"
              zIndex={-1}
            />
            <Box
              as="img"
              src="/images/solutions/use-cases/previewduration.png"
              position="relative"
              height="100%"
              border="1px solid"
              borderColor="grey5"
              borderRadius={3}
              boxShadow="dropDown"
              zIndex={-2}
            />
          </Box>
        </Grid>

        <FancyQuote
          body={
            <>
              Northflank is way easier than gluing a bunch of tools together to
              spin up apps and databases. It’s the ideal platform to deploy
              containers in our cloud account, avoiding the brain damage of big
              cloud and Kubernetes. It’s more powerful and flexible than
              traditional PaaS – all within our VPC.{' '}
              <Text as="span" color="success" fontWeight={500}>
                Northflank has become a go-to way to deploy workloads at Sentry
              </Text>
              .
            </>
          }
          attribution={
            <TestimonialHeader
              name="David Cramer"
              position="Co-Founder and CPO @ Sentry"
              avatar="/images/landing/quotes/david-c.jpeg"
              linkedin="https://www.linkedin.com/in/dmcramer/"
              mb={0}
            />
          }
        />
      </Box>

      <Box background="linear-gradient(to bottom, #141c28 0%, #192338 30%, #192338 70%, #141c28 100%)">
        <Box variant="bounding" py={[12, 12, '120px !important']}>
          <DevExpSection />
        </Box>
      </Box>

      <Box variant="bounding">
        <StatsSection />
      </Box>

      <Box
        background={`linear-gradient(to bottom, #141c28 0%, transparent 30%, #192338 70%, #141c28 100%), ${texture}, linear-gradient(to bottom, #141c28 0%, #192338 30%, #192338 70%, #141c28 100%)`}
      >
        <Box variant="bounding" py={[12, 12, '120px !important']}>
          <Grid
            gridTemplateColumns={['1fr', '1fr', '1fr 1.2fr']}
            gridGap={[12, 12, 13]}
            mb={[12, 12, 13]}
          >
            <Box>
              <PreHeading mb={6}>
                Don’t break the bank, OR your workloads
              </PreHeading>
              <Heading fontSize={[5, 5, '32px']} color="grey1" mb={8}>
                Challenges for right sizing
              </Heading>
              <Text fontSize="18px" color="grey2">
                You need control over how and when you scale. How do you create
                control loops that balance performance, latency, and cost?
              </Text>
            </Box>
            <Box as="img" src="/images/landing/k8s.png" width="100%" />
          </Grid>
          <Box mb={[12, 12, 13]}>
            <PrimaryFeatureBlock
              subFeatures={[
                {
                  Icon: TrendingUp,
                  heading: 'Reliability through traffic surges',
                  text: 'Keeping workloads online and running smoothly at all times can be challenging, particularly with fluctuating traffic. As demand increases, maintaining performance and reliability is critical. Scalability also needs to be codified and designed with good UX, so it’s easy to use while you’re in the heat of the moment.',
                },
                {
                  Icon: Server,
                  heading: 'Workload focused scaling for fluctuating demand',
                  text: 'As demand grows, configuring scaling settings for each workload becomes essential. Without effective auto-scaling tools it’s difficult to burst to higher capacity when needed. At the same time maintaining efficiency during steady-state loads requires minimising infrastructure to match the workload precisely, avoiding unnecessary costs.',
                },
                {
                  Icon: BarChartSquare,
                  heading: 'Dangling resources',
                  text: 'Managing resources effectively requires a high-level view of what’s running and how it’s performing. Easier said than done. A granular workload view should surface metadata like costs, runtime duration, and deployment details. Resource cleanup, including lingering scheduled jobs to temporary previews, is essential to maintain efficiency.',
                },
              ]}
              subFeaturesGrid={['1fr', '1fr', 'repeat(2, 1fr)']}
              subFeaturesGap={[12, 12, 13]}
              subFeaturesAlign="stretch"
            />
          </Box>
          <FancyQuote
            body={
              <>
                Northflank is the first batteries-included developer platform
                that doesn’t suffer from the invisible ceilings that hover over
                its competitors.{' '}
                <Text as="span" color="success" fontWeight={500}>
                  We could have built all of Slack with Northflank
                </Text>{' '}
                – and we would have, had it been available.
              </>
            }
            attribution={
              <TestimonialHeader
                name="Keith Adams"
                position="Formerly Chief Architect Slack, Facebook, VMware, GP @ Pebblebed"
                avatar="/images/landing/quotes/keith.jpeg"
                linkedin="https://linkedin.com/in/keith-adams-pb/"
                mb={0}
              />
            }
          />
        </Box>
      </Box>

      <Box py={[12, 12, '96px']}>
        <LargeCarousel
          images={[
            '/images/solutions/use-cases/backupschedule.png',
            '/images/solutions/use-cases/cluster.png',
            '/images/solutions/use-cases/clusterobserve.png',
            '/images/solutions/use-cases/dashboard.png',
            '/images/solutions/use-cases/jobruns.png',
            '/images/solutions/use-cases/logs.png',
            '/images/solutions/use-cases/metrics.png',
            '/images/solutions/use-cases/preview.png',
            '/images/solutions/use-cases/previewduration.png',
            '/images/solutions/use-cases/releaseflow.png',
          ]}
        />
      </Box>

      <Box variant="bounding">
        <UseCasesFooterLinks mb={[12, 12, 13]} />
        <GetStartedCta />
      </Box>
    </>
  );
};

export default RightSizingPage;
