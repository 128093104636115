import moment from 'moment';
import { Buffer } from 'buffer';
import slugify from 'slugify';
import isString from 'lodash/isString';
import escapeRegExp from 'lodash/escapeRegExp';
import defaults from 'lodash/defaults';
import type { EncryptedObject, EncryptionHelperWithDefaults } from '@northflank/encryption';
import { NorthflankError } from '@northflank/errors';
import { split, join } from 'shlex';
import type {
  DBAddon,
  DBAddonNative,
  DBAddonType,
  DBAddonTypeNative,
  DBAddonTypeTemplateEngine,
  DBService,
  DockerOverrideSettings,
} from '@northflank/schema-types';
import {
  AddonContainerResourceInfo,
  AddonCustomSettings,
  AddonReleaseResourceInfo,
  MysqlHACustomSettings,
  PostgresqlCustomSettings,
} from '@northflank/addons-shared';
import { V1TemplateSubdomainSpec, V1TemplateSubdomainPathSpec } from '@northflank/schema-types';
import { imageHostnameRegex } from '../regex';
import {
  blockedNFObjectLifecycleStates,
  unprocessableNFObjectLifecycleStates,
  httpPortProtocols,
  publicPortProtocols,
  V1EntityTypes,
  terminalLifecycleStates,
  ResourcePlanTypes,
  DockerCredentialProviders,
} from '../data';

export * from './generate-dns-code';
export * from './custom-metadata';
export * from './templates';
export * from './instances';
export * from './metrics';
export * from './flatten';
export * from './plans';
export * from './ceph';
export * from './byoc';

function getField(obj, path, separator = '.') {
  const properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev, curr) => prev && prev[curr], obj);
}

function setField(obj, path, value, separator = '.') {
  const properties = Array.isArray(path) ? path : path.split(separator);
  const key = properties.pop();
  const parent = properties.reduce((prev, curr) => prev && prev[curr], obj);
  parent[key] = value;
}

function convertPath(document, key) {
  let value;
  const field = getField(document, key);

  // Need to check for null as typeof null is 'object'
  if (field === null) return;
  switch (typeof field) {
    case 'object':
      if (Array.isArray(field)) value = field.map((a) => a._str);
      else if (field && field._str) value = field._str;
      else if (field && field.id) value = Buffer.from(field.id).toString('hex');
      else throw Error('Cannot convert non object array to mongoIds');

      setField(document, key, value);
      break;
    case 'string':
      // no conversion needed, already a string
      break;
    case 'undefined':
      // no conversion possible
      break;
    default:
      console.log('Unhandled convertPath field type');
  }
}

function convertDocumentPath(document, key) {
  const path = key.split('.');
  const wildcardIndex = path.findIndex((p) => p === '$');
  if (wildcardIndex === -1) {
    convertPath(document, key);
  } else {
    const parentPath = path.slice(0, wildcardIndex);
    const value = getField(document, parentPath);
    // Works here as object and array are truthy
    if (!value) return;

    const childPath = path.slice(wildcardIndex + 1, path.length).join('.');
    value.forEach((v) => convertDocumentPath(v, childPath));
  }
}

export function convertDocumentIdArray(document, convertFields) {
  ['_id', ...convertFields].forEach((key) => {
    convertDocumentPath(document, key);
  });
  return document;
}

export function getRegistryCredentialUrl(registryProvider) {
  switch (registryProvider) {
    case 'dockerhub':
      return 'https://docker.io';
    case 'gcr':
      return 'https://gcr.io';
    case 'gcr-asia':
      return 'https://asia.gcr.io';
    case 'gcr-eu':
      return 'https://eu.gcr.io';
    case 'gcr-us':
      return 'https://us.gcr.io';
    case 'gitlab':
      return 'https://registry.gitlab.com';
    case 'github':
      return 'https://ghcr.io';
    default:
      return '';
  }
}

export function getRegistryDashboardUrl({ registryProvider, registryUrl, image, sha }) {
  if (registryProvider.startsWith('gcr')) {
    const [projectComponent, imageName] = image.split(`\/`) || Array(2); // extract equivalent image owner username
    const digestComponent = sha ? `@${sha}` : ``; // direct to exact digest page, if digest available
    const regionComponent =
      registryProvider === 'gcr' ? `GLOBAL/` : `${registryProvider.substr(4).toUpperCase()}/`; // respect region
    return `https://console.cloud.google.com/gcr/images/${projectComponent}/${regionComponent}${imageName}${digestComponent}`;
  }

  switch (registryProvider) {
    case 'dockerhub': {
      const [projectComponent, imageName] = image.split(`\/`) || Array(2);
      if (projectComponent === 'library') {
        return `https://hub.docker.com/_/${imageName}`;
      }
      return `https://hub.docker.com/r/${image}`;
    }
    case 'gitlab':
      const namespace = image.split('/').slice(0, -1).join('/');
      return `https://gitlab.com/${namespace}/container_registry`;
    case 'github': {
      const imageFolder = image.match(/(.+)\/(.+)/)?.[1];
      return imageFolder ? `https://ghcr.io/${image}` : `https://github.com/`;
    }
    default:
      return `${registryUrl}`;
  }
}

const providerAliases = [
  { domain: 'docker.io', alias: 'dockerhub' },
  { domain: 'index.docker.io', alias: 'dockerhub' },
  { domain: 'index.docker.io/v1/', alias: 'dockerhub' },
  { domain: 'registry.hub.docker.com', alias: 'dockerhub' },
  { domain: 'gcr.io', alias: 'gcr' },
  { domain: 'asia.gcr.io', alias: 'gcr-asia' },
  { domain: 'eu.gcr.io', alias: 'gcr-eu' },
  { domain: 'us.gcr.io', alias: 'gcr-us' },
  { domain: 'registry.gitlab.com', alias: 'gitlab' },
  { domain: 'ghcr.io', alias: 'github' },
  // Just keep this so it throws an error in the backend if we try to verify with ghcr.io credentials
  { domain: 'docker.pkg.github.com', alias: 'github-pkg' },
];

export const authExceptions = [
  { provider: 'dockerhub', authenticate: 'https://index.docker.io/v1' },
];
// Append protocol to registry URL if not specified
export const appendProtocol = (url) => {
  if (!url) return;
  return !url.match(/^https?:\/\//) ? `https://${url}` : url;
};

// @ converts from 'https://registry.hub.docker.com' to 'dockerhub'
//
export const getRegistryProvider = (_registryUrl) => {
  if (!_registryUrl) return 'custom';
  const registryUrl = _registryUrl.endsWith('/') ? _registryUrl.slice(0, -1) : _registryUrl;
  const [_, registry] = registryUrl.match(/^(?:https?:\/\/)?(.*)$/) || Array(2);

  if (registry.endsWith('amazonaws.com') && registryUrl.match(/\.ecr\./)) {
    return DockerCredentialProviders.ECR;
  }
  return providerAliases.find((provider) => provider.domain === registry)?.alias || 'custom';
};

// @ converts from 'dockerhub' to 'index.docker.io/v1'
//
export const getProviderAuthException = (provider) => {
  if (!provider) return;
  return authExceptions.find((p) => p.provider === provider)?.authenticate || null;
};

// @ Returns values in the form:
//
// {
//   image                // ex. 'library/nginx'
//   tag                  // ex. 'latest'
//   sha                  // ex. 'sha256:abc...'
//   registryUrl          // ex. 'https://eu.gcr.io'
//   imagePath            // ex. 'nginx:latest'
//   registryProvider     // ex. 'gcr-eu'
// };
//
export const extractImageHostnameComponents = (imageHostname) => {
  // TODO: there may be a better implementation of this in northflank/docker-registry-client/src/names
  if (!imageHostname) return {};
  let [_1, registryUrl, _2, image, tag, sha] = imageHostname.match(imageHostnameRegex) || Array(5); // handle match not returning values
  let registryProvider;
  if (!image) return {};

  // If a registry URL was provided, extract the provider:
  if (registryUrl) {
    // User provided registry url in hostname - check what registry is used
    registryProvider = getRegistryProvider(registryUrl);
  }

  // No registry url was provided in hostname - default to dockerhub or the registryProvider is dockerhub, in which case we use a different url
  if (!registryUrl || registryProvider === 'dockerhub') {
    registryUrl = 'docker.io';
    registryProvider = 'dockerhub';
  }

  // if user didn't specify a registry username with dockerhub, default to library
  if (registryProvider === 'dockerhub') {
    if (image.indexOf(`/`) === -1) {
      image = `library/${image}`;
    }
  }

  const account = image.match(`^([a-zA-Z0-9-_.]*)`)[0];

  registryUrl = appendProtocol(registryUrl);

  return {
    image,
    account,
    tag,
    sha,
    registryUrl,
    registryProvider,
    imagePath: tag ? `${image}:${tag}` : `${image}@${sha}`,
  };
};

export const convertPortProtocol = (protocol) => {
  switch (protocol.toUpperCase()) {
    case 'HTTP':
    case 'HTTP/2':
    case 'TCP':
      return 'TCP';
    case 'UDP':
      return 'UDP';
    default:
      throw new NorthflankError({ code: 500, message: 'Unsupported port protocol' });
  }
};

export const convertProtocolToAppProtocol = (protocol) => {
  switch (protocol.toUpperCase()) {
    case 'HTTP':
      return 'http';
    case 'HTTP/2':
      return 'http2';
    case 'TCP':
      return 'tcp';
    case 'UDP':
      return 'udp';
    default:
      throw new NorthflankError({ code: 500, message: 'Unsupported port protocol' });
  }
};

export function parseManifestResponseForPorts(exposedPorts) {
  if (!exposedPorts) return [];

  const ports: any = [];
  const keys = Object.keys(exposedPorts);

  keys.forEach((key, index) => {
    const internalPort = parseInt(key.match(/[0-9]+/), 10);
    let protocol = String(key.match(/[tcp|udp]+/i)).toUpperCase();
    let portPublic = true;

    switch (protocol) {
      case 'TCP':
        portPublic = false;
        break;
      case 'UDP':
        portPublic = false;
        break;
      default:
        protocol = 'HTTP';
    }

    ports.push({
      internalPort,
      protocol,
      name: generatePortName(index),
      public: portPublic,
      key: `docker${index}`,
    });
  });

  return ports;
}

export const reformatAddonKey = (string) => string.replace(/-/g, '_').toUpperCase();

export const camelToSnakeCase = (str) =>
  str.replace(/[a-z][A-Z]/g, (letters) => {
    const [lower, upper] = letters.split('');
    return `${lower}_${upper}`;
  });

export const snakeCaseToCamel = (str) =>
  str.replace(/[a-z]_[a-z]/g, (letters) => {
    const [lower, upper] = letters.split('_');
    return `${lower}${upper.toUpperCase()}`;
  });

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.substring(1);

export const getDefaultPM = (customer) =>
  customer?.stripeCustomer?.invoice_settings?.default_payment_method ||
  customer?.stripeCustomer?.default_source;

/** ADDONS */
/** Retrieves all possible addon version from a given current version, taking into account the feature flag for minor/major upgrades for this addon type
 *  input: currentVersion: string, upgradePath: upgrade structure (addon-types collection -> upgrade path)
 * */
export function getSupportedUpgradesForCurrentVersion(currentVersion, addonTypeDefinition) {
  const upgradeTo = getMetaInfoForCurrentVersion(
    currentVersion,
    addonTypeDefinition.upgradePath
  )?.upgradeTo;
  const filteredForFeatureFlag = upgradeTo?.filter((upgrade) => {
    const upgradeType = upgrade.type === 'major' ? 'upgradeMajor' : 'upgradeMinor';
    return addonTypeDefinition.features[upgradeType] === true;
  });
  return filteredForFeatureFlag;
}
/** Retrieves info for current version such as lifecycleStatus, discontinuedBy date etc.
 *  input: currentVersion: string, upgradePath: upgrade structure (addon-types collection -> upgrade path)
 * */
export function getMetaInfoForCurrentVersion(
  currentVersion: string,
  upgradePath: DBAddonTypeNative['upgradePath']
): DBAddonTypeNative['upgradePath']['majors'][0]['minors'][0] | undefined {
  const currentMajorVersionPath = upgradePath?.majors?.find((m) =>
    currentVersion?.startsWith(m?.version)
  );
  return currentMajorVersionPath?.minors?.find((minor) => minor?.version === currentVersion);
}
/** Retrieves currently installable addon versions, string[]
 *  input: upgradePath: upgrade structure (addon-types collection -> upgrade path)
 * */
export function getInstallableVersions(upgradePath: DBAddonTypeNative['upgradePath']) {
  return upgradePath?.majors
    ?.map((m) => m.minors)
    .flat()
    .filter((m) => m.lifecycleStatus === 'active')
    .map((m) => m.version);
}

/** Validate backup version for fork source
 * */
export function getMajorVersionInfo(
  upgradePath: DBAddonTypeNative['upgradePath'],
  version: string
) {
  return upgradePath?.majors?.find((maj) => maj.minors.find((min) => min.version === version));
}

/** Validate backup version for fork source
 * */
export function isVersionSuitableAsForkSource(
  upgradePath: DBAddonTypeNative['upgradePath'],
  backupVersion: string,
  newAddonVersion: string
): boolean {
  const backupMajor = getMajorVersionInfo(upgradePath, backupVersion);
  const newAddonMajor = getMajorVersionInfo(upgradePath, newAddonVersion)?.version;

  if (!backupMajor || !newAddonMajor || backupMajor.version !== newAddonMajor) {
    return false;
  }

  const backupIndex = backupMajor!.minors.findIndex((min) => min.version === backupVersion);
  const addonIndex = backupMajor!.minors.findIndex((min) => min.version === newAddonVersion);

  // ensure backup version is older than or the same as new addon version, therefore the index should be bigger as newest versions are first in array
  return addonIndex <= backupIndex;
}

/** Retrieves currently installable major addon versions, string[]
 *  input: upgradePath: upgrade structure (addon-types collection -> upgrade path)
 * */
export function getInstallableMajorVersions(upgradePath) {
  return upgradePath?.majors?.filter((m) => m.lifecycleStatus === 'active').map((m) => m.version);
}

/**
 * This function returns replica count for additional sts or deployments for addons.
 * Currently used for postgres HA but might be extended by other addons in the future as well.
 * Edit: adapted for mysqlha as well.
 * Implemented here as it's also used in frontend.
 * @param addon addon db schema
 */
export function getAdditionalAddonReplicas(
  spec: DBAddonNative['spec']
): { count: number; type: 'postgres-ha-pooler' | string }[] {
  const customSettings = spec?.config?.customSettings as AddonCustomSettings | undefined;

  if (spec?.type === 'postgresql') {
    const typedCustomSettings = customSettings as PostgresqlCustomSettings | undefined;
    const poolerNodeCount = typedCustomSettings?.poolerEnabled
      ? (typedCustomSettings?.poolerReplicas ?? 2)
      : 0;
    const pgReplicas = spec.config.deployment.replicas;
    const readOnlyPoolerNodeCount =
      pgReplicas > 1 && typedCustomSettings?.readOnlyPoolerEnabled
        ? (typedCustomSettings?.readOnlyPoolerReplicas ?? 2)
        : 0;

    return [{ type: 'postgres-ha-pooler', count: poolerNodeCount + readOnlyPoolerNodeCount }];
  }

  if (spec?.type === 'mysqlha') {
    const typedCustomSettings = customSettings as MysqlHACustomSettings | undefined;
    const routerNodeCount = typedCustomSettings?.routerReplicas ?? 1;
    return [{ type: 'router', count: routerNodeCount }];
  }

  return [];
}

/**
 * This function returns replica count for an addon (respects template-based addon types)
 */
export function getAddonReplicaCount(addon: DBAddon): number {
  if (addon.spec.provisionerType === 'templateEngine') {
    if (addon.statusExtended?.addonReleaseResourceInfo !== undefined) {
      const k8sWorkloadResources =
        addon?.statusExtended?.addonReleaseResourceInfo?.k8sWorkloadResources;
      const podReplicas = k8sWorkloadResources?.filter((r) => r.kind === 'Pod').length;

      return podReplicas + k8sWorkloadResources?.reduce((sum, i) => sum + (i.replicas ?? 0), 0);
    }
    return 0;
  }
  const nativeSpec = addon.spec as DBAddonNative['spec'];
  const addRepCount = getAdditionalAddonReplicas(nativeSpec)
    .map((r) => r.count)
    .reduce((pSum, a) => pSum + a, 0);
  return nativeSpec.config.deployment.replicas + addRepCount;
}

export function getAddonDaemonSetsCount(addon: DBAddon): number {
  if (addon.spec.provisionerType === 'templateEngine') {
    if (addon.statusExtended?.addonReleaseResourceInfo !== undefined) {
      const k8sWorkloadResources =
        addon?.statusExtended?.addonReleaseResourceInfo?.k8sWorkloadResources;
      const daemonSetCount = k8sWorkloadResources?.filter((r) => r.kind === 'DaemonSet').length;

      return daemonSetCount;
    }
  }
  return 0;
}

export const getResourcesFromConfig = (resourceConfiguration) => {
  if (!resourceConfiguration?.type) {
    return {
      cpu: resourceConfiguration.cpu,
      memory: resourceConfiguration.memory,
    };
  }

  const {
    type,
    resources: { cpu, memory },
  } = resourceConfiguration;

  switch (type) {
    case ResourcePlanTypes.NF: {
      return { cpu: cpu.resources.limit, memory: memory.resources.limit };
    }
    case ResourcePlanTypes.K8S: {
      const resources = { cpu: cpu.resources.limit, memory: memory.resources.limit };
      // if cpu is set to unbounded, then delete cpu limit
      if (cpu.options?.unbounded) {
        resources.cpu = cpu.resources.request;
        resources.cpuUnbounded = true;
      }
      return resources;
    }
    default:
      throw new Error('Unsupported plan resource type');
  }
};

export const getResourcesFromPlan = (plan) => {
  return getResourcesFromConfig(
    plan?.configuration ?? {
      cpu: plan?.cpuResource,
      memory: plan?.ramResource,
    }
  );
};

/**
 * Total addon resources
 */
export function getTotalAddonResources(addon: DBAddon): {
  vCpu: number;
  memory: number;
  hasUnboundedvCpu?: boolean;
  hasUnboundedMemory?: boolean;
} {
  const sum = (a?: number[]) => a?.reduce((s, i) => s + i, 0) ?? 0;

  if (addon.spec.provisionerType === 'templateEngine') {
    if (addon.statusExtended?.addonReleaseResourceInfo !== undefined) {
      const { k8sWorkloadResources } = addon?.statusExtended
        ?.addonReleaseResourceInfo as AddonReleaseResourceInfo['releaseStatus'];
      const rLimits = k8sWorkloadResources?.flatMap((p) =>
        p?.containers?.map((c) => getAddonContainerResourcesWithDefaults(c?.resources)?.limits)
      );

      return {
        vCpu: sum(rLimits?.map((r) => r?.cpu ?? 0)),
        memory: sum(rLimits?.map((r) => r?.memory ?? 0)) / 1000 / 1000,
        hasUnboundedvCpu: rLimits.some((r) => r?.cpu === undefined),
        hasUnboundedMemory: rLimits.some((r) => r?.memory === undefined),
      };
    }
    return { vCpu: 0, memory: 0 };
  }
  const nativeSpec = addon.spec as DBAddonNative['spec'];

  const customSettings = nativeSpec?.config?.customSettings as AddonCustomSettings | undefined;

  const { replicas, resources: _resources } = nativeSpec.config.deployment;

  const resources = getResourcesFromConfig(_resources);
  const { cpu, memory } = resources;

  const baseVcpu = replicas * cpu;
  const baseMemory = replicas * memory;
  if (addon.spec?.type === 'postgresql') {
    const typedCustomSettings = customSettings as PostgresqlCustomSettings | undefined;
    const additionalReplicas = getAdditionalAddonReplicas(nativeSpec);
    const poolerReplicas =
      additionalReplicas.find((t) => t.type === 'postgres-ha-pooler')?.count ?? 0;
    const poolerResources = getPostgresqlPoolerResourcesFrontend(
      { cpu, memory },
      typedCustomSettings
    );
    const poolerVcpu = poolerReplicas * poolerResources.poolerCpu;
    const poolerMemory = poolerReplicas * poolerResources.poolerMemory;
    return { vCpu: baseVcpu + poolerVcpu, memory: baseMemory + poolerMemory };
  }
  if (addon.spec?.type === 'mysqlha') {
    const typedCustomSettings = customSettings as MysqlHACustomSettings | undefined;
    const additionalReplicas = getAdditionalAddonReplicas(nativeSpec);
    const routerReplicas = additionalReplicas.find((t) => t.type === 'router')?.count ?? 0;
    const routerResources = getMysqlHaRouterResourcesFrontend({ cpu, memory }, typedCustomSettings);
    const routerVcpu = routerReplicas * routerResources.routerCpu;
    const routerMemory = routerReplicas * routerResources.routerMemory;
    return { vCpu: baseVcpu + routerVcpu, memory: baseMemory + routerMemory };
  }
  return { vCpu: baseVcpu, memory: baseMemory };
}

export const addonPostgresqlConnectionPoolerDefaultMaxMemory = 2048;
export const addonPostgresqlConnectionPoolerDefaultMaxCPU = 1;

export const addonPostgresqlConnectionPoolerOptionsRead = [1, 2, 3];
export const addonPostgresqlConnectionPoolerOptionsPrimary = [1, 2, 3];

export const addonMysqlHaRouterReplicaOptions = [1, 2, 3];

export function getPostgresqlPoolerResourcesFrontend(
  resources: { cpu: number; memory: number },
  customSettings?: PostgresqlCustomSettings
) {
  let cpuLimit = resources.cpu;
  if (!cpuLimit) cpuLimit = addonPostgresqlConnectionPoolerDefaultMaxCPU;
  const poolerMemory =
    customSettings?.pgBouncerMemory ??
    Math.min(resources.memory, addonPostgresqlConnectionPoolerDefaultMaxMemory);
  const poolerCpu =
    customSettings?.pgBouncerCpu ??
    Math.min(cpuLimit, addonPostgresqlConnectionPoolerDefaultMaxCPU);

  return { poolerMemory, poolerCpu };
}

export const addonPostgresqlConnectionPoolerBounds = {
  primary: {
    min: addonPostgresqlConnectionPoolerOptionsPrimary[0],
    max: addonPostgresqlConnectionPoolerOptionsPrimary[
      addonPostgresqlConnectionPoolerOptionsPrimary.length - 1
    ],
    replicaOptions: addonPostgresqlConnectionPoolerOptionsPrimary,
    default: 2,
  },
  readOnly: {
    min: addonPostgresqlConnectionPoolerOptionsRead[0],
    max: addonPostgresqlConnectionPoolerOptionsRead[
      addonPostgresqlConnectionPoolerOptionsRead.length - 1
    ],
    replicaOptions: addonPostgresqlConnectionPoolerOptionsRead,
    default: 2,
  },
};

export const addonMysqlHaRouterDefaultMaxMemory = 2048;
export const addonMysqlHaRouterDefaultMaxCPU = 1;

export const addonMysqlHaRouterBounds = {
  min: addonMysqlHaRouterReplicaOptions[0],
  max: addonMysqlHaRouterReplicaOptions[addonMysqlHaRouterReplicaOptions.length - 1],
  replicaOptions: addonMysqlHaRouterReplicaOptions,
  default: 2,
};

export function getMysqlHaRouterResourcesFrontend(
  resources: { cpu: number; memory: number },
  customSettings?: MysqlHACustomSettings
) {
  let cpuLimit = resources.cpu;
  if (!cpuLimit) cpuLimit = addonMysqlHaRouterDefaultMaxCPU;
  const routerMemory =
    customSettings?.routerMemory ?? Math.min(resources.memory, addonMysqlHaRouterDefaultMaxMemory);
  const routerCpu =
    customSettings?.routerCpu ?? Math.min(cpuLimit, addonMysqlHaRouterDefaultMaxCPU);

  return { routerMemory, routerCpu };
}

export const addonMysqlHaSidecarDefaultMaxMemory = 1024;
export const addonMysqlHaSidecarDefaultMaxCPU = 1;

// ADDON-TYPES
export const addonTypeMaxBundleUploadSize = 10 * 1024 * 1024; // 10MB

export const addonTypeFeatures: Array<keyof DBAddonType['features']> = [
  'backups',
  'backupsSnapshot',
  'backupsScheduled',
  'customDbName',
  'pitr',
  'importDump',
  'importLive',
  'importUpload',
  'scaleStorage',
  'scaleReplicas',
  'scalePlan',
  'upgradeMinor',
  'upgradeMajor',
  'forkAddon',
  'internalNfDomain',
  'tls',
  'externalAccess',
  'networkTcpTlsTransition',
  'networkIpPolicies',
  'pause',
  'redeploy',
  'reset',
  'zonalRedundancyDeployment',
];

export const addonTypeFeaturesDisabled = Object.fromEntries(
  addonTypeFeatures.map((f) => [f, false])
) as Record<keyof DBAddonType['features'], boolean>;

export const addonTypeFeaturesDefault = Object.fromEntries(
  addonTypeFeatures.map((f) =>
    ['pause', 'redeploy', 'reset'].includes(f) ? [f, true] : [f, false]
  )
) as Record<keyof DBAddonType['features'], boolean>;

export const getAddonTypeTemplateRelevantFeatures: {
  feature: Partial<typeof addonTypeFeatures>[1];
  name?: string;
}[] = [
  { feature: 'pause', name: 'Pause' },
  { feature: 'redeploy', name: 'Redeploy' },
  { feature: 'reset', name: 'Reset Addon' },
  // { feature: 'backupsSnapshot', name: 'Allow creation of disk backups' },
  // { feature: 'backupsScheduled', name: 'Allow creation of backup schedules' },
];

export const addonTypeTemplateConfig: Array<keyof DBAddonTypeTemplateEngine['config']> = [
  'showTemplateValues',
  'enableTemplateValuesModification',
  'enableErrorRecovery',
  'installCrds',
  'useNfLabelsAndAnnotations',
  'useNfSecretInjection',
  'useNfImagePullSecret',
  // 'useNfServiceAccount',
];

export const addonTypeTemplateConfigDisabled = Object.fromEntries(
  addonTypeTemplateConfig.map((f) => [f, false])
) as Record<keyof DBAddonTypeTemplateEngine['config'], boolean>;

export const addonTypeTemplateConfigDefault = Object.fromEntries(
  addonTypeTemplateConfig.map((f) =>
    [
      'showTemplateValues',
      'enableTemplateValuesModification',
      'enableErrorRecovery',
      'useNfLabelsAndAnnotations',
      'useNfImagePullSecret',
    ].includes(f)
      ? [f, true]
      : [f, false]
  )
) as Record<keyof DBAddonTypeTemplateEngine['config'], boolean>;

export const getAddonTypeTemplateRelevantConfig: {
  config: (typeof addonTypeTemplateConfig)[1];
  name?: string;
  description?: string;
}[] = [
  { config: 'showTemplateValues', name: 'Show template values' },
  { config: 'enableTemplateValuesModification', name: 'Enable modifying template values' },
  { config: 'enableErrorRecovery', name: 'Enable error recovery' },
  {
    config: 'useNfLabelsAndAnnotations',
    name: 'Use Northflank labels and annotations on Kubernetes resources',
    description: 'Required for container list view',
  },
  {
    config: 'installCrds',
    name: 'Install CRDs provided in resource bundle',
    description: 'Only relevant if resource bundle includes CRDs',
  },
  {
    config: 'useNfSecretInjection',
    name: 'Use Northflank secret injection',
    description:
      'Adapts pod secrets, container command, probes to enable full integration with Northflank',
  },
  {
    config: 'useNfServiceAccount',
    name: 'Use Northflank service account',
    description:
      'No access control resources such as roles, role bindings and service accounts will be created',
  },
  {
    config: 'useNfImagePullSecret',
    name: 'Use default Northflank image pull secret',
    description: 'Disable if template creates a custom image pull secret',
  },
];

// Resources overrides in case custom addon type container has no resources set
export const getAddonContainerResourcesWithDefaults = (
  resources?: AddonContainerResourceInfo['resources']
): Required<AddonContainerResourceInfo['resources']> => {
  return {
    requests: {
      cpu: resources?.requests?.cpu ?? resources?.limits?.cpu, // ??  0.5,
      memory: resources?.requests?.memory ?? resources?.limits?.memory, // ?? 500000000, // 500000000 = 500m
    },
    limits: {
      cpu: resources?.limits?.cpu ?? resources?.requests?.cpu, // ?? 2,
      memory: resources?.limits?.memory ?? resources?.requests?.memory, // ?? 2000000000, // 2000000000 = 2000M
    },
  };
};

// ADDON-TYPES END

export enum LifecycleStates {
  CREATING = 'creating',
  CREATED = 'created',
  CREATION_FAILED = 'creation_failed',
  UPGRADE_PENDING = 'upgrade_pending',
  UPGRADING = 'upgrading',
  UPGRADE_FAILED = 'upgrade_failed',
  MIGRATING = 'migrating',
  MIGRATION_FAILED = 'migration_failed',
  DELETING = 'deleting',
  DELETION_FAILED = 'deletion_failed',
  DELETED = 'deleted',
  CLUSTER_DELETED = 'cluster_deleted',
  INITIALIZING = 'initializing',
}

export const getLifecycleState = (object) => object.lifecycleStatus;

export const isInBlockedLifecycleStatus = (object) =>
  blockedNFObjectLifecycleStates.includes(object.lifecycleStatus);

export const isInValidLifecycleState = (object) =>
  !unprocessableNFObjectLifecycleStates.includes(object.lifecycleStatus);

export const isInTerminalLifecycleStatus = (object) =>
  terminalLifecycleStates.includes(object.lifecycleStatus);

export const convertStorageDisplayUnit = (storageSize) => parseInt(`${storageSize / 1024}`, 10);

export const getAddonTypeResources = (addonType, provider) =>
  defaults(
    addonType?.providerResourceOverrides?.find((override) => override.provider === provider)
      ?.resources || {},
    addonType?.resources || {}
  );

export const hasTemplateSyntax = (string) => /\${(template\.)?(refs|args)\./.test(string);

export const getRepoName = (url) => {
  if (url && typeof url === 'string') {
    if (hasTemplateSyntax(url)) return url;
    const sp = url.split('/');
    return `${sp[sp.length - 2]}/${sp[sp.length - 1]}`;
  }
  return null;
};

export const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

const MAX_RECURSION_DEPTH = 5;
export const templatingRegex = /(\$\{[^}]+\})/g;

export const stripLeadingSlash = (str) => (str?.startsWith('/') ? str.replace('/', '') : str);

export const stripMetaCharacters = (str) => str.substr(2, str.length - 3);

export const assembleObject = (environment) => {
  const object = {};

  Object.entries(environment).forEach(([key, value]) => {
    if (value !== null && typeof value === 'object') {
      object[key] = JSON.stringify(value);
    } else {
      object[key] = value;
    }
  });

  return object;
};

const recursiveReplace = (environment, depth) => {
  const completed = {};
  const uncompleted = {};

  let recursionRequired = false;

  Object.entries(environment).forEach(([key, value]) => {
    // @ts-ignore
    const convertedValue: string =
      value !== null && typeof value === 'object' ? JSON.stringify(value) : value;
    // Check if it contains a key to replace
    if (convertedValue.match(templatingRegex)) {
      uncompleted[key] = convertedValue;
      // Otherwise just write values to the completed object
    } else {
      completed[key] = convertedValue;
    }
  });

  // Iterate over key value pairs which need templates substituted
  Object.entries(uncompleted).forEach(([key, value]) => {
    // Get template matches
    // @ts-ignore
    const matches = value.match(templatingRegex);
    const matchValues = matches.map((m) => {
      const sM = stripMetaCharacters(m);
      const substitution = completed[sM];

      // Might be possible to have a better check here, but not sure yet
      if (
        (substitution && substitution.match(templatingRegex)) ||
        (!substitution && environment[sM])
      ) {
        recursionRequired = true;
      }
      return substitution;
    });

    // Iterate over template matches and replace with values from completed object, if no value is found to substitute, replace with the key again
    completed[key] = matches.reduce((a, v, i) => {
      return a.replace(v, matchValues[i] ?? `${v}`);
    }, value);
  });

  if (depth < MAX_RECURSION_DEPTH - 1 && recursionRequired) {
    return recursiveReplace(deepCopy(completed), depth + 1);
  }
  return completed;
};

type ReplaceTemplatedValuesInFilesResult = {
  success: boolean;
  substitutedObject: Record<string, { data: string; encoding?: string }>;
};

export const replaceTemplatedValuesInFiles = (
  files,
  values
): ReplaceTemplatedValuesInFilesResult => {
  const substitutedFiles = {};
  Object.entries(files).forEach(([key, file]) => {
    const { data, encoding } = JSON.parse(file as string);

    // Only want to substitute values in case the file is a text file
    if (encoding !== 'utf-8') {
      substitutedFiles[key] = { data, encoding };
    } else {
      const convertedData = Buffer.from(data, 'base64').toString();
      // @ts-ignore
      const convertedValue: string =
        convertedData !== null && typeof convertedData === 'object'
          ? JSON.stringify(convertedData)
          : convertedData;
      // Check if it contains a key to replace

      const matches = convertedValue.match(templatingRegex);

      // If no template strings were found, return early
      if (!Array.isArray(matches)) {
        substitutedFiles[key] = { data: Buffer.from(convertedData).toString('base64'), encoding };
        return;
      }

      const matchValues = (matches as string[]).map((m) => {
        const sM = stripMetaCharacters(m);
        const substitution = values[sM];

        return substitution;
      });

      // Iterate over template matches and replace with values from completed object, if no value is found to substitute, replace with the key again
      const substitutedFile = (matches as string[]).reduce((a, v, i) => {
        return a.replace(v, matchValues[i] ?? `${v}`);
      }, convertedValue);

      substitutedFiles[key] = {
        data: Buffer.from(substitutedFile).toString('base64'),
        encoding,
      };
    }
  });

  return {
    success: true,
    substitutedObject: substitutedFiles,
  };
};

export const replaceTemplatesAndAssembleObject = (environment) => {
  const completed = recursiveReplace(deepCopy(environment), 0);
  const missingValues = {};
  Object.entries(completed).forEach(([_, v]) => {
    // @ts-ignore
    const missing = v.match(templatingRegex);
    if (Array.isArray(missing)) {
      missing.forEach((t) => {
        missingValues[t] = `Template key not found`;
      });
    }
  });

  return {
    success: Object.keys(missingValues).length === 0,
    substitutedObject: completed,
    missingValues,
  };
};

// takes the formik variables array and transforms it into a template-resolved object
export const variableArrayToSubstitutedObj = (variables) => {
  const vars = variables.reduce((acc, { key, value }) => {
    acc[key] = value;
    return acc;
  }, {});
  return replaceTemplatesAndAssembleObject(vars);
};

export const combineObjects = (objectArr) => {
  let tmpObject = {};

  // We only want to replace on top level keys
  objectArr.forEach((o) => {
    // @ts-ignore
    tmpObject = { ...tmpObject, ...o };
  });

  return tmpObject;
};

export const combineVariableObjects = async (
  encryptedObjectArray: EncryptedObject[],
  encryptionHelper: EncryptionHelperWithDefaults
) => {
  const relevantObjects = encryptedObjectArray.filter((o) => encryptionHelper.canDecrypt(o));
  const decryptedObjectArray = await Promise.all(
    relevantObjects.map(async (o) => encryptionHelper.decryptObject(o))
  );

  return combineObjects(decryptedObjectArray);
};

export const enrichGroupedDataWithSubstitutionResults = (data) => {
  // Just a shallow copy, mainly to avoid lint complaints.
  const enrichedData = { ...data };

  if (data.secrets?.length) {
    enrichedData.secrets = data.secrets.map((s) => {
      const combined = combineObjects([s.variables, ...s.addons.map((a) => a.variables)]);
      return { ...s, substitutionResult: replaceTemplatesAndAssembleObject(combined) };
    });
  }
  if (data.variables) {
    const combined = combineObjects([
      data.variables,
      ...enrichedData.secrets.map((s) => s.substitutionResult?.substitutedObject || {}),
    ]);
    enrichedData.substitutionResult = replaceTemplatesAndAssembleObject(combined);
  }

  return enrichedData;
};

export const parseDockerfileResponse = (provider, result) => {
  let decodedDockerfile: string | null = null;

  // Decode Dockerfile from result
  switch (provider) {
    case 'github':
      if (result.sha) {
        decodedDockerfile = Buffer.from(result.content, 'base64').toString('utf-8');
      } else {
        throw new NorthflankError({
          code: 409,
          message: 'Github response had no SHA. Could not decode dockerfile.',
        });
      }
      break;
    case 'bitbucket':
      if (!result.error) {
        decodedDockerfile = result;
      } else {
        throw new NorthflankError({
          code: 409,
          message: `Bitbucket Dockerfile check encountered an error: ${result.error}`,
        });
      }
      break;
    case 'gitlab':
      if (result.ref) {
        decodedDockerfile = Buffer.from(result.content, 'base64').toString('utf-8');
      } else {
        throw new NorthflankError({
          code: 409,
          message: `Gitlab dockerfile check encountered an error: ${result.error}`,
        });
      }
      break;
    case 'self-hosted':
      if (result.ref) {
        decodedDockerfile = Buffer.from(result.content, 'base64').toString('utf-8');
      } else {
        throw new NorthflankError({
          code: 409,
          message: 'Self-hosted registry result had no REF. Cannot decode dockerfile.',
        });
      }
      break;
    case 'azure':
      decodedDockerfile = result;
      break;
    case 'string':
      // no conversion needed, already a string
      break;
    case 'undefined':
      // no conversion possible
      break;
    default:
  }

  if (decodedDockerfile === null) {
    throw new NorthflankError({ code: 500, message: 'Dockerfile decoding failed!' });
  }

  return decodedDockerfile;
};

const charactersToDelete = [`'`, '.', '_'];
export const getInternalId = (name) =>
  name &&
  slugify(charactersToDelete.reduce((str, char) => str.replaceAll(char, ''), name)).toLowerCase();

export const getInternalBackupId = (name) => slugify(name).toLowerCase().replace(/:/g, '-');

export const generatePortName = (_index) => {
  const index = _index + 1;
  return index < 10 ? `p0${index}` : `p${index}`;
};

export const RANDOM_B64_MAX_LENGTH = 4096;

export const randomBase64 = (stringLength) => {
  let length = parseInt(stringLength, 10);
  length = Number.isNaN(length) ? 32 : length;
  length = Math.max(length, 1);
  length = Math.min(length, RANDOM_B64_MAX_LENGTH);

  const arr = new Uint8Array(length);
  crypto.getRandomValues(arr);

  return btoa(String.fromCharCode.apply(null, arr)).slice(0, length);
};

export const getRepositoryDetails = (
  projectUrl: string
): { repositoryName: string; repositoryOwner: string; repositoryProject?: string } => {
  let transformedString = projectUrl.replace('https://', '');
  transformedString = transformedString.replace('http://', '');
  const urlSplit = transformedString.split('/').filter((x) => !!x);

  // Azure specific handling
  if (projectUrl.includes('/_git/')) {
    if (urlSplit.length === 4) {
      return {
        repositoryOwner: urlSplit[1],
        repositoryName: urlSplit[3],
        repositoryProject: urlSplit[3],
      };
    }
    return {
      repositoryOwner: urlSplit[1],
      repositoryName: urlSplit[4],
      repositoryProject: urlSplit[2],
    };
  }

  const repositoryOwner = urlSplit.slice(1, 2)[0];
  const repositoryName = urlSplit.slice(2).join('/');

  return {
    repositoryName,
    repositoryOwner,
  };
};

export const getPullRequestUrl = (vcsService, repoUrl, pullRequestId) => {
  switch (vcsService) {
    case 'github':
      return `${repoUrl}/pull/${pullRequestId}`;
    case 'gitlab':
      return `${repoUrl}/merge_requests/${pullRequestId}`;
    case 'bitbucket':
      `${repoUrl}/pull-requests/${pullRequestId}`;
    default:
      return repoUrl;
  }
};

// deeply merge one object (modifier) into another (original), overriding existing keys
export const deepObjectMerge = (original = {}, modifier) => {
  if (!modifier) return original;
  const copy = { ...original };
  for (const [key, value] of Object.entries(modifier)) {
    const valueIsObject = typeof value === 'object' && !Array.isArray(value) && value !== null;
    if (valueIsObject) {
      copy[key] = deepObjectMerge(copy[key], value);
    } else {
      copy[key] = value;
    }
  }
  return copy;
};

// compare two objects and return the diff
export const deepObjectDiff = (original = {}, compare = {}) => {
  const copy = JSON.parse(JSON.stringify(compare));
  for (const [k, v] of Object.entries(original)) {
    if (typeof v === 'object' && v !== null && !Array.isArray(v)) {
      if (!copy.hasOwnProperty(k)) {
        copy[k] = v;
      } else {
        const diff = deepObjectDiff(v, copy?.[k]);
        if (Object.keys(diff).length) {
          copy[k] = diff;
        } else {
          delete copy?.[k];
        }
      }
    } else if (
      (Array.isArray(v) && JSON.stringify(v) === JSON.stringify(copy?.[k])) ||
      Object.is(v, copy?.[k])
    ) {
      delete copy?.[k];
    }
  }
  return copy;
};

export const jobRunTerminatedDueToExpiry = (run) =>
  run.status === 'FAILED' && run.endTimestamp >= moment(run.expiryDate).unix();

export const isMongoId = (candidate) => !!candidate.toString().match(/^[0-9a-fA-F]{24}$/);

// test if two or more backup schedules are going to clash and overlap
export const getClashingSchedules = (thisSchedule, otherSchedules: any[] = []) => {
  const clashing = [];

  otherSchedules.forEach((otherSchedule) => {
    // we don't worry about overlap if backup types are different
    if (thisSchedule.backupType !== otherSchedule.backupType) return;

    // we only need to compare clashing hours if one schedule is weekly and the other is daily
    const compareHours =
      (thisSchedule.scheduling.interval === 'weekly' &&
        otherSchedule.scheduling.interval === 'daily') ||
      (thisSchedule.scheduling.interval === 'daily' &&
        otherSchedule.scheduling.interval === 'weekly');
    let hoursClash = false;

    // check if both schedules include the same hour
    if (compareHours) {
      hoursClash = Array.from(thisSchedule.scheduling.hour).some((hour) =>
        Array.from(otherSchedule.scheduling.hour).includes(hour)
      );
    }

    // if we didn't need to compare hours or we did and hours match, check if both schedules include the same minute (15 min window)
    if (!compareHours || (compareHours && hoursClash)) {
      const minutesClash = Array.from(thisSchedule.scheduling.minute).some((minute) =>
        Array.from(otherSchedule.scheduling.minute).includes(minute)
      );
      // if the minutes match, then these schedules clash
      if (minutesClash) {
        clashing.push(thisSchedule.scheduling.interval);
        clashing.push(otherSchedule.scheduling.interval);
      }
    }
  });

  return {
    schedule: thisSchedule.scheduling.interval,
    clashesWith: clashing.filter((c) => c && c !== thisSchedule.scheduling.interval),
  };
};

export const getTotalBackupsMade = (repeat, days, hours, minutes, retention) => {
  const nDays = repeat === 'weekly' ? days.length : 7;
  const nHours = repeat === 'hourly' ? 24 : hours.length;
  const nMinutes = minutes.length;

  return Math.floor(nDays * nHours * nMinutes * (retention / 7));
};

export const isPortHttp = (port) => httpPortProtocols.includes(port.protocol);
export const isPortPublic = (port) => publicPortProtocols.includes(port.protocol);

export const getEntityTypeCollections = (entityType): 'users' | 'teams' | 'orgs' => {
  if (entityType === V1EntityTypes.USER) return `users`;
  if (entityType === V1EntityTypes.TEAM) return `teams`;
  if (entityType === V1EntityTypes.ORG) return `orgs`;

  throw new Error(`Unrecognized entity type: ${entityType}`);
};

const uppercaseAcronyms = ['tls', 'ci', 'cd', 'ci/cd'];
const scope = ['ts', 'ps'];
const scopeNames = {
  ts: 'Account',
  ps: 'Project',
};
const capitalizeAction = (word) =>
  uppercaseAcronyms.includes(word)
    ? word.toUpperCase()
    : word.charAt(0).toUpperCase() + word.substr(1);

// Returns a formatted permission string given an API or RBAC action
// e.g. Project > Services > General > Read
export const getPermissionNameString = (action) => {
  return action
    .split('_')
    .map((s1) =>
      scope.includes(s1)
        ? scopeNames[s1]
        : s1
            .split('-')
            .map((s2) => capitalizeAction(s2))
            .join(' ')
    )
    .join(' > ');
};

export const convertToBase64 = (string) => {
  if (Buffer) {
    return Buffer.from(string).toString('base64');
  }
  return window.btoa(string);
};

export const generateRegistryCredentialsJson = ({
  url = '',
  username,
  token,
  display,
  onlyReturnAuths,
}: {
  url?: string;
  username?: string;
  token?: string;
  display?: boolean;
  onlyReturnAuths?: boolean;
}) => {
  const authString = username && token ? convertToBase64(`${username}:${token}`) : '';

  let data: any = { auths: { [url]: { auth: authString } } };
  if (onlyReturnAuths) {
    data = data.auths;
  }

  if (display) return JSON.stringify(data, null, 2);

  return data;
};

// TODO: verify this import works
type BuildpackCmdOverrides = NonNullable<DBService['deployment']>['buildpack'];
type DockerCmdOverrides = NonNullable<DBService['deployment']>['docker'];

type CmdOverrides = { entrypoint?: string[]; cmd?: string[] };
type CmdOverridesResult = { ok: CmdOverrides } | { error: string } | undefined;

const parseCommand = (string: string, name: string) => {
  try {
    return split(string);
  } catch (e) {
    throw new NorthflankError({
      code: 400,
      message: `Failed to parse ${name} string. ${e.message}`,
    });
  }
};

export const generateDockerOverrides = (
  overrides: DockerCmdOverrides | any,
  displayOnly?: boolean
): CmdOverridesResult => {
  if (!overrides) {
    return undefined;
  }

  // Fallback for backwards compatibility
  if (!('configType' in overrides)) {
    const { entrypoint, cmd } = overrides;
    return {
      ok: {
        cmd: cmd?.value && cmd?.enabled ? cmd.value : undefined,
        entrypoint: entrypoint?.value && entrypoint?.enabled ? entrypoint.value : undefined,
      },
    };
  }

  switch (overrides.configType) {
    case 'default':
      return { ok: {} };

    case 'customEntrypoint':
      if (!displayOnly && !overrides.customEntrypoint) return { error: 'customEntrypoint not set' };

      return {
        ok: {
          entrypoint: parseCommand(overrides.customEntrypoint, 'customEntrypoint'),
        },
      };

    case 'customCommand':
      if (!displayOnly && !overrides.customCommand) return { error: 'customCommand not set' };

      return {
        ok: {
          cmd: parseCommand(overrides.customCommand, 'customCommand'),
        },
      };

    case 'customEntrypointCustomCommand':
      if (!displayOnly && !overrides.customEntrypoint) return { error: 'customEntrypoint not set' };
      if (!displayOnly && !overrides.customCommand) return { error: 'customCommand not set' };

      return {
        ok: {
          entrypoint: parseCommand(overrides.customEntrypoint, 'customEntrypoint'),
          cmd: parseCommand(overrides.customCommand, 'customCommand'),
        },
      };

    default:
      return { ok: {} };
  }
};

export const generateBuildpackCmdOverrides = (
  overrides: BuildpackCmdOverrides,
  displayOnly?: boolean
): CmdOverridesResult => {
  if (!overrides) {
    return undefined;
  }

  switch (overrides.configType) {
    case 'default':
      return { ok: {} };

    case 'customProcess':
      if (!displayOnly && !overrides.customProcess) return { error: 'customProcess not set' };

      return {
        ok: {
          // TODO: maybe add /cnb/process/{} prefix
          entrypoint: parseCommand(overrides.customProcess, 'customProcess'), // [`/cnb/process/${program}`, ...args],
          cmd: [],
        },
      };

    case 'customCommand':
      if (!displayOnly && !overrides.customCommand) return { error: 'customCommand not set' };

      return {
        ok: {
          entrypoint: [`/cnb/lifecycle/launcher`],
          cmd: parseCommand(overrides.customCommand, 'customCommand'),
        },
      };

    case 'customEntrypointCustomCommand':
      if (!displayOnly && !overrides.customEntrypoint) return { error: 'customEntrypoint not set' };
      if (!displayOnly && !overrides.customCommand) return { error: 'customCommand not set' };

      return {
        ok: {
          entrypoint: parseCommand(overrides.customEntrypoint, 'customEntrypoint'),
          cmd: parseCommand(overrides.customCommand, 'customCommand'),
        },
      };

    case 'originalEntrypointCustomCommand':
      if (!displayOnly && !overrides.customCommand) return { error: 'customCommand not set' };

      return {
        ok: {
          cmd: parseCommand(overrides.customCommand, 'customCommand'),
        },
      };

    default:
      return { ok: {} };
    // TODO: with this handling we'd need to definitely add a migration to ensure backwards compatibility
    // default:
    //   return { error: `invalid configType ${overrides.configType}` };
  }
};

export const renderBuildpackCommand = (overrides: BuildpackCmdOverrides) => {
  try {
    const response = generateBuildpackCmdOverrides(overrides, true);
    if (!response || !('ok' in response)) {
      return `Could not parse command`;
    }

    const entrypoint = response.ok.entrypoint ?? [];
    const cmd = response.ok.cmd ?? [];

    return join([...entrypoint, ...cmd]);
  } catch (e) {
    return 'Could not parse command';
  }
};

export const renderDockerCommand = (
  overrides: DockerOverrideSettings,
  imageData?: any,
  displayOnly?: boolean
) => {
  try {
    const response = generateDockerOverrides(overrides, displayOnly);
    if (!response || !('ok' in response)) {
      return 'Could not parse command';
    }

    const entrypoint = response.ok.entrypoint ?? imageData?.entrypoint ?? [];
    const cmd = response.ok.cmd ?? imageData?.cmd ?? [];

    return join([...entrypoint, ...cmd]);
  } catch (e) {
    return 'Could not parse command';
  }
};

export const getBuildpackSettings = (buildpack: BuildpackCmdOverrides) => {
  if (!buildpack?.configType) return { configType: 'default' };

  // eslint-disable-next-line no-underscore-dangle
  const _buildpack: BuildpackCmdOverrides = {
    configType: buildpack.configType,
  };

  const checkField = (key: 'customProcess' | 'customCommand' | 'customEntrypoint') => {
    if (!buildpack[key] || !isString(buildpack[key])) {
      throw new NorthflankError({
        code: 409,
        message: `configType ${buildpack.configType} requires ${key} to be specified as a non-empty string.`,
      });
    }
  };

  switch (buildpack.configType) {
    case 'default':
      break;
    case 'customProcess':
      checkField('customProcess');
      _buildpack.customProcess = buildpack.customProcess;
      break;
    case 'customCommand':
      checkField('customCommand');
      _buildpack.customCommand = buildpack.customCommand;
      break;
    case 'originalEntrypointCustomCommand':
      checkField('customCommand');
      _buildpack.customCommand = buildpack.customCommand;
      break;
    case 'customEntrypointCustomCommand':
      checkField('customEntrypoint');
      checkField('customCommand');
      _buildpack.customEntrypoint = buildpack.customEntrypoint;
      _buildpack.customCommand = buildpack.customCommand;
      break;
    default:
      throw new NorthflankError({
        code: 409,
        message: `Unsupported buildpack.configType "${buildpack.configType}"`,
      });
  }

  return _buildpack;
};

export const getDockerSettings = (docker?: DockerOverrideSettings) => {
  if (!docker?.configType) return { configType: 'default' };

  // eslint-disable-next-line no-underscore-dangle
  const _docker: BuildpackCmdOverrides = {
    configType: docker.configType,
  };

  const checkField = (key: 'customEntrypoint' | 'customCommand') => {
    if (!docker[key] || !isString(docker[key])) {
      throw new NorthflankError({
        code: 409,
        message: `configType ${docker.configType} requires ${key} to be specified as a non-empty string.`,
      });
    }
  };

  switch (docker.configType) {
    case 'default':
      break;
    case 'customEntrypoint':
      checkField('customEntrypoint');
      _docker.customEntrypoint = docker.customEntrypoint;
      break;
    case 'customCommand':
      checkField('customCommand');
      _docker.customCommand = docker.customCommand;
      break;
    case 'customEntrypointCustomCommand':
      checkField('customEntrypoint');
      checkField('customCommand');
      _docker.customEntrypoint = docker.customEntrypoint;
      _docker.customCommand = docker.customCommand;
      break;
    default:
      throw new NorthflankError({
        code: 409,
        message: `Unsupported docker.configType "${docker.configType}"`,
      });
  }

  return _docker;
};

// Removes leading + trailing whitespace from strings in an array, then filters empty strings.
// Used for commit ignore flags to remove empty lines from the file editor
export const filterWhitespaceFromStringArray = (arr: string[]): string[] => {
  const trimmed = arr.map((str) => str.trim());
  return trimmed.filter((str) => str.length);
};

// Converts an array of strings to a formatted version. Used in the API for printing arrays of strings.
export const formatStringArray = (strings: any[]): string =>
  `[${strings.reduce((acc, x, i) => `${acc}${i ? ', ' : ''}"${x.toString()}"`, '')}]`;

export const handleDeploymentStrategy = (deploymentStrategy) => ({
  type: deploymentStrategy.type,
  ...(deploymentStrategy.type === 'custom' ? { settings: deploymentStrategy.settings } : {}),
});

export const trimTrailingSlash = (url) => (url.endsWith('/') ? url.substr(0, url.length - 1) : url);

export const stringToCaseInsensitiveRegex = (string: string): RegExp => {
  const escaped = escapeRegExp(string);
  return new RegExp(`^${escaped}$`, 'i');
};

export const formatWorkOSEmail = (email: string): string => {
  const squareBracketRegex = /^\[(.*)\]$/;

  const matches = squareBracketRegex.exec(email);
  if (matches?.length && matches[1]) {
    return matches[1];
  }
  return email;
};

// Quite close to maximum that Istio accepts
const DEFAULT_MAX_ALLOWED_DURATION_MS = 9000000000000;

export const validateK8sDuration = (
  durationString,
  maxAllowedDuration = DEFAULT_MAX_ALLOWED_DURATION_MS
) => {
  const regex = /^([1-9][0-9]*)(ms|s|m|h)$/;

  const match = durationString.match(regex);
  if (!match) {
    throw new NorthflankError({
      code: 409,
      message: `Invalid duration specified ${durationString}`,
    });
  }

  const [_, amount, unit] = match;

  let multiplier = 1;
  switch (unit) {
    case 'ms':
      multiplier = 1;
      break;
    case 's':
      multiplier = 1000;
      break;
    case 'm':
      multiplier = 1000 * 60;
      break;
    case 'h':
      multiplier = 1000 * 60 * 60;
      break;
  }

  if (amount * multiplier > maxAllowedDuration) {
    throw new NorthflankError({
      code: 409,
      message: `Timeout ${durationString} exceeds maximum allowed duration ${
        maxAllowedDuration / 1000
      }s`,
    });
  }
};

export const getSubdomainName = (spec: V1TemplateSubdomainSpec) => {
  const { name, domain } = spec;
  return name === '@' ? domain : `${name}.${domain}`;
};

export const getDomainNameFromSubdomain = (name: string, domainName: string) => {
  return name === '@' ? domainName : domainName.replace(`${name}.`, '');
};

export const getSubdomainNameFromFullName = (fullName: string, domainName: string) =>
  fullName.replace(`.${domainName}`, '');

export const getSubdomainPathName = (spec: V1TemplateSubdomainPathSpec) => {
  const { uri, subdomain } = spec;
  return uri === '/' ? subdomain : `${subdomain}${uri}`;
};
