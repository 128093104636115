import React from 'react';

import Box from '@northflank/components/Box';
import Grid from '@northflank/components/Grid';
import Icon from '@northflank/components/Icon';
import Text from '@northflank/components/Text';

export const MasonryCard = ({
  header,
  icon,
  title,
  text,
  afterText,
  cols = 25,
  rows = 8,
  imageVertical,
  imageHorizontal,
  imageProps,
  textBoxProps,
  ...rest
}) => {
  return (
    <Box
      bg="grey10"
      border="1px solid"
      borderColor="grey5"
      borderRadius={3}
      variant="flex"
      flexDirection={imageVertical ? 'column' : ['column', 'column', 'row']}
      position="relative"
      overflow="hidden"
      _css={{
        gridColumnEnd: [
          1,
          1,
          ...(Array.isArray(cols)
            ? cols.map((c) => `span ${c}`)
            : [`span ${cols}`]),
        ],
        gridRowEnd: [
          'unset',
          'unset',
          ...(Array.isArray(rows)
            ? rows.map((r) => `span ${r}`)
            : [`span ${rows}`]),
        ],
      }}
      {...rest}
    >
      <Box height="100%" width="100%" p={[12, 12, '28px']} {...textBoxProps}>
        {header}
        {icon && (
          <Box
            borderRadius={5}
            position="relative"
            display="inline-block"
            mb={8}
            _css={{
              '&::before': {
                content: '""',
                display: 'block',
                width: '100%',
                height: '100%',
                position: 'absolute',
                bg: 'primary',
                borderRadius: 5,
                opacity: 0.6,
                filter: 'blur(24px)',
              },
            }}
          >
            <Icon
              Content={icon}
              Size={32}
              color="url(#gradient_svg__blueSvgGradient)"
            />
          </Box>
        )}
        <Text fontSize={4} fontWeight={600}>
          {title}
        </Text>
        {text && (
          <>
            {Array.isArray(text) ? (
              text.map((line, i) => (
                <Text key={i} fontSize={3} color="grey2" mt={6}>
                  {line}
                </Text>
              ))
            ) : (
              <Text fontSize={3} color="grey2" mt={6}>
                {text}
              </Text>
            )}
          </>
        )}
        {afterText}
      </Box>
      {(imageVertical || imageHorizontal) && (
        <Box height="100%" width="100%" position="relative">
          <Box
            as="img"
            src={imageVertical || imageHorizontal}
            position={['static', 'static', 'absolute']}
            width="100%"
            {...imageProps}
          />
        </Box>
      )}
    </Box>
  );
};

export const FeatureMasonry = ({
  children,
  gridTemplateColumns = ['auto', 'auto', 'repeat(25, 1fr)'],
  ...rest
}) => {
  return (
    <Grid
      gridTemplateColumns={gridTemplateColumns}
      gridAutoRows={['auto', 'auto', 'auto', '32px']}
      gridColumnGap={[0, 0, 8]}
      gridRowGap={8}
      alignItems="stretch"
      {...rest}
    >
      {children}
    </Grid>
  );
};
