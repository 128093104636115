import React, { useEffect, useRef, useState } from 'react';

import { Kubernetes } from '@styled-icons/boxicons-logos/Kubernetes';
import { Cloud } from '@styled-icons/boxicons-regular/Cloud';
import { Extension } from '@styled-icons/boxicons-regular/Extension';
import { Globe } from '@styled-icons/boxicons-regular/Globe';
import { Hdd } from '@styled-icons/boxicons-regular/Hdd';
import { Layer } from '@styled-icons/boxicons-regular/Layer';
import { LinkExternal } from '@styled-icons/boxicons-regular/LinkExternal';
import { Plus } from '@styled-icons/boxicons-regular/Plus';
import { Run } from '@styled-icons/boxicons-regular/Run';
import { TrashAlt } from '@styled-icons/boxicons-regular/TrashAlt';
import { Wrench } from '@styled-icons/boxicons-regular/Wrench';
import { FieldArray, Form, Formik, useFormikContext } from 'formik';
import { Base64 } from 'js-base64';
import pluralize from 'pluralize';
import qs from 'qs';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import { copyToClipboard } from '@northflank/components/CopyToClipboard';
import FormikRange from '@northflank/components/FormikRange';
import Grid from '@northflank/components/Grid';
import Icon from '@northflank/components/Icon';
import {
  cloudProviderPrices,
  formatCost,
} from '@northflank/components/marketing/Pricing/data';
import Text from '@northflank/components/Text';
import Toggle from '@northflank/components/Toggle';
import { capitalize } from '@northflank/constants-base';

import Addon from './forms/Addon';
import Build from './forms/Build';
import Job from './forms/Job';
import NetworkEgress from './forms/NetworkEgress';
import Service from './forms/Service';
import Volume from './forms/Volume';
import {
  calculateClustersPrice,
  calculateCpuPrice,
  calculateMemoryPrice,
  calculateResourcePrice,
} from './utils';

const resourceDescriptions = {
  service: 'Services are used to build and run code continuously.',
  job: 'Jobs are used to build and run code once or on a schedule.',
  addon:
    'Databases, caches, queues, and other off-the-shelf solutions are provided through Northflank ‘Addons’.',
  volume:
    'Volumes are persistent storage disks that can be attached to otherwise ephemeral services.',
  cluster:
    'Bring Your Own Cloud clusters are Kubernetes clusters running in your own cloud accounts. Northflank can deploy into these as an alternative to the Northflank Cloud.',
  build:
    'Northflank builds your code from Git for deployment to services and jobs.',
  network: 'Network egress is data leaving the host infrastructure.',
};

const resourceIcons = {
  service: Layer,
  job: Run,
  addon: Extension,
  volume: Hdd,
  cluster: Kubernetes,
  build: Wrench,
  network: Globe,
};

const resourceNames = {
  addon: 'database',
  network: 'network egress',
};

const ResourceLineItemGroup = ({
  type,
  resources,
  remove,
  plans,
  addonTypes,
}) => {
  const resourceName = capitalize(resourceNames[type] ?? type);
  return resources.length ? (
    <Box>
      <Text fontWeight={600} textTransform="uppercase">
        {pluralize(resourceName)} ({resources.length})
      </Text>
      {!!resourceDescriptions[type] && (
        <Text color="grey3" fontSize={3} mt={6}>
          {resourceDescriptions[type]}
        </Text>
      )}
      <Grid
        gridTemplateColumns={['1fr', '1fr', '1fr', '1fr 1fr']}
        gridGap={7}
        alignItems="stretch"
        mt={8}
      >
        {resources.map((resource, i) => (
          <ResourceLineItem
            key={`calculator-${type}-${resource.id}`}
            resource={resource}
            index={i}
            resourceName={resourceName}
            remove={remove}
            plans={plans}
            addonTypes={addonTypes}
          />
        ))}
      </Grid>
    </Box>
  ) : null;
};

const ResourceLineItem = ({
  resource,
  index,
  resourceName,
  remove,
  plans,
  addonTypes,
}) => {
  const { values } = useFormikContext();
  const formikIndex = values.resources.findIndex(
    (r) => r.type === resource.type && r.id === resource.id
  );

  const deleteResource = () => {
    if (formikIndex > -1) remove(formikIndex);
  };

  return (
    <Box
      variant="flex"
      flexDirection="column"
      justifyContent="space-between"
      bg="grey9"
      border="1px solid"
      borderColor="grey6"
      borderRadius={2}
      boxShadow="dropDown"
    >
      <Box>
        <Box
          variant="flex"
          alignItems="center"
          justifyContent="space-between"
          borderBottom="1px solid"
          borderBottomColor="grey6"
          px={7}
          py={6}
        >
          <Box variant="flex" alignItems="center">
            <Icon
              Content={resourceIcons[resource.type]}
              Size={18}
              color="grey3"
              mr={5}
              mb="1px"
            />
            <Text fontSize={3} fontWeight={500} lineHeight={1}>
              {resourceName} #{index + 1}
            </Text>
          </Box>
          <Box variant="flex" alignItems="center" justifyContent="flex-end">
            <Button
              type="button"
              variant="icon"
              onClick={() => deleteResource()}
            >
              <TrashAlt size={16} />
            </Button>
          </Box>
        </Box>
        <Box p={7}>
          {resource.type === 'service' && (
            <Service
              resource={resource}
              formikIndex={formikIndex}
              plans={plans}
            />
          )}
          {resource.type === 'job' && (
            <Job resource={resource} formikIndex={formikIndex} plans={plans} />
          )}
          {resource.type === 'addon' && (
            <Addon
              resource={resource}
              formikIndex={formikIndex}
              plans={plans}
              addonTypes={addonTypes}
            />
          )}
          {resource.type === 'volume' && (
            <Volume resource={resource} formikIndex={formikIndex} />
          )}
          {resource.type === 'cluster' && (
            <Text color="grey3">
              This cluster does not require any further configuration.
            </Text>
          )}
          {resource.type === 'build' && (
            <Build
              resource={resource}
              formikIndex={formikIndex}
              plans={plans}
            />
          )}
          {resource.type === 'network' && (
            <NetworkEgress formikIndex={formikIndex} />
          )}
        </Box>
      </Box>
      <Box
        variant="flex"
        alignItems="center"
        justifyContent="flex-end"
        borderTop="1px solid"
        borderTopColor="grey6"
        textAlign="right"
        p={7}
      >
        <Box>
          {resource.isByoc && (
            <Box
              borderBottom="1px dashed"
              borderBottomColor="grey5"
              pl={7}
              pb={7}
              mb={7}
            >
              <Text>
                <Text as="span" color="grey3">
                  Cloud provider
                </Text>{' '}
                ~
                <strong>
                  $
                  {formatCost(calculateResourcePrice(resource, plans).provider)}
                </strong>
                /month
              </Text>
              <Text>
                <Text as="span" color="grey3">
                  Northflank fee
                </Text>{' '}
                <strong>
                  ${formatCost(calculateResourcePrice(resource, plans).managed)}
                </strong>
                /month
              </Text>
            </Box>
          )}
          <Text fontSize={3}>
            <strong>
              ${formatCost(calculateResourcePrice(resource, plans).total)}
            </strong>
            /month
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

const ByocToggle = ({ isByoc, disabled, ...rest }) => {
  return (
    <Toggle
      labelBefore={
        <Box as="span" variant="flex" alignItems="center">
          <Icon
            Content={Cloud}
            Size={16}
            color={disabled ? 'grey4' : isByoc ? 'grey3' : 'success'}
            mr={3}
          />
          <Text>Northflank Cloud</Text>
        </Box>
      }
      labelAfter={
        <Box as="span" variant="flex" alignItems="center">
          <Icon
            Content={Kubernetes}
            Size={16}
            color={disabled ? 'grey4' : isByoc ? 'success' : 'grey3'}
            mr={3}
          />
          <Text>BYOC</Text>
        </Box>
      }
      color="grey4"
      disabled={disabled}
      {...rest}
    />
  );
};

const CopyableData = ({ data, ...rest }) => {
  const [res, setRes] = useState();

  const copyRef = useRef();

  return (
    <Box variant="flex" flexDirection="column" alignItems="flex-end" {...rest}>
      <Text
        ref={copyRef}
        css={{
          width: '1px !important',
          height: '1px !important',
          overflow: 'hidden !important',
          opacity: '0 !important',
        }}
      >
        https://northflank.com/pricing?calculator={data}#calculator
      </Text>
      <Button
        onClick={(e) =>
          copyToClipboard(copyRef, e, 'URL', (r) => {
            setRes(r);
            setTimeout(() => {
              setRes(undefined);
            }, 2000);
          })
        }
        type="button"
        variant="smallDelimiter"
        icon={LinkExternal}
      >
        Share this calculation
      </Button>
      {res && (
        <Text
          fontSize={0}
          color={res.type === 'error' ? 'danger' : 'success'}
          mt={3}
        >
          {res}
        </Text>
      )}
    </Box>
  );
};

const maxCpu = 256;
const maxMem = 2000;
const maxClusters = 25;

const Calculator = ({ plans, addonTypes, simple, setCalculatorMode }) => {
  const [sharableData, setShareableData] = useState('');
  const [initialData, setInitialData] = useState();

  useEffect(() => {
    try {
      const data = qs.parse(window.location.search.replace('?', ''));
      if (data.calculator) {
        const parsed = JSON.parse(Base64.decode(data.calculator));
        if (parsed.mode) setCalculatorMode(parsed.mode);
        setInitialData(parsed);
      }
    } catch (e) {}
  }, []);

  if (simple) {
    return (
      <Box>
        <Formik
          key="infrastructure"
          initialValues={
            (initialData &&
              initialData.mode === 'infrastructure' &&
              initialData.values) || {
              cpu: 8,
              memory: 32,
              clusters: 1,
              isByoc: false,
            }
          }
          onSubmit={() => {}}
          enableReinitialize
        >
          {({ values, setFieldValue }) => {
            const totalCosts = {
              cpu: calculateCpuPrice(values.cpu, values.isByoc),
              memory: calculateMemoryPrice(values.memory, values.isByoc),
              cluster: calculateClustersPrice(values.clusters, values.isByoc),
            };

            const grandTotal =
              totalCosts.cpu.total +
              totalCosts.memory.total +
              totalCosts.cluster.total;

            useEffect(() => {
              const data = { mode: 'infrastructure', values };
              setShareableData(Base64.encode(JSON.stringify(data)));
            }, [JSON.stringify(values)]);

            return (
              <Form>
                <Box variant="flex" justifyContent="flex-end" mb={7}>
                  <ByocToggle
                    isByoc={values.isByoc}
                    inputProps={{
                      checked: values.isByoc,
                      onChange: () => {
                        setFieldValue('isByoc', !values.isByoc);
                      },
                    }}
                  />
                </Box>
                <FormikRange
                  name="cpu"
                  text="vCPU"
                  minValue={1}
                  maxValue={maxCpu}
                  renderDisplayValue={(val) =>
                    val === maxCpu ? `${maxCpu}+` : val
                  }
                  mb={7}
                />
                <FormikRange
                  name="memory"
                  text="Memory (GB)"
                  minValue={1}
                  maxValue={maxMem}
                  renderDisplayValue={(val) =>
                    val === maxMem ? `${maxMem}+` : val
                  }
                  mb={values.isByoc ? 7 : 10}
                />
                {values.isByoc && (
                  <FormikRange
                    name="clusters"
                    text="Clusters"
                    minValue={1}
                    maxValue={maxClusters}
                    renderDisplayValue={(val) =>
                      val === maxClusters ? `${maxClusters}+` : val
                    }
                    mb={10}
                  />
                )}
                <Box variant="flex" justifyContent="flex-end">
                  <Box textAlign="right" ml={8}>
                    {values.isByoc && (
                      <Box>
                        <Text>
                          <Text as="span" color="grey3">
                            Cloud provider
                          </Text>{' '}
                          ~
                          <strong>
                            $
                            {formatCost(
                              totalCosts.cpu.provider +
                                totalCosts.memory.provider +
                                totalCosts.cluster.provider
                            )}
                            {values.cpu === maxCpu || values.memory === maxMem
                              ? '+'
                              : ''}
                          </strong>
                          /month
                        </Text>
                        <Text>
                          <Text as="span" color="grey3">
                            Northflank fee
                          </Text>{' '}
                          <strong>
                            $
                            {formatCost(
                              totalCosts.cpu.managed +
                                totalCosts.memory.managed +
                                totalCosts.cluster.managed
                            )}
                            {values.cpu === maxCpu || values.memory === maxMem
                              ? '+'
                              : ''}
                          </strong>
                          /month
                        </Text>
                      </Box>
                    )}
                    <Box
                      borderTop="1px dashed"
                      borderTopColor="grey5"
                      pl={6}
                      pt={6}
                      mt={6}
                    >
                      <Text fontSize={4} whiteSpace="nowrap">
                        Total{' '}
                        <strong>
                          ${formatCost(grandTotal)}
                          {values.cpu === maxCpu ||
                          values.memory === maxMem ||
                          values.clusters === maxClusters
                            ? '+'
                            : ''}
                        </strong>
                        /month
                      </Text>
                    </Box>
                    {!values.isByoc && (
                      <Box mt={3}>
                        <Text color="grey3" mb={3}>
                          vs.
                        </Text>
                        {Object.keys(totalCosts.cpu.cloudProviders).map(
                          (key) => (
                            <Box
                              key={`comparison-${key}`}
                              variant="flex"
                              alignItems="center"
                              justifyContent="flex-end"
                              mt={1}
                            >
                              <Icon
                                Content={cloudProviderPrices[key].meta.icon}
                                Size={16}
                                color="grey3"
                                mr={3}
                              />
                              <Text color="grey2">
                                {cloudProviderPrices[key].meta.name}{' '}
                                <Text as="strong" color="grey1">
                                  $
                                  {formatCost(
                                    totalCosts.cpu.cloudProviders[key] +
                                      totalCosts.memory.cloudProviders[key]
                                  )}
                                </Text>
                                /mo
                              </Text>
                            </Box>
                          )
                        )}
                      </Box>
                    )}
                    <Text fontSize={1} color="grey4" mt={1}>
                      Cloud provider costs are estimated
                    </Text>
                    <CopyableData
                      data={sharableData}
                      display="inline-block"
                      mt={7}
                    />
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    );
  }

  return (
    <Box>
      <Formik
        key="workload"
        initialValues={
          (initialData &&
            initialData.mode === 'workload' &&
            initialData.values) || {
            resources: [],
          }
        }
        onSubmit={() => {}}
        enableReinitialize
      >
        {({ values, setFieldValue }) => {
          const services = values.resources.filter((r) => r.type === 'service');
          const jobs = values.resources.filter((r) => r.type === 'job');
          const addons = values.resources.filter((r) => r.type === 'addon');
          const volumes = values.resources.filter((r) => r.type === 'volume');
          const clusters = values.resources.filter((r) => r.type === 'cluster');
          const buildTime = values.resources.filter((r) => r.type === 'build');
          const network = values.resources.filter((r) => r.type === 'network');

          const isByoc = values.resources.some((r) => r.isByoc);

          const totalCosts = values.resources.reduce(
            (totals, resource) => {
              totals.managed += calculateResourcePrice(resource, plans).managed;
              totals.provider += calculateResourcePrice(
                resource,
                plans
              ).provider;
              return totals;
            },
            { managed: 0, provider: 0 }
          );

          const grandTotal = totalCosts.provider + totalCosts.managed;

          useEffect(() => {
            const data = { mode: 'workload', values };
            setShareableData(Base64.encode(JSON.stringify(data)));
          }, [JSON.stringify(values)]);

          const buildPlans = plans.filter((plan) =>
            plan.type.includes('build')
          );

          return (
            <Form>
              <FieldArray name="resources">
                {({ push, remove }) => (
                  <Box position="relative">
                    <Box
                      variant="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      position="sticky"
                      top="81px"
                      width="106%"
                      ml="-3%"
                      bg="background"
                      borderBottom="1px solid"
                      borderBottomColor="grey6"
                      px="3%"
                      py={8}
                      mb={12}
                      zIndex={2}
                    >
                      <Box>
                        <ByocToggle
                          isByoc={isByoc}
                          inputProps={{
                            checked: isByoc,
                            onChange: () => {
                              setFieldValue(
                                'resources',
                                values.resources.map((r) => ({
                                  ...r,
                                  isByoc: !isByoc,
                                }))
                              );
                            },
                          }}
                          disabled={!values.resources.length}
                          mb={8}
                        />
                        <Box variant="flex" flexWrap="wrap" m={-3}>
                          <Button
                            type="button"
                            onClick={() => {
                              push({
                                id: Math.random(),
                                type: 'service',
                                isByoc,
                                plan: plans[0].internalId,
                                instances: 1,
                              });
                            }}
                            variant="smallDelimiter"
                            icon={Plus}
                            m={3}
                          >
                            Service
                            {services.length > 0 && ` (${services.length})`}
                          </Button>
                          <Button
                            type="button"
                            onClick={() => {
                              push({
                                id: Math.random(),
                                type: 'job',
                                isByoc,
                                plan: plans[0].internalId,
                                runMinutes: 1000,
                              });
                            }}
                            variant="smallDelimiter"
                            icon={Plus}
                            m={3}
                          >
                            Job
                            {jobs.length > 0 && ` (${jobs.length})`}
                          </Button>
                          <Button
                            type="button"
                            onClick={() => {
                              push({
                                id: Math.random(),
                                type: 'addon',
                                isByoc,
                                kind: 'redis',
                                plan: plans[0].internalId,
                                replicas: 1,
                                storage: 4096,
                                storageClass: 'ssd',
                              });
                            }}
                            variant="smallDelimiter"
                            icon={Plus}
                            m={3}
                          >
                            Database
                            {addons.length > 0 && ` (${addons.length})`}
                          </Button>
                          <Button
                            type="button"
                            onClick={() => {
                              push({
                                id: Math.random(),
                                type: 'volume',
                                isByoc,
                                storage: 5120,
                                storageClass: 'ssd',
                              });
                            }}
                            variant="smallDelimiter"
                            icon={Plus}
                            m={3}
                          >
                            Volume
                            {volumes.length > 0 && ` (${volumes.length})`}
                          </Button>
                          <Button
                            type="button"
                            onClick={() => {
                              push({ id: Math.random(), type: 'cluster' });
                            }}
                            variant="smallDelimiter"
                            icon={Plus}
                            m={3}
                          >
                            BYOC cluster
                            {clusters.length > 0 && ` (${clusters.length})`}
                          </Button>
                          <Button
                            type="button"
                            onClick={() => {
                              push({
                                id: Math.random(),
                                type: 'build',
                                isByoc,
                                plan: buildPlans[0].internalId,
                                buildMinutes: 1000,
                              });
                            }}
                            variant="smallDelimiter"
                            icon={Plus}
                            m={3}
                          >
                            Build time
                            {buildTime.length > 0 && ` (${buildTime.length})`}
                          </Button>
                          <Button
                            type="button"
                            onClick={() => {
                              push({
                                id: Math.random(),
                                type: 'network',
                                gb: 10,
                                reqs: 1e6,
                              });
                            }}
                            variant="smallDelimiter"
                            icon={Plus}
                            m={3}
                          >
                            Network egress
                            {network.length > 0 && ` (${network.length})`}
                          </Button>
                        </Box>
                      </Box>

                      <Box textAlign="right" ml={8}>
                        {totalCosts.provider > 0 && (
                          <Box
                            borderBottom="1px dashed"
                            borderBottomColor="grey5"
                            pl={6}
                            pb={6}
                            mb={6}
                          >
                            <Text>
                              <Text as="span" color="grey3">
                                Cloud provider
                              </Text>{' '}
                              ~
                              <strong>
                                ${formatCost(totalCosts.provider)}
                              </strong>
                              /month
                            </Text>
                            <Text>
                              <Text as="span" color="grey3">
                                Northflank fee
                              </Text>{' '}
                              <strong>${formatCost(totalCosts.managed)}</strong>
                              /month
                            </Text>
                          </Box>
                        )}
                        <Text fontSize={4} whiteSpace="nowrap">
                          Total <strong>${formatCost(grandTotal)}</strong>
                          /month
                        </Text>
                        {values.resources.some((r) => r.isByoc) && (
                          <Text fontSize={1} color="grey4" mt={1}>
                            Cloud provider costs are estimated
                          </Text>
                        )}
                        <CopyableData
                          data={sharableData}
                          display="inline-block"
                          mt={7}
                        />
                      </Box>
                    </Box>

                    {!values.resources.length && (
                      <Text fontSize={3} color="grey3">
                        Nothing here yet. Add some resources above to get
                        started.
                      </Text>
                    )}
                    <Box _css={{ '> div + div': { mt: 12 } }}>
                      <ResourceLineItemGroup
                        type="service"
                        resources={services}
                        remove={remove}
                        plans={plans}
                      />
                      <ResourceLineItemGroup
                        type="job"
                        resources={jobs}
                        remove={remove}
                        plans={plans}
                      />
                      <ResourceLineItemGroup
                        type="addon"
                        resources={addons}
                        remove={remove}
                        plans={plans}
                        addonTypes={addonTypes}
                      />
                      <ResourceLineItemGroup
                        type="volume"
                        resources={volumes}
                        remove={remove}
                      />
                      <ResourceLineItemGroup
                        type="cluster"
                        resources={clusters}
                        remove={remove}
                      />
                      <ResourceLineItemGroup
                        type="build"
                        resources={buildTime}
                        remove={remove}
                        plans={buildPlans}
                      />
                      <ResourceLineItemGroup
                        type="network"
                        resources={network}
                        remove={remove}
                      />
                    </Box>
                  </Box>
                )}
              </FieldArray>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default Calculator;
