import React from 'react';

import {
  adjustHue,
  darken,
  desaturate,
  lighten,
  saturate,
  transparentize,
} from 'polished';
import { ThemeProvider } from 'styled-components';

import Themes from '../themes';

export const space = [
  0, 2, 3, 4, 6, 7, 8, 12, 16, 20, 24, 28, 32, 54, 64, 128, 256, 512,
];

export const breakpoints = ['320px', '768px', '1250px', '1480px', '1600px'];
export const dashboardBreakpoints = [
  '320px',
  '768px',
  '1200px',
  '1480px',
  '1800px',
];
export const marketingBreakpoints = [
  '320px',
  '820px',
  '1250px',
  '1480px',
  '1600px',
];

export const fonts = {
  body: '"Inter", system-ui, sans-serif',
  monospace: '"JetBrains Mono", monospace',
};

export const fontSizes = [12, 13, 14, 16, 20, 24, 36, 48, 60, 80, 96, 11];

export const fontWeights = [300, 400, 500, 700, 900];

export const radii = [0, 2, 4, 8, 16, '50%'];

export const getShadows = (intensity = 0.24) => ({
  button: {
    small: '0px 1px 1px rgba(0, 0, 0, 0.24)',
    smallHover: '0px 1px 2px rgba(0, 0, 0, 0.24)',
    medium: '0px 1px 2px rgba(0, 0, 0, 0.24)',
    mediumHover: '0px 2px 4px rgba(0, 0, 0, 0.24)',
    inner: 'inset 0px 0px 8px rgba(0, 0, 0, 0.24)',
  },
  dropDown: `0 4px 24px 0 rgba(0, 0, 0, ${intensity})`,
  dropUp: `0 -4px 24px 0 rgba(0, 0, 0, ${intensity})`,
  dropLeft: `-4px 0 24px 0 rgba(0, 0, 0, ${intensity})`,
  dropRight: `4px 0 24px 0 rgba(0, 0, 0, ${intensity})`,
  image: `0px 64px 32px -32px rgba(0, 0, 0, ${intensity})`,
  empty: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
});

export const lineHeights = {
  body: 1.4,
  heading: 1.2,
};

export const colors = {
  background: '#151C28',
  primary: '#0093FF',
  primaryLight: '#33A9FF',
  success: '#2DD881',
  danger: '#DE1A1A',
  warning: '#F7B801',
  buttonGradient: 'linear-gradient(155deg,#0093ff 11%,#846cce 103.85%)',
  grey0: '#FFFFFF',
  grey1: '#EBF1F7',
  grey2: '#C6D3E2',
  grey3: '#7A89A1',
  grey4: '#57637A',
  grey5: '#27303E',
  grey6: '#1E2736',
  grey7: '#20252E',
  grey8: '#171C25',
  grey9: '#121721',
  grey10: '#11151E',
  grey11: '#000000',
  button: '#171C25',
  project: [
    '#57637A',
    '#AF3B6E',
    '#6F2DBD',
    '#7FD1B9',
    '#DF99F0',
    '#DBFE87',
    '#EF233C',
    '#FDCA40',
  ],
  avatarBg: '#192338',
  static: {
    grey0: '#FFFFFF',
    grey1: '#EBF1F7',
    grey2: '#C6D3E2',
    grey3: '#7A89A1',
    grey11: '#131A2A',
  },
};

export const mapColors = [
  '#0093FF', // blue
  '#6a5acd', // purple
  '#E9473A', // amber
  '#FFB6C1', // pink
  '#FF7F50', // coral
  '#20B2AA', // seagreen
  '#87CEEB', // skyblue
];

export const sizes = {
  max: '1250px',
  wide: '1400px',
};

export const Theme = {
  space,
  colors,
  radii,
  fonts,
  fontWeights,
  fontSizes,
  breakpoints,
  dashboardBreakpoints,
  marketingBreakpoints,
  sizes,
  lineHeights,
};

export const createNorthflankTheme = ({
  name,
  customColors = {},
  customBreakpoints,
}) => {
  const SelectedTheme = Themes[name] ?? Theme;
  const useCustomTheme = name === 'custom';

  // if custom theme is selected but no custom values present, use default theme values
  if (useCustomTheme && !Object.keys(customColors).length) {
    customColors = Themes.dark.colors;
  }

  const colorsToChange = [
    'primary',
    'success',
    'danger',
    'warning',
    'background',
  ];
  const changedColors = {};
  colorsToChange.forEach((color) => {
    changedColors[`${color}Dark5`] = adjustHue(
      12,
      desaturate('0.20', darken('0.20', SelectedTheme.colors[color]))
    );
    changedColors[`${color}Dark4`] = adjustHue(
      8,
      desaturate('0.16', darken('0.16', SelectedTheme.colors[color]))
    );
    changedColors[`${color}Dark3`] = adjustHue(
      6,
      desaturate('0.12', darken('0.12', SelectedTheme.colors[color]))
    );
    changedColors[`${color}Dark2`] = adjustHue(
      4,
      desaturate('0.08', darken('0.08', SelectedTheme.colors[color]))
    );
    changedColors[`${color}Dark1`] = adjustHue(
      2,
      desaturate('0.04', darken('0.04', SelectedTheme.colors[color]))
    );
    changedColors[`${color}Light1`] = adjustHue(
      358,
      saturate('0.04', lighten('0.04', SelectedTheme.colors[color]))
    );
    changedColors[`${color}Light2`] = adjustHue(
      356,
      saturate('0.08', lighten('0.08', SelectedTheme.colors[color]))
    );
    changedColors[`${color}Light3`] = adjustHue(
      354,
      saturate('0.12', lighten('0.12', SelectedTheme.colors[color]))
    );
    changedColors[`${color}Light4`] = adjustHue(
      352,
      saturate('0.16', lighten('0.16', SelectedTheme.colors[color]))
    );
    changedColors[`${color}Light5`] = adjustHue(
      350,
      saturate('0.20', lighten('0.20', SelectedTheme.colors[color]))
    );
  });
  colorsToChange.forEach((color) => {
    changedColors[`${color}Opacity9`] = transparentize(
      '0.9',
      SelectedTheme.colors[color]
    );
    changedColors[`${color}Opacity8`] = transparentize(
      '0.8',
      SelectedTheme.colors[color]
    );
    changedColors[`${color}Opacity7`] = transparentize(
      '0.7',
      SelectedTheme.colors[color]
    );
    changedColors[`${color}Opacity6`] = transparentize(
      '0.6',
      SelectedTheme.colors[color]
    );
    changedColors[`${color}Opacity5`] = transparentize(
      '0.5',
      SelectedTheme.colors[color]
    );
    changedColors[`${color}Opacity4`] = transparentize(
      '0.4',
      SelectedTheme.colors[color]
    );
    changedColors[`${color}Opacity3`] = transparentize(
      '0.3',
      SelectedTheme.colors[color]
    );
    changedColors[`${color}Opacity2`] = transparentize(
      '0.2',
      SelectedTheme.colors[color]
    );
    changedColors[`${color}Opacity1`] = transparentize(
      '0.1',
      SelectedTheme.colors[color]
    );
  });

  if (customBreakpoints) Theme.breakpoints = Theme[customBreakpoints];

  return {
    ...Theme,
    colors: {
      ...Theme.colors,
      ...(useCustomTheme ? customColors : SelectedTheme.colors),
      ...changedColors,
    },
    shadows: getShadows(SelectedTheme.shadowIntensity),
    name: useCustomTheme ? 'custom' : SelectedTheme.name,
  };
};

export const withTheme = ({ children }) => {
  return (
    <ThemeProvider theme={createNorthflankTheme({ name: 'dark' })}>
      {children}
    </ThemeProvider>
  );
};
