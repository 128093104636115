import React from 'react';
import { useLocation } from 'react-router-dom';

import { Kubernetes } from '@styled-icons/boxicons-logos/Kubernetes';
import { AddToQueue } from '@styled-icons/boxicons-regular/AddToQueue';
import { Chip } from '@styled-icons/boxicons-regular/Chip';
import { Cloud } from '@styled-icons/boxicons-regular/Cloud';
import { CloudUpload } from '@styled-icons/boxicons-regular/CloudUpload';
import { Customize } from '@styled-icons/boxicons-regular/Customize';
import { Data } from '@styled-icons/boxicons-regular/Data';
import { Hive } from '@styled-icons/boxicons-regular/Hive';
import { LineChartDown } from '@styled-icons/boxicons-regular/LineChartDown';
import { PackageIcon } from '@styled-icons/boxicons-regular/PackageIcon';
import { PlayCircle } from '@styled-icons/boxicons-regular/PlayCircle';
import { Radar } from '@styled-icons/boxicons-regular/Radar';
import { Search } from '@styled-icons/boxicons-regular/Search';
import { ShieldQuarter } from '@styled-icons/boxicons-regular/ShieldQuarter';
import { User } from '@styled-icons/boxicons-regular/User';
import { WindowAlt } from '@styled-icons/boxicons-regular/WindowAlt';
import { Wrench } from '@styled-icons/boxicons-regular/Wrench';

import Box from '@northflank/components/Box';
import FeatureTabs from '@northflank/components/marketing/FeatureTabs';
import NorthflankIcon from '@northflank/components/NorthflankIcon';

export const pages = [
  {
    title: 'Features',
    items: [
      {
        label: 'Overview',
        path: '/features',
        icon: Search,
        description: 'See what Northflank has to offer',
      },
      {
        label: 'Platform',
        path: '/features/platform',
        icon: WindowAlt,
        description: 'The ultimate developer experience',
      },
      {
        label: 'Build',
        path: '/features/build',
        icon: Wrench,
        description: 'Rapid builds & continuous integration',
      },
      {
        label: 'Run',
        path: '/features/run',
        icon: PlayCircle,
        description: 'Continuous deployment & more',
      },
      {
        label: 'Release',
        path: '/features/release',
        icon: CloudUpload,
        description: 'Pipelines & automated release flows',
      },
      {
        label: 'Scale',
        path: '/features/scale',
        icon: AddToQueue,
        description: 'Resource scaling & teams',
      },
      {
        label: 'Observe',
        path: '/features/observe',
        icon: Radar,
        description: 'Logs, metrics & alerts',
      },
      {
        label: 'Databases',
        path: '/features/databases',
        icon: Data,
        description: 'Data stores, caches, & queues',
      },
      {
        label: 'Templates',
        path: '/features/templates',
        icon: Hive,
        description: 'Codify infrastructure management',
      },
      {
        label: 'GPUs',
        path: '/gpu',
        icon: Chip,
        description: 'Run AI & ML workloads',
      },
    ],
  },
  {
    title: 'Cloud',
    items: [
      {
        label: 'Managed cloud',
        path: '/features/managed-cloud',
        icon: NorthflankIcon,
        description: "Deploy on Northflank's cloud",
      },
      {
        label: 'Bring your own cloud (BYOC)',
        path: '/features/bring-your-own-cloud',
        icon: Cloud,
        description: 'GCP, AWS, Azure, Civo, & bare-metal',
      },
    ],
  },
];

export const solutionPages = [
  {
    title: 'Use cases',
    items: [
      {
        label: 'Production workloads',
        path: '/use-cases/production-workloads-deployment-platform',
        icon: PackageIcon,
        description: 'Post-commit toolkit for production apps',
      },
      {
        label: 'App platform for Kubernetes',
        path: '/use-cases/app-platform-for-kubernetes',
        icon: Kubernetes,
        description: 'Ideal abstraction level for Kubernetes',
      },
      {
        label: 'Bring your own cloud',
        path: '/use-cases/bring-your-own-cloud-app-platform-kubernetes',
        icon: CloudUpload,
        description: 'Deploy products to customer environments',
      },
      {
        label: 'Preview environments',
        path: '/use-cases/preview-environments-backend-for-kubernetes',
        icon: Search,
        description: 'Fast ephemeral environments for PRs',
      },
      {
        label: 'Internal developer platform',
        path: '/use-cases/internal-developer-platform-idp-for-kubernetes',
        icon: Customize,
        description: 'Composable platform ready today',
      },
      {
        label: 'Self-service DX',
        path: '/use-cases/self-service-developer-experience-for-kubernetes',
        icon: User,
        description: 'Empower developers through self-service',
      },
      {
        label: 'Right-sized by default',
        path: '/use-cases/right-sized-infrastructure-optimisation-for-kubernetes',
        icon: LineChartDown,
        description: 'Optimize performance and cost efficiency',
      },
      {
        label: 'Disaster recovery',
        path: '/use-cases/disaster-recovery-for-kubernetes',
        icon: ShieldQuarter,
        description: 'Fail-over and rollback for disasters',
      },
      // {
      //   label: 'Secure multi-tenancy',
      //   path: '/solutions/use-cases/multi-tenancy',
      //   icon: Group,
      //   description: 'Safely run untrusted code at scale',
      // },
      // {
      //   label: 'Hybrid cloud',
      //   path: '/solutions/use-cases/hybrid-cloud',
      //   icon: Cloud,
      //   description: 'Consistent deployment across multiple clouds',
      // },
    ],
  },
  // {
  //   title: 'Northflank for',
  //   items: [
  //     {
  //       label: 'Software engineers',
  //       path: '/personas/software-engineers',
  //       icon: Bug,
  //       description: 'Deploy code faster with Northflank',
  //     },
  //     // {
  //     //   label: 'SREs',
  //     //   path: '/solutions/for/sre',
  //     //   icon: PlusMedical,
  //     //   description: 'Enhance reliability and uptime',
  //     // },
  //     {
  //       label: 'DevOps/Platform',
  //       path: '/personas/devops-engineers',
  //       icon: Factory,
  //       description: 'Automate infrastructure management tasks',
  //     },
  //     // {
  //     //   label: 'CTO/VPE',
  //     //   path: '/solutions/for/cto',
  //     //   icon: Briefcase,
  //     //   description: 'Accelerate delivery and innovation',
  //     // },
  //   ],
  // },
];

const FeatureNav = () => {
  const { pathname } = useLocation();

  const flattenedPages = pages.reduce((acc, cur) => {
    acc = acc.concat(cur.items);
    return acc;
  }, []);

  return (
    <Box
      display={['none', 'none', 'block']}
      bg="grey11"
      position="sticky"
      top="80px"
      zIndex={11}
      borderTop="1px solid"
      borderBottom="1px solid"
      borderColor="grey5"
      boxShadow="dropDown"
      px={10}
      py={2}
    >
      <FeatureTabs
        tabs={flattenedPages.map((f) => ({
          value: f.path,
          icon: f.icon,
          label: f.label,
          link: f.path,
        }))}
        activeTab={flattenedPages.find((p) => p.path === pathname)?.path}
        mx="auto"
        flexWrap="wrap"
        justifyContent="center"
      />
    </Box>
  );
};

export default FeatureNav;
