import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import '@northflank/components/marketing/util/line-numbers';

import { Facebook } from '@styled-icons/boxicons-logos/Facebook';
import { Linkedin } from '@styled-icons/boxicons-logos/Linkedin';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Heading from '@northflank/components/Heading';
import InfoBox from '@northflank/components/InfoBox';
import Label from '@northflank/components/Label';
import MarkdownBody from '@northflank/components/marketing/MarkdownBody';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';
import Tooltip from '@northflank/components/Tooltip';
import X from '@northflank/images/icons/X';

import PreHeading from '../../components/PreHeading';
import formatDate from '../../utils/formatDate';
import useSyntaxHighlighting from '../../utils/useSyntaxHighlighting';
import { AuthorAvatar, ExtraAuthors, StyledLink } from '../blog/post';
import { BackLink, ShareLink } from '../changelog/post';
import { getAnchor } from '../guides/post';

const StackPost = ({ post, renderedPost }) => {
  const { pathname } = useLocation();

  useSyntaxHighlighting([post.attributes.slug]);

  const shareText = encodeURIComponent(
    `Northflank Stack Templates — ${post.template.name}`
  );

  const headingRegex = /^#+ /; // starts with one or more hashes followed by space

  // find lines that start with #, but not inside code blocks
  const headings = [];
  let inCodeBlock = false;
  for (const line of post.attributes.content.split('\n')) {
    if (line.startsWith('```')) inCodeBlock = !inCodeBlock;
    if (headingRegex.test(line) && !inCodeBlock) headings.push(line);
  }

  const headingsWithLevels = headings.map((heading) => {
    const [match] = headingRegex.exec(heading);
    return {
      level: match.match(/#/g || []).length,
      text: heading.replace(match, ''),
      anchor: `#${getAnchor(heading.replace(match, ''))}`,
    };
  });
  const minLevel = Math.min(...headingsWithLevels.map((h) => h.level));

  return (
    <>
      <SEO
        title={`${post.template.name} — Stack Templates`}
        description={post.attributes.excerpt}
        image={post.attributes.header?.data?.attributes.url}
      />
      <Box variant="bounding" position="relative">
        <Box
          position={['static', 'static', 'absolute']}
          top={[12, 12, '96px']}
          bottom={[12, 12, '96px']}
          left={12}
          maxWidth={['750px', '750px', '250px', '300px']}
          width="100%"
          mx="auto"
          mb={[12, 12, 12, 0]}
        >
          <Box
            position="sticky"
            top="110px"
            maxHeight={['none', 'none', 'calc(100vh - 150px)']}
            overflowY="hidden"
            display="flex"
            flexDirection="column"
          >
            <Link to="/stacks">
              <BackLink>&larr; Back to Stacks</BackLink>
            </Link>
            <Box
              width="100%"
              border="2px solid"
              borderColor="grey6"
              borderRadius={3}
              flexShrink={0}
              _css={{
                aspectRatio: '4/3',
                backgroundImage: `url(${post.attributes.header?.data?.attributes.url})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
              mb={10}
            />
            <Button
              to={`https://app.northflank.com/s/account/stack-templates/${post.template.internalId}`}
              target="_blank"
              externalLink
              variant={['large', 'primary']}
              width="100%"
              linkStyle={{ width: '100%' }}
              mb={10}
            >
              Deploy {post.template.name} Now
            </Button>
            {post.attributes.free && (
              <Label
                containerProps={{ maxHeight: '64px' }}
                px={8}
                py={4}
                mb={10}
              >
                <PreHeading>Deploy for free</PreHeading>
              </Label>
            )}
            {!!post.attributes.product_url && (
              <Box mb={10}>
                <Text
                  fontWeight={600}
                  color="grey2"
                  css={{ textTransform: 'uppercase' }}
                  mb={4}
                >
                  Website
                </Text>
                <Text
                  as="a"
                  href={post.attributes.product_url}
                  target="_blank"
                  fontSize={3}
                  css={{ wordBreak: 'break-all' }}
                >
                  {post.attributes.product_url.replace(/^http(s)?:\/\//i, '')}
                </Text>
              </Box>
            )}
            {!!post.attributes.docs_url && (
              <Box mb={10}>
                <Text
                  fontWeight={600}
                  color="grey2"
                  css={{ textTransform: 'uppercase' }}
                  mb={4}
                >
                  Docs
                </Text>
                <Text
                  as="a"
                  href={post.attributes.docs_url}
                  target="_blank"
                  fontSize={3}
                  css={{ wordBreak: 'break-all' }}
                >
                  {post.attributes.docs_url.replace(/^http(s)?:\/\//i, '')}
                </Text>
              </Box>
            )}
            {headingsWithLevels.length > 1 && (
              <Box
                bg="grey9"
                border="1px solid"
                borderColor="grey6"
                borderRadius={2}
                overflowY="auto"
                _css={{
                  '&::-webkit-scrollbar-track': { bg: 'grey9' },
                  '&::-webkit-scrollbar-thumb': { borderColor: 'grey9' },
                }}
                p={8}
              >
                <PreHeading mb={6}>In this stack guide</PreHeading>
                <Box _css={{ '> * + *': { mt: 4 } }}>
                  {headingsWithLevels.map((heading) => (
                    <Text
                      key={heading.anchor}
                      as="a"
                      href={heading.anchor}
                      color="grey1"
                      display="block"
                      ml={`${(heading.level - minLevel) * 12}px`}
                      css={{ '&:hover': { textDecoration: 'underline' } }}
                    >
                      {heading.text}
                    </Text>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          maxWidth="100%"
          ml={[0, 0, 'calc(250px + 32px)', 'calc(300px + 32px)']}
          mt={[0, 0, '46px']}
        >
          <Box>
            {!!post.attributes.stack_categories?.data.length && (
              <Box variant="flex" mb={6}>
                {post.attributes.stack_categories.data.map((category, i) => (
                  <Text
                    key={category}
                    color="success"
                    as="span"
                    textTransform="uppercase"
                    fontWeight={500}
                    fontSize={1}
                    mr={3}
                  >
                    <Link to={`/stacks?category=${category.attributes.slug}`}>
                      <Text
                        color="success"
                        css={{ '&:hover': { textDecoration: 'underline' } }}
                      >
                        {category.attributes.title}
                        {i < post.attributes.stack_categories.data.length - 1 &&
                          ', '}
                      </Text>
                    </Link>
                  </Text>
                ))}
              </Box>
            )}
          </Box>
          <Heading as="h1" color="grey1" fontSize={[5, 5, 6]} mb={6}>
            Deploy {post.template.name} on Northflank
          </Heading>
          <Text color="grey3" mb={7}>
            Published {formatDate(post.attributes.publication_date)}
          </Text>
          <Box display="flex" flexDirection="column" mb={10}>
            <Box
              variant="flex"
              alignItems={[
                post.attributes.writers.data.length > 1
                  ? 'flex-start'
                  : 'center',
                post.attributes.writers.data.length > 1
                  ? 'flex-start'
                  : 'center',
                'center',
                'center',
              ]}
              flexDirection={[
                post.attributes.writers.data.length > 1 ? 'column' : 'row',
                post.attributes.writers.data.length > 1 ? 'column' : 'row',
                'row',
                'row',
              ]}
            >
              {!!post.attributes.writers?.data.length && (
                <Box
                  variant="flex"
                  height="40px"
                  mr={6}
                  mb={
                    post.attributes.writers.data.length > 1 ? [6, 6, 0, 0] : 0
                  }
                >
                  {post.attributes.writers.data.slice(0, 3).map((author, i) => (
                    <Tooltip
                      key={author.id}
                      position="bottom"
                      distance={5}
                      ml={i > 0 && '-20px'}
                      html={
                        <Box>
                          <Text>{author.attributes.name}</Text>
                          {author.attributes.twitter && (
                            <StyledLink
                              href={`https://x.com/${author.attributes.twitter.replace(
                                '@',
                                ''
                              )}`}
                              target="_blank"
                            >
                              {author.attributes.twitter}
                            </StyledLink>
                          )}
                        </Box>
                      }
                      interactive
                      inline
                    >
                      <AuthorAvatar
                        src={
                          author.attributes.picture?.data?.attributes.url ||
                          '/images/headshot/default.png'
                        }
                        alt={`Profile image for ${author.attributes.name}`}
                        size="40px"
                      />
                    </Tooltip>
                  ))}
                  {post.attributes.writers.data.length > 3 && (
                    <ExtraAuthors ml="-20px">
                      +{post.attributes.writers.data.length - 3}
                    </ExtraAuthors>
                  )}
                </Box>
              )}
              <Box>
                {!!post.attributes.writers?.data.length && (
                  <Text>
                    By{' '}
                    {post.attributes.writers.data.map((author, i) => (
                      <>
                        <Link to={`/author/${author.attributes.slug}`}>
                          <Text
                            color="grey1"
                            css={{ '&:hover': { textDecoration: 'underline' } }}
                          >
                            {author.attributes.name}
                          </Text>
                        </Link>
                        {i < post.attributes.writers.data.length - 1 && ', '}
                      </>
                    ))}
                  </Text>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            pt={12}
            mt={12}
            borderTop="1px solid"
            borderColor="grey6"
            position="relative"
          >
            <MarkdownBody dangerouslySetInnerHTML={{ __html: renderedPost }} />
          </Box>
          {post.attributes.free && (
            <InfoBox mt={12}>
              <PreHeading mb={10}>Deploy for free</PreHeading>
              <Text>
                This stack template is compatible with the free{' '}
                <Link to="/pricing">Developer Sandbox plan</Link> offered by
                Northflank.
              </Text>
            </InfoBox>
          )}
          <Box pt={12} mt={12} borderTop="1px solid" borderColor="grey6">
            <Box>
              <PreHeading mb={6}>
                Share this template with your network
              </PreHeading>
            </Box>
            <ShareLink
              href={`https://x.com/intent/tweet?text=${shareText}&url=https://northflank.com${pathname}`}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Share to X"
            >
              <X size={24} />
            </ShareLink>
            <ShareLink
              href={`https://www.facebook.com/sharer/sharer.php?t=${shareText}&u=https://northflank.com${pathname}`}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Share to Facebook"
            >
              <Facebook size={32} />
            </ShareLink>
            <ShareLink
              href={`https://www.linkedin.com/shareArticle?mini=true&text=${shareText}&url=https://northflank.com${pathname}`}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Share to LinkedIn"
            >
              <Linkedin size={32} />
            </ShareLink>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default StackPost;
