import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import css from '@styled-system/css';
import { lighten } from 'polished';
import styled, { ThemeContext } from 'styled-components';

import Box from '@northflank/components/Box';
import Icon from '@northflank/components/Icon';
import Image from '@northflank/components/Image';
import Text from '@northflank/components/Text';
import useBackgroundCursorPosition from '@northflank/utilities/hooks/useBackgroundCursorPosition';

const PreHeading = styled(Text)(() =>
  css({
    backgroundImage: 'linear-gradient(90deg, #0A84FF 10%, #0AF0FF 90%)',
    backgroundSize: '100%',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    '-moz-background-clip': 'text',
    '-moz-text-fill-color': 'transparent',
    letterSpacing: '1px',
  })
);

export const WrapLink = ({ href, children }) =>
  href ? <Link to={href}>{children}</Link> : children;

const FeatureCard = ({
  icon,
  iconColor,
  preHeading,
  heading,
  body,
  image,
  bottomImage,
  textAlign = 'center',
  link,
}) => {
  const theme = useContext(ThemeContext);
  const [elementRef, background] = useBackgroundCursorPosition({
    backgroundColor: theme.colors.grey8,
    highlightColor: lighten(0.03, theme.colors.grey8),
    highlightSize: '400px',
    active: !!link,
  });
  return (
    <WrapLink href={link}>
      <Box
        ref={elementRef}
        bg="grey8"
        border="1px solid"
        borderColor="grey5"
        borderRadius={2}
        p={bottomImage ? [12, 12, 13] : 10}
        pb={bottomImage && [0, 0, 0]}
        textAlign={textAlign}
        height="100%"
        boxShadow="dropDown"
        position="relative"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        style={{
          background,
        }}
      >
        {icon && (
          <Icon Content={icon} Size={32} color={iconColor || 'grey3'} mb={10} />
        )}
        {image && <Image src={image} alt={heading} width="100%" mb={6} />}
        {preHeading && (
          <PreHeading
            fontSize={0}
            mb={4}
            textTransform="uppercase"
            fontWeight={600}
          >
            {preHeading}
          </PreHeading>
        )}
        <Text
          color="grey1"
          fontSize={bottomImage ? 6 : 4}
          fontWeight={500}
          mb={6}
        >
          {heading}
        </Text>
        <Text color="grey2" fontSize={bottomImage ? 3 : 2}>
          {body}
        </Text>
        {bottomImage && (
          <Image
            src={bottomImage}
            alt={heading}
            width="100%"
            borderRadius="8px 8px 0 0"
            mt={[12, 12, 13]}
            mb="-4px"
          />
        )}
      </Box>
    </WrapLink>
  );
};

export default FeatureCard;
