import React, { useRef } from 'react';
import { createPortal } from 'react-dom';

import { CopyAlt } from '@styled-icons/boxicons-regular/CopyAlt';
import css from '@styled-system/css';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Icon from '@northflank/components/Icon';
import notify from '@northflank/components/Noty';
import Text from '@northflank/components/Text';
import Tooltip from '@northflank/components/Tooltip';
import withComponentId from '@northflank/utilities/withComponentId';

// Redefined here to avoid marketing build issue
const uppercase = ['tls', 'ci', 'cd', 'ci/cd', 'cmd'];
const capitalize = (word) =>
  uppercase.includes(word)
    ? word.toUpperCase()
    : word.charAt(0).toUpperCase() + word.substr(1);

const hiddenStyle = {
  opacity: 0,
  height: '1px',
  width: '1px',
  zIndex: -1,
  overflow: 'hidden',
};

export const StyledCopyToClipboard = styled(Box)(({ commitHash, maxWidth }) =>
  css({
    display: 'flex',
    minWidth: '45px',
    maxWidth: commitHash ? '105px' : maxWidth || 'max-content',
    borderRadius: 1,
    borderColor: 'grey6',
    borderStyle: 'solid',
    borderWidth: '1px',
    cursor: 'pointer',
  })
);

const ValueTooltip = ({ valueTooltip, children }) =>
  valueTooltip ? (
    <Tooltip html={valueTooltip} overflow="hidden">
      {children}
    </Tooltip>
  ) : (
    children
  );

const CopyToClipboard = ({
  uid,
  children,
  typeOfElement,
  displayValue,
  hideValue,
  noTooltip,
  commitHash,
  button,
  maxWidth,
  showFullOnHover,
  noLowerCase,
  valueTooltip,
  portal,
  ...rest
}) => {
  const clipboard = useRef(null);

  const lowerCaseType = noLowerCase
    ? typeOfElement
    : typeof typeOfElement === 'string'
      ? typeOfElement.toLowerCase()
      : '';

  const portalFn = (el) =>
    portal ? createPortal(el, document.querySelector('#main')) : el;

  if (button) {
    return (
      <>
        <Tooltip
          title={`Copy ${lowerCaseType}`}
          disabled={noTooltip}
          style={{ display: 'flex' }}
        >
          <Button
            variant="icon"
            onClick={(e) => copyToClipboard(clipboard, e, lowerCaseType)}
            {...rest}
          >
            <Icon Content={CopyAlt} size={16} />
          </Button>
        </Tooltip>
        <Text ref={clipboard} {...hiddenStyle}>
          {children}
        </Text>
      </>
    );
  }
  return (
    <StyledCopyToClipboard
      commitHash={commitHash}
      maxWidth={maxWidth}
      onClick={(e) => copyToClipboard(clipboard, e, lowerCaseType)}
      id={uid && `copy-${uid}`}
      {...rest}
    >
      <Tooltip
        title={`Copy ${lowerCaseType}`}
        disabled={noTooltip}
        style={{ height: '100%' }}
      >
        {/* className required to set border color within safari :visited links */}
        <Box
          className="copyIconContainer"
          px={4}
          py={2}
          variant={['flex', 'icon']}
          alignItems="flex-start"
          borderRightColor="grey6"
          borderRightWidth="1px"
          borderRightStyle="solid"
          height="100%"
          mt="1px"
        >
          <Icon
            color="grey3"
            Size={{ height: 14, width: 14 }}
            Content={CopyAlt}
          />
        </Box>
      </Tooltip>
      {displayValue && (
        <Text
          color="grey2"
          fontFamily="monospace"
          fontSize={0}
          px={5}
          py={2}
          css={
            showFullOnHover
              ? {
                  'text-overflow': 'ellipsis',
                  'white-space': 'nowrap',
                  overflow: 'hidden',
                  '&:hover': {
                    'text-overflow': 'unset',
                    'white-space': 'normal',
                    'word-break': 'break-word',
                  },
                }
              : {
                  'text-overflow': 'ellipsis',
                  'white-space': 'nowrap',
                  overflow: 'hidden',
                }
          }
        >
          {displayValue}
        </Text>
      )}
      <ValueTooltip valueTooltip={valueTooltip}>
        {portalFn(
          <Text
            variant="ellipse"
            color="grey2"
            fontFamily="monospace"
            fontSize={0}
            px={displayValue ? 0 : 5}
            py={displayValue ? 0 : 2}
            ref={clipboard}
            {...(displayValue ? hiddenStyle : {})}
          >
            {children || displayValue}
          </Text>
        )}
      </ValueTooltip>
    </StyledCopyToClipboard>
  );
};

export const copyToClipboard = (ref, e, type, notifyFunction = notify) => {
  const node = ref.current;
  e?.preventDefault();
  e?.stopPropagation();
  const capitalisedType = capitalize(type);
  const isChrome = !!window.chrome && !!window.chrome.webstore;

  const selection = window.getSelection();
  const range = document.createRange();
  if (isChrome) range.selectNode(node);
  else range.selectNodeContents(node);
  selection.removeAllRanges();
  selection.addRange(range);

  try {
    document.execCommand('copy');
    notifyFunction(
      `${capitalisedType} ${capitalisedType.length > 0 ? 'copied' : 'Copied'} successfully`,
      'success'
    );
  } catch (e) {
    notifyFunction(`Error copying ${capitalisedType}: ${e}`, 'error');
  }

  selection.removeAllRanges();
  // notify('Copied to clipboard', 'alert', { timeout: 1750 });
};

CopyToClipboard.propTypes = {
  typeOfElement: PropTypes.string,
};

CopyToClipboard.defaultProps = {
  typeOfElement: '',
};

export default withComponentId(CopyToClipboard);
