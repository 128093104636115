import React from 'react';

import css from '@styled-system/css';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';

import Box from '@northflank/components/Box';
import Text from '@northflank/components/Text';
import withComponentId from '@northflank/utilities/withComponentId';

const Container = styled.label(space, ({ theme, color, error }) =>
  css({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    input: {
      opacity: 0,
      height: 0,
      width: 0,
      '& ~ .toggle': {
        position: 'relative',
        transition: 'background-color 100ms ease-in',
        '.pip': {
          position: 'absolute',
          top: '3px',
          transition: 'left 100ms ease-in',
        },
      },
      '&:checked': {
        '& ~ .toggle': {
          bg: error ? 'danger' : color ? theme.colors[color] : 'success',
          '.pip': {
            left: '15px',
          },
        },
        '&:focus': {
          '& ~ .toggle': {
            bg: error
              ? 'dangerLight1'
              : color
                ? theme.colors[`${color}Light1`]
                : 'successLight1',
          },
        },
      },
      '&:not(:checked)': {
        '& ~ .toggle': {
          bg: error ? 'danger' : 'grey4',
          '.pip': {
            left: '3px',
          },
        },
        '&:focus': {
          '& ~ .toggle': {
            bg: 'grey3',
          },
        },
      },
    },
    '&[disabled]': {
      cursor: 'not-allowed',
      span: {
        color: 'grey4',
      },
      input: {
        '& ~ .toggle': {
          bg: 'grey5',
          '.pip': {
            bg: 'grey7',
          },
        },
      },
    },
  })
);

/*
  Props get spread onto Container to allow styling by props. If you want to
  pass props to the input itself (such as name, value etc.), use the
  `inputProps` object, which will be spread onto the input element.
*/
const Toggle = ({
  uid,
  label,
  labelBefore: _labelBefore,
  labelAfter: _labelAfter,
  reverse,
  color,
  disabled,
  inputProps,
  labelProps,
  name,
  error,
  hideError,
  ...props
}) => {
  const labelBefore = _labelBefore || (!reverse && label);
  const labelAfter = _labelAfter || (reverse && label);

  return (
    <Box variant="flex">
      <Container disabled={disabled} color={color} error={error} {...props}>
        {labelBefore && (
          <Text
            as="span"
            display="inline-block"
            color="grey1"
            mr={6}
            lineHeight={1}
            fontSize={1}
          >
            {labelBefore}
          </Text>
        )}
        <input
          id={`toggle-${uid}`}
          type="checkbox"
          disabled={disabled}
          name={name}
          {...inputProps}
        />
        <Box
          className="toggle"
          bg="grey4"
          height="16px"
          width="28px"
          minWidth="28px"
          borderRadius={3}
        >
          <Box
            className="pip"
            bg="grey9"
            height="10px"
            width="10px"
            minWidth="10px"
            borderRadius={5}
          />
        </Box>
        {labelAfter && (
          <Text
            as="span"
            display="inline-block"
            color="grey1"
            ml={6}
            lineHeight={1}
            fontSize={1}
            {...labelProps}
          >
            {labelAfter}
          </Text>
        )}
      </Container>
      {!hideError && error && (
        <Text color="danger" fontSize={0} mt={5}>
          {error}
        </Text>
      )}
      <Box />
    </Box>
  );
};

Toggle.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  reverse: PropTypes.bool,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  labelProps: PropTypes.object,
  name: PropTypes.string,
  error: PropTypes.string,
};

export default withComponentId(Toggle);
