import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import css from '@styled-system/css';
import { transparentize } from 'polished';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { space } from 'styled-system';

import Box from '@northflank/components/Box';
import FieldLabel from '@northflank/components/FieldLabel';
import Text from '@northflank/components/Text';

const thumbStyle = {
  appearance: 'none',
  mt: '-3px',
  bg: 'grey9',
  border: '4px solid',
  borderColor: 'primary',
  height: '16px',
  width: '16px',
  borderRadius: '50%',
  cursor: 'pointer',
};

const sliderStyle = {
  appearance: 'none',
  width: '100%',
  height: '8px',
  cursor: 'pointer',
  background: 'var(--range-bg)',
  borderRadius: '100px',
  boxShadow: 'inset 0px 0px 8px rgba(0, 0, 0, 0.12)',
};

const Input = styled.input(
  space,
  css({
    appearance: 'none',
    width: '100%',
    background: 'transparent',

    '&::-webkit-slider-thumb': {
      ...thumbStyle,
      mt: '-4px' /* alignment fix for Chrome */,
    },
    '&::-moz-range-thumb': { ...thumbStyle },
    '&::-ms-thumb': { ...thumbStyle, margin: 0 },

    '&:focus': {
      outline: 'none',
      '&::-webkit-slider-thumb': {
        borderWidth: '2px',
      },
      '&::-moz-range-thumb': {
        borderWidth: '2px',
      },
      '&::-ms-thumb': {
        borderWidth: '2px',
      },
    },

    '&::-webkit-slider-runnable-track': sliderStyle,
    '&::-moz-range-track': sliderStyle,
    '&::-ms-track': sliderStyle,

    '&::-ms-fill-lower': {
      bg: 'primary',
    },
  })
);

const DisabledStyle = styled.div(({ disabled }) =>
  css({
    opacity: disabled ? 0.5 : 1,
    cursor: disabled ? 'not-allowed' : 'pointer',
  })
);

const getClosestHit = (options, input) =>
  options.reduce((prev, curr) =>
    Math.abs(curr - input) < Math.abs(prev - input) ? curr : prev
  );

const InputRange = ({
  value: inputValue,
  minValue,
  minValidValue,
  maxValue,
  initialValue,
  text,
  Label,
  onChange,
  onBlur,
  validOptions,
  markedOptions = [],
  width,
  disabled,
  renderDisplayValue = (v) => v,
  ...rest
}) => {
  const [value, setValue] = useState(initialValue || minValidValue || minValue);

  const inputRef = useRef();

  const { colors } = useContext(ThemeContext);

  const progress = useMemo(
    () => ((value - minValue) / (maxValue - minValue)) * 100,
    [value, minValue, maxValue]
  );

  const scaleOptions = useMemo(() => {
    if (!validOptions) return [];

    const opts = [...validOptions];
    if (opts.includes(minValue)) opts.splice(0, 1);
    opts.splice(-1, 1);

    return opts;
  }, [JSON.stringify(validOptions), minValue]);

  useEffect(() => {
    handleChange({ target: { value: inputValue } });
  }, [inputValue]);

  useEffect(() => {
    const minProgress =
      ((minValidValue - minValue) / (maxValue - minValue)) * 100;
    inputRef.current.style.setProperty(
      '--range-bg',
      minProgress
        ? `linear-gradient(90deg, ${colors.grey6} 0% ${minProgress}%, ${
            colors.primary
          } ${minProgress}% ${progress}%, ${transparentize(
            0.75,
            colors.primary
          )} ${progress}% 100%)`
        : `linear-gradient(90deg, ${colors.primary} 0% ${progress}%, ${transparentize(
            0.75,
            colors.primary
          )} ${progress}% 100%)`
    );
  }, [progress]);

  const getValueProgress = (val) =>
    ((val - minValue) / (maxValue - minValue)) * 100;

  const handleChange = useCallback((event) => {
    let value;
    const selected = event.target.value;
    if (validOptions) {
      value = parseInt(getClosestHit(validOptions, selected));
    } else {
      value = parseInt(selected);
    }

    if (minValidValue && value < minValidValue) value = minValidValue;

    if (typeof onChange === 'function') onChange(value, event);
    setValue(value);
  }, []);

  const handleMouseUp = useCallback(() => {
    if (typeof onBlur === 'function') onBlur(value);
  }, [value]);

  return (
    <Box maxWidth={width} width="100%" {...rest}>
      <Box mb={6}>{Label || <FieldLabel color="grey3">{text}</FieldLabel>}</Box>
      <DisabledStyle disabled={disabled}>
        <Input
          ref={inputRef}
          type="range"
          onChange={handleChange}
          onMouseUp={handleMouseUp}
          min={minValue}
          max={maxValue}
          value={value}
          mb={6}
          disabled={disabled}
          css={{ pointerEvents: disabled ? 'none' : 'all' }}
        />
        <Box variant="flex" justifyContent="space-between" position="relative">
          <Text color={value === minValue ? 'grey1' : 'grey4'} fontSize={0}>
            {renderDisplayValue(minValue)}
          </Text>
          {value !== minValue && value !== maxValue && (
            <Text
              color="grey1"
              fontSize={0}
              textAlign="center"
              position="absolute"
              left={`calc(${progress}% + (${7 - progress * 0.125}px))`}
              css={{ transform: 'translateX(-50%)' }}
            >
              {renderDisplayValue(value)}
            </Text>
          )}
          {(scaleOptions || markedOptions) &&
            [...scaleOptions, ...markedOptions].map(
              (val, i) =>
                val !== value && (
                  <Text
                    key={i}
                    position="absolute"
                    top="-2px"
                    left={`${getValueProgress(val)}%`}
                    color="grey3"
                    fontSize="6px"
                    lineHeight={1}
                    opacity="0.6"
                    css={{ transform: 'translateX(-50%)' }}
                  >
                    |
                  </Text>
                )
            )}
          <Text
            color={value === maxValue ? 'grey1' : 'grey4'}
            fontSize={0}
            textAlign="right"
          >
            {renderDisplayValue(maxValue)}
          </Text>
        </Box>
      </DisabledStyle>
    </Box>
  );
};

InputRange.propTypes = {
  minValue: PropTypes.number.isRequired,
  minValidValue: PropTypes.number,
  maxValue: PropTypes.number.isRequired,
  initialValue: PropTypes.number,
  text: PropTypes.string,
  Label: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  validOptions: PropTypes.arrayOf(PropTypes.number),
  width: PropTypes.string,
};

export default InputRange;
