import React from 'react';

import { Digitalocean } from '@styled-icons/boxicons-logos/Digitalocean';
import { Discord } from '@styled-icons/boxicons-logos/Discord';
import { Gitlab } from '@styled-icons/boxicons-logos/Gitlab';
import { GoogleCloud } from '@styled-icons/boxicons-logos/GoogleCloud';
import { Kubernetes } from '@styled-icons/boxicons-logos/Kubernetes';
import { MicrosoftTeams } from '@styled-icons/boxicons-logos/MicrosoftTeams';
import { Slack } from '@styled-icons/boxicons-logos/Slack';
import { AddToQueue } from '@styled-icons/boxicons-regular/AddToQueue';
import { CheckCircle } from '@styled-icons/boxicons-regular/CheckCircle';
import { CheckShield } from '@styled-icons/boxicons-regular/CheckShield';
import { Cloud } from '@styled-icons/boxicons-regular/Cloud';
import { CloudDownload } from '@styled-icons/boxicons-regular/CloudDownload';
import { CloudUpload } from '@styled-icons/boxicons-regular/CloudUpload';
import { Cog } from '@styled-icons/boxicons-regular/Cog';
import { Cube } from '@styled-icons/boxicons-regular/Cube';
import { Customize } from '@styled-icons/boxicons-regular/Customize';
import { File } from '@styled-icons/boxicons-regular/File';
import { GitCommit } from '@styled-icons/boxicons-regular/GitCommit';
import { GitPullRequest } from '@styled-icons/boxicons-regular/GitPullRequest';
import { Globe } from '@styled-icons/boxicons-regular/Globe';
import { Hdd } from '@styled-icons/boxicons-regular/Hdd';
import { HeartCircle } from '@styled-icons/boxicons-regular/HeartCircle';
import { Layer } from '@styled-icons/boxicons-regular/Layer';
import { Link } from '@styled-icons/boxicons-regular/Link';
import { PlayCircle } from '@styled-icons/boxicons-regular/PlayCircle';
import { QuestionMark } from '@styled-icons/boxicons-regular/QuestionMark';
import { Server } from '@styled-icons/boxicons-regular/Server';
import { ShareAlt } from '@styled-icons/boxicons-regular/ShareAlt';
import { Import } from '@styled-icons/boxicons-regular/Import';
import { StopCircle } from '@styled-icons/boxicons-regular/StopCircle';
import { Terminal } from '@styled-icons/boxicons-regular/Terminal';
import { Timer } from '@styled-icons/boxicons-regular/Timer';
import { Bitbucket } from '@styled-icons/fa-brands/Bitbucket';
import { Docker } from '@styled-icons/fa-brands/Docker';
import { Github } from '@styled-icons/fa-brands/Github';
import { Amazonaws } from '@styled-icons/simple-icons/Amazonaws';
import { Azuredevops } from '@styled-icons/simple-icons/Azuredevops';
import { Fastly } from '@styled-icons/simple-icons/Fastly';
import { Heroku } from '@styled-icons/simple-icons/Heroku';
import { Microsoftazure } from '@styled-icons/simple-icons/Microsoftazure';
import { split } from 'shlex';

import {
  capitalize,
  cdnProviderFeatureFlagMap,
  CDNProviders,
  DockerCredentialProviders,
  LifecycleStates,
  TemplateDraftStates,
} from '@northflank/constants-base';

import { ArrowToTop, Expand, TrashAlt } from '@styled-icons/boxicons-regular';
import { Oracle } from '@styled-icons/simple-icons/Oracle';
import { NorthflankIcon } from './NorthflankIcon';

export * from './functions';
export * from './determineProjectLimits';
export const serviceTypeOptions = [
  {
    key: 'combined',
    title: 'Combined',
    description: 'Create a complete CI/CD pipeline in one service',
    summary: 'Build and deploy a Git repo',
    icon: Link,
    longDescription:
      'A combined service will build a specific branch from a Git repository, and deploy new builds automatically. New commits will be built as you make them, giving you a full CI/CD pipeline in a single service.',
    templateKind: 'CombinedService',
  },
  {
    key: 'build',
    title: 'Build',
    description: 'Build repository branches & pull requests from Git',
    summary: 'Build a Git repo and store image',
    icon: Cog,
    longDescription:
      'A build service will build specific branches and pull requests from a Git repo, and store the resulting images in the Northflank container registry for later use by deployment services. Custom build rules tell the service exactly what it should build.',
    templateKind: 'BuildService',
  },
  {
    key: 'deployment',
    title: 'Deployment',
    description: 'Deploy built images from Northflank or external registries',
    summary: 'Deploy a Docker image',
    icon: CloudUpload,
    longDescription:
      'A deployment service will deploy both public and private Docker images from your existing build services or from external Docker registries. Using pipelines, multiple deployment services can be linked in stages to create a full promotion flow.',
    templateKind: 'DeploymentService',
  },
];

export const jobOptions = [
  {
    key: 'cron',
    title: 'Cron job',
    shortTitle: 'Cron',
    description: 'Run on a schedule',
    summary: 'Run code automatically on a defined schedule',
    icon: Timer,
    longDescription:
      'A cron job will run automatically, as often as it is scheduled to. Cron jobs can also be manually triggered via the UI, API, CLI, or templates.',
    templateKind: 'CronJob',
  },
  {
    key: 'manual',
    title: 'Manual job',
    shortTitle: 'Manual',
    description: 'Run manually',
    summary: 'Run code manually when triggered',
    icon: PlayCircle,
    longDescription:
      'A manual job will not run until manually triggered to do so. They can be triggered via the UI, API, CLI, or via templates.',
    templateKind: 'ManualJob',
  },
];

export const vcsOptions = [
  {
    key: 'github',
    title: 'GitHub',
    Icon: Github,
    teamIntegrationDescription: `Link your GitHub user or organisation account to your team. This will allow you to use that account's repositories.`,
    userIntegrationDescription:
      'GitHub is linked by installing the Northflank GitHub app on your repositories. These can then be used with Northflank.',
    url: 'https://github.com',
  },
  {
    key: 'bitbucket',
    title: 'Bitbucket',
    Icon: Bitbucket,
    teamIntegrationDescription:
      "Link your Bitbucket user account to your team. This will allow you to use that account's repositories.",
    userIntegrationDescription:
      'Bitbucket is linked via OAuth. This will allow use of any repository you have access to.',
    url: 'https://bitbucket.org',
  },
  {
    key: 'gitlab',
    title: 'GitLab',
    Icon: Gitlab,
    teamIntegrationDescription:
      "Link your GitLab user account to your team. This will allow you to use that account's repositories.",
    userIntegrationDescription:
      'GitLab is linked via OAuth. This will allow use of any repository you have access to.',
    url: 'https://gitlab.com',
  },
  {
    key: 'azure',
    title: 'Azure DevOps',
    Icon: Azuredevops,
    teamIntegrationDescription:
      "Link your Microsoft user account to your team. This will allow you to use that account's repositories.",
    userIntegrationDescription:
      'Azure Devops is linked via OAuth. This will allow use of any repository you have access to.',
    url: 'https://dev.azure.com/',
    allowPAT: true,
  },
];

export const selfHostedVCSOptions = [
  {
    key: 'gitlab-ee',
    title: 'GitLab EE',
    value: 'gitlab-ee',
    label: 'GitLab Enterprise Edition or Community Edition',
    Icon: Server,
  },
];

export const vcsRepoUnlinkedStatusData = {
  'UNINSTALLED_REPO': {
    title: 'Repository unlinked from GitHub app',
    description:
      "Access to this service's repository has been removed from the linked GitHub installation. Please edit your installation settings to allow access to this repository again if you wish to continue using this service.",
    displayEditInstallation: true,
  },
  'DELETED': {
    title: 'Repository no longer exists',
    description:
      "This service's repository no longer exists. This may be because it has been renamed or deleted.",
  },
  'LOST_ACCESS': {
    title: 'Lost access to repository',
    description:
      "The version control account you have linked no longer has access to this repository. This may because the repository's owner has revoked your access, or it may have been renamed or deleted.",
  },
  'UNLINKED_ACCOUNT': {
    title: 'VCS account unlinked',
    description:
      "This service's repository is no longer accessible as the version control account used to access it has been unlinked from Northflank. Please link a version control account that has access to this repository.",
    displaySettingsLink: true,
  },
  'REMOVED_CONTEXT': {
    title: 'Repository access revoked by team admin',
    description:
      "This service's repository is no longer accessible as it belongs to a context that this team no longer has access to. A team admin will need to add this repository's context if you wish to continue using this service.",
    displaySettingsLink: true,
  },
  'SUSPENDED_INSTALLATION': {
    title: 'GitHub installation suspended',
    description:
      "This repository can't be accessed because the GitHub installation it belongs to has suspended access. If this is your repo, please edit your installation settings to unsuspend the installation.",
    displayEditInstallation: true,
  },
  'TEAM_ONLY': {
    title: 'Self-hosted version control access revoked by team admin',
    description:
      "This repository can't be accessed as the self-hosted version control provider has had its settings changed by a team admin to disallow access on personal accounts. A team admin will need to change the self-hosted version control settings to 'Allow personal use' for you to continue using this service.",
  },
  'UNLINKED_ACCOUNT_SELF_HOSTED': {
    title: 'VCS account unlinked',
    description:
      "This service's repository is no longer accessible as a change to the settings of this self-hosted version control has required your account to be unlinked. Please link a version control account that has access to this repository.",
    displaySettingsLink: true,
  },
  'REMOVED_FROM_TEAM': {
    title: 'Self-hosted version control access revoked',
    description:
      "This repository can't be accessed as the self-hosted version control provider belongs to a team you were removed from. You will not be able to access it without rejoining the team and relinking your version control account.",
  },
};

const internalImageOption = {
  key: 'internal',
  title: 'Northflank',
  description: 'Deploy an image built on Northflank',
  Icon: NorthflankIcon,
};

const externalImageOption = {
  key: 'external',
  title: 'External image',
  description: 'Deploy an image from an external container registry',
  Icon: CloudDownload,
};

const vcsOption = {
  key: 'vcs',
  title: 'Version control',
  description: 'Deploy from a version control repository',
  Icon: GitCommit,
};

export const serviceDeploymentOptions = [internalImageOption, externalImageOption];

export const jobDeploymentOptions = [vcsOption, externalImageOption, internalImageOption];

export const progressTrackerDeploymentOptions = [
  vcsOption,
  internalImageOption,
  externalImageOption,
];

export const currencySymbols = {
  'USD': '$', // US Dollar
  'EUR': '€', // Euro
  'CRC': '₡', // Costa Rican Colón
  'GBP': '£', // British Pound Sterling
  'ILS': '₪', // Israeli New Sheqel
  'INR': '₹', // Indian Rupee
  'JPY': '¥', // Japanese Yen
  'KRW': '₩', // South Korean Won
  'NGN': '₦', // Nigerian Naira
  'PHP': '₱', // Philippine Peso
  'PLN': 'zł', // Polish Zloty
  'PYG': '₲', // Paraguayan Guarani
  'THB': '฿', // Thai Baht
  'UAH': '₴', // Ukrainian Hryvnia
  'VND': '₫', // Vietnamese Dong
};

export const getRegistryByValue = (value) => {
  return (
    registryOptions.find((r) => r.value === value) ||
    gcrRegistryOptions.find((r) => r.value === value)
  );
};

export const registryOptions = [
  {
    value: DockerCredentialProviders.GCR,
    label: 'Google Container Registry',
    Icon: GoogleCloud,
  },
  {
    value: DockerCredentialProviders.DOCKERHUB,
    label: 'Docker Hub',
    Icon: Docker,
    url: 'index.docker.io/v1',
  },
  {
    value: DockerCredentialProviders.GITLAB,
    label: 'GitLab',
    Icon: Gitlab,
    url: 'registry.gitlab.com',
  },
  {
    value: DockerCredentialProviders.GITHUB,
    label: 'GitHub',
    Icon: Github,
    url: 'docker.pkg.github.com',
  },
  {
    value: DockerCredentialProviders.ECR,
    label: 'Elastic Container Registry',
    Icon: Amazonaws,
    url: '',
  },
  {
    value: DockerCredentialProviders.CUSTOM,
    label: 'Custom container registry',
    Icon: Customize,
    url: '',
  },
];

export const gcrRegistryOptions = [
  {
    value: 'gcr',
    label: 'gcr.io',
    listLabel: 'Google Container Registry',
    Icon: GoogleCloud,
    url: 'gcr.io',
  },
  {
    value: 'gcr-eu',
    label: 'eu.gcr.io',
    listLabel: '(EU) Google Container Registry',
    Icon: GoogleCloud,
    url: 'eu.gcr.io',
  },
  {
    value: 'gcr-us',
    label: 'us.gcr.io',
    listLabel: '(US) Google Container Registry',
    Icon: GoogleCloud,
    url: 'us.gcr.io',
  },
  {
    value: 'gcr-asia',
    label: 'asia.gcr.io',
    listLabel: '(Asia) Google Container Registry',
    Icon: GoogleCloud,
    url: 'asia.gcr.io',
  },
];

export const cloudProviderOptions = [
  {
    value: 'northflank',
    title: 'Northflank',
    label: 'Northflank',
    Icon: NorthflankIcon,
    regions: [
      'us-west',
      'us-central',
      'us-east',
      'us-east1',
      'europe-west',
      'europe-west-metal',
      'europe-west-netherlands',
      'asia-southeast',
    ],
  },
  { value: 'google', title: 'Google Cloud Platform', Icon: GoogleCloud },
  { value: 'amazon', title: 'Amazon Web Services', Icon: Amazonaws },
  { value: 'self-hosted', title: 'Self Hosted', Icon: Server },
];

export const tmpCloudProviderFallback = {
  value: 'custom',
  title: 'Custom',
  Icon: QuestionMark,
};

export const RegistryImagePathExample =
  'registry-url/account/image:version (e.g. docker.io/nginxinc/nginx-unprivileged:latest)';

export const buildTypeOptions = [
  {
    value: 'dockerfile',
    label: 'Dockerfile',
    description: 'Requires your project to have a Dockerfile, gives full control of build process',
    Icon: Docker,
  },
  {
    value: 'buildpack',
    label: 'Buildpack',
    description: 'Detects project type and builds automatically, no Dockerfile required',
    Icon: Cube,
    metaLabel: {
      icon: Heroku,
      label: 'Heroku',
      color: '#8d6db1',
    },
  },
];

export const buildEngineOptions = [
  {
    value: 'kaniko',
    label: 'Kaniko',
    suggested: true,
  },
  {
    value: 'buildkit',
    label: 'BuildKit',
  },
];

export const buildpackStackOptions = [
  {
    value: 'HEROKU_24',
    label: 'heroku/builder:24',
    suggested: true,
  },
  {
    value: 'HEROKU_22',
    label: 'heroku/builder:22',
  },
  {
    value: 'HEROKU_22_CLASSIC',
    label: 'heroku/builder-classic:22',
    deprecated: true,
  },
  {
    // todo: mark as unavailable/unselectable
    value: 'HEROKU_20',
    label: 'heroku/buildpacks:20',
    deprecated: true,
  },
  {
    // todo: mark as unavailable/unselectable
    value: 'HEROKU_18',
    label: 'heroku/buildpacks:18',
    deprecated: true,
  },
  {
    value: 'GOOGLE_22',
    label: 'gcr.io/buildpacks/builder:google-22',
  },
  {
    value: 'GOOGLE_V1',
    label: 'gcr.io/buildpacks/builder:v1',
  },
  {
    value: 'CNB_ALPINE',
    label: 'cnbs/sample-builder:alpine',
  },
  {
    value: 'CNB_BIONIC',
    label: 'cnbs/sample-builder:bionic',
  },
  {
    value: 'PAKETO_TINY',
    label: 'paketobuildpacks/builder:tiny',
  },
  {
    value: 'PAKETO_BASE',
    label: 'paketobuildpacks/builder:base',
  },
  {
    value: 'PAKETO_FULL',
    label: 'paketobuildpacks/builder:full',
  },
];

export const healthCheckProtocolOptions = [
  { value: 'HTTP', label: 'HTTP', disabled: false },
  // { value: 'HTTPS', label: 'HTTPS', disabled: false },
  { value: 'TCP', label: 'TCP', disabled: false },
  { value: 'CMD', label: 'CMD', disabled: false },
];

export const healthCheckTypeOptions = [
  {
    value: 'readinessProbe',
    label: 'Readiness Probe',
    icon: PlayCircle,
    description: ['Ensures a new container is ready to serve traffic before terminating old ones'],
    disabled: false,
  },
  {
    value: 'livenessProbe',
    label: 'Liveness Probe',
    icon: HeartCircle,
    description: ['Will restart containers that are failing'],
    disabled: false,
  },
  {
    value: 'startupProbe',
    label: 'Startup Probe',
    icon: CheckCircle,
    description: ['Will delay readiness and liveness probes until passing'],
    disabled: false,
  },
];

export const splitDockerCMD = (cmd) => {
  try {
    return cmd ? split(cmd) : [];
  } catch (e) {
    return -1;
  }
};

export const clusterProviderKeyedOptions = {
  'nf': {
    value: 'nf',
    label: 'Northflank',
    icon: NorthflankIcon,
    engine: 'Northflank PaaS',
  },
  'gcp': {
    value: 'gcp',
    label: 'Google Cloud Platform',
    icon: GoogleCloud,
    engine: 'Google Kubernetes Engine (GKE)',
    shortLabel: 'GCP',
  },
  'aws': {
    value: 'aws',
    label: 'Amazon Web Services',
    icon: Amazonaws,
    engine: 'Elastic Kubernetes Service (EKS)',
    shortLabel: 'AWS',
  },
  'do': {
    value: 'do',
    label: 'DigitalOcean',
    icon: Digitalocean,
    engine: 'DigitalOcean Kubernetes Service (DOKS)',
  },
  'azure': {
    value: 'azure',
    label: 'Azure',
    icon: Microsoftazure,
    engine: 'Azure Kubernetes Services (AKS)',
  },
  'civo': {
    value: 'civo',
    label: 'Civo',
    icon: Cloud,
    engine: 'Civo Kubernetes',
  },
  'oci': {
    value: 'oci',
    label: 'Oracle',
    icon: Oracle,
    engine: 'Oracle Kubernetes Engine (OKE)',
  },
  'byok': {
    value: 'byok',
    label: 'BYOK',
    icon: Import,
    engine: 'byok',
  },
};

export const projectLifecycleStateOptions = {
  [LifecycleStates.CREATED]: {
    label: 'Created',
    icon: CheckCircle,
    color: 'success',
  },
  [LifecycleStates.UPGRADING]: {
    label: 'Upgrading',
    icon: ArrowToTop,
    color: 'primary',
  },
  [LifecycleStates.MIGRATING]: {
    label: 'Migrating',
    icon: Expand,
    color: 'warning',
  },
  [LifecycleStates.DELETING]: {
    label: 'Deleting',
    icon: TrashAlt,
    color: 'danger',
  },
  [LifecycleStates.CLUSTER_DELETED]: {
    label: 'Cluster deleted',
    icon: TrashAlt,
    color: 'grey3',
  },
};

export const projectTypeOptions = [
  {
    key: 'unrestricted',
    value: 'unrestricted',
    title: 'Managed cloud project',
    description:
      'Northflank’s managed cloud gives you the power of the Northflank platform, with no infrastructure setup required. Deploy any project with a hassle-free Kubernetes experience.',
    billingDescription: (
      <>
        You will be charged for usage as seen{' '}
        <a href="https://northflank.com/pricing#plans" target="_blank" rel="noreferrer">
          here
        </a>
        .
      </>
    ),
    billingSubText: 'Billing is calculated hourly and charged by the second.',
    icon: NorthflankIcon,
  },
  {
    key: 'unrestricted-byoc',
    value: 'unrestricted-byoc',
    title: 'Bring Your Own Cloud project',
    description:
      'Deploy into your own GCP, AWS, or Azure accounts with Bring Your Own Cloud. Enjoy all the benefits of Northflank’s platform layer while maintaining control over your data residency, deployment regions, cloud expenses, and security.',
    billingDescription: (
      <>
        You will be charged for usage as seen{' '}
        <a href="https://northflank.com/pricing#plans" target="_blank" rel="noreferrer">
          here
        </a>
        .
      </>
    ),
    billingSubText: 'Billing is calculated hourly and charged by the second.',
    icon: Cloud,
  },
  {
    key: 'free',
    value: 'free',
    title: 'Free project on Northflank’s managed cloud',
    description:
      'Get started deploying your apps, databases, and jobs on Northflank’s managed cloud for free.',
    billingDescription: 'You will not be charged for any usage.',
    // billingSubText: 'You can upgrade your free project to an unrestricted project later.',
    icon: NorthflankIcon,
  },
  {
    key: 'free-byoc',
    value: 'free-byoc',
    title: 'Free “Bring Your Own Cloud” project',
    description: 'Deploy into your own cloud account at no extra cost.',
    billingDescription: 'You will still pay your cloud provider for resources consumed.',
    // billingSubText: 'You can upgrade your free project to an unrestricted project later.',
    icon: Cloud,
  },
];

export const limitLabels = {
  teams: 'Teams',
  teamRoles: 'Team roles',
  'team-invitations': 'Team invitations',
  'api-token-templates': 'API roles',
  projects: 'Projects',
  'api-tokens': 'API tokens',
  domains: 'Domains',
  subdomains: 'Subdomains',
  'entity-docker-credentials': 'Docker credentials',
  services: 'Services',
  jobs: 'Jobs',
  addons: 'Addons',
  'project-secrets': 'Secret groups',
  pipelines: 'Pipelines V1',
  'pipelines-v2': 'Pipelines',
  instances: 'Instances per service',
  parallelBuildLimit: 'Parallel builds',
  addonExposeLimit: 'Publicly exposed addons',
  'platform-volumes': 'Volumes',
  volumeStorage: 'MB storage per volume',
  volumeStorageClasses: 'allowed volume storage classes',
  cpu: 'vCPU per instance',
  ram: 'MB RAM per instance',
  storage: 'MB storage per addon',
  replicas: 'Replicas per addon',
  notificationIntegrations: 'Notification integrations',
  billingAlerts: 'Billing alerts',
  addonLbPortExposeLimit: 'Publicly exposed MySQL/PostgreSQL',
  ephemeralStorage: 'MB ephemeral storage per instance',
};

export const nestedLimitLabels = {
  cpu: 'vCPU per build',
  ram: 'MB RAM per build',
  ephemeralStorage: 'MB ephemeral storage per build',
};

export const featureLabels = {
  addonsExpose: 'Publicly accessible addons',
};

export const notificationIntegrationTypeIcons = [
  {
    key: 'RAW_WEBHOOK',
    icon: ShareAlt,
  },
  {
    key: 'SLACK',
    icon: Slack,
  },
  {
    key: 'DISCORD',
    icon: Discord,
  },
  {
    key: 'TEAMS',
    icon: MicrosoftTeams,
  },
];

export const clusterComponents = [
  { key: 'cluster', label: 'Cluster', icon: Cloud },
  { key: 'nodes', label: 'Nodes', icon: Server },
  { key: 'network', label: 'Network', icon: Globe },
  { key: 'volumes', label: 'Volumes', icon: Hdd },
  { key: 'services', label: 'Services', icon: Layer },
  { key: 'certificate', label: 'Certificate', icon: CheckShield },
];

export const buildpackConfigOptions = [
  {
    value: 'default',
    label: 'Default configuration',
    description: ['Your Buildpack will run the default process.'],
    icon: Cube,
    smallLabel: 'Suggested',
    requiredFields: [],
  },
  {
    value: 'customProcess',
    label: 'Custom process',
    description: ['Enter the name of a custom process defined in your Procfile.'],
    icon: File,
    smallLabel: 'Procfile',
    requiredFields: ['customProcess'],
  },
  {
    value: 'customCommand',
    label: 'Custom command (launcher process)',
    description: ['Run a custom command with the Buildpack launcher.'],
    icon: Terminal,
    smallLabel: 'Command',
    requiredFields: ['customCommand'],
  },
  {
    value: 'originalEntrypointCustomCommand',
    label: 'Custom command (default process)',
    description: ['Run a custom command with the default process.'],
    icon: Terminal,
    smallLabel: 'Command',
    requiredFields: ['customCommand'],
  },
  {
    value: 'customEntrypointCustomCommand',
    label: 'Custom entrypoint & command',
    description: [
      'Set custom entrypoint and command overrides e.g. `/path/to/binary python main.py`.',
    ],
    icon: Cog,
    smallLabel: 'Advanced',
    requiredFields: ['customEntrypoint', 'customCommand'],
  },
];

export const dockerConfigOptions = [
  {
    value: 'default',
    label: 'Default configuration',
    description: ['Your Docker image will run the default ENTRYPOINT & CMD.'],
    icon: Cube,
    smallLabel: 'Suggested',
    requiredFields: [],
  },
  {
    value: 'customEntrypoint',
    label: 'Custom entrypoint',
    description: ['Set a custom entrypoint and maintain the default command.'],
    icon: File,
    smallLabel: 'ENTRYPOINT',
    requiredFields: ['customEntrypoint'],
  },
  {
    value: 'customCommand',
    label: 'Custom command',
    description: ['Set a custom command and maintain the default entrypoint.'],
    icon: Terminal,
    smallLabel: 'CMD',
    requiredFields: ['customCommand'],
  },
  {
    value: 'customEntrypointCustomCommand',
    label: 'Custom entrypoint & command',
    description: ['Set both a custom entrypoint and custom command.'],
    icon: Cog,
    smallLabel: 'Advanced',
    requiredFields: ['customEntrypoint', 'customCommand'],
  },
];

export const customPlanConfigOptions = [
  {
    value: 'northflank',
    label: 'Northflank',
    icon: NorthflankIcon,
    smallLabel: 'Default Northflank behaviour.',
  },
  {
    value: 'kubernetes',
    label: 'Kubernetes',
    icon: Kubernetes,
    smallLabel: 'Advanced controls over requests and limits.',
  },
];

export const customPlanTypeOptions = [
  {
    key: 'build',
    title: 'Build',
    icon: Cog,
  },
  {
    key: 'deployment',
    title: 'Deployment',
    icon: CloudUpload,
  },
];

export const templateConcurrencyOptions = [
  {
    value: 'allow',
    label: 'Allow',
    description: 'Allows parallel runs without restriction.',
    icon: PlayCircle,
  },
  {
    value: 'queue',
    label: 'Queue',
    description: 'Runs will be queued and processed sequentially in order of creation.',
    icon: AddToQueue,
  },
  {
    value: 'forbid',
    label: 'Forbid',
    description: 'While an existing run is pending or running, no additional runs will be added.',
    icon: StopCircle,
  },
];

export const backupCompressionOptions = [
  { value: 'gz', label: 'gz' },
  { value: 'zstd', label: 'zstd (Zstandard)' },
];

export const subdomainCdnOptions = [
  {
    value: CDNProviders.FASTLY,
    label: 'Fastly via Northflank',
    icon: Fastly,
    featureFlag: cdnProviderFeatureFlagMap[CDNProviders.FASTLY],
  },
  {
    value: CDNProviders.CLOUDFRONT,
    label: 'Custom CloudFront',
    icon: Amazonaws,
    featureFlag: cdnProviderFeatureFlagMap[CDNProviders.CLOUDFRONT],
  },
];

export const nodeMetricKeyTitleMap = {
  nodeCpuTotal: 'vCPU usage',
  nodeMemoryTotal: 'Memory usage',
  nodeCpu: 'vCPU',
  nodeMemory: 'Memory',
  nodePodCount: 'Pod count',
  'nodeResourceRequests-cpu': 'rCPU',
  'nodeResourceRequests-memory': 'rMem',
  nodeNetworkTotal: 'Network usage',
  nodeCount: 'Node count',
};

export const nodeMetricKeyOrder = [
  'nodeResourceRequests-cpu',
  'nodeResourceRequests-memory',
  'nodeCpu',
  'nodeMemory',
];

export const deploymentMetricKeyTitleMap = {
  cpuTotal: 'vCPU usage',
  memoryTotal: 'Memory usage',
  networkTotal: 'Network usage',
};

export const backupDestinationOptions = [
  {
    value: 's3',
    label: 'Amazon S3',
    icon: Amazonaws,
  },
];

export const observeRangeOptions = [
  { value: { type: 'live' }, label: 'Live tailing' },
  { value: { type: 'range', amount: '5', unit: 'm' }, label: 'Last 5 minutes' },
  {
    value: { type: 'range', amount: '15', unit: 'm' },
    label: 'Last 15 minutes',
  },
  {
    value: { type: 'range', amount: '30', unit: 'm' },
    label: 'Last 30 minutes',
  },
  { value: { type: 'range', amount: '1', unit: 'h' }, label: 'Last 1 hour' },
  { value: { type: 'range', amount: '3', unit: 'h' }, label: 'Last 3 hours' },
  { value: { type: 'range', amount: '6', unit: 'h' }, label: 'Last 6 hours' },
  { value: { type: 'range', amount: '12', unit: 'h' }, label: 'Last 12 hours' },
  { value: { type: 'range', amount: '24', unit: 'h' }, label: 'Last 24 hours' },
  { value: { type: 'range', amount: '2', unit: 'd' }, label: 'Last 2 days' },
  { value: { type: 'range', amount: '7', unit: 'd' }, label: 'Last 7 days' },
  { value: { type: 'custom' }, label: 'Custom range (UTC)' },
];

export const liveStatusColours = {
  online: 'success',
  retrying: 'grey3',
  offline: 'danger',
  loading: 'grey3',
};

export const draftTemplateStatusLabels = {
  [TemplateDraftStates.OPEN]: {
    label: capitalize(TemplateDraftStates.OPEN),
    icon: GitPullRequest,
    color: 'success',
  },
  [TemplateDraftStates.CLOSED]: {
    label: capitalize(TemplateDraftStates.CLOSED),
    icon: GitPullRequest,
    color: 'danger',
  },
  [TemplateDraftStates.ACCEPTED]: {
    label: capitalize(TemplateDraftStates.ACCEPTED),
    icon: GitPullRequest,
    color: 'done',
  },
};

export const vowels = ['a', 'e', 'i', 'o', 'u'];

export const getIndefiniteArticle = (string = '') =>
  vowels.includes(string[0]?.toLowerCase()) ? 'an' : 'a';

export const stripArgWrapperSyntax = (string) => string.replaceAll(`\${'`, '').replaceAll(`'}`, '');

export const BRANCHES_PAGE_SIZE = 50;

export const stacksDescription =
  'Stack templates allow you to configure and deploy popular applications consisting of multiple components in a matter of clicks';
