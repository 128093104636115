import React from 'react';

import { Customize } from '@styled-icons/boxicons-regular/Customize';
import { DollarCircle } from '@styled-icons/boxicons-regular/DollarCircle';
import { GitCommit } from '@styled-icons/boxicons-regular/GitCommit';
import { LineChart } from '@styled-icons/boxicons-regular/LineChart';
import { PackageIcon } from '@styled-icons/boxicons-regular/PackageIcon';
import { Pulse } from '@styled-icons/boxicons-regular/Pulse';
import { UpArrowCircle } from '@styled-icons/boxicons-regular/UpArrowCircle';
import { World } from '@styled-icons/boxicons-regular/World';

import Box from '@northflank/components/Box';
import Heading from '@northflank/components/Heading';
import FeatureNav from '@northflank/components/marketing/FeatureNav';
import FeatureWithIcon from '@northflank/components/marketing/FeatureWithIcon';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';

import {
  FeatureGroup,
  FeaturesWrapper,
} from '../../components/FeaturePageWrapper';
import PreHeading from '../../components/PreHeading';

const ManagedCloud = () => {
  return (
    <>
      <SEO
        title="Managed Cloud — Features"
        description="Deploying on Northflank’s managed cloud is the easiest way to get your workloads deployed on Kubernetes. We do all the work of scaling and management so you don’t have to worry about it."
      />
      <FeatureNav />
      <FeaturesWrapper>
        <Box variant="bounding" pb="70px!important">
          <PreHeading mb={6}>Feature breakdown</PreHeading>
          <Heading as="h1" color="grey1" fontSize={[6, 6, 7, 7]} mb={8}>
            Northflank’s Managed Cloud
          </Heading>
          <Text color="grey3" fontSize={4} width={[1, 1, 1 / 2]} mediaQuery>
            Northflank’s managed cloud gives you the power of the Northflank
            platform, with no infrastructure setup required. Deploy any project
            with a hassle-free Kubernetes experience.
          </Text>
        </Box>
        <FeatureGroup
          heading="The Simplest Way to Deploy"
          text="Northflank’s intuitive interface empowers developers with all levels of DevOps expertise to build, deploy, and manage their workloads with ease."
          items={
            <>
              <FeatureWithIcon
                Icon={GitCommit}
                iconGradient
                heading="Just Add Git"
                text="Connect to your version control account and start spinning up services seamlessly. Spend less time learning convoluted cloud tooling, and more time building the next big thing."
              />
              <FeatureWithIcon
                Icon={Customize}
                iconGradient
                heading="Fully Managed Platform"
                text="Let us do the hard work of managing your infra, nodes, load balancing, DNS, disk storage, and scaling, so you can focus on shipping code faster, and more confidently."
              />
              <FeatureWithIcon
                Icon={PackageIcon}
                iconGradient
                heading="Unified DX"
                text="Simplify your cloud stack and unify disparate tooling with Northflank’s wide array of features. Deploy to the cloud without the hassle of managing additional cloud providers."
              />
            </>
          }
        />
        <FeatureGroup
          heading="Total Flexibility"
          items={
            <>
              <FeatureWithIcon
                Icon={World}
                iconGradient
                heading="Choose Your Region"
                text={
                  <>
                    Northflank offers a range of regions for you to choose from.
                    Deploy your services in multiple locations for no extra
                    cost. We currently support:
                    <Box as="ul" pl="1em" mt={6}>
                      <li>US East</li>
                      <li>US Central</li>
                      <li>Europe West</li>
                      <li>Europe Amsterdam</li>
                      <li>Asia Singapore</li>
                    </Box>
                  </>
                }
              />
              <FeatureWithIcon
                Icon={DollarCircle}
                iconGradient
                heading="Pay As You Go"
                text="Avoid vendor lock-in and expensive cloud provider fees and commitments. With Northflank’s managed cloud, we handle the upfront costs, so you only have to pay for what you need, when you need it."
              />
              <FeatureWithIcon
                Icon={Customize}
                iconGradient
                heading="Mix-and-Match Cloud"
                text="Choose between Northflank’s managed cloud and Bring Your Own Cloud (BYOC) on a project-by-project basis. Run large projects in your own cloud while leaving the small things on Northflank’s managed cloud."
              />
            </>
          }
        />
        <FeatureGroup
          heading="Stress-Free Infrastructure"
          items={
            <>
              <FeatureWithIcon
                Icon={LineChart}
                iconGradient
                heading="High Volume Workloads"
                text="Northflank’s managed cloud makes it easy for you to scale up your workloads to meet demand, so you don’t have to worry when your services get more popular."
              />
              <FeatureWithIcon
                Icon={Pulse}
                iconGradient
                heading="24/7 Monitoring"
                text="All of Northflank’s cloud resources are monitored and scaled by our team of experts, so you can rest easy knowing you’ll have high uptime, no matter the time of day."
              />
              <FeatureWithIcon
                Icon={UpArrowCircle}
                iconGradient
                heading="Upgrade at Any Time"
                text="Easily move your workloads to your own cloud at any time. Bring Your Own Cloud is part of the same platform and feature suite - so you won’t need to worry about changing your workflow."
              />
            </>
          }
        />
      </FeaturesWrapper>
    </>
  );
};

export default ManagedCloud;
