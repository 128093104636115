/*
  HOC to generate a unique ID based on props of a component. Internal props and
  some other specified props are ignored, in order to prevent the ID changing
  throughout the life of the component.
*/

import React, { useEffect, useRef, useState } from 'react';

import murmurhash from 'murmurhash';

const propsToIgnore = [
  'class',
  'className',
  'value',
  'error',
  'disabled',
  'options',
  'menuIsOpen',
  'selected',
  'ref',
];

const withComponentId = (Component) => {
  const WithComponentId = React.forwardRef(
    ({ disableComponentId, ...props }, ref) => {
      const [digest, setDigest] = useState();
      const [nodeIndex, setNodeIndex] = useState(0);
      const [requiresRef, setRequiresRef] = useState(true);
      const node = useRef();

      useEffect(() => {
        if (requiresRef && window.useComponentIds) {
          /*
          stringify props, ignoring ones specified and ones already seen so to
          avoid circular structures, then hash the resulting string
          */
          const cache = [];
          const toHash = JSON.stringify(props, (key, value) => {
            if (key.startsWith('_') || propsToIgnore.includes(key)) return;
            if (typeof value === 'object' && value !== null) {
              if (cache.includes(value)) return;
              cache.push(value);
            }
            return value;
          });
          const hash = murmurhash.v3(toHash).toString(16);
          setDigest(hash);

          /*
          it's possible that the same component might exist more than once on a
          page, and have the same ID. so we search the DOM and set the index of
          each occurrence, then remove the now redundant ref'd wrapping div
          */
          const nodes = document.querySelectorAll(
            `div[data-component-id="${hash}"]`
          );
          for (let i = 0; i < nodes.length; i++) {
            if (nodes[i] === node.current) {
              setNodeIndex(i);
              setRequiresRef(false);
            }
          }
        }
      }, [node.current]);

      // window might not always be available - e.g. building marketing site
      try {
        if (!window.useComponentIds || disableComponentId) {
          return <Component {...props} ref={ref} />;
        }
      } catch (e) {
        return <Component {...props} ref={ref} />;
      }

      return requiresRef ? (
        <div data-component-id={digest} ref={node}>
          <Component {...props} ref={ref} />
        </div>
      ) : (
        <Component uid={`${digest}-${nodeIndex}`} {...props} ref={ref} />
      );
    }
  );

  WithComponentId.displayName = `WithComponentId`;

  return WithComponentId;
};

export default withComponentId;
