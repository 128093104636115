import Prism from 'prismjs';

Prism.hooks.add('after-highlight', (env) => {
  setTimeout(() => {
    // works only for <code> wrapped inside <pre data-line-numbers> (not inline)
    const pre = env.element.parentNode;
    const hasLineNumbers =
      pre && pre.querySelector('code').querySelector('span.line-numbers-rows');
    if (
      !pre ||
      !/pre/i.test(pre.nodeName) ||
      pre.className.indexOf('line-numbers') === -1 ||
      hasLineNumbers
    ) {
      return;
    }

    const linesNum = 1 + env.code.split('\n').length;

    let lines = new Array(linesNum);
    lines = lines.join('<span></span>');

    const lineNumbersWrapper = document.createElement('span');
    lineNumbersWrapper.className = 'line-numbers-rows';
    lineNumbersWrapper.innerHTML = lines;

    if (pre.hasAttribute('data-start')) {
      pre.style.counterReset =
        'linenumber ' + (parseInt(pre.getAttribute('data-start'), 10) - 1);
    }

    env.element.appendChild(lineNumbersWrapper);
  }, 0);
});
