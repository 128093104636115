export const STRIPE_BILLING_KEYS = {
  subscription: {
    key: 'nf-hosting',
  },
  paasMeteredPrice: {
    key: 'nf-hosting-metered-item',
    env: 'STRIPE_HOSTING_METERED_PRICE',
  },
  byocMeteredPrice: {
    key: 'nf-byoc-metered-item',
    env: 'STRIPE_BYOC_METERED_PRICE',
  },
  byocBasePrice: {
    key: 'nf-byoc-base-price-item',
    env: 'STRIPE_BYOC_DEFAULT_SUBSCRIPTION_PRICE',
  },
};
