import { useEffect, useRef } from 'react';

const useOnClickOutside = (onClickOutsideAction) => {
  const elementRef = useRef();

  useEffect(() => {
    // Event.composedPath polyfill from https://stackoverflow.com/a/57554722
    /* eslint-disable */
    (function (e, d, w) {
      if (!e.composedPath) {
        e.composedPath = function () {
          if (this.path) {
            return this.path;
          }
          var target = this.target;
          this.path = [];
          while (target.parentNode !== null) {
            this.path.push(target);
            target = target.parentNode;
          }
          this.path.push(d, w);
          return this.path;
        };
      }
    })(Event.prototype, document, window);
    /* eslint-enable */

    const handleClickOutside = (e) => {
      const eventPath = e.path || (e.composedPath && e.composedPath());
      if (elementRef.current && !eventPath.includes(elementRef.current))
        onClickOutsideAction(eventPath);
    };

    document.addEventListener('click', handleClickOutside, { capture: true });
    return () => {
      document.removeEventListener('click', handleClickOutside, {
        capture: true,
      });
    };
  }, []);

  return elementRef;
};

export default useOnClickOutside;
