import React from 'react';
import { Link } from 'react-router-dom';

import { X } from '@styled-icons/boxicons-regular/X';
import css from '@styled-system/css';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  typography,
} from 'styled-system';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Tooltip from '@northflank/components/Tooltip';

const StyledLink = styled(Link)(() => css({ overflow: 'hidden' }));

// Seems duplicated from other places
const WrapLink = ({ to, target, children, linkStyle }) =>
  to ? (
    target ? (
      <a
        href={to}
        target={target}
        style={{
          textDecoration: 'none',
          display: 'inline-block',
          outline: 0,
          ...linkStyle,
        }}
      >
        {children}
      </a>
    ) : (
      <StyledLink to={to} overflow="hidden">
        {children}
      </StyledLink>
    )
  ) : (
    children
  );

export const StyledLabel = styled.div(
  space,
  layout,
  grid,
  background,
  color,
  flexbox,
  typography,
  border,
  position,
  shadow,
  ({
    hover,
    display = 'flex',
    fontSize = 0,
    border = '1px solid',
    borderColor = 'grey6',
    borderRadius = '50px',
    bg = 'grey9',
    px = 6,
    py = 2,
    alignItems = 'center',
    color = 'grey1',
    overflow = 'hidden',
    whiteSpace = 'normal',
  }) =>
    css({
      cursor: hover && 'pointer',
      display,
      fontSize,
      border,
      borderColor,
      borderRadius,
      bg,
      px,
      py,
      alignItems,
      color,
      overflow,
      whiteSpace,
    })
);

const Label = React.forwardRef(
  (
    {
      closeFunc,
      children,
      to,
      target,
      linkStyle,
      containerProps,
      tooltip,
      ...props
    },
    ref
  ) => (
    <WrapLink to={to} target={target} linkStyle={linkStyle}>
      <Tooltip title={tooltip}>
        <Box display="flex" maxHeight="24px" {...containerProps}>
          <StyledLabel ref={ref} {...props}>
            {children}
            {closeFunc && (
              <Button
                onClick={closeFunc}
                variant={['noBorder', 'noBackground']}
                px={1}
                py={1}
                ml={3}
              >
                <X size={12} />
              </Button>
            )}
          </StyledLabel>
        </Box>
      </Tooltip>
    </WrapLink>
  )
);

Label.propTypes = {
  closeFunc: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default Label;
