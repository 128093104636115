import React from 'react';

import Box from '@northflank/components/Box';
import Icon from '@northflank/components/Icon';
import Text from '@northflank/components/Text';

const LargeHeading = ({
  icon,
  subHeading,
  firstLine,
  followingLines,
  headingAs = 'h2',
  firstLineColor = 'success',
  fontSize = [5, 5, 6],
  iconColor = 'grey0',
  iconBorderRadius = 3,
  iconBoxShadow = 'dropDown',
  iconWithLineBoxShadow,
  iconBg = 'grey5',
  ...rest
}) => (
  <Box variant="flex" alignItems="flex-start" {...rest}>
    {icon && (
      <Box
        bg="grey9"
        boxShadow={iconWithLineBoxShadow}
        borderRadius={iconBorderRadius}
        mr={[8, 8, 10]}
      >
        <Box
          background={iconBg}
          borderRadius={iconBorderRadius}
          boxShadow={iconBoxShadow}
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="64px"
          height="64px"
          p={6}
        >
          <Icon Content={icon} Size={48} color={iconColor} />
        </Box>
      </Box>
    )}
    <Box mt={8}>
      {subHeading && (
        <Text color="grey2" fontSize={5} fontWeight={600} mb={10}>
          {subHeading}
        </Text>
      )}
      <Text
        as={headingAs}
        fontSize={fontSize}
        lineHeight={1.2}
        width={[1, 1, 1, 3 / 4]}
      >
        {firstLine && (
          <>
            <Text as="span" color={firstLineColor}>
              {firstLine}
            </Text>
            <br />
          </>
        )}
        {followingLines}
      </Text>
    </Box>
  </Box>
);

export default LargeHeading;
