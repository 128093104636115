import css from '@styled-system/css';
import styled from 'styled-components';

import Text from '@northflank/components/Text';

export default styled(Text)(() =>
  css({
    backgroundImage: 'linear-gradient(90deg, #2DD881 10%, #30F18E 90%)',
    backgroundSize: '100%',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    '-moz-background-clip': 'text',
    '-moz-text-fill-color': 'transparent',
    fontWeight: 600,
    textTransform: 'uppercase',
    display: 'inline-block',
  })
);
