import React, { useContext } from 'react';

import { AddToQueue } from '@styled-icons/boxicons-regular/AddToQueue';
import { Award } from '@styled-icons/boxicons-regular/Award';
import { Brush } from '@styled-icons/boxicons-regular/Brush';
import { CheckCircle } from '@styled-icons/boxicons-regular/CheckCircle';
import { Cloud } from '@styled-icons/boxicons-regular/Cloud';
import { CodeAlt } from '@styled-icons/boxicons-regular/CodeAlt';
import { Customize } from '@styled-icons/boxicons-regular/Customize';
import { ErrorCircle } from '@styled-icons/boxicons-regular/ErrorCircle';
import { Extension } from '@styled-icons/boxicons-regular/Extension';
import { HardHat } from '@styled-icons/boxicons-regular/HardHat';
import { Refresh } from '@styled-icons/boxicons-regular/Refresh';
import { Time } from '@styled-icons/boxicons-regular/Time';
import { Trip } from '@styled-icons/boxicons-regular/Trip';
import { UserCircle } from '@styled-icons/boxicons-regular/UserCircle';
import { Wrench } from '@styled-icons/boxicons-regular/Wrench';
import { Dashboard } from '@styled-icons/boxicons-solid/Dashboard';
import { ThemeContext } from 'styled-components';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Grid from '@northflank/components/Grid';
import Heading from '@northflank/components/Heading';
import Icon from '@northflank/components/Icon';
import PrimaryFeatureBlock from '@northflank/components/marketing/PrimaryFeatureBlock';
import SEO from '@northflank/components/marketing/SEO';
import { TestimonialHeader } from '@northflank/components/marketing/Testimonial';
import Text from '@northflank/components/Text';

import DemoVideo from '../../components/DemoVideo';
import DevExpSection from '../../components/DevExpSection';
import FancyQuote from '../../components/FancyQuote';
import GetStartedCta from '../../components/GetStartedCta';
import HeroHeading from '../../components/HeroHeading';
import LargeCarousel from '../../components/LargeCarousel';
import PreHeading from '../../components/PreHeading';
import StatsSection from '../../components/StatsSection';
import texture from '../../components/Texture';
import topog from '../../components/Topog';
import UseCasesFooterLinks from '../../components/UseCasesFooterLinks';
import DevStepsDiagram from '../../svg/DevStepsDiagram.svg';

const InternalDeveloperPlatformPage = () => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <SEO
        title="Internal developer platform — Use cases"
        description="The ultimate toolkit for platform engineers. Northflank provides powerful and composable IaC primitives that accelerate platform creation."
      />

      <Box
        background={`radial-gradient(60% 100% at 70% 50%, rgba(0, 147, 255, 0.1) 0%, #151C28 100%),
        radial-gradient(circle at 70% 50%, rgba(0, 0, 0, 0) 0%, #151C28 100%),
         ${topog}`}
        borderBottom="1px solid"
        borderColor="grey5"
        mt="-80px"
        pt="80px"
      >
        <Box variant="bounding">
          <Grid
            gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
            alignItems="center"
            gridGap={12}
          >
            <Box>
              <HeroHeading
                as="h1"
                fontSize={[6, 6, 7]}
                color="grey1"
                mb={8}
                style={{ letterSpacing: 'normal', lineHeight: 1.2 }}
              >
                Build your Internal Developer Platform (IDP) on a solid
                foundation
              </HeroHeading>
              <Text
                fontSize={[5, 5, 6]}
                color="grey2"
                mb={[12, 12, 13]}
                css={{ lineHeight: 1.2 }}
              >
                The ultimate toolkit for platform engineers. Northflank provides
                powerful and composable IaC primitives that accelerate platform
                creation.
              </Text>
              <Text fontSize={4} color="grey2" mb={[12, 12, 13]}>
                Define reusable workflows so that app engineers can get started
                quickly. Make changes from anywhere—the web UI, CLI, and API.
                Get straightforward GitOps where you need it.
              </Text>
              <Grid
                gridTemplateColumns={['1fr', '1fr', 'repeat(2, max-content)']}
                gridGap={6}
                mb={6}
              >
                <a href="https://app.northflank.com/signup">
                  <Button
                    variant={['primary', 'large', 'noWrap']}
                    width={['100%', '100%', 'auto']}
                  >
                    <Icon Content={Cloud} Size={16} mr={4} />
                    Try Northflank Now
                  </Button>
                </a>
                <DemoVideo buttonProps={{ width: ['100%', '100%', 'auto'] }} />
              </Grid>
              <Text color="grey3">
                Prefer a live walkthrough?{' '}
                <a
                  href="https://cal.com/team/northflank/northflank-demo?duration=30"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Schedule a demo &rarr;
                </a>
              </Text>
            </Box>
            <Box width="100%">
              <DevStepsDiagram />
            </Box>
          </Grid>
        </Box>
      </Box>

      <Box variant="bounding">
        <Grid
          gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
          alignItems="center"
          gridGap={14}
          mb={[13, 13, 14]}
        >
          <Box>
            <Box mb={[12, 12, 13]}>
              <PreHeading mb={6}>
                Accelerate Your Platform Development
              </PreHeading>
              <Heading fontSize={[5, 5, '32px']} mb={6}>
                Why settle for anything
                <br />
                but the best?
              </Heading>
              <Text fontSize="18px" color="grey2">
                Building a custom Internal Developer Platform (IDP) from scratch
                is risky and time-consuming. With Northflank, you get a
                battle-tested platform that accelerates your development process
                and empowers your teams.
              </Text>
            </Box>
            <PrimaryFeatureBlock
              subFeatures={[
                {
                  Icon: Wrench,
                  heading: 'Ship your platform sooner',
                  text: 'Use the right building blocks to ship your platform quickly. Northflank offers higher-level primitives that make it easier to build a platform based on Kubernetes and cloud-native technologies. Get a head start with ready-to-go solutions for build, deployments, delivery, secure multi-tenancy, and more.',
                },
                {
                  Icon: Cloud,
                  heading: 'Move fast on any cloud',
                  text: 'Get running in your cloud of choice in 30 minutes or less. Northflank seamlessly integrates with EKS, GKE, AKS, and most flavors of Kubernetes. When you need to add more regions, availability zones, or other providers—we’ve got you covered with one consistent experience.',
                },
                {
                  Icon: CheckCircle,
                  heading: 'Exceptional developer experience',
                  text: 'Give your teams the best developer experience, with you defining the defaults and guardrails. All your developers need is a Dockerfile or Buildpack. Northflank picks up changes from Git and can automatically kick off builds.',
                },
                {
                  Icon: UserCircle,
                  heading: 'A platform for you',
                  text: 'Northflank is a battle-tested platform used in production by thousands of engineering teams. A platform flexible enough for the most complex workloads—from one microservice to a thousand, ephemeral to stateful. Usable by hobbyist app developers all the way to the most sophisticated platform teams.',
                },
              ]}
              subFeaturesGrid="1fr"
              subFeaturesGap={[12, 12, 13]}
              subFeaturesAlign="stretch"
            />
          </Box>
          <Box height="700px" overflow="hidden" position="relative" p={8}>
            <Box
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
              background={`linear-gradient(to right, transparent 0%, transparent 70%, ${theme.colors.background} 95%)`}
              backgroundSize="cover"
              zIndex={-1}
            />
            <Box
              as="img"
              src="/images/solutions/use-cases/dashboard.png"
              position="relative"
              height="100%"
              border="1px solid"
              borderColor="grey5"
              borderRadius={3}
              boxShadow="dropDown"
              zIndex={-2}
            />
          </Box>
        </Grid>
        <Grid
          gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
          alignItems="center"
          gridGap={14}
          mb={[13, 13, 14]}
        >
          <Box height="700px" overflow="hidden" position="relative" p={8}>
            <Box
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
              background={`linear-gradient(to left, transparent 0%, transparent 70%, ${theme.colors.background} 95%)`}
              backgroundSize="cover"
              zIndex={-1}
            />
            <Box
              as="img"
              src="/images/solutions/use-cases/preview.png"
              position="absolute"
              right={0}
              height="684px"
              border="1px solid"
              borderColor="grey5"
              borderRadius={3}
              boxShadow="dropDown"
              zIndex={-2}
            />
          </Box>
          <Box>
            <Box mb={[12, 12, 13]}>
              <PreHeading mb={6}>Automate and Simplify</PreHeading>
              <Heading fontSize={[5, 5, '32px']} mb={6}>
                Automate all the things
              </Heading>
              <Text fontSize="18px" color="grey2">
                Northflank automates your deployment pipeline, so you can focus
                on building great software. Harness the power Kubernetes without
                the YAML hell&nbsp;
                <Text as="strike" color="grey3">
                  helm
                </Text>
                .
              </Text>
            </Box>
            <PrimaryFeatureBlock
              subFeatures={[
                {
                  Icon: CodeAlt,
                  heading: 'IaC templates',
                  text: 'Group multiple services, jobs, and stateful workloads together into a single IaC template. Manage, compose, and repeat infrastructure operations effortlessly.',
                },
                {
                  Icon: AddToQueue,
                  heading: 'Beyond provisioning',
                  text: 'Traditional IaC stops at provisioning and ignores your apps. Northflank handles infrastructure and extends codification to pipelines and apps. Manage everything from one flexible interface.',
                },
                {
                  Icon: Extension,
                  heading: 'Bring your own addons',
                  text: 'Northflank provides managed services inside your clusters, so that you as a platform team can deliver high-quality services within the same VPC. We support the six most popular databases. If we don’t support one, leverage BYOA and bring your own Helm charts to offer your own stateful services.',
                },
                {
                  Icon: Customize,
                  heading: 'Integrations',
                  text: 'Automatic integrations with cloud and self-hosted version control (VCS) providers (GitHub, GitLab, BitBucket), CDNs (Fastly & CloudFront), custom DNS providers, and custom container registries. Sink capabilities to external observability and alerting providers.',
                },
              ]}
              subFeaturesGrid="1fr"
              subFeaturesGap={[12, 12, 13]}
              subFeaturesAlign="stretch"
            />
          </Box>
        </Grid>

        <Grid
          gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
          alignItems="center"
          gridGap={14}
          mb={[13, 13, 14]}
        >
          <Box>
            <Box mb={[12, 12, 13]}>
              <PreHeading mb={6}>
                Streamline Your Deployment Pipeline
              </PreHeading>
              <Heading fontSize={[5, 5, '32px']} mb={6}>
                From Git to production in no time
              </Heading>
              <Text fontSize="18px" color="grey2">
                Northflank automates your deployment pipeline, so you can focus
                on building great software without the usual headaches.
              </Text>
            </Box>
            <PrimaryFeatureBlock
              subFeatures={[
                {
                  Icon: Trip,
                  heading: 'Build golden paths',
                  text: 'Pipelines and automatic preview environments are your golden path to production. Codify your workflows and graduation policies, storing them in Northflank or Git. Automatically collect logs and metrics on every workload.',
                },
                {
                  Icon: Refresh,
                  heading: 'Fast builds for faster feedback',
                  text: 'Snappy container builds, preview environments, and release workflows speed up feedback cycles for stakeholders, QA, and code reviewers.',
                },
                {
                  Icon: Dashboard,
                  heading: 'Environment dashboard',
                  text: 'Monitor the state of all your resources with pipeline overviews, health status, and alerts. Segment your resources into projects or development, staging, and production environments.',
                },
                {
                  Icon: Brush,
                  heading: 'Clean up dangling resources automatically',
                  text: 'Easily spin up and down clusters with auto-scaling and templates. Automatically provision preview environments on commit. Avoid dangling resources with fine-grained usage and billing data and easily configurable retention periods for resources and backups.',
                },
              ]}
              subFeaturesGrid="1fr"
              subFeaturesGap={[12, 12, 13]}
              subFeaturesAlign="stretch"
            />
          </Box>
          <Box height="700px" overflow="hidden" position="relative" p={8}>
            <Box
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
              background={`linear-gradient(to right, transparent 0%, transparent 70%, ${theme.colors.background} 95%)`}
              backgroundSize="cover"
              zIndex={-1}
            />
            <Box
              as="img"
              src="/images/solutions/use-cases/releaseflow.png"
              position="relative"
              height="100%"
              border="1px solid"
              borderColor="grey5"
              borderRadius={3}
              boxShadow="dropDown"
              zIndex={-2}
            />
          </Box>
        </Grid>

        <FancyQuote
          body={
            <>
              Northflank is the first batteries-included developer platform that
              doesn’t suffer from the invisible ceilings that hover over its
              competitors.{' '}
              <Text as="span" color="success" fontWeight={500}>
                We could have built all of Slack with Northflank
              </Text>{' '}
              – and we would have, had it been available.
            </>
          }
          attribution={
            <TestimonialHeader
              name="Keith Adams"
              position="Formerly Chief Architect at Slack, Facebook, VMware, GP @ Pebblebed"
              avatar="/images/landing/quotes/keith.jpeg"
              linkedin="https://linkedin.com/in/keith-adams-pb/"
              mb={0}
            />
          }
        />
      </Box>

      <Box background="linear-gradient(to bottom, #141c28 0%, #192338 30%, #192338 70%, #141c28 100%)">
        <Box variant="bounding" py={[12, 12, '120px !important']}>
          <DevExpSection />
        </Box>
      </Box>

      <Box variant="bounding">
        <StatsSection />
      </Box>

      <Box
        background={`linear-gradient(to bottom, #141c28 0%, transparent 30%, #192338 70%, #141c28 100%), ${texture}, linear-gradient(to bottom, #141c28 0%, #192338 30%, #192338 70%, #141c28 100%)`}
      >
        <Box variant="bounding" py={[12, 12, '120px !important']}>
          <Grid
            gridTemplateColumns={['1fr', '1fr', '1fr 1.2fr']}
            gridGap={[12, 12, 13]}
            mb={[12, 12, 13]}
          >
            <Box>
              <PreHeading mb={6}>What’s Holding You Back?</PreHeading>
              <Heading fontSize={[5, 5, '32px']} color="grey1" mb={8}>
                Challenges for Internal Developer Platforms
              </Heading>
              <Text fontSize="18px" color="grey2">
                Building your own platform can be a daunting task. From the
                complexities of Kubernetes to the pitfalls of DIY solutions,
                many obstacles can impede your progress.
              </Text>
            </Box>
            <Box as="img" src="/images/landing/k8s.png" width="100%" />
          </Grid>
          <Box mb={[12, 12, 13]}>
            <PrimaryFeatureBlock
              subFeatures={[
                {
                  Icon: HardHat,
                  heading: 'Custom parts and DIY dangers',
                  text: 'Building custom solutions from scratch is high risk and high... well, just high risk. DIY platforms are too dependent on the engineers who build them, leading to single points of failure.',
                },
                {
                  Icon: ErrorCircle,
                  heading: 'Your developers are never satisfied',
                  text: 'Developers want to build great software without worrying about infrastructure. A platform must be flexible enough to satisfy changing requirements and evolving user expectations. Without quick iteration, friction between Dev and Ops becomes inevitable. Flexibility is seldom a feature of homegrown platforms.',
                },
                {
                  Icon: Award,
                  heading: 'There’s no prize for building from scratch',
                  text: 'Your platform should be built on established and battle-tested solutions. Creating your own abstraction over infrastructure-as-code is tedious and thankless work. And when it goes wrong, who is to blame? You.',
                },
                {
                  Icon: Time,
                  heading: 'Time & costs',
                  text: 'Building an IDP takes an army of engineers and years of work. It also requires massive investments in software and infrastructure—and it’s never under budget. Just when you hope you’re finished, it’s time to re-platform.',
                },
              ]}
              subFeaturesGrid={['1fr', '1fr', 'repeat(2, 1fr)']}
              subFeaturesGap={[12, 12, 13]}
              subFeaturesAlign="stretch"
            />
          </Box>
          <FancyQuote
            body={
              <>
                Northflank is the first batteries-included developer platform
                that doesn’t suffer from the invisible ceilings that hover over
                its competitors.{' '}
                <Text as="span" color="success" fontWeight={500}>
                  We could have built all of Slack with Northflank
                </Text>{' '}
                – and we would have, had it been available.
              </>
            }
            attribution={
              <TestimonialHeader
                name="Keith Adams"
                position="Formerly Chief Architect at Slack, Facebook, VMware, GP @ Pebblebed"
                avatar="/images/landing/quotes/keith.jpeg"
                linkedin="https://linkedin.com/in/keith-adams-pb/"
                mb={0}
              />
            }
          />
        </Box>
      </Box>

      <Box py={[12, 12, '96px']}>
        <LargeCarousel
          images={[
            '/images/solutions/use-cases/backupschedule.png',
            '/images/solutions/use-cases/cluster.png',
            '/images/solutions/use-cases/clusterobserve.png',
            '/images/solutions/use-cases/dashboard.png',
            '/images/solutions/use-cases/jobruns.png',
            '/images/solutions/use-cases/logs.png',
            '/images/solutions/use-cases/metrics.png',
            '/images/solutions/use-cases/preview.png',
            '/images/solutions/use-cases/previewduration.png',
            '/images/solutions/use-cases/releaseflow.png',
          ]}
        />
      </Box>

      <Box variant="bounding">
        <UseCasesFooterLinks mb={[12, 12, 13]} />
        <GetStartedCta />
      </Box>
    </>
  );
};

export default InternalDeveloperPlatformPage;
