import React from 'react';

const NorthflankIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M8.223 14.826l.744 1.29a.396.396 0 010 .395l-2.116 3.667a.846.846 0 01-.73.423h-3.52a.847.847 0 01-.728-.421l-1.76-3.045a.846.846 0 010-.846L6.966 4.421A.846.846 0 017.695 4h3.518a.845.845 0 01.73.421l2.303 3.987.3.523.677 1.162.3.521.73 1.265a.846.846 0 010 .846l-1.76 3.045a.845.845 0 01-.729.423H12.67a.396.396 0 01-.338-.198l-.746-1.29a.133.133 0 010-.132.132.132 0 01.113-.067h1.573l1.272-2.199-.97-1.691-.601-1.042-.677-1.164-1.573-2.725H8.179L1.815 16.717l1.274 2.2h2.537l2.368-4.09a.132.132 0 01.229 0zM23.885 7.47l-1.76-3.044a.845.845 0 00-.728-.422h-3.519a.846.846 0 00-.729.422L15.04 8.09a.396.396 0 000 .396l.742 1.286a.132.132 0 00.228 0l2.368-4.09h2.548l1.272 2.198-6.38 11.036h-2.536l-.302-.52-.986-1.679-.3-.519-.676-1.164-.3-.52-.302-.522-.973-1.691 1.272-2.2h1.575a.131.131 0 00.114-.197l-.743-1.288a.395.395 0 00-.338-.198h-1.088a.846.846 0 00-.73.423l-1.76 3.045a.846.846 0 000 .846l.73 1.264.3.52.677 1.164.6 1.042 2.003 3.468a.845.845 0 00.73.423h3.52a.846.846 0 00.729-.423l6.85-11.866a.846.846 0 00.002-.855z" />
  </svg>
);

export default NorthflankIcon;
