import React from 'react';

import { getIn, useFormikContext } from 'formik';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import FieldLabel from '@northflank/components/FieldLabel';
import NumberSelector from '@northflank/components/NumberSelector';
import {
  convertStorageDisplayUnit,
  storageClassMetaInformation,
} from '@northflank/constants-base';

const StorageSelector = ({ formikIndex, addonType }) => {
  const { values, setFieldValue, errors } = useFormikContext();
  return (
    <>
      <FieldLabel mb={6}>Storage</FieldLabel>
      <NumberSelector
        label="Storage"
        options={
          addonType
            ? addonType.resources?.storage?.options
            : storageClassMetaInformation[
                getIn(values, `resources.${formikIndex}.storageClass`)
              ].volumeValidOptions
        }
        selected={getIn(values, `resources.${formikIndex}.storage`)}
        handleSelect={(val) =>
          setFieldValue(`resources.${formikIndex}.storage`, val)
        }
        min={
          addonType
            ? addonType.resources?.storage?.minDisplayValue
            : storageClassMetaInformation[
                getIn(values, `resources.${formikIndex}.storageClass`)
              ].minValidValue
        }
        max={
          addonType
            ? addonType.resources?.storage?.maxDisplayValue
            : storageClassMetaInformation[
                getIn(values, `resources.${formikIndex}.storageClass`)
              ].maxValidValue
        }
        minValidValue={
          storageClassMetaInformation[
            getIn(values, `resources.${formikIndex}.storageClass`)
          ].minValidValue
        }
        minDisabledTooltip={
          storageClassMetaInformation[
            getIn(values, `resources.${formikIndex}.storageClass`)
          ].minimumRequirementMsg
        }
        postfix="GB"
        dropdownPostfix={
          getIn(values, `resources.${formikIndex}.storageClass`)
            ? `GB ${getIn(values, `resources.${formikIndex}.storageClass`).toUpperCase()}`
            : undefined
        }
        transform={convertStorageDisplayUnit}
        gridTemplateColumns="repeat(4, 80px)"
        otherOptions={
          <Box mb={6}>
            <FieldLabel mb={6}>Storage type</FieldLabel>
            <Box display="flex" _css={{ '> * + *': { ml: 3 } }}>
              {storageClassMetaInformation.storageClassOptions.map((opt) => (
                <Button
                  key={opt.value}
                  type="button"
                  onClick={() =>
                    setFieldValue(
                      `resources.${formikIndex}.storageClass`,
                      opt.value
                    )
                  }
                  borderColor={
                    opt.value ===
                    getIn(values, `resources.${formikIndex}.storageClass`)
                      ? 'primary'
                      : 'grey5'
                  }
                  borderRadius={4}
                  width="80px"
                  py={6}
                  px={6}
                >
                  {opt.label}
                </Button>
              ))}
            </Box>
          </Box>
        }
        error={getIn(errors, `resources.${formikIndex}.storage`)}
        dontCloseOnSelect
      />
    </>
  );
};

export default StorageSelector;
