import Prism from 'prismjs';

import React, { useEffect, useRef, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { X } from '@styled-icons/boxicons-regular/X';
import { usePostHog } from 'posthog-js/react';
import styled, { keyframes } from 'styled-components';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Input from '@northflank/components/Input';
import Text from '@northflank/components/Text';

const show = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const PopupContainer = styled(Box)`
  animation: ${show} 300ms forwards;
`;

const flyout = keyframes`
  0% {
    transform: translate(-50%, -50%);
  }
  50% {
    transform: translate(-50%, calc(-50% + 100px));
  }
  100% {
    transform: translate(-50%, calc(min(500px, 50vh) * -1 - 150%));
  }
`;

const Popup = styled(Box)`
  &.submit {
    animation: ${flyout} 600ms ease-in forwards;
  }
`;

const NewsletterPopup = () => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);

  const popupRef = useRef();

  useEffect(() => {
    Prism.highlightAll();

    const scrollListener = (e) => {
      if (
        e.target.scrollingElement.scrollTop >
        e.target.scrollingElement.scrollHeight * 0.3
      ) {
        setShow(true);
      } else {
        setShow(false);
      }
    };

    const userDismissed = window.localStorage.getItem(
      'nf-marketing-newsletter-dismissed'
    );

    if (!userDismissed) {
      document.addEventListener('scroll', scrollListener);
    } else {
      document.removeEventListener('scroll', scrollListener);
    }

    return () => {
      document.removeEventListener('scroll', scrollListener);
    };
  }, [show]);

  const posthog = usePostHog();

  const hide = () => {
    window.localStorage.setItem('nf-marketing-newsletter-dismissed', 'true');
    setShow(false);
  };

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setError(undefined);

    const form = new FormData(e.target);
    const email = form.get('email');

    const token = await executeRecaptcha('marketing_newsletter_signup');

    const res = await fetch('/api/capture-newsletter-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, token }),
    });

    setLoading(false);

    if (res.ok) {
      setSuccess(true);

      if (posthog) {
        posthog.capture('newsletter_subscribe', { email });
      }

      setTimeout(() => {
        popupRef.current.classList.add('submit');
        setTimeout(hide, 650);
      }, 2000);
    } else {
      setError(`${res.statusText}: ${await res.text()}`);
    }
  };

  if (!show) return null;

  return (
    <PopupContainer
      width="100vw"
      height="100vh"
      position="fixed"
      top={0}
      left={0}
      bg="rgba(0, 0, 0, 0.66)"
      zIndex={100}
      p={12}
      _css={{
        backdropFilter: 'blur(4px)',
      }}
      onClick={hide}
    >
      <Popup
        ref={popupRef}
        position="absolute"
        top="min(500px, 50vh)"
        left="50%"
        width="calc(100vw - 32px)"
        maxWidth="700px"
        bg="grey8"
        border="1px solid"
        borderColor="grey5"
        borderRadius={2}
        boxShadow="dropDown"
        _css={{
          transform: 'translate(-50%, -50%)',
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Box
          variant="flex"
          justifyContent="flex-end"
          borderBottom="1px solid"
          borderColor="grey5"
          px={12}
          py={7}
        >
          <Button variant={['icon', 'noBorder']} onClick={hide}>
            <X size={16} />
          </Button>
        </Box>
        <Box p={12}>
          <Box bg="grey11" borderRadius={3} mb={8}>
            <pre className="language-javascript">
              <code>
                {`try {
  await subscribeToNewsletter(user.email);
} catch (err) {
  console.error("🚨 Subscription failed! Creating incident...");
  await createIncidentTicket({ priority: "P1", issue: err.message });
}`}
              </code>
            </pre>
          </Box>
          <Box border="1px solid" borderColor="grey5" borderRadius={3} p={8}>
            <Box variant="flex" alignItems="flex-start" mb={10}>
              <Box>
                <Text fontSize={4} fontWeight={600} mb={4}>
                  We know pop-ups are rarely the highlight of your day...
                </Text>
                <Text fontSize={3} color="grey2">
                  But if you’re game for a few commits of great content, sign up
                  to our digest.
                </Text>
              </Box>
            </Box>
            {success ? (
              <Text fontSize={3} color="success" textAlign="center">
                Thank you for subscribing!
              </Text>
            ) : (
              <form onSubmit={handleSubmit}>
                <Box variant="flex" alignItems="flex-end">
                  <Input
                    type="email"
                    name="email"
                    label="Email"
                    wrapperProps={{ width: '100%' }}
                    required
                  />
                  <Button
                    variant="primary"
                    loading={loading}
                    minHeight="36px !important"
                    ml={6}
                  >
                    Submit
                  </Button>
                </Box>
              </form>
            )}
            {error && (
              <Text color="danger" mt={8}>
                Something went wrong: {error}
              </Text>
            )}
          </Box>
        </Box>
      </Popup>
    </PopupContainer>
  );
};

export default NewsletterPopup;
