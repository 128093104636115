import React from 'react';

import { Aws } from '@styled-icons/boxicons-logos/Aws';
import { GoogleCloud } from '@styled-icons/boxicons-logos/GoogleCloud';
import { Heroku } from '@styled-icons/boxicons-logos/Heroku';
import { Kubernetes } from '@styled-icons/boxicons-logos/Kubernetes';
import { Buildings } from '@styled-icons/boxicons-regular/Buildings';
import { Chip } from '@styled-icons/boxicons-regular/Chip';
import { Data } from '@styled-icons/boxicons-regular/Data';
import { Detail } from '@styled-icons/boxicons-regular/Detail';
import { Globe } from '@styled-icons/boxicons-regular/Globe';
import { Group } from '@styled-icons/boxicons-regular/Group';
import { Hdd } from '@styled-icons/boxicons-regular/Hdd';
import { Microchip } from '@styled-icons/boxicons-regular/Microchip';
import { User } from '@styled-icons/boxicons-regular/User';
import { Microsoftazure } from '@styled-icons/simple-icons/Microsoftazure';

import {
  baseResourcePrices,
  byocResourcePriceObject,
  calculateCPURate,
  calculateRAMRate,
  hoursPerMonth,
} from '@northflank/pricing';

export const baseByocCpuPrice = byocResourcePriceObject.cpu;
export const baseByocMemPrice = byocResourcePriceObject.memory;

export const resourcePriceObject =
  baseResourcePrices.find((p) => p.default) ??
  baseResourcePrices.find((p) => p.version === 'v2');

export const networkEgressPrices = {
  gb: 0.15, // $ per GB
  reqs: 0.5, // $ per 1M reqs
};

// generic for BYOC spend calculation (average of below)
export const genericCloudProviderPrices = {
  cpu: 29,
  memory: 2.9,
  storage: {
    ssd: 0.3,
    hdd: 0.15,
  }, // per month & GB
  cluster: 73,
};

// for Northflank managed comparison
export const cloudProviderPrices = {
  aws: {
    meta: {
      name: 'Amazon Web Services',
      icon: Aws,
    },
    cpu: 33.215,
    memory: 1.825,
  },
  azure: {
    meta: {
      name: 'Microsoft Azure',
      icon: Microsoftazure,
    },
    cpu: 27.01,
    memory: 3.38,
  },
  gcp: {
    meta: {
      name: 'Google Cloud Platform',
      icon: GoogleCloud,
    },
    cpu: 26,
    memory: 3.49,
  },
  heroku: {
    meta: {
      name: 'Heroku',
      icon: Heroku,
    },
    cpu: 60.9477,
    memory: 3.8106,
  },
};

export const resources = [
  {
    title: 'CPU',
    icon: Chip,
    price: calculateCPURate(
      { cpuResource: 1 },
      false,
      resourcePriceObject,
      false
    ),
    hourlyPrice: calculateCPURate(
      { cpuResource: 1 },
      false,
      resourcePriceObject,
      true
    ),
    byocPrice: baseByocCpuPrice,
    byocHourlyPrice: baseByocCpuPrice / hoursPerMonth,
    unit: '/ vCPU',
    description:
      'Scale compute from 0.1 vCPU to 32 vCPU. CPU based autoscaling supported.',
    byocDescription: 'In addition to cloud provider cost.',
    gridColumn: ['1', '1', '1 / 2', '1 / 2'],
    byocGridColumn: ['1', '1', '1 / 2', '3 / 4'],
  },
  {
    title: 'Memory',
    icon: Microchip,
    price: calculateRAMRate(
      { ramResource: 1024 },
      false,
      resourcePriceObject,
      false
    ),
    hourlyPrice: calculateRAMRate(
      { ramResource: 1024 },
      false,
      resourcePriceObject,
      true
    ),
    byocPrice: baseByocMemPrice,
    byocHourlyPrice: baseByocMemPrice / hoursPerMonth,
    unit: '/ GB',
    description:
      'Scale memory from 256 MB to 256 GB. Memory based autoscaling supported.',
    byocDescription: 'In addition to cloud provider cost.',
    gridColumn: ['1', '1', '2 / 3', '2 / 3'],
    byocGridColumn: ['1', '1', '2 / 3', '4 / 5'],
  },
  {
    title: 'Public Network Egress',
    byocTitle: 'Control Plane Network Egress',
    icon: Globe,
    price: networkEgressPrices.gb,
    unit: '/ GB',
    additionalPrice: networkEgressPrices.reqs,
    additionalUnit: '/ 1M reqs',
    tooltip: (
      <>
        Transfer to a different zone or AZ costs $0.02&nbsp;/&nbsp;GB when using
        Zonal Redundancy
      </>
    ),
    small: true,
    byocDescription:
      'Applies to Northflank managed services egress such as container registry, log sinks, and Northflank control plane.',
    byocGridColumn: ['1', '1', '1 / 3', '1 / 5'],
  },
  {
    title: 'Disk',
    icon: Hdd,
    price: resourcePriceObject.storage.ssd,
    unit: '/ GB / month',
    tooltip: `Pricing for SSD storage. HDD storage available for $${resourcePriceObject.storage.hdd} / GB / month`,
    small: true,
    byocGridColumn: ['1', '1', '2 / 3', '3 / 5'],
    hideByoc: true,
  },
  {
    title: 'Builds & Backups Storage',
    icon: Data,
    price: 0.08,
    unit: '/ GB / month',
    small: true,
    byocGridRow: [5, 5, 5, 4],
    byocGridColumn: ['1', '1', '1 / 2', '1 / 3'],
  },
  {
    title: 'Logs & Metrics Storage',
    icon: Detail,
    price: 0.5,
    unit: '/ GB',
    tooltip: 'First 10 GB / month included free. 30 days retention',
    small: true,
    byocGridRow: [6, 6, 5, 4],
    byocGridColumn: ['1', '1', '2 / 3', '3 / 5'],
  },
];

export const byocClusterResource = {
  title: 'Clusters',
  icon: Kubernetes,
  price: 100,
  unit: '/ cluster / month',
  byocDescription: 'Cost per BYOC cluster on AKS, EKS, or GKE.',
  byocGridRow: 1,
  byocGridColumn: ['1', '1', '1 / 3', '1 / 3'],
};

export const formatCost = (price) => {
  let intPrice = Math.floor(price);
  let decimalPrice = Math.ceil(Number((price - intPrice) * 100));
  if (decimalPrice === 100) {
    intPrice += 1;
    decimalPrice = 0;
  }
  if (decimalPrice === 0) return `${intPrice}.00`;
  if (decimalPrice < 10) return `${intPrice}.${decimalPrice}0`;
  return `${intPrice}.${decimalPrice}`;
};

export const sortPlans = (a, b) => {
  if (a.amountPerMonth < b.amountPerMonth) return -1;
  if (a.amountPerMonth > b.amountPerMonth) return 1;
  return 0;
};

export const allPlansInclude = [
  'Deployments on Northflank’s managed cloud or to your own AWS, GCP, or Azure account',
  'Apps, databases, and cron workloads',
  'Commit, push, build from Git workflow',
  'GitHub, GitLab & Bitbucket',
  'Buildpacks and custom Dockerfiles',
  'Free SSL and custom domains',
  'UI, API, & CLI access',
  'GitOps, IaC & Templates',
  'Real-time collaboration',
  'Pipelines and preview environments',
  'Logs and metrics',
  'Secret management',
  'RBAC',
  'Basic email support',
];

export const SandboxLimitedResourcesTooltip = () => (
  <>
    Deploy up to $20/mo worth of free resources on Northflank’s managed cloud
    <br />
    <br />
    Use up to 12 vCPU and 48 GB of memory on your free BYOC cluster
  </>
);

export const tiers = [
  {
    attributes: {
      key: 'developer',
      highlight: false,
      title: 'Developer Sandbox',
      shortTitle: 'Developer',
      description:
        'Test drive the Northflank experience with access to all our platform essentials.',
      icon: User,
      linkText: 'Get started for free',
      linkUrl: 'https://app.northflank.com/signup',
      features: [
        'Trial the Northflank platform',
        <>Includes 2 services, 2&nbsp;jobs, and 1 addon</>,
        {
          text: '1 BYOC cluster',
          tooltip: 'You will still incur usage costs from your cloud provider',
        },
        {
          text: 'Limited resources and plans',
          tooltip: <SandboxLimitedResourcesTooltip />,
        },
        'Upgrade at any time',
      ],
      cost: 'Free',
    },
  },
  {
    attributes: {
      key: 'team',
      highlight: true,
      title: 'Pay as you go',
      description:
        'The ultimate experience for start-ups and agile teams. Pay as you go, with no monthly commitment.',
      icon: Group,
      linkText: 'Get started',
      linkUrl: 'https://app.northflank.com/signup',
      features: [
        <>Northflank Cloud & Bring Your Own Cloud</>,
        'Unlimited projects',
        'Unlimited collaborators',
        {
          text: 'Global regions',
          tooltip:
            'US East, US Central, Europe West, Europe Amsterdam, Asia Singapore and 100 regions via BYOC',
        },
        {
          text: 'Unlimited BYOC clusters',
          tooltip: '$100/cluster/month',
        },
        'Unlimited vCPU & Memory',
        {
          text: '0.1-32vCPU 512MB-256GB Plans ',
          tooltip: 'Custom plans available',
        },
        'Unlimited Services, Jobs and Databases',
        {
          text: 'Platform SDK',
          tooltip:
            'Build or Buy? Leverage the Northflank Platform SDK to deliver on your App Platform or IDP',
        },
      ],
      dollarCost: 0,
      additionalCost: 'Infrastructure usage',
    },
  },
  {
    attributes: {
      key: 'enterprise',
      highlight: false,
      title: 'Enterprise',
      description: (
        <>
          Deliver the most advanced and flexible app deployment experience
          wherever you need it.
        </>
      ),
      icon: Buildings,
      linkText: 'Get in touch',
      linkUrl: 'https://cal.com/team/northflank/northflank-enterprise',
      features: [
        'On-prem and bare-metal clusters',
        {
          text: 'BYOX',
          tooltip:
            'Bring your own Logs, DNS, CDN, Registry, Cloud, Metrics, Vault & more',
        },
        'Unlimited org teams',
        'SAML & OIDC',
        'Great for 100-5000 engineers',
        'White labelling',
        'Contractual uptime guarantee',
        'Audit logging',
        'Custom SLA',
        'Optional self-deployable control plane',
      ],
      cost: 'Pricing based on features and deploy footprint',
    },
  },
];
