export const displayPrice = (value) => `${parseFloat(value).toFixed(3)}`;

export const parseCurrencyUnits = (units) => parseFloat(units / 100).toFixed(2);

export const getCurrencyAmountString = (units, currency) =>
  `${units < 0 ? '-' : ''}${displayCurrencySymbol(currency)}${parseCurrencyUnits(Math.abs(units))}`;

export const displayCurrencySymbol = (currency) => {
  if (!currency) return '';
  switch (currency.toUpperCase()) {
    case 'USD':
      return '$';
    case 'GBP':
      return '£';
    default:
      return currency.toUpperCase();
  }
};
