import React from 'react';
import { Link } from 'react-router-dom';

import { World } from '@styled-icons/boxicons-regular/World';
import slugify from 'slugify';

import Box from '@northflank/components/Box';
import Button from '@northflank/components/Button';
import Heading from '@northflank/components/Heading';
import SEO from '@northflank/components/marketing/SEO';
import Text from '@northflank/components/Text';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import CloudProviderRegions from '../../../../components/CloudProviders/CloudProviderRegions';
import CloudProvidersNav from '../../../../components/CloudProviders/CloudProvidersNav';
import { getDisplayId } from '../../../../components/CloudProviders/utility/getDisplayId';
import { FeaturesWrapper } from '../../../../components/FeaturePageWrapper';
import { Section } from '../index';

const BYOCProvider = ({ cloudProvider, providerRegions }) => {
  const { id, name, engine } = cloudProvider;

  const displayId = getDisplayId(id);
  const slugCloudName = slugify(name, { lower: true });

  const navItem = [
    {
      title: `${name}`,
      href: `/cloud/${id}/regions`,
      subItems: [
        {
          icon: World,
          title: `${name} Regions on Northflank`,
        },
      ],
    },
  ];

  return (
    <>
      <SEO
        title={`${name} Regions | Cloud Providers`}
        description={`Deploy Northflank projects on ${
          engine || name
        } to regions in EMEA, Americas, and Asia Pacific.`}
      />
      <CloudProvidersNav />
      <FeaturesWrapper cloudProviderPage customNavItems={navItem}>
        <Section anchor={`${slugCloudName}-regions-on-northflank`}>
          <Breadcrumbs mb={6} />
          <Heading as="h1" color="grey1" fontSize={[5, 5, 6, 6]} mb={8}>
            {name} Regions on Northflank
          </Heading>
          <Text fontSize={4} mediaQuery mb={13}>
            Deploy into your own {name} account into the following regions with{' '}
            <Link href="/features/bring-your-own-cloud">
              Bring Your Own Cloud (BYOC)
            </Link>
            . Enjoy all the benefits of Northflank’s platform layer while
            maintaining control over your data residency, deployment regions,
            cloud expenses, and security.
          </Text>
          <a
            href={`https://app.northflank.com/s/account/cloud/clusters/new/${id}`}
            target="_blank"
            style={{ display: 'inline-block' }}
            rel="noreferrer"
          >
            <Button variant={['large', 'primary']}>
              Deploy on {displayId} now
            </Button>
          </a>
        </Section>
        <Section>
          <CloudProviderRegions
            cloudProviderId={id}
            regions={providerRegions}
          />
        </Section>
      </FeaturesWrapper>
    </>
  );
};

const NorthflankProvider = ({ cloudProvider, providerRegions }) => {
  const { id, name } = cloudProvider;

  const navItem = [
    {
      title: `${name}`,
      href: `/cloud/${id}/regions`,
      subItems: [
        {
          icon: World,
          title: `Northflank Regions`,
        },
      ],
    },
  ];

  return (
    <>
      <SEO
        title={`${name} Regions | Cloud Providers`}
        description={`Deploy projects on ${name} to regions in EMEA, Americas, and Asia Pacific.`}
      />
      <CloudProvidersNav />
      <FeaturesWrapper cloudProviderPage customNavItems={navItem}>
        <Box id="northflank-regions" variant="bounding" pb="70px!important">
          <Breadcrumbs mb={6} />
          <Heading as="h1" color="grey1" fontSize={[5, 5, 6, 6]} mb={8}>
            {name} Regions
          </Heading>
          <Text fontSize={4} mediaQuery mb={13}>
            Northflank’s managed cloud gives you the power of the Northflank
            platform, with no infrastructure setup required. Deploy any project
            into the following regions with a hassle-free Kubernetes experience.
          </Text>
          <CloudProviderRegions
            cloudProvider={cloudProvider}
            regions={providerRegions}
          />
        </Box>
      </FeaturesWrapper>
    </>
  );
};

const Regions = ({ cloudProvider, providerRegions }) => {
  const northflank = cloudProvider?.id && cloudProvider.id === 'northflank';

  return northflank ? (
    <NorthflankProvider
      cloudProvider={cloudProvider}
      providerRegions={providerRegions}
    />
  ) : (
    <BYOCProvider
      cloudProvider={cloudProvider}
      providerRegions={providerRegions}
    />
  );
};

export default Regions;
