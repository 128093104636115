import css from '@styled-system/css';
import styled from 'styled-components';
import { typography } from 'styled-system';

import Heading from '@northflank/components/Heading';

export default styled(Heading)(
  () =>
    css({
      backgroundImage: 'linear-gradient(350deg, #0A84FF 10%, #0AF0FF 80%)',
      backgroundSize: '100%',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
      '-moz-background-clip': 'text',
      '-moz-text-fill-color': 'transparent',
      fontWeight: '500 !important',
      letterSpacing: '-2px',
      lineHeight: 1.2,
    }),
  typography
);
